import React from 'react'
import PropTypes from 'prop-types'
import { get, upperFirst, filter } from 'lodash'
import dayjs from 'dayjs'
import { Tooltip } from 'react-tippy'
import { formatDate } from '../../utils/date'
import { history } from '../../utils/history'

// utils
import Permissions from '../../utils/permissionsHoc'
import { UKONY_CISELNIK, UKON_STAV } from '../../utils/enums'
import { setRouteParams, UKON_DETAIL } from '../../utils/routes'
import { getUkonStateColor } from '../../utils/zmluvneVztahy'

class HistoryItem extends React.Component {
	static propTypes = {
		evenOdd: PropTypes.string.isRequired,
		ukon: PropTypes.shape(),
		interakcia: PropTypes.shape(),
		refetchHistrySideBar: PropTypes.func.isRequired,
		computedMatch: PropTypes.shape(),
		permissions: PropTypes.arrayOf(PropTypes.string),
		t: PropTypes.func
	}

	constructor(props) {
		super(props)

		this.state = {
			ukonDetail: null
		}
	}

	render() {
		const { evenOdd, ukon, permissions, interakcia, refetchHistrySideBar, computedMatch, t } = this.props

		const isActive = get(ukon, 'id') == get(computedMatch, 'params.ukonCislo') ? 'active' : ''

		const timeRange = filter([formatDate(get(ukon, 'zacatyOd'), null, 'HH:mm'), formatDate(get(ukon, 'spustenyOd'), null, 'HH:mm')], (item) => !!item).join(
			' - '
		)

		const vytvorenyOd = get(ukon, 'vytvorenyOd')
		const spustenyOd = get(ukon, 'spustenyOd')

		let ukonStavTooltip = get(ukon, 'ukonStav.nazov', '')

		const historyItemColor = getUkonStateColor(ukon)
		let backgroundColor

		if (get(ukon, 'ukonStav.id') == UKON_STAV.ODLOZENY) {
			backgroundColor = 'red'

			if (
				get(ukon, 'typ.id') === UKONY_CISELNIK.PODPIS_SEPA_MANDATU ||
				get(ukon, 'typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_NOVY_ODBER ||
				get(ukon, 'typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS ||
				get(ukon, 'typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_OBNOVA_MS ||
				get(ukon, 'typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_VAS ||
				get(ukon, 'typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_DODATOK
			) {
				ukonStavTooltip = t('components:HistoryItem.Na dokumente chýba podpis zákazníka Dajte mu ho podpísať')
			}
		}

		let stateChanges = []
		if (get(ukon, 'typ.id') == UKONY_CISELNIK.PODPIS_SEPA_MANDATU) {
			if (get(ukon, 'ukonStav.id') != UKON_STAV.ODLOZENY) {
				if (spustenyOd) {
					stateChanges = [
						<Tooltip
							key={`${get(ukon, 'id')}_state_1`}
							html={ukonStavTooltip}
							position='bottom'
							trigger='mouseenter'
							theme='light'
							followCursor={true}
							disabled={!ukonStavTooltip}
						>
							<div className='history-header clearfix'>
								<div className='title pull-left'>
									{formatDate(spustenyOd, '-', 'DD.MM.YYYY')}{' '}
									{spustenyOd && dayjs().format('YYYY-MM-DD') == dayjs(spustenyOd).format('YYYY-MM-DD') && '(dnes)'}
								</div>
								<div className='time pull-right'>{timeRange}</div>
							</div>
						</Tooltip>
					]
				}

				if (!spustenyOd || dayjs(spustenyOd).format('YYYY-MM-DD') != dayjs(vytvorenyOd).format('YYYY-MM-DD')) {
					stateChanges.push(
						<div key={`${get(ukon, 'id')}_state_2`} className='history-header clearfix'>
							<div className='title pull-left'>
								{formatDate(vytvorenyOd, '-', 'DD.MM.YYYY')}{' '}
								{vytvorenyOd && dayjs().format('YYYY-MM-DD') == dayjs(vytvorenyOd).format('YYYY-MM-DD') && '(dnes)'}
							</div>
							<div className='time pull-right'>{formatDate(vytvorenyOd, null, 'HH:mm')}</div>
						</div>
					)
				}
			} else {
				stateChanges = (
					<Tooltip
						key={`${get(ukon, 'id')}_state_1`}
						html={ukonStavTooltip}
						position='bottom'
						trigger='mouseenter'
						theme='light'
						followCursor={true}
						disabled={!ukonStavTooltip}
					>
						<div className='history-header clearfix'>
							<div className='title pull-left'>
								{formatDate(vytvorenyOd, '-', 'DD.MM.YYYY')}{' '}
								{vytvorenyOd && dayjs().format('YYYY-MM-DD') == dayjs(vytvorenyOd).format('YYYY-MM-DD') && '(dnes)'}
							</div>
							<div className='time pull-right'>{formatDate(vytvorenyOd, null, 'HH:mm')}</div>
						</div>
					</Tooltip>
				)
			}
		} else {
			stateChanges = (
				<Tooltip
					key={`${get(ukon, 'id')}_state_1`}
					html={ukonStavTooltip}
					position='bottom'
					trigger='mouseenter'
					theme='light'
					followCursor={true}
					disabled={!ukonStavTooltip}
				>
					<div className='history-header clearfix'>
						<div className='title pull-left'>
							{formatDate(vytvorenyOd, '-', 'DD.MM.YYYY')}{' '}
							{vytvorenyOd && dayjs().format('YYYY-MM-DD') === dayjs(vytvorenyOd).format('YYYY-MM-DD') ? '(dnes)' : ''}
						</div>
						<div className='time pull-right'>{timeRange}</div>
					</div>
				</Tooltip>
			)
		}

		const renderLabel = () => {
			// In case of VPU
			if (get(ukon, 'typ.id') === UKONY_CISELNIK.VSEOBECNY_UKON_PASIVNY) {
				if (get(ukon, 'popis')) {
					return upperFirst(get(ukon, 'popis'))
				}
			}
			return upperFirst(get(ukon, 'typ.nazov', '-'))
		}

		return (
			<div id={get(ukon, 'id')} className={`history-item-wrapper ${isActive} ${evenOdd}`} data-background-color={backgroundColor}>
				<div className='history-item' data-label-color={historyItemColor}>
					{stateChanges}
					<div className='history-content clearfix'>
						<Permissions
							allowed={permissions}
							render={(hasPerm, actions) => {
								return (
									<a
										onClick={(e) => {
											e.preventDefault()
											if (hasPerm) {
												history.push(setRouteParams(UKON_DETAIL, get(interakcia, 'data.opCislo'), get(ukon, 'id')))
												refetchHistrySideBar()
											} else {
												actions.openForbiddenModal()
											}
										}}
										style={{ textDecoration: 'underline', cursor: 'pointer' }}
										className='content'
									>
										{renderLabel()}
									</a>
								)
							}}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default HistoryItem
