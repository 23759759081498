import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { get, join, map } from 'lodash'
import dayjs from 'dayjs'
import { Tooltip } from 'react-tippy'

// utils
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'
import { formatDate } from '../../utils/date'
import { openDataUriWindow } from '../../utils/files'
import { SPLNOMOCNENIE_STAV_ICON, OBCHODNY_PARTNER_TYP, OP_SEGMENT_BB_MAP } from '../../utils/enums'
import { celeMeno } from '../../utils/obchodnyPartner'
import { setRouteParams, VZTAHY_DETAIL, VZTAHY_ZRUSIT } from '../../utils/routes'

const VZTAH_OPRAVNENEJ_OSOBY = 'ZOPR01'

function SplnomocneniaRow(props) {
	const { interakcia, splnomocnenie, t, obchodnyPartner } = props

	const vztahMatchSkupinaOpravneni = () => {
		const typ = get(splnomocnenie, 'typ.id')
		const skupinaOpravneni = get(obchodnyPartner, 'data.skupinaOpravneni', [])
		const isMopSegment = Object.values(OP_SEGMENT_BB_MAP).includes(typ)

		if (isMopSegment === false) {
			return true
		}

		if (typ === OP_SEGMENT_BB_MAP[skupinaOpravneni]) {
			return true
		}

		return false
	}

	let stav = SPLNOMOCNENIE_STAV_ICON.ZATIAL_NEAKTIVNE

	const platnostDatum = []
	if (get(splnomocnenie, 'platnostOd')) {
		platnostDatum.push(formatDate(get(splnomocnenie, 'platnostOd'), null, 'DD.MM.YYYY'))
		if (dayjs(get(splnomocnenie, 'platnostOd')).endOf('day') > dayjs().endOf('day')) {
			stav = SPLNOMOCNENIE_STAV_ICON.ZATIAL_NEAKTIVNE
		} else {
			stav = SPLNOMOCNENIE_STAV_ICON.AKTIVNE
		}
	}
	if (get(splnomocnenie, 'platnostDo')) {
		platnostDatum.push(formatDate(get(splnomocnenie, 'platnostDo'), null, 'DD.MM.YYYY'))
		if (dayjs(get(splnomocnenie, 'platnostDo')).endOf('day') < dayjs().endOf('day')) {
			stav = SPLNOMOCNENIE_STAV_ICON.NEAKTIVNE
		}
	} else {
		platnostDatum.push(t('components:SplnomocneniaTableRow.neurčito'))
		stav = SPLNOMOCNENIE_STAV_ICON.AKTIVNE
	}

	return (
		<tr>
			<td style={{ color: vztahMatchSkupinaOpravneni() === false ? 'red' : '' }}>{get(splnomocnenie, 'typ.nazov', '-')}</td>
			<td>{get(splnomocnenie, 'op2.cislo', '-')}</td>
			{get(splnomocnenie, 'op2.typ', null) === OBCHODNY_PARTNER_TYP.MOP && <td>{get(splnomocnenie, 'op2.obchodneMeno', '-')}</td>}
			{(get(splnomocnenie, 'op2.typ', null) === OBCHODNY_PARTNER_TYP.MOO || get(splnomocnenie, 'op2.typ', null) === OBCHODNY_PARTNER_TYP.OSTATNI) && (
				<td>{celeMeno(get(splnomocnenie, 'op2'))}</td>
			)}

			<td>
				<Tooltip
					html={
						<strong>
							{stav == SPLNOMOCNENIE_STAV_ICON.AKTIVNE
								? props.t('components:SplnomocneniaTableRow.Aktívny vzťah')
								: props.t('components:SplnomocneniaTableRow.Neaktívny vzťah')}
						</strong>
					}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					<div className='label circle' data-color={stav} style={{ marginRight: '15px', verticalAlign: '-3px' }} />
				</Tooltip>
				<span>{join(platnostDatum, ' - ')}</span>
			</td>
			<td>
				{map(get(splnomocnenie, 'dokumenty', []), (dokument) => (
					<Tooltip html={get(dokument, 'nazov', '-')} position='bottom' trigger='mouseenter' theme='light'>
						<a
							key={get(dokument, 'id')}
							className='file'
							data-type='general'
							style={{ cursor: 'pointer', marginRight: '20px', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }}
							target='_blank'
							onClick={(e) => {
								e.preventDefault()
								openDataUriWindow(get(dokument, 'id'), get(dokument, 'nazov', '-'))
							}}
						>
							{get(dokument, 'nazov', '-')}
						</a>
					</Tooltip>
				))}
			</td>
			<td style={{ textAlign: 'right' }}>
				{get(splnomocnenie, 'typ.id') === VZTAH_OPRAVNENEJ_OSOBY && (
					<Permissions
						allowed={[PERMISSIONS.UKON_SPLNOMOCNENIE]}
						render={(hasPerm, actions) => {
							if (hasPerm) {
								return (
									<Tooltip
										html={<strong>{props.t('components:SplnomocneniaTableRow.Ukončenie splnomocnenia')}</strong>}
										position='left'
										trigger='mouseenter'
										theme='light'
									>
										<Link
											to={setRouteParams(
												VZTAHY_ZRUSIT,
												get(interakcia, 'data.opCislo'),
												get(splnomocnenie, 'op2.cislo'),
												get(splnomocnenie, 'typ.id')
											)}
											className='button'
											data-type='icon-button'
											data-icon='cancel'
										/>
									</Tooltip>
								)
							}

							return (
								<Tooltip
									html={<span>{t('containers:SplnomocneniaPage.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>}
									position='left'
									trigger='mouseenter'
									theme='light'
								>
									<Link
										to={setRouteParams(
											VZTAHY_ZRUSIT,
											get(interakcia, 'data.opCislo'),
											get(splnomocnenie, 'op2.cislo'),
											get(splnomocnenie, 'typ.id')
										)}
										className='button'
										data-type='icon-button'
										data-icon='cancel'
										onClick={(e) => {
											e.preventDefault()
											actions.openForbiddenModal()
										}}
									/>
								</Tooltip>
							)
						}}
					/>
				)}
				<Tooltip
					html={t('containers:SplnomocneniaTableRow.Detail vzťahu')}
					position='bottom'
					trigger='mouseenter'
					theme='light'
					style={{ marginLeft: '10px' }}
				>
					<Link
						to={setRouteParams(VZTAHY_DETAIL, get(interakcia, 'data.opCislo'), get(splnomocnenie, 'op2.cislo'), get(splnomocnenie, 'typ.id'))}
						className='button'
						data-type='icon-button'
						data-icon='view'
					/>
				</Tooltip>
			</td>
		</tr>
	)
}

SplnomocneniaRow.propTypes = {
	interakcia: PropTypes.shape(),
	splnomocnenie: PropTypes.shape(),
	t: PropTypes.func.isRequired
}

export default SplnomocneniaRow
