import * as ZmluvneUcty from './ZmluvneUcty'
import * as ObchodnyPartnerDetail from './ObchodnyPartnerDetail'
import * as RozpisyZaloh from './RozpisyZaloh'
import * as OdberneMiesta from './OdberneMiesta'
import * as ZmluvnyUcetDetail from './ZmluvnyUcetDetail'
import * as RozpisZalohDetail from './RozpisZalohDetail'
import * as Pohladavky from './PohladavkyActions'
import * as Vztahy from './Vztahy'
import * as BankoveUcty from './BankoveUcty'
import * as PlanySplatok from './PlanySplatokActions'
import * as OtvoreneFaktury from './OtvoreneFakturyActions'
import * as Upomienky from './Upomienky'
import * as MarketingoveAtributy from './MarketingoveAtributy'
import * as Regulacie from './Regulacie'
import * as Digitalizacia from './Digitalizacia'

export default {
	...Pohladavky,
	...OdberneMiesta,
	...ZmluvneUcty,
	...ObchodnyPartnerDetail,
	...RozpisyZaloh,
	...ZmluvnyUcetDetail,
	...RozpisZalohDetail,
	...Vztahy,
	...BankoveUcty,
	...PlanySplatok,
	...OtvoreneFaktury,
	...Upomienky,
	...MarketingoveAtributy,
	...Regulacie,
	...Digitalizacia
}
