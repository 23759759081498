import React from 'react'
// resources
import EmptyMandates from '../../resources/img/icons/empty-mandates.svg'

export default class RozpisyZalohWidget extends React.Component {
	commonContentContainer = (content) => {
		return <div className='box-content'>{content}</div>
	}

	render() {
		return this.commonContentContainer(
			<div
				className='empty-state'
				data-text='Veľmi nás to mrzí, ale sekciu ešte len pripravujeme...'
				style={{ backgroundImage: `url(${EmptyMandates})`, zIndex: 0 }}
			/>
		)
	}
}
