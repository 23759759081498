import { get } from 'lodash'
import { FAKTURY_LOAD_DONE, FAKTURY_LOAD_FAIL, FAKTURY_LOAD_START } from '../../types/faktury'

import { getReq } from '../../utils/request'
import config from '../../utils/config'

export function fakturyLoadStart(page) {
	return {
		type: FAKTURY_LOAD_START,
		payload: { page }
	}
}

export function fakturyLoadDone(faktury, hasNextPage) {
	return {
		type: FAKTURY_LOAD_DONE,
		payload: {
			faktury,
			hasNextPage
		}
	}
}

export function fakturyLoadFail() {
	return {
		type: FAKTURY_LOAD_FAIL
	}
}

const getFaktury = async (page, dispatch, getStore, queryParams) => {
	const interakcia = getStore().interakcie.detail.data

	dispatch(fakturyLoadStart(page))

	const query = {
		page,
		pageSize: config.fakturyPageSize,
		sort: 'vystavenieDatum:DESC',
		...queryParams
	}

	try {
		const res = await getReq(`/api/v2/op/${interakcia.opCislo}/faktury`, query)

		const faktury = res.response.content
		const pageSize = get(res, 'response.pageSize', 0)
		const hasNextPage = get(faktury, 'length') >= pageSize

		return dispatch(fakturyLoadDone(faktury, hasNextPage))
	} catch (err) {
		dispatch(fakturyLoadFail())
	}
}

export function loadFaktury(page = 1, queryParams) {
	return (dispatch, getStore) => {
		getFaktury(page, dispatch, getStore, queryParams)
	}
}
