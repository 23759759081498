import { get } from 'lodash'
import { PK_GENERIC_UKON_CONTEXT_LOAD_START, PK_GENERIC_UKON_CONTEXT_LOAD_DONE, PK_GENERIC_UKON_CONTEXT_LOAD_FAIL } from '../../types/procesnyKonfigurator'
import { RESET_STORAGE } from '../../types/search'

export const initState = {
	context: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case PK_GENERIC_UKON_CONTEXT_LOAD_START:
			return {
				...state,
				context: {
					...state.context,
					isLoading: true,
					isFailure: false
				}
			}
		case PK_GENERIC_UKON_CONTEXT_LOAD_DONE:
			return {
				...state,
				context: {
					...state.context,
					data: get(action, 'payload.context'),
					isLoading: false,
					isFailure: false
				}
			}
		case PK_GENERIC_UKON_CONTEXT_LOAD_FAIL:
			return {
				...state,
				context: {
					...initState.context,
					isLoading: false,
					isFailure: true
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
