import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import * as AuthActions from '../actions/AuthActions'
import { history } from '../utils/history'

// elements
import ElementLoading from './ElementLoading'

class LoginCompleteHandler extends Component {
	static propTypes = {
		location: PropTypes.shape({
			search: PropTypes.string
		}).isRequired,
		authActions: PropTypes.shape({
			logInUser: PropTypes.func.isRequired
		}).isRequired,
		auth: PropTypes.shape()
	}

	static defaultProps = {
		location: {
			search: null
		}
	}

	componentDidMount() {
		if (this.props.location.search) {
			const params = new URLSearchParams(this.props.location.search)
			const token = params.get('t')
			if (token) {
				this.props.authActions.logInUser(token)
				return
			}
		}

		history.replace('/prihlasenie')
	}

	render() {
		return <ElementLoading />
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth
})

const mapDispatchToProps = (dispatch) => ({
	authActions: bindActionCreators(AuthActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginCompleteHandler)
