import React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'
import { Tooltip } from 'react-tippy'

// components
import ElementFailure from '../ElementFailure'
import ElementLoading from '../ElementLoading'

// utils
import { checkPermissions } from '../../utils/permissionsHoc'
import { getReq } from '../../utils/request'

class StavUctuWidget extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
		permission: PropTypes.string
	}

	_mounted = false

	constructor(props) {
		super(props)

		this.state = {
			hasPermission: checkPermissions(get(props, 'auth.user.roles', []), [props.permission]),
			zostatok: {
				data: null,
				isLoading: false,
				isFailure: false
			}
		}
	}

	loadZostatok = async () => {
		try {
			const { interakcia } = this.props

			if (this._mounted) {
				this.setState({
					zostatok: {
						data: null,
						isLoading: true,
						isFailure: false
					}
				})
			}

			const { response } = await getReq(`/api/v0/obchodni-partneri/${get(interakcia, 'opCislo')}/pohladavky/zostatok`, null, null, null)

			if (this._mounted) {
				this.setState({
					zostatok: {
						data: get(response, 'obsah'),
						isLoading: false,
						isFailure: false
					}
				})
			}
		} catch (e) {
			if (this._mounted) {
				this.setState({
					zostatok: {
						data: null,
						isLoading: false,
						isFailure: true
					}
				})
			}
		}
	}

	componentDidMount() {
		this._mounted = true

		const { hasPermission } = this.state
		if (hasPermission) {
			this.loadZostatok()
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	commonContentContainer = (content) => <div className='box-content'>{content}</div>

	render() {
		const { t } = this.props
		const { hasPermission, zostatok } = this.state

		if (!hasPermission) {
			return this.commonContentContainer(<ElementFailure text={t('translation:Common.Na zobrazenie informácií nemáte potrebné oprávnenia')} />)
		}

		if (get(zostatok, 'isLoading')) {
			return this.commonContentContainer(<ElementLoading />)
		}

		return this.commonContentContainer(
			<>
				<div className='inner-box'>
					<table style={{ height: 'auto' }}>
						<tbody>
							<tr>
								<td>{t('translation:StavUctu.Preplatky')}</td>
							</tr>
							<tr>
								<td className='text-right' data-text-size='large' style={{ lineHeight: '1' }}>
									<strong>
										<NumericFormat
											thousandSeparator={' '}
											decimalSeparator={','}
											decimalScale={2}
											fixedDecimalScale={true}
											displayType='text'
											suffix=' €'
											value={get(zostatok, 'data.sumaPreplatky')}
										/>
									</strong>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className='inner-box'>
					<Tooltip
						html={
							<span>
								{t(
									'translation:Common.Súhrn všetkých platieb v splatnosti, po splatnosti a očakávaných platieb do konca splátkového kalendára'
								)}
							</span>
						}
						position='top'
						trigger='mouseenter'
						theme='light'
					>
						<table style={{ height: 'auto' }}>
							<tbody>
								<tr>
									<td>{t('translation:StavUctu.Očakávané platby')}</td>
								</tr>
								<tr>
									<td className='text-right' data-text-size='large' style={{ lineHeight: '1' }} data-text-color='red'>
										<strong>
											<NumericFormat
												thousandSeparator={' '}
												decimalSeparator={','}
												decimalScale={2}
												fixedDecimalScale={true}
												displayType='text'
												suffix=' €'
												value={get(zostatok, 'data.sumaNedoplatky')}
											/>
										</strong>
									</td>
								</tr>
							</tbody>
						</table>
					</Tooltip>
				</div>
				<div className='inner-box'>
					<table style={{ height: 'auto' }}>
						<tbody>
							<tr>
								<td>{t('translation:StavUctu.Nedoplatky (po splatnosti)')}</td>
							</tr>
							<tr>
								<td className='text-right' data-text-size='large' style={{ lineHeight: '1' }} data-text-color='red'>
									<strong>
										<NumericFormat
											thousandSeparator={' '}
											decimalSeparator={','}
											decimalScale={2}
											fixedDecimalScale={true}
											displayType='text'
											suffix=' €'
											value={get(zostatok, 'data.sumaNedoplatkyPoSplatnosti')}
										/>
									</strong>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(StavUctuWidget)
