import React from 'react'
import { isEqual, get, filter } from 'lodash'
import dayjs from 'dayjs'
import { DEFINICIA_TYP, SUHLAS_HODNOTA, SUHLASY_KATEGORIA } from './enums'

export const isConfirmValid = (confirmDate) => {
	if (!confirmDate) return false

	if (dayjs(confirmDate).isBefore(dayjs().subtract(180, 'days'))) {
		return false
	}

	return true
}

export const isValidSuhlas = (suhlas) => {
	const now = Date.now()
	const validFrom = Date.parse(get(suhlas, 'platnostOd'))

	let valid = now >= validFrom

	if (get(suhlas, 'platnostDo')) {
		const validTo = Date.parse(get(suhlas, 'platnostDo'))
		valid = now >= validFrom && now < validTo
	}

	return valid
}

export const createSuhlasDTO = (hodnota, dovod, label, platnostOd) => {
	const now = new Date()
	if (hodnota === SUHLAS_HODNOTA.SUHLAS) {
		return {
			hodnota,
			dovod,
			platnostOd: platnostOd || now.toISOString()
		}
	}

	return {
		hodnota,
		nesuhlasDovod: {
			...(typeof dovod === 'number' && label && { id: dovod, dovod: label }),
			...(typeof dovod === 'number' && !label && { id: dovod }),
			// add default dovod for nesuhlasDovodIny based on CP-3124 request
			...(typeof dovod === 'string' && { id: 3, dovod: 'Iný' })
		},
		...(typeof dovod === 'string' && { nesuhlasDovodIny: dovod }),
		platnostOd: platnostOd || now.toISOString()
	}
}

export function formatOriginalSuhlas(nonPreferredValue, originalSuhlas) {
	if (!nonPreferredValue) {
		return ''
	}
	if (get(originalSuhlas, 'hodnota') == SUHLAS_HODNOTA.NAMIETKA && get(originalSuhlas, 'nesuhlasDovod.dovod')) {
		if (get(originalSuhlas, 'nesuhlasPoznamka')) {
			return `Nesúhlas: ${get(originalSuhlas, 'nesuhlasDovod.dovod', 'Nenájdená')} | Poznámka: ${get(originalSuhlas, 'nesuhlasPoznamka')}`
		}
		return `Nesúhlas: ${get(originalSuhlas, 'nesuhlasDovod.dovod', 'Nenájdená')}`
	}
}

// TODO: refactor whole function and remove suhlasy
export function formatSuhlas(nonPreferredValue, newSuhlas, originalSuhlas, nesuhlasDovod, originalAktualnost, aktualnost) {
	let areSame = areEquals(originalSuhlas, newSuhlas)

	// NOTE: ak mame dostupne aktualnosti, nektontrolujeme platnost pomocou suhlasu
	if ((originalAktualnost || aktualnost) && !newSuhlas) {
		areSame = areEquals(originalAktualnost, aktualnost)
	}

	let message = 'Žiadny dôvod odmietnutia preferovanej hodnoty'

	if (!nonPreferredValue && !originalAktualnost && !aktualnost) {
		// NOTE: CP-2355
		if (
			(get(originalSuhlas, 'hodnota') == SUHLAS_HODNOTA.SUHLAS &&
				get(newSuhlas, 'hodnota') == SUHLAS_HODNOTA.SUHLAS &&
				Date.parse(get(originalSuhlas, 'platnostOd')) < Date.parse(get(newSuhlas, 'platnostOd')) &&
				isValidSuhlas(newSuhlas)) ||
			(get(newSuhlas, 'hodnota') == SUHLAS_HODNOTA.SUHLAS && isValidSuhlas(newSuhlas))
		) {
			message = 'Overené'
		} else {
			message = ''
		}
	} else if (get(newSuhlas, 'hodnota') == SUHLAS_HODNOTA.NAMIETKA && get(newSuhlas, 'nesuhlasDovod')) {
		let namietka
		if (newSuhlas.nesuhlasDovodIny) {
			namietka = newSuhlas.nesuhlasDovodIny
		} else {
			namietka = get(newSuhlas, 'nesuhlasDovod.dovod', 'Nenájdená')
		}
		message = `Námietka: ${namietka}`
	} else if (originalAktualnost || aktualnost) {
		if (originalAktualnost === aktualnost) {
			message = ''
		} else if (isConfirmValid(aktualnost)) {
			message = 'Overené'
		} else {
			message = 'Zrušené overenie'
		}
	}

	return areSame ? message : <strong>{message}</strong>
}

export function formatConfirm(newConfirm, originalConfirm) {
	if (originalConfirm === newConfirm) {
		return null
	}
	if (isConfirmValid(newConfirm)) {
		return <strong>{'Overené'}</strong>
	}

	return <strong>{'Zrušené overenie'}</strong>
}

export function areEquals(a, b) {
	return a || b ? isEqual(a, b) : true
}

export function isDigitalizationDefinition(definiciaId) {
	if (!definiciaId) {
		return false
	}
	if (Number(definiciaId)) {
		const parsedDefiniciaId = Number(definiciaId)
		return (
			parsedDefiniciaId === DEFINICIA_TYP.E_FAKTURA ||
			parsedDefiniciaId === DEFINICIA_TYP.E_PLATBA ||
			parsedDefiniciaId === DEFINICIA_TYP.E_PREPLATKY ||
			parsedDefiniciaId === DEFINICIA_TYP.KONTAKTY_EMAIL ||
			parsedDefiniciaId === DEFINICIA_TYP.KONTAKTNY_TELEFON
		)
	}
	return false
}

export function requireSignature(poslednaVerzia) {
	if (!poslednaVerzia?.dokumentUrl) return false
	return true
}

export function areDifferentNesuhlas(originalNesuhlas, newNnesuhlas) {
	if (
		isEqual(originalNesuhlas?.platnostOd, newNnesuhlas?.platnostOd) &&
		isEqual(originalNesuhlas?.nesuhlasDovodIny, newNnesuhlas?.nesuhlasDovodIny) &&
		isEqual(originalNesuhlas?.nesuhlasDovod?.id, newNnesuhlas?.nesuhlasDovod?.id) &&
		isEqual(originalNesuhlas?.nesuhlasDovod?.dovod, newNnesuhlas?.nesuhlasDovod?.dovod) &&
		isEqual(originalNesuhlas?.nesuhlasDovod?.dovodAlt, newNnesuhlas?.nesuhlasDovod?.dovodAlt)
	) {
		return false
	}

	return true
}

export const suhlasCategoryInformativeTexts = [
	{
		suhlasKategoriaId: SUHLASY_KATEGORIA.OPRAVNENE_ZAUJMY,
		content: (
			<div className={'info-text'}>
				<p>
					<strong>Oprávnený záujem - priamy marketing: </strong>
				</p>
				<p>
					Umožňuje osloviť zákazníka bez potreby vopred udeleného marketingové súhlasu, a to s ponukou vlastných aj podobných produktov a služieb v
					primeranej miere. Oprávnenie na oslovenie vzniká na základe zmluvného vzťahu so zákazníkom t.j. napríklad platnej zmluvy.
				</p>
				<p>
					<strong>
						Oprávnený záujem môže zákazník namietať = požiadať, aby sme ho nekontaktovali s ponukami, neoslovovali e-mailami, volaním, sms.
					</strong>
				</p>
				<p>
					<strong>RIEŠENIE:</strong> agent zadá Námietku a tým vypne „Oprávnený záujem – priamy marketing“. Vypnutím sa automaticky zrušia aj všetky
					marketingové súhlasy.
				</p>
				<ul>
					<li>Zelený text „Povolené“: oslovujeme zákazníka s ponukami v primeranej miere</li>
					<li>Červený text „Námietka“: neoslovujeme zákazníka marketingovou komunikáciou, nemá záujem o ponuky</li>
				</ul>
			</div>
		)
	},
	{
		suhlasKategoriaId: SUHLASY_KATEGORIA.MARKETING,
		content: (
			<div className={'info-text'}>
				<p>
					<strong>Súhlasy (GDPR) - marketing: </strong>
				</p>
				<p>Každý Súhlas musí byť udelený dobrovoľne a slobodne. Udelený súhlas môže zákazník kedykoľvek odvolať.</p>
				<ul>
					<li>
						<strong>Individuálny marketingový súhlas:</strong> umožňuje poskytovať informácie o vlastných produktoch a službách nielen ZSE, ale aj
						našich partnerov. Ponuky prispôsobujeme individuálnym záujmom zákazníka, majú byť pre zákazníka užitočné a zaujímavé, ideálne „šité na
						mieru“.
					</li>
					<li>
						<strong>Všeobecný marketingový súhlas:</strong> umožňuje poskytovať len všeobecné informácie o vlastných produktoch a službách nielen
						ZSE, ale aj našich partnerov. Neanalyzujeme individuálne záujmy a preferencie zákazníka.
					</li>
				</ul>
				<p>
					<strong>Dôležité:</strong> Uprednostniť zber Individuálneho marketingového súhlasu.
				</p>
			</div>
		)
	}
]
