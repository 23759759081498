import React from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { get, find, map, filter, head, set, isEmpty, includes } from 'lodash'
import dayjs from 'dayjs'
import { destroy, change, initialize, reset } from 'redux-form'

// components
import SplnomocneniaInfo from '../../components/Splnomocnenia/SplnomocneniaCancelForm/SplnomocneniaInfo/SplnomocneniaInfo'
import SplnomocneniaConfirm from '../../components/Splnomocnenia/SplnomocneniaCancelForm/SplnomocneniaConfirm'
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementLoading from '../../components/ElementLoading'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import initProcesnyKonfigurator from '../../components/ProcesnyKonfigurator'

// utils
import { deleteReq, postReq, putReq } from '../../utils/request'
import { UKONY, UKONY_CISELNIK, FORMS, BUSINESS_CHANNELS, SCENARE, NOTIFICATION_TYPES, FILTER_SELECTORS, DOKUMENT_TYP } from '../../utils/enums'
import { getUkonVstupByKanal } from '../../utils/scenar'
import { setRouteParams, VZTAHY_ZOZNAM } from '../../utils/routes'
import { history } from '../../utils/history'
import { createFormInitValues } from '../../utils/form'
import { withPermissions, checkPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { celeMeno } from '../../utils/obchodnyPartner'

// actions
import * as TrackingActions from '../../actions/TrackingActions'
import * as DataActions from '../../actions/DataActions'
import * as UkonyActions from '../../actions/UkonyActions'
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'

// resources
import failureStateIcon from '../../resources/img/icons/warning.svg'

const scenarioOptions = [
	{
		kanal: BUSINESS_CHANNELS.ZSE_CENTRUM,
		scenarios: [{ typ: SCENARE.PODPISANIE_NA_MIESTE }, { typ: SCENARE.VYTLACIT_A_NAHRAT }, { typ: SCENARE.NAHRAT }],
		maxAllowedScenars: 3,
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	},
	{
		kanal: BUSINESS_CHANNELS.ZAKAZNICKA_LINKA,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		maxAllowedScenars: 1,
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	},
	{
		kanal: BUSINESS_CHANNELS.BACK_OFFICE,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		maxAllowedScenars: 1,
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	}
]

class SplnomocneniaCancelPage extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.any.isRequired
			}).isRequired
		}).isRequired,
		auth: PropTypes.shape().isRequired,
		dispatch: PropTypes.func.isRequired,
		ciselniky: PropTypes.shape().isRequired,
		tracking: PropTypes.shape().isRequired,
		notification: PropTypes.shape(),
		signedPdf: PropTypes.shape(),
		procesnyKonfigurator: PropTypes.shape({
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired,
			data: PropTypes.shape()
		}).isRequired,
		t: PropTypes.func.isRequired,
		ukonyActions: PropTypes.shape({
			clearUkonId: PropTypes.func.isRequired
		}).isRequired,
		trackingActions: PropTypes.shape().isRequired,
		dataActions: PropTypes.shape().isRequired,
		obchodnyPartnerActions: PropTypes.shape().isRequired,
		vztahDetail: PropTypes.shape(),
		computedMatch: PropTypes.shape(),
		selectedFiltersUkony: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		const ProcesnyKonfigurator = initProcesnyKonfigurator(FORMS.SPLNOMOCNENIA, 'splnomocnenie')

		// filter possible scenars for signing pdf files
		const scenarioOption = filter(scenarioOptions, (scenarioOption) => {
			return scenarioOption.kanal == get(props, 'auth.businessChannel.actual.id')
		})

		this.state = {
			step: 1,
			originalValues: {},
			scenarioOption: head(scenarioOption),
			isLoading: true,
			koKriteria: [],
			isKoKriteriumChecked: false,
			validacneKriteria: [],
			schvalovacieKriteria: [],
			ProcesnyKonfigurator,
			context: {
				data: null,
				isLoading: false,
				isFailure: false
			}
		}
	}

	_mounted = false

	async componentDidMount() {
		this._mounted = true

		const { tracking, trackingActions, dataActions, obchodnyPartnerActions, auth, ciselniky, dispatch, computedMatch, interakcia } = this.props

		dataActions.registerLeavePageModal()

		if (!tracking.startTime || (tracking.startTime && tracking.type !== UKONY.SPLNOMOCNENIE)) {
			trackingActions.tryToStartTracking(UKONY.SPLNOMOCNENIE)
		}

		const { params } = computedMatch

		const cisloOP = get(params, 'cisloOP')
		const cisloSplnomocnenca = get(params, 'cisloSplnomocnenca')
		const typVztahu = get(params, 'typVztahu')

		const vztahDetail = await obchodnyPartnerActions.loadVztahDetail(cisloOP, cisloSplnomocnenca, typVztahu)

		const vztahDetailData = get(vztahDetail, 'vztah', null)
		const op2 = get(vztahDetail, 'op2', null)

		const initValues = createFormInitValues({
			ukonVstup: getUkonVstupByKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'data.ukonVstup')),
			podpisMiesto: get(auth, 'user.podpisMiesto', ''),
			splnomocnenecCislo: get(op2, 'cislo'),
			splnomocnenaOsoba: celeMeno(op2),
			/* ukonRealizujeSplnomocnenaOsoba: false, */
			datumPrijatiaZiadosti: new Date(),
			platnostDo: get(vztahDetailData, 'platnostDo')
		})

		dispatch(initialize(FORMS.SPLNOMOCNENIA, initValues, true))
		dispatch(reset(FORMS.SPLNOMOCNENIA))

		if (this._mounted) {
			this.setState({
				originalValues: {
					...initValues,
					platnostOd: get(vztahDetailData, 'platnostOd'),
					originPlatnostDo: get(vztahDetailData, 'platnostDo') ? dayjs(get(vztahDetailData, 'platnostDo')) : null
				}
			})
		}

		this.setState({
			isLoading: false
		})
	}

	componentWillUnmount() {
		const { dispatch, dataActions, trackingActions, ukonyActions } = this.props

		dispatch(destroy(FORMS.SPLNOMOCNENIA))

		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()

		// clear ukonId from storage
		ukonyActions.clearUkonId()

		this._mounted = false
	}

	componentDidUpdate(prevProps, prevState) {
		const { step } = this.state

		// In case of return from step 3 back to step 2 revert default list of available
		if (prevState.step == 2 && step == 1) {
			this.setState({ dissallowedScenarios: [] })
		}
	}

	formatDataForUkon = (values) => {
		const { interakcia, ciselniky, tracking, auth, vztahDetail } = this.props

		const vstup = find(get(ciselniky, 'data.ukonVstup', []), (vstup) => {
			return vstup.id == get(values, 'ukonVstup')
		})

		const typ = find(get(ciselniky, 'data.ukonTyp', []), (ukonTyp) => get(ukonTyp, 'id') == UKONY_CISELNIK.SPLNOMOCNENIE_UKONCENIE)

		return {
			typ,
			opCislo: get(interakcia, 'opCislo'),
			interakciaId: get(interakcia, 'id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
			zacatyOd: dayjs(tracking.startTime).toISOString(),
			vstup,
			splnomocnenec: '',
			/* splnomocnenec: get(values, 'ukonRealizujeSplnomocnenaOsoba')
				? get(values, 'splnomocnenaOsoba')
				: '', */
			podpisMiesto: get(values, 'podpisMiesto'),
			poznamka: get(values, 'poznamka'),
			ziadanyOd: dayjs(get(values, 'datumPrijatiaZiadosti')).toISOString(),
			data: {
				splnomocnenec: get(vztahDetail, 'data.op2'),
				platnostOd: get(vztahDetail, 'data.vztah.platnostOd'),
				platnostDo: dayjs(get(values, 'platnostDo')).format('YYYY-MM-DD')
			}
		}
	}

	setLoading = (value) => {
		if (this._mounted) {
			this.setState({
				isLoading: value
			})
		}
	}

	onDeleteFile = async (fileID) => {
		const { ukonNovy } = this.props

		try {
			await deleteReq(`/api/v0/ukony/${ukonNovy.id}/dokumenty/${fileID}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	stepOneClickHandler = async (values) => {
		const { interakcia, ukonyActions, ukonNovy, tracking, dispatch } = this.props

		this.setLoading(true)

		const body = this.formatDataForUkon(values)

		try {
			let ukonId
			// if ukon not exist yet create a new one else update it
			if (!ukonNovy.id) {
				const res = await postReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ukony`, null, body)

				// set podpis Miesto from response CP-588
				const podpisMiesto = get(res, 'response.content.podpisMiesto')
				dispatch(change(FORMS.SPLNOMOCNENIA, 'podpisMiesto', podpisMiesto))

				ukonId = get(res, 'response.content.id')
				ukonyActions.setUkonId(ukonId)
			} else {
				ukonId = ukonNovy.id
				const updateBody = {
					...body,
					trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
					id: ukonId
				}
				await putReq(`/api/v2/ukony/${ukonId}`, null, updateBody)
			}

			// dokumenty which are not upload does not have ID
			const notUploadedDokumenty = filter(values.dokumenty, (dokument) => !dokument.id)
			const uploadedDokumenty = filter(values.dokumenty, (dokument) => dokument.id)

			const dokumenty = map(notUploadedDokumenty, async (file) => {
				const body = {
					contentType: file.type,
					nazov: file.name,
					data: file.dataAsBase64,
					typ: {
						id: DOKUMENT_TYP.VSTUPNY
					}
				}

				const dokumentResponse = await postReq(`/api/v0/ukony/${ukonId}/prilozit-dokument`, null, body)
				return {
					type: get(dokumentResponse, 'response.contentType'),
					id: get(dokumentResponse, 'response.id'),
					dataAsBase64: get(file, 'dataAsBase64'),
					typ: get(dokumentResponse, 'response.typ'),
					name: get(dokumentResponse, 'response.nazov')
				}
			})

			const dokumentyPromises = await Promise.all(dokumenty)

			// merge already uploaded dokumenty and fresh uploaded
			dispatch(change(FORMS.SPLNOMOCNENIA, 'dokumenty', [...uploadedDokumenty, ...dokumentyPromises]))

			if (this._mounted) {
				this.setLoading(false)
				this.setState({
					step: 2
				})
			}
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	stepTwoClickHandler = async (values) => {
		const { ukonNovy, tracking, notification, signedPdf, templatePdf, dataActions, t } = this.props

		this.setLoading(true)

		let notifikacie = {}
		if (get(notification, 'typ') == NOTIFICATION_TYPES.ADDRESS) {
			set(notifikacie, 'adresyPosta', [get(notification, 'address')])
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.EMAIL) {
			set(notifikacie, 'adresyUri', [
				{
					typ: NOTIFICATION_TYPES.EMAIL,
					hodnota: get(notification, 'email')
				}
			])
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.PRINTER) {
			set(notifikacie, 'adresyUri', [
				{
					typ: NOTIFICATION_TYPES.PRINTER,
					poradie: 0
				}
			])
		}

		notifikacie = isEmpty(notifikacie) ? undefined : notifikacie
		const documentForUpload = get(signedPdf, 'data.dataAsBase64') ? get(signedPdf, 'data') : get(templatePdf, 'data')
		const dokument = {
			contentType: get(documentForUpload, 'type'),
			typ: {
				id: DOKUMENT_TYP.VYSTUPNY
			},
			nazov: get(documentForUpload, 'name'),
			data: get(documentForUpload, 'dataAsBase64')
		}

		try {
			if (documentForUpload) {
				await postReq(`/api/v0/ukony/${ukonNovy.id}/prilozit-dokument`, null, dokument)
			}

			const body = this.formatDataForUkon(values)
			const spustitBody = {
				...body,
				trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
				notifikacie,
				id: ukonNovy.id
			}
			await postReq(`/api/v2/ukony/${ukonNovy.id}/spustit`, null, spustitBody)

			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Úkon bol úspešne odoslaný'),
					success: true,
					isLoading: false
				})
			}
		} catch (e) {
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
					success: false,
					isLoading: false
				})
			}
		}
	}

	confirmModal = () => {
		const { interakcia, ukonyActions, selectedFiltersUkony } = this.props
		const { success } = this.state

		if (this._mounted) {
			if (success) {
				this.setState(
					{
						success: false
					},
					() => {
						// clear ukonId from storage
						ukonyActions.clearUkonId()
						// refetch new data for historia ukonov
						ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)
						history.replace(setRouteParams(VZTAHY_ZOZNAM, interakcia.opCislo))
					}
				)
			}
		} else {
			this.setState({
				result: null
			})
		}
	}

	koKriteriaHandler = (isKoKriteriumChecked, koKriteria) => {
		return this.setState({
			isKoKriteriumChecked,
			koKriteria
		})
	}

	validacneKriteriaHandler = (validacneKriteria) => this.setState({ validacneKriteria })

	schvalovacieKriteriaHandler = (schvalovacieKriteria) => this.setState({ schvalovacieKriteria })

	koKriteriumContentElement = (koKriteria) => {
		const content = map(koKriteria, (koKriterium, index) => (
			<React.Fragment key={index}>
				<p>{koKriterium.nazov}:</p>
				<p className='message fail'>{koKriterium.popis}</p>
			</React.Fragment>
		))

		return (
			<div className='failure-content-container'>
				<img src={failureStateIcon} />
				{content}
			</div>
		)
	}

	render() {
		const { t, notification, signedPdf, interakcia, procesnyKonfigurator, auth, vztahDetail } = this.props
		const {
			step,
			success,
			result,
			scenarioOption,
			isLoading,
			koKriteria,
			schvalovacieKriteria,
			validacneKriteria,
			context,
			dissallowedScenarios,
			originalValues,
			ProcesnyKonfigurator
		} = this.state

		if (isLoading || get(procesnyKonfigurator, 'isLoading') || get(vztahDetail, 'isLoading')) {
			return this.loadingContentElement()
		}

		if (get(vztahDetail, 'isFailure')) {
			return this.failureContentElement()
		}

		if (isEmpty(vztahDetail, 'data')) {
			return <ElementEmptyContent text={t('containers:SplnomocneniaCancelPage.Splnomocnenie neexistuje')} />
		}

		if (get(procesnyKonfigurator, 'isFailure') || !get(procesnyKonfigurator, 'data.ukony')) {
			return <ElementEmptyContent text={t('containers:Ľutujeme ale nepodarilo sa načítať Procesný konfigurátor')} />
		}

		// check if every Ko kriterium pass condition
		const invalidKoKriteria = filter(koKriteria, { vysledok: false })
		if (!isEmpty(invalidKoKriteria)) {
			return this.koKriteriumContentElement(invalidKoKriteria)
		}

		let component = null
		switch (step) {
			case 1:
				component = (
					<SplnomocneniaInfo
						{...this.props}
						formTitle={t('containers:SplnomocneniaCancelPage.Krok 1 z 2 Ukončenie splnomocnenia')}
						onSubmit={this.stepOneClickHandler}
						onDeleteFile={this.onDeleteFile}
						onBackClick={() => {
							history.push(setRouteParams(VZTAHY_ZOZNAM, get(interakcia, 'opCislo')))
						}}
						schvalovacieKriteria={schvalovacieKriteria}
						validacneKriteria={validacneKriteria}
						originalValues={originalValues}
					/>
				)
				break
			case 2: {
				// check if scenar is define
				const customizedScenarioOption = {
					...scenarioOption,
					scenarios: filter(get(scenarioOption, 'scenarios', []), (scenar) => {
						let allowedRoles = get(scenar, 'allowedRoles', [])
						return (
							!includes(dissallowedScenarios, get(scenar, 'typ')) &&
							(isEmpty(get(scenar, 'allowedRoles', [])) || checkPermissions(get(auth, 'user.roles'), allowedRoles))
						)
					})
				}

				// if notification is required and not selected yet
				let isDisabledNotification = get(customizedScenarioOption, 'notificationRequired') && !get(notification, 'typ')

				// for scenarios VYTLACIT_A_NAHRAT and PODPISANIE_NA_MIESTE is required signedPDF
				let isDisabledSignedPdf = true
				if (
					(find(get(customizedScenarioOption, 'scenarios'), { typ: SCENARE.VYTLACIT_A_NAHRAT }) ||
						find(get(customizedScenarioOption, 'scenarios'), { typ: SCENARE.PODPISANIE_NA_MIESTE })) &&
					get(signedPdf, 'data.dataAsBase64')
				) {
					isDisabledSignedPdf = false
				} else if (
					find(get(customizedScenarioOption, 'scenarios'), { typ: SCENARE.ODOSLAT_BEZ_PODPISU }) ||
					find(get(customizedScenarioOption, 'scenarios'), { typ: SCENARE.ODOSLAT_NA_PODPIS })
				) {
					isDisabledSignedPdf = false
				}

				const isDisabledScenarNotSelected = get(customizedScenarioOption, 'scenarios', []).length > get(customizedScenarioOption, 'maxAllowedScenars')
				component = (
					<SplnomocneniaConfirm
						formTitle={t('containers:SplnomocneniaCancelPage.Krok 2 z 2 Ukončenie splnomocnenia')}
						scenarioOption={customizedScenarioOption}
						onSubmit={this.stepTwoClickHandler}
						onBackClick={() => {
							this.setState({ step: 1 })
						}}
						notification={notification}
						isDisabledNotification={isDisabledNotification}
						isDisabledSignedPdf={isDisabledSignedPdf}
						isDisabledScenarNotSelected={isDisabledScenarNotSelected}
						schvalovacieKriteria={schvalovacieKriteria}
						disallowScenarios={this.disallowScenarios}
						originalValues={originalValues}
					/>
				)
				break
			}
			default:
		}

		return (
			<>
				{result && (
					<DefaultModal
						modalTitle={success ? t('containers:Odoslané') : t('containers:Chyba')}
						modalContent={result}
						leftButton={{
							onClick: this.confirmModal,
							text: t('containers:Zavrieť'),
							color: success ? 'green' : 'red'
						}}
						visible
					/>
				)}
				{isLoading && <ElementLoading />}
				{component}
				<ProcesnyKonfigurator
					koKriteriaHandler={this.koKriteriaHandler}
					validacneKriteriaHandler={this.validacneKriteriaHandler}
					schvalovacieKriteriaHandler={this.schvalovacieKriteriaHandler}
					formatDataForUkon={this.formatDataForUkon}
					context={context}
				/>
			</>
		)
	}

	loadingContentElement = () => <ElementLoading />

	failureContentElement = () => {
		const { t } = this.props

		return <div>{t('containers:SplnomocneniaCancelPage.Nepodarilo sa načítať splnomocnenia')}</div>
	}

	disallowScenarios = (scenarios) =>
		this.setState({
			disallowedScenarios: scenarios
		})
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail.data,
	auth: state.auth,
	tracking: state.tracking,
	ukonNovy: state.ukony.ukonNovy,
	ciselniky: state.ciselniky,
	signedPdf: state.podpisovanieDokumentov.signedPdf,
	notification: state.podpisovanieDokumentov.notification,
	templatePdf: state.podpisovanieDokumentov.templatePdf,
	procesnyKonfigurator: state.procesnyKonfigurator.procesnyKonfigurator,
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {}),
	vztahDetail: state.obchodnyPartner.vztahDetail
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch),
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.UKON_SPLNOMOCNENIE])
)(SplnomocneniaCancelPage)
