import { cloneDeep } from 'lodash'
import { FORM_ADDRESSES_INIT, FORM_ADDRESSES_UPDATE, FORM_ADDRESSES_PUSH, FORM_ADDRESSES_CLEAN } from '../types/formAddresses'

export const formAddressesInit = (addresses) => (dispatch) => {
	dispatch({ type: FORM_ADDRESSES_INIT, payload: { addresses } })
}

export const formAddressUpdate = (address) => (dispatch) => {
	dispatch({ type: FORM_ADDRESSES_UPDATE, payload: { address: cloneDeep(address) } })
}

export const formAddressPush = (address) => (dispatch) => {
	dispatch({ type: FORM_ADDRESSES_PUSH, payload: { address: cloneDeep(address) } })
}

export const formAddressClean = () => (dispatch) => {
	dispatch({ type: FORM_ADDRESSES_CLEAN })
}
