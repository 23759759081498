/* eslint-disable no-new-func */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { bindActionCreators } from 'redux'
import { map, get } from 'lodash'

import * as ProcesnyKonfiguratorActions from '../actions/ProcesnyKonfigurator/ProcesnyKonfiguratorActions'
import { setErrorMsgToLocalStorage } from '../utils/errorHandler'
import { CRASH_ERROR_TYPES } from '../utils/enums'

export default (formName, type) => {
	class ProcesnyKonfigurator extends React.Component {
		constructor(props) {
			super(props)
			this.state = {}
		}

		componentDidMount() {
			this.props.procesnyKonfiguratorActions.loadProcesnyKonfigurator()
		}

		// eslint-disable-next-line
		static getDerivedStateFromProps(props, state) {
			if (!props.procesnyKonfigurator || !props.procesnyKonfigurator.ukony || !props.procesnyKonfigurator.ukony[type] || !props.context) {
				props.koKriteriaHandler(false, [])
				props.validacneKriteriaHandler([])
				props.schvalovacieKriteriaHandler([])
				return null
			}

			// eslint-disable-next-line
			const { koKriteria, schvalovacieKriteria, validacneKriteria, standardneAtributy, ctx } = props.procesnyKonfigurator.ukony[type]
			const code = get(props.context, 'ukon.data.produkt.kod')
			const productType = code && code.split('.')[1]
			const context = {
				...props.context,
				...standardneAtributy,
				ukon: {
					...props.formatDataForUkon(props.formValues),
					...props.formatDataForUkon(props.formValues).ukon,
					...(productType === 'M' && {
						// ak je tarif mesacny treba nastavit zalohy prepocitane z BB
						zalohy: {
							...props.formatDataForUkon(props.formValues).data.zalohy,
							zalohySuma: get(
								props.context,
								'ukon.data.zalohy.zalohySuma',
								get(props.formatDataForUkon(props.formValues), 'data.zalohy.zalohySuma', null)
							)
						}
					})
				},
				ukonData: {
					...props.context.ukonData,
					...props.formatDataForUkon(props.formValues).data,
					...props.formatDataForUkon(props.formValues).ukonData,
					...(productType === 'M' && {
						// ak je tarif mesacny treba nastavit zalohy prepocitane z BB
						zalohy: {
							...props.formatDataForUkon(props.formValues).data.zalohy,
							zalohySuma: get(
								props.context,
								'ukon.data.zalohy.zalohySuma',
								get(props.formatDataForUkon(props.formValues), 'data.zalohy.zalohySuma', null)
							)
						}
					})
				}
			}

			try {
				const koKriteriaResults = map(koKriteria, (value, key) => {
					const koKriteriumFunction = new Function('ctx', value)
					const { vysledok, popis } = koKriteriumFunction(context)
					return {
						nazov: key,
						popis,
						vysledok
					}
				})
				props.koKriteriaHandler(true, koKriteriaResults)
			} catch (e) {
				// eslint-disable-next-line
				console.log('KO exception', e)

				// log procesny konfigurator error
				const errorMsg = { msg: `Procesny konfigurator koKriterium ${e.toString()}`, stack: e.stack }
				setErrorMsgToLocalStorage(CRASH_ERROR_TYPES.APPLICATION, errorMsg)
				const koKriteriaResults = [
					{
						nazov: 'Ko kriterium',
						popis: 'Nepodarilo sa vyhodnotit konfiguráciu.',
						vysledok: false
					}
				]
				props.koKriteriaHandler(true, koKriteriaResults)
			}

			try {
				const validacneKriteriaResults = map(validacneKriteria, (value, key) => {
					const validacneKriteriumFunction = new Function('ctx', value)
					const { vysledok, popis, statusTyp } = validacneKriteriumFunction(context)
					return {
						vysledok,
						popis,
						nazov: key,
						statusTyp
					}
				})
				props.validacneKriteriaHandler(validacneKriteriaResults)
			} catch (e) {
				// eslint-disable-next-line
				console.log('VAL e', e)

				// log procesny konfigurator error
				const errorMsg = { msg: `Procesny konfigurator validacnekriterium ${e.toString()}`, stack: e.stack }
				setErrorMsgToLocalStorage(CRASH_ERROR_TYPES.APPLICATION, errorMsg)
				const validacneKriteriaResults = [
					{
						nazov: 'Validačné kritérium',
						popis: 'Nepodarilo sa vyhodnotiť konfiguráciu.',
						vysledok: false
					}
				]
				props.validacneKriteriaHandler(validacneKriteriaResults)
			}
			try {
				const schvalovacieKriteriaResults = map(schvalovacieKriteria, (value, key) => {
					const schvalovacieKriteriumFunction = new Function('ctx', value)
					const { vysledok, popis, urovenSchvalovania } = schvalovacieKriteriumFunction(context)
					return {
						urovenSchvalovania,
						vysledok,
						popis,
						nazov: key
					}
				})
				props.schvalovacieKriteriaHandler(schvalovacieKriteriaResults)
			} catch (e) {
				// eslint-disable-next-line
				console.log('Schval e', e)

				// log procesny konfigurator error
				const errorMsg = { msg: `Procesny konfigurator validacnekriterium ${e.toString()}`, stack: e.stack }
				setErrorMsgToLocalStorage(CRASH_ERROR_TYPES.APPLICATION, errorMsg)
				const schvalovacieKriteriaResults = [
					{
						nazov: 'Schvaľovacie kritérium',
						popis: 'Nepodarilo sa vyhodnotiť konfiguráciu.',
						error: true
					}
				]
				props.schvalovacieKriteriaHandler(schvalovacieKriteriaResults)
			}
			return null
		}

		render() {
			return null
		}
	}
	ProcesnyKonfigurator.propTypes = {
		procesnyKonfiguratorActions: PropTypes.shape({
			loadProcesnyKonfigurator: PropTypes.func.isRequired
		}).isRequired,
		koKriteriaHandler: PropTypes.func.isRequired,
		validacneKriteriaHandler: PropTypes.func.isRequired
	}

	const mapDispatchToProps = (dispatch) => {
		return {
			procesnyKonfiguratorActions: bindActionCreators(ProcesnyKonfiguratorActions, dispatch),
			dispatch
		}
	}

	const mapStateToProps = (state) => {
		return {
			formValues: getFormValues(formName)(state),
			procesnyKonfigurator: state.procesnyKonfigurator.procesnyKonfigurator.data
		}
	}

	return connect(mapStateToProps, mapDispatchToProps)(ProcesnyKonfigurator)
}
