import {
	OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_START,
	OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_DONE,
	OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_FAIL
} from '../../types/obchodnyPartner'
import { getReq } from '../../utils/request'

export const rozpisZalohLoadStart = () => {
	return {
		type: OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_START
	}
}

export const rozpisZalohLoadDone = (rozpisZaloh) => {
	return {
		type: OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_DONE,
		payload: {
			rozpisZaloh
		}
	}
}

export const rozpisZalohLoadFail = () => {
	return {
		type: OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_FAIL
	}
}

export const loadRozpisZaloh = (rozpisZalohCislo) => {
	return async (dispatch, getStore) => {
		try {
			const store = getStore()
			const interakcia = store.interakcie.detail
			dispatch(rozpisZalohLoadStart())

			const res = await getReq(`/api/v2/op/${interakcia.data.opCislo}/rozpisy-zaloh/${rozpisZalohCislo}`)
			const rozpisZaloh = res.response.content
			dispatch(rozpisZalohLoadDone(rozpisZaloh))
			return rozpisZaloh
		} catch (e) {
			dispatch(rozpisZalohLoadFail())
		}
	}
}
