import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
/*
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
*/

import Header from './Header'
import UserSubHeader from '../../components/UserSubheader'
import SidebarMenu from '../../components/Sidebars/SidebarMenu'
import SidebarHistory from '../../components/Sidebars/SidebarHistory'

class MainLayout extends React.Component {
	static propTypes = {
		page: PropTypes.string
	}

	constructor(props) {
		super(props)

		this.state = {
			sidebarOpened: true
		}
	}

	toggleSidebar = () => {
		this.setState({
			sidebarOpened: !this.state.sidebarOpened
		})
	}

	render() {
		const btnEndInteractionDisabled = window.location.pathname.search(/koniec-interakcie/g) === -1 ? '' : 'disabled'

		return (
			<div
				style={{ height: '100%' }}
				data-page={this.props.page}
				className={cx({
					'menu-sidebar-opened': this.state.sidebarOpened,
					'history-sidebar-opened': this.state.sidebarOpened
				})}
			>
				<Header page={this.props.page} />
				<UserSubHeader btnEndInteractionDisabled={btnEndInteractionDisabled} />

				<div className='content main-layout'>
					<SidebarMenu page={this.props.page} sidebarOpened={this.state.sidebarOpened} />
					<SidebarHistory {...this.props} toggleSidebar={this.toggleSidebar} />
					<div className='main-content'>{this.props.children}</div>
					<div className={cx('sidebar-toggle', { shown: !this.state.sidebarOpened })} onClick={this.toggleSidebar}>
						<span>História interakcií</span>
					</div>
				</div>
			</div>
		)
	}
}

export default MainLayout
/*
const mapStateToProps = state => ({
	auth: state.auth
})

const mapDispatchToProps = dispatch => ({
	a: bindActionCreators({}, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
*/
