import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { get } from 'lodash'

// config
import { COLUMNS_COUNT } from '../../../containers/GenericUkon/attributesConfig'

// utils
import { formatDate } from '../../../utils/date'

class PozadovanyDatumField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		auth: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired,
		max: PropTypes.string,
		min: PropTypes.string,
		vyluceneDni: PropTypes.arrayOf(PropTypes.string)
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	render() {
		const { columnsCount = COLUMNS_COUNT.THREE, t, value, originalValue } = this.props

		let valueBefore = formatDate(originalValue, null, 'DD.MM.YYYY')
		let valueAfter = formatDate(value, null, 'DD.MM.YYYY')
		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Požadovaný dátum')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
							<td>{valueAfter}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(PozadovanyDatumField)
