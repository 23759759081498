import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

class Switch extends React.Component {
	constructor(props) {
		super(props)
	}

	handleChange = () => {
		this.props.onChange(!this.props.isChecked)
	}

	render() {
		const { isChecked, label, disabled } = this.props

		return (
			<div className='boolean-switch'>
				<div className='boolean-switch-label'>{label}</div>
				<button className={cx('boolean-switch-button', { 'boolean-switch-active': isChecked })} onClick={this.handleChange} disabled={disabled}>
					<span className='boolean-switch-handle' />
				</button>
			</div>
		)
	}
}

Switch.propTypes = {
	isChecked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	disabled: PropTypes.bool
}

export default Switch
