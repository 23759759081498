import { isEmpty } from 'lodash'

const validate = (values, props) => {
	const errors = {}

	if (isEmpty(values.preplatky)) {
		errors.selectedFaktury = props.t('translation:Preplatky.validate.Výber preplatkov na vrátenie je povinný')
	}

	return errors
}

export default validate
