import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'
import { get, map, head, find, upperFirst } from 'lodash'
import { withTranslation } from 'react-i18next'

// components
import ZmluvnyVztahDetail from '../Modals/ZmluvnyVztahDetail'

// utils
import { formatAddressCityFirst, miestoSpotrebyAdresaDoplnokFormat } from '../../utils/address'
import { getKomoditaTypIcon } from '../../utils/odberneMiesta'

class MiestaSpotrebySearchRow extends React.Component {
	static propTypes = {
		checkRows: PropTypes.bool,
		selectedAdresaIdIsu: PropTypes.number,
		selectedMsCislo: PropTypes.string,
		odberneMiesta: PropTypes.shape(),
		miestaSpotreby: PropTypes.arrayOf(PropTypes.shape()),
		komoditaTyp: PropTypes.shape(),
		onClick: PropTypes.func.isRequired,
		onClickCheckbox: PropTypes.func,
		disabledCheckbox: PropTypes.bool,
		disabledCheckboxText: PropTypes.string,
		showOnlyAvailable: PropTypes.bool,
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			zmluvnyVztahModal: null
		}
	}

	handleClickMiestoSpotreby = (omCislo, komoditaTyp) => {
		this.setState({
			zmluvnyVztahModal: {
				showModal: true,
				komoditaTyp,
				omCislo
			}
		})
	}

	render() {
		const {
			checkRows,
			selectedAdresaIdIsu,
			selectedMsCislo,
			odberneMiesta,
			miestaSpotreby,
			komoditaTyp,
			onClick,
			onClickCheckbox,
			disabledCheckbox,
			disabledCheckboxText,
			showOnlyAvailable,
			t
		} = this.props
		const { zmluvnyVztahModal } = this.state

		const adresa = get(head(miestaSpotreby), 'adresa')

		if (selectedAdresaIdIsu && selectedAdresaIdIsu != get(adresa, 'idIsu')) {
			return null
		}

		let addressCheckbox = (
			<button
				type='button'
				style={{ marginTop: '0px' }}
				className='button pull-right'
				data-type='outline'
				data-color='blue'
				disabled={disabledCheckbox}
				onClick={() =>
					onClickCheckbox({
						type: 'ADDRESS',
						data: adresa
					})
				}
			>
				{get(adresa, 'idIsu') == selectedAdresaIdIsu && !selectedMsCislo ? t('translation:Common.Zrušiť') : t('translation:Common.Nové MS')}
			</button>
		)

		if (disabledCheckbox && disabledCheckboxText) {
			addressCheckbox = (
				<Tooltip html={<span>{disabledCheckboxText}</span>} position='left' trigger='mouseenter' theme='light'>
					{addressCheckbox}
				</Tooltip>
			)
		} else {
			addressCheckbox = (
				<Tooltip
					html={<span>{t('translation:Common.Vytvorí nové odberné miesto a nové miesto spotreby na danej adrese (prípojnom objekte)')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{addressCheckbox}
				</Tooltip>
			)
		}

		let formattedAdresa = formatAddressCityFirst(adresa) || '-'
		if (get(adresa, 'budova')) {
			formattedAdresa += ` - ${upperFirst(get(adresa, 'budova.nazov') || get(adresa, 'budova.id'))}`
		}

		let modal
		if (get(zmluvnyVztahModal, 'showModal')) {
			modal = (
				<ZmluvnyVztahDetail
					modalTitle={t('translation:Common.Zmluvný vzťah (posledný)')}
					onCloseButton={() =>
						this.setState({
							zmluvnyVztahModal: null
						})
					}
					komoditaTyp={get(zmluvnyVztahModal, 'komoditaTyp')}
					omCislo={get(zmluvnyVztahModal, 'omCislo')}
				/>
			)
		}
		return (
			<>
				<tr key={`adresa-${get(adresa, 'idIsu')}`} onClick={onClick}>
					<td>
						<strong>{formattedAdresa}</strong>
					</td>
					{checkRows && <td className='button-column'>{addressCheckbox}</td>}
				</tr>
				{(!selectedAdresaIdIsu || selectedMsCislo) &&
					map(miestaSpotreby, (miestoSpotreby) => {
						const msCislo = get(miestoSpotreby, 'cislo')

						if (selectedMsCislo && selectedMsCislo != msCislo) {
							return null
						}

						const komoditnaZmluva = find(get(odberneMiesta, msCislo), (odberneMiesto) => {
							return get(odberneMiesto, 'komoditaTyp.id') == get(komoditaTyp, 'id')
						})

						let disabledMsCheckbox
						let disabledMsCheckboxText
						if (komoditnaZmluva) {
							if (showOnlyAvailable) {
								return null
							}
							disabledMsCheckbox = true
							disabledMsCheckboxText = t('translation:Common.Na mieste spotreby už existuje komoditná zmluva')
						}
						let msCheckbox = (
							<button
								type='button'
								style={{ marginTop: '0px' }}
								className='button pull-right'
								data-type='outline'
								data-color='blue'
								disabled={disabledMsCheckbox}
								onClick={() =>
									onClickCheckbox({
										type: 'MS',
										data: miestoSpotreby
									})
								}
							>
								{msCislo == selectedMsCislo ? t('translation:Common.Zrušiť') : t('translation:Common.Nové OM')}
							</button>
						)

						if (disabledMsCheckbox && disabledMsCheckboxText) {
							msCheckbox = (
								<Tooltip html={<span>{disabledMsCheckboxText}</span>} position='left' trigger='mouseenter' theme='light'>
									{msCheckbox}
								</Tooltip>
							)
						} else {
							msCheckbox = (
								<Tooltip
									html={<span>{t('translation:Common.Vytvorí nové odberné miesto na existujúcom mieste spotreby')}</span>}
									position='left'
									trigger='mouseenter'
									theme='light'
								>
									{msCheckbox}
								</Tooltip>
							)
						}
						const adresaDoplnok = miestoSpotrebyAdresaDoplnokFormat(miestoSpotreby)
						return (
							<tr key={`miesto-spotreby-${msCislo}`}>
								<td style={{ paddingLeft: '25px' }}>
									<div
										style={{
											minWidth: '250px',
											marginRight: '10px',
											display: 'inline-block'
										}}
									>
										{msCislo}
										{adresaDoplnok ? ` (${adresaDoplnok})` : ''}
									</div>
									{map(get(odberneMiesta, msCislo), (odberneMiesto) => {
										return getKomoditaTypIcon(odberneMiesto, this.handleClickMiestoSpotreby, t)
									})}
								</td>
								{checkRows && (
									<td className='button-column' onClick={onClick}>
										{!disabledMsCheckbox && msCheckbox}
									</td>
								)}
							</tr>
						)
					})}
				{modal}
			</>
		)
	}
}

export default compose(withTranslation('components'))(MiestaSpotrebySearchRow)
