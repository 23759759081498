import { UPDATE_ZMLUVNE_VZTAHY_CONFIG, CLEAR_ZMLUVNE_VZTAHY_CONFIG } from '../types/zmluvneVztahyConfigTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	miestaSpotreby: {}
}

export default (state = initState, action) => {
	switch (action.type) {
		case UPDATE_ZMLUVNE_VZTAHY_CONFIG:
			return {
				...state,
				miestaSpotreby: {
					...state.miestaSpotreby,
					...action.payload
				}
			}
		case CLEAR_ZMLUVNE_VZTAHY_CONFIG:
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
