import { DATA_CHANGED, LEAVE_PAGE_MODAL_SHOW, LEAVE_PAGE_MODAL_HIDE } from '../types/data'

export const dataChanged = (selector, data) => (dispatch) => {
	dispatch({
		type: DATA_CHANGED,
		payload: {
			selector,
			data
		}
	})
}
export const registerLeavePageModal = () => (dispatch) => {
	dispatch({
		type: LEAVE_PAGE_MODAL_SHOW
	})
}

export const unregisterLeavePageModal = () => (dispatch) => {
	dispatch({
		type: LEAVE_PAGE_MODAL_HIDE
	})
}
