import React from 'react'
import Creatable from 'react-select/creatable'
import { throttle, isEmpty, get } from 'lodash'
import PropTypes from 'prop-types'

const FONT_COLOR = '#191919'

const DEFAULT_STYLES = {
	placeholder: (provided) => ({
		...provided,
		color: FONT_COLOR
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		backgroundColor: FONT_COLOR
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		color: FONT_COLOR
	})
}

class AcwSelector extends React.Component {
	constructor(props) {
		super(props)
		this.selectRef = React.createRef()
		this.state = {
			open: false,
			customOptions: []
		}
	}

	handleMenuOpen = () => {
		this.setState({
			open: true
		})
	}

	handleBlur = () => {
		const focusedElement = document.activeElement
		if (focusedElement && !focusedElement.className.includes('react-select')) {
			this.setState({
				open: false
			})
		} else {
			throttle(this.selectRef.current.focus(), 150)
		}
	}

	handleChange = (option) => {
		this.setState({
			open: false
		})

		this.props.onChange(option.value)
	}

	formatOptions = (acwOptions) => {
		const { customOptions } = this.state

		const propsOptions = acwOptions.map((option) => {
			return { value: option.nazov, label: option.nazov }
		})

		return [...propsOptions, ...customOptions]
	}

	formatValue = (value) => {
		if (!value) return null

		return { value, label: value }
	}

	handleCreate = (value) => {
		const newOption = this.formatValue(value)

		const { customOptions } = this.state
		this.setState({
			customOptions: [...customOptions, newOption]
		})

		// NOTE: CP-2189
		this.handleChange(newOption)
	}

	render() {
		const { open } = this.state
		const { acwOptions, acwValue } = this.props

		let styles = DEFAULT_STYLES

		if (isEmpty(acwValue)) {
			styles = {
				...DEFAULT_STYLES,
				control: (provided) => ({
					...provided,
					backgroundColor: '#FFB6B6 !important',
					boxShadow: 'none !important'
				})
			}
		}

		return (
			<div className='select-wrapper'>
				<Creatable
					value={this.formatValue(acwValue)}
					onChange={this.handleChange}
					handleCreate={this.handleCreate}
					onCreateOption={this.handleCreate}
					placeholder={'Odpoveď'}
					options={this.formatOptions(acwOptions)}
					classNamePrefix='react-select'
					formatCreateLabel={(input) => 'Uviesť odpoveď:' + ` "${input}"`}
					cacheOptions={false}
					styles={styles}
					// NOTE: next lines are for IE hot fix https://github.com/JedWatson/react-select/issues/1020
					onMenuOpen={this.handleMenuOpen}
					menuIsOpen={open}
					onBlur={this.handleBlur}
					ref={this.selectRef}
				/>
			</div>
		)
	}
}

AcwSelector.propTypes = {
	acwValue: PropTypes.string.isRequired,
	acwOptions: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired
}

export default AcwSelector
