import React from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'

function DefaultModal(props) {
	const { modalContent, modalTitle, leftButton, rightButton, visible } = props
	return (
		<Modal size='m' shown={!!visible}>
			{modalTitle && (
				<div className='modal-header'>
					<h3>{modalTitle}</h3>
				</div>
			)}
			{modalContent && <div className='modal-content'>{modalContent}</div>}
			<div className='modal-footer clearfix'>
				{rightButton && rightButton.text && (
					<button
						className={`button pull-right ${rightButton.disabled ? 'disabled' : ''}`}
						onClick={() => {
							rightButton && rightButton.onClick ? rightButton.onClick() : () => {}
						}}
						data-type={rightButton && rightButton.outline ? 'outline' : ''}
						data-color={(rightButton && rightButton.color) || 'red'}
						disabled={rightButton.disabled || false}
					>
						{rightButton ? rightButton.text : ''}
					</button>
				)}
				{leftButton.text && (
					<button
						className={`button pull-right ${leftButton.disabled ? 'disabled' : ''}`}
						onClick={() => {
							leftButton.onClick ? leftButton.onClick() : () => {}
						}}
						data-type={leftButton.outline ? 'outline' : ''}
						data-color={leftButton.color || 'blue'}
						disabled={leftButton.disabled || false}
					>
						{leftButton.text}
					</button>
				)}
			</div>
		</Modal>
	)
}

DefaultModal.propTypes = {
	modalTitle: PropTypes.string,
	modalContent: PropTypes.string,
	visible: PropTypes.bool,
	leftButton: PropTypes.shape({
		text: PropTypes.shape.isRequired,
		onClick: PropTypes.func,
		color: PropTypes.string,
		outline: PropTypes.bool
	}).isRequired,
	rightButton: PropTypes.shape({
		text: PropTypes.shape.isRequired,
		onClick: PropTypes.func,
		color: PropTypes.string,
		outline: PropTypes.bool
	})
}

DefaultModal.defaultProps = {
	leftButton: {
		onClick: () => {},
		color: 'blue',
		outline: false
	},
	rightButton: {
		onClick: () => {},
		color: 'red',
		outline: false
	}
}

export default DefaultModal
