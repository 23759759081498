import { get } from 'lodash'

import { getReq } from '../../utils/request'
import { OBCHODNY_PARTNER_UPOMIENKY_LOAD_DONE, OBCHODNY_PARTNER_UPOMIENKY_LOAD_FAIL, OBCHODNY_PARTNER_UPOMIENKY_LOAD_START } from '../../types/obchodnyPartner'

export const loadUpomienky = (page = 1, queryParams = {}) => {
	return async (dispatch, getStore) => {
		try {
			const store = getStore()
			const interakcia = store.interakcie.detail.data
			const previousUpomienky = store.obchodnyPartner.upomienky.data
			dispatch({
				type: OBCHODNY_PARTNER_UPOMIENKY_LOAD_START,
				payload: { page }
			})

			const query = {
				stranka: page,
				velkostStranky: 20,
				...queryParams
			}

			const res = await getReq(`/api/v0/obchodni-partneri/${interakcia.opCislo}/upomienky`, query)

			const upomienkyData = res.response.obsah

			const zaznamov = get(res, 'response.strankovanie.zaznamov', Number.MAX_SAFE_INTEGER)
			const aktualnaStranka = get(res, 'response.strankovanie.aktualnaStranka', 0)
			const velkostStranky = get(res, 'response.strankovanie.velkostStranky', 0)

			const isLastPage = !(velkostStranky * aktualnaStranka < zaznamov)

			const upomienky = page === 1 ? upomienkyData : [...previousUpomienky, ...upomienkyData]

			dispatch({
				type: OBCHODNY_PARTNER_UPOMIENKY_LOAD_DONE,
				payload: {
					upomienky,
					isLastPage,
					lastLoad: new Date()
				}
			})
			return upomienky
		} catch (err) {
			dispatch({
				type: OBCHODNY_PARTNER_UPOMIENKY_LOAD_FAIL
			})
		}
	}
}
