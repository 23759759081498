import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import { Tooltip } from 'react-tippy'
import { NumericFormat } from 'react-number-format'

// utils
import config from '../../utils/config'
import { formatDate } from '../../utils/date'
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'
import { setRouteParams, POHLADAVKY_ZOZNAM, FAKTURY_DETAIL } from '../../utils/routes'
import { getAccessToken } from '../../utils/auth'

function FakturaRow(props) {
	const { interakcia, faktura, onClickUkonUC03, t, isMOO } = props

	const backUrl = btoa(`${window.location.pathname}${window.location.search}`)

	const formatTypFaktury = () => {
		if (get(faktura, 'zdrojDat.id') == 2 && get(faktura, 'fakturaTyp.id') == 1 && isMOO) {
			return '3v1'
		}

		return get(faktura, 'fakturaTyp.nazov', '-')
	}

	const accessToken = getAccessToken()
	const xmlDocument = faktura?.xmlDokumentId ? `${config.baseUrl}/api/v0/dokumenty/${faktura?.xmlDokumentId}?accessToken=${accessToken}` : null

	return (
		<tr>
			<td>{get(faktura, 'cislo', '-')}</td>
			<td>{formatDate(get(faktura, 'vystavenieDatum'), '-', 'DD.MM.YYYY')}</td>
			<td>{formatTypFaktury()}</td>
			<td>{get(faktura, 'zmluvnyUcetCislo', '-')}</td>
			<td style={{ whiteSpace: 'nowrap' }}>
				<NumericFormat
					thousandSeparator={' '}
					decimalSeparator={','}
					decimalScale={2}
					fixedDecimalScale={true}
					displayType='text'
					suffix=' €'
					value={get(faktura, 'sumaSpolu')}
					defaultValue='-'
				/>
			</td>
			<td>{formatDate(get(faktura, 'splatnostDatum'), '-', 'DD.MM.YYYY')}</td>
			<td>{get(faktura, 'variabilnySymbol', '-')}</td>
			<td>
				{get(faktura, 'zdrojDat.id') == 2 && 'SAP'}
				{get(faktura, 'zdrojDat.id') == 4 && 'Flores'}
			</td>
			<td style={{ textAlign: 'right' }}>
				{xmlDocument && (
					<Tooltip
						html={t('containers:FakturaPage.Zobraziť faktúru vo formáte XML v novom okne')}
						position='bottom'
						trigger='mouseenter'
						theme='light'
					>
						<Link
							to={xmlDocument}
							target='_blank'
							className='button'
							data-type='icon-button'
							rel='noreferrer'
							style={{
								marginRight: '10px',
								padding: '14px 3px',
								width: '30px',
								fontSize: '10px',
								lineHeight: 0
							}}
						>
							XML
						</Link>
					</Tooltip>
				)}
				{get(faktura, 'dokumentId') && (
					<Tooltip html={t('containers:FakturaPage.Detail faktúry')} position='bottom' trigger='mouseenter' theme='light'>
						<Link
							to={setRouteParams(FAKTURY_DETAIL, get(interakcia, 'data.opCislo'), get(faktura, 'cislo'))}
							className='button'
							data-type='icon-button'
							data-icon='view'
							style={{ marginRight: '10px' }}
						/>
					</Tooltip>
				)}
				{get(faktura, 'dokumentId') && (
					<Permissions
						allowed={[PERMISSIONS.UKON_DUPLIKAT_FAKTURY]}
						render={(hasPerm, actions) => {
							return (
								<Tooltip html={t('containers:FakturaPage.Zaslanie duplikátu faktúry')} position='bottom' trigger='mouseenter' theme='light'>
									<a
										className='button'
										style={{ marginRight: '10px' }}
										data-type='icon-button'
										data-icon='send'
										onClick={(e) => {
											if (!hasPerm) {
												e.preventDefault()
												actions.openForbiddenModal()
											} else {
												onClickUkonUC03()
											}
										}}
									/>
								</Tooltip>
							)
						}}
					/>
				)}
				{get(faktura, 'cislo') && (
					<Permissions
						allowed={[PERMISSIONS.VIEW_POHLADAVKY]}
						render={(hasPerm, actions) => {
							return (
								<Tooltip html={t('translation:Common.Preklik na záložku Stav účtu')} position='bottom' trigger='mouseenter' theme='light'>
									<Link
										to={`${setRouteParams(POHLADAVKY_ZOZNAM, get(interakcia, 'data.opCislo'))}?variabilnySymbol=${get(
											faktura,
											'cislo'
										)}&backUrl=${backUrl}`}
										className='button'
										data-type='icon-button'
										data-icon='pig'
										onClick={(e) => {
											if (!hasPerm) {
												e.preventDefault()
												actions.openForbiddenModal()
											}
										}}
									/>
								</Tooltip>
							)
						}}
					/>
				)}
			</td>
		</tr>
	)
}

FakturaRow.propTypes = {
	interakcia: PropTypes.shape({
		data: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired
	}).isRequired,
	onClickUkonUC03: PropTypes.func.isRequired,
	faktura: PropTypes.shape({
		zmluvnyUcetCislo: PropTypes.string.isRequired,
		cislo: PropTypes.string.isRequired,
		sumaSpolu: PropTypes.number,
		vystavenieDatum: PropTypes.string.isRequired,
		splatnostDatum: PropTypes.string.isRequired,
		variabilnySymbol: PropTypes.string.isRequired,
		fakturaTyp: PropTypes.shape({
			nazov: PropTypes.string
		})
	}).isRequired,
	t: PropTypes.func.isRequired,
	isMOO: PropTypes.bool
}

export default FakturaRow
