import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import ElementFailure from '../components/ElementFailure'

import { setRouteParams, PREHLAD, INDEX } from '../utils/routes'

function ForbiddenPage(props) {
	let buttonBack = null
	if (props.interakcia && props.interakcia.data) {
		buttonBack = (
			<Link to={setRouteParams(PREHLAD, get(props.interakcia, 'data.opCislo'))} className='button' data-color='blue'>
				{' '}
				Späť na prehľad
			</Link>
		)
	} else {
		buttonBack = (
			<Link to={INDEX} className='button' data-color='blue'>
				{' '}
				Späť na vyhľadávanie
			</Link>
		)
	}

	return (
		<div className='forbidden-page'>
			<h4>403 Forbidden</h4>

			<ElementFailure size='lg' text='Na vykonanie akcie nemáte potrebné oprávnenia.' />

			<div className='content'>{buttonBack}</div>
		</div>
	)
}

ForbiddenPage.propTypes = {
	interakcia: PropTypes.shape()
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail
})

export default connect(mapStateToProps)(ForbiddenPage)
