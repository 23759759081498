import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

class ElementLoading extends React.Component {
	static propTypes = {
		backgroundColor: PropTypes.string
	}

	render() {
		const { backgroundColor } = this.props
		const style = {}
		if (backgroundColor) {
			style.backgroundColor = backgroundColor
		}

		return (
			<div className={cx('element-loading', 'shown')} style={style}>
				<div className='loader' />
			</div>
		)
	}
}

export default ElementLoading
