import React from 'react'
import PropTypes from 'prop-types'
import { get, find } from 'lodash'
import { NumericFormat } from 'react-number-format'
import { Tooltip } from 'react-tippy'

// atoms
import CheckBoxField from '../../atoms/CheckboxField'

function FakturaSearchResultRow(props) {
	const { faktura, onClickCheckbox, disabledCheckbox, disabledCheckboxText } = props

	let checkbox = (
		<CheckBoxField
			disabled={disabledCheckbox}
			input={{
				value: find(get(props, 'zdrojoveFaktury', []), (zdrojovaFaktura) => get(faktura, 'cislo') == get(zdrojovaFaktura, 'cislo')),
				onChange: onClickCheckbox,
				label: ''
			}}
		/>
	)
	if (disabledCheckbox && disabledCheckboxText) {
		checkbox = (
			<Tooltip html={<span>{disabledCheckboxText}</span>} position='left' trigger='mouseenter' theme='light'>
				{checkbox}
			</Tooltip>
		)
	}

	return (
		<tr>
			<td>{get(faktura, 'cislo', '-')}</td>
			<td>{get(faktura, 'zmluvnyUcetCislo', '-')}</td>
			<td style={{ whiteSpace: 'nowrap' }}>
				<NumericFormat
					thousandSeparator={' '}
					decimalSeparator={','}
					decimalScale={2}
					fixedDecimalScale={true}
					displayType='text'
					suffix=' €'
					value={get(faktura, 'sumaOtvorena')}
					defaultValue='-'
				/>
			</td>
			<td style={{ whiteSpace: 'nowrap' }}>
				<NumericFormat
					thousandSeparator={' '}
					decimalSeparator={','}
					decimalScale={2}
					fixedDecimalScale={true}
					displayType='text'
					suffix=' €'
					value={get(faktura, 'sumaBlokovana')}
					defaultValue='-'
				/>
			</td>
			{get(props, 'checkRows') && (
				<td>
					<div style={{ float: 'right' }}>{checkbox}</div>
				</td>
			)}
		</tr>
	)
}

FakturaSearchResultRow.propTypes = {
	faktura: PropTypes.shape({
		zmluvnyUcetCislo: PropTypes.string.isRequired,
		cislo: PropTypes.string.isRequired,
		sumaOtvorena: PropTypes.number
	}).isRequired,
	onClickCheckbox: PropTypes.func,
	t: PropTypes.func.isRequired,
	disabledCheckbox: PropTypes.bool,
	disabledCheckboxText: PropTypes.string
}

export default FakturaSearchResultRow
