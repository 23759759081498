import { SELECTED_FILTERS_CHANGED } from '../types/selectedFilters'

export const filtersChangedCreator = (selector, filters) => {
	return {
		type: SELECTED_FILTERS_CHANGED,
		payload: {
			selector,
			selectedFilters: filters
		}
	}
}

export const selectedFiltersChanged = (selector, filters) => (dispatch) => {
	dispatch(filtersChangedCreator(selector, filters))
}
