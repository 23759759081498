import {
	OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_START,
	OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_DONE,
	OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_FAIL,
	OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_START,
	OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_FAIL,
	OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_DONE
} from '../../types/obchodnyPartner'

import { getReq } from '../../utils/request'

export function podradeneSuhlasyLoadStart() {
	return {
		type: OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_START
	}
}

export function podradeneSuhlasyLoadDone(podradeneSuhlasy) {
	return {
		type: OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_DONE,
		payload: {
			podradeneSuhlasy
		}
	}
}

export function podradeneSuhlasyLoadFail() {
	return {
		type: OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_FAIL
	}
}

export function aktualnaVerziaSuhlasuLoadStart() {
	return {
		type: OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_START
	}
}

export function aktualnaVerziaSuhlasuLoadDone(aktualnaVerziaSuhlasu) {
	return {
		type: OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_DONE,
		payload: {
			aktualnaVerziaSuhlasu
		}
	}
}

export function aktualnaVerziaSuhlasuLoadFail() {
	return {
		type: OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_FAIL
	}
}

export function loadPodradeneSuhlasy(definiciaId, typVyjadrenia) {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = store.interakcie.detail

		dispatch(podradeneSuhlasyLoadStart())
		try {
			const query = {
				typVyjadrenia,
				definiciaId
			}

			const res = await getReq(`/api/ces/suhlas/podradene-suhlasy/${interakcia.data.opCislo}`, query)
			const podradeneSuhlasy = res.response

			dispatch(podradeneSuhlasyLoadDone(podradeneSuhlasy))
		} catch (e) {
			dispatch(podradeneSuhlasyLoadFail())
		}
	}
}

export function loadAktualnaVerziaSuhlasu(definiciaId, kanalId) {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = store.interakcie.detail
		dispatch(aktualnaVerziaSuhlasuLoadStart())
		try {
			const query = {
				kanalId
			}

			const res = await getReq(`/api/ces/suhlas/${interakcia.data.opCislo}/${definiciaId}`, query)
			const aktualnaVerziaSuhlasu = res.response

			dispatch(aktualnaVerziaSuhlasuLoadDone(aktualnaVerziaSuhlasu))
			return aktualnaVerziaSuhlasu
		} catch (e) {
			dispatch(aktualnaVerziaSuhlasuLoadFail())
		}
	}
}
