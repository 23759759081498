import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, propTypes, getFormValues } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { map, get, isEmpty, without, find, size, uniqBy, filter } from 'lodash'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Tooltip } from 'react-tippy'

// atoms
import CheckBoxField from '../../../atoms/CheckboxField'

// components
import ElementLoading from '../../ElementLoading'
import ElementEmptyContent from '../../ElementEmptyContent'
import BlokovanieUpominaniaSearchResultRow from '../../TableRows/BlokovanieUpominaniaSearchResultRow'

import validate from './validateForm'

// utils
import { FORMS, POHLADAVKA_VYROVNANIE_STATUS, POHLADAVKA_ZDROJ_DAT } from '../../../utils/enums'

class BlokovanieUpominaniaSearch extends React.Component {
	static propTypes = {
		...propTypes,
		formValues: PropTypes.shape(),
		filters: PropTypes.shape()
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true

		const { filters, ciselniky, pohladavkyActions } = this.props

		pohladavkyActions.loadAllBlokovanieUpominania(
			{
				...filters,
				vyrovnanieStatus: POHLADAVKA_VYROVNANIE_STATUS.OTVORENA
			},
			get(ciselniky, 'pohladavkaTyp', [])
		)
	}

	componentWillUnmount() {
		this._mounted = false
	}

	selectAll = () => {
		const { change, formValues, blokovanieUpominania } = this.props

		change(
			'pohladavky',
			size(get(formValues, 'pohladavky', [])) < size(get(blokovanieUpominania, 'data', []))
				? filter(
						get(blokovanieUpominania, 'data', []),
						(pohladavka) => get(pohladavka, 'suma') > 0 && get(pohladavka, 'zdrojDat.id') == POHLADAVKA_ZDROJ_DAT.SAP_ISU
				  )
				: []
		)
	}

	formatSearchResult = () => {
		const { blokovanieUpominania, formValues, change, t } = this.props

		if (get(blokovanieUpominania, 'isLoading')) {
			return (
				<div className='cornered-loading' style={{ minHeight: '118px' }}>
					<ElementLoading />
				</div>
			)
		}

		if (get(blokovanieUpominania, 'isFailure')) {
			return (
				<div className='alert' data-color='red'>
					{t('translation:BlokovanieUpominania.Pri vyhľadávaní pohľadávok nastala chyba')}
				</div>
			)
		}

		if (isEmpty(get(blokovanieUpominania, 'data', []))) {
			return (
				<div className='box-content' style={{ minHeight: '318px' }}>
					<ElementEmptyContent
						text={t('translation:BlokovanieUpominania.Zadanému filtru nekorešponduje žiadna pohľadávka')}
						style={{ borderRadius: '5px' }}
					/>
				</div>
			)
		}

		return (
			<table className='search-results-table no-pointer' cellSpacing='0'>
				<thead>
					<tr>
						<th style={{ width: '10%' }}>{t('translation:BlokovanieUpominania.filter.Číslo faktúry')}</th>
						<th style={{ whiteSpace: 'nowrap', width: '15%' }}>{t('translation:BlokovanieUpominania.filter.Typ')}</th>
						<th style={{ width: '10%' }}>{t('translation:BlokovanieUpominania.filter.Číslo dokladu')}</th>
						<th style={{ width: '10%' }}>{t('translation:BlokovanieUpominania.filter.Dátum dokladu')}</th>
						<th style={{ width: '10%' }}>{t('translation:BlokovanieUpominania.filter.Splatnosť')}</th>
						<th style={{ textAlign: 'right', width: '10%' }}>{t('translation:BlokovanieUpominania.filter.Suma')}</th>
						<th style={{ textAlign: 'right', width: '10%' }}>{t('translation:BlokovanieUpominania.filter.Otvorená suma')}</th>
						<th style={{ width: '25%' }}>{t('translation:BlokovanieUpominania.filter.Blokovanie (od - do, dôvod)')}</th>
						<th className='checkbox-column' />
					</tr>
				</thead>
				<tbody data-empty={t('translation:Common.Nenašli sa žiadne výsledky')}>
					{map(get(blokovanieUpominania, 'data', []), (pohladavka, index) => {
						let disabledCheckbox
						let disabledCheckboxText
						if (get(pohladavka, 'suma') <= 0) {
							disabledCheckbox = true
							disabledCheckboxText = t('translation:BlokovanieUpominania.Nie je možné zvoliť preplatok')
						} else if (get(pohladavka, 'zdrojDat.id') != POHLADAVKA_ZDROJ_DAT.SAP_ISU) {
							disabledCheckbox = true
							disabledCheckboxText = t('translation:BlokovanieUpominania.Blokovanie je možné len pre pohľadávky na komoditných ZÚ')
						}
						return (
							<BlokovanieUpominaniaSearchResultRow
								checkRows
								t={t}
								key={`pohladavka_${index}`}
								pohladavka={pohladavka}
								pohladavky={get(formValues, 'pohladavky', [])}
								disabledCheckbox={disabledCheckbox}
								disabledCheckboxText={disabledCheckboxText}
								onClickCheckbox={async () => {
									const selectedPohladavka = find(
										get(formValues, 'pohladavky', []),
										(selectedPohladavka) =>
											get(pohladavka, 'cislo') == get(selectedPohladavka, 'cislo') &&
											get(pohladavka, 'typ.id') == get(selectedPohladavka, 'typ.id')
									)
									let newPohladavky = []
									if (selectedPohladavka) {
										if (!get(selectedPohladavka, 'variabilnySymbol')) {
											newPohladavky = without(get(formValues, 'pohladavky', []), selectedPohladavka)
										} else {
											newPohladavky = filter(get(formValues, 'pohladavky', []), (item) => {
												return (
													!get(item, 'variabilnySymbol') ||
													get(item, 'variabilnySymbol') != get(selectedPohladavka, 'variabilnySymbol')
												)
											})
										}
									} else if (!get(pohladavka, 'variabilnySymbol')) {
										newPohladavky = [...get(formValues, 'pohladavky', []), pohladavka]
									} else {
										newPohladavky = uniqBy(
											[
												...get(formValues, 'pohladavky', []),
												...filter(get(blokovanieUpominania, 'data', []), (item) => {
													return get(item, 'variabilnySymbol') == get(pohladavka, 'variabilnySymbol')
												})
											],
											(pohladavka) => {
												return `${get(pohladavka, 'id')}_${get(pohladavka, 'typ.id')}`
											}
										)
									}
									await change('pohladavky', newPohladavky)
								}}
							/>
						)
					})}
				</tbody>
			</table>
		)
	}

	render() {
		const { filters, formTitle, handleSubmit, invalid, onBackClick, onCancelClick, formValues, blokovanieUpominania, t } = this.props

		const disableSubmit = invalid || isEmpty(get(formValues, 'pohladavky', []))

		const submitBtn = (
			<button
				className={cx('button', 'pull-right', { disabled: disableSubmit })}
				disabled={disableSubmit}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('translation:Common.Pokračovať')}
			</button>
		)

		let wrappedSubmitBtn
		if (isEmpty(get(formValues, 'pohladavky', []))) {
			wrappedSubmitBtn = (
				<Tooltip
					className='pull-right'
					html={<span>{t('translation:BlokovanieUpominania.validate.Vyberte riadky pohľadávok na blokovanie')}</span>}
					position='top-start'
					trigger='mouseenter'
					theme='light'
				>
					{submitBtn}
				</Tooltip>
			)
		} else {
			wrappedSubmitBtn = submitBtn
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					{wrappedSubmitBtn}
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-header'>
					<div className='row'>
						<div className='col-2 header-info'>
							<span>{t('translation:Common.Zmluvný účet')}</span>
							<br />
							<strong>{get(filters, 'zmluvnyUcetCislo')}</strong>
						</div>
						<div className='col-2 header-info'>
							{get(filters, 'variabilnySymbol') && (
								<>
									<span>{t('translation:Common.Číslo faktúry')}</span>
									<br />
									<strong>{get(filters, 'variabilnySymbol')}</strong>
								</>
							)}
						</div>
						<div className='col-2' />
						<div className='col-3' />
						<div className='col-3' style={{ padding: '10px 20px 20px 20px' }}>
							<div className='pull-right' style={{ fontSize: '12px' }}>
								<CheckBoxField
									label={t('translation:Common.Označiť všetky')}
									input={{
										value:
											size(get(formValues, 'pohladavky', [])) == size(get(blokovanieUpominania, 'data', [])) &&
											size(get(formValues, 'pohladavky', [])) > 0,
										onChange: this.selectAll,
										label: ''
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='content-wrapper'>
					<div className='box'>{this.formatSearchResult()}</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE_VYBER_FAKTUR,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(BlokovanieUpominaniaSearch)

const mapStateToProps = (state) => ({
	formValues: getFormValues(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE_VYBER_FAKTUR)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
