import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'
import { get, upperFirst } from 'lodash'
import { NumericFormat } from 'react-number-format'
import * as IBAN from 'iban'

// actions
import * as PlatbyActions from '../../actions/ObchodniPartneri/PlatbyActions'

// components
import Modal from './Modal'
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'

// resources
import crossIcon from '../../resources/img/icons/cross-black.svg'

// utils
import { formatDate } from '../../utils/date'
import { formatAddress } from '../../utils/address'
import { POHLADAVKA_TYP } from '../../utils/enums'

class PlatbaDetail extends React.Component {
	static propTypes = {
		modalTitle: PropTypes.string,
		platby: PropTypes.shape().isRequired,
		platbyActions: PropTypes.shape().isRequired,
		platbaCislo: PropTypes.string.isRequired,
		onCloseButton: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	componentDidMount() {
		const { platbaCislo, platbyActions } = this.props

		platbyActions.loadPlatbaDetail(platbaCislo)
	}

	render() {
		const { modalTitle, onCloseButton, platby, t } = this.props

		let content
		if (get(platby, 'isLoading')) {
			content = <ElementLoading />
		} else if (get(platby, 'isFailure')) {
			content = <ElementFailure text={t('translation:Platby.Nepodarilo sa načítať detail platby')} />
		} else {
			content = (
				<>
					<table>
						<tbody>
							<tr>
								<td>
									<strong>{t('translation:Platby.Variabilný symbol')}</strong>
								</td>
								<td>{get(platby, 'data.variabilnySymbol') || '-'}</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:Platby.Dátum platby')}</strong>
								</td>
								<td>{formatDate(get(platby, 'data.platbaDatum'), '-', 'DD.MM.YYYY')}</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:Platby.Suma')}</strong>
								</td>
								<td>
									<NumericFormat
										thousandSeparator={' '}
										decimalSeparator={','}
										decimalScale={2}
										fixedDecimalScale={true}
										displayType='text'
										suffix=' €'
										value={get(platby, 'data.suma')}
									/>
								</td>
							</tr>
							{get(platby, 'data.sumaAnulovana') != null && (
								<tr>
									<td>
										<strong>{t('translation:Platby.Suma anulovaná')}</strong>
									</td>
									<td>
										<NumericFormat
											thousandSeparator={' '}
											decimalSeparator={','}
											decimalScale={2}
											fixedDecimalScale={true}
											displayType='text'
											suffix=' €'
											value={get(platby, 'data.sumaAnulovana')}
										/>
									</td>
								</tr>
							)}
							<tr>
								<td>
									<strong>{t('translation:Platby.Zdroj dát')}</strong>
								</td>
								<td>{upperFirst(get(platby, 'data.zdrojDat.nazov')) || '-'}</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:Platby.Typ platby')}</strong>
								</td>
								<td>{upperFirst(get(platby, 'data.typ.nazov')) || '-'}</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:Platby.Typ platby (detail)')}</strong>
								</td>
								<td>{upperFirst(get(platby, 'data.typDetail.nazov')) || '-'}</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:Platby.IBAN')}</strong>
								</td>
								<td>{IBAN.printFormat(get(platby, 'data.iban') || '') || '-'}</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:Platby.Text platby')}</strong>
								</td>
								<td>{get(platby, 'data.platbaText') || '-'}</td>
							</tr>
							<tr>
								<td>
									<strong>
										{get(platby, 'data.typ.id') == POHLADAVKA_TYP.PRIJATA_PLATBA.value
											? t('translation:Platby.Meno platiteľa')
											: t('translation:Platby.Meno príjemcu')}
									</strong>
								</td>
								<td>{get(platby, 'data.meno') || '-'}</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:Platby.Poštová adresa')}</strong>
								</td>
								<td>{formatAddress(get(platby, 'data.adresaPostova')) || '-'}</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:Platby.Číslo SEPA mandátu')}</strong>
								</td>
								<td>{get(platby, 'data.sepaMandatCislo') || '-'}</td>
							</tr>
						</tbody>
					</table>
					{get(platby, 'data.platobnyPrikaz') && (
						<>
							<h4 style={{ fontSize: '18px' }}>{t('translation:PlatobnePrikazy.Platobný príkaz')}</h4>
							<div className='inner-box'>
								<table>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:PlatobnePrikazy.Číslo platobného príkazu')}</strong>
											</td>
											<td>{get(platby, 'data.platobnyPrikaz.id') || '-'}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlatobnePrikazy.Zadaný od')}</strong>
											</td>
											<td>{formatDate(get(platby, 'data.platobnyPrikaz.zadanyOd'), '-', 'DD.MM.YYYY')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlatobnePrikazy.Stav')}</strong>
											</td>
											<td>
												<strong>{upperFirst(get(platby, 'data.platobnyPrikaz.stav.nazov')) || '-'}</strong>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlatobnePrikazy.Spôsob platby')}</strong>
											</td>
											<td>
												{get(platby, 'data.platobnyPrikaz.doslePlatbySposob') && t('translation:PlatobnePrikazy.Prijatá platba')}
												{get(platby, 'data.platobnyPrikaz.odoslanePlatbySposob') && t('translation:PlatobnePrikazy.Odoslaná platba')}
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlatobnePrikazy.Typ platby')}</strong>
											</td>
											<td>
												{upperFirst(get(platby, 'data.platobnyPrikaz.doslePlatbySposob.nazov')) ||
													upperFirst(get(platby, 'data.platobnyPrikaz.odoslanePlatbySposob.nazov')) ||
													'-'}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</>
					)}
				</>
			)
		}

		return (
			<Modal shown size='m'>
				{modalTitle && (
					<div className='modal-header'>
						<h3>{modalTitle}</h3>
						<div className='close' onClick={onCloseButton}>
							<img src={crossIcon} width='25' />
						</div>
					</div>
				)}
				<div className='modal-content'>{content}</div>
			</Modal>
		)
	}
}
const mapStateToProps = (state) => ({
	platby: get(state, 'platby.detail')
})

const mapDispatchToProps = (dispatch) => ({
	platbyActions: bindActionCreators(PlatbyActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(PlatbaDetail)
