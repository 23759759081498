import { get } from 'lodash'

import { getReq } from '../../utils/request'
import { OBCHODNY_PARTNER_REGULACIE_LOAD_START, OBCHODNY_PARTNER_REGULACIE_LOAD_FAIL, OBCHODNY_PARTNER_REGULACIE_LOAD_DONE } from '../../types/obchodnyPartner'

export const loadRegulacie = (page = 1, queryParams = {}) => {
	return async (dispatch, getStore) => {
		try {
			const interakcia = getStore().interakcie.detail.data

			dispatch({
				type: OBCHODNY_PARTNER_REGULACIE_LOAD_START,
				payload: { page }
			})

			const query = {
				page,
				stav: queryParams?.regulaciaStav,
				regulaciaTyp: queryParams?.regulaciaTyp
			}

			const res = await getReq(`/api/v2/op/${interakcia.opCislo}/regulacie`, query)

			dispatch({
				type: OBCHODNY_PARTNER_REGULACIE_LOAD_DONE,
				payload: {
					regulacie: get(res, 'response.content', []),
					page: get(res, 'response.page', 1),
					hasNextPage: get(res, 'response.content.length') >= get(res, 'response.pageSize'),
					lastLoad: new Date()
				}
			})
			return get(res, 'response.content', [])
		} catch (err) {
			dispatch({
				type: OBCHODNY_PARTNER_REGULACIE_LOAD_FAIL
			})
		}
	}
}
