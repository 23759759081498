import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { get, size } from 'lodash'

// atoms
import { TextInputField } from '../../../atoms'

// utils
import { formatFormValue } from '../../../utils/form'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/attributesConfig'

class PodpisMiestoField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		auth: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape()
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t } = this.props

		if (value && size(value) > 100) {
			return t('translation:Common.validate.Miesto podpisu úkonu môže mať najviac 100 znakov')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, auth, t, value, originalValue } = this.props

		const valueBefore = get(auth, 'user.podpisMiesto', '')
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT) {
			valueAfter = (
				<Field
					name={field}
					component={TextInputField}
					validate={this.validate}
					placeholder={t('translation:Common.Zadajte miesto podpisu úkonu (ak nejde o prípad odloženého podpisu)')}
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter = formatFormValue(value, originalValue)
		}

		return (
			<tr>
				<td>
					<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
				</td>
				{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
				<td>{valueAfter}</td>
			</tr>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(PodpisMiestoField)
