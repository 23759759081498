import React from 'react'
import PropTypes from 'prop-types'
import { get, find, map, isEmpty } from 'lodash'
import { NumericFormat } from 'react-number-format'
import { Tooltip } from 'react-tippy'

// atoms
import CheckBoxField from '../../atoms/CheckboxField'

// utils
import { formatDate } from '../../utils/date'
import { POHLADAVKA_TYP, POHLADAVKA_SPLATNOST_STATUS } from '../../utils/enums'
import { getItemState, getTypPohladavkyTooltip } from '../../utils/pohladavky'

function BlokovanieUpominaniaSearchResultRow(props) {
	const { pohladavka, pohladavky, onClickCheckbox, disabledCheckbox, disabledCheckboxText, t } = props

	let checkbox = (
		<CheckBoxField
			disabled={disabledCheckbox}
			input={{
				value: find(
					pohladavky,
					(selectedPohladavka) =>
						get(pohladavka, 'cislo') == get(selectedPohladavka, 'cislo') && get(pohladavka, 'typ.id') == get(selectedPohladavka, 'typ.id')
				),
				onChange: onClickCheckbox,
				label: ''
			}}
		/>
	)
	if (disabledCheckbox && disabledCheckboxText) {
		checkbox = (
			<Tooltip html={<span>{disabledCheckboxText}</span>} position='left' trigger='mouseenter' theme='light'>
				{checkbox}
			</Tooltip>
		)
	}

	let typPohladavky
	switch (get(pohladavka, 'typ.id')) {
		case POHLADAVKA_TYP.PREPLATOK_Z_FAKTURACIE.value:
		case POHLADAVKA_TYP.AKONTO.value:
		case POHLADAVKA_TYP.ZALOHA_ZNOVUPRIPOJENIE.value:
		case POHLADAVKA_TYP.UDRZBA_UCTU.value:
		case POHLADAVKA_TYP.STANDARD_KVALITY.value:
			typPohladavky = (
				<Tooltip html={getTypPohladavkyTooltip(pohladavka, t)} position='top' trigger='mouseenter' theme='light'>
					{get(pohladavka, 'typ.nazov')}
				</Tooltip>
			)
			break
		default:
			typPohladavky = get(pohladavka, 'typ.nazov')
	}

	const splatnostDatum = formatDate(get(pohladavka, 'splatnostDatum'), '', 'DD.MM.YYYY')
	let splatnostDatumString = splatnostDatum
	const sumaOtvorena = (
		<NumericFormat
			thousandSeparator={' '}
			decimalSeparator={','}
			decimalScale={2}
			fixedDecimalScale={true}
			displayType='text'
			suffix=' €'
			value={get(pohladavka, 'sumaOtvorena')}
		/>
	)
	let sumaOtvorenaString = sumaOtvorena

	const state = getItemState(pohladavka)
	if (
		get(pohladavka, 'sumaOtvorena') != 0 &&
		get(pohladavka, 'typ.id') != POHLADAVKA_TYP.PRIJATA_PLATBA.value &&
		get(pohladavka, 'typ.id') != POHLADAVKA_TYP.ODOSLANA_PLATBA.value
	) {
		if (state == POHLADAVKA_SPLATNOST_STATUS.SPLATNA_PRED) {
			splatnostDatumString = <strong data-text-color='blue'>{splatnostDatum}</strong>
			sumaOtvorenaString = <strong data-text-color='blue'>{sumaOtvorena}</strong>
		} else if (state == POHLADAVKA_SPLATNOST_STATUS.SPLATNA_PO) {
			splatnostDatumString = <strong data-text-color='red'>{splatnostDatum}</strong>
			sumaOtvorenaString = <strong data-text-color='red'>{sumaOtvorena}</strong>
		}
	}

	return (
		<tr>
			<td>{get(pohladavka, 'variabilnySymbol', '-')}</td>
			<td style={{ wordBreak: 'normal' }}>{typPohladavky}</td>
			<td>{get(pohladavka, 'cislo')}</td>
			<td>{formatDate(get(pohladavka, 'dokladDatum'), '', 'DD.MM.YYYY')}</td>
			<td>{splatnostDatumString}</td>
			<td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
				<NumericFormat
					thousandSeparator={' '}
					decimalSeparator={','}
					decimalScale={2}
					fixedDecimalScale={true}
					displayType='text'
					suffix=' €'
					value={get(pohladavka, 'suma')}
				/>
			</td>
			<td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{sumaOtvorenaString}</td>
			<td>
				{!isEmpty(get(pohladavka, 'blokovania', [])) ? (
					<table className='content-table padded' cellSpacing='0' style={{ height: 'auto' }}>
						<tbody>
							{map(get(pohladavka, 'blokovania', []), (blokovanie) => (
								<tr>
									<td style={{ whiteSpace: 'nowrap', backgroundColor: 'inherit', padding: '5px', /* fontSize: '12px', */ width: '30%' }}>
										{formatDate(get(blokovanie, 'blokovanieOd'), '', 'DD.MM.YYYY')}
										<br />
										{formatDate(get(blokovanie, 'blokovanieDo'), '', 'DD.MM.YYYY')}
									</td>
									<td style={{ backgroundColor: 'inherit', padding: '5px', /* fontSize: '12px', */ wordBreak: 'normal', width: '70%' }}>
										{get(blokovanie, 'blokovanieDovod.nazov', '')}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				) : null}
			</td>
			{get(props, 'checkRows') && (
				<td>
					<div style={{ float: 'right' }}>{checkbox}</div>
				</td>
			)}
		</tr>
	)
}

BlokovanieUpominaniaSearchResultRow.propTypes = {
	pohladavka: PropTypes.shape().isRequired,
	pohladavky: PropTypes.arrayOf(PropTypes.shape()),
	onClickCheckbox: PropTypes.func,
	disabledCheckbox: PropTypes.bool,
	disabledCheckboxText: PropTypes.string,
	t: PropTypes.func.isRequired
}

export default BlokovanieUpominaniaSearchResultRow
