import { get } from 'lodash'

import { getReq } from '../../utils/request'
import {
	OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_START,
	OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_DONE,
	OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_FAIL
} from '../../types/obchodnyPartner'

export const loadMarketingoveAtributy = (page = 1, queryParams = {}) => {
	return async (dispatch, getStore) => {
		try {
			const interakcia = getStore().interakcie.detail.data

			dispatch({
				type: OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_START,
				payload: { page }
			})

			const query = {
				page,
				dolezite: queryParams?.marketingDolezite,
				sort: `poradie:asc${queryParams?.marketingSort ? `,${queryParams.marketingSort}` : ''}`
			}

			const res = await getReq(`/api/v2/op/${interakcia.opCislo}/marketingove-atributy`, query)

			dispatch({
				type: OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_DONE,
				payload: {
					marketingoveAtributy: get(res, 'response.content', []),
					page: get(res, 'response.page', 1),
					hasNextPage: get(res, 'response.content.length') >= get(res, 'response.pageSize'),
					lastLoad: new Date()
				}
			})
			return get(res, 'response.content', [])
		} catch (err) {
			dispatch({
				type: OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_FAIL
			})
		}
	}
}
