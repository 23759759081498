import { get } from 'lodash'
import { getReq } from '../../utils/request'
import { PLATOBNE_PRIKAZY_LOAD_START, PLATOBNE_PRIKAZY_LOAD_DONE, PLATOBNE_PRIKAZY_LOAD_FAIL } from '../../types/platobnePrikazyTypes'

export const loadPlatobnePrikazy = (pohladavkaId) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: PLATOBNE_PRIKAZY_LOAD_START
		})

		try {
			const platobnePrikazyData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/pohladavky/${pohladavkaId}/platobne-prikazy`)
			dispatch({
				type: PLATOBNE_PRIKAZY_LOAD_DONE,
				payload: {
					detail: get(platobnePrikazyData, 'response.content', null)
				}
			})
			return platobnePrikazyData
		} catch (e) {
			dispatch({
				type: PLATOBNE_PRIKAZY_LOAD_FAIL
			})
		}
	}
}
