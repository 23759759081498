import React from 'react'
import cx from 'classnames'
import { SortableHandle } from 'react-sortable-hoc'
import PropTypes from 'prop-types'
import { WIDGETY_ENUMS } from '../utils/enums'

const DragHandle = SortableHandle(() => <div className='box-header-handle' />)

class DashboardBox extends React.Component {
	static propTypes = {
		boxId: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		Content: PropTypes.func.isRequired,
		footer: PropTypes.string,
		permission: PropTypes.string,
		headerOnChangeHandler: PropTypes.func.isRequired,
		onClickDeleteItem: PropTypes.func.isRequired,
		allowSettings: PropTypes.bool.isRequired,
		onSubmitSettings: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			showSettings: false
		}
	}

	toggle = () => {
		this.setState({
			visible: !this.state.visible
		})
	}

	handleClickOutside() {
		this.setState({
			visible: false
		})
	}

	onFocusBoxHeaderInput = () => {
		const { boxId } = this.props
		document.getElementById(`box-header-${boxId}`).focus()
		this.toggle()
	}

	toogleShowSettings = () => {
		this.setState({
			visible: false,
			showSettings: !this.state.showSettings
		})
	}

	render() {
		const { visible, showSettings } = this.state
		const { boxId, title, Content, footer, permission, headerOnChangeHandler, onClickDeleteItem, onSubmitSettings, allowSettings } = this.props

		return (
			<div className='box dashboard-box noselect'>
				<div className='box-header text-center'>
					{boxId !== WIDGETY_ENUMS.DIGITALIZACIA && <DragHandle />}
					<div className='box-header-settings' onClick={this.toggle} />
					<div className='box-header-dropdown'>
						<div className={cx('dropdown', { shown: visible })}>
							<ul>
								<li className={cx({ disabled: !allowSettings })}>
									<a
										onClick={(e) => {
											e.preventDefault()
											if (allowSettings) {
												this.toogleShowSettings()
											}
										}}
									>
										Nastavenia
									</a>
								</li>
								<li>
									<a onClick={this.onFocusBoxHeaderInput}>Premenovať</a>
								</li>
								<li>
									<a onClick={() => onClickDeleteItem(boxId)}>Odstrániť</a>
								</li>
							</ul>
						</div>
					</div>
					<input
						id={`box-header-${boxId}`}
						defaultValue={title}
						placeholder='Zadajte názov'
						onBlur={(e) => headerOnChangeHandler(e, boxId)}
						required
					/>
				</div>
				<Content
					boxId={boxId}
					permission={permission}
					showSettings={showSettings}
					onSubmitSettings={onSubmitSettings}
					toogleShowSettings={this.toogleShowSettings}
				/>
				<div className='box-footer'>{footer}</div>
			</div>
		)
	}
}

export default DashboardBox
