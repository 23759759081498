import { TRACKING_CLEAR, TRACKING_START } from '../types/tracking'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	startTime: null,
	type: null,
	data: null
}

export default (state = initState, action) => {
	switch (action.type) {
		case TRACKING_START:
			return {
				...initState,
				startTime: action.payload.startTime,
				type: action.payload.type,
				data: action.payload.data
			}
		case TRACKING_CLEAR:
			return {
				...initState
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
