import { DATA_CHANGED, LEAVE_PAGE_MODAL_HIDE, LEAVE_PAGE_MODAL_SHOW } from '../types/data'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	leavePageModal: {
		show: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case DATA_CHANGED:
			return {
				...state,
				[action.payload.selector]: action.payload.data
			}
		case LEAVE_PAGE_MODAL_SHOW:
			return {
				...state,
				leavePageModal: {
					show: true
				}
			}
		case LEAVE_PAGE_MODAL_HIDE:
			return {
				...state,
				leavePageModal: {
					show: false
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
