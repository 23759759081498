import { get, keyBy, times } from 'lodash'
import dayjs from 'dayjs'
import { PLAN_SPLATOK_STAV, TYP_PLATCU_PLANU_SPLATOK } from './enums'

/**
 * @param {Object} planSplatokDTO
 * @param {Object} OdberneMiestoZoznamRozsirenyDTO
 * @return {Object} {zmluvnyUcetDTO, zmluvnyVztahDTO, odberneMiestoDTO, miestoSpotrebyDTO}
 */
export const getContextForPlanySplatok = (planSplatokDTO, OdberneMiestoZoznamRozsirenyDTO) => {
	const { zmluvnyVztahCislo, zmluvnyUcetCislo } = planSplatokDTO
	const zmluvneUctyKeyBy = keyBy(OdberneMiestoZoznamRozsirenyDTO.zmluvneUcty, (zmluvnyUcet) => zmluvnyUcet.cislo)
	const odberneMiestaKeyBy = keyBy(OdberneMiestoZoznamRozsirenyDTO.odberneMiesta, (odberneMiesto) => odberneMiesto.cislo)
	const zmluvnyVztahKeyBy = keyBy(OdberneMiestoZoznamRozsirenyDTO.zmluvneVztahy, (zmluvnyVztah) => zmluvnyVztah.cislo)
	const miestaSpotrebyKeyBy = keyBy(OdberneMiestoZoznamRozsirenyDTO.miestaSpotreby, (miestoSpotreby) => miestoSpotreby.cislo)

	const zmluvnyUcet = get(zmluvneUctyKeyBy, zmluvnyUcetCislo)
	const zmluvnyVztah = get(zmluvnyVztahKeyBy, zmluvnyVztahCislo)
	const odberneMiesto = get(odberneMiestaKeyBy, get(zmluvnyVztah, 'odberneMiestoCislo'))
	const miestoSpotreby = get(miestaSpotrebyKeyBy, get(odberneMiesto, 'miestoSpotrebyCislo'))

	return {
		zmluvnyUcet,
		zmluvnyVztah,
		odberneMiesto,
		miestoSpotreby
	}
}

/**
 *
 * @param {int} stav
 * @return {string} color
 */
export const getColorByPlanSplatokStav = (stav) => {
	switch (stav) {
		case PLAN_SPLATOK_STAV.NEAKTIVNY:
			return 'grey'
		case PLAN_SPLATOK_STAV.OTVORENY:
			return 'red'
		case PLAN_SPLATOK_STAV.VYROVNANY:
			return 'green'
		case PLAN_SPLATOK_STAV.ZRUSENY:
		case PLAN_SPLATOK_STAV.DEAKTIVOVANY_UPOMINANIM:
		case PLAN_SPLATOK_STAV.DEAKTIVOVANY_MANUALNE:
			return 'black'
	}
}

export const getPlatcaTypString = (platcaTyp, t) => {
	switch (platcaTyp) {
		case TYP_PLATCU_PLANU_SPLATOK.SAM_ZA_SEBA:
			return t('translation:PlanySplatok.PlatcaTyp.SAM_ZA_SEBA')
		case TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC:
			return t('translation:PlanySplatok.PlatcaTyp.SPLNOMOCNENEC')
		case TYP_PLATCU_PLANU_SPLATOK.DEDIC:
			return t('translation:PlanySplatok.PlatcaTyp.DEDIC')
		default:
			return null
	}
}

export const getPlatcaTypLabel = (platcaTyp, t) => {
	switch (platcaTyp) {
		case TYP_PLATCU_PLANU_SPLATOK.SAM_ZA_SEBA:
			return null
		case TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC:
			return t('translation:Common.Splnomocnená osoba')
		case TYP_PLATCU_PLANU_SPLATOK.DEDIC:
			return t('translation:Common.Dedič')
		default:
			return null
	}
}

export const getNormalizedDatumSplatnosti = (maxUrovenSchvalovania, schvalovanieKalDniMax, offset) => {
	let date = dayjs().startOf('day')
	times(maxUrovenSchvalovania, () => {
		date = date.add(schvalovanieKalDniMax, 'days') // addWeekdays(date, 3)
	})
	return date.add(offset + 1, 'days') // addWeekdays(date, offset + 1)
}

export const getContextForPlanSplatokAktualneNastavenie = (values) => {
	return {
		zahrnuteFaktury: get(values, 'zdrojoveFaktury', []),
		splatkyPocet: get(values, 'splatkyPocet'),
		datumSplatnosti: get(values, 'datumSplatnosti') ? dayjs(get(values, 'datumSplatnosti')).format('YYYY-MM-DD') : null,
		urok: get(values, 'urok', true)
	}
}
