import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { get, find, head } from 'lodash'
import dayjs from 'dayjs'

// components
import Modal from './Modal'
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'

// resources
import crossIcon from '../../resources/img/icons/cross-black.svg'

// utils
import { postReq } from '../../utils/request'
import { UKONY_CISELNIK } from '../../utils/enums'
import { getUkonVstupByBiznisKanal } from '../../utils/scenar'

class GeneratePin extends React.Component {
	static propTypes = {
		onCloseButton: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		interakcia: PropTypes.shape().isRequired,
		auth: PropTypes.shape().isRequired,
		ciselniky: PropTypes.shape().isRequired
	}

	_mounted = false

	state = {
		isSuccess: false,
		isLoading: false,
		isFailure: false
	}

	constructor(props) {
		super(props)
	}

	async componentDidMount() {
		this._mounted = true

		const { interakcia, auth, ciselniky, notifikacie } = this.props

		this.setState({
			isLoading: true
		})

		const opCislo = get(interakcia, 'opCislo')
		const typ = find(get(ciselniky, 'ukonTyp', []), (ukonTyp) => {
			return get(ukonTyp, 'id') == UKONY_CISELNIK.GENEROVANIE_PIN
		})

		const body = {
			vstup: getUkonVstupByBiznisKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'ukonVstup')),
			podpisMiesto: get(auth, 'user.podpisMiesto', ''),
			splnomocnenec: null,
			trvanie: 0,
			zacatyOd: dayjs().toISOString(),
			typ,
			opCislo,
			interakciaId: get(interakcia, 'id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			ziadanyOd: dayjs(new Date()).toISOString(),
			notifikacie
		}

		try {
			await postReq(`/api/v2/op/${opCislo}/pin/generate`, null, body)

			this.setState({
				isSuccess: true,
				isLoading: false,
				isFailure: false,
				status: null
			})
		} catch (e) {
			this.setState({
				isSuccess: false,
				isLoading: false,
				isFailure: true,
				status: get(head(get(e, 'response.data.response.status', [])), 'popis', null)
			})
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	render() {
		const { onCloseButton, t } = this.props
		const { isSuccess, isLoading, isFailure, status } = this.state

		let content
		if (isLoading) {
			content = <ElementLoading />
		} else if (isFailure) {
			content = <ElementFailure text={status || t('translation:Common.Nepodarilo odoslať požiadavku na vygenerovanie PIN')} />
		} else {
			content = t('translation:Common.Požiadavka na vygenerovanie PIN kódu bola odoslaná')
		}

		return (
			<Modal shown size='l' handleOutsideClick={() => onCloseButton(isSuccess)}>
				<div className='modal-header'>
					<h3>{t('translation:Common.Generovanie PIN')}</h3>
					<div className='close' onClick={() => onCloseButton(isSuccess)}>
						<img src={crossIcon} width='25' />
					</div>
				</div>
				<div className='modal-content'>{content}</div>
				<div className='modal-footer clearfix'>
					<button
						className={`button pull-right ${isLoading ? 'disabled' : ''}`}
						onClick={() => onCloseButton(isSuccess)}
						data-type='outline'
						data-color='blue'
						disabled={isLoading}
					>
						{t('translation:Common.Zavrieť')}
					</button>
				</div>
			</Modal>
		)
	}
}
const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth'),
	ciselniky: get(state, 'ciselniky.data')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(GeneratePin)
