import { UKON_STAV } from './enums'

export const getUkonStavString = (ukonStav, t) => {
	switch (ukonStav) {
		case UKON_STAV.SPRACOVANY:
			return t('translation:Enums.UkonStav.SPRACOVANY')
		case UKON_STAV.STORNOVANY:
			return t('translation:Enums.UkonStav.STORNOVANY')
		default:
			return null
	}
}
