import { WIDGETY_ENUMS } from './enums'
import Widgets from '../components/Widgets'
import { PERMISSIONS } from './permissionsHoc'

const widgets = [
	{
		id: WIDGETY_ENUMS.DIGITALIZACIA,
		title: 'Digitalizácia',
		Content: Widgets.digitalizacia,
		footer: '',
		allowSettings: false,
		modifiable: false,
		availableQuantity: 1,
		assignedQuantity: 1,
		static: true,
		attributes: ['skupiny indikátorov']
	},
	{
		id: WIDGETY_ENUMS.SPRAVA_SUHLASOV,
		title: 'Súhlasy',
		Content: Widgets.spravaSuhlasov,
		footer: '',
		allowSettings: false,
		modifiable: false,
		availableQuantity: 1,
		assignedQuantity: 1,
		attributes: ['skupiny indikátorov'],
		permission: PERMISSIONS.VIEW_SUHLASY
	},
	{
		id: WIDGETY_ENUMS.STAV_UCTU,
		title: 'Stav účtu',
		Content: Widgets.stavUctu,
		footer: '',
		allowSettings: false,
		modifiable: false,
		availableQuantity: 1,
		assignedQuantity: 0,
		attributes: ['celkový stav účtu', 'otvorené doklady', 'typ dokladu', 'suma'],
		permission: PERMISSIONS.VIEW_POHLADAVKY
	},
	{
		id: WIDGETY_ENUMS.FAKTURY,
		title: 'Faktúry',
		Content: Widgets.faktury,
		footer: '',
		allowSettings: false,
		modifiable: false,
		availableQuantity: 1,
		assignedQuantity: 1,
		attributes: ['číslo dokladu', 'typ faktúry', 'dátum vystavenia', 'suma k úhrade', 'stav úhrady'],
		permission: PERMISSIONS.VIEW_FAKTURY
	},
	{
		id: WIDGETY_ENUMS.RYCHLE_UKONY,
		title: 'Rýchle úkony',
		Content: Widgets.rychleUkony,
		footer: '',
		allowSettings: false,
		modifiable: false,
		availableQuantity: 3,
		assignedQuantity: 1,
		attributes: ['výber z dostupných úkonov']
	},
	{
		id: WIDGETY_ENUMS.ZOZNAM_PRODUKTOV_ZMLUV,
		title: 'Zoznam produktov / zmlúv',
		Content: Widgets.produkty,
		footer: '',
		allowSettings: true,
		modifiable: true,
		availableQuantity: 1,
		assignedQuantity: 1,
		attributes: ['názov produktu', 'platnosť od', 'bude neskôr definované'],
		permission: PERMISSIONS.VIEW_OP_ZMLUVY
	},
	{
		id: WIDGETY_ENUMS.ODBERNE_MIESTA,
		title: 'Odberné miesta',
		Content: Widgets.odberneMiesta,
		footer: '',
		allowSettings: false,
		modifiable: false,
		availableQuantity: 1,
		assignedQuantity: 1,
		attributes: ['adresa miesta spotreby', 'číslo EIC/POD', 'typ produktu' /* , 'stav zapojenia' */],
		permission: PERMISSIONS.VIEW_OP_ZMLUVY
	},
	{
		id: WIDGETY_ENUMS.SPLNOMOCNENIA,
		title: 'Splnomocnenia',
		Content: Widgets.splnomocnenia,
		footer: '',
		allowSettings: false,
		modifiable: false,
		availableQuantity: 1,
		assignedQuantity: 1,
		attributes: ['meno splnomocnenca', 'adresa splnomocnenca', 'platnosť'],
		permission: PERMISSIONS.VIEW_SPLNOMOCNENIE
	},
	{
		id: WIDGETY_ENUMS.ROZPIS_ZALOH,
		title: 'Rozpis záloh',
		Content: Widgets.rozpisyZaloh,
		footer: '',
		allowSettings: false,
		modifiable: false,
		availableQuantity: 1,
		assignedQuantity: 0,
		attributes: ['stav položiek rozpisu záloh'],
		permission: PERMISSIONS.VIEW_PREDDAVKOVE_PLATBY
	},
	{
		id: WIDGETY_ENUMS.SPLATKOVE_KALENDARE,
		title: 'Plány splátok',
		Content: Widgets.splatkoveKalendare,
		footer: '',
		allowSettings: false,
		modifiable: false,
		availableQuantity: 1,
		assignedQuantity: 1,
		attributes: ['otvorené plány splátok', 'miesto spotreby', 'komodita', 'platnosť plánu splátok'],
		permission: PERMISSIONS.VIEW_PLAN_SPLATOK
	}
]

export default widgets
