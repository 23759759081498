import { WIDGETY_SETTING_LOAD_DONE, WIDGETY_SETTING_LOAD_FAIL, WIDGETY_SETTING_LOAD_START } from '../types/widgety'

export const initState = {
	data: null,
	isLoading: false,
	isFailure: false
}

export default (state = initState, action) => {
	switch (action.type) {
		case WIDGETY_SETTING_LOAD_DONE:
			return {
				...state,
				data: action.payload.widgetSettings,
				isLoading: false,
				isFailure: false
			}
		case WIDGETY_SETTING_LOAD_FAIL: {
			return {
				...state,
				data: null,
				isLoading: false,
				isFailure: true
			}
		}
		case WIDGETY_SETTING_LOAD_START: {
			return {
				...state,
				isLoading: true,
				isFailure: false
			}
		}

		default:
			return state
	}
}
