import React from 'react'
import PropTypes from 'prop-types'

import { get, isEmpty, head, map } from 'lodash'
import { TARIFA } from '../../utils/enums'

import { formatDate } from '../../utils/date'

function Odpocet({ label, spotrebaSkutocna, spotrebaPredpokladana, placeholder, withoutLabel, withoutPeriodLabel, periodLabelAfterValue, units = 'kWh', t }) {
	let spotrebaLabel
	let odpocet

	if (!isEmpty(spotrebaSkutocna)) {
		spotrebaLabel = t('translation:ZmluvneUcty.Spotreba - posledná vyúčtovaná')
		odpocet = spotrebaSkutocna
	} else if (spotrebaPredpokladana) {
		spotrebaLabel = t('translation:ZmluvneUcty.Spotreba - predpokladaná')
		odpocet = [
			{
				tarifa: TARIFA.TARIFA_1T,
				hodnota: spotrebaPredpokladana
			}
		]
	} else {
		return null
	}

	let periodLabel
	const period = head(odpocet)
	if (get(period, 'obdobieOd') || get(period, 'obdobieDo')) {
		periodLabel = `${formatDate(get(period, 'obdobieOd'), '', 'DD.MM.YYYY')} - ${formatDate(get(period, 'obdobieDo'), '', 'DD.MM.YYYY')}`
	}

	return (
		<div>
			{!withoutLabel && <div>{label || spotrebaLabel}</div>}
			{!withoutPeriodLabel && periodLabel && <div>{periodLabel}</div>}
			<table style={{ width: 'auto', marginLeft: '-2px', lineHeight: '1.8' }}>
				<tbody>
					{map(odpocet, (item, index) => {
						const tarifa = get(item, 'tarifa')
						if (tarifa == TARIFA.TARIFA_1T) {
							return (
								<tr key={`spotreba-${index}`}>
									<td style={{ padding: '0px', border: 'none' }}>
										{get(item, 'hodnota') != null && get(item, 'hodnota') != undefined
											? `${get(item, 'hodnota')} ${get(item, 'jednotka') || units}`
											: placeholder}
										{periodLabelAfterValue && periodLabel && ` (${periodLabel})`}
									</td>
								</tr>
							)
						}
						if (tarifa == TARIFA.TARIFA_NT) {
							return (
								<tr key={`spotreba-${index}`}>
									<td style={{ padding: '0 10px 0 0', border: 'none' }}>{t('translation:ZmluvneUcty.NT')}:</td>
									<td style={{ padding: '0px', textAlign: 'right', border: 'none' }}>
										{get(item, 'hodnota') != null && get(item, 'hodnota') != undefined
											? `${get(item, 'hodnota')} ${get(item, 'jednotka') || units}`
											: placeholder}
									</td>
								</tr>
							)
						}
						if (tarifa == TARIFA.TARIFA_VT) {
							return (
								<>
									<tr key={`spotreba-${index}`}>
										<td style={{ padding: '0 10px 0 0', border: 'none' }}>{t('translation:ZmluvneUcty.VT')}:</td>
										<td style={{ padding: '0px', textAlign: 'right', border: 'none' }}>
											{get(item, 'hodnota') != null && get(item, 'hodnota') != undefined
												? `${get(item, 'hodnota')} ${get(item, 'jednotka') || units}`
												: placeholder}
											<br />
										</td>
									</tr>
									{periodLabelAfterValue && periodLabel && (
										<tr key={`spotreba-${index}-period`}>
											<td colSpan={2} style={{ padding: '0px', border: 'none' }}>
												({periodLabel})
											</td>
										</tr>
									)}
								</>
							)
						}
						if (!tarifa) {
							return (
								<tr key={`spotreba-${index}`}>
									<td style={{ padding: '0px', border: 'none' }}>
										{get(item, 'hodnota') != null && get(item, 'hodnota') != undefined
											? `${get(item, 'hodnota')} ${get(item, 'jednotka') || units}`
											: placeholder}
										{periodLabelAfterValue && periodLabel && ` (${periodLabel})`}
									</td>
								</tr>
							)
						}
					})}
				</tbody>
			</table>
		</div>
	)
}

Odpocet.propTypes = {
	label: PropTypes.string,
	spotrebaSkutocna: PropTypes.arrayOf(PropTypes.shape({})),
	spotrebaPredpokladana: PropTypes.arrayOf(PropTypes.shape({})),
	placeholder: PropTypes.string,
	withoutLabel: PropTypes.bool,
	withoutPeriodLabel: PropTypes.bool,
	periodLabelAfterValue: PropTypes.bool,
	t: PropTypes.func.isRequired,
	units: PropTypes.string
}

Odpocet.defaultProps = {
	placeholder: null
}

export default Odpocet
