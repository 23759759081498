import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'
import { initialize, reset, change, destroy, touch } from 'redux-form'
import { map, isEmpty, get, find, filter, head, set, maxBy, includes } from 'lodash'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'

// componets
import ElementLoading from '../../components/ElementLoading'
import initProcesnyKonfigurator from '../../components/ProcesnyKonfigurator'
import RozpisZalohEditInfo from '../../components/RozpisZaloh/RozpisZalohEditForm/RozpisZalohEditInfo'
import RozpisZalohEditConfirm from '../../components/RozpisZaloh/RozpisZalohEditForm/RozpisZalohEditConfirm'
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'

// utils
import { postReq, putReq, deleteReq } from '../../utils/request'
import { history } from '../../utils/history'
import { groupRozpisZalohItems, getReferencnaVyskaZaloh, getAktualnaVyskaZaloh, getRozpisZalohEditableStatus } from '../../utils/rozpisyZaloh'
import { ROZPISY_ZALOH_DETAIL, FORBIDDEN, setRouteParams } from '../../utils/routes'
import { FILTER_SELECTORS, SCENARE, NOTIFICATION_TYPES, UKONY, BUSINESS_CHANNELS, FORMS, UKONY_CISELNIK, DOKUMENT_TYP } from '../../utils/enums'
import { createFormInitValues } from '../../utils/form'
import { getUkonVstupByKanal } from '../../utils/scenar'
import { withPermissions, checkPermissions, PERMISSIONS } from '../../utils/permissionsHoc'

// recources
import failureStateIcon from '../../resources/img/icons/warning.svg'

// actions
import PodpisovanieDokumentovActions from '../../actions/PodpisovanieDokumentov'
import * as TrackingActions from '../../actions/TrackingActions'
import * as UkonyActions from '../../actions/UkonyActions'
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as DataActions from '../../actions/DataActions'
import * as UkonZmenaPreddavkovychPlatiebActions from '../../actions/ProcesnyKonfigurator/UkonZmenaPreddavkovychPlatieb'
import * as ProcesnyKonfiguratorActions from '../../actions/ProcesnyKonfigurator/ProcesnyKonfiguratorActions'

const scenarioOptions = [
	{
		kanal: BUSINESS_CHANNELS.ZSE_CENTRUM,
		scenarios: [
			{ typ: SCENARE.PODPISANIE_NA_MIESTE },
			{ typ: SCENARE.VYTLACIT_A_NAHRAT },
			{ typ: SCENARE.NAHRAT },
			{ typ: SCENARE.ODOSLAT_BEZ_PODPISU, allowedDocumentWithoutSignature: true, allowedRoles: ['per-ukon-bez-podpisu'] }
		],
		maxAllowedScenars: 3,
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	},
	{
		kanal: BUSINESS_CHANNELS.ZAKAZNICKA_LINKA,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		maxAllowedScenars: 1,
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	},
	{
		kanal: BUSINESS_CHANNELS.BACK_OFFICE,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		maxAllowedScenars: 1,
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	}
]

class RozpisZalohEditPage extends React.Component {
	static propTypes = {
		computedMatch: PropTypes.shape({
			params: PropTypes.shape({
				cisloRozpisZaloh: PropTypes.string.isRequired
			}).isRequired
		}).isRequired,
		interakcia: PropTypes.shape({
			id: PropTypes.number.isRequired,
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		procesnyKonfigurator: PropTypes.shape({
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired,
			data: PropTypes.shape()
		}).isRequired,
		ukonZmenaPreddavkovychPlatieb: PropTypes.shape({
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired,
			data: PropTypes.shape()
		}).isRequired,
		obchodnyPartner: PropTypes.shape({
			data: PropTypes.shape({})
		}),
		tracking: PropTypes.shape(),
		trackingActions: PropTypes.shape(),
		dispatch: PropTypes.func.isRequired,
		selectedFilters: PropTypes.shape(),
		ukonyActions: PropTypes.func.isRequired,
		obchodnyPartnerActions: PropTypes.func.isRequired,
		ciselniky: PropTypes.shape(),
		auth: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		notification: PropTypes.shape(),
		signedPdf: PropTypes.shape(),
		dataActions: PropTypes.shape(),
		selectedFiltersUkony: PropTypes.shape()
	}

	constructor(props) {
		super(props)
		const ProcesnyKonfigurator = initProcesnyKonfigurator(FORMS.ROZPISY_ZALOH, 'zmenaPreddavkovychPlatieb')

		this.state = {
			ProcesnyKonfigurator,
			step: 1,
			numOfSteps: 2,
			isFailure: false,
			originalValues: {},
			koKriteria: [],
			isKoKriteriumChecked: false,
			validacneKriteria: [],
			schvalovacieKriteria: [],
			context: null,
			podpisanieSuccess: false,
			podpisanyDokument: null,
			isLoading: true
		}
	}

	_mounted = false

	componentDidUpdate(prevProps, prevState) {
		const { step } = this.state

		// In case of return from step 2 back to step 1 revert default list of available
		if (prevState.step == 2 && step == 1) {
			this.setState({ dissallowedScenarios: [] })
		}
	}

	async componentDidMount() {
		this._mounted = true
		const {
			computedMatch,
			tracking,
			trackingActions,
			auth,
			obchodnyPartnerActions,
			obchodnyPartner,
			dataActions,
			ukonZmenaPreddavkovychPlatiebActions,
			ciselniky,
			procesnyKonfiguratorActions
		} = this.props

		dataActions.registerLeavePageModal()
		const procesnyKonfigurator = await procesnyKonfiguratorActions.loadProcesnyKonfigurator()

		// filter possible scenars for signing pdf files
		const scenarioOptionsForBusinessChannel = filter(scenarioOptions, (scenarioOption) => {
			return scenarioOption.kanal == get(auth, 'businessChannel.actual.id')
		})
		const scenarioOption = head(scenarioOptionsForBusinessChannel)
		this.setState({ scenarioOption })

		if (!tracking.startTime || (tracking.startTime && tracking.type !== UKONY.PREDDAVKOVE_PLATBY)) {
			trackingActions.tryToStartTracking(UKONY.PREDDAVKOVE_PLATBY)
		}
		const { cisloRozpisZaloh } = computedMatch.params

		const rozpisZalohDetail = await obchodnyPartnerActions.loadRozpisZaloh(cisloRozpisZaloh)
		const context = await ukonZmenaPreddavkovychPlatiebActions.loadContext(cisloRozpisZaloh)

		if (this._mounted && get(context, 'zu')) {
			const zalohyCyklus = {
				value: get(rozpisZalohDetail, 'zalohyCyklus.id', null),
				label: get(rozpisZalohDetail, 'zalohyCyklus.nazov', null)
			}

			const zalohyDenSplatnosti = {
				value: get(context, 'zu.zalohyDenSplatnosti.id', null),
				label: get(context, 'zu.zalohyDenSplatnosti.nazov', null)
			}

			const ukonVstupId = getUkonVstupByKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'data.ukonVstup'))

			const initValues = createFormInitValues({
				zalohySuma: null,
				zalohySumaPlaceholder: get(rozpisZalohDetail, 'zalohySuma'),
				zalohyNeznizovatSumu: get(context, 'zu.zalohyNeznizovatSumu', false),
				zalohyCyklus: zalohyCyklus.value,
				zalohyDenSplatnosti: zalohyDenSplatnosti.value,
				datumPrijatiaZiadosti: new Date(),
				datumZmeny: new Date(),
				ukonVstup: ukonVstupId,
				splnomocnenec: '',
				podpisMiesto: get(auth, 'user.podpisMiesto', '')
			})

			const originalValues = {
				zalohySuma: get(rozpisZalohDetail, 'zalohySuma'),
				zalohyNeznizovatSumu: get(context, 'zu.zalohyNeznizovatSumu', false),
				zalohyCyklus,
				zalohyDenSplatnosti,
				preddavkovePlatby: groupRozpisZalohItems(get(rozpisZalohDetail, 'polozky', []), get(procesnyKonfigurator, 'ukony.zmenaPreddavkovychPlatieb')),
				podpisMiesto: get(auth, 'user.podpisMiesto', '')
			}

			this.props.dispatch(initialize(FORMS.ROZPISY_ZALOH, initValues, true))
			this.props.dispatch(reset(FORMS.ROZPISY_ZALOH))

			if (!get(initValues, 'ukonVstup')) {
				// trigger validation immediately after init form
				this.props.dispatch(touch(FORMS.ROZPISY_ZALOH, 'ukonVstup'))
			}
			if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(get(initValues, 'dokumenty'))) {
				// trigger validation immediately after init form
				this.props.dispatch(touch(FORMS.ROZPISY_ZALOH, 'dokumenty'))
			}

			this.setState({
				step: 1,
				isLoading: false,
				originalValues,
				isFailure: false,
				context: {
					...context,
					op: get(obchodnyPartner, 'detail.data'),
					ukonData: {
						zalohySumaPovodna: get(obchodnyPartner, 'rozpisZalohDetail.data.zalohySuma'),
						zalohyCyklusPovodny: { id: zalohyCyklus.value },
						zalohyDenSplatnostiPovodny: zalohyDenSplatnosti.value
					}
				}
			})
		}
	}

	setLoading = (value) => {
		if (this._mounted) {
			this.setState({
				isLoading: value
			})
		}
	}

	componentWillUnmount() {
		const { dispatch, dataActions, trackingActions, ukonyActions } = this.props

		dispatch(destroy(FORMS.ROZPISY_ZALOH))

		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()
		ukonyActions.clearUkonId()
		this._mounted = false
	}

	setStep = (step) => {
		if (step < 1 || step > this.state.numOfSteps) {
			return
		}

		this.setState({ step })
	}

	formatDataForUkon = (values) => {
		const { interakcia, auth, tracking, ciselniky, obchodnyPartner } = this.props

		const { schvalovacieKriteria, context } = this.state

		const typ = find(get(ciselniky, 'data.ukonTyp', []), (el) => get(el, 'id') == UKONY_CISELNIK.PREDDAVKOVE_PLATBY)
		const zalohyCyklus = find(get(ciselniky, 'data.zalohaCyklus', []), (el) => get(el, 'id') == get(values, 'zalohyCyklus'))
		const zalohyDenSplatnosti = find(get(ciselniky, 'data.zalohaDenSplatnosti', []), (el) => get(el, 'id') == get(values, 'zalohyDenSplatnosti'))

		const vstup = find(get(this.props, 'ciselniky.data.ukonVstup', []), (vstup) => {
			return vstup.id == values.ukonVstup
		})

		let schvalovaciRezim = null
		if (!isEmpty(schvalovacieKriteria)) {
			const maxUrovenSchvalovania = get(
				maxBy(
					filter(schvalovacieKriteria, (schvalovacieKriterium) => !schvalovacieKriterium.vysledok),
					'urovenSchvalovania'
				),
				'urovenSchvalovania',
				0
			)
			const schvalovacieKriteriaNormalize = map(schvalovacieKriteria, (schvalovacieKriterium) => ({
				id: schvalovacieKriterium.nazov,
				vysledok: schvalovacieKriterium.vysledok,
				dovod: schvalovacieKriterium.popis
			}))
			schvalovaciRezim = {
				kod: maxUrovenSchvalovania,
				pravidla: schvalovacieKriteriaNormalize
			}
		}

		return {
			typ,
			opCislo: get(interakcia, 'opCislo'),
			interakciaId: get(interakcia, 'id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
			zacatyOd: dayjs(tracking.startTime).toISOString(),
			vstup,
			podpisMiesto: get(values, 'podpisMiesto'),
			poznamka: get(values, 'poznamka'),
			ziadanyOd: dayjs(get(values, 'datumPrijatiaZiadosti')).toISOString(),
			splnomocnenec: get(values, 'splnomocnenec'),
			poznamkaInterna: get(values, 'poznamkaInterna'),
			data: {
				rozpisZalohCislo: get(obchodnyPartner, 'rozpisZalohDetail.data.cislo'),
				zmluvnyUcetCislo: get(context, 'zu.cislo'),
				zmluvnyVztahCislo: get(context, 'zmluvnyVztah.cislo'),
				zalohySuma: get(values, 'zalohySuma') || get(values, 'zalohySumaPlaceholder'),
				schvalovaciRezim,
				zalohyNeznizovatSumu: get(values, 'zalohyNeznizovatSumu'),
				zalohyCyklus,
				zalohyDenSplatnosti,
				datumZmeny: dayjs(get(values, 'datumZmeny')).toISOString()
			}
		}
	}

	submitHandler = async (values) => {
		const { ukonNovy, tracking, notification, signedPdf, templatePdf, obchodnyPartnerActions, dataActions, t } = this.props

		this.setLoading(true)

		let notifikacie = {}
		if (get(notification, 'typ') == NOTIFICATION_TYPES.ADDRESS) {
			set(notifikacie, 'adresyPosta', [get(notification, 'address')])
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.EMAIL) {
			set(notifikacie, 'adresyUri', [
				{
					typ: NOTIFICATION_TYPES.EMAIL,
					hodnota: get(notification, 'email')
				}
			])
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.PRINTER) {
			set(notifikacie, 'adresyUri', [
				{
					typ: NOTIFICATION_TYPES.PRINTER,
					poradie: 0
				}
			])
		}

		notifikacie = isEmpty(notifikacie) ? undefined : notifikacie

		const documentForUpload = get(signedPdf, 'data.dataAsBase64') ? get(signedPdf, 'data') : get(templatePdf, 'data')
		try {
			if (documentForUpload) {
				await postReq(`/api/v0/ukony/${ukonNovy.id}/prilozit-dokument`, null, {
					contentType: get(documentForUpload, 'type'),
					typ: {
						id: DOKUMENT_TYP.VYSTUPNY
					},
					nazov: get(documentForUpload, 'name'),
					data: get(documentForUpload, 'dataAsBase64')
				})
			}

			const spustitBody = {
				...this.formatDataForUkon(values),
				trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
				notifikacie,
				id: ukonNovy.id
			}
			await postReq(`/api/v2/ukony/${ukonNovy.id}/spustit`, null, spustitBody)

			// refetch data for odberne miesta cause some attributes from rozpisy zaloh are getting from this data, set cache time to 0
			obchodnyPartnerActions.loadAllOdberneMiesta(0)

			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Úkon bol úspešne odoslaný'),
					success: true,
					isLoading: false
				})
			}
		} catch (e) {
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
					success: false,
					isLoading: false
				})
			}
		}
	}

	stepOneClickHandler = async (values) => {
		const { interakcia, ukonyActions, ukonNovy, tracking, dispatch } = this.props

		this.setLoading(true)

		const body = this.formatDataForUkon(values)

		try {
			let ukonId
			// if ukon not exist yet create a new one else update it
			if (!ukonNovy.id) {
				const res = await postReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ukony`, null, body)

				// set podpis Miesto from response CP-588
				const podpisMiesto = get(res, 'response.content.podpisMiesto')
				dispatch(change(FORMS.ROZPISY_ZALOH, 'podpisMiesto', podpisMiesto))

				ukonId = get(res, 'response.content.id')
				ukonyActions.setUkonId(ukonId)
			} else {
				ukonId = ukonNovy.id
				const updateBody = {
					...body,
					trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
					id: ukonId
				}
				await putReq(`/api/v2/ukony/${ukonId}`, null, updateBody)
			}
			// dokumenty which are not upload does not have ID
			const notUploadedDokumenty = filter(get(values, 'dokumenty', []), (dokument) => !get(dokument, 'id'))
			const uploadedDokumenty = filter(get(values, 'dokumenty', []), (dokument) => get(dokument, 'id'))

			const dokumenty = map(notUploadedDokumenty, async (file) => {
				const dokumentResponse = await postReq(`/api/v0/ukony/${ukonId}/prilozit-dokument`, null, {
					contentType: file.type,
					nazov: file.name,
					data: file.dataAsBase64,
					typ: {
						id: DOKUMENT_TYP.VSTUPNY
					}
				})
				return {
					type: get(dokumentResponse, 'response.contentType'),
					id: get(dokumentResponse, 'response.id'),
					dataAsBase64: get(file, 'dataAsBase64'),
					typ: get(dokumentResponse, 'response.typ'),
					name: get(dokumentResponse, 'response.nazov')
				}
			})

			const dokumentyPromises = await Promise.all(dokumenty)

			// merge already uploaded dokumenty and fresh uploaded
			dispatch(change(FORMS.ROZPISY_ZALOH, 'dokumenty', [...uploadedDokumenty, ...dokumentyPromises]))

			if (this._mounted) {
				this.setLoading(false)
				this.setStep(2)
			}
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	koKriteriaHandler = (isKoKriteriumChecked, koKriteria) => {
		return this.setState({
			isKoKriteriumChecked,
			koKriteria
		})
	}

	validacneKriteriaHandler = (validacneKriteria) => {
		return this.setState({ validacneKriteria })
	}

	schvalovacieKriteriaHandler = (schvalovacieKriteria) => {
		return this.setState({ schvalovacieKriteria })
	}

	confirmModal = () => {
		const { interakcia, computedMatch, ukonyActions, obchodnyPartnerActions, selectedFilters, selectedFiltersUkony } = this.props
		const { success } = this.state
		const { cisloRozpisZaloh } = computedMatch.params

		if (success) {
			this.setState(
				{
					success: false
				},
				() => {
					history.replace(setRouteParams(ROZPISY_ZALOH_DETAIL, interakcia.opCislo, cisloRozpisZaloh))
					ukonyActions.clearUkonId()
					ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)
					obchodnyPartnerActions.loadRozpisyZaloh(1, undefined, selectedFilters)
				}
			)
		}
	}

	handlePodpisanieResult = (result) => {
		if (result.success) {
			this.setState({
				podpisanieSuccess: true,
				podpisanyDokument: result.document
			})
		} else {
			this.setState({
				podpisanieSuccess: false,
				podpisanyDokument: null
			})
		}
	}

	onDeleteFile = async (fileID) => {
		const { ukonNovy } = this.props
		try {
			await deleteReq(`/api/v0/ukony/${ukonNovy.id}/dokumenty/${fileID}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	render() {
		const {
			step,
			koKriteria,
			originalValues,
			schvalovacieKriteria,
			validacneKriteria,
			result,
			success,
			isLoading,
			scenarioOption,
			dissallowedScenarios,
			context,
			ProcesnyKonfigurator
		} = this.state

		const { obchodnyPartner, procesnyKonfigurator, ukonZmenaPreddavkovychPlatieb, notification, signedPdf, t, auth } = this.props
		const { rozpisZalohDetail } = obchodnyPartner

		if (rozpisZalohDetail.isLoading || ukonZmenaPreddavkovychPlatieb.isLoading || procesnyKonfigurator.isLoading) {
			return this.loadingContentElement()
		}
		if (rozpisZalohDetail.isFailure || ukonZmenaPreddavkovychPlatieb.isFailure || procesnyKonfigurator.isFailure) {
			return this.failureContentElement()
		}
		if (isEmpty(rozpisZalohDetail.data)) {
			return this.emptyContentElement()
		}
		if (!get(procesnyKonfigurator, 'data.ukony')) {
			return this.emptyContentElement(t('containers:Ľutujeme ale nepodarilo sa načítať Procesný konfigurátor'))
		}

		// NOTE: GLPI 70369 - niekolko RZ v systeme nema naviazane entity potrebne pre PK
		if (!get(context, 'zu') || !get(context, 'zmluvnyVztah') || !get(context, 'om' || !get(context, 'pohladavkyZostatok'))) {
			return this.failureContentElement()
		}

		const { isStavRozpisuZalohOtvoreny, isStavRozpisuZalohOtvorenyPreZmeny, isOutDated } = getRozpisZalohEditableStatus(
			rozpisZalohDetail,
			procesnyKonfigurator
		)
		if (!isStavRozpisuZalohOtvoreny || !isStavRozpisuZalohOtvorenyPreZmeny || isOutDated) {
			return <Redirect to={FORBIDDEN} />
		}

		const customizedScenarioOption = {
			...scenarioOption,
			scenarios: filter(get(scenarioOption, 'scenarios', []), (scenar) => {
				const allowedRoles = get(scenar, 'allowedRoles', [])
				return (
					!includes(dissallowedScenarios, get(scenar, 'typ')) &&
					(isEmpty(get(scenar, 'allowedRoles', [])) || checkPermissions(get(auth, 'user.roles'), allowedRoles))
				)
			})
		}

		// if notification is required and not selected yet
		const isDisabledNotification = get(customizedScenarioOption, 'notificationRequired') && !get(notification, 'typ')

		// for scenarios VYTLACIT_A_NAHRAT and PODPISANIE_NA_MIESTE is required signedPDF
		let isDisabledSignedPdf = true
		if (
			(find(get(customizedScenarioOption, 'scenarios'), { typ: SCENARE.VYTLACIT_A_NAHRAT }) ||
				find(get(customizedScenarioOption, 'scenarios'), { typ: SCENARE.PODPISANIE_NA_MIESTE })) &&
			get(signedPdf, 'data.dataAsBase64')
		) {
			isDisabledSignedPdf = false
		} else if (
			find(get(customizedScenarioOption, 'scenarios'), { typ: SCENARE.ODOSLAT_BEZ_PODPISU }) ||
			find(get(customizedScenarioOption, 'scenarios'), { typ: SCENARE.ODOSLAT_NA_PODPIS })
		) {
			isDisabledSignedPdf = false
		}

		const isDisabledScenarNotSelected = get(customizedScenarioOption, 'scenarios', []).length > get(customizedScenarioOption, 'maxAllowedScenars')

		const helperNotice = {
			vyuctovanie: getReferencnaVyskaZaloh(rozpisZalohDetail),
			aktualna: getAktualnaVyskaZaloh(rozpisZalohDetail),
			procesnyKonfigurator: get(procesnyKonfigurator, 'data.ukony.zmenaPreddavkovychPlatieb')
		}

		// check if every Ko kriterium pass condition
		const invalidKoKriteria = filter(koKriteria, { vysledok: false })
		if (!isEmpty(invalidKoKriteria)) {
			return this.koKriteriumContentElement(invalidKoKriteria)
		}
		let component = null

		switch (step) {
			case 1:
				component = (
					<RozpisZalohEditInfo
						{...this.props}
						originalValues={originalValues}
						helperNotice={helperNotice}
						onSubmit={this.stepOneClickHandler}
						schvalovacieKriteria={schvalovacieKriteria}
						validacneKriteria={validacneKriteria}
						onDeleteFile={this.onDeleteFile}
					/>
				)
				break
			case 2:
				component = (
					<RozpisZalohEditConfirm
						{...this.props}
						originalValues={originalValues}
						submitHandler={this.submitHandler}
						setStep={this.setStep}
						schvalovacieKriteria={schvalovacieKriteria}
						formatDataForUkon={this.formatDataForUkon}
						scenarioOption={customizedScenarioOption}
						notification={notification}
						disallowScenarios={this.disallowScenarios}
						isDisabledNotification={isDisabledNotification}
						isDisabledSignedPdf={isDisabledSignedPdf}
						isDisabledScenarNotSelected={isDisabledScenarNotSelected}
					/>
				)
				break
		}
		return this.commonContentContainer(
			<>
				{result && (
					<DefaultModal
						modalTitle={success ? 'Odoslané' : 'Chyba'}
						modalContent={result}
						leftButton={{
							onClick: this.confirmModal,
							text: 'Zavrieť',
							color: success ? 'green' : 'red'
						}}
						visible
					/>
				)}
				{isLoading && <ElementLoading />}
				{component}
				<ProcesnyKonfigurator
					koKriteriaHandler={this.koKriteriaHandler}
					validacneKriteriaHandler={this.validacneKriteriaHandler}
					schvalovacieKriteriaHandler={this.schvalovacieKriteriaHandler}
					context={context}
					formatDataForUkon={this.formatDataForUkon}
				/>
			</>
		)
	}

	commonContentContainer = (content) => {
		return <>{content}</>
	}

	loadingContentElement = () => {
		return this.commonContentContainer(<ElementLoading />)
	}

	failureContentElement = () => {
		const { t } = this.props
		return this.commonContentContainer(<ElementFailure text={t('containers:RozpisZalohEditPage.Nepodarilo sa načítať údaje rozpisu záloh')} />)
	}

	emptyContentElement = (text) => {
		const { t } = this.props
		const textElement = text || t('containers:RozpisZalohEditPage.Pre obchodného partnera neevidujeme žiadne rozpisy záloh')
		return this.commonContentContainer(<ElementEmptyContent text={textElement} />)
	}

	koKriteriumContentElement = (koKriteria) => {
		const content = map(koKriteria, (koKriterium, index) => {
			return (
				<React.Fragment key={index}>
					<p>{koKriterium.nazov}:</p>
					<p className='message fail'>{koKriterium.popis}</p>
				</React.Fragment>
			)
		})

		return this.commonContentContainer(
			<div className='failure-content-container'>
				<img src={failureStateIcon} />
				{content}
			</div>
		)
	}

	disallowScenarios = (scenarios) =>
		this.setState({
			dissallowedScenarios: scenarios
		})
}

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
		podpisovanieDokumentovAction: bindActionCreators(PodpisovanieDokumentovActions, dispatch),
		trackingActions: bindActionCreators(TrackingActions, dispatch),
		ukonyActions: bindActionCreators(UkonyActions, dispatch),
		obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
		dataActions: bindActionCreators(DataActions, dispatch),
		procesnyKonfiguratorActions: bindActionCreators(ProcesnyKonfiguratorActions, dispatch),
		ukonZmenaPreddavkovychPlatiebActions: bindActionCreators(UkonZmenaPreddavkovychPlatiebActions, dispatch)
	}
}

const mapStateToProps = (state) => ({
	ukonNovy: state.ukony.ukonNovy,
	interakcia: state.interakcie.detail.data,
	procesnyKonfigurator: state.procesnyKonfigurator.procesnyKonfigurator,
	ukonZmenaPreddavkovychPlatieb: state.procesnyKonfigurator.ukonZmenaPreddavkovychPlatieb,
	obchodnyPartner: state.obchodnyPartner,
	ciselniky: state.ciselniky,
	tracking: state.tracking,
	auth: state.auth,
	selectedFilters: get(state.selectedFilters, FILTER_SELECTORS.ROZPISY_ZALOH_PAGE, {}),
	signedPdf: state.podpisovanieDokumentov.signedPdf,
	notification: state.podpisovanieDokumentov.notification,
	templatePdf: state.podpisovanieDokumentov.templatePdf,
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.UKON_PREDDAVKOVE_PLATBY])
)(RozpisZalohEditPage)
