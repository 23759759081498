import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { get, head } from 'lodash'

// actions
import * as SpravaSuhlasovActions from '../../actions/ObchodniPartneri/SpravaSuhlasov'

// utils
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { suhlasCategoryInformativeTexts } from '../../utils/suhlas'

// components
import SuhlasKategoriaCollapsibleItem from '../../components/SpravaSuhlasov/SuhlasKategoriaCollapsibleItem'
import SuhlasCollapsibleItem from '../../components/SpravaSuhlasov/SuhlasCollapsibleItem'
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'

// resources
import infoIcon from '../../resources/img/icons/icon-info.svg'

class ZoznamSuhlasovPage extends React.Component {
	static propTypes = {
		t: PropTypes.func,
		auth: PropTypes.shape(),
		suhlasy: PropTypes.shape(),
		hlavnyZoznamSuhlasov: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		this.state = {}
	}

	_mounted = false

	componentDidMount() {
		const { spravaSuhlasovActions, auth } = this.props

		// TODO: Check if I shoud use id or name of businessChannel (CES ciselnik doesnt match BB ciselnik)
		const activeBusinessChannel = get(auth, 'businessChannel.actual.id')
		spravaSuhlasovActions.loadSpravaSuhlasov(activeBusinessChannel)
		spravaSuhlasovActions.loadHlavnyZoznamSuhlasov(activeBusinessChannel)

		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate() {}

	commonContentContainer = (content) => {
		return <div className='box-content'>{content}</div>
	}

	render() {
		const { t, suhlasy, hlavnyZoznamSuhlasov } = this.props

		if (!suhlasy || suhlasy.isLoading) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (suhlasy.isFailure) {
			return this.commonContentContainer(<ElementFailure text={t('containers:SpravaSuhlasovPage.Nepodarilo sa načítať zoznam súhlasov')} />)
		}

		if (suhlasy.isPrepared === false) {
			return this.commonContentContainer(<ElementLoading />)
		}

		const suhlasyData = get(suhlasy, 'data', [])

		return (
			<>
				<div className='consent-page'>
					<div className='content-header clearfix'>
						<div className='header-title pull-left'>{t('translation:Suhlasy.Súhlasy')}</div>
					</div>
				</div>
				<div className='content-wrapper'>
					{Array.isArray(hlavnyZoznamSuhlasov.data) && hlavnyZoznamSuhlasov.data.length > 0 && (
						<div className='box consent-main-category'>
							<div className='consent-warning-header'>
								<img className='consent-warning-icon' src={infoIcon} />
								{`${t('translation:Suhlasy.Prebieha zber súhlasov Je potrebné od obchodného partnera')}`}&nbsp;
								<strong>{`${t('translation:Suhlasy.vyžiadať tieto súhlasy')} (${hlavnyZoznamSuhlasov.data.length}):`}</strong>
							</div>
							<div className='consent-main-category-body'>
								{hlavnyZoznamSuhlasov.data.map((suhlasValue) => {
									return <SuhlasCollapsibleItem key={suhlasValue.id} suhlas={suhlasValue} showSuhlasCategory showWarning />
								})}
							</div>
						</div>
					)}
					{suhlasyData.map((suhlasValue) => {
						const categoryHelp = head(suhlasCategoryInformativeTexts.filter((help) => help.suhlasKategoriaId === suhlasValue.suhlasKategoria.id))
						return (
							<SuhlasKategoriaCollapsibleItem
								categoryHelp={categoryHelp}
								key={suhlasValue.suhlasKategoria.nazov}
								suhlasKey={suhlasValue.suhlasKategoria.nazov}
								kategorizovaneSuhlasy={suhlasValue.suhlasDefinicia}
							/>
						)
					})}
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	suhlasy: state.obchodnyPartner.suhlasy,
	hlavnyZoznamSuhlasov: state.obchodnyPartner.hlavnyZoznamSuhlasov
})

const mapDispatchToProps = (dispatch) => ({
	spravaSuhlasovActions: bindActionCreators(SpravaSuhlasovActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_SUHLASY])
)(ZoznamSuhlasovPage)
