import dayjs from 'dayjs'
import { get, isEmpty, forEach } from 'lodash'
import { GENERIC_FIELDS_KEY } from './AnonymnyVseobecnyUkonPage'

const validate = (values, props) => {
	const { t } = props

	const errors = {}

	const ukonyArrayErrors = []
	forEach(get(values, GENERIC_FIELDS_KEY, []), (ukon, ukonIndex) => {
		const ukonErrors = {}
		if (!get(ukon, 'trvanie.startTime') || !get(ukon, 'trvanie.endTime')) {
			ukonErrors.trvanie = t('translation:AnonymnyVseobecnyUkon.validate.Vyber čas začiatku a konca úkonu')
		} else {
			const startTime = dayjs(get(ukon, 'trvanie.startTime'))
			const endTime = dayjs(get(ukon, 'trvanie.endTime'))
			if (endTime.isBefore(startTime)) {
				ukonErrors.trvanie = t('translation:AnonymnyVseobecnyUkon.validate.Konečný čas nemôže byť menší ako začiatočný')
			}
		}

		ukonyArrayErrors[ukonIndex] = ukonErrors
	})

	if (!isEmpty(ukonyArrayErrors)) {
		errors[GENERIC_FIELDS_KEY] = ukonyArrayErrors
	}

	return errors
}

export default validate
