import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { get, map, isEmpty, upperFirst, trim, join } from 'lodash'
import { Tooltip } from 'react-tippy'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'
import * as IBAN from 'iban'
import qs from 'qs'
import ReactQueryParams from 'react-query-params'

// utils
import { formatAddress } from '../../utils/address'
import { formatDate, formatDateWithTime } from '../../utils/date'
import { setRouteParams, ZMLUVNE_UCTY_ZOZNAM, ZMLUVNE_UCTY_UPRAVIT } from '../../utils/routes'
import { SPOSOB_PLATBY_DOSLA_PLATBA, SPOSOB_PLATBY_ODOSLANA_PLATBA } from '../../utils/enums'
import { openDataUriWindow } from '../../utils/files'
import Permissions, { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { getSepaMandatStavColor } from '../../utils/zmluvnyUcet'

// components
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'

// actions
import ObchodniPartneriActions from '../../actions/ObchodniPartneri'
import ElementEmptyContent from '../../components/ElementEmptyContent'

class ZmluvnyUcetDetailPage extends ReactQueryParams {
	static propTypes = {
		computedMatch: PropTypes.shape({
			params: PropTypes.shape({
				cisloZU: PropTypes.string.isRequired
			}).isRequired
		}).isRequired,
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		obchodnyPartner: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired
		}),
		zmluvnyUcet: PropTypes.shape(),
		obchodniPartneriActions: PropTypes.shape(),
		t: PropTypes.func.isRequired
	}

	_mounted = false

	defaultQueryParams = {
		backUrl: ''
	}

	constructor(props) {
		super(props)

		const { backUrl } = qs.parse(window.location.search, {
			ignoreQueryPrefix: true
		})

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		} else {
			backUrlLink = setRouteParams(ZMLUVNE_UCTY_ZOZNAM, get(props, 'interakcia.opCislo'))
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			backUrl: ''
		})

		this.state = {
			odlisnyPlatca: {
				isLoading: false,
				data: null,
				isFailure: false
			},
			odlisnyPrijemca: {
				isLoading: false,
				data: null,
				isFailure: false
			},
			odlisnyPlatcaUpomienky: {
				isLoading: false,
				data: null,
				isFailure: false
			},
			odlisnyPlatcaDoslePlatby: {
				isLoading: false,
				data: null,
				isFailure: false
			},
			backUrlLink
		}
	}

	componentDidMount = () => {
		this._mounted = true

		const { computedMatch, obchodniPartneriActions } = this.props
		const { cisloZU } = computedMatch.params

		obchodniPartneriActions.loadZmluvnyUcetDetail(cisloZU)
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	render = () => {
		const { interakcia, zmluvnyUcet, obchodnyPartner, t } = this.props
		const { backUrlLink } = this.state
		const { data } = zmluvnyUcet

		if (get(zmluvnyUcet, 'isLoading')) {
			return (
				<div className='box' style={{ height: '100%' }}>
					<ElementLoading />
				</div>
			)
		}
		if (get(zmluvnyUcet, 'isFailure')) {
			return <ElementFailure text={t('containers:ZmluvnyUcetDetailPage.Ľutujeme ale nepodarilo sa načítať detail zmluvného účtu')} />
		}
		if (!data) {
			return <ElementEmptyContent text={t('containers:ZmluvnyUcetDetailPage.Zmluvný účet neexistuje')} />
		}

		const { opOdlisnyPrijemca, opOdlisnyPlatca, opOdlisnyPlatcaDoslePlatby, opOdlisnyPlatcaUpomienky } = get(data, 'odlisnyOp')

		let adresaFakturacie = '-'
		let adresaFakturacieInherit = ''
		if (get(data, 'zmluvnyUcet.adresaFakturacie')) {
			adresaFakturacie = formatAddress(get(data, 'zmluvnyUcet.adresaFakturacie'))
		} else if (get(obchodnyPartner, 'data.adresaKorespondencna')) {
			adresaFakturacie = formatAddress(get(obchodnyPartner, 'data.adresaKorespondencna'))
			adresaFakturacieInherit = t('containers:ZmluvnyUcetDetailPage.Adresa korešpondenčná')
		} else if (get(obchodnyPartner, 'data.adresaZakaznika')) {
			adresaFakturacie = formatAddress(get(obchodnyPartner, 'data.adresaZakaznika'))
			adresaFakturacieInherit = t('containers:ZmluvnyUcetDetailPage.Adresa trvalého pobytu')
		}

		// adresa upominacia check if exist or if it should inherit from another addresses
		let adresaUpominacia = '-'
		let adresaUpominaciaInherit = ''
		if (get(data, 'zmluvnyUcet.adresaUpominacia')) {
			adresaUpominacia = formatAddress(get(data, 'zmluvnyUcet.adresaUpominacia'))
		} else if (get(data, 'zmluvnyUcet.adresaFakturacie')) {
			// inherit from adresa fakturacna
			adresaUpominaciaInherit = t('containers:ObchodnyPartnerPage.Adresa fakturačná')
			adresaUpominacia = formatAddress(get(data, 'zmluvnyUcet.adresaFakturacie'))
		} else if (get(obchodnyPartner, 'data.adresaKorespondencna')) {
			adresaUpominacia = formatAddress(get(obchodnyPartner, 'data.adresaKorespondencna'))
			adresaUpominaciaInherit = t('containers:ZmluvnyUcetDetailPage.Adresa korešpondenčná')
		} else if (get(obchodnyPartner, 'data.adresaZakaznika')) {
			adresaUpominacia = formatAddress(get(obchodnyPartner, 'data.adresaZakaznika'))
			adresaUpominaciaInherit = t('containers:ZmluvnyUcetDetailPage.Adresa trvalého pobytu')
		}

		// adresa odoslane platby check if exist or if it should inherit from another addresses
		let adresaOdoslanePlatby = '-'
		let adresaOdoslanePlatbyInherit = ''
		if (get(data, 'zmluvnyUcet.adresaOdoslanePlatby')) {
			adresaOdoslanePlatby = formatAddress(get(data, 'zmluvnyUcet.adresaOdoslanePlatby'))
		} else if (opOdlisnyPrijemca && get(opOdlisnyPrijemca, 'adresaZakaznika')) {
			adresaOdoslanePlatbyInherit = t('containers:ObchodnyPartnerPage.Adresa trvalého pobytu odlišného príjemcu')
			adresaOdoslanePlatby = formatAddress(get(opOdlisnyPrijemca, 'adresaZakaznika'))
		} else if (get(data, 'zmluvnyUcet.adresaFakturacie')) {
			// inherit from adresa fakturacna
			adresaOdoslanePlatbyInherit = t('containers:ObchodnyPartnerPage.Adresa fakturačná')
			adresaOdoslanePlatby = formatAddress(get(data, 'zmluvnyUcet.adresaFakturacie'))
		} else if (get(obchodnyPartner, 'data.adresaKorespondencna')) {
			adresaOdoslanePlatby = formatAddress(get(obchodnyPartner, 'data.adresaKorespondencna'))
			adresaOdoslanePlatbyInherit = t('containers:ZmluvnyUcetDetailPage.Adresa korešpondenčná')
		} else if (get(obchodnyPartner, 'data.adresaZakaznika')) {
			adresaOdoslanePlatby = formatAddress(get(obchodnyPartner, 'data.adresaZakaznika'))
			adresaOdoslanePlatbyInherit = t('containers:ZmluvnyUcetDetailPage.Adresa trvalého pobytu')
		}

		const eFakturaEmaily = map(get(data, 'zmluvnyUcet.eFakturaEmaily'), (item, index) => (
			<tr key={index}>
				<td>{t('containers:ZmluvnyUcetDetailPage.E-mailová adresa pre e-faktúry')}</td>
				<td>
					<strong style={{ paddingRight: '20px' }}>{get(item, 'email')}</strong>
					<div className='label circle' data-color='green' />
				</td>
			</tr>
		))

		const backUrl = btoa(`${window.location.pathname}${window.location.search}`)

		return (
			<>
				<div className='content-header clearfix'>
					<Link to={backUrlLink} className='button pull-left' data-type='back-button' data-color='blue'>
						{t('containers:Späť')}
					</Link>
					<div className='header-title pull-left'>{t('containers:ZmluvnyUcetDetailPage.Detail zmluvného účtu')}</div>
					<Permissions
						allowed={[PERMISSIONS.UKON_UDAJE_ZU]}
						render={(hasPerm, actions) => {
							if (hasPerm) {
								return (
									<Link
										to={`${setRouteParams(
											ZMLUVNE_UCTY_UPRAVIT,
											get(interakcia, 'opCislo'),
											get(data, 'zmluvnyUcet.cislo')
										)}?backUrl=${backUrl}`}
										className='button pull-right'
										data-color='blue'
									>
										{t('translation:ZmluvneUcty.Upraviť nastavenie ZÚ')}
									</Link>
								)
							}

							return (
								<Link
									to={`${setRouteParams(
										ZMLUVNE_UCTY_UPRAVIT,
										get(interakcia, 'opCislo'),
										get(data, 'zmluvnyUcet.cislo')
									)}?backUrl=${backUrl}`}
									className={cx('button', 'pull-right', { disabled: true })}
									data-color='blue'
									onClick={(e) => {
										e.preventDefault()
										actions.openForbiddenModal()
									}}
								>
									<Tooltip
										html={<span>{t('containers:ZmluvnyUcetDetailPage.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>}
										position='left'
										trigger='mouseenter'
										theme='light'
									>
										{t('translation:ZmluvneUcty.Upraviť nastavenie ZÚ')}
									</Tooltip>
								</Link>
							)
						}}
					/>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-header'>
							<div className='row'>
								<div className='col-4 vertical-center'>
									<strong>
										{t('containers:ZmluvnyUcetDetailPage.Zmluvný účet')} {get(data, 'zmluvnyUcet.cislo')}
									</strong>
								</div>
								<div className='col-4 vertical-center'>
									{get(data, 'zmluvnyUcet.oznacenie') && (
										<strong>
											{t('containers:ZmluvnyUcetDetailPage.Označenie')} {get(data, 'zmluvnyUcet.oznacenie')}
										</strong>
									)}
								</div>
							</div>
						</div>
						<div className='box-content'>
							<table className='content-table'>
								{/* UC06 - Spôsob platby */}

								<thead>
									<tr>
										<th colSpan='4'>{t('containers:ZmluvnyUcetDetailPage.Spôsob platby')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{t('containers:ZmluvnyUcetDetailPage.Prijaté platby')}</td>
										<td colSpan='2'>
											<strong style={{ paddingRight: '20px' }}>
												{upperFirst(get(data, 'zmluvnyUcet.doslePlatbySposob.nazov', '-'))}
											</strong>
											{get(data, 'zmluvnyUcet.sepaMandat') && (
												<Tooltip
													html={
														<div style={{ textAlign: 'left' }}>
															{t('containers:ZmluvnyUcetDetailPage.Číslo SEPA mandátu')}&nbsp;
															<strong>{get(data, 'zmluvnyUcet.sepaMandat.cislo', '-')}</strong>
															<br />
															{t('containers:ZmluvnyUcetDetailPage.Stav SEPA mandátu')}&nbsp;
															<strong>{get(data, 'zmluvnyUcet.sepaMandat.sepaMandatStav.nazov', '-')}</strong>
															<br />
															{t('containers:ZmluvnyUcetDetailPage.Dátum podpisu')}&nbsp;
															{formatDate(get(data, 'zmluvnyUcet.sepaMandat.podpisDatum'), '-', 'DD.MM.YYYY')}
														</div>
													}
													position='right'
													trigger='mouseenter'
													theme='light'
												>
													<div
														className='label circle'
														data-color={getSepaMandatStavColor(get(data, 'zmluvnyUcet.sepaMandat.sepaMandatStav.id'))}
													/>
												</Tooltip>
											)}
											<div style={{ marginTop: '10px' }}>
												{get(data, 'zmluvnyUcet.doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA
													? formatAddress(get(data, 'zmluvnyUcet.adresaDoslePlatby')) || '-'
													: IBAN.printFormat(get(data, 'zmluvnyUcet.doslePlatbyBankoveSpojenie.IBAN') || '') || '-'}
											</div>
											{get(data, 'zmluvnyUcet.sepaMandat.dokumentId') && (
												<div style={{ marginTop: '10px' }}>
													<a
														className='file'
														onClick={() =>
															openDataUriWindow(
																get(data, 'zmluvnyUcet.sepaMandat.dokumentId'),
																t('containers:ZmluvnyUcetDetailPage.SEPA Mandát')
															)
														}
														data-type='general'
														style={{ cursor: 'pointer' }}
													>
														{t('containers:ZmluvnyUcetDetailPage.SEPA Mandát')}
													</a>
												</div>
											)}
										</td>
									</tr>
									<tr>
										<td>{t('containers:ZmluvnyUcetDetailPage.Odoslané platby')}</td>
										<td colSpan='2'>
											<strong style={{ paddingRight: '20px' }}>
												{upperFirst(get(data, 'zmluvnyUcet.odoslanePlatbySposob.nazov', '-'))}
											</strong>
											<div style={{ marginTop: '10px' }}>
												{get(data, 'zmluvnyUcet.odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA
													? formatAddress(get(data, 'zmluvnyUcet.adresaOdoslanePlatby')) || '-'
													: IBAN.printFormat(get(data, 'zmluvnyUcet.odoslanePlatbyBankoveSpojenie.IBAN') || '') || '-'}
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan='4'>
											<div className='hr' style={{ marginTop: '20px', marginBottom: 0 }} />
										</td>
									</tr>
								</tbody>

								{/* UC04 - Aktivácia / deaktivácia eFaktúry */}

								<thead>
									<tr>
										<th colSpan='4'>{t('containers:ZmluvnyUcetDetailPage.Spôsob fakturácie')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{t('containers:ZmluvnyUcetDetailPage.Fakturačná adresa')}</td>
										<td colSpan='2'>
											<strong style={{ position: 'relative', paddingRight: '20px' }}>
												{adresaFakturacie}
												{adresaFakturacieInherit && (
													<Tooltip
														html={
															<>
																<span>{t('components:UserSubheader.Odvodená adresa z')}</span>
																<br />
																<span>
																	<strong>{adresaFakturacieInherit}</strong>
																</span>
															</>
														}
														position='bottom'
														trigger='mouseenter'
														theme='light'
													>
														<div className='info-icon' />
													</Tooltip>
												)}
											</strong>
											<div className='label circle' data-color={isEmpty(eFakturaEmaily) && 'green'} />
										</td>
										<td className='clearfix' />
									</tr>
									{eFakturaEmaily}
									<tr>
										<td colSpan='4'>
											<div className='hr' style={{ marginTop: '20px', marginBottom: 0 }} />
										</td>
									</tr>
								</tbody>

								{/* Doplnkové adresy */}

								<thead>
									<tr>
										<th colSpan='4'>{t('containers:ZmluvnyUcetDetailPage.Doplnkové adresy')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{t('containers:ZmluvnyUcetDetailPage.Doručovacia adresa pre poštové poukazy')}</td>
										<td colSpan='2'>
											<strong style={{ position: 'relative', paddingRight: '20px' }}>
												{adresaOdoslanePlatby}
												{adresaOdoslanePlatbyInherit && (
													<Tooltip
														html={
															<>
																<span>{t('components:UserSubheader.Odvodená adresa z')}</span>
																<br />
																<span>
																	<strong>{adresaOdoslanePlatbyInherit}</strong>
																</span>
															</>
														}
														position='bottom'
														trigger='mouseenter'
														theme='light'
													>
														<div className='info-icon' />
													</Tooltip>
												)}
											</strong>
										</td>
										<td />
									</tr>
									<tr>
										<td colSpan='4'>
											<div className='hr' style={{ marginTop: '20px', marginBottom: 0 }} />
										</td>
									</tr>
								</tbody>

								{/* Upomínanie */}

								<thead>
									<tr>
										<th colSpan='4'>{t('containers:ZmluvnyUcetDetailPage.Upomínanie')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{t('containers:ZmluvnyUcetDetailPage.Metóda upomínania')}</td>
										<td>
											<strong>{get(data, 'zmluvnyUcet.metodaUpominania.nazov', '-')}</strong>
										</td>
										<td />
									</tr>
									<tr>
										<td>{t('containers:ZmluvnyUcetDetailPage.Adresa pre zasielanie upomienok')}</td>
										<td colSpan='2'>
											<strong style={{ position: 'relative', paddingRight: '20px' }}>
												{adresaUpominacia}
												{adresaUpominaciaInherit && (
													<Tooltip
														html={
															<>
																<span>{t('components:UserSubheader.Odvodená adresa z')}</span>
																<br />
																<span>
																	<strong>{adresaUpominaciaInherit}</strong>
																</span>
															</>
														}
														position='bottom'
														trigger='mouseenter'
														theme='light'
													>
														<div className='info-icon' />
													</Tooltip>
												)}
											</strong>
										</td>
										<td />
									</tr>
									<tr>
										<td colSpan='4'>
											<div className='hr' style={{ marginTop: '20px', marginBottom: 0 }} />
										</td>
									</tr>
								</tbody>

								{/* Odlišný príjemca / platca */}

								{this.formatPartnerTable(t('containers:ZmluvnyUcetDetailPage.Odlišný platca pre upomienky'), opOdlisnyPlatcaUpomienky, true)}
								{this.formatPartnerTable(t('containers:ZmluvnyUcetDetailPage.Odlišný príjemca'), opOdlisnyPrijemca, true)}
								{this.formatPartnerTable(t('containers:ZmluvnyUcetDetailPage.Odlišný platca'), opOdlisnyPlatca, true)}
								{this.formatPartnerTable(t('containers:ZmluvnyUcetDetailPage.Odlišný platca pre došlé platby'), opOdlisnyPlatcaDoslePlatby)}
							</table>
							<br />
						</div>
						{get(data, 'zmluvnyUcet.poslednaZmenaDatum') && (
							<div className='box-footer'>
								{t('containers:Posledná zmena z')}&nbsp;
								{formatDateWithTime(get(data, 'zmluvnyUcet.poslednaZmenaDatum'))}
							</div>
						)}
					</div>
				</div>
			</>
		)
	}

	formatPartnerTable = (title, obchodnyPartner, divider) => {
		const { t } = this.props

		return (
			<>
				<thead className='bordered'>
					<tr>
						<th colSpan='3'>{title}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{t('containers:ZmluvnyUcetDetailPage.Meno a priezvisko')}</td>
						<td>
							<strong>{trim(join([get(obchodnyPartner, 'meno', ''), get(obchodnyPartner, 'priezvisko', '')], ' ')) || '-'}</strong>
						</td>
						<td />
					</tr>
					<tr>
						<td>{t('containers:ZmluvnyUcetDetailPage.Číslo obchodného partnera')}</td>
						<td>
							<strong>{get(obchodnyPartner, 'cislo', '-')}</strong>
						</td>
						<td />
					</tr>
					<tr>
						<td>{t('containers:ZmluvnyUcetDetailPage.Adresa')}</td>
						<td>
							<strong>{formatAddress(get(obchodnyPartner, 'adresaZakaznika')) || '-'}</strong>
						</td>
						<td />
					</tr>
					{divider && (
						<tr>
							<td colSpan='4'>
								<div className='hr' style={{ marginTop: '20px', marginBottom: 0 }} />
							</td>
						</tr>
					)}
				</tbody>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	zmluvnyUcet: get(state, 'obchodnyPartner.zmluvnyUcetDetail'),
	obchodnyPartner: get(state, 'obchodnyPartner.detail')
})

const mapDispatchToProps = (dispatch) => ({
	obchodniPartneriActions: bindActionCreators(ObchodniPartneriActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_OP_ZMLUVY])
)(ZmluvnyUcetDetailPage)
