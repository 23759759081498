import dayjs from 'dayjs'
import { get, isEmpty, some } from 'lodash'

import { BUSINESS_CHANNELS } from '../../../../utils/enums'

const validate = (values, props) => {
	const { originalValues } = props

	const errors = {}

	if (!values.ukonVstup) {
		errors.ukonVstup = props.t('components:ZmluvnyUcetPlatbaEditInfo.validate.Vstup do úkonu je povinný')
	}

	if (values.podpisMiesto) {
		if (values.podpisMiesto.length > 100) {
			errors.podpisMiesto = props.t('components:ZmluvnyUcetPlatbaEditInfo.validate.Miesto podpisu úkonu môže mať najviac 100 znakov')
		}
	}

	if (!values.platnostDo) {
		errors.platnostDo = props.t('components:SplnomocneniaInfo.validate.Dátum platnosti je povinný')
	} else if (get(originalValues, 'originPlatnostDo')) {
		if (values.platnostDo > get(originalValues, 'originPlatnostDo')) {
			errors.platnostDo = props.t('components:SplnomocneniaInfo.validate.Dátum platnosti nie je možné predĺžiť')
		}
	} else if (get(props, 'actualBusinessChannel.id') != BUSINESS_CHANNELS.BACK_OFFICE && values.platnostDo < dayjs().startOf('day')) {
		errors.platnostDo = props.t('components:SplnomocneniaInfo.validate.Dátum platnosti nie je platný - nemôžete vybrať dátum z minulosti')
	}

	if (!isEmpty(values.dokumenty)) {
		some(values.dokumenty, (dokument) => {
			if (!dokument.dataAsBase64) {
				errors.dokumenty = props.t('components:ZmluvnyUcetPlatbaEditInfo.validate.Neplatný dokument', { nazovDokumentu: dokument.name })
				return true
			}
		})
	}

	if (values.poznamka && values.poznamka.length > 2000) {
		errors.poznamka = props.t('translation:Common.validate.Maximálna dĺžka poznámky je 2000 znakov')
	}

	return errors
}

export default validate
