import { get } from 'lodash'
import {
	PONUKA_MIESTO_SPOTREBY_LOAD_START,
	PONUKA_MIESTO_SPOTREBY_LOAD_DONE,
	PONUKA_MIESTO_SPOTREBY_LOAD_FAIL,
	PONUKA_OBCHODNY_PARTNER_LOAD_START,
	PONUKA_OBCHODNY_PARTNER_LOAD_DONE,
	PONUKA_OBCHODNY_PARTNER_LOAD_FAIL,
	PONUKA_ZMLUVNY_VZTAH_LOAD_START,
	PONUKA_ZMLUVNY_VZTAH_LOAD_DONE,
	PONUKA_ZMLUVNY_VZTAH_LOAD_FAIL,
	PONUKA_SEARCH_PAGE_LOAD_START,
	PONUKA_SEARCH_PAGE_LOAD_DONE,
	PONUKA_SEARCH_PAGE_LOAD_FAIL
} from '../types/ponukaTypes'

import { getReq } from '../utils/request'

const initStatePart = {
	data: [],
	isLoaded: false,
	isLoading: false,
	isFailure: false
}

export const loadPonukaMiestoSpotreby = (msCislo, queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: PONUKA_MIESTO_SPOTREBY_LOAD_START,
			payload: {
				miestoSpotreby: {
					[msCislo]: {
						...initStatePart,
						isLoading: true
					}
				}
			}
		})

		const query = {
			...queryParams
		}
		try {
			const ponukaResponse = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/miesta-spotreby/${msCislo}/ponuka`, query)
			const ponuka = get(ponukaResponse, 'response.content', [])

			dispatch({
				type: PONUKA_MIESTO_SPOTREBY_LOAD_DONE,
				payload: {
					miestoSpotreby: {
						[msCislo]: {
							...initStatePart,
							data: ponuka,
							isLoaded: true,
							isLoading: false
						}
					}
				}
			})

			return ponuka
		} catch (e) {
			dispatch({
				type: PONUKA_MIESTO_SPOTREBY_LOAD_FAIL,
				payload: {
					miestoSpotreby: {
						[msCislo]: {
							...initStatePart,
							isLoaded: true,
							isFailure: true
						}
					}
				}
			})
		}
	}
}

export const loadPonukaObchodnyPartner = (queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: PONUKA_OBCHODNY_PARTNER_LOAD_START
		})

		const query = {
			...queryParams
		}
		try {
			const ponukaResponse = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ponuka`, query)
			const ponuka = get(ponukaResponse, 'response.content', [])

			dispatch({
				type: PONUKA_OBCHODNY_PARTNER_LOAD_DONE,
				payload: {
					ponuka
				}
			})

			return ponuka
		} catch (e) {
			dispatch({
				type: PONUKA_OBCHODNY_PARTNER_LOAD_FAIL
			})
		}
	}
}

export const loadPonukaZmluvnyVztah = (zvCislo, queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: PONUKA_ZMLUVNY_VZTAH_LOAD_START,
			payload: {
				zmluvnyVztah: {
					[zvCislo]: {
						...initStatePart,
						isLoading: true
					}
				}
			}
		})

		const query = {
			...queryParams
		}
		try {
			const ponukaResponse = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/zmluvne-vztahy/${zvCislo}/ponuka`, query)
			const ponuka = get(ponukaResponse, 'response.content', [])

			dispatch({
				type: PONUKA_ZMLUVNY_VZTAH_LOAD_DONE,
				payload: {
					zmluvnyVztah: {
						[zvCislo]: {
							...initStatePart,
							data: ponuka,
							isLoaded: true,
							isLoading: false
						}
					}
				}
			})

			return ponuka
		} catch (e) {
			dispatch({
				type: PONUKA_ZMLUVNY_VZTAH_LOAD_FAIL,
				payload: {
					zmluvnyVztah: {
						[zvCislo]: {
							...initStatePart,
							isLoaded: true,
							isFailure: true
						}
					}
				}
			})
		}
	}
}

export const loadPonukaSearchPage = (queryParams = {}) => {
	return async (dispatch) => {
		dispatch({
			type: PONUKA_SEARCH_PAGE_LOAD_START
		})

		try {
			const ponukaResponse = await getReq(`/api/v2/ponuka`, queryParams)
			const ponuka = get(ponukaResponse, 'response.content', [])

			dispatch({
				type: PONUKA_SEARCH_PAGE_LOAD_DONE,
				payload: {
					ponuka
				}
			})

			return ponuka
		} catch (e) {
			dispatch({
				type: PONUKA_SEARCH_PAGE_LOAD_FAIL
			})
		}
	}
}
