import { isEmpty } from 'lodash'

const validate = (values, props) => {
	const errors = {}

	if (!values.biznisKanal) {
		errors.biznisKanal = props.t('translation:Nastavenia.validate.Biznis kanál je povinný')
	}

	if (isEmpty(values.podpisMiesto)) {
		errors.podpisMiesto = props.t('translation:Nastavenia.validate.Miesto podpisu je povinné')
	}

	return errors
}

export default validate
