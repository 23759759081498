import { map, get, find, set, trim, size, forEach, split } from 'lodash'

// utils
import postalCodes from 'postal-codes-js'
import { getReq } from '../../utils/request'
import { ADDRESS_SEARCH_FIELD_MODE } from '../../utils/enums'

export async function searchOptionsObec(searchValue) {
	try {
		const { source, editAddress } = this.state

		const obecNazov = trim(searchValue) || null

		// call autocomplete only if country is SK
		if (get(editAddress, 'stat') != 'SK') {
			return
		}

		const res = await getReq('/api/v2/adresa/obec', {
			statId: get(editAddress, 'stat'),
			nazovLike: obecNazov,
			firma: source
		})

		const obceOptions = map(get(res, 'response.content', []), (obec) => ({
			...(obec || {}),
			value: get(obec, 'nazov'),
			label: get(obec, 'nazov')
		}))

		this.setState({ obceOptions })

		return obceOptions
	} catch (err) {
		return this.state.obceOptions
	}
}

export async function searchOptionsUlica(searchValue) {
	try {
		const { source, editAddress, obceOptions } = this.state

		if (get(editAddress, 'stat') != 'SK') {
			return
		}

		const ulicaNazov = trim(searchValue) || null

		const selectedObec = find(obceOptions, {
			value: get(editAddress, 'obec')
		})
		const obecId = get(selectedObec, 'id')
		if (!obecId) {
			return
		}

		const res = await getReq('/api/v2/adresa/ulica', {
			obecId,
			nazovLike: ulicaNazov,
			firma: source
		})

		const uliceOptions = map(get(res, 'response.content', []), (ulica) => ({
			...(ulica || {}),
			value: get(ulica, 'nazov'),
			label: get(ulica, 'nazov')
		}))

		this.setState({ uliceOptions })

		return uliceOptions
	} catch (err) {
		return this.state.uliceOptions
	}
}

export async function searchOptionsPsc(searchValue) {
	try {
		const { source, editAddress, uliceOptions, obceOptions, mode } = this.state

		let pscNazov = trim(searchValue) || null

		const selectedObec = find(obceOptions, {
			value: get(editAddress, 'obec')
		})
		const obecId = get(selectedObec, 'id')
		if (!obecId) {
			return
		}

		const selectedUlica = find(uliceOptions, {
			value: get(editAddress, 'ulica'),
			mestoId: obecId
		})
		const ulicaId = get(selectedUlica, 'id')
		if (!ulicaId) {
			return
		}

		// normalize SK PSC format from XXXXX to XXX XX
		if (get(editAddress, 'stat') == 'SK' && size(pscNazov) == 5) {
			pscNazov = `${pscNazov.substr(0, 3)} ${pscNazov.substr(3)}`
		}

		if (get(editAddress, 'stat') == 'CZ' && size(pscNazov) == 5) {
			pscNazov = `${pscNazov.substr(0, 3)} ${pscNazov.substr(3)}`
		}

		const body = {
			ulicaId,
			psc: pscNazov,
			firma: source
		}
		if (mode == ADDRESS_SEARCH_FIELD_MODE.SUPISNE_CISLO) {
			body.orientacneCislo = get(editAddress, 'orientacneCislo') || undefined
		}

		const res = await getReq('/api/v2/adresa/psc', body)

		const pscOptions = map(get(res, 'response.content', []), (psc) => ({
			value: psc,
			label: psc
		}))

		this.setState({ pscOptions })

		return pscOptions
	} catch (err) {
		return this.state.pscOptions
	}
}

export function handleChangeAddress(field, value) {
	const { editAddress, mode } = this.state

	const newState = {
		mode,
		editAddress: {
			...editAddress
		}
	}

	set(newState, `editAddress.${field}`, value)

	// reset other field if value is null or the value change on different
	if ((field == 'stat' && !value) || (field == 'stat' && value != get(editAddress, 'stat'))) {
		set(newState, 'editAddress.obec', '')
		set(newState, 'editAddress.ulica', '')
		if (mode == ADDRESS_SEARCH_FIELD_MODE.SUPISNE_CISLO) {
			set(newState, 'editAddress.orientacneCislo', '')
			set(newState, 'editAddress.supisneCislo', '')
		} else {
			set(newState, 'editAddress.parcelneCislo', '')
		}
		set(newState, 'editAddress.psc', '')
		set(newState, 'obceOptions', [])
		set(newState, 'uliceOptions', [])
		set(newState, 'pscOptions', [])
	}
	// reset other field exept field "stat" if value is null or the value change on different
	if ((field == 'obec' && !value) || (field == 'obec' && value != get(editAddress, 'obec'))) {
		set(newState, 'editAddress.ulica', '')
		if (mode == ADDRESS_SEARCH_FIELD_MODE.SUPISNE_CISLO) {
			set(newState, 'editAddress.orientacneCislo', '')
			set(newState, 'editAddress.supisneCislo', '')
		} else {
			set(newState, 'editAddress.parcelneCislo', '')
		}
		set(newState, 'editAddress.psc', '')
		set(newState, 'uliceOptions', [])
		set(newState, 'pscOptions', [])
	}

	if (field == 'ulica') {
		if (mode == ADDRESS_SEARCH_FIELD_MODE.SUPISNE_CISLO) {
			set(newState, 'editAddress.orientacneCislo', '')
			set(newState, 'editAddress.supisneCislo', '')
		} else {
			set(newState, 'editAddress.parcelneCislo', '')
		}
		set(newState, 'editAddress.psc', '')
		set(newState, 'pscOptions', [])
	}

	if (field == 'orientacneCislo') {
		set(newState, 'editAddress.psc', '')
		set(newState, 'pscOptions', [])
	}

	if (field == 'psc') {
		const psc = value
			? [
					{
						value,
						label: value
					}
			  ]
			: []
		set(newState, 'pscOptions', psc)
	}

	const { errors, warnings } = revalidate.call(this, newState)

	this.setState(
		{
			...newState,
			errors,
			warnings
		},
		async () => {
			if (field == 'obec') {
				await searchOptionsUlica.call(this, value)
			}
			if (field == 'ulica' || field == 'orientacneCislo') {
				await searchOptionsPsc.call(this)
			}
		}
	)
}

export function revalidate(newState) {
	const { t } = this.props
	const { editAddress, mode } = newState

	const errors = {}
	const warnings = {}

	const stat = get(editAddress, 'stat')
	if (!stat) {
		errors.stat = t('translation:Common.Štát nesmie byť prázdny')
	}

	if (!get(editAddress, 'obec')) {
		errors.obec = t('translation:Common.Obec nesmie byť prázdna')
	} else if (size(get(editAddress, 'obec', '')) > 40) {
		errors.obec = t('translation:Common.Obec môže mať najviac 40 znakov')
	}

	if (!get(editAddress, 'ulica')) {
		errors.ulica = t('translation:Common.Ulica nesmie byť prázdna')
	} else if (size(get(editAddress, 'ulica', '')) > 60) {
		errors.ulica = t('translation:Common.Ulica môže mať najviac 60 znakov')
	}

	if (mode == ADDRESS_SEARCH_FIELD_MODE.SUPISNE_CISLO) {
		if (stat != 'SK') {
			return
		}
		if (get(editAddress, 'orientacneCislo')) {
			const reValidOrientacneCislo = new RegExp(/^[1-9][0-9]{0,4}[A-Z]{0,1}$/gi)
			if (!reValidOrientacneCislo.test(get(editAddress, 'orientacneCislo'))) {
				errors.orientacneCislo = t('translation:Common.Orientačné číslo nemá správny formát')
			} else if (size(get(editAddress, 'orientacneCislo', '')) > 10) {
				errors.orientacneCislo = t('translation:Common.Orientačné číslo môže mať najviac 10 znakov')
			}
		}

		if (get(editAddress, 'supisneCislo')) {
			const reValidSupisneCislo = new RegExp(/^[1-9][0-9]{0,4}$/gi)
			if (!reValidSupisneCislo.test(get(editAddress, 'supisneCislo'))) {
				errors.supisneCislo = t('translation:Common.Súpisné číslo nemá správny formát')
			} else if (size(get(editAddress, 'supisneCislo', '')) > 10) {
				errors.supisneCislo = t('translation:Common.Súpisné číslo môže mať najviac 10 znakov')
			}
		}

		if (!get(editAddress, 'orientacneCislo') && !get(editAddress, 'supisneCislo')) {
			errors.orientacneAleboSupisneCislo = t('translation:Common.Súpisné alebo orientačné číslo musí byť zadané')
		} else if (
			get(editAddress, 'supisneCislo') &&
			get(editAddress, 'orientacneCislo') &&
			get(editAddress, 'supisneCislo') == get(editAddress, 'orientacneCislo')
		) {
			warnings.orientacneAleboSupisneCislo = t('translation:Common.Overte, či súpisné a orientačné číslo sú skutočne rovnaké')
		}
	} else {
		if (stat != 'SK') {
			return
		}
		if (!get(editAddress, 'parcelneCislo')) {
			errors.parcelneCislo = t('translation:Common.Parcelné číslo musí byť zadané')
		} else {
			const reValidParcelneCislo = new RegExp(/^[1-9]+[0-9]*\/?[0-9]*$/gi)
			if (!reValidParcelneCislo.test(get(editAddress, 'parcelneCislo'))) {
				errors.parcelneCislo = t('translation:Common.Parcelné číslo nemá správny formát')
			} else if (size(get(editAddress, 'parcelneCislo', '')) > 21) {
				errors.parcelneCislo = t('translation:Common.Parcelné číslo môže mať najviac 21 znakov')
			} else {
				const parts = split(get(editAddress, 'parcelneCislo'), '/')
				forEach(parts, (part) => {
					if (size(part) > 10) {
						errors.parcelneCislo = t('translation:Common.Parcelné číslo nemá správny formát')
					}
				})
			}
		}
	}

	const psc = get(editAddress, 'psc')
	if (!psc) {
		errors.psc = t('translation:Common.PSČ nesmie byť prázdne')
	} else if (stat == 'SK') {
		const reWhiteSpace = new RegExp(/\s/g)
		const reNumber = new RegExp(/^[0-9\s]+$/g)
		const hasWhiteSpace = reWhiteSpace.test(psc)
		const isNumber = reNumber.test(psc)

		if (hasWhiteSpace && isNumber) {
			if (size(psc) != 6) {
				errors.psc = t('atoms:AddressField.PSČ by malo byť 6 znakov dlhé (napr 841 07)')
			}
		} else if (isNumber) {
			if (size(psc) != 5) {
				errors.psc = t('atoms:AddressField.PSČ by malo byť 5 znakov dlhé (napr 84107)')
			}
		} else {
			errors.psc = t('atoms:AddressField.PSČ musí obsahovať iba číslice (napr 84107)')
		}
	} else if (stat == 'PL') {
		if (postalCodes.validate(stat, psc) !== true) {
			errors.psc = t('atoms:AddressField.PSČ by malo byť 6 znakov dlhé (napr 00-001)')
		}
	} else if (stat == 'CZ') {
		const reWhiteSpace = new RegExp(/\s/g)
		const reNumber = new RegExp(/^[0-9\s]+$/g)
		const hasWhiteSpace = reWhiteSpace.test(psc)
		const isNumber = reNumber.test(psc)

		if (hasWhiteSpace && isNumber) {
			if (size(psc) != 6) {
				errors.psc = t('atoms:AddressField.PSČ by malo byť 6 znakov dlhé (napr 100 00)')
			}
		} else if (isNumber) {
			if (size(psc) != 5) {
				errors.psc = t('atoms:AddressField.PSČ by malo byť 5 znakov dlhé (napr 10000)')
			}
		} else {
			errors.psc = t('atoms:AddressField.PSČ musí obsahovať iba číslice (napr 10000)')
		}
	} else if (stat == 'AT') {
		if (postalCodes.validate(stat, psc) !== true) {
			errors.psc = t('atoms:AddressField.PSČ by malo byť 4 znaky dlhé (napr 1010)')
		}
	} else if (stat == 'UA') {
		if (postalCodes.validate(stat, psc) !== true) {
			errors.psc = t('atoms:AddressField.PSČ by malo byť 5 znakov dlhé (napr 01014)')
		}
	} else if (stat == 'HU') {
		if (postalCodes.validate(stat, psc) !== true) {
			errors.psc = t('atoms:AddressField.PSČ by malo byť 4 znaky dlhé (napr 1188)')
		}
	} else if (postalCodes.validate(stat, psc) !== true) {
		errors.psc = t('atoms:AddressField.PSČ nemá správny tvar')
	} else if (size(psc) > 10) {
		errors.psc = t('translation:Common.PSČ môže mať najviac 10 znakov')
	}

	return {
		errors,
		warnings
	}
}
