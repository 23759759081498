import { chunk, flatten, map, without, isArray } from 'lodash'
import { postReq } from './request'
import { REDUCER_KEYS } from '../reducers'
import { CRASH_ERROR_TYPES } from './enums'

export const ERROR_MSG_KEY = 'error_messages'

/**
 * @param {string(CRASH_ERROR_TYPES)} type
 * @param {object} stack
 */
export const setErrorMsgToLocalStorage = (type, stack) => {
	try {
		const persistAuth = JSON.parse(localStorage.getItem(`persist:${REDUCER_KEYS.AUTH}`)) || null
		const user = persistAuth && persistAuth.user ? JSON.parse(persistAuth.user) : null
		const userId = user && user.id ? user.id : null

		const persistInterakcie = JSON.parse(sessionStorage.getItem(`persist:${REDUCER_KEYS.INTERAKCIE}`)) || null
		const interakcia = persistInterakcie && persistInterakcie.detail ? JSON.parse(persistInterakcie.detail) : null
		const interakciaId = interakcia && interakcia.data && interakcia.data.id ? interakcia.data.id : null

		const customError = {
			stack,
			type,
			timestamp: new Date(),
			userId,
			interakciaId
		}
		const previousErrors = JSON.parse(localStorage.getItem(ERROR_MSG_KEY)) || []
		const newErrors = [...previousErrors, customError]
		localStorage.setItem(ERROR_MSG_KEY, JSON.stringify(newErrors))
	} catch (e) {
		// eslint-disable-next-line
		console.log(e)
	}
}

const releaseErrorMessagesToServer = async () => {
	try {
		const CHUNK_SIZE = 10
		const errors = JSON.parse(localStorage.getItem(ERROR_MSG_KEY)) || []

		if (!isArray(errors) || errors.length < CHUNK_SIZE) {
			return null
		}

		// use chung for limitation log requests to the server
		const chunkErros = chunk(errors, CHUNK_SIZE)
		const restErrorsPromises = map(chunkErros, async (chunk) => {
			try {
				await postReq('/api/v0/logovanie', null, chunk)
				return null
			} catch (e) {
				return chunk
			}
		})

		const restErrors = await Promise.all(restErrorsPromises)
		const restErrorsWithoutNull = without(restErrors, null)
		const restErrorsFlatten = flatten(restErrorsWithoutNull)

		localStorage.setItem(ERROR_MSG_KEY, JSON.stringify(restErrorsFlatten))
	} catch (e) {
		setErrorMsgToLocalStorage(CRASH_ERROR_TYPES.APPLICATION, { msg: e.message })
	}
}

/**
 * sending error logs to the server is triggering in every init application and then every 10 minutes
 */
const init = () => {
	releaseErrorMessagesToServer()
	setInterval(releaseErrorMessagesToServer, 1000 * 60 * 10) // trigger every 10 minutes
}

export default {
	init
}
