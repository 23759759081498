import React from 'react'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { get, isEmpty, forEach } from 'lodash'

// atoms
import { DropZoneField } from '../../../atoms'

class UkonDokumentyField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func,
		change: PropTypes.func,
		field: PropTypes.string,
		parentFieldName: PropTypes.string,
		editMode: PropTypes.string,
		columnsCount: PropTypes.number,
		formValues: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape()
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (dokumenty) => {
		const { povinny, t } = this.props

		if (povinny && isEmpty(dokumenty)) {
			return t('translation:Common.validate.Dokumenty sú povinné')
		}
		if (!isEmpty(dokumenty)) {
			forEach(dokumenty, (document) => {
				if (!get(document, 'dataAsBase64')) {
					return t('translation:Common.validate.Neplatný dokument', {
						nazovDokumentu: get(document, 'name')
					})
				}
			})
		}
	}

	render() {
		const { parentFieldName, t } = this.props

		return (
			<table className='content-table padded bordered' cellSpacing='0'>
				<tbody>
					<tr>
						<td>
							<strong>{t('translation:Common.Vstupné dokumenty k úkonu')}</strong>
							<Field
								name={`${parentFieldName}.dokumentyVstupne`}
								component={DropZoneField}
								validate={this.validate}
								placeholder={t('translation:Common.Klikni alebo presuň súbory pre nahratie')}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		)
	}
}

export default compose(withTranslation('components'))(UkonDokumentyField)
