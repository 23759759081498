import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Field, reduxForm, propTypes, change, getFormValues } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { every, map, get, upperFirst, isEmpty, some, filter } from 'lodash'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Tooltip } from 'react-tippy'

import validate from './validateForm'

// atoms
import { SelectField, DropZoneField, DatePickerField, TextareaField, TextInputField } from '../../../../atoms'

// utils
import { FORMS, BUSINESS_CHANNELS } from '../../../../utils/enums'
import { formatDate } from '../../../../utils/date'
import { setRouteParams, VZTAHY_ZOZNAM } from '../../../../utils/routes'
import { isValidacneKriteriumError } from '../../../../utils/form'

class SplnomocneniaInfo extends React.Component {
	static propTypes = {
		...propTypes,
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.number.isRequired,
				opCislo: PropTypes.string.isRequired
			}).isRequired,
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		originalValues: PropTypes.shape(),
		t: PropTypes.func
	}

	constructor(props) {
		super(props)

		this.state = {
			ukonVstupOptions: []
		}
	}

	componentDidMount() {
		const { touch, dispatch, actualBusinessChannel, formValues, ciselniky } = this.props

		if (!get(formValues, 'ukonVstup')) {
			// trigger validation immediately after init form
			dispatch(change(FORMS.SPLNOMOCNENIA, 'ukonVstup', null, true))
		}
		if (get(actualBusinessChannel, 'id') == BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(get(formValues, 'dokumenty'))) {
			// trigger validation immediately after init form
			dispatch(change(FORMS.SPLNOMOCNENIA, 'dokumenty', [], true))
		}
		if (get(actualBusinessChannel, 'id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			touch('dokumentSplnomocnenia')
		}

		dispatch(touch('platnostDo'))
		dispatch(touch('splnomocnenieDovodUkoncenia'))

		this.setState({
			ukonVstupOptions: map(get(ciselniky, 'ukonVstup', []), (ukonVstup) => ({
				label: upperFirst(ukonVstup.nazov),
				value: ukonVstup.id
			}))
		})
	}

	render() {
		const {
			handleSubmit,
			invalid,
			interakcia,
			originalValues,
			actualBusinessChannel,
			t,
			validacneKriteria,
			schvalovacieKriteria,
			onDeleteFile,
			formTitle,
			onBackClick,
			formValues
		} = this.props

		const { ukonVstupOptions } = this.state

		let minTimeDatumPrijatiaZiadosti = null
		let maxTimeDatumPrijatiaZiadosti = null
		if (actualBusinessChannel.id == BUSINESS_CHANNELS.ZSE_CENTRUM) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (actualBusinessChannel.id == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (actualBusinessChannel.id == BUSINESS_CHANNELS.BACK_OFFICE) {
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}

		let minDatePlatnostDo = null
		let maxDatePlatnostDo = null
		if (get(actualBusinessChannel, 'id') == BUSINESS_CHANNELS.ZSE_CENTRUM) {
			if (get(originalValues, 'platnostOd') > dayjs().endOf('day')) {
				minDatePlatnostDo = dayjs(get(originalValues, 'platnostOd'))
				maxDatePlatnostDo = dayjs(get(originalValues, 'platnostOd'))
			} else {
				minDatePlatnostDo = dayjs().startOf('day')
				maxDatePlatnostDo = dayjs().startOf('day')
			}
		} else if (get(actualBusinessChannel, 'id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			if (get(originalValues, 'platnostOd') > dayjs().endOf('day')) {
				minDatePlatnostDo = dayjs(get(originalValues, 'platnostOd'))
			} else {
				minDatePlatnostDo = dayjs().startOf('day')
			}
			if (get(originalValues, 'platnostDo')) {
				maxDatePlatnostDo = dayjs(get(originalValues, 'platnostDo'))
			}
		} else {
			if (get(originalValues, 'platnostOd')) {
				minDatePlatnostDo = dayjs(get(originalValues, 'platnostOd'))
			}
			if (get(originalValues, 'platnostDo')) {
				maxDatePlatnostDo = dayjs(get(originalValues, 'platnostDo'))
			}
		}

		// result from procesnyKonfigurator if form is valid
		const passValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumError), { vysledok: true })
		const errorSchvalovacieKriterium = some(schvalovacieKriteria, { error: true })

		const isValid = passValidacneKriterium && !errorSchvalovacieKriterium

		const btn = (
			<button
				className={cx('button', 'pull-right', { disabled: !isValid || invalid })}
				disabled={!isValid || invalid}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('components:SplnomocneniaInfo.Pokračovať')}
			</button>
		)

		let btnComponent
		if (!isValid) {
			let btnTooltipText
			if (!passValidacneKriterium) {
				const valKrit = filter(validacneKriteria, (validacneKriterium) => !validacneKriterium.vysledok)
				btnTooltipText = map(valKrit, (validacneKriterium) => (
					<div>
						{validacneKriterium.nazov}: {validacneKriterium.popis}
					</div>
				))
			} else if (errorSchvalovacieKriterium) {
				const schvalKrit = filter(schvalovacieKriteria, (schvalovacieKriterium) => !schvalovacieKriterium.vysledok || schvalovacieKriterium.error)
				btnTooltipText = map(schvalKrit, (schvalovacieKriterium) => (
					<div>
						{schvalovacieKriterium.nazov}: {schvalovacieKriterium.popis}
					</div>
				))
			}
			btnComponent = (
				<Tooltip html={btnTooltipText} position='bottom' trigger='mouseenter' theme='light'>
					{btn}
				</Tooltip>
			)
		} else {
			btnComponent = btn
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{btnComponent}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('components:SplnomocneniaInfo.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<Link
						to={setRouteParams(VZTAHY_ZOZNAM, get(interakcia, 'data.opCislo'))}
						className='button pull-right'
						data-type='outline'
						data-color='red'
					>
						{t('components:SplnomocneniaInfo.Zrušiť')}
					</Link>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('components:SplnomocneniaInfo.Aktuálne hodnoty')}</th>
										<th>{t('components:SplnomocneniaInfo.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('components:SplnomocneniaInfo.Vstup do úkonu')}</strong>
										</td>
										<td>{get(originalValues, 'ukonVstup.nazov')}</td>
										<td>
											<Field
												name='ukonVstup'
												component={SelectField}
												options={ukonVstupOptions}
												isSearchable={false}
												isDisabled={ukonVstupOptions.length == 0}
												classNamePrefix='react-select'
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:SplnomocneniaInfo.Miesto podpisu úkonu')}</strong>
										</td>
										<td>{get(originalValues, 'podpisMiesto')}</td>
										<td>
											<Field
												name='podpisMiesto'
												component={TextInputField}
												placeholder={t(
													'components:SplnomocneniaInfo.Zadajte miesto podpisu úkonu (ak nejde o prípad odloženého podpisu)'
												)}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:SplnomocneniaInfo.Dátum prijatia žiadosti')}</strong>
										</td>
										<td />
										<td>
											<div className='select-wrapper'>
												<Field
													name='datumPrijatiaZiadosti'
													component={DatePickerField}
													showYearDropdown
													scrollableYearDropdown
													minDate={minTimeDatumPrijatiaZiadosti?.toDate()}
													maxDate={maxTimeDatumPrijatiaZiadosti?.toDate()}
													placeholderText={t('components:SplnomocneniaInfo.Zvoľte dátum')}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('components:SplnomocneniaInfo.Číslo obchodného partnera')}</strong>
											</td>
											<td>{get(formValues, 'splnomocnenecCislo')}</td>
											<td>{get(originalValues, 'splnomocnenecCislo')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('components:SplnomocneniaInfo.Splnomocnená osoba')}</strong>
											</td>
											<td>{get(formValues, 'splnomocnenaOsoba')}</td>
											<td>{get(originalValues, 'splnomocnenaOsoba')}</td>
										</tr>
										{/* <tr>
										<td>
											<strong>{t('components:SplnomocneniaInfo.Úkon realizuje splnomocnenec')}</strong>
										</td>
										<td></td>
										<td>
											<Field
												name="ukonRealizujeSplnomocnenaOsoba"
												component={CheckboxField}
											/>
										</td>
									</tr> */}
										<tr>
											<td>
												<strong>{t('components:SplnomocneniaInfo.Platnosť od')}</strong>
											</td>
											<td>{formatDate(get(originalValues, 'platnostOd'), '', 'DD.MM.YYYY')}</td>
											<td>{formatDate(get(originalValues, 'platnostOd'), '', 'DD.MM.YYYY')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('components:SplnomocneniaInfo.Platnosť do')}</strong>
											</td>
											<td>{formatDate(get(originalValues, 'platnostDo'), '', 'DD.MM.YYYY')}</td>
											<td>
												<div className='select-wrapper'>
													<Field
														name='platnostDo'
														component={DatePickerField}
														showYearDropdown
														scrollableYearDropdown
														minDate={minDatePlatnostDo?.toDate()}
														maxDate={maxDatePlatnostDo?.toDate()}
														placeholderText={t('components:SplnomocneniaInfo.Zvoľte dátum')}
													/>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('components:SplnomocneniaInfo.Poznámka')}</strong>
										</td>
										<td />
										<td>
											<Field
												name='poznamka'
												component={TextareaField}
												rows='3'
												placeholder={t('components:SplnomocneniaInfo.Zadajte poznámku')}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:SplnomocneniaInfo.Vstupné dokumenty k úkonu')}</strong>
										</td>
										<td />
										<td>
											<Field
												name='dokumenty'
												onDelete={onDeleteFile}
												component={DropZoneField}
												placeholder={t('components:SplnomocneniaInfo.Klikni alebo presuň súbory pre nahratie')}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.SPLNOMOCNENIA,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(SplnomocneniaInfo)

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	ciselniky: state.ciselniky.data,
	actualBusinessChannel: state.auth.businessChannel.actual,
	formValues: getFormValues(FORMS.SPLNOMOCNENIA)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
