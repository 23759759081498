import React from 'react'
import { get, uniqBy, map, isEmpty, head, find, mapValues, keyBy, orderBy } from 'lodash'
import { HashLink as Link } from 'react-router-hash-link'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Field, reduxForm, initialize } from 'redux-form'

// utils
import { getReq } from '../../utils/request'
import { formatDate } from '../../utils/date'
import { ZMLUVNE_UCTY_ZOZNAM, setRouteParams } from '../../utils/routes'
import { formatZmluvnyVztahTyp } from '../../utils/zmluvnyUcet'
import { checkPermissions } from '../../utils/permissionsHoc'

// components
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'
import ElementEmptyContent from '../ElementEmptyContent'
import Modal from '../Modals/Modal'

// atoms
import SelectField from '../../atoms/SelectField'

const emptySelectState = {
	value: null,
	label: 'Všetky'
}
const stavZmluvyFilterOptions = [
	{
		value: 'aktivne',
		label: 'Len aktívne'
	}
]

const stavZmluvyOptions = [emptySelectState, ...stavZmluvyFilterOptions]

function ModalForm(props) {
	const { handleSubmit, t, onCancel } = props
	return (
		<form onSubmit={handleSubmit}>
			<div className='modal-header'>
				<h3>{t('components:Produkty.Nastavenia Widgetu')}</h3>
			</div>
			<div className='modal-content'>
				<div>
					<div>
						<label>{t('components:Produkty.Stav zmluvy')}</label>
						<Field name='stav' options={stavZmluvyOptions} component={SelectField} placeholder={t('components:Produkty.Stav zmluvy')} />
					</div>
				</div>
			</div>
			<div className='modal-footer clearfix'>
				<button className='button' data-color='red' type='button' onClick={onCancel}>
					{t('components:Produkty.Zrušiť')}
				</button>
				<button className='button pull-right' data-color='green' type='submit'>
					{t('components:Produkty.Uložiť')}
				</button>
			</div>
		</form>
	)
}

const formName = 'widgetProdukty'

ModalForm.propTypes = {
	t: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
}

const ModalContent = reduxForm({
	form: formName
})(ModalForm)

class ProduktyWidget extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		showSettings: PropTypes.bool.isRequired,
		toogleShowSettings: PropTypes.func.isRequired,
		onSubmitSettings: PropTypes.func.isRequired,
		boxId: PropTypes.string.isRequired,
		dispatch: PropTypes.func.isRequired,
		widget: PropTypes.shape(),
		permission: PropTypes.string
	}

	constructor(props) {
		super(props)
		this.state = {
			hasPermission: checkPermissions(get(props, 'authUser.roles', []), [props.permission]),
			zmluvneVztahy: {
				data: null,
				stranka: 1,
				velkostStranky: 4,
				isLoading: false,
				isFailure: false,
				isLastPage: false
			}
		}
	}

	_mounted = false

	loadZmluvneVztahyByParts = (page = 1, query) => {
		const { interakcia } = this.props
		const { zmluvneVztahy } = this.state
		try {
			if (this._mounted) {
				this.setState(
					{
						zmluvneVztahy: {
							...this.state.zmluvneVztahy,
							stranka: page,
							isLoading: true
						}
					},
					async () => {
						const response = await getReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/zmluvne-vztahy`, {
							...query,
							stranka: page,
							velkostStranky: zmluvneVztahy.velkostStranky
						})
						const { velkostStranky, aktualnaStranka, zaznamov } = get(response, 'response.strankovanie')

						const isLastPage = !(velkostStranky * aktualnaStranka < zaznamov)
						const zmluvneVztahyData = uniqBy(
							[...get(this.state.zmluvneVztahy.data, 'zmluvneVztahy', []), ...get(response, 'response.obsah.zmluvneVztahy', [])],
							'cislo'
						)

						const data = {
							zmluvneVztahy: orderBy(zmluvneVztahyData, ['vytvorenyOd'], ['desc']),
							odberneMiesta: uniqBy(
								[...get(this.state.zmluvneVztahy.data, 'odberneMiesta', []), ...get(response, 'response.obsah.odberneMiesta', [])],
								'cislo'
							),
							miestaSpotreby: uniqBy(
								[...get(this.state.zmluvneVztahy.data, 'miestaSpotreby', []), ...get(response, 'response.obsah.miestaSpotreby', [])],
								'cislo'
							),
							zmluvneUcty: uniqBy(
								[...get(this.state.zmluvneVztahy.data, 'zmluvneUcty', []), ...get(response, 'response.obsah.zmluvneUcty', [])],
								'cislo'
							)
						}

						if (this._mounted) {
							this.setState({
								zmluvneVztahy: {
									...this.state.zmluvneVztahy,
									data,
									isLoading: false,
									isLastPage
								}
							})
						}
					}
				)
			}
		} catch (e) {
			if (this._mounted) {
				this.setState({
					odberneMiesta: {
						...this.state.odberneMiesta,
						isLoading: false,
						isFailure: true
					}
				})
			}
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidMount() {
		const { dispatch, widget } = this.props
		this._mounted = true

		const { hasPermission } = this.state
		if (hasPermission) {
			const initFilters = mapValues(keyBy(get(widget, 'settings.filters'), 'name'), 'value')
			const initValues = {
				stav: 'aktivne',
				...initFilters
			}
			this.loadZmluvneVztahyByParts(1, initValues)
			dispatch(initialize(formName, initValues, true))
		}
	}

	onSettingsSaveClick = (data) => {
		const { toogleShowSettings, onSubmitSettings, boxId } = this.props
		const keys = Object.keys(data)

		// normalize data for API call
		const body = [{ name: head(keys), value: data.stav }]

		toogleShowSettings()
		onSubmitSettings(boxId, body)
	}

	commonContentContainer = (content) => {
		const { showSettings, t, toogleShowSettings } = this.props
		return (
			<>
				<Modal size='m' shown={showSettings}>
					<ModalContent t={t} onSubmit={this.onSettingsSaveClick} onCancel={toogleShowSettings} />
				</Modal>
				<div className='box-content' onScroll={this.onScrollHandler}>
					{content}
				</div>
			</>
		)
	}

	onScrollHandler = (e) => {
		const { widget } = this.props
		const { zmluvneVztahy } = this.state
		const { isLoading, isLastPage, stranka } = zmluvneVztahy
		const { clientHeight, scrollTop, scrollHeight } = e.target

		if (scrollHeight - clientHeight >= scrollTop - 5 && !isLastPage && !isLoading) {
			const zmluvneVztahyNextPage = stranka + 1

			const query = mapValues(keyBy(get(widget, 'settings.filters'), 'name'), 'value')

			this.loadZmluvneVztahyByParts(zmluvneVztahyNextPage, query)
		}
	}

	render() {
		const { t, interakcia } = this.props
		const { zmluvneVztahy, hasPermission } = this.state

		if (!hasPermission) {
			return this.commonContentContainer(<ElementFailure text='Na zobrazenie informácií nemáte potrebné oprávnenia.' />)
		}

		if (zmluvneVztahy.isLoading && zmluvneVztahy.stranka === 1) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (zmluvneVztahy.isFailure) {
			return this.commonContentContainer(<ElementFailure text='Produkty sa nepodarilo načítať.' />)
		}
		if (isEmpty(get(zmluvneVztahy, 'data.zmluvneVztahy'))) {
			return this.commonContentContainer(<ElementEmptyContent text='Pre obchodného partnera neevidujeme žiadne produkty.' />)
		}

		const produktyList = map(get(zmluvneVztahy, 'data.zmluvneVztahy', []), (zmluvnyVztah) => {
			return (
				<Link
					key={`zmluvnyVztah-${zmluvnyVztah.cislo}`}
					to={`${setRouteParams(ZMLUVNE_UCTY_ZOZNAM, interakcia.data.opCislo)}#${zmluvnyVztah.cislo}`}
					className='inner-box'
				>
					<table>
						<tbody>
							<tr>
								<td>{t('components:Produkty.Produkt')}</td>
								<td className='text-right'>
									<strong>{formatZmluvnyVztahTyp(zmluvnyVztah)}</strong>
								</td>
							</tr>
							{get(zmluvnyVztah, 'platnyOd') && (
								<tr>
									<td>{t('components:Produkty.Platné od')}</td>
									<td className='text-right'>
										<strong>{formatDate(get(zmluvnyVztah, 'platnyOd'), '-', 'DD.MM.YYYY')}</strong>
									</td>
								</tr>
							)}
							{!get(zmluvnyVztah, 'platnyOd') && (
								<tr>
									<td>{t('components:Produkty.Účinný od')}</td>
									<td className='text-right'>
										<strong>{formatDate(get(zmluvnyVztah, 'ucinnyOd'), '-', 'DD.MM.YYYY')}</strong>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</Link>
			)
		})
		if (zmluvneVztahy.isLoading) {
			produktyList.push(
				<div key='produkty-loading' className='inner-box'>
					<table>
						<tbody>
							<tr>
								<td>
									<ElementLoading />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			)
		}
		return this.commonContentContainer(produktyList)
	}
}
const mapStateToProps = (state, props) => {
	const widget = find(get(state, 'widgety.data.sorting'), { id: props.boxId })
	return {
		interakcia: state.interakcie.detail,
		authUser: state.auth.user,
		widget
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(ProduktyWidget)
