import React from 'react'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import cx from 'classnames'

import 'react-datepicker/dist/react-datepicker.css'

class DatePickerField extends React.Component {
	static propTypes = {
		input: PropTypes.shape({
			value: PropTypes.any,
			onChange: PropTypes.func.isRequired
		}).isRequired,
		meta: PropTypes.shape({
			touched: PropTypes.bool,
			error: PropTypes.string
		}).isRequired,
		label: PropTypes.string,
		disabled: PropTypes.bool,
		manualEdit: PropTypes.bool,
		showTimeSelect: PropTypes.bool,
		timeIntervals: PropTypes.number
	}

	render() {
		const {
			input: { value, onChange },
			meta: { touched, error, warning },
			label,
			disabled,
			manualEdit,
			showTimeSelect,
			timeIntervals,
			...props
		} = this.props

		const dateFormat = showTimeSelect ? 'dd.MM.yyyy, HH:mm' : 'dd.MM.yyyy'

		return (
			<div className={cx('input-wrapper', { 'has-error': touched && error })}>
				{label && <label>{label}</label>}
				<DatePicker
					{...props}
					locale='sk'
					dateFormat={dateFormat}
					selected={value ? dayjs(value).toDate() : null}
					onChange={(e) => {
						const value = e || null
						onChange(value)
					}}
					onChangeRaw={(e) => {
						if (!manualEdit) {
							e.preventDefault()
						} else if (e && e.target && e.target.value) {
							const value = dayjs(e.target.value)
							if (value.isValid()) {
								onChange(value)
							}
						}
					}}
					onKeyDown={(e) => {
						if (!manualEdit) {
							e.preventDefault()
						}
					}}
					disabled={disabled}
					showTimeSelect={showTimeSelect}
					timeIntervals={timeIntervals}
				/>
				<div className='text-danger'>{touched ? error : ''}</div>
				<div className='text-warn'>{touched ? warning : ''}</div>
			</div>
		)
	}
}

export default DatePickerField
