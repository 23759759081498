import { map, get } from 'lodash'
import {
	PLANY_SPLATOK_LOAD_DONE,
	PLANY_SPLATOK_LOAD_FAIL,
	PLANY_SPLATOK_LOAD_START,
	PLAN_SPLATOK_LOAD_START,
	PLAN_SPLATOK_LOAD_DONE,
	PLAN_SPLATOK_LOAD_FAIL,
	PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_START,
	PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_DONE,
	PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_FAIL,
	PLAN_SPLATOK_AKTUALNE_NASTAVENIE_CLEAR
} from '../../types/planySplatokTypes'

import { getReq, postReq } from '../../utils/request'

export function planySplatokLoadStart() {
	return {
		type: PLANY_SPLATOK_LOAD_START
	}
}

export function planySplatokLoadDone(planySplatok) {
	return {
		type: PLANY_SPLATOK_LOAD_DONE,
		payload: {
			planySplatok
		}
	}
}

export function planySplatokLoadFail() {
	return {
		type: PLANY_SPLATOK_LOAD_FAIL
	}
}

export const loadPlanySplatok = (queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch(planySplatokLoadStart())

		try {
			const query = {
				...queryParams,
				velkostStranky: 100,
				stranka: 1
			}

			const planySplatokData = await getReq(`/api/v0/obchodni-partneri/${get(interakcia, 'opCislo')}/plany-splatok`, query)

			const zdrojoveFakturyPromises = map(get(planySplatokData, 'response.obsah', []), async (planSplatok) => {
				const zdrojoveFakturyData = await getReq(
					`/api/v0/obchodni-partneri/${get(interakcia, 'opCislo')}/plany-splatok/${get(planSplatok, 'id')}/faktury`
				)
				return {
					...planSplatok,
					zdrojoveFaktury: get(zdrojoveFakturyData, 'response.obsah', [])
				}
			})

			const planySplatok = await Promise.all(zdrojoveFakturyPromises)

			dispatch(planySplatokLoadDone(planySplatok))
		} catch (e) {
			dispatch(planySplatokLoadFail())
		}
	}
}

export function planSplatokLoadStart() {
	return {
		type: PLAN_SPLATOK_LOAD_START
	}
}

export function planSplatokLoadDone(planSplatok) {
	return {
		type: PLAN_SPLATOK_LOAD_DONE,
		payload: {
			planSplatok
		}
	}
}

export function planSplatokLoadFail() {
	return {
		type: PLAN_SPLATOK_LOAD_FAIL
	}
}

export const loadPlanSplatok = (planSplatokCislo) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail')

		dispatch(planSplatokLoadStart())

		try {
			const planSplatokData = await getReq(`/api/v0/obchodni-partneri/${get(interakcia, 'data.opCislo')}/plany-splatok/${planSplatokCislo}`)
			const zdrojoveFakturyData = await getReq(
				`/api/v0/obchodni-partneri/${get(interakcia, 'data.opCislo')}/plany-splatok/${get(planSplatokData, 'response.obsah.id')}/faktury`
			)

			return dispatch(
				planSplatokLoadDone({
					...get(planSplatokData, 'response.obsah'),
					zdrojoveFaktury: get(zdrojoveFakturyData, 'response.obsah')
				})
			)
		} catch (e) {
			dispatch(planSplatokLoadFail())
		}
	}
}

export function planSplatokAktualneNastavenieLoadStart() {
	return {
		type: PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_START
	}
}

export function planSplatokAktualneNastavenieLoadDone(planSplatokAktualneNastavenie) {
	return {
		type: PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_DONE,
		payload: {
			planSplatokAktualneNastavenie
		}
	}
}

export function planSplatokAktualneNastavenieLoadFail() {
	return {
		type: PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_FAIL
	}
}

export const loadPlanSplatokAktualneNastavenie = (data) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail')

		dispatch(planSplatokAktualneNastavenieLoadStart())

		try {
			const planSplatokAktualneNastavenieData = await postReq(
				`/api/v0/obchodni-partneri/${get(interakcia, 'data.opCislo')}/ukon-plan-splatok/kalkulovat`,
				null,
				data
			)

			return dispatch(planSplatokAktualneNastavenieLoadDone([...get(planSplatokAktualneNastavenieData, 'response.obsah')]))
		} catch (e) {
			dispatch(planSplatokAktualneNastavenieLoadFail())
		}
	}
}

export const aktualneNastavenieClear = () => {
	return (dispatch) => {
		dispatch({
			type: PLAN_SPLATOK_AKTUALNE_NASTAVENIE_CLEAR
		})
	}
}
