import React from 'react'
import PropTypes from 'prop-types'
import { get, find } from 'lodash'
import cx from 'classnames'

import { generateTimeObjects, manipulateTimeObject } from './TimeModel'

import './style.css'

/* eslint-disable */
import BasicSelect from '../BasicSelect'

export class TimeRange extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			timeModel: null
		}
	}

	componentDidMount() {

		// Generate timeModel and store in our component state
		const timeModel = generateTimeObjects({ ...this.props })

		this.setState({ timeModel })
	}

	static getDerivedStateFromProps (props) {
		return {
			timeModel: generateTimeObjects({ ...props })
		}
	}

	changeTime = (e, type) => {
		const { input: { onChange, value } } = this.props

		// Fetch our current start and end time values
		let startTime = get(value, 'startTime')
		let endTime = get(value, 'endTime')

		// Manipulate time based on value selected
		if (type === 'startTime') {
			startTime = manipulateTimeObject(startTime, e.value)
		}
		else if (type === 'endTime') {
			endTime = manipulateTimeObject(endTime, e.value)
		}
	
		// Return both time objects back
		onChange && onChange({
			startTime,
			endTime
		})
	}

	render = () => {
		const {
			meta: { touched, error },
			showErrorOnUntouch,
			className,
			use24Hours,
			children,
			placeholder
		} = this.props
		const { timeModel } = this.state

		const calendar = React.Children.toArray(children)
		
		let optionsStartTime
		let optionsEndTime

		const startTimeIncrement = get(timeModel, 'startTimeIncrement', [])
		const endTimeIncrement = get(timeModel, 'endTimeIncrement', [])

		// if start and end time range is equals map only once
		if( startTimeIncrement.length === endTimeIncrement.length) {
			const options = startTimeIncrement.map((resp) => ({
				value: resp.value,
				label: use24Hours
					? `${resp.HH}:${resp.MM}`
					: `${resp.hh}:${resp.mm} ${resp.period}`
			}))
			optionsStartTime = options
			optionsEndTime = options
		}
		else {
			optionsStartTime = startTimeIncrement.map((resp) => ({
				value: resp.value,
				label: use24Hours
					? `${resp.HH}:${resp.MM}`
					: `${resp.hh}:${resp.mm} ${resp.period}`
			}))
			optionsEndTime = endTimeIncrement.map((resp) => ({
				value: resp.value,
				label: use24Hours
					? `${resp.HH}:${resp.MM}`
					: `${resp.hh}:${resp.mm} ${resp.period}`
			}))
		}

		return <div id="react-time-range" className="row">
			<div className="col-12">
				<div className={className}>
					<div id="start-component" className={cx('select-wrapper', { 'has-error': (touched || showErrorOnUntouch) && error })} style={{ float: 'left', width: 'calc(50% - 10px)' }}>
						{calendar[0] && <span className="component">{calendar[0]}</span>}
						<BasicSelect
							value={find(optionsStartTime, { value: timeModel.startTimeValue })}
							onChange={(e) => this.changeTime(e, 'startTime')}
							options={optionsStartTime}
							placeholder={placeholder}
						/>
					</div>
					<div id="end-component" className={cx('select-wrapper', { 'has-error': (touched || showErrorOnUntouch) && error })} style={{ float: 'left', width: 'calc(50% - 10px)' }}>
						{calendar[1] && <span className="component">{calendar[1]}</span>}
						<BasicSelect
							value={find(optionsEndTime, { value: timeModel.endTimeValue })}
							onChange={(e) => this.changeTime(e, 'endTime')}
							options={optionsEndTime}
							placeholder={placeholder}
						/>
					</div>
				</div>
			</div>
			<div className="col-12">
				<div className="text-danger">
					{(touched || showErrorOnUntouch) ? error : ''}
				</div>
			</div>
		</div>
	}
}

TimeRange.defaultProps = {
	use24Hours: false,
	minuteIncrement: 30
}

TimeRange.propTypes = {
	use24Hours: PropTypes.bool,
	minuteIncrement: PropTypes.oneOf([1, 2, 5, 10, 15, 20, 30, 60]),
	className: PropTypes.string
}

export default TimeRange
