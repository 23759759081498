import {
	OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_START,
	OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_DONE,
	OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_FAIL
} from '../../types/obchodnyPartner'

import { getReq } from '../../utils/request'

export function historiaSuhlasuLoadStart() {
	return {
		type: OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_START
	}
}

export function historiaSuhlasuLoadDone(historiaSuhlasu) {
	return {
		type: OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_DONE,
		payload: {
			historiaSuhlasu
		}
	}
}

export function historiaSuhlasuLoadFail() {
	return {
		type: OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_FAIL
	}
}

export function loadHistoriaSuhlasu(definiciaId, kanalId) {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = store.interakcie.detail

		dispatch(historiaSuhlasuLoadStart())
		try {
			const query = {
				kanalId,
				definiciaId
			}

			const res = await getReq(`/api/ces/suhlas/historia/${interakcia.data.opCislo}`, query)
			const historiaSuhlasu = res.response

			dispatch(historiaSuhlasuLoadDone(historiaSuhlasu))
		} catch (e) {
			dispatch(historiaSuhlasuLoadFail())
		}
	}
}
