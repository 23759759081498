import {
	CISELNIKY_MIESTA_SPOTREBY_LOAD_DONE,
	CISELNIKY_MIESTA_SPOTREBY_LOAD_FAIL,
	CISELNIKY_MIESTA_SPOTREBY_LOAD_START,
	CISELNIKY_MIESTA_SPOTREBY_LOAD_PART_DONE,
	CISELNIKY_ZMLUVNE_UCTY_DONE,
	CISELNIKY_ZMLUVNE_UCTY_FAIL,
	CISELNIKY_ZMLUVNE_UCTY_START
} from '../types/ciselnikySelectBox'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	miestaSpotreby: {
		data: [],
		allDataPrepared: false,
		isLoading: false,
		isFailure: false,
		lastLoad: new Date(0)
	},
	zmluvneUcty: {
		data: [],
		isLoading: false,
		isFailure: false,
		lastLoad: new Date(0)
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case CISELNIKY_MIESTA_SPOTREBY_LOAD_DONE:
			return {
				...state,
				miestaSpotreby: {
					...state.miestaSpotreby,
					allDataPrepared: true,
					isLoading: false,
					isFailure: false,
					lastLoad: new Date()
				}
			}
		case CISELNIKY_MIESTA_SPOTREBY_LOAD_FAIL:
			return {
				...state,
				miestaSpotreby: {
					...state.miestaSpotreby,
					data: [],
					allDataPrepared: false,
					isLoading: false,
					isFailure: true
				}
			}
		case CISELNIKY_MIESTA_SPOTREBY_LOAD_PART_DONE:
			return {
				...state,
				miestaSpotreby: {
					...state.miestaSpotreby,
					data: action.payload.miestaSpotreby,
					allDataPrepared: false,
					isLoading: false,
					isFailure: true
				}
			}
		case CISELNIKY_MIESTA_SPOTREBY_LOAD_START:
			return {
				...state,
				miestaSpotreby: {
					...state.miestaSpotreby,
					allDataPrepared: false,
					data: [],
					isLoading: true,
					isFailure: false
				}
			}
		case CISELNIKY_ZMLUVNE_UCTY_DONE:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					data: action.payload.zmluvneUcty,
					isLoading: false,
					isFailure: false,
					lastLoad: new Date()
				}
			}
		case CISELNIKY_ZMLUVNE_UCTY_START:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					data: [],
					isLoading: true,
					isFailure: false
				}
			}
		case CISELNIKY_ZMLUVNE_UCTY_FAIL:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					data: [],
					isLoading: false,
					isFailure: true
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
