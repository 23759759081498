import { get } from 'lodash'
import {
	PLANY_SPLATOK_LOAD_START,
	PLANY_SPLATOK_LOAD_DONE,
	PLANY_SPLATOK_LOAD_FAIL,
	PLAN_SPLATOK_LOAD_START,
	PLAN_SPLATOK_LOAD_DONE,
	PLAN_SPLATOK_LOAD_FAIL,
	PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_START,
	PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_DONE,
	PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_FAIL,
	PLAN_SPLATOK_AKTUALNE_NASTAVENIE_CLEAR
} from '../types/planySplatokTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	planySplatok: {
		data: [],
		isFailure: false,
		isLoading: false
	},
	planySplatokDetail: {
		data: null,
		isFailure: false,
		isLoading: false
	},
	planSplatokAktualneNastavenie: {
		data: [],
		isFailure: false,
		isLoading: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case PLANY_SPLATOK_LOAD_START:
			return {
				...state,
				planySplatok: {
					...initState.planySplatok,
					isFailure: false,
					isLoading: true
				}
			}
		case PLANY_SPLATOK_LOAD_DONE:
			return {
				...state,
				planySplatok: {
					isFailure: false,
					isLoading: false,
					data: get(action, 'payload.planySplatok')
				}
			}
		case PLANY_SPLATOK_LOAD_FAIL:
			return {
				...state,
				planySplatok: {
					...initState.planySplatok,
					isFailure: true,
					isLoading: false
				}
			}

		case PLAN_SPLATOK_LOAD_START:
			return {
				...state,
				planySplatokDetail: {
					...initState.planySplatokDetail,
					isFailure: false,
					isLoading: true
				}
			}
		case PLAN_SPLATOK_LOAD_DONE:
			return {
				...state,
				planySplatokDetail: {
					isFailure: false,
					isLoading: false,
					data: get(action, 'payload.planSplatok')
				}
			}
		case PLAN_SPLATOK_LOAD_FAIL:
			return {
				...state,
				planySplatokDetail: {
					...initState.planySplatokDetail,
					isFailure: true,
					isLoading: false
				}
			}
		case PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_START:
			return {
				...state,
				planSplatokAktualneNastavenie: {
					...initState.planSplatokAktualneNastavenie,
					isFailure: false,
					isLoading: true
				}
			}
		case PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_DONE:
			return {
				...state,
				planSplatokAktualneNastavenie: {
					isFailure: false,
					isLoading: false,
					data: get(action, 'payload.planSplatokAktualneNastavenie')
				}
			}
		case PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_FAIL:
			return {
				...state,
				planSplatokAktualneNastavenie: {
					...initState.planSplatokAktualneNastavenie,
					isFailure: true,
					isLoading: false
				}
			}
		case PLAN_SPLATOK_AKTUALNE_NASTAVENIE_CLEAR:
			return {
				...state,
				planSplatokAktualneNastavenie: {
					...initState.planSplatokAktualneNastavenie
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
