import { get } from 'lodash'
import {
	PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_START,
	PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_DONE,
	PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_FAIL
} from '../../types/procesnyKonfigurator'
import { getReq } from '../../utils/request'

export const contextLoadStart = () => {
	return {
		type: PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_START
	}
}

export const contextLoadDone = (context) => {
	return {
		type: PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_DONE,
		payload: {
			context
		}
	}
}

export const contextLoadFail = () => {
	return {
		type: PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_FAIL
	}
}

export const loadContext = (queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		const query = {
			...queryParams
		}

		dispatch(contextLoadStart())

		try {
			const ukonPlanSplatokContextData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ukon-plan-splatok/process-eval-context`, query)
			dispatch(contextLoadDone(get(ukonPlanSplatokContextData, 'response.content')))

			return get(ukonPlanSplatokContextData, 'response.content')
		} catch (e) {
			dispatch(contextLoadFail())
		}
	}
}
