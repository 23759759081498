export const PROCESNY_KONFIGURATOR_LOAD_START = 'PROCESNY_KONFIGURATOR_LOAD_START'
export const PROCESNY_KONFIGURATOR_LOAD_DONE = 'PROCESNY_KONFIGURATOR_LOAD_DONE'
export const PROCESNY_KONFIGURATOR_LOAD_FAIL = 'PROCESNY_KONFIGURATOR_LOAD_FAIL'

export const PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_START = 'PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_START'
export const PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_DONE = 'PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_DONE'
export const PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_FAIL = 'PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_FAIL'

export const PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_START = 'PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_START'
export const PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_DONE = 'PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_DONE'
export const PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_FAIL = 'PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_FAIL'

export const PK_ZMLUVNY_VZTAH_PREPIS_CONTEXT_LOAD_START = 'PK_ZMLUVNY_VZTAH_PREPIS_CONTEXT_LOAD_START'
export const PK_ZMLUVNY_VZTAH_PREPIS_CONTEXT_LOAD_DONE = 'PK_ZMLUVNY_VZTAH_PREPIS_CONTEXT_LOAD_DONE'
export const PK_ZMLUVNY_VZTAH_PREPIS_CONTEXT_LOAD_FAIL = 'PK_ZMLUVNY_VZTAH_PREPIS_CONTEXT_LOAD_FAIL'

export const PK_ZMLUVNY_VZTAH_NOVY_CONTEXT_LOAD_START = 'PK_ZMLUVNY_VZTAH_NOVY_CONTEXT_LOAD_START'
export const PK_ZMLUVNY_VZTAH_NOVY_CONTEXT_LOAD_DONE = 'PK_ZMLUVNY_VZTAH_NOVY_CONTEXT_LOAD_DONE'
export const PK_ZMLUVNY_VZTAH_NOVY_CONTEXT_LOAD_FAIL = 'PK_ZMLUVNY_VZTAH_NOVY_CONTEXT_LOAD_FAIL'

export const PK_GENERIC_UKON_CONTEXT_LOAD_START = 'PK_GENERIC_UKON_CONTEXT_LOAD_START'
export const PK_GENERIC_UKON_CONTEXT_LOAD_DONE = 'PK_GENERIC_UKON_CONTEXT_LOAD_DONE'
export const PK_GENERIC_UKON_CONTEXT_LOAD_FAIL = 'PK_GENERIC_UKON_CONTEXT_LOAD_FAIL'
