import React from 'react'
import { connect } from 'react-redux'
import { map, get, find, isEqual, isEmpty, indexOf, pick, keys, values } from 'lodash'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import reactQueryParams from 'react-query-params'
import { withTranslation } from 'react-i18next'

// atom
import Select from '../../atoms/BasicSelect'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as FiltersActions from '../../actions/SelectedFiltersActions'
import * as CiselnikySelectBoxActions from '../../actions/CiselnikySelectBoxActions'

// components
import RozpisyZalohTableRow from '../../components/TableRows/RozpisyZalohTableRow'
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'

// utils
import { formatAddress, miestoSpotrebyAdresaDoplnokFormat } from '../../utils/address'
import { FILTER_SELECTORS } from '../../utils/enums'
import extractProperties from '../../utils/extractProperties'
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'

const emptySelectState = {
	value: null,
	label: 'Všetky'
}

const queryParams = {
	zmluvnyUcetCislo: 'zmluvnyUcetCislo',
	typ: 'komoditaTyp',
	stav: 'stav',
	miestoSpotrebyAdresa: 'adresaIdIsu'
}

// build data for select box filter stav rozpisu zaloh
const stavRozpisuZalohFilterOptions = [
	{
		value: '1',
		label: 'Len otvorené'
	},
	{
		value: '2',
		label: 'Len vyrovnané'
	}
]

// build data for select box filter komoditny typ
const komoditnyTypFilterOptions = [
	{
		value: '2',
		label: 'GAS - Plyn'
	},
	{
		value: '1',
		label: 'EE - Elektrina'
	}
]

class RozpisyZalohPage extends reactQueryParams {
	static propTypes = {
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.number.isRequired,
				opCislo: PropTypes.string.isRequired
			}).isRequired,
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		obchodnyPartnerActions: PropTypes.shape({
			loadZmluvneUcty: PropTypes.func.isRequired,
			loadRozpisyZaloh: PropTypes.func.isRequired
		}).isRequired,
		rozpisyZaloh: PropTypes.shape({
			data: PropTypes.array.isRequired,
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired,
			page: PropTypes.number.isRequired
		}),
		computedMatch: PropTypes.shape({
			params: PropTypes.shape({
				cisloOP: PropTypes.string.isRequired
			}).isRequired
		}).isRequired
	}

	state = {}

	defaultQueryParams = {
		[queryParams.zmluvnyUcetCislo]: null,
		[queryParams.stav]: null,
		[queryParams.typ]: null,
		[queryParams.miestoSpotrebyAdresa]: null
	}

	componentDidMount = () => {
		const { obchodnyPartnerActions, ciselnikySelectBoxActions, selectedFilters, filtersActions } = this.props

		const filteredParams = pick(
			this.queryParams,
			keys(this.queryParams).filter((key) => {
				return indexOf(values(queryParams), key) !== -1 && !isEmpty(this.queryParams[key])
			})
		)
		const filters = {
			...selectedFilters,
			...filteredParams
		}

		obchodnyPartnerActions.loadRozpisyZaloh(1, undefined, filters)
		obchodnyPartnerActions.loadAllOdberneMiesta(0)
		ciselnikySelectBoxActions.loadCiselnikMiestaSpotreby()
		ciselnikySelectBoxActions.loadCiselnikZmluvneUcty()

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.ROZPISY_ZALOH_PAGE, filters)
	}

	componentDidUpdate = () => {
		// prevent to inject not allowed query param
		const query = extractProperties(this.queryParams, values(queryParams))
		if (!isEqual(this.props.selectedFilters, query)) {
			this.setQueryParams({
				...this.props.selectedFilters
			})
		}
	}

	render = () => {
		const { interakcia, obchodnyPartner, rozpisyZaloh, odberneMiesta, procesnyKonfigurator, t } = this.props
		const { data, isLoading, isFailure, isLastPage, page } = rozpisyZaloh

		if ((isLoading && page === 1) || odberneMiesta.isLoading || procesnyKonfigurator.isLoading) {
			return this.loadingContentElement()
		}
		if (isFailure || procesnyKonfigurator.isFailure || odberneMiesta.isFailure) {
			return this.failureContentElement()
		}
		if (!odberneMiesta.allDataPrepared) {
			return this.loadingContentElement()
		}
		if (isEmpty(data)) {
			return this.emptyContentElement()
		}
		if (!get(procesnyKonfigurator, 'data.ukony')) {
			return this.emptyContentElement(t('containers:Ľutujeme ale nepodarilo sa načítať Procesný konfigurátor'))
		}

		let loadMoreBtn = ''

		if (isLoading && page > 1) {
			loadMoreBtn = (
				<div className='box' style={{ height: '120px' }}>
					<ElementLoading />
				</div>
			)
		} else if (!isLastPage) {
			loadMoreBtn = (
				<div className='text-center'>
					<button className='button' data-color='blue' data-type='outline' style={{ margin: '20px 0' }} onClick={this.loadMore}>
						{t('containers:RozpisyZalohPage.Načítať dalšie rozpisy záloh')}
					</button>
				</div>
			)
		}

		const rozpisyZalohRows = map(data, (rozpisZaloh) => {
			return (
				<RozpisyZalohTableRow
					key={`rozpisyZaloh-${rozpisZaloh.cislo}`}
					interakcia={interakcia}
					obchodnyPartner={obchodnyPartner}
					rozpisZaloh={rozpisZaloh}
					odberneMiesta={odberneMiesta.allData}
					procesnyKonfigurator={procesnyKonfigurator}
				/>
			)
		})

		return this.commonContentContainer(
			<>
				{rozpisyZalohRows}
				{loadMoreBtn}
			</>
		)
	}

	loadMore = () => {
		const { obchodnyPartnerActions, rozpisyZaloh } = this.props
		const rozpisZalohNextPage = rozpisyZaloh.page + 1
		obchodnyPartnerActions.loadRozpisyZaloh(rozpisZalohNextPage, undefined, this.props.selectedFilters)
	}

	filterChanged = (filter, fieldName) => {
		// stringify value or set a default value null
		const newFilter = {
			...this.props.selectedFilters,
			[fieldName]: filter && filter.value ? `${filter.value}` : null
		}

		if (newFilter[fieldName] == this.props.selectedFilters[fieldName]) {
			return
		}

		this.props.obchodnyPartnerActions.loadRozpisyZaloh(1, undefined, newFilter)
		// update filter state
		this.props.filtersActions.selectedFiltersChanged(FILTER_SELECTORS.ROZPISY_ZALOH_PAGE, newFilter)
	}

	commonContentContainer = (content) => {
		const { ciselnikySelectBox, selectedFilters, t } = this.props
		// build data for select box filter zmluvne ucty
		let zmluvnyUcetCisloFilterOptions = []
		if (ciselnikySelectBox.zmluvneUcty.data) {
			zmluvnyUcetCisloFilterOptions = map(ciselnikySelectBox.zmluvneUcty.data, (zmluvnyUcet) => ({
				value: zmluvnyUcet.cislo,
				label: zmluvnyUcet.cislo
			}))
		}

		let miestaSpotrebyFilterOptions = []
		if (ciselnikySelectBox.miestaSpotreby.allDataPrepared) {
			// build data for select box filter odberne miesto
			miestaSpotrebyFilterOptions = map(ciselnikySelectBox.miestaSpotreby.data, (miestoSpotreby) => ({
				value: `${get(miestoSpotreby, 'adresa.idIsu')}`,
				label: `${formatAddress(get(miestoSpotreby, 'adresa'))}, ${miestoSpotrebyAdresaDoplnokFormat(miestoSpotreby)}`
			}))
		}

		const filters = {
			komoditnyTypFilterOptions: [emptySelectState, ...komoditnyTypFilterOptions],
			zmluvnyUcetCisloFilterOptions: [emptySelectState, ...zmluvnyUcetCisloFilterOptions],
			stavRozpisuZalohFilterOptions: [emptySelectState, ...stavRozpisuZalohFilterOptions],
			miestaSpotrebyFilterOptions: [emptySelectState, ...miestaSpotrebyFilterOptions]
		}

		return (
			<>
				<div className='content-header clearfix'>
					<div className='row'>
						<div className='col-3'>
							<div className='select-wrapper'>
								<label htmlFor='partner-bank-accounts-account-filter'>{t('containers:RozpisyZalohPage.Zmluvný účet')}</label>
								<Select
									value={find(
										filters.zmluvnyUcetCisloFilterOptions,
										(element) => element.value == selectedFilters[queryParams.zmluvnyUcetCislo]
									)}
									onChange={(zmluvnyUcetCislo) => this.filterChanged(zmluvnyUcetCislo, queryParams.zmluvnyUcetCislo)}
									options={filters.zmluvnyUcetCisloFilterOptions}
									isLoading={!ciselnikySelectBox.zmluvneUcty.data}
									isDisabled={!ciselnikySelectBox.zmluvneUcty.data}
									classNamePrefix='react-select'
								/>
							</div>
						</div>
						<div className='col-3'>
							<div className='select-wrapper'>
								<label htmlFor='partner-bank-accounts-account-filter'>{t('containers:RozpisyZalohPage.Miesto spotreby')}</label>
								<Select
									value={find(
										filters.miestaSpotrebyFilterOptions,
										(element) => element.value == selectedFilters[queryParams.miestoSpotrebyAdresa]
									)}
									onChange={(miestoSpotrebyAdresa) => this.filterChanged(miestoSpotrebyAdresa, queryParams.miestoSpotrebyAdresa)}
									options={filters.miestaSpotrebyFilterOptions}
									isLoading={!ciselnikySelectBox.miestaSpotreby.allDataPrepared}
									isDisabled={!ciselnikySelectBox.miestaSpotreby.allDataPrepared}
									classNamePrefix='react-select'
								/>
							</div>
						</div>
						<div className='col-3'>
							<div className='select-wrapper'>
								<label htmlFor='partner-bank-accounts-account-filter'>{t('containers:RozpisyZalohPage.Komoditný typ')}</label>
								<Select
									value={find(filters.komoditnyTypFilterOptions, (element) => element.value == selectedFilters[queryParams.typ])}
									onChange={(typ) => this.filterChanged(typ, queryParams.typ)}
									options={filters.komoditnyTypFilterOptions}
									classNamePrefix='react-select'
								/>
							</div>
						</div>
						<div className='col-3'>
							<div className='select-wrapper'>
								<label htmlFor='partner-bank-accounts-account-filter'>{t('containers:RozpisyZalohPage.Stav rozpisu záloh')}</label>
								<Select
									value={find(filters.stavRozpisuZalohFilterOptions, (element) => element.value == selectedFilters[queryParams.stav])}
									onChange={(stav) => this.filterChanged(stav, queryParams.stav)}
									options={filters.stavRozpisuZalohFilterOptions}
									classNamePrefix='react-select'
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='content-wrapper'>{content}</div>
			</>
		)
	}

	loadingContentElement = () => {
		return this.commonContentContainer(
			<div className='box' style={{ height: '100%' }}>
				<ElementLoading />
			</div>
		)
	}

	failureContentElement = () => {
		const { t } = this.props
		return this.commonContentContainer(<ElementFailure text={t('containers:RozpisyZalohPage.Nepodarilo sa načítať zoznam rozpisov záloh')} />)
	}

	emptyContentElement = (text) => {
		const { t } = this.props
		const textElement =
			text ||
			t('containers:RozpisyZalohPage.Pre obchodného partnera neevidujeme žiadne rozpisy záloh, alebo zadanému filtru nekorešponduje žiadny rozpis záloh')
		return this.commonContentContainer(<ElementEmptyContent text={textElement} />)
	}
}

const mapStateToProps = (state) => ({
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data'),
	interakcia: state.interakcie.detail,
	rozpisyZaloh: state.obchodnyPartner.rozpisyZaloh,
	odberneMiesta: state.obchodnyPartner.odberneMiesta,
	selectedFilters: get(state.selectedFilters, FILTER_SELECTORS.ROZPISY_ZALOH_PAGE, {}),
	ciselnikySelectBox: state.ciselnikySelectBox,
	procesnyKonfigurator: state.procesnyKonfigurator.procesnyKonfigurator
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch),
	ciselnikySelectBoxActions: bindActionCreators(CiselnikySelectBoxActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_PREDDAVKOVE_PLATBY])
)(RozpisyZalohPage)
