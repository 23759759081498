import { get } from 'lodash'
import {
	PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_START,
	PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_DONE,
	PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_FAIL
} from '../../types/procesnyKonfigurator'
import { getReq } from '../../utils/request'

export const contextLoadStart = () => {
	return {
		type: PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_START
	}
}

export const contextLoadDone = (context) => {
	return {
		type: PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_DONE,
		payload: {
			context
		}
	}
}

export const contextLoadFail = () => {
	return {
		type: PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_FAIL
	}
}

export const loadContext = (rozpisZalohCislo) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch(contextLoadStart())

		try {
			const ukonZmenaZalohContextData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ukon-zmena-zaloh/process-eval-context/${rozpisZalohCislo}`)
			dispatch(contextLoadDone(get(ukonZmenaZalohContextData, 'response.content')))

			return get(ukonZmenaZalohContextData, 'response.content')
		} catch (e) {
			dispatch(contextLoadFail())
		}
	}
}
