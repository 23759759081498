import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, propTypes, touch } from 'redux-form'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { get, map, filter, every, some, isEmpty, forEach, isEqual } from 'lodash'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'

// components
import FormInfo from '../FormInfo'
import UndefinedField from './fields/UndefinedField'

// utils
import { FORMS } from '../../utils/enums'
import { isValidacneKriteriumError, isValidacneKriteriumWarning } from '../../utils/form'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../containers/GenericUkon/attributesConfig'

// resources
import DatumPrijatiaZiadostiField from './fields/DatumPrijatiaZiadostiField'
import VstupDoUkonuField from './fields/VstupDoUkonuField'
import SplnomocnenecField from './fields/SplnomocnenecField'
import PodpisMiestoField from './fields/PodpisMiestoField'

class GenericUkonStep extends React.Component {
	static propTypes = {
		...propTypes,
		attributes: PropTypes.array.isRequired,
		unsupportedAttributes: PropTypes.array.isRequired,
		editMode: PropTypes.string.isRequired,
		formTitle: PropTypes.string.isRequired,
		methodologicalGuidelineLink: PropTypes.string,
		onSubmit: PropTypes.func.isRequired,
		onBackClick: PropTypes.func.isRequired,
		onCancelClick: PropTypes.func.isRequired,
		koKriteria: PropTypes.array.isRequired,
		schvalovacieKriteria: PropTypes.array.isRequired,
		validacneKriteria: PropTypes.array.isRequired,
		confirm: PropTypes.shape({
			scenarioOption: PropTypes.shape({
				kanal: PropTypes.number.isRequired,
				scenarios: PropTypes.array.isRequired,
				notificationRequire: PropTypes.bool.isRequired,
				notificationTypes: PropTypes.array.isRequired
			}),
			disallowScenarios: PropTypes.shape(),
			isDisabledNotification: PropTypes.bool,
			isDisabledSignedPdf: PropTypes.bool
		}),
		formValues: PropTypes.shape(),
		originalValues: PropTypes.shape(),
		ukonNovy: PropTypes.shape().isRequired
	}

	_mounted = false

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { dispatch, attributes, editMode, formValues } = this.props

		if (editMode == EDIT_MODE.EDIT) {
			if (!get(formValues, 'vstup')) {
				// trigger validation immediately after init form
				dispatch(touch(FORMS.GENERIC_UKON, 'vstup'))
			}
		}

		forEach(attributes, (attribute) => {
			get(attribute, 'cesta') && dispatch(touch(FORMS.GENERIC_UKON, get(attribute, 'cesta')))
		})
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		const { dispatch, attributes, editMode, formValues } = this.props

		if (editMode != get(prevProps, 'editMode') && editMode == EDIT_MODE.EDIT) {
			if (!get(formValues, 'vstup')) {
				// trigger validation immediately after init form
				dispatch(touch(FORMS.GENERIC_UKON, 'vstup'))
			}
		}

		if (!isEqual(attributes, get(prevProps, 'attributes'))) {
			// trigger validation for all attributes in all steps
			forEach(attributes, (attribute) => {
				get(attribute, 'cesta') && dispatch(touch(FORMS.GENERIC_UKON, get(attribute, 'cesta')))
			})
		}
	}

	render() {
		const {
			editMode,
			attributes,
			unsupportedAttributes,
			handleSubmit,
			invalid,
			t,
			koKriteria,
			validacneKriteria,
			schvalovacieKriteria,
			formTitle,
			methodologicalGuidelineLink,
			onBackClick,
			onCancelClick,
			formValues,
			originalValues,
			confirm,
			podpisovanieDokumentov,
			change,
			ukonNovy
		} = this.props

		let submitBtn
		if (confirm) {
			submitBtn = (
				<button className='button' type='submit' disabled data-color='blue' style={{ marginLeft: '20px' }}>
					{t('translation:Common.Dokončiť')}
				</button>
			)

			if (get(podpisovanieDokumentov, 'templatePdf.isLoading')) {
				submitBtn = (
					<Tooltip
						html={<span>{t('translation:Common.Prebieha načítavanie dokumentu pre podpisovanie')}</span>}
						position='left'
						trigger='mouseenter'
						theme='light'
					>
						{submitBtn}
					</Tooltip>
				)
			} else if (get(confirm, 'isDisabledSignedPdf')) {
				submitBtn = (
					<Tooltip html={<span>{t('translation:Common.Dokument musí byť podpísaný')}</span>} position='left' trigger='mouseenter' theme='light'>
						{submitBtn}
					</Tooltip>
				)
			} else if (!get(confirm, 'isDisabledSignedPdf') && get(confirm, 'isDisabledNotification')) {
				submitBtn = (
					<Tooltip
						html={<span>{t('translation:Common.Na dokončenie úkonu je potrebné nastaviť notifikáciu')}</span>}
						position='left'
						trigger='mouseenter'
						theme='light'
					>
						<button className='button' type='submit' disabled data-color='blue' style={{ marginLeft: '20px' }}>
							{t('translation:Common.Dokončiť')}
						</button>
					</Tooltip>
				)
			} else if (!get(confirm, 'isDisabledNotification') && !get(confirm, 'isDisabledSignedPdf')) {
				submitBtn = (
					<button className='button pull-right' type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
						{t('translation:Common.Dokončiť')}
					</button>
				)
			}
		} else {
			// result from procesnyKonfigurator if form is valid
			const invalidKoKriteria = filter(koKriteria, {
				vysledok: false
			})
			const passValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumError), {
				vysledok: true
			})
			const warningValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumWarning), {
				vysledok: true
			})
			const errorSchvalovacieKriterium = some(schvalovacieKriteria, {
				error: true
			})

			const isValid = editMode != EDIT_MODE.EDIT || (passValidacneKriterium && !errorSchvalovacieKriterium && isEmpty(invalidKoKriteria))
			const disableSubmit = !isValid || invalid
			const showWarning = !disableSubmit && !warningValidacneKriterium

			submitBtn = (
				<button
					className={cx('button', 'pull-right', { disabled: disableSubmit })}
					disabled={disableSubmit}
					type='submit'
					data-color={showWarning ? 'orange' : 'blue'}
					style={{ marginLeft: '20px' }}
				>
					{t('translation:Common.Pokračovať')}
				</button>
			)

			if (!isValid || !warningValidacneKriterium) {
				let btnTooltipText
				if (!passValidacneKriterium || !warningValidacneKriterium) {
					const valKrit = filter(validacneKriteria, (validacneKriterium) => {
						return !get(validacneKriterium, 'vysledok')
					})
					btnTooltipText = map(valKrit, (validacneKriterium) => {
						return (
							<div>
								{get(validacneKriterium, 'nazov')}: {get(validacneKriterium, 'popis')}
							</div>
						)
					})
				} else if (errorSchvalovacieKriterium) {
					const schvalKrit = filter(schvalovacieKriteria, (schvalovacieKriterium) => {
						return !get(schvalovacieKriterium, 'vysledok') || get(schvalovacieKriterium, 'error')
					})
					btnTooltipText = map(schvalKrit, (schvalovacieKriterium) => {
						return (
							<div>
								{get(schvalovacieKriterium, 'nazov')}: {get(schvalovacieKriterium, 'popis')}
							</div>
						)
					})
				} else if (!isEmpty(invalidKoKriteria)) {
					btnTooltipText = map(invalidKoKriteria, (koKriterium, index) => {
						return (
							<div key={`koKriterium-${index}`}>
								{get(koKriterium, 'nazov')}: {get(koKriterium, 'popis')}
							</div>
						)
					})
				}
				submitBtn = (
					<Tooltip html={btnTooltipText} position='top' trigger='mouseenter' theme='light'>
						{submitBtn}
					</Tooltip>
				)
			}
		}

		const fields = map(attributes, (attribute, index) => {
			const fieldComponent = get(attribute, 'fieldComponent')
			return React.createElement(fieldComponent, {
				key: `generic-form-field-${index}`,
				form: FORMS.GENERIC_UKON,
				field: get(attribute, 'cesta') || null,
				editMode,
				columnsCount: COLUMNS_COUNT.THREE,
				value: get(attribute, 'cesta') ? get(formValues, get(attribute, 'cesta')) : null,
				originalValue: get(attribute, 'cesta') ? get(originalValues, get(attribute, 'cesta')) : null,
				formValues,
				originalValues,
				change,
				scenarioOption: get(confirm, 'scenarioOption'),
				ukonNovy,
				...attribute
			})
		})
		const unsupportedFields =
			editMode == EDIT_MODE.EDIT
				? map(unsupportedAttributes, (attribute, index) => {
						return React.createElement(UndefinedField, {
							key: `generic-form-unsupported-field-${index}`,
							field: get(attribute, 'cesta') || null,
							editMode,
							columnsCount: COLUMNS_COUNT.THREE,
							value: get(attribute, 'cesta') ? get(formValues, get(attribute, 'cesta')) : null,
							originalValue: get(attribute, 'cesta') ? get(originalValues, get(attribute, 'cesta')) : null,
							...attribute
						})
				  })
				: []

		let fieldsSection = null
		if (editMode == EDIT_MODE.LOOKUP) {
			fieldsSection = fields
		} else {
			fieldsSection = (
				<div className='box'>
					{editMode == EDIT_MODE.EDIT && (
						<>
							<FormInfo koKriteria={koKriteria} />
							<FormInfo schvalovacieKriteria={schvalovacieKriteria} />
							<FormInfo validacneKriteria={validacneKriteria} />
						</>
					)}
					<div className='box-content'>
						{(editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CONFIRM) && (
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('translation:Common.Aktuálne hodnoty')}</th>
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<VstupDoUkonuField
										field={'vstup'}
										editMode={editMode}
										columnsCount={COLUMNS_COUNT.THREE}
										value={get(formValues, 'vstup')}
										isTableView
									/>
									<SplnomocnenecField
										field={'splnomocnenec'}
										editMode={editMode}
										columnsCount={COLUMNS_COUNT.THREE}
										value={get(formValues, 'splnomocnenec')}
									/>
									<PodpisMiestoField
										field={'podpisMiesto'}
										editMode={editMode}
										columnsCount={COLUMNS_COUNT.THREE}
										value={get(formValues, 'podpisMiesto')}
										originalValue={get(originalValues, 'podpisMiesto')}
									/>
									<DatumPrijatiaZiadostiField
										field={'ziadanyOd'}
										editMode={editMode}
										columnsCount={COLUMNS_COUNT.THREE}
										value={get(formValues, 'ziadanyOd')}
									/>
								</tbody>
							</table>
						)}

						{/* Specificke data k ukonu */}
						{fields}
						{unsupportedFields}
					</div>
				</div>
			)
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{submitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					{methodologicalGuidelineLink && (
						<a href={methodologicalGuidelineLink} className='methodology-icon' target='_blank' rel='noopener noreferrer' />
					)}
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>{fieldsSection}</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.GENERIC_UKON,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true
})(GenericUkonStep)

const mapStateToProps = (state) => {
	return {
		podpisovanieDokumentov: get(state, 'podpisovanieDokumentov')
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(form)
