import {
	SAVE_LIVE_AGENT_SESSION,
	CLEAR_LIVE_AGENT_SESSION,
	SAVE_FINESSE_SESSION,
	CLEAR_FINESSE_SESSION,
	SAVE_CALL250_SESSION,
	CLEAR_CALL250_SESSION,
	SAVE_SOURCE
} from '../types/externalIntegrations'

export const initState = {
	liveAgentId: null,
	finesseId: null,
	externalSessionId: null,
	call250Id: null,
	source: null
}

export default (state = initState, action) => {
	switch (action.type) {
		case SAVE_SOURCE:
			return {
				...state,
				source: action.payload.source
			}
		case SAVE_LIVE_AGENT_SESSION:
			return {
				...state,
				liveAgentId: action.payload.liveAgentId
			}
		case CLEAR_LIVE_AGENT_SESSION:
			return {
				...state,
				liveAgentId: null
			}
		case SAVE_FINESSE_SESSION:
			return {
				...state,
				finesseId: action.payload.finesseId,
				externalSessionId: action.payload.externalSessionId
			}
		case CLEAR_FINESSE_SESSION:
			return {
				...state,
				finesseId: null,
				externalSessionId: null
			}
		case SAVE_CALL250_SESSION:
			return {
				...state,
				call250Id: action.payload.call250Id
			}
		case CLEAR_CALL250_SESSION:
			return {
				...state,
				call250Id: null
			}
		default:
			return state
	}
}
