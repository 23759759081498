import { isEmpty } from 'lodash'

const phonePrefix = '+421'

// TODO: Deprecated aspon sa tak javy poslednych zmenach v API navrh na odstranenie
/**
 * @param Object { predvolba: string, cislo: string }
 * @return string
 *
 * Formating phone object into beauty string
 */
export const formatPhone = (phone) => {
	if (isEmpty(phone)) {
		return ''
	}
	if (!phone.predvolba) {
		return phone.cislo
	}
	if (phone.cislo && phone.cislo.startsWith('0')) {
		return `${phone.predvolba} ${phone.cislo.substr(1)}`
	}
	return `${phone.predvolba} ${phone.cislo}`
}

export const isPhoneNumberValid = (phoneNumber) => {
	if (isEmpty(phoneNumber)) {
		return false
	}

	// remove whitespaces
	const phone = phoneNumber.split(' ').join('')
	let regex

	if (phone.startsWith(phonePrefix)) {
		// SK format
		regex = new RegExp(/^\+421[1-9][0-9]{8}$/)
	} else {
		// ITU-T E.164 format - other countries
		regex = new RegExp(/^\+[1-9][0-9]{3,14}$/)
	}

	return regex.test(phone)
}
