import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty, filter, map, uniqBy } from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

// components
import ElementFailure from '../ElementFailure'
import ElementLoading from '../ElementLoading'
import ElementEmptyContent from '../ElementEmptyContent'

// utils
import { getContextForPlanySplatok, getColorByPlanSplatokStav } from '../../utils/planySplatok'
import { getIconByKomoditaTyp } from '../../utils/rozpisyZaloh'
import { checkPermissions } from '../../utils/permissionsHoc'
import { getReq } from '../../utils/request'
import { PLAN_SPLATOK_STAV, KOMODITA_TYP } from '../../utils/enums'
import { formatDate } from '../../utils/date'
import { formatAddress } from '../../utils/address'
import { setRouteParams, PLANY_SPLATOK_DETAIL } from '../../utils/routes'

class SplatkoveKalendareWidget extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape().isRequired,
		auth: PropTypes.shape().isRequired,
		handleSubmit: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
		permission: PropTypes.string,
		t: PropTypes.func.isRequired
	}

	_mounted = false

	constructor(props) {
		super(props)

		this.state = {
			hasPermission: checkPermissions(get(props, 'auth.user.roles', []), [props.permission]),
			planySplatok: {
				data: [],
				isLoading: false,
				isFailure: false
			},
			odberneMiesta: {
				data: null,
				stranka: 1,
				velkostStranky: 1,
				isLoading: false,
				isFailure: false
			}
		}
	}

	loadPlanySplatok = async () => {
		const { interakcia } = this.props

		try {
			if (this._mounted) {
				this.setState({
					planySplatok: {
						data: [],
						isLoading: true,
						isFailure: false
					}
				})
			}

			const planySplatokData = await getReq(`/api/v0/obchodni-partneri/${get(interakcia, 'opCislo')}/plany-splatok`, {})
			const otvorenePlanySplatok = filter(
				get(planySplatokData, 'response.obsah', []),
				(planSplatok) => get(planSplatok, 'stav.id') == PLAN_SPLATOK_STAV.OTVORENY
			)

			if (this._mounted) {
				this.setState({
					planySplatok: {
						data: otvorenePlanySplatok,
						isLoading: false,
						isFailure: false
					}
				})
			}
		} catch (e) {
			if (this._mounted) {
				this.setState({
					planySplatok: {
						data: [],
						isLoading: false,
						isFailure: true
					}
				})
			}
		}
	}

	loadOdberneMiestaByParts = async (stranka = 1) => {
		const { interakcia } = this.props
		const { odberneMiesta } = this.state

		try {
			if (this._mounted) {
				this.setState(
					{
						odberneMiesta: {
							...odberneMiesta,
							stranka,
							isLoading: true
						}
					},
					async () => {
						if (this._mounted) {
							const odberneMiestaData = await getReq(`/api/v0/obchodni-partneri/${get(interakcia, 'opCislo')}/odberne-miesta`, {
								stranka,
								velkostStranky: odberneMiesta.velkostStranky
							})

							const { velkostStranky, aktualnaStranka, zaznamov } = get(odberneMiestaData, 'response.strankovanie')

							const isLastPage = !(velkostStranky * aktualnaStranka < zaznamov)

							if (this._mounted) {
								this.setState({
									odberneMiesta: {
										...odberneMiesta,
										data: {
											odberneMiesta: uniqBy(
												[
													...get(odberneMiesta, 'data.odberneMiesta', []),
													...get(odberneMiestaData, 'response.obsah.odberneMiesta', [])
												],
												'cislo'
											),
											miestaSpotreby: uniqBy(
												[
													...get(odberneMiesta, 'data.miestaSpotreby', []),
													...get(odberneMiestaData, 'response.obsah.miestaSpotreby', [])
												],
												'cislo'
											),
											zmluvneUcty: uniqBy(
												[...get(odberneMiesta, 'data.zmluvneUcty', []), ...get(odberneMiestaData, 'response.obsah.zmluvneUcty', [])],
												'cislo'
											),
											zmluvneVztahy: uniqBy(
												[
													...get(odberneMiesta, 'data.zmluvneVztahy', []),
													...get(odberneMiestaData, 'response.obsah.zmluvneVztahy', [])
												],
												'cislo'
											)
										},
										isLoading: !isLastPage
									}
								})
							}

							if (!isLastPage) {
								await this.loadOdberneMiestaByParts(stranka + 1)
							}
						}
					}
				)
			}
		} catch (e) {
			if (this._mounted) {
				this.setState({
					odberneMiesta: {
						...odberneMiesta,
						isLoading: false,
						isFailure: true
					}
				})
			}
		}
	}

	componentDidMount() {
		this._mounted = true

		const { hasPermission } = this.state

		if (hasPermission) {
			this.loadPlanySplatok()
			this.loadOdberneMiestaByParts()
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	commonContentContainer = (content) => <div className='box-content'>{content}</div>

	render() {
		const { interakcia, t } = this.props

		const { hasPermission, planySplatok, odberneMiesta } = this.state

		if (!hasPermission) {
			return this.commonContentContainer(<ElementFailure text={t('translation:Common.Na zobrazenie informácií nemáte potrebné oprávnenia')} />)
		}

		if (get(planySplatok, 'isLoading') || get(odberneMiesta, 'isLoading')) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (get(planySplatok, 'isFailure') || get(odberneMiesta, 'isFailure')) {
			return this.commonContentContainer(<ElementFailure text={t('translation:PlanySplatok.Nepodarilo sa načítať plány splátok')} />)
		}

		if (isEmpty(get(planySplatok, 'data', []))) {
			return this.commonContentContainer(<ElementEmptyContent text={t('translation:PlanySplatok.Obchodný partner nemá žiadne otvorené plány splátok')} />)
		}

		const backUrl = btoa(`${window.location.pathname}${window.location.search}`)

		return this.commonContentContainer(
			map(get(planySplatok, 'data', []), (planSplatok) => {
				const { odberneMiesto, miestoSpotreby } = getContextForPlanySplatok(planSplatok, get(odberneMiesta, 'data', []))

				const productIcon = getIconByKomoditaTyp(get(odberneMiesto, 'komoditaTyp'))
				const stavColor = getColorByPlanSplatokStav(get(planSplatok, 'stav.id'))

				return (
					<Link
						key={`planSplatok-${get(planSplatok, 'cislo')}`}
						to={`${setRouteParams(PLANY_SPLATOK_DETAIL, get(interakcia, 'opCislo'), get(planSplatok, 'id'))}?backUrl=${backUrl}`}
						className='inner-box'
					>
						<table>
							<tbody>
								<tr>
									<td>{t('translation:Common.Zmluvný účet')}</td>
									<td className='text-right'>
										<strong>{get(planSplatok, 'zmluvnyUcetCislo')}</strong>
									</td>
								</tr>
								<tr>
									<td>{t('translation:Common.Miesto spotreby')}</td>
									<td className='text-right'>
										<strong>{get(miestoSpotreby, 'adresa') ? formatAddress(get(miestoSpotreby, 'adresa')) : '-'}</strong>
									</td>
								</tr>
								{get(odberneMiesto, 'komoditaTyp.id') == KOMODITA_TYP.ELEKTRINA && (
									<tr>
										<td>{t('translation:Common.EIC')}</td>
										<td className='text-right'>
											<strong>{get(odberneMiesto, 'EIC', '-')}</strong>
										</td>
									</tr>
								)}
								{get(odberneMiesto, 'komoditaTyp.id') == KOMODITA_TYP.PLYN && (
									<tr>
										<td>{t('translation:Common.POD')}</td>
										<td className='text-right'>
											<strong>{get(odberneMiesto, 'POD', '-')}</strong>
										</td>
									</tr>
								)}
								<tr>
									<td>{t('translation:Common.Produkt')}</td>
									<td className='text-right'>
										<strong className='icon'>
											{productIcon && <img src={productIcon} alt={get(odberneMiesto, 'komoditaTyp.nazov') || '-'} />}
											<span>{get(odberneMiesto, 'komoditaTyp.nazov') || '-'}</span>
										</strong>
									</td>
								</tr>
								<tr>
									<td>{t('translation:Common.Dátum od - do')}</td>
									<td className='text-right'>
										<strong>{`${formatDate(get(planSplatok, 'datumOd'), null, 'DD.MM.YYYY')} - ${formatDate(
											get(planSplatok, 'datumDo'),
											null,
											'DD.MM.YYYY'
										)}`}</strong>
									</td>
								</tr>
								<tr>
									<td>{t('translation:PlanySplatok.Počet splátok')}</td>
									<td className='text-right'>
										<strong>{get(planSplatok, 'splatkyPocet', '-')}</strong>
									</td>
								</tr>
								<tr>
									<td>{t('translation:PlanySplatok.Stav plánu splátok')}</td>
									<td className='text-right'>
										<div className='label' data-color={stavColor}>
											{get(planSplatok, 'stav.nazov') || '-'}
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</Link>
				)
			})
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(SplatkoveKalendareWidget)
