import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import cx from 'classnames'

class DigitalizaciaBasicSelect extends React.PureComponent {
	static propTypes = {
		onChange: PropTypes.func,
		customStyles: PropTypes.shape()
	}

	constructor(props) {
		super(props)
		this.selectRef = React.createRef()
	}

	handleOnChange = (value, action) => {
		const { onChange } = this.props
		if (onChange) {
			onChange(value, action)
		}
	}

	handleChangeMultiple = (val, action) => {
		const { onChange } = this.props
		const values = val || []
		onChange(values, action)
	}

	render() {
		const { customStyles, ...props } = this.props

		let value = props.value !== undefined ? props.value : null
		let handleChange
		if (props.isMulti) {
			handleChange = this.handleChangeMultiple
			if (!value) {
				value = []
			}
		} else {
			handleChange = this.handleOnChange
		}

		return (
			<div className={cx('select-wrapper', { 'has-error': !!props.error })}>
				{props.label && <label>{props.label}</label>}
				<Select
					classNamePrefix='react-select'
					{...props}
					value={value}
					noOptionsMessage={() => 'Nič sa nenašlo.'}
					// NOTE: next lines are for IE hot fix https://github.com/JedWatson/react-select/issues/1020
					onChange={handleChange}
					ref={this.selectRef}
					styles={customStyles}
				/>
				{props.error && <div className='text-danger'>{props.error}</div>}
			</div>
		)
	}
}

export default DigitalizaciaBasicSelect
