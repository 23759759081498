import React from 'react'
import PropTypes from 'prop-types'
import { filter, map } from 'lodash'

class FormInfo extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			shown: false
		}
	}

	render() {
		const koKriteria = filter(this.props.koKriteria, (koKriterium) => !koKriterium.vysledok)
		const validacneKriteria = filter(this.props.validacneKriteria, (validacneKriterium) => !validacneKriterium.vysledok)
		const schvalovacieKriteria = filter(
			this.props.schvalovacieKriteria,
			(schvalovacieKriterium) => !schvalovacieKriterium.vysledok || schvalovacieKriterium.error
		)

		const valKriteriaRows = map(validacneKriteria, (valKriterium, index) => {
			let color = 'red'
			let icon = 'error'
			if (valKriterium.statusTyp === 'WARN') {
				color = 'orange'
				icon = 'info'
			} else if (valKriterium.statusTyp === 'INFO') {
				color = 'blue'
				icon = 'info'
			}
			let text = `${valKriterium.nazov}: ${valKriterium.popis}`
			if (valKriterium.statusTyp === 'WARN' || valKriterium.statusTyp === 'INFO') {
				text = valKriterium.popis
			}
			return (
				<div key={`validacneKriterium-${index}`} className='box-header' data-color={color} data-icon={icon}>
					{text}
				</div>
			)
		})
		const koKriteriaRows = map(koKriteria, (koKriterium, index) => (
			<div key={`koKriterium-${index}`} className='box-header' data-color='red' data-icon='info'>
				{koKriterium.nazov}: {koKriterium.popis}
			</div>
		))
		const schvalovacieKriteriaRows = map(schvalovacieKriteria, (schvalovacieKriterium, index) => (
			<div key={`schvalovacieKriterium-${index}`} className='box-header' data-color='orange' data-icon='info'>
				{schvalovacieKriterium.nazov}: {schvalovacieKriterium.popis}
			</div>
		))
		const simpleMessages = map(this.props.simpleMessages, (message, index) => (
			<div key={`simpleMessage-${index}`} className='box-header' data-color='orange' data-icon='info'>
				{message}
			</div>
		))
		return (
			<>
				{koKriteriaRows}
				{valKriteriaRows}
				{schvalovacieKriteriaRows}
				{simpleMessages}
			</>
		)
	}
}

FormInfo.propTypes = {
	koKriteria: PropTypes.array,
	validacneKriteria: PropTypes.array,
	schvalovacieKriteria: PropTypes.array,
	simpleMessages: PropTypes.array
}

export default FormInfo
