import { get } from 'lodash'
import {
	BLOKOVANIE_UPOMINANIA_ALL_LOAD_START,
	BLOKOVANIE_UPOMINANIA_LOAD_START,
	BLOKOVANIE_UPOMINANIA_ALL_LOAD_PART_DONE,
	BLOKOVANIE_UPOMINANIA_LOAD_DONE,
	BLOKOVANIE_UPOMINANIA_LOAD_FAIL,
	BLOKOVANIE_UPOMINANIA_CLEAR
} from '../types/pohladavkyTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	blokovanieUpominania: {
		data: [],
		page: 1,
		isFailure: false,
		isLoading: false,
		isLastPage: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case BLOKOVANIE_UPOMINANIA_ALL_LOAD_START:
			return {
				...state,
				blokovanieUpominania: {
					...state.blokovanieUpominania,
					data: initState.blokovanieUpominania.data,
					page: initState.blokovanieUpominania.page,
					isLoading: true,
					isFailure: false
				}
			}
		case BLOKOVANIE_UPOMINANIA_LOAD_START:
			return {
				...state,
				blokovanieUpominania: {
					...state.blokovanieUpominania,
					page: get(action, 'payload.page'),
					isLoading: true,
					isFailure: false
				}
			}
		case BLOKOVANIE_UPOMINANIA_ALL_LOAD_PART_DONE:
			return {
				...state,
				blokovanieUpominania: {
					...state.blokovanieUpominania,
					data: get(action, 'payload.blokovanieUpominania')
				}
			}
		case BLOKOVANIE_UPOMINANIA_LOAD_DONE:
			return {
				...state,
				blokovanieUpominania: {
					...state.blokovanieUpominania,
					data: get(action, 'payload.blokovanieUpominania'),
					isLoading: false,
					isFailure: false,
					isLastPage: get(action, 'payload.isLastPage')
				}
			}
		case BLOKOVANIE_UPOMINANIA_LOAD_FAIL:
			return {
				...state,
				blokovanieUpominania: {
					...initState.blokovanieUpominania,
					isLoading: false,
					isFailure: true
				}
			}
		case BLOKOVANIE_UPOMINANIA_CLEAR:
			return {
				...state,
				blokovanieUpominania: {
					...initState.blokovanieUpominania
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
