import React from 'react'
import Creatable from 'react-select/creatable'
import { find, findIndex, throttle, isEmpty } from 'lodash'
import PropTypes from 'prop-types'

// utils
import { SUHLAS_HODNOTA } from '../../utils/enums'
import { createSuhlasDTO } from '../../utils/suhlas'

const FONT_COLOR = '#191919'

const DEFAULT_STYLES = {
	placeholder: (provided) => ({
		...provided,
		color: FONT_COLOR
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		backgroundColor: FONT_COLOR
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		color: FONT_COLOR
	})
}

class ReasonSelector extends React.Component {
	constructor(props) {
		super(props)
		this.selectRef = React.createRef()
		this.state = {
			open: false,
			emptyValue: true,
			options: props.selectOptions,
			useValue: null,
			placeholderStyle: {},
			focused: false
		}
	}

	static getDerivedStateFromProps(props) {
		if (isEmpty(props.value)) {
			return null
		}

		return {
			emptyValue: false
		}
	}

	componentDidMount() {
		const { value } = this.props

		if (value) {
			this.setState({
				useValue: value
			})
		}
	}

	componentDidUpdate(prevProps) {
		const { value } = this.props

		if (value !== prevProps.value) {
			this.setState({
				useValue: value
			})
		}
	}

	handleMenuOpen = () => {
		this.setState({
			open: true
		})
	}

	handleBlur = () => {
		const focusedElement = document.activeElement
		if (focusedElement && !focusedElement.className.includes('react-select')) {
			this.setState({
				open: false,
				focused: false
			})
		} else {
			throttle(this.selectRef.current.focus(), 150)
		}
	}

	submitChange = (option) => {
		const { onChange } = this.props

		if (isEmpty(option)) {
			onChange(null)
			return
		}

		const isEmptyValue = option.value === null || option.value === ''

		this.setState({
			emptyValue: isEmptyValue,
			useValue: option.value,
			focused: false,
			open: false
		})

		onChange(createSuhlasDTO(SUHLAS_HODNOTA.NAMIETKA, option.value, option.label))
	}

	handleChange = (option) => {
		const val = option || null

		this.submitChange(val)
	}

	createOption = (label) => ({
		label,
		value: label
	})

	handleCreate = (inputValue) => {
		const newOption = this.createOption(inputValue)
		const { options } = this.state

		this.setState({
			options: [...options, newOption]
		})

		this.submitChange(inputValue)
	}

	getDisplayStyleByValue = (data) => {
		const { focused } = this.state

		return focused && data ? 'none' : 'inherit'
	}

	render() {
		const { reasonIsNotActual, ...otherProps } = this.props
		const { open, emptyValue, options, useValue, focused } = this.state

		if (useValue && findIndex(options, (option) => option.value === useValue) === -1) {
			options.push({
				value: useValue,
				label: useValue
			})
		}
		const val = find(options, (option) => option.value == useValue)
		let styles = {
			singleValue: (provided, { data }) => ({
				...provided,
				display: this.getDisplayStyleByValue(data)
			})
		}

		if ((reasonIsNotActual || emptyValue) && !focused) {
			styles = {
				...DEFAULT_STYLES,
				control: (provided) => ({
					...provided,
					backgroundColor: '#FFB6B6 !important',
					boxShadow: 'none !important'
				})
			}
		}

		return (
			<div className='select-wrapper'>
				<Creatable
					{...otherProps}
					value={val}
					onChange={this.handleChange}
					handleCreate={this.handleCreate}
					onCreateOption={this.handleCreate}
					noOptionsMessage={() => 'Nič sa nenašlo.'}
					placeholder={this.props.placeholder || 'Uviesť dôvod'}
					options={options}
					classNamePrefix='react-select'
					formatCreateLabel={(input) => 'Potvrdiť dôvod:' + ` "${input}"`}
					cacheOptions={false}
					styles={styles}
					isClearable={true}
					onFocus={() => this.setState({ focused: true })}
					// NOTE: next lines are for IE hot fix https://github.com/JedWatson/react-select/issues/1020
					onMenuOpen={this.handleMenuOpen}
					menuIsOpen={!this.props.isDisabled && open}
					onBlur={this.handleBlur}
					ref={this.selectRef}
				/>
			</div>
		)
	}
}

ReasonSelector.propTypes = {
	value: PropTypes.any,
	selectOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any.isRequired,
			label: PropTypes.string.isRequired
		})
	),
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	isDisabled: PropTypes.bool,
	error: PropTypes.any,
	reasonIsNotActual: PropTypes.bool
}

ReasonSelector.defaultProps = {
	input: {
		value: null
	}
}

export default ReasonSelector
