import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'

import ElementLoading from './ElementLoading'

class WidgetBox extends React.Component {
	static propTypes = {
		attributes: PropTypes.array,
		title: PropTypes.string,
		modifiable: PropTypes.bool,
		isStatic: PropTypes.bool,
		addWidgetBoxHandler: PropTypes.func,
		t: PropTypes.func.isRequired,
		hasPermission: PropTypes.bool,
		alreadyAdded: PropTypes.bool
	}

	_mounted = false

	constructor(props) {
		super(props)

		this.state = {
			recentlyAdded: false
		}
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		const { alreadyAdded } = this.props
		const { recentlyAdded } = this.state

		if (alreadyAdded != prevProps.alreadyAdded && recentlyAdded) {
			this.setState({
				recentlyAdded: false
			})
		}
	}

	addWidgetBox = (body) => {
		const { addWidgetBoxHandler } = this.props

		if (!this._mounted) {
			return
		}

		this.setState({ status: 'PROCESSING' })

		addWidgetBoxHandler(body)
			.then(() => {
				if (!this._mounted) {
					return
				}
				this.setState({
					status: 'ADDED',
					recentlyAdded: true
				})
				setTimeout(
					() =>
						this.setState({
							status: null
						}),
					3000
				)
			})
			.catch(() => this._mounted && this.setState({ status: null }))
	}

	render() {
		const { t, hasPermission, alreadyAdded, isStatic } = this.props
		const { recentlyAdded } = this.state

		let header = <div className={cx('widget-box-header', { active: alreadyAdded || recentlyAdded })}>{this.props.title}</div>
		if (alreadyAdded) {
			header = (
				<Tooltip html={t('components:WidgetBox.Pridaný na prehľade')} position='bottom' trigger='mouseenter' theme='light'>
					{header}
				</Tooltip>
			)
		}

		return (
			<div className='col-4'>
				<div className='widget-box'>
					{header}
					<div className='widget-box-content'>
						<ul>
							{this.props.attributes.map((value, index) => (
								<li key={index}>{value}</li>
							))}
						</ul>
					</div>
					<div className='widget-box-footer clearfix'>
						{!this.state.status && hasPermission && !isStatic && (
							<div className='widget-box-overlay'>
								<button className='button' data-type='outline' data-color='blue' onClick={this.addWidgetBox}>
									{t('components:WidgetBox.Pridať do prehľadu')}
								</button>
							</div>
						)}
						{!this.state.status && !hasPermission && (
							<div className='label pull-left' data-type='outline'>
								{t('components:WidgetBox.Nedostatočné oprávnenia')}
							</div>
						)}
						{this.state.status == 'PROCESSING' && (
							<div className='widget-box-overlay'>
								<ElementLoading />
							</div>
						)}
						{this.state.status == 'ADDED' && (
							<div className='widget-box-overlay'>
								<button className='button' data-color='green'>
									{t('components:WidgetBox.Widget pridaný')}
								</button>
							</div>
						)}
						{this.state.status != 'PROCESSING' && hasPermission && (
							<div className='label pull-left' data-type='outline'>
								{this.props.modifiable ? t('components:WidgetBox.Modifikovateľný') : t('components:WidgetBox.Statický')}
							</div>
						)}
						{/* <div className="pull-right">{this.props.assignedQuantity}/{this.props.availableQuantity}</div> */}
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation('components')(WidgetBox)
