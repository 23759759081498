import { WARN, ERROR, INFO } from './enums'

export default (errorType) => {
	switch (errorType) {
		case WARN:
			return 'orange'
		case INFO:
			return 'blue'
		case ERROR:
			return 'red'
		default:
			return 'blue'
	}
}
