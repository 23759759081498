import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash'
import { history } from '../../../utils/history'

// actions
import * as DataActions from '../../../actions/DataActions'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/attributesConfig'

// utils
import { getRequiredPermissionsForUkonRead, checkPermissions } from '../../../utils/permissionsHoc'
import { getReq } from '../../../utils/request'
import { setRouteParams, UKON_DETAIL } from '../../../utils/routes'

class RelatedUkonField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		buttonLabel: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		value: PropTypes.shape()
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	loadUkonDetail = async () => {
		const { auth, interakcia, value, dataActions } = this.props

		const res = await getReq(`/api/v2/ukony/${value}`)

		const relatedOpCislo = get(res, 'response.content.opCislo')
		const relatedUkon = get(res, 'response.content') || null

		const permissions = getRequiredPermissionsForUkonRead(relatedUkon)
		if (!isEmpty(permissions) && !checkPermissions(get(auth, 'user.roles', []), permissions)) {
			dataActions.dataChanged('forbiddenModal', true)
		} else {
			const opCislo = get(interakcia, 'opCislo')
			// CP-1810 - open in new tab if `Ukon` is assigned to another OP
			if (opCislo != relatedOpCislo) {
				const win = window.open(`${setRouteParams(UKON_DETAIL, relatedOpCislo, value)}?clear=true&keepRoute=true`, '_blank')
				win.focus()
			} else {
				history.push(setRouteParams(UKON_DETAIL, opCislo, value))
			}
		}
	}

	render() {
		const { label, buttonLabel, editMode, value, columnsCount = COLUMNS_COUNT.THREE } = this.props

		if (editMode != EDIT_MODE.DETAIL || !value) {
			return null
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{label}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td />}
							<td>
								<a
									onClick={(e) => {
										e.preventDefault()
										this.loadUkonDetail()
									}}
									style={{ textDecoration: 'underline', cursor: 'pointer' }}
									className='content'
								>
									{buttonLabel}
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	interakcia: get(state, 'interakcie.detail.data')
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	dataActions: bindActionCreators(DataActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(RelatedUkonField)
