import React from 'react'
import { Collapse } from 'react-collapse'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { head } from 'lodash'
import { Tooltip } from 'react-tippy'

// components
import SuhlasBadge from './SuhlasBadge'
import UdelenySuhlas from './UdelenySuhlas'

// utils
import { formatDate } from '../../utils/date'
import { history } from '../../utils/history'
import { setRouteParams, HISTORIA_SUHLASU, ZMENA_SUHLASU, OP_UPRAVIT, ZMLUVNE_UCTY_UPRAVIT } from '../../utils/routes'
import { TYP_SUHLASU, SUHLAS_TYP_VYJADRENIA, DEFINICIA_TYP, SUHLASY_KATEGORIA } from '../../utils/enums'
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'

// resources
import documentErrorIcon from '../../resources/img/icons/document-error.svg'

class SuhlasCollapsibleItem extends React.Component {
	static propTypes = {
		suhlas: PropTypes.shape({}).isRequired,
		showWarning: PropTypes.bool,
		showSuhlasCategory: PropTypes.bool,
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			isSuhlasOpen: false,
			isPosledneVyjadreniaOpen: false,
			isDefiniciaSuhlasuOpen: false,
			isZnenieSuhlasuOpen: false
		}
	}

	onSuhlasClick = () => {
		this.setState({
			isSuhlasOpen: !this.state.isSuhlasOpen
		})
	}

	onPosledneVyjadreniaClick = () => {
		this.setState({
			isPosledneVyjadreniaOpen: !this.state.isPosledneVyjadreniaOpen
		})
	}

	onDefiniciaSuhlasuClick = () => {
		this.setState({
			isDefiniciaSuhlasuOpen: !this.state.isDefiniciaSuhlasuOpen
		})
	}

	onZnenieSuhlasuClick = () => {
		this.setState({
			isZnenieSuhlasuOpen: !this.state.isZnenieSuhlasuOpen
		})
	}

	getUrl = (definiciaId, opCislo, zuCislo) => {
		if (definiciaId === DEFINICIA_TYP.KONTAKTNY_TELEFON || definiciaId === DEFINICIA_TYP.KONTAKTY_EMAIL) {
			return setRouteParams(OP_UPRAVIT, opCislo)
		}
		if (definiciaId === DEFINICIA_TYP.E_FAKTURA || definiciaId === DEFINICIA_TYP.E_PLATBA || definiciaId === DEFINICIA_TYP.E_PREPLATKY) {
			return setRouteParams(ZMLUVNE_UCTY_UPRAVIT, opCislo, zuCislo)
		}
	}

	redirectToUri = (definiciaId, opCislo, zuCislo) => {
		history.push(this.getUrl(definiciaId, opCislo, zuCislo))
	}

	renderTitle = (suhlasKategoriaId, suhlas) => {
		if (!suhlasKategoriaId || !suhlas) {
			return ''
		}

		switch (suhlasKategoriaId) {
			case SUHLASY_KATEGORIA.DIGITALIZACIA:
				return suhlas?.zmluvnyUcet
			case SUHLASY_KATEGORIA.CRIF:
				return suhlas?.produktNazov || suhlas?.zmluvnyUcetCislo
			default:
				return ''
		}
	}

	getActioBtnTitle = (poslednySuhlasPrePoslednuVerziu) => {
		const { t, suhlas } = this.props
		if (suhlas.suhlasKategoria.id === SUHLASY_KATEGORIA.DIGITALIZACIA) {
			return t('translation:Suhlasy.Zmeniť dôvod nesúhlasu')
		}
		if (poslednySuhlasPrePoslednuVerziu) {
			return t('translation:Suhlasy.Zmeniť vyjadrenie')
		}
		return t('translation:Suhlasy.Nové vyjadrenie')
	}

	checkNesuhlas = (suhlas) => {
		return !head(suhlas.suhlasVerzia)?.suhlas?.some((suhlas) => suhlas?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NESUHLAS)
	}

	render() {
		const { t, suhlas, interakcia, showWarning } = this.props

		const verzieSuhlasov = suhlas.suhlasVerzia
		const najnovsiaVerziaSuhlasu = verzieSuhlasov?.length > 0 ? verzieSuhlasov[verzieSuhlasov.length - 1] : null

		let udeleneSuhlasy = null
		if (Array.isArray(verzieSuhlasov) && verzieSuhlasov.length > 0) {
			udeleneSuhlasy = verzieSuhlasov
				.map((verziaSuhlasu) => {
					if (Array.isArray(verziaSuhlasu.suhlas) && verziaSuhlasu.suhlas.length > 0) {
						return {
							...verziaSuhlasu.suhlas[0],
							verzia: verziaSuhlasu.verzia
						}
					}

					return null
				})
				.filter((udelenySuhlas) => udelenySuhlas !== null)
		}

		let poslednySuhlas = null
		if (Array.isArray(udeleneSuhlasy) && udeleneSuhlasy.length > 0) {
			poslednySuhlas = udeleneSuhlasy[udeleneSuhlasy.length - 1]
		}

		let poslednySuhlasPrePoslednuVerziu = null
		if (najnovsiaVerziaSuhlasu && Array.isArray(najnovsiaVerziaSuhlasu.suhlas) && udeleneSuhlasy.length > 0) {
			poslednySuhlasPrePoslednuVerziu = najnovsiaVerziaSuhlasu.suhlas[najnovsiaVerziaSuhlasu.suhlas.length - 1]
		}

		// Oprávnený záujem ak nemá explicitné vyjadrenie, tak je odsúhlasený
		// Zobrazujeme badge pre poslednú verziu súhlasu
		let typVyjadrenieSuhlasInBadge
		if (suhlas?.suhlasTyp?.id === TYP_SUHLASU.OPRAVNENY_ZAUJEM && poslednySuhlasPrePoslednuVerziu?.typVyjadrenieSuhlas == null) {
			typVyjadrenieSuhlasInBadge = SUHLAS_TYP_VYJADRENIA.SUHLAS
		} else {
			typVyjadrenieSuhlasInBadge = poslednySuhlasPrePoslednuVerziu?.typVyjadrenieSuhlas
		}

		const isItDigitalizacia = suhlas.suhlasKategoria.id === SUHLASY_KATEGORIA.DIGITALIZACIA
		const isItCRIF = suhlas.suhlasKategoria.id === SUHLASY_KATEGORIA.CRIF

		const isBtnDisabled = !suhlas.edit || (isItDigitalizacia && this.checkNesuhlas(suhlas))
		const showSuhlasyPreProdukty = (isItDigitalizacia || isItCRIF) && najnovsiaVerziaSuhlasu?.suhlas && najnovsiaVerziaSuhlasu?.suhlas.length > 1

		return (
			<div className='box collapsible'>
				<div className='box-header clearfix' style={{ fontWeight: 'normal', padding: '0' }}>
					<div className='consent-collapsible-header' onClick={this.onSuhlasClick}>
						{showWarning && (
							<div>
								<div className='consent-collapsible-warning'>
									<img src={documentErrorIcon} />
								</div>
							</div>
						)}
						<div className='consent-collapsible-name' onClick={this.onOpenSuhlasyList}>
							{this.props.showSuhlasCategory && <div className='consent-collapsible-category'>{suhlas.suhlasKategoria?.nazov}</div>}
							<strong>{suhlas.nazov}</strong>
						</div>
						{najnovsiaVerziaSuhlasu?.verzia > 1 && (
							<div className='consent-collapsible-version'>{`${t('translation:Suhlasy.Verzia')} ${najnovsiaVerziaSuhlasu.verzia}`}</div>
						)}
						<div className='consent-collapsible-divider' />
						{poslednySuhlasPrePoslednuVerziu?.datumVyjadrenie && (
							<div className='consent-collapsible-date'>{`${t('translation:Suhlasy.Vyjadrenie z')} ${formatDate(
								poslednySuhlasPrePoslednuVerziu?.datumVyjadrenie,
								null,
								'DD.MM.YYYY'
							)}`}</div>
						)}
						<div className='consent-badge'>
							<SuhlasBadge typVyjadrenieSuhlas={typVyjadrenieSuhlasInBadge} />
						</div>
						<div>
							<div className={cx('button', 'noselect', 'arrow', { opened: this.state.isSuhlasOpen })} data-type='icon-button' data-icon='arrow' />
						</div>
					</div>
				</div>
				<div className={'box-content'} style={{ height: 'auto', overflow: 'inherit' }}>
					<Collapse isOpened={this.state.isSuhlasOpen} hasNestedCollapse>
						<div className='consent-action-container'>
							<div className='consent-action-button'>
								<Permissions
									allowed={[PERMISSIONS.UKON_ZMENA_SUHLASU]}
									render={(hasPerm, actions) => {
										return (
											<Tooltip
												html={t('translation:Editovať je možné len nesúhlasné vyjadrenie')}
												position='right'
												trigger='mouseenter'
												theme='light'
												disabled={!isBtnDisabled}
											>
												<Link
													to={() => {
														if (isItDigitalizacia || isItCRIF) {
															return `${setRouteParams(ZMENA_SUHLASU, interakcia?.opCislo, suhlas?.id)}?suhlasKategoriaId=${
																suhlas?.suhlasKategoria?.id
															}`
														}
														return setRouteParams(ZMENA_SUHLASU, interakcia?.opCislo, suhlas?.id)
													}}
													className={cx('button', { disabled: !hasPerm || isBtnDisabled })}
													data-color='blue'
													onClick={(e) => {
														if (!hasPerm) {
															e.preventDefault()
															actions.openForbiddenModal()
															// CES also handle if suhlas is editable
														} else if (isBtnDisabled) {
															e.preventDefault()
														}
													}}
												>
													{this.getActioBtnTitle(poslednySuhlasPrePoslednuVerziu)}
												</Link>
											</Tooltip>
										)
									}}
								/>
							</div>
						</div>
						{showSuhlasyPreProdukty && (
							<>
								<div className='consent-products-container'>
									<div className='consent-action-first-column'>
										<strong>{isItCRIF ? t('translation:Suhlasy.Produkty') : t('translation:Suhlasy.Zmluvné účty')}</strong>
									</div>
								</div>
								{najnovsiaVerziaSuhlasu.suhlas.map((verziaSuhlasuSuhlas) => (
									<div className='consent-outher-collabsible'>
										<div
											className='consent-inner-collabsible'
											onClick={() =>
												this.redirectToUri(
													najnovsiaVerziaSuhlasu.definiciaId,
													verziaSuhlasuSuhlas.opCislo,
													verziaSuhlasuSuhlas.zmluvnyUcet
												)
											}
										>
											<div className='consent-inner-collabsible-name'>
												{this.renderTitle(suhlas.suhlasKategoria.id, verziaSuhlasuSuhlas)}
											</div>
											<div className='consent-collapsible-right-box'>
												{verziaSuhlasuSuhlas?.datumVyjadrenie && (
													<div className='consent-collapsible-date'>{`${t('translation:Suhlasy.Vyjadrenie z')} ${formatDate(
														verziaSuhlasuSuhlas?.datumVyjadrenie,
														null,
														'DD.MM.YYYY'
													)}`}</div>
												)}
												<div className='consent-badge'>
													<SuhlasBadge typVyjadrenieSuhlas={verziaSuhlasuSuhlas.typVyjadrenieSuhlas} />
												</div>
											</div>
										</div>
									</div>
								))}
							</>
						)}
						{udeleneSuhlasy && udeleneSuhlasy.length > 0 && (
							<div className='consent-outher-collabsible'>
								<div className='consent-inner-collabsible' onClick={this.onPosledneVyjadreniaClick}>
									<div className='consent-inner-collabsible-name'>{t('translation:Suhlasy.Posledné vyjadrenia')}</div>
									<div
										className={cx('consent-inner-collabsible-action', 'button', { bleached: this.state.isPosledneVyjadreniaOpen })}
										data-type='icon-button'
										data-icon='view'
										data-color={this.state.isPosledneVyjadreniaOpen ? 'blue' : undefined}
									/>
								</div>
								<Collapse isOpened={this.state.isPosledneVyjadreniaOpen}>
									<div className='consent-last-statement-container'>
										{verzieSuhlasov &&
											verzieSuhlasov.map((verziaSuhlasu) => {
												let udelenySuhlas = verziaSuhlasu?.suhlas[0] ? { ...verziaSuhlasu?.suhlas[0] } : null

												if (!udelenySuhlas) return null
												udelenySuhlas.verzia = verziaSuhlasu.verzia

												return <UdelenySuhlas key={udelenySuhlas.id} udelenySuhlas={udelenySuhlas} />
											})}
										<div className='consent-last-statement-history-link'>
											<Link to={setRouteParams(HISTORIA_SUHLASU, interakcia?.opCislo, suhlas?.id)}>
												{t('translation:Suhlasy.Zobraziť celú históriu')}
											</Link>
										</div>
									</div>
								</Collapse>
							</div>
						)}
						<div className='consent-outher-collabsible'>
							<div className='consent-inner-collabsible' onClick={this.onDefiniciaSuhlasuClick}>
								<div className='consent-inner-collabsible-name'>{t('translation:Suhlasy.Definícia súhlasu')}</div>
								<div
									className={cx('consent-inner-collabsible-action', 'button', { bleached: this.state.isDefiniciaSuhlasuOpen })}
									data-type='icon-button'
									data-icon='view'
									data-color={this.state.isDefiniciaSuhlasuOpen ? 'blue' : undefined}
								/>
							</div>
							<Collapse isOpened={this.state.isDefiniciaSuhlasuOpen}>
								<div className='consent-definition'>
									<div>
										<ul>
											<li>
												<span className='consent-definition-column-1'>{t('translation:Suhlasy.Typ definície')}</span>
												<span className='consent-definition-column-2'>{suhlas.suhlasTyp?.nazov ?? '-'}</span>
											</li>
											<li>
												<span className='consent-definition-column-1'>{t('translation:Suhlasy.Názov')}</span>
												<span className='consent-definition-column-2'>{suhlas.nazov ?? '-'}</span>
											</li>
											<li>
												<span className='consent-definition-column-1'>{t('translation:Suhlasy.Počet verzií')}</span>
												<span className='consent-definition-column-2'>{suhlas?.suhlasVerzia?.length ?? '-'}</span>
											</li>
											<li>
												<span className='consent-definition-column-1'>{t('translation:Suhlasy.Kategória')}</span>
												<span className='consent-definition-column-2'>{suhlas.suhlasKategoria?.nazov ?? '-'}</span>
											</li>
											<li>
												<span className='consent-definition-column-1'>{t('translation:Suhlasy.Skupina')}</span>
												<span className='consent-definition-column-2'>{suhlas.suhlasSkupina?.nazov ?? '-'}</span>
											</li>
											{suhlas.nadradenySuhlasId != undefined && (
												<li>
													<span className='consent-definition-column-1'>{t('translation:Suhlasy.Nadradený súhlas')}</span>
													<span className='consent-definition-column-2'>{suhlas.nadradenySuhlasId}</span>
												</li>
											)}
											<li>
												<span className='consent-definition-column-1'>{t('translation:Suhlasy.Aktívny zber')}</span>
												<span className='consent-definition-column-2'>
													{suhlas.aktivnyZber === true ? (
														<span className='consent-true'>{t('translation:Common.Áno').toUpperCase()}</span>
													) : (
														<span className='consent-false'>{t('translation:Common.Nie').toUpperCase()}</span>
													)}
												</span>
											</li>
											{suhlas.suhlasVerifikacia != undefined && (
												<li>
													<span className='consent-definition-column-1'>{t('translation:Suhlasy.Verifikácia súhlasu')}</span>
													<span className='consent-definition-column-2'>{`${suhlas.suhlasVerifikacia} ${t(
														'translation:Common.dní'
													)}`}</span>
												</li>
											)}
											{suhlas.dalsiPokus != undefined && (
												<li>
													<span className='consent-definition-column-1'>
														{t('translation:Suhlasy.Opakovaný zber je možné vykonať po')}
													</span>
													<span className='consent-definition-column-2'>{`${suhlas.dalsiPokus} ${t(
														'translation:Common.dňoch'
													)}`}</span>
												</li>
											)}
											{suhlas.pocetPokus != undefined && (
												<li>
													<span className='consent-definition-column-1'>{t('translation:Suhlasy.Počet pokusov o zber')}</span>
													<span className='consent-definition-column-2'>{suhlas.pocetPokus}</span>
												</li>
											)}
											<li>
												<span className='consent-definition-column-1'>{t('translation:Suhlasy.Definícia platná od')}</span>
												<span className='consent-definition-column-2'>
													{suhlas.platnostOd != undefined ? formatDate(suhlas.platnostOd, null, 'DD.MM.YYYY') : '-'}
												</span>
											</li>
											{suhlas.dobaPlatnosti != undefined && (
												<li>
													<span className='consent-definition-column-1'>
														{t('translation:Suhlasy.Súhlasy tejto definície sú platné po dobu')}
													</span>
													<span className='consent-definition-column-2'>
														{suhlas.dobaPlatnosti === true
															? t('translation:Suhlasy.od ukončenia zmluvného vzťahu')
															: `${suhlas.dobaPlatnosti} ${t('translation:Common.rokov')}`}
													</span>
												</li>
											)}
											<li>
												<span className='consent-definition-column-1'>{t('translation:Suhlasy.Účel')}</span>
												<span className='consent-definition-column-2'>{suhlas.ucel ?? '-'}</span>
											</li>
											{suhlas.defPreProdukt === true && (
												<li>
													<span className='consent-definition-column-1' style={{ maxWidth: 350 }}>
														{t('translation:Suhlasy.Definícia sa vzťahuje na zakúpené produkty obchodného partnera')}
													</span>
													<span className='consent-definition-column-2'>
														<span className='consent-true'>{t('translation:Common.Áno').toUpperCase()}</span>
													</span>
												</li>
											)}
											{suhlas.zmluvnyUcet === true && (
												<li>
													<span className='consent-definition-column-1' style={{ maxWidth: 350 }}>
														{t('translation:Suhlasy.Definícia sa vzťahuje na existujúce zmluvné účty obchodného partnera')}
													</span>
													<span className='consent-true'>{t('translation:Common.Áno').toUpperCase()}</span>
												</li>
											)}
										</ul>
									</div>
								</div>
							</Collapse>
						</div>
						{najnovsiaVerziaSuhlasu?.suhlasZnenie[0]?.suhlasZnenieText && (
							<div className='consent-outher-collabsible'>
								<div className='consent-inner-collabsible' onClick={this.onZnenieSuhlasuClick}>
									<div className='consent-inner-collabsible-name'>{t('translation:Suhlasy.Znenie súhlasu')}</div>
									<div
										className={cx('consent-inner-collabsible-action', 'button', { bleached: this.state.isZnenieSuhlasuOpen })}
										data-type='icon-button'
										data-icon='view'
										data-color={this.state.isZnenieSuhlasuOpen ? 'blue' : undefined}
									/>
								</div>
								<Collapse isOpened={this.state.isZnenieSuhlasuOpen}>
									<div className='consent-text'>{najnovsiaVerziaSuhlasu.suhlasZnenie[0].suhlasZnenieText}</div>
								</Collapse>
							</div>
						)}
					</Collapse>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail.data
})

export default withTranslation('components')(connect(mapStateToProps)(SuhlasCollapsibleItem))
