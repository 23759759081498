import React from 'react'
import cx from 'classnames'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import Select from '../../../../atoms/BasicSelect'

class SelectCategoryOneField extends React.Component {
	render() {
		const { t, input, ukonOptions, meta } = this.props
		const { error, touched } = meta

		return (
			<div className={cx('select-wrapper', { 'has-error': error && touched })}>
				<Select
					value={input.value || ''}
					options={ukonOptions}
					onChange={(value) => {
						input.onChange(value)
					}}
					className='small-input'
					placeholder={t('containers:UkoncenieInterakcie.Vyberte typ všeobecného pasívneho úkonu')}
					classNamePrefix='react-select'
				/>
				<div className='text-danger'>{error && touched ? 'Toto pole je povinné' : ''}</div>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(SelectCategoryOneField)
