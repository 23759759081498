import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { initialize, reset, change, destroy, getFormValues } from 'redux-form'
import { find, get, map, filter, head, isEmpty, includes, maxBy, forEach, size, nth, isEqual, some, set, cloneDeep, omit } from 'lodash'
import { bindActionCreators, compose } from 'redux'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import qs from 'qs'
import reactQueryParams from 'react-query-params'

// components
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementLoading from '../../components/ElementLoading'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import initProcesnyKonfigurator from '../../components/ProcesnyKonfigurator'

// utils
import { history } from '../../utils/history'
import { postReq, putReq } from '../../utils/request'
import { getUkonVstupByBiznisKanal } from '../../utils/scenar'
import { createFormInitValues } from '../../utils/form'
import { checkPermissions, getRequiredPermissionsForUkonWrite } from '../../utils/permissionsHoc'
import {
	SCENARE,
	NOTIFICATION_TYPES,
	BUSINESS_CHANNELS,
	FORMS,
	FILTER_SELECTORS,
	DOKUMENT_TYP,
	VSTUPNE_DOKUMENTY_ZIADOST_TYP,
	UKONY_CISELNIK,
	UKON_STAV,
	UKONCENIE_DOVOD,
	UKONY,
	SUHLAS_HODNOTA,
	DEFINICIA_TYP,
	BIZNIS_REFERENCIA_TYP
} from '../../utils/enums'
import { setRouteParams, UKON_DETAIL, ZMLUVY_ZOZNAM } from '../../utils/routes'
import { getUkonEnumByType, getProcesnyKonfigType } from '../../utils/ukony'
import { ATTRIBUTES_CONFIG, EDIT_MODE, recognizeMethodologicalGuideline } from './attributesConfig'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as UkonyActions from '../../actions/UkonyActions'
import * as TrackingActions from '../../actions/TrackingActions'
import * as DataActions from '../../actions/DataActions'
import * as StatusActions from '../../actions/StatusActions'
import * as OdberneMiestaActions from '../../actions/OdberneMiestaActions'
import * as UkonGenericActions from '../../actions/ProcesnyKonfigurator/UkonGenericActions'
import * as ZmluvneVztahyActions from '../../actions/ZmluvneVztahyActions'
import * as MiestaSpotrebyActions from '../../actions/MiestaSpotrebyActions'
import * as FormAddresssesActions from '../../actions/FormAddresses'

// resources
import GenericUkonStep from '../../components/GenericUkon/GenericUkonStep'

const scenarioOptions = [
	{
		kanal: BUSINESS_CHANNELS.ZSE_CENTRUM,
		scenarios: [{ typ: SCENARE.PODPISANIE_NA_MIESTE }, { typ: SCENARE.VYTLACIT_A_NAHRAT }, { typ: SCENARE.NAHRAT }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS]
	},
	{
		kanal: BUSINESS_CHANNELS.ZAKAZNICKA_LINKA,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS]
	},
	{
		kanal: BUSINESS_CHANNELS.BACK_OFFICE,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	}
]

class GenericUkonPage extends reactQueryParams {
	static propTypes = {
		interakcia: PropTypes.shape().isRequired,
		auth: PropTypes.shape().isRequired,
		tracking: PropTypes.shape().isRequired,
		ukonNovy: PropTypes.shape().isRequired,
		ciselniky: PropTypes.shape().isRequired,
		signedPdf: PropTypes.shape(),
		notification: PropTypes.shape(),
		templatePdf: PropTypes.shape(),
		procesnyKonfigurator: PropTypes.shape().isRequired,
		formValues: PropTypes.shape(),
		context: PropTypes.shape().isRequired,
		ukonyActions: PropTypes.shape().isRequired,
		trackingActions: PropTypes.shape().isRequired,
		dataActions: PropTypes.shape().isRequired,
		ukonGenericActions: PropTypes.shape().isRequired
	}

	defaultQueryParams = {
		backUrl: ''
	}

	_mounted = false

	constructor(props) {
		super(props)

		// filter possible scenars for signing pdf files
		const scenarioOption = filter(scenarioOptions, (scenarioOption) => {
			return get(scenarioOption, 'kanal') == get(props, 'auth.businessChannel.actual.id')
		})

		const { backUrl } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			backUrl: ''
		})

		this.state = {
			ponuka: null,
			supportedAttributes: [],
			unsupportedAttributes: [],
			attributesSteps: [],
			step: 1,
			stepsTotalCount: 2,
			ProcesnyKonfigurator: null,
			scenarioOption: head(scenarioOption),
			originalValues: {},
			isLoading: false,
			koKriteria: [],
			validacneKriteria: [],
			schvalovacieKriteria: [],
			dissallowedScenarios: [],
			backUrlLink,
			showMissingPonukaModal: false,
			showMissingPermissionsModal: false,
			dependency: null
		}
	}

	componentDidMount = async () => {
		this._mounted = true

		const {
			auth,
			ciselniky,
			dispatch,
			tracking,
			location,
			obchodnyPartner,
			ukonyActions,
			trackingActions,
			dataActions,
			ukonGenericActions,
			formAddressesActions,
			procesnyKonfigurator
		} = this.props

		const ponuka = get(location, 'ponuka')
		if (!ponuka) {
			dataActions.unregisterLeavePageModal()
			trackingActions.clearTracking()
			return this.setState({
				showMissingPonukaModal: true
			})
		}

		const permissions = getRequiredPermissionsForUkonWrite(get(ponuka, 'ukon'))
		if (!checkPermissions(get(auth, 'user.roles', []), permissions)) {
			dataActions.unregisterLeavePageModal()
			trackingActions.clearTracking()
			return this.setState({
				showMissingPermissionsModal: true
			})
		}

		formAddressesActions.formAddressClean()
		const addresses = [...map(get(obchodnyPartner, 'adresy', []), (address) => cloneDeep(address))]
		formAddressesActions.formAddressesInit(addresses)

		const ukonTyp = getUkonEnumByType(get(ponuka, 'ukon.typ'))

		const ukon = get(ponuka, 'ukon', {})
		const ukonData = get(ponuka, 'ukon.data', {})
		const ukonDataPred = get(ponuka, 'ukon.dataPred', {})

		if (get(ukon, 'id')) {
			ukonyActions.setUkonId(get(ukon, 'id'))
		}

		const { supportedAttributes, unsupportedAttributes, supportedEmptyLookupAttributes } = this.prepareAttributes(get(ponuka, 'atributy', []), ukon)

		const procesnyKonfigKey = getProcesnyKonfigType(get(ponuka, 'ukon.typ.id'), get(procesnyKonfigurator, 'data.ukony'))
		const ProcesnyKonfigurator = procesnyKonfigKey ? initProcesnyKonfigurator(FORMS.GENERIC_UKON, procesnyKonfigKey) : null

		dataActions.registerLeavePageModal()

		if (!get(tracking, 'startTime') || (get(tracking, 'startTime') && get(tracking, 'type') !== ukonTyp)) {
			trackingActions.tryToStartTracking(ukonTyp)
		}

		const initValuesData = {
			vstup: getUkonVstupByBiznisKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'ukonVstup')),
			podpisMiesto: get(auth, 'user.podpisMiesto', ''),
			splnomocnenec: null,
			ziadanyOd: new Date(),
			data: {
				...ukonData
			},
			dokumenty: get(ukon, 'dokumenty', [])
		}

		forEach(supportedAttributes, (attribute) => {
			get(attribute, 'cesta') && set(initValuesData, get(attribute, 'cesta'), get(ukon, get(attribute, 'cesta'), null))
		})

		const initValues = createFormInitValues(initValuesData)

		dispatch(initialize(FORMS.GENERIC_UKON, initValues, true))
		dispatch(reset(FORMS.GENERIC_UKON))

		this.setState(
			{
				ponuka,
				supportedAttributes,
				unsupportedAttributes,
				attributesSteps: supportedEmptyLookupAttributes,
				stepsTotalCount: size(supportedEmptyLookupAttributes) + 2,
				ProcesnyKonfigurator,
				originalValues: {
					...initValues,
					data: {
						...ukonDataPred
					}
				}
			},
			() => {
				const editMode = this.getEditMode(1)
				if (editMode == EDIT_MODE.EDIT) {
					const body = this.formatDataForUkon()
					ukonGenericActions.loadContext({
						...body,
						data: {
							...get(initValues, 'data')
						}
					})
				}
			}
		)
	}

	componentWillUnmount = () => {
		const { dispatch, trackingActions, ukonyActions, dataActions } = this.props

		const { supportedAttributes } = this.state

		dispatch(destroy(FORMS.GENERIC_UKON))

		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()

		// clear ukonId from storage
		ukonyActions.clearUkonId()

		forEach(supportedAttributes, (attribute) => {
			if (get(attribute, 'destroy')) {
				attribute.destroy(this.props, attribute)
			}
		})

		this._mounted = false
	}

	componentDidUpdate = (prevProps) => {
		const { formValues, dispatch, location } = this.props
		const { step, supportedAttributes } = this.state

		const ponuka = get(location, 'ponuka')
		const ukonTyp = getUkonEnumByType(get(ponuka, 'ukon.typ'))

		const enabledUkony = [
			UKONY.ZMLUVNY_VZTAH_NOVY,
			UKONY.ZMLUVNY_VZTAH_ZMENA,
			UKONY.ZMLUVNY_VZTAH_PREPIS_GAS,
			UKONY.ZMLUVNY_VZTAH_PREPIS_EE,
			UKONY.ZMLUVNY_VZTAH_ZMENA_PRODUKTU_EE,
			UKONY.ZMLUVNY_VZTAH_ZMENA_PRODUKTU_GAS,
			UKONY.ZMLUVNY_VZTAH_NOVY_EE_OBNOVA_MS,
			UKONY.ZMLUVNY_VZTAH_NOVY_GAS_OBNOVA_MS,
			UKONY.ZMLUVNY_VZTAH_NOVY_EE,
			UKONY.ZMLUVNY_VZTAH_NOVY_GAS,
			UKONY.PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS,
			UKONY.PODPIS_ZMLUVNEJ_DOKUMENTACIE_NOVY_ODBER,
			UKONY.PODPIS_ZMLUVNEJ_DOKUMENTACIE_OBNOVA_MS,
			UKONY.PODPIS_ZMLUVNEJ_DOKUMENTACIE_DODATOK,
			UKONY.PODPIS_ZMLUVNEJ_DOKUMENTACIE_VAS
		]

		// NOTE: set default value for ziadostTyp atribute based on https://jira.zsee.sk/jira/browse/CP-2980 request
		if (enabledUkony.includes(ukonTyp) && !isEqual(formValues?.dokumenty, prevProps.formValues?.dokumenty)) {
			// find AttrPrilohaDTO attribute with select options
			const attrPrilohaDTO = supportedAttributes?.find((atribut) => atribut.typ === 'AttrPrilohaDTO' || atribut.typ === 'AttrPrilohyDTO')
			if (attrPrilohaDTO && attrPrilohaDTO?.moznosti) {
				const value = head(Object.keys(attrPrilohaDTO.moznosti))
				if (!isEqual(formValues?.data?.ziadostTyp, value)) {
					dispatch(change(FORMS.GENERIC_UKON, `${attrPrilohaDTO.cesta}.ziadostTyp`, value))
				}
			}
		}

		const editMode = this.getEditMode(step)
		if (editMode == EDIT_MODE.EDIT && get(prevProps, 'formValues') && !isEqual(get(formValues, 'data'), get(prevProps, 'formValues.data'))) {
			const changedRecalcAttribute = some(supportedAttributes, (attribute) => {
				if (!get(attribute, 'cesta')) {
					return
				}
				if (isEqual(get(formValues, get(attribute, 'cesta')), get(prevProps, `formValues.${get(attribute, 'cesta')}`))) {
					return
				}
				if (get(attribute, 'recalc') && !get(attribute, 'vstupny')) {
					return attribute
				}
			})
			if (changedRecalcAttribute) {
				this.prepareDraftRecalc()
			}
		}
	}

	prepareAttributes = (attributes, ukon) => {
		const supportedAttributes = map(
			filter(attributes, (attribute) => {
				const config = ATTRIBUTES_CONFIG[get(attribute, 'typ')]
				return config && !get(config, 'readonly')
			}),
			(attribute) => ({
				...attribute,
				...ATTRIBUTES_CONFIG[get(attribute, 'typ')]
			})
		)
		const unsupportedAttributes = map(
			filter(attributes, (attribute) => {
				return !ATTRIBUTES_CONFIG[get(attribute, 'typ')]
			})
		)
		const supportedEmptyLookupAttributes = filter(supportedAttributes, (attribute) => {
			const attributeData = get(ukon, get(attribute, 'cesta'))
			return get(attribute, 'cesta') && get(attribute, 'vstupny') && (attributeData == null || attributeData == undefined)
		})

		return {
			supportedAttributes,
			unsupportedAttributes,
			supportedEmptyLookupAttributes
		}
	}

	prepareDraftRecalc = async () => {
		const { dispatch, formValues, ukonGenericActions, procesnyKonfigurator } = this.props
		const { ponuka, originalValues } = this.state

		this.setLoading(true)

		try {
			const body = this.formatDataForUkon(formValues)

			const res = await postReq(`/api/v2/ukony/draft-prepare`, null, body)
			const ponukaRecalc = get(res, 'response.content')

			const ukon = get(ponukaRecalc, 'ukon', {})
			const ukonDataPred = get(ponukaRecalc, 'ukon.dataPred', {})

			const { supportedAttributes, unsupportedAttributes, supportedEmptyLookupAttributes } = this.prepareAttributes(
				get(ponukaRecalc, 'atributy', []),
				ukon
			)

			forEach(supportedAttributes, (attribute) => {
				get(attribute, 'cesta') && dispatch(change(FORMS.GENERIC_UKON, get(attribute, 'cesta'), get(ukon, get(attribute, 'cesta'), null)))
			})

			const procesnyKonfigKey = getProcesnyKonfigType(get(ponukaRecalc, 'ukon.typ.id'), get(procesnyKonfigurator, 'data.ukony'))
			const ProcesnyKonfigurator = procesnyKonfigKey ? initProcesnyKonfigurator(FORMS.GENERIC_UKON, procesnyKonfigKey) : null

			this.setState(
				{
					step: 1,
					ponuka: {
						...ponuka,
						ukon: get(ponukaRecalc, 'ukon'),
						atributy: get(ponukaRecalc, 'atributy')
					},
					ProcesnyKonfigurator,
					supportedAttributes,
					unsupportedAttributes,
					attributesSteps: supportedEmptyLookupAttributes,
					stepsTotalCount: size(supportedEmptyLookupAttributes) + 2,
					originalValues: {
						...originalValues,
						data: {
							...get(originalValues, 'data', {}),
							...ukonDataPred
						}
					}
				},
				() => {
					const editMode = this.getEditMode(1)
					if (editMode == EDIT_MODE.EDIT) {
						const formattedValuesForUkon = this.formatDataForUkon(formValues)

						const body = {
							...formattedValuesForUkon,
							data: ukon.data
						}
						ukonGenericActions.loadContext(body)
					}
				}
			)

			this.setLoading(false)
		} catch (e) {
			this.setLoading(false)
		}
	}

	formDataForNesuhlasy = (values) => {
		let nesuhlasy = {}

		if (!isEmpty(values?.suhlasEfaktura?.nesuhlasDovod) && values.suhlasEfaktura?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
			nesuhlasy = {
				...nesuhlasy,
				[DEFINICIA_TYP.E_FAKTURA]: {
					...values.suhlasEfaktura.nesuhlasDovod,
					dovodAlt: values.suhlasEfaktura?.nesuhlasDovodIny
				}
			}
		}
		if (!isEmpty(values?.suhlasEplatba?.nesuhlasDovod) && values.suhlasEplatba?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
			nesuhlasy = {
				...nesuhlasy,
				[DEFINICIA_TYP.E_PLATBA]: {
					...values.suhlasEplatba.nesuhlasDovod,
					dovodAlt: values.suhlasEplatba?.nesuhlasDovodIny
				}
			}
		}
		if (!isEmpty(values?.suhlasEpreplatky?.nesuhlasDovod) && values.suhlasEpreplatky?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
			nesuhlasy = {
				...nesuhlasy,
				[DEFINICIA_TYP.E_PREPLATKY]: {
					...values.suhlasEpreplatky.nesuhlasDovod,
					dovodAlt: values.suhlasEpreplatky?.nesuhlasDovodIny
				}
			}
		}

		return nesuhlasy
	}

	formatDataForUkon = (values) => {
		const { interakcia, auth, tracking } = this.props
		const { supportedAttributes, ponuka, schvalovacieKriteria } = this.state

		let schvalovaciRezim = null
		if (!isEmpty(schvalovacieKriteria)) {
			const maxUrovenSchvalovania = get(
				maxBy(
					filter(schvalovacieKriteria, (schvalovacieKriterium) => !get(schvalovacieKriterium, 'vysledok')),
					'urovenSchvalovania'
				),
				'urovenSchvalovania',
				0
			)
			const schvalovacieKriteriaNormalize = map(schvalovacieKriteria, (schvalovacieKriterium) => ({
				id: get(schvalovacieKriterium, 'nazov'),
				vysledok: get(schvalovacieKriterium, 'vysledok'),
				dovod: get(schvalovacieKriterium, 'popis')
			}))
			schvalovaciRezim = {
				kod: maxUrovenSchvalovania,
				pravidla: schvalovacieKriteriaNormalize
			}
		}

		const startTime = get(tracking, 'startTime')
		const ziadanyOd = get(values, 'ziadanyOd')

		let ukon = {
			...omit(values, ['INIT_TIMESTAMP']),
			data: {
				...get(values, 'data', {}),
				schvalovaciRezim
			},
			trvanie: dayjs().diff(dayjs(startTime), 'millisecond'),
			zacatyOd: dayjs(startTime).toISOString(),
			typ: get(ponuka, 'ukon.typ'),
			opCislo: get(interakcia, 'opCislo'),
			interakciaId: get(interakcia, 'id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			ziadanyOd: dayjs(ziadanyOd).toISOString(),
			dokumenty: undefined
		}

		forEach(supportedAttributes, (attribute) => {
			if (get(attribute, 'format')) {
				const formattedValue = attribute.format(this.props, get(values, get(attribute, 'cesta')))
				set(ukon, get(attribute, 'cesta'), formattedValue)
			}
		})

		if (ukon.data?.zmluvnyUcet) {
			const nesuhlasy = this.formDataForNesuhlasy(ukon.data?.zmluvnyUcet)

			if (!isEmpty(nesuhlasy)) {
				ukon = {
					...ukon,
					data: {
						...ukon.data,
						zmluvnyUcet: {
							...ukon.data.zmluvnyUcet,
							nesuhlasy
						}
					}
				}
			}
		}

		return ukon
	}

	setLoading = (value) => {
		this.setState({
			isLoading: value
		})
	}

	stepSubmitClickHandler = async (values) => {
		const { step, stepsTotalCount, attributesSteps } = this.state

		const remainingSteps = stepsTotalCount - step
		try {
			if (remainingSteps > 1) {
				const editMode = this.getEditMode(step)
				const stepAttributes = nth(attributesSteps, step - 1)
				if (editMode == EDIT_MODE.LOOKUP || get(head(stepAttributes), 'recalc')) {
					this.prepareDraftRecalc()
				} else {
					this.setState({
						step: step + 1
					})
				}
			} else if (remainingSteps == 1) {
				this.setLoading(true)

				await this.createUkon(values)

				this.setState(
					{
						step: step + 1
					},
					async () => {
						this.setLoading(false)
					}
				)
			} else {
				this.setLoading(true)

				const result = await this.submitUkon(values)

				this.setState(
					{
						result: get(result, 'message'),
						dependency: get(result, 'dependency'),
						success: get(result, 'success')
					},
					async () => {
						this.setLoading(false)
					}
				)
			}
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	processDocuments = async (values, ukonID) => {
		const { dispatch } = this.props
		const { ponuka } = this.state

		// NOTE: CP-2341
		const notUploadedDuplicates = []
		// documents which are not uploaded does not have ID
		const attrPrilohaDTO = find(get(ponuka, 'atributy', []), (attribute) => get(attribute, 'typ') == 'AttrPrilohaDTO')
		const attrPrilohyDTO = find(get(ponuka, 'atributy', []), (attribute) => get(attribute, 'typ') == 'AttrPrilohyDTO')
		const ziadostTypPath = get(attrPrilohaDTO, 'cesta') || (get(attrPrilohyDTO, 'cesta') ? `${get(attrPrilohyDTO, 'cesta')}.ziadostTyp` : null)
		const ziadostTyp = get(values, ziadostTypPath)
		const notUploadedDocuments = map(
			filter(get(values, 'dokumenty', []), (document) => !get(document, 'id')),
			(document) => {
				// NOTE: CP-2223 + CP-2274
				let dokumentTyp = DOKUMENT_TYP.VSTUPNY
				if (ziadostTyp == VSTUPNE_DOKUMENTY_ZIADOST_TYP.ZMLUVA) {
					notUploadedDuplicates.push({
						...document,
						typ: {
							id: dokumentTyp
						}
					})
					dokumentTyp = DOKUMENT_TYP.VYSTUPNY
				}
				return {
					...document,
					typ: {
						id: dokumentTyp
					}
				}
			}
		)
		const uploadedDocuments = filter(get(values, 'dokumenty', []), (document) => get(document, 'id'))

		const documentsForUpload = map([...notUploadedDocuments, ...notUploadedDuplicates], async (document) => {
			const documentResponse = await postReq(`/api/v0/ukony/${ukonID}/prilozit-dokument`, null, {
				contentType: get(document, 'type'),
				nazov: get(document, 'name'),
				data: get(document, 'dataAsBase64'),
				typ: get(document, 'typ')
			})
			return {
				type: get(documentResponse, 'response.contentType'),
				id: get(documentResponse, 'response.id'),
				dataAsBase64: get(document, 'dataAsBase64'),
				typ: get(documentResponse, 'response.typ'),
				name: get(documentResponse, 'response.nazov')
			}
		})

		const documentsPromises = await Promise.all(documentsForUpload)

		// NOTE: CP-2378
		if (attrPrilohyDTO) {
			const existujucePrilohy = get(values, `${attrPrilohyDTO.cesta}.dokumenty`, [])
			const novePrilohy = map(
				filter(documentsPromises, (document) => get(document, 'typ.id') == DOKUMENT_TYP.VYSTUPNY),
				'id'
			)
			const prilohy = [...existujucePrilohy, ...novePrilohy]
			dispatch(change(FORMS.GENERIC_UKON, 'dokumenty', [...uploadedDocuments, ...documentsPromises]))
			dispatch(change(FORMS.GENERIC_UKON, `${attrPrilohyDTO.cesta}.dokumenty`, prilohy))
			return [
				{
					key: `${attrPrilohyDTO.cesta}.dokumenty`,
					value: prilohy
				}
			]
		}
		// merge already uploaded documents and fresh uploaded documents
		dispatch(change(FORMS.GENERIC_UKON, 'dokumenty', [...uploadedDocuments, ...documentsPromises]))
	}

	createUkon = async (values) => {
		const { dispatch, interakcia, ukonyActions, ukonNovy } = this.props

		let ukonID
		// if ukon not exist yet create a new one else update it
		if (!get(ukonNovy, 'id')) {
			const createUkonBody = this.formatDataForUkon(values)

			const res = await postReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ukony`, null, createUkonBody)

			// set podpis Miesto from response CP-588
			const podpisMiesto = get(res, 'response.content.podpisMiesto')
			dispatch(change(FORMS.GENERIC_UKON, 'podpisMiesto', podpisMiesto))

			ukonID = get(res, 'response.content.id')
			ukonyActions.setUkonId(ukonID)

			const prilohy = await this.processDocuments(values, ukonID)

			const updateUkonBody = this.formatDataForUkon(values)

			// NOTE: CP-2378
			if (prilohy) {
				forEach(prilohy, (priloha) => {
					set(updateUkonBody, priloha.key, priloha.value)
				})
			}

			if (!isEqual(createUkonBody, updateUkonBody)) {
				await putReq(`/api/v2/ukony/${ukonID}`, null, {
					...updateUkonBody,
					id: ukonID
				})
			}
		} else {
			ukonID = get(ukonNovy, 'id')

			const prilohy = await this.processDocuments(values, ukonID)

			const body = this.formatDataForUkon(values)

			// NOTE: CP-2378
			if (prilohy) {
				forEach(prilohy, (priloha) => {
					set(body, priloha.key, priloha.value)
				})
			}

			await putReq(`/api/v2/ukony/${ukonID}`, null, {
				...body,
				id: ukonID
			})
		}
	}

	submitUkon = async (values) => {
		const { auth, ukonNovy, signedPdf, notification, templatePdf, t, dataActions } = this.props

		let notifikacie = {
			adresyPosta: [],
			adresyUri: []
		}
		if (get(notification, 'typ') == NOTIFICATION_TYPES.ADDRESS) {
			notifikacie.adresyPosta.push(get(notification, 'address'))
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.EMAIL) {
			notifikacie.adresyUri.push({
				typ: NOTIFICATION_TYPES.EMAIL,
				hodnota: get(notification, 'email')
			})
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.PRINTER) {
			notifikacie.adresyUri.push({
				typ: NOTIFICATION_TYPES.PRINTER,
				poradie: 0
			})
		}

		try {
			const documentForUpload = get(signedPdf, 'data.dataAsBase64') ? get(signedPdf, 'data') : get(templatePdf, 'data')

			if (documentForUpload) {
				await postReq(`/api/v0/ukony/${get(ukonNovy, 'id')}/prilozit-dokument`, null, {
					contentType: get(documentForUpload, 'type'),
					typ: {
						id: DOKUMENT_TYP.VYSTUPNY
					},
					nazov: get(documentForUpload, 'name'),
					data: get(documentForUpload, 'dataAsBase64')
				})
			}

			const body = this.formatDataForUkon(values)
			const res = await postReq(`/api/v2/ukony/${get(ukonNovy, 'id')}/spustit`, null, {
				...body,
				notifikacie,
				id: get(ukonNovy, 'id')
			})

			if (this._mounted) {
				dataActions.unregisterLeavePageModal()

				const dependency = find(get(res, 'response.content.ukonDependencies', []), (dependency) => {
					return get(dependency, 'ukonStav.id') == UKON_STAV.ODLOZENY && get(dependency, 'typ.id') == UKONY_CISELNIK.PODPIS_SEPA_MANDATU
				})

				let message = t('translation:Common.Úkon bol úspešne odoslaný')
				if (dependency) {
					if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM) {
						message = 'Úkon bol úspešne odoslaný, môžete pokračovať podpisom alebo nahratím SEPA mandátu'
					} else if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
						message = 'Úkon bol úspešne odoslaný, môžete pokračovať nahratím alebo zaslaním SEPA mandátu'
					}
				}

				return {
					message,
					success: true,
					dependency: get(dependency, 'id')
				}
			}
		} catch (e) {
			dataActions.unregisterLeavePageModal()
			return {
				message: t('translation:Common.Počas odosielania úkonu nastala chyba'),
				success: false
			}
		}
	}

	confirmModal = (force) => {
		const { interakcia, ukonyActions, miestaSpotrebyActions, zmluvneVztahyActions, selectedFiltersUkony } = this.props
		const { success, backUrlLink } = this.state

		if (success || force) {
			this.setState(
				{
					success: false
				},
				() => {
					// refetch new data for OP
					miestaSpotrebyActions.clearMiestaSpotreby()
					zmluvneVztahyActions.clearZmluvneVztahy()

					// clear ukonId from storage
					ukonyActions.clearUkonId()

					// refetch new data for historia ukonov
					ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)

					history.replace(backUrlLink || setRouteParams(ZMLUVY_ZOZNAM, get(interakcia, 'opCislo')))
				}
			)
		} else {
			this.setState({
				result: null
			})
		}
	}

	continueSEPASigning = () => {
		const { selectedFiltersUkony } = this.props
		const { interakcia, ukonyActions, obchodnyPartnerActions } = this.props
		const { dependency } = this.state

		this.setState(
			{
				success: false
			},
			() => {
				// refetch new data for OP
				obchodnyPartnerActions.loadZmluvneUcty(true)

				// clear ukonID from storage
				ukonyActions.clearUkonId()

				// refetch new data for historia ukonov
				ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)
				history.replace(setRouteParams(UKON_DETAIL, get(interakcia, 'opCislo'), dependency))
			}
		)
	}

	koKriteriaHandler = (isKoKriteriumChecked, koKriteriaNew) => {
		const { koKriteria } = this.state

		if (!isEqual(koKriteria, koKriteriaNew)) {
			this.setState({
				koKriteria: koKriteriaNew
			})
		}
	}

	validacneKriteriaHandler = (validacneKriteriaNew) => {
		const { validacneKriteria } = this.state

		if (!isEqual(validacneKriteria, validacneKriteriaNew)) {
			this.setState({
				validacneKriteria: validacneKriteriaNew
			})
		}
	}

	schvalovacieKriteriaHandler = (schvalovacieKriteriaNew) => {
		const { schvalovacieKriteria } = this.state

		if (!isEqual(schvalovacieKriteria, schvalovacieKriteriaNew)) {
			this.setState({
				schvalovacieKriteria: schvalovacieKriteriaNew
			})
		}
	}

	disallowScenarios = (scenarios) =>
		this.setState({
			disallowedScenarios: scenarios
		})

	onBackClick = () => {
		const { interakcia } = this.props
		const { step, backUrlLink } = this.state

		if (step == 1) {
			history.push(backUrlLink || setRouteParams(ZMLUVY_ZOZNAM, get(interakcia, 'opCislo')))
		} else {
			this.setState({
				step: step - 1
			})
		}
	}

	onCancelClick = () => {
		const { interakcia } = this.props
		const { backUrlLink } = this.state

		history.push(backUrlLink || setRouteParams(ZMLUVY_ZOZNAM, get(interakcia, 'opCislo')))
	}

	getEditMode = (step) => {
		const { stepsTotalCount } = this.state

		const delta = stepsTotalCount - step

		if (delta >= 2) {
			return EDIT_MODE.LOOKUP
		}
		if (delta == 1) {
			return EDIT_MODE.EDIT
		}
		return EDIT_MODE.CONFIRM
	}

	// NOTE: CP-2223
	isDocumentRequired = (scenarioOption, originalValues, formValues) => {
		const { ponuka } = this.state
		const attrPrilohaDTO = find(get(ponuka, 'atributy', []), (attribute) => get(attribute, 'typ') == 'AttrPrilohaDTO')
		const attrPrilohyDTO = find(get(ponuka, 'atributy', []), (attribute) => get(attribute, 'typ') == 'AttrPrilohyDTO')
		const ziadostTypPath = get(attrPrilohaDTO, 'cesta') || (get(attrPrilohyDTO, 'cesta') ? `${get(attrPrilohyDTO, 'cesta')}.ziadostTyp` : null)
		const ziadostTyp = get(formValues, ziadostTypPath)
		if (ziadostTyp == VSTUPNE_DOKUMENTY_ZIADOST_TYP.ZMLUVA) {
			return size(get(formValues, 'dokumenty', [])) == get(originalValues, 'dokumenty', []) || isEmpty(get(formValues, 'dokumenty', []))
		}
		return get(scenarioOption, 'documentRequired', true)
	}

	// NOTE: CP-2223
	isNotificationRequired = (scenarioOption, ponuka) => {
		if (
			get(ponuka, 'ukon.typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_NOVY_ODBER ||
			get(ponuka, 'ukon.typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVY_GAS_NOVY_ODBER ||
			get(ponuka, 'ukon.typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS ||
			get(ponuka, 'ukon.typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS_GAS ||
			get(ponuka, 'ukon.typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_OBNOVA_MS ||
			get(ponuka, 'ukon.typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVY_GAS_OBNOVA_MS ||
			get(ponuka, 'ukon.typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_VAS ||
			get(ponuka, 'ukon.typ.id') === UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_DODATOK
		) {
			return false
		}
		return get(scenarioOption, 'notificationRequired', true)
	}

	render = () => {
		const { auth, signedPdf, notification, procesnyKonfigurator, formValues, t, context } = this.props
		const {
			ponuka,
			step,
			stepsTotalCount,
			supportedAttributes,
			unsupportedAttributes,
			attributesSteps,
			isLoading,
			koKriteria,
			validacneKriteria,
			schvalovacieKriteria,
			success,
			result,
			scenarioOption,
			dissallowedScenarios,
			showMissingPonukaModal,
			showMissingPermissionsModal,
			originalValues,
			dependency,
			ProcesnyKonfigurator
		} = this.state

		const editMode = this.getEditMode(step)
		if (!this._mounted || isLoading || get(procesnyKonfigurator, 'isLoading') || get(context, 'isLoading')) {
			return <ElementLoading />
		}

		if (editMode == EDIT_MODE.EDIT) {
			if (get(procesnyKonfigurator, 'isFailure') || isEmpty(get(procesnyKonfigurator, 'data.ukony')) || get(context, 'isFailure')) {
				return <ElementEmptyContent text={t('translation:ProcesnyKonfigurator.Ľutujeme ale nepodarilo sa načítať Procesný konfigurátor')} />
			}
		}

		let modal = null
		if (showMissingPonukaModal) {
			modal = (
				<DefaultModal
					modalTitle={t('translation:Common.Chyba')}
					modalContent={t('translation:Common.Prosím zvoľte si z ponuky na prechádzajúcom kroku')}
					leftButton={{
						onClick: () => this.confirmModal(true),
						text: t('translation:Common.Zavrieť'),
						color: 'red'
					}}
					visible
				/>
			)
		} else if (showMissingPermissionsModal) {
			modal = (
				<DefaultModal
					modalTitle={t('translation:Common.Chyba')}
					modalContent={t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}
					leftButton={{
						onClick: () => this.confirmModal(true),
						text: t('translation:Common.Zavrieť'),
						color: 'red'
					}}
					visible
				/>
			)
		} else if (dependency && success) {
			modal = (
				<DefaultModal
					modalTitle={t('translation:Common.Odoslané')}
					modalContent={result}
					leftButton={{
						onClick: this.confirmModal,
						text: t('translation:Common.Zavrieť'),
						color: 'green',
						outline: 'outline'
					}}
					rightButton={{
						onClick: this.continueSEPASigning,
						text: t('translation:Common.Pokračovať'),
						color: 'green'
					}}
					visible
				/>
			)
		} else if (result != null) {
			modal = (
				<DefaultModal
					modalTitle={success ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
					modalContent={result}
					leftButton={{
						onClick: this.confirmModal,
						text: t('translation:Common.Zavrieť'),
						color: success ? 'green' : 'red'
					}}
					visible
				/>
			)
		}

		let confirm = null
		if (step == stepsTotalCount) {
			const attrPrilohaDTO = find(get(ponuka, 'atributy', []), (attribute) => get(attribute, 'typ') == 'AttrPrilohaDTO')
			const attrPrilohyDTO = find(get(ponuka, 'atributy', []), (attribute) => get(attribute, 'typ') == 'AttrPrilohyDTO')
			const ziadostTypPath = get(attrPrilohaDTO, 'cesta') || (get(attrPrilohyDTO, 'cesta') ? `${get(attrPrilohyDTO, 'cesta')}.ziadostTyp` : null)
			const ziadostTyp = get(formValues, ziadostTypPath)

			// check if scenar is define
			const customizedScenarioOption = {
				...scenarioOption,
				documentRequired: this.isDocumentRequired(scenarioOption, originalValues, formValues),

				// NOTE: CP-2223
				onlyShowDocuments:
					ziadostTyp == VSTUPNE_DOKUMENTY_ZIADOST_TYP.ZMLUVA &&
					filter(get(formValues, 'dokumenty', []), (document) => {
						return get(document, 'typ.id') == DOKUMENT_TYP.VYSTUPNY
					}),
				notificationRequired: this.isNotificationRequired(scenarioOption, ponuka),

				// NOTE: CP-2313
				scenarios:
					get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM && get(formValues, 'data.ukoncenieDovod') == UKONCENIE_DOVOD.UMRTIE
						? [
								{
									typ: SCENARE.ODOSLAT_BEZ_PODPISU
								}
						  ]
						: filter(get(scenarioOption, 'scenarios', []), (scenario) => {
								let allowedRoles = get(scenario, 'allowedRoles', [])
								return (
									!includes(dissallowedScenarios, get(scenario, 'typ')) &&
									(isEmpty(get(scenario, 'allowedRoles', [])) || checkPermissions(get(auth, 'user.roles'), allowedRoles))
								)
						  })
			}

			// if notification is required and not selected yet
			let isDisabledNotification = get(customizedScenarioOption, 'notificationRequired') && !get(notification, 'typ')

			// for scenarios VYTLACIT_A_NAHRAT and PODPISANIE_NA_MIESTE is required signedPDF
			let isDisabledSignedPdf = true
			// NOTE: CP-2223
			if (ziadostTyp == VSTUPNE_DOKUMENTY_ZIADOST_TYP.ZMLUVA && !isEmpty(get(formValues, 'dokumenty', []))) {
				isDisabledSignedPdf = false
			} else if (
				(find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.VYTLACIT_A_NAHRAT }) ||
					find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.PODPISANIE_NA_MIESTE })) &&
				get(signedPdf, 'data.dataAsBase64')
			) {
				isDisabledSignedPdf = false
			} else if (
				find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.ODOSLAT_BEZ_PODPISU }) ||
				find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.ODOSLAT_NA_PODPIS })
			) {
				isDisabledSignedPdf = false
			}

			confirm = {
				scenarioOption: customizedScenarioOption,
				disallowScenarios: this.disallowScenarios,
				isDisabledNotification,
				isDisabledSignedPdf
			}
		}

		const stepAttributes = editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CONFIRM ? supportedAttributes : [nth(attributesSteps, step - 1)]

		const formTitle =
			editMode == EDIT_MODE.LOOKUP
				? t('translation:Common.{ukonNazov}', {
						ukonNazov: `${get(ponuka, 'nazov') || '-'} - ${get(head(stepAttributes), 'label') || '-'}`
				  })
				: t('translation:Common.Krok {step} z {stepsTotalCount} {ukonNazov}', {
						step,
						stepsTotalCount,
						ukonNazov: get(ponuka, 'nazov') || '-'
				  })

		const methodologicalGuidelineLink = recognizeMethodologicalGuideline(ponuka)

		let component = null
		if (!modal) {
			component = (
				<GenericUkonStep
					{...this.props}
					ponuka={ponuka}
					attributes={stepAttributes}
					unsupportedAttributes={unsupportedAttributes}
					formValues={formValues}
					originalValues={originalValues}
					editMode={editMode}
					formTitle={formTitle}
					methodologicalGuidelineLink={methodologicalGuidelineLink}
					onSubmit={this.stepSubmitClickHandler}
					onBackClick={this.onBackClick}
					onCancelClick={this.onCancelClick}
					koKriteria={koKriteria}
					schvalovacieKriteria={schvalovacieKriteria}
					validacneKriteria={validacneKriteria}
					confirm={confirm}
				/>
			)
		}

		return (
			<>
				{modal}
				{component}
				{this._mounted && editMode == EDIT_MODE.EDIT && !isEmpty(get(context, 'data')) && ProcesnyKonfigurator && (
					<ProcesnyKonfigurator
						koKriteriaHandler={this.koKriteriaHandler}
						validacneKriteriaHandler={this.validacneKriteriaHandler}
						schvalovacieKriteriaHandler={this.schvalovacieKriteriaHandler}
						formatDataForUkon={this.formatDataForUkon}
						context={get(context, 'data') || {}}
					/>
				)}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth'),
	tracking: get(state, 'tracking'),
	ukonNovy: get(state, 'ukony.ukonNovy'),
	ciselniky: get(state, 'ciselniky.data'),
	signedPdf: get(state, 'podpisovanieDokumentov.signedPdf'),
	notification: get(state, 'podpisovanieDokumentov.notification'),
	templatePdf: get(state, 'podpisovanieDokumentov.templatePdf'),
	procesnyKonfigurator: get(state, 'procesnyKonfigurator.procesnyKonfigurator'),
	addresses: get(state, 'formAddresses.data'),
	statuses: get(state, 'statuses.statuses'),
	context: get(state, 'pkUkonGenericContext.context'),
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data'),
	formValues: getFormValues(FORMS.GENERIC_UKON)(state),
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch),
	statusActions: bindActionCreators(StatusActions, dispatch),
	miestaSpotrebyActions: bindActionCreators(MiestaSpotrebyActions, dispatch),
	zmluvneVztahyActions: bindActionCreators(ZmluvneVztahyActions, dispatch),
	odberneMiestaActions: bindActionCreators(OdberneMiestaActions, dispatch),
	ukonGenericActions: bindActionCreators(UkonGenericActions, dispatch),
	formAddressesActions: bindActionCreators(FormAddresssesActions, dispatch),
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(withTranslation('containers'), connect(mapStateToProps, mapDispatchToProps))(GenericUkonPage)
