import { isEmpty } from 'lodash'

const validate = (values, props) => {
	const errors = {}

	if (isEmpty(values.selectedFaktury)) {
		errors.selectedFaktury = props.t('translation:PlanySplatok.validate.Zahrnuté faktúry sú povinné')
	}

	return errors
}

export default validate
