import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { reduxForm, propTypes, getFormValues } from 'redux-form'
import PropTypes from 'prop-types'
import { get, find, upperFirst } from 'lodash'
import { Tooltip } from 'react-tippy'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import FormInfo from '../../FormInfo'

// utils
import { formatDate } from '../../../utils/date'
import { formatFormValue } from '../../../utils/form'
import { VZTAHY_ZOZNAM, setRouteParams } from '../../../utils/routes'
import { FORMS } from '../../../utils/enums'

import WrapperPodpisovanieDokumentov from '../../PodpisovanieDokumentov/WrapperPodpisovanieDokumentov'

class SplnomocneniaConfirm extends React.Component {
	static propTypes = {
		...propTypes,
		submitHandler: PropTypes.func.isRequired,
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.number.isRequired,
				opCislo: PropTypes.string.isRequired
			}).isRequired,
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		t: PropTypes.func
	}

	render() {
		const {
			handleSubmit,
			formValues,
			interakcia,
			originalValues,
			ciselniky,
			t,
			scenarioOption,
			isDisabledNotification,
			isDisabledSignedPdf,
			isDisabledScenarNotSelected,
			podpisovanieDokumentov,
			formTitle,
			onBackClick,
			schvalovacieKriteria,
			disallowScenarios
		} = this.props

		const ukonVstup = find(get(ciselniky, 'ukonVstup', []), (item) => item.id == formValues.ukonVstup)

		const disabledBtn = (
			<button className='button' type='submit' disabled data-color='blue' style={{ marginLeft: '20px' }}>
				{t('components:SplnomocneniaConfirm.Dokončiť')}
			</button>
		)

		let submitBtn = null
		if (get(podpisovanieDokumentov, 'templatePdf.isLoading')) {
			submitBtn = (
				<Tooltip
					html={<span>{t('components:SplnomocneniaConfirm.Prebieha načítavanie dokumentu pre podpisovanie')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (isDisabledScenarNotSelected) {
			submitBtn = (
				<Tooltip
					html={<span>{t('components:SplnomocneniaConfirm.Pre pokračovanie zvoľte scenár')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (isDisabledSignedPdf) {
			submitBtn = (
				<Tooltip
					html={<span>{t('components:SplnomocneniaConfirm.Dokument musí byť podpísaný')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledSignedPdf && isDisabledNotification) {
			submitBtn = (
				<Tooltip
					html={<span>{t('components:SplnomocneniaConfirm.Na dokončenie úkonu je potrebné nastaviť notifikáciu')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledNotification && !isDisabledSignedPdf) {
			submitBtn = (
				<button className='button pull-right' type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
					{t('components:SplnomocneniaConfirm.Dokončiť')}
				</button>
			)
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{submitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('components:SplnomocneniaConfirm.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<Link to={setRouteParams(VZTAHY_ZOZNAM, interakcia.data.opCislo)} className='button pull-right' data-type='outline' data-color='red'>
						{t('components:SplnomocneniaConfirm.Zrušiť')}
					</Link>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<FormInfo schvalovacieKriteria={schvalovacieKriteria} />
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('components:SplnomocneniaConfirm.Aktuálne hodnoty')}</th>
										<th>{t('components:SplnomocneniaConfirm.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('components:SplnomocneniaConfirm.Vstup do úkonu')}</strong>
										</td>
										<td />
										<td>{upperFirst(get(ukonVstup, 'nazov', ''))}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:EditContactConfirm.Miesto podpisu úkonu')}</strong>
										</td>
										<td>{get(originalValues, 'podpisMiesto')}</td>
										<td>{formatFormValue(get(formValues, 'podpisMiesto'), get(originalValues, 'podpisMiesto'))}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:SplnomocneniaConfirm.Dátum prijatia žiadosti')}</strong>
										</td>
										<td />
										<td>{formatDate(get(formValues, 'datumPrijatiaZiadosti'), null, 'DD.MM.YYYY')}</td>
									</tr>
								</tbody>
							</table>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('components:SplnomocneniaConfirm.Číslo obchodného partnera')}</strong>
											</td>
											<td>{get(originalValues, 'splnomocnenecCislo')}</td>
											<td>
												{get(formValues, 'splnomocnenecCislo') === -1 ? (
													<strong>{t('components:SplnomocneniaConfirm.Nový OP')}</strong>
												) : (
													formatFormValue(get(formValues, 'splnomocnenecCislo'), get(originalValues, 'splnomocnenecCislo'))
												)}
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('components:SplnomocneniaConfirm.Splnomocnená osoba')}</strong>
											</td>
											<td>{get(originalValues, 'splnomocnenaOsoba')}</td>
											<td>{formatFormValue(get(formValues, 'splnomocnenaOsoba'), get(originalValues, 'splnomocnenaOsoba'))}</td>
										</tr>
										{/* <tr>
										<td>
											<strong>{t('components:SplnomocneniaInfo.Úkon realizuje splnomocnenec')}</strong>
										</td>
										<td></td>
										<td>
											{get(formValues, 'ukonRealizujeSplnomocnenaOsoba') ? t('translation:Common.Áno') : t('translation:Common.Nie')}
										</td>
									</tr> */}
										<tr>
											<td>
												<strong>{t('components:SplnomocneniaConfirm.Platnosť od')}</strong>
											</td>
											<td>{formatDate(get(originalValues, 'platnostOd'), '', 'DD.MM.YYYY')}</td>
											<td>{formatDate(get(originalValues, 'platnostOd'), '', 'DD.MM.YYYY')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('components:SplnomocneniaConfirm.Platnosť do')}</strong>
											</td>
											<td>{formatDate(get(originalValues, 'platnostDo'), '', 'DD.MM.YYYY')}</td>
											<td>
												{formatFormValue(
													formatDate(get(formValues, 'platnostDo'), '', 'DD.MM.YYYY'),
													formatDate(get(originalValues, 'platnostDo'), '', 'DD.MM.YYYY')
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('components:SplnomocneniaConfirm.Poznámka')}</strong>
										</td>
										<td />
										<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
											<div className='input-wrapper'>
												<textarea
													disabled='disabled'
													rows='5'
													className='form-control static'
													style={{ backgroundColor: 'white' }}
													value={get(formValues, 'poznamka')}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>

							<WrapperPodpisovanieDokumentov scenarioOption={scenarioOption} disallowScenarios={disallowScenarios} />
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.SPLNOMOCNENIA,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true
})(SplnomocneniaConfirm)

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	ciselniky: state.ciselniky.data,
	podpisovanieDokumentov: state.podpisovanieDokumentov,
	formValues: getFormValues(FORMS.SPLNOMOCNENIA)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
