import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

function UkonVseobecnyPasivny(props) {
	const { t, ukonData, dokumenty, formatDocuments } = props
	return (
		<tbody>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Číslo miesta spotreby')}</strong>
				</td>
				<td>{get(ukonData, 'miestoSpotreby.cislo', '-')}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Typ všeobecného pasívneho úkonu')}</strong>
				</td>
				<td>{get(ukonData, 'ukonKategoria1.nazov')}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Kategória 1')}</strong>
				</td>
				<td>{get(ukonData, 'ukonKategoria2.nazov')}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Kategória 2')}</strong>
				</td>
				<td>{get(ukonData, 'ukonKategoria3.nazov')}</td>
			</tr>
			<tr>
				<td colSpan={2}>{formatDocuments(dokumenty)}</td>
			</tr>
		</tbody>
	)
}

UkonVseobecnyPasivny.propTypes = {
	ukon: PropTypes.shape(),
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	dokumenty: PropTypes.array,
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonVseobecnyPasivny
