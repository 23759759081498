import dayjs from 'dayjs'
import { get, isEmpty, some } from 'lodash'

import { BUSINESS_CHANNELS } from '../../utils/enums'
import { formatHTML } from '../../utils/systemoveListy'

const validate = (values, props) => {
	const errors = {}

	if (!values.ukonVstup) {
		errors.ukonVstup = props.t('translation:Common.validate.Vstup do úkonu je povinný')
	}

	if (values.podpisMiesto) {
		if (values.podpisMiesto.length > 100) {
			errors.podpisMiesto = props.t('translation:Common.validate.Miesto podpisu úkonu môže mať najviac 100 znakov')
		}
	}

	if (get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && !values.datumPrijatiaZiadosti) {
		errors.datumPrijatiaZiadosti = props.t('translation:Common.validate.Dátum prijatia žiadosti je povinný')
	}

	if (values.datumPrijatiaZiadosti) {
		if (
			get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM ||
			get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA
		) {
			if (values.datumPrijatiaZiadosti < dayjs().startOf('day') || values.datumPrijatiaZiadosti > dayjs().endOf('day')) {
				errors.datumPrijatiaZiadosti = props.t('translation:Common.validate.Dátum prijatia žiadosti nie je platný - zvoľte prosím aktuálny deň')
			}
		} else if (get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			if (values.datumPrijatiaZiadosti > dayjs().endOf('day')) {
				errors.datumPrijatiaZiadosti = props.t('translation:Common.validate.Dátum prijatia žiadosti nie je platný - nemôžete zvoliť dátum v budúcnosti')
			}
		}
	}

	if (!values.kategoria1) {
		errors.kategoria1 = props.t('translation:SystemoveListyUkon.validate.Kategória je povinné pole')
	}

	if (values.step == 2) {
		if (values.znacka && values.znacka.length > 20) {
			errors.znacka = props.t('translation:SystemoveListyUkon.validate.Značka môže mať najviac 20 znakov')
		}
		if (isEmpty(formatHTML(values.odpovedText))) {
			errors.odpovedText = props.t('translation:SystemoveListyUkon.validate.Text odpovede je povinný')
		}
	}

	if (!isEmpty(values.dokumenty)) {
		some(values.dokumenty, (dokument) => {
			if (!dokument.dataAsBase64) {
				errors.dokumenty = props.t('translation:Common.validate.Neplatný dokument', { nazovDokumentu: dokument.name })
				return true
			}
		})
	}

	if (values.poznamka && values.poznamka.length > 2000) {
		errors.poznamka = props.t('translation:Common.validate.Maximálna dĺžka poznámky je 2000 znakov')
	}

	return errors
}

export default validate
