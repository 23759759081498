import React from 'react'
import PropTypes from 'prop-types'
import { map, get, upperFirst, filter } from 'lodash'
import { formatAddress } from '../../utils/address'
import { formatDate } from '../../utils/date'
import { OBCHODNY_PARTNER_TYP } from '../../utils/enums'

function SearchResultInteractionTableRow(props) {
	const { interakcia } = props

	const timeRange = filter(
		[formatDate(get(interakcia, 'vytvorenaOd'), '-', 'HH:mm:ss'), formatDate(get(interakcia, 'ukoncenaOd'), null, 'HH:mm:ss')],
		(item) => !!item
	).join(' - ')

	const ukonyCounter = {}
	get(interakcia, 'ukony', []).forEach((ukon) => {
		const id = get(ukon, 'typ.id')
		if (ukonyCounter[id]) {
			ukonyCounter[id].count++
		} else {
			ukonyCounter[id] = {
				...ukon,
				count: 1
			}
		}
	})
	const ukony = map(ukonyCounter, (ukon) => {
		return upperFirst(get(ukon, 'typ.nazov')) + (ukon.count > 1 ? ` (${ukon.count}x)` : '')
	}).join(', ')
	const ukonyShortString = ukony.length > 35 ? `${ukony.substring(0, 35)}...` : ukony
	return (
		<tr key={interakcia.id} onClick={props.onClick}>
			{get(interakcia, 'op.typ') === OBCHODNY_PARTNER_TYP.MOO && (
				<td>{[get(interakcia, 'op.meno', ''), get(interakcia, 'op.priezvisko', '')].join(' ').trim() || '-'}</td>
			)}
			{get(interakcia, 'op.typ') === OBCHODNY_PARTNER_TYP.MOP && <td>{get(interakcia, 'op.obchodneMeno') || '-'}</td>}
			<td>{timeRange}</td>
			<td>{formatAddress(get(interakcia, 'op.adresaZakaznika')) || '-'}</td>
			<td title={ukony}>{ukonyShortString || '-'}</td>
		</tr>
	)
}

SearchResultInteractionTableRow.propTypes = {
	interakcia: PropTypes.shape({
		id: PropTypes.number
	}),
	onClick: PropTypes.func
}

export default SearchResultInteractionTableRow
