import React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import onClickOutside from 'react-onclickoutside'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { get, isEmpty, map, indexOf } from 'lodash'
import { Tooltip } from 'react-tippy'
import { checkPermissions, PERMISSIONS } from '../../utils/permissionsHoc'

// actions
import * as PonukaActions from '../../actions/PonukaActions'

// utils
import { setRouteParams, GENERIC_UKON } from '../../utils/routes'
import { PONUKA_MODE, UKONY_CISELNIK } from '../../utils/enums'
/* import DefaultModal from '../Modals/DefaultModal' */

class PonukaDropdownMenu extends React.Component {
	static propTypes = {
		auth: PropTypes.shape().isRequired,
		miestoSpotreby: PropTypes.shape(),
		ponukaMode: PropTypes.string.isRequired,
		msCislo: PropTypes.string,
		zvCislo: PropTypes.string,
		ponuka: PropTypes.shape(),
		ponukaActions: PropTypes.shape(),
		interakcia: PropTypes.shape({
			id: PropTypes.number.isRequired,
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		t: PropTypes.func.isRequired
	}

	state = {
		visible: false /* ,
		konflikty: [] */
	}

	toggle = () => {
		const { auth, msCislo, zvCislo, ponukaMode, ponuka, ponukaActions } = this.props
		const { visible } = this.state

		const isLoading = get(ponuka, 'isLoading') || false

		if (!visible && !isLoading) {
			if (ponukaMode == PONUKA_MODE.MIESTO_SPOTREBY && msCislo) {
				ponukaActions.loadPonukaMiestoSpotreby(msCislo, {
					kanalId: get(auth, 'businessChannel.actual.id')
				})
			} else if (ponukaMode == PONUKA_MODE.ZMLUVNY_VZTAH && zvCislo) {
				ponukaActions.loadPonukaZmluvnyVztah(zvCislo, {
					kanalId: get(auth, 'businessChannel.actual.id')
				})
			} else if (ponukaMode == PONUKA_MODE.OBCHODNY_PARTNER) {
				ponukaActions.loadPonukaObchodnyPartner({
					kanalId: get(auth, 'businessChannel.actual.id')
				})
			}
		}

		this.setState({
			visible: !visible
		})
	}

	handleClickOutside(element) {
		const target = get(element, 'target')
		if (!target || indexOf(get(target, 'classList', []), 'dropdown-ponuka-list') < 0) {
			this.setState({
				visible: false
			})
		}
	}

	renderTopPaddingByType = () => {
		const { ponukaMode } = this.props

		if (ponukaMode == PONUKA_MODE.MIESTO_SPOTREBY || ponukaMode == PONUKA_MODE.ZMLUVNY_VZTAH) {
			return 'calc(100% + 42px)'
		}
		return 'calc(100% + 55px)'
	}

	formatPonukaItem = (ponuka) => {
		const { interakcia, auth } = this.props

		const ukonTyp = get(ponuka, 'ukon.typ.id')

		if (ukonTyp === UKONY_CISELNIK.ZMLUVNY_VZTAH_ZMENA_PRODUKTU_EE || ukonTyp === UKONY_CISELNIK.ZMLUVNY_VZTAH_ZMENA_PRODUKTU_GAS) {
			if (!checkPermissions(get(auth, 'user.roles', []), [PERMISSIONS.UKON_ZMENA_PRODUKTU])) {
				return
			}
		}

		if (ukonTyp === UKONY_CISELNIK.ZMLUVNY_VZTAH_UKONCENIE_EE_PREPIS || ukonTyp === UKONY_CISELNIK.ZMLUVNY_VZTAH_UKONCENIE_GAS_PREPIS) {
			if (!checkPermissions(get(auth, 'user.roles', []), [PERMISSIONS.UKON_PREPIS_IDEALNY])) {
				return
			}
		}

		if (ukonTyp === UKONY_CISELNIK.UKONCENIE_ZMLUVY_EE || ukonTyp === UKONY_CISELNIK.UKONCENIE_ZMLUVY_GAS) {
			if (!checkPermissions(get(auth, 'user.roles', []), [PERMISSIONS.UKON_UKONCENIE_ZMLUVY])) {
				return
			}
		}

		return (
			<Link
				to={{
					pathname: setRouteParams(GENERIC_UKON, get(interakcia, 'opCislo')),
					ponuka
				}}
			>
				{get(ponuka, 'nazov')}
			</Link>
		)
	}

	render() {
		const { ponuka, ponukaMode, t } = this.props
		const {
			visible /* ,
			konflikty */
		} = this.state

		const isLoaded = get(ponuka, 'isLoaded') || false
		const isLoading = get(ponuka, 'isLoading') || false
		const isFailure = get(ponuka, 'isFailure') || isEmpty(get(ponuka, 'data')) || false

		let content
		if (isLoading || !isLoaded) {
			content = <div className='custom-empty-state'>{t('translation:ZmluvneUcty.Načítavam ponuku')}</div>
		} else if (isFailure) {
			content = <div className='custom-empty-state'>{t('translation:ZmluvneUcty.Prázdna ponuka')}</div>
		} else {
			content = (
				<ul className='dropdown-ponuka-list' style={{ maxHeight: '300px', overflowY: 'auto', width: '358px' }}>
					{map(get(ponuka, 'data', []), (ponuka, index) => {
						return <li key={`ponuka-item-${index}`}>{this.formatPonukaItem(ponuka)}</li>
					})}
				</ul>
			)
		}

		let activeButton
		if (ponukaMode == PONUKA_MODE.MIESTO_SPOTREBY) {
			activeButton = <div className='box-header-settings' onClick={this.toggle} />
		} else if (ponukaMode == PONUKA_MODE.ZMLUVNY_VZTAH) {
			activeButton = <button onClick={this.toggle} style={{ marginLeft: '10px' }} className='button' data-type='icon-button' data-icon='edit' />
		} else if (ponukaMode == PONUKA_MODE.OBCHODNY_PARTNER) {
			activeButton = (
				<button onClick={this.toggle} style={{ marginLeft: '10px' }} className='button' data-color='blue'>
					{t('translation:ZmluvneUcty.Nová zmluva')}
				</button>
			)
		}

		return (
			<>
				<Tooltip html={t('translation:ZmluvneUcty.Ponuka')} className='pull-right' position='left' trigger='mouseenter' theme='light'>
					{activeButton}
				</Tooltip>
				<div style={{ position: 'relative', textAlign: 'left' }}>
					<div className='box-header-dropdown'>
						<div className={cx('dropdown', { shown: visible })} style={{ top: this.renderTopPaddingByType(), right: '-3px', width: '360px' }}>
							{content}
						</div>
					</div>
				</div>
				{/* {!isEmpty(konflikty) && <DefaultModal
					modalTitle={t('translation:Common.Nieje možné spustiť úkon z nasledovných dôvodov')}
					modalContent={<ul>{map(konflikty, (dovod) => <li>{dovod}</li>)}</ul>}
					leftButton={{
						onClick: () => this.setState({
							konflikty: []
						}),
						text: t('translation:Common.Zavrieť'),
						color: 'red'
					}}
					visible
				/>} */}
			</>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const msCislo = get(ownProps, 'miestoSpotreby.cislo')
	const zvCislo = get(ownProps, 'zmluvnyVztah.cislo')
	let ponuka
	if (msCislo) {
		ponuka = get(state, `ponuka.miestaSpotreby[${msCislo}]`)
	} else if (zvCislo) {
		ponuka = get(state, `ponuka.zmluvneVztahy[${zvCislo}]`)
	} else {
		ponuka = get(state, 'ponuka.obchodnyPartner')
	}
	return {
		interakcia: get(state, 'interakcie.detail.data'),
		auth: get(state, 'auth'),
		ponuka,
		msCislo,
		zvCislo
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	ponukaActions: bindActionCreators(PonukaActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps), onClickOutside)(PonukaDropdownMenu)
