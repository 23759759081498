import React from 'react'
import PropTypes from 'prop-types'

function CommonContainer(props) {
	return (
		<div className='inner-box'>
			<h4>{props.title}</h4>
			<table className='content-table bordered' cellSpacing='0' style={{ minHeight: '100px' }}>
				<tbody>
					{props.content}
					{props.children}
				</tbody>
			</table>
			{props.modal}
		</div>
	)
}

CommonContainer.defaultProps = {
	title: 'Výstupné dokumenty'
}

CommonContainer.propTypes = {
	content: PropTypes.shape(),
	modal: PropTypes.shape(),
	title: PropTypes.string
}

export default CommonContainer
