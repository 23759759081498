import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { get, times } from 'lodash'
import PropTypes from 'prop-types'

// utils
import { setRouteParams, ROZPISY_ZALOH_ZOZNAM } from '../../utils/routes'

function RozpisZalohHistory(props) {
	const { interakcia } = props
	const history = []
	times(4, (i) => {
		history.push(
			<div key={i} className='box'>
				<div className='box-content'>
					<div className='row bordered'>
						<div className='col-8'>
							<div className='rozpis-zaloh-history'>
								<div className='rozpis-zaloh-history-item'>
									<div className='rozpis-zaloh-history-item-content' style={{ zIndex: 4 }}>
										<div className='rozpis-zaloh-history-item-content-wrapper clearfix'>
											<a href='/' className='file pull-left' target='_blank' data-type='general'>
												historia_zaloh_Apr_2018.pdf
											</a>
											<div className='pull-right'>
												<a className='button' data-type='icon-button' data-icon='view' />
												<a className='button' data-type='icon-button' data-icon='print' />
												<a className='button' data-type='icon-button' data-icon='download' />
												<a className='button' data-type='icon-button' data-icon='send' />
											</div>
										</div>
									</div>
									<div className='rozpis-zaloh-history-item'>
										<div className='rozpis-zaloh-history-item-content' style={{ zIndex: 3 }}>
											<div className='rozpis-zaloh-history-item-content-wrapper'>
												<div className='history-item' data-label-color='green'>
													<div className='history-header clearfix'>
														<div className='title pull-left'>6.2.2018</div>
														<div className='time pull-right'>8:53</div>
													</div>
													<div className='history-content clearfix'>
														<div className='content'>Zmena údajov Obchodného partnera a Zmluvného účtu</div>
														<div className='price'>22€</div>
													</div>
												</div>
											</div>
										</div>
										<div className='rozpis-zaloh-history-item'>
											<div className='rozpis-zaloh-history-item-content' style={{ zIndex: 2 }}>
												<div className='rozpis-zaloh-history-item-content-wrapper clearfix'>
													<a href='/' className='file pull-left' target='_blank' data-type='general'>
														historia_zaloh_Apr_2018.pdf
													</a>
													<div className='pull-right'>
														<a className='button' data-type='icon-button' data-icon='view' />
														<a className='button' data-type='icon-button' data-icon='print' />
														<a className='button' data-type='icon-button' data-icon='download' />
														<a className='button' data-type='icon-button' data-icon='send' />
													</div>
												</div>
											</div>
											<div className='rozpis-zaloh-history-item last'>
												<div className='rozpis-zaloh-history-item-content' style={{ zIndex: 1 }}>
													<div className='rozpis-zaloh-history-item-content-wrapper'>
														<div className='history-item' data-label-color='red'>
															<div className='history-header clearfix'>
																<div className='title pull-left'>6.2.2018</div>
																<div className='time pull-right'>9:00 - 9:12</div>
															</div>
															<div className='history-content clearfix'>
																<div className='content'>Zmena sumy, cyklu a splatnosti preddavkových platieb</div>
																<div className='price'>78€</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-4'>
							<table className='content-table'>
								<tbody>
									<tr>
										<td>Výška PP</td>
										<td className='text-right'>
											<strong>105€</strong>
										</td>
									</tr>
									<tr>
										<td>Cyklus</td>
										<td className='text-right'>
											<strong>Kvartálny</strong>
										</td>
									</tr>
									<tr>
										<td>Fakturačná oblasť</td>
										<td className='text-right'>
											<strong>T2 Feb</strong>
										</td>
									</tr>
									<tr>
										<td>Deň splatnosti záloh</td>
										<td className='text-right'>
											<strong>12.</strong>
										</td>
									</tr>
									<tr>
										<td>Nezvyšovať výšku záloh</td>
										<td className='text-right'>
											<strong>Áno</strong>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		)
	})

	return (
		<>
			<div className='content-header clearfix'>
				<Link
					to={setRouteParams(ROZPISY_ZALOH_ZOZNAM, get(interakcia, 'data.opCislo'))}
					className='button pull-left'
					data-type='back-button'
					data-color='blue'
				>
					Späť
				</Link>
				<div className='header-title pull-left'>História záloh za rok 2018</div>
			</div>

			<div className='content-wrapper'>{history}</div>
		</>
	)
}

RozpisZalohHistory.propTypes = {
	interakcia: PropTypes.shape({
		partner: PropTypes.string.isRequired
	}).isRequired
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail
})

export default connect(mapStateToProps)(RozpisZalohHistory)
