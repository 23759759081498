import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { get } from 'lodash'

// atoms
import { DatePickerField } from '../../../atoms'

// utils
import { BUSINESS_CHANNELS } from '../../../utils/enums'
import { formatDate } from '../../../utils/date'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/attributesConfig'

class DatumPrijatiaZiadostiField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		auth: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape()
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { auth, t } = this.props

		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && !value) {
			return t('translation:Common.validate.Dátum prijatia žiadosti je povinný')
		}

		if (value) {
			if (
				get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM ||
				get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA
			) {
				if (value < dayjs().startOf('day') || value > dayjs().endOf('day')) {
					return t('translation:Common.validate.Dátum prijatia žiadosti nie je platný - zvoľte prosím aktuálny deň')
				}
			} else if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
				if (value > dayjs().endOf('day')) {
					return t('translation:Common.validate.Dátum prijatia žiadosti nie je platný - nemôžete zvoliť dátum v budúcnosti')
				}
			}
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, auth, t, value } = this.props

		let minTimeDatumPrijatiaZiadosti = null
		let maxTimeDatumPrijatiaZiadosti = null
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}

		const valueBefore = null
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT) {
			valueAfter = (
				<div className='select-wrapper'>
					<Field
						name={field}
						component={DatePickerField}
						showYearDropdown
						scrollableYearDropdown
						minDate={minTimeDatumPrijatiaZiadosti?.toDate()}
						maxDate={maxTimeDatumPrijatiaZiadosti?.toDate()}
						validate={this.validate}
						placeholderText={t('translation:Common.Zvoľte dátum')}
					/>
				</div>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter = formatDate(value, null, 'DD.MM.YYYY')
		}

		return (
			<tr>
				<td>
					<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
				</td>
				{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
				<td>{valueAfter}</td>
			</tr>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(DatumPrijatiaZiadostiField)
