import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { get, head, isEmpty, isEqual } from 'lodash'
import { Tooltip } from 'react-tippy'
import { withTranslation } from 'react-i18next'
import { Field, reduxForm, change } from 'redux-form'

import validate from './validateZmenaSuhlasuForm'

// atoms
import TextareaField from '../../atoms/TextareaField'
import DatePickerField from '../../atoms/DatePickerField'
import SelectField from '../../atoms/SelectField'

// utils
import { formatDate } from '../../utils/date'
import { DEFINICIA_TYP, FORMS, SUHLAS_TYP_VYJADRENIA, SUHLASY_KATEGORIA, TYP_SUHLASU } from '../../utils/enums'
import OptionsPickerField from '../../atoms/OptionsPickerField'

class ZmenaSuhlasuForm extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		t: PropTypes.func,
		originalValues: PropTypes.shape().isRequired,
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		scenarioOption: PropTypes.shape({
			kanal: PropTypes.number.isRequired,
			scenarios: PropTypes.array.isRequired,
			notificationRequire: PropTypes.bool.isRequired,
			notificationTypes: PropTypes.array.isRequired
		}),
		formTitle: PropTypes.string.isRequired,
		suhlasyOptions: PropTypes.array.isRequired,
		onSubmit: PropTypes.func.isRequired,
		onBackClick: PropTypes.func.isRequired,
		onCancelClick: PropTypes.func.isRequired,
		kategoriaId: PropTypes.string.isRequired,
		definiciaId: PropTypes.string.isRequired,
		formValues: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		this.state = {}
	}

	_mounted = false

	componentDidMount() {
		const { dispatch, kategoriaId } = this.props

		// NOTE: init "nesuhlas" option in case of digitalization definition -> https://jira.zsee.sk/jira/browse/CP-3018
		if (kategoriaId === SUHLASY_KATEGORIA.DIGITALIZACIA) {
			dispatch(change(FORMS.ZMENA_SUHLASU, 'typVyjadrenieSuhlas', SUHLAS_TYP_VYJADRENIA.NESUHLAS))
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		const { dispatch, suhlasyOptions, kategoriaId } = this.props

		// NOTE: change value only for DIGITALZIACIA and CRIF definitions
		if (!isEqual(prevProps.suhlasyOptions, suhlasyOptions) && suhlasyOptions?.length >= 1) {
			// NOTE: default value is zmluvnyUcet for DIGITALZIACIA definition
			let fieldKey = 'zmluvnyUcet'
			if (kategoriaId === SUHLASY_KATEGORIA.CRIF) {
				fieldKey = 'produktKod'
			}
			const value = suhlasyOptions?.length === 1 ? head(suhlasyOptions)?.value : [head(suhlasyOptions)]
			dispatch(change(FORMS.ZMENA_SUHLASU, fieldKey, value))
		}
	}

	getNesuhlasDovodyOptions(nesuhlasDovody) {
		if (!nesuhlasDovody) return null

		return nesuhlasDovody.map((nesuhlasDovod) => {
			return {
				label: nesuhlasDovod.dovod,
				value: nesuhlasDovod
			}
		})
	}

	getTypVyjadreniaOptions(typSuhlasu, posledneVyjadrenie) {
		const { originalValues, kategoriaId } = this.props

		// NOTE: set only "nesuhlas" option in case of digitalization definition -> https://jira.zsee.sk/jira/browse/CP-3018
		if (kategoriaId === SUHLASY_KATEGORIA.DIGITALIZACIA) {
			return [{ label: SUHLAS_TYP_VYJADRENIA.NESUHLAS, id: SUHLAS_TYP_VYJADRENIA.NESUHLAS }]
		}

		if (typSuhlasu?.id === TYP_SUHLASU.SUHLAS) {
			return [
				{ label: SUHLAS_TYP_VYJADRENIA.SUHLAS, id: SUHLAS_TYP_VYJADRENIA.SUHLAS },
				{ label: SUHLAS_TYP_VYJADRENIA.NESUHLAS, id: SUHLAS_TYP_VYJADRENIA.NESUHLAS }
			]
		}

		if (typSuhlasu?.id === TYP_SUHLASU.OPRAVNENY_ZAUJEM) {
			if (posledneVyjadrenie === SUHLAS_TYP_VYJADRENIA.NAMIETKA_NA_SCHVALENIE) {
				return [
					{ label: SUHLAS_TYP_VYJADRENIA.NAMIETKA, id: SUHLAS_TYP_VYJADRENIA.NAMIETKA },
					{ label: SUHLAS_TYP_VYJADRENIA.SUHLAS, id: SUHLAS_TYP_VYJADRENIA.SUHLAS }
				]
			}

			if (posledneVyjadrenie === SUHLAS_TYP_VYJADRENIA.NAMIETKA) {
				return [{ label: SUHLAS_TYP_VYJADRENIA.SUHLAS, id: SUHLAS_TYP_VYJADRENIA.SUHLAS }]
			}

			if (originalValues.autoSchvalenieNamietka) {
				return [{ label: SUHLAS_TYP_VYJADRENIA.NAMIETKA, id: SUHLAS_TYP_VYJADRENIA.NAMIETKA }]
			}

			return [{ label: SUHLAS_TYP_VYJADRENIA.NAMIETKA_NA_SCHVALENIE, id: SUHLAS_TYP_VYJADRENIA.NAMIETKA_NA_SCHVALENIE }]
		}

		return null
	}

	handleTypVyjadreniaChange(suhlasTypVajadrenia) {
		const { dispatch, originalValues } = this.props

		if (suhlasTypVajadrenia?.id === SUHLAS_TYP_VYJADRENIA.SUHLAS) {
			dispatch(change(FORMS.ZMENA_SUHLASU, 'nesuhlasDovod', null))
		}

		if (suhlasTypVajadrenia?.id !== originalValues.poslednySuhlas?.typVyjadrenieSuhlas) {
			dispatch(change(FORMS.ZMENA_SUHLASU, 'platnostOd', new Date()))
		} else {
			dispatch(change(FORMS.ZMENA_SUHLASU, 'platnostOd', originalValues.poslednySuhlas?.platnostOd))
		}
	}

	renderZuOrKp = (formValue) => {
		if (formValue instanceof Array) {
			return head(formValue)?.value
		}
		return formValue
	}

	render() {
		const { t, originalValues, handleSubmit, formValues, invalid, formTitle, onCancelClick, onBackClick, suhlasyOptions, kategoriaId, definiciaId } =
			this.props
		const suhlasy = head(originalValues.suhlasVerzia)?.suhlas

		let poslednySuhlas = {}

		const isBulkChange = formValues?.zmluvnyUcet instanceof Array && formValues?.zmluvnyUcet?.length > 1
		const isCRIF = kategoriaId === SUHLASY_KATEGORIA.CRIF
		// NOTE: only in case -> E_FAKTURA, E_PLATBA or E_PREPLATKY
		const isDIGITALIZACIA = definiciaId === DEFINICIA_TYP.E_FAKTURA || definiciaId === DEFINICIA_TYP.E_PLATBA || definiciaId === DEFINICIA_TYP.E_PREPLATKY

		if (!isEmpty(formValues?.produktKod)) {
			poslednySuhlas = head(
				suhlasy?.filter((suhlas) => {
					if (
						formValues?.produktKod instanceof Array &&
						formValues?.produktKod?.length === 1 &&
						suhlas.produktKod === head(formValues.produktKod)?.value
					) {
						return true
					}
					if (suhlas.produktKod === formValues.produktKod) {
						return true
					}
					return false
				})
			)
		} else if (!isEmpty(formValues?.zmluvnyUcet)) {
			poslednySuhlas = head(
				suhlasy?.filter((suhlas) => {
					if (
						formValues?.zmluvnyUcet instanceof Array &&
						formValues?.zmluvnyUcet?.length === 1 &&
						suhlas.zmluvnyUcet === head(formValues.zmluvnyUcet)?.value
					) {
						return true
					}
					if (suhlas.zmluvnyUcet === formValues.zmluvnyUcet) {
						return true
					}
					return false
				})
			)
		} else if (!isDIGITALIZACIA && !isCRIF) {
			// default value
			poslednySuhlas = originalValues?.poslednySuhlas
		}

		const nesuhlasDovodyOptions = this.getNesuhlasDovodyOptions(originalValues.nesuhlasDovod)

		const showDovodNesuhlasu =
			poslednySuhlas?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NESUHLAS || formValues?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NESUHLAS
		const isNamietkaOrNesuhlas =
			formValues?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NAMIETKA ||
			formValues?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NAMIETKA_NA_SCHVALENIE ||
			formValues?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NESUHLAS
		const showDovodNamietky =
			poslednySuhlas?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NAMIETKA ||
			poslednySuhlas?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NAMIETKA_NA_SCHVALENIE ||
			formValues?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NAMIETKA ||
			formValues?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NAMIETKA_NA_SCHVALENIE

		const typVyjadrenieSuhlasValue = get(formValues, 'typVyjadrenieSuhlas')

		// NOTE: https://jira.zsee.sk/jira/browse/CP-3018
		const zuSuhlasDisabled = poslednySuhlas?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.SUHLAS && isDIGITALIZACIA

		const continueBtn = (
			<button
				className={cx('button', 'pull-right', { disabled: invalid || zuSuhlasDisabled })}
				disabled={invalid || zuSuhlasDisabled}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('translation:Common.Pokračovať')}
			</button>
		)

		return (
			<form className='consent-page' onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>
						{zuSuhlasDisabled ? (
							<Tooltip
								html={
									<span>
										{t(
											'translation:Common.Na danom zmluvnom účte je evidovaný súhlas, pre udelenie nesúhlasu je potrebná aktualizácia údajov zmluvného účtu'
										)}
									</span>
								}
								position='top'
								trigger='mouseenter'
								theme='light'
							>
								{continueBtn}
							</Tooltip>
						) : (
							continueBtn
						)}
					</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{!isBulkChange && t('translation:Common.Aktuálne hodnoty')}</th>
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									{isDIGITALIZACIA && (
										<tr>
											<td>
												<strong>{t('translation:Suhlasy.Zmluvný účet')}</strong>
											</td>
											{/* Zmluvný účet – SuhlasDTO.zmluvnyUcet (zobrazí sa, len ak nie je NULL) */}
											<td>{!isBulkChange && poslednySuhlas?.zmluvnyUcet}</td>
											{/* Zmluvný účet – Nie je možné zmeniť (zobrazí sa, len ak nie je NULL) */}
											<td>
												{suhlasyOptions?.length > 1 ? (
													<Field
														name='zmluvnyUcet'
														component={SelectField}
														options={suhlasyOptions}
														isMulti
														classNamePrefix='react-select'
														selectAllOption
													/>
												) : (
													<strong>{this.renderZuOrKp(formValues?.zmluvnyUcet)}</strong>
												)}
											</td>
										</tr>
									)}
									{isCRIF && (
										<tr>
											<td>
												<strong>{t('translation:Suhlasy.Súhlas pre produkt')}</strong>
											</td>
											{/* Názov produktu pre SuhlasDTO.produktKod (zobrazí sa, len ak nie je NULL) */}
											<td>{!isBulkChange && poslednySuhlas?.produktKod}</td>
											{/* Súhlas pre produkt – Nie je možné zmeniť (zobrazí sa, len ak nie je NULL) */}
											<td>
												{suhlasyOptions?.length > 1 ? (
													<Field
														name='produktKod'
														component={SelectField}
														isMulti
														options={suhlasyOptions}
														classNamePrefix='react-select'
														selectAllOption
													/>
												) : (
													<strong>{this.renderZuOrKp(formValues?.produktKod)}</strong>
												)}
											</td>
										</tr>
									)}
									<tr>
										<td>
											<strong>{t('translation:Suhlasy.Dátum a čas vyjadrenia')}</strong>
										</td>
										{/* Dátum a čas vyjadrenia – SuhlasDTO.datumVyjadrenie */}
										<td>{!isBulkChange && formatDate(poslednySuhlas?.datumVyjadrenie, null, 'DD.MM.YYYY, HH:mm')}</td>
										{/* Dátum a čas vyjadrenia – systém predvyplní na aktuálny dátum a čas */}
										<td>
											<div className='select-wrapper'>
												<Field
													name='datumVyjadrenie'
													component={DatePickerField}
													showYearDropdown
													scrollableYearDropdown
													placeholderText={t('translation:Common.Zvoľte dátum')}
													showTimeSelect
													timeIntervals={5}
												/>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Suhlasy.Platnosť od')}</strong>
										</td>
										{/* Platnosť od – SuhlasDTO.platnostOD */}
										<td>{!isBulkChange && formatDate(poslednySuhlas?.platnostOd, null, 'DD.MM.YYYY')}</td>
										{/* Platnosť od – systém predvyplní na základe výberu v type vyjadrenia:
										    - Ak je vybratá hodnota iná ako v Aktuálne hodnoty.Typ vyjadrenia, naplní sa na aktuálny dátum.
										    - Ak je ponechaná rovnaká hodnota ako v predch. vyjadrení (napr. opakované dopýtanie, kedy bol opätovne zvolený nesúhlas, nemení platnosť od), platnosť od sa predvyplní hodnotou z predch. Vyjadrenia SuhlasDTO.platnostOd a nie je ju možné zmeniť.
										*/}
										<td>
											{formValues?.platnostOd !== poslednySuhlas?.platnostOd && (
												<strong>{formatDate(formValues.platnostOd, null, 'DD.MM.YYYY')}</strong>
											)}
											{formValues?.platnostOd === poslednySuhlas?.platnostOd && formatDate(formValues.platnostOd, null, 'DD.MM.YYYY')}
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Suhlasy.Typ vyjadrenia')}</strong>
										</td>
										{/* Typ vyjadrenia – SuhlasDTO. typVyjadrenieSuhlas */}
										<td>{!isBulkChange && poslednySuhlas?.typVyjadrenieSuhlas}</td>
										{/* Typ vyjadrenia – Možnosť výberu z hodnôt:
										    - Súhlas/Nesúhlas pri definícii typu Súhlas.
										    - Pre definíciu typu OZ automaticky predvyplnená hodnota Námietka alebo Námietka na schválenie podľa nastavenia v definícii:
											  - Ak je v definícii SuhlasDefiniciaDTO. autoSchvalenieNamietka = TRUE, potom je nastavený stav „Námietka“, inak „Námietka na schválenie“.
										*/}
										<td>
											<Field
												name={`typVyjadrenieSuhlas`}
												selectedOptionId={typVyjadrenieSuhlasValue}
												component={OptionsPickerField}
												options={this.getTypVyjadreniaOptions(originalValues?.suhlasTyp, poslednySuhlas?.typVyjadrenieSuhlas)}
												classNamePrefix='react-select'
												parse={(value) => value?.id}
												onChange={(e) => this.handleTypVyjadreniaChange(e)}
											/>
										</td>
									</tr>
									{originalValues?.pocetPokus && formValues?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NESUHLAS && (
										<tr>
											<td>
												<strong>{t('translation:Suhlasy.Počet dopýtaní')}</strong>
											</td>
											{/* Počet dopýtaní – SuhlasDTO.pocetVykonanyDopyt/ SuhlasDefiniciaDTO. pocetPokus (zobrazí sa, len ak v SuhlasDefiniciaDTO.pocetPokus != NULL a zároveň Dôvod nesúhlasu = Nesúhlas) */}
											<td>
												{!isBulkChange &&
													poslednySuhlas?.pocetVykonanyDopyt &&
													`${poslednySuhlas?.pocetVykonanyDopyt} / ${originalValues?.pocetPokus}`}
											</td>
											{/* Počet dopýtaní - SuhlasDTO.pocetVykonanyDopyt +1 Len ak je v poli „Typ vyjadrenia“ zvolená hodnota „Nesúhlas“. */}
											<td>
												<strong>
													{poslednySuhlas?.pocetVykonanyDopyt &&
														formValues?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NESUHLAS &&
														Number(poslednySuhlas?.pocetVykonanyDopyt) + 1}
												</strong>
											</td>
										</tr>
									)}
									{(showDovodNesuhlasu || showDovodNamietky) && (
										<tr>
											<td>
												{showDovodNesuhlasu && <strong>{t('translation:Suhlasy.Dôvod nesúhlasu')}</strong>}
												{showDovodNamietky && <strong>{t('translation:Suhlasy.Dôvod námietky')}</strong>}
											</td>
											{/* Dôvod námietky – SuhlasDTO.nesuhlasDovod (zobrazí sa, len ak Typ vyjadrenia = Námietka alebo Námietka na schválenie) */}
											{/* Dôvod nesúhlasu – SuhlasDTO. nesuhlasDovod (zobrazí sa, len ak Typ vyjadrenia = Nesúhlas) */}
											<td>{!isBulkChange && (poslednySuhlas?.nesuhlasDovod?.dovod ?? '-')}</td>
											{/* Dôvod nesúhlasu – možnosť výberu hodnoty z číselníka „Dôvody nesúhlasu“ definované v NesuhlasDovodDTO pre definíciu.
											    Len ak je v poli „Typ vyjadrenia“ zvolená hodnota jedna z: „Nesúhlas“, „Námietka na schválenie“, „Námietka“
											*/}
											<td>
												{isNamietkaOrNesuhlas && (
													<Field
														name={`nesuhlasDovod`}
														component={SelectField}
														options={nesuhlasDovodyOptions}
														compare={(option, val) => {
															return option.value.id == val.id
														}}
														classNamePrefix='react-select'
													/>
												)}
											</td>
										</tr>
									)}
									{(isNamietkaOrNesuhlas || poslednySuhlas?.nesuhlasPoznamka) && (
										<tr>
											<td>
												<strong>{t('translation:Suhlasy.Poznámka')}</strong>
											</td>
											<td>{!isBulkChange && poslednySuhlas?.nesuhlasPoznamka}</td>
											{/* Poznámka – možnosť doplniť text pre bližšie informácie o nesúhlase.
											    Len ak je v poli „Typ vyjadrenia“ zvolená hodnota jedna z: „Nesúhlas“, „Námietka na schválenie“, „Námietka“
											*/}
											<td>
												{isNamietkaOrNesuhlas && (
													<Field
														name={'nesuhlasPoznamka'}
														component={TextareaField}
														rows='3'
														validate={this.validate}
														placeholder={t('translation:Suhlasy.Zapíšte poznámku')}
													/>
												)}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail.data,
	auth: get(state, 'auth')
})

const form = reduxForm({
	form: FORMS.ZMENA_SUHLASU,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(ZmenaSuhlasuForm)

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(form)
