import { get } from 'lodash'
import {
	OTVORENE_FAKTURY_SEARCH_START,
	OTVORENE_FAKTURY_SEARCH_DONE,
	OTVORENE_FAKTURY_SEARCH_FAIL,
	OTVORENE_FAKTURY_SEARCH_CLEAR
} from '../../types/otvoreneFakturyTypes'

import { getReq } from '../../utils/request'

export function otvoreneFakturySearchStart() {
	return {
		type: OTVORENE_FAKTURY_SEARCH_START
	}
}

export function otvoreneFakturySearchDone(otvoreneFaktury) {
	return {
		type: OTVORENE_FAKTURY_SEARCH_DONE,
		payload: {
			otvoreneFaktury
		}
	}
}

export function otvoreneFakturySearchFail() {
	return {
		type: OTVORENE_FAKTURY_SEARCH_FAIL
	}
}

export function searchFaktury(queryParams = {}) {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		const query = {
			...queryParams
		}

		dispatch(otvoreneFakturySearchStart())

		try {
			const otvoreneFakturyData = await getReq(`/api/v0/obchodni-partneri/${get(interakcia, 'opCislo')}/plany-splatok/otvorene-faktury`, query)
			dispatch(otvoreneFakturySearchDone(get(otvoreneFakturyData, 'response.obsah', [])))
			return get(otvoreneFakturyData, 'response.obsah', [])
		} catch (e) {
			dispatch(otvoreneFakturySearchFail())
		}
	}
}

export const fakturyClear = () => {
	return (dispatch) => {
		dispatch({
			type: OTVORENE_FAKTURY_SEARCH_CLEAR
		})
	}
}
