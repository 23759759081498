import React from 'react'
import { compose, bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, isEmpty, map } from 'lodash'
import { NumericFormat } from 'react-number-format'
import { Tooltip } from 'react-tippy'
import { withTranslation } from 'react-i18next'
import onClickOutside from 'react-onclickoutside'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'

// utils
import { formatDate } from '../../utils/date'
import { POHLADAVKA_TYP, POHLADAVKA_SPLATNOST_STATUS } from '../../utils/enums'
import { getItemState, getTypPohladavkyTooltip } from '../../utils/pohladavky'

class BlokovanieUpominaniaTableRow extends React.Component {
	static propTypes = {
		auth: PropTypes.shape().isRequired,
		pohladavka: PropTypes.shape(),
		obchodnyPartnerActions: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			visible: false
		}
	}

	toggle = () => {
		this.setState({
			visible: !this.state.visible
		})
	}

	handleClickOutside() {
		this.setState({
			visible: false
		})
	}

	render() {
		const { pohladavka, t } = this.props

		let typPohladavky
		switch (get(pohladavka, 'typ.id')) {
			case POHLADAVKA_TYP.PREPLATOK_Z_FAKTURACIE.value:
			case POHLADAVKA_TYP.AKONTO.value:
			case POHLADAVKA_TYP.ZALOHA_ZNOVUPRIPOJENIE.value:
			case POHLADAVKA_TYP.UDRZBA_UCTU.value:
			case POHLADAVKA_TYP.STANDARD_KVALITY.value:
				typPohladavky = (
					<Tooltip html={getTypPohladavkyTooltip(pohladavka, t)} position='top' trigger='mouseenter' theme='light'>
						{get(pohladavka, 'typ.nazov')}
					</Tooltip>
				)
				break
			default:
				typPohladavky = get(pohladavka, 'typ.nazov')
		}

		const splatnostDatum = formatDate(get(pohladavka, 'splatnostDatum'), '', 'DD.MM.YYYY')
		let splatnostDatumString = splatnostDatum
		const sumaOtvorena = (
			<NumericFormat
				thousandSeparator={' '}
				decimalSeparator={','}
				decimalScale={2}
				fixedDecimalScale={true}
				displayType='text'
				suffix=' €'
				value={get(pohladavka, 'sumaOtvorena')}
			/>
		)
		let sumaOtvorenaString = sumaOtvorena

		const state = getItemState(pohladavka)
		if (
			get(pohladavka, 'sumaOtvorena') != 0 &&
			get(pohladavka, 'typ.id') != POHLADAVKA_TYP.PRIJATA_PLATBA.value &&
			get(pohladavka, 'typ.id') != POHLADAVKA_TYP.ODOSLANA_PLATBA.value
		) {
			if (state == POHLADAVKA_SPLATNOST_STATUS.SPLATNA_PRED) {
				splatnostDatumString = <strong data-text-color='blue'>{splatnostDatum}</strong>
				sumaOtvorenaString = <strong data-text-color='blue'>{sumaOtvorena}</strong>
			} else if (state == POHLADAVKA_SPLATNOST_STATUS.SPLATNA_PO) {
				splatnostDatumString = <strong data-text-color='red'>{splatnostDatum}</strong>
				sumaOtvorenaString = <strong data-text-color='red'>{sumaOtvorena}</strong>
			}
		}

		return (
			<tr>
				<td style={{ width: '10%' }}>{get(pohladavka, 'variabilnySymbol', '-')}</td>
				<td style={{ width: '15%', wordBreak: 'normal' }}>{typPohladavky}</td>
				<td style={{ width: '10%' }}>{get(pohladavka, 'cislo')}</td>
				<td style={{ width: '10%' }}>{formatDate(get(pohladavka, 'dokladDatum'), '', 'DD.MM.YYYY')}</td>
				<td style={{ width: '10%' }}>{splatnostDatumString}</td>
				<td style={{ width: '10%', whiteSpace: 'nowrap', textAlign: 'right' }}>
					<NumericFormat
						thousandSeparator={' '}
						decimalSeparator={','}
						decimalScale={2}
						fixedDecimalScale={true}
						displayType='text'
						suffix=' €'
						value={get(pohladavka, 'suma')}
					/>
				</td>
				<td style={{ width: '10%', whiteSpace: 'nowrap', textAlign: 'right' }}>{sumaOtvorenaString}</td>
				<td style={{ width: '25%' }}>
					{!isEmpty(get(pohladavka, 'blokovania', [])) ? (
						<table className='content-table padded' cellSpacing='0' style={{ height: 'auto' }}>
							<tbody>
								{map(get(pohladavka, 'blokovania', []), (blokovanie) => (
									<tr>
										<td style={{ whiteSpace: 'nowrap', backgroundColor: 'inherit', padding: '5px', /* fontSize: '12px', */ width: '30%' }}>
											{formatDate(get(blokovanie, 'blokovanieOd'), '', 'DD.MM.YYYY')}
											<br />
											{formatDate(get(blokovanie, 'blokovanieDo'), '', 'DD.MM.YYYY')}
										</td>
										<td style={{ backgroundColor: 'inherit', padding: '5px', /* fontSize: '12px', */ wordBreak: 'normal', width: '70%' }}>
											{get(blokovanie, 'blokovanieDovod.nazov', '')}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : null}
				</td>
				<td />
			</tr>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth')
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(onClickOutside(BlokovanieUpominaniaTableRow))
