import { get } from 'lodash'

import {
	HISTORIA_UKONOV_LOAD_DONE,
	HISTORIA_UKONOV_LOAD_FAIL,
	HISTORIA_UKONOV_LOAD_START,
	UKONY_BY_INTERAKCIA_ID_LOAD_DONE,
	UKONY_BY_INTERAKCIA_ID_LOAD_FAIL,
	UKONY_BY_INTERAKCIA_ID_LOAD_START,
	UKON_SET_ID,
	UKON_CLEAR_ID,
	UKONY_ODBERNE_MIESTO_LOAD_START,
	UKONY_ODBERNE_MIESTO_LOAD_FAIL,
	UKONY_ODBERNE_MIESTO_LOAD_DONE,
	UKONY_ODBERNE_MIESTO_CLEAR
} from '../types/ukony'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	historiaUkonov: {
		data: [],
		isLoading: false,
		isFailure: false,
		page: 1,
		isLastPage: false
	},
	ukonyByInterakciaId: {
		data: [],
		isLoading: false,
		isFailure: false
	},
	ukonNovy: {
		id: null
	},
	odberneMiesta: {}
}

export default (state = initState, action) => {
	switch (action.type) {
		case UKONY_BY_INTERAKCIA_ID_LOAD_START:
			return {
				...state,
				ukonyByInterakciaId: {
					...state.ukonyByInterakciaId,
					data: [],
					isLoading: true,
					isFailure: false
				}
			}
		case UKONY_BY_INTERAKCIA_ID_LOAD_FAIL:
			return {
				...state,
				ukonyByInterakciaId: {
					...state.ukonyByInterakciaId,
					data: [],
					isLoading: false,
					isFailure: true
				}
			}
		case UKONY_BY_INTERAKCIA_ID_LOAD_DONE:
			return {
				...state,
				ukonyByInterakciaId: {
					...state.ukonyByInterakciaId,
					data: action.payload.ukonyByInterakciaId,
					isLoading: false,
					isFailure: false
				}
			}
		case HISTORIA_UKONOV_LOAD_DONE:
			return {
				...state,
				historiaUkonov: {
					...state.historiaUkonov,
					data: state.historiaUkonov.page === 1 ? action.payload.historiaUkonov : state.historiaUkonov.data.concat(action.payload.historiaUkonov),
					isLoading: false,
					isFailure: false,
					lastLoad: new Date(),
					isLastPage: action.payload.isLastPage
				}
			}
		case HISTORIA_UKONOV_LOAD_FAIL:
			return {
				...state,
				historiaUkonov: {
					...state.historiaUkonov,
					isLoading: false,
					isFailure: true
				}
			}
		case HISTORIA_UKONOV_LOAD_START:
			return {
				...state,
				historiaUkonov: {
					...state.historiaUkonov,
					page: action.payload.page,
					isLoading: true,
					isFailure: false
				}
			}
		case UKON_SET_ID:
			return {
				...state,
				ukonNovy: {
					...state.ukonNovy,
					id: action.payload.ukonId
				}
			}
		case UKON_CLEAR_ID:
			return {
				...state,
				ukonNovy: {
					...state.ukonNovy,
					id: null
				}
			}
		case UKONY_ODBERNE_MIESTO_LOAD_START: {
			return {
				...state,
				odberneMiesta: {
					...get(state, 'odberneMiesta'),
					...get(action, 'payload.odberneMiesto')
				}
			}
		}
		case UKONY_ODBERNE_MIESTO_LOAD_DONE:
			return {
				...state,
				odberneMiesta: {
					...state.odberneMiesta,
					...get(action, 'payload.odberneMiesto')
				}
			}
		case UKONY_ODBERNE_MIESTO_LOAD_FAIL:
			return {
				...state,
				odberneMiesta: {
					...state.odberneMiesta,
					...get(action, 'payload.odberneMiesto')
				}
			}
		case UKONY_ODBERNE_MIESTO_CLEAR:
			return {
				...state,
				odberneMiesta: {
					...state.odberneMiesta,
					...get(action, 'payload.odberneMiesto')
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
