import { get, omit } from 'lodash'
import { EXTERNAL_INTEGRATIONS } from './enums'

export const getExterneIDs = (externalIntegrationsStore) => {
	if (!externalIntegrationsStore) return []

	const externeIDs = []

	const { liveAgentId, call250Id, finesseId } = externalIntegrationsStore

	if (liveAgentId) {
		externeIDs.push({
			source: EXTERNAL_INTEGRATIONS.LIVE_AGENT,
			externalId: liveAgentId
		})
	}

	if (finesseId) {
		// NOTE: external session ID missing, because it is not necessary to send it to interaction
		externeIDs.push({
			source: EXTERNAL_INTEGRATIONS.FINESSE,
			externalId: finesseId
		})
	}

	if (call250Id) {
		externeIDs.push({
			source: EXTERNAL_INTEGRATIONS.CALL250,
			externalId: call250Id
		})
	}

	return externeIDs
}
