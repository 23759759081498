import { get } from 'lodash'

import { getReq } from '../../utils/request'
import {
	OBCHODNY_PARTNER_VZTAHY_LOAD_DONE,
	OBCHODNY_PARTNER_VZTAHY_LOAD_START,
	OBCHODNY_PARTNER_VZTAHY_LOAD_FAIL,
	OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_START,
	OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_DONE,
	OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_FAIL
} from '../../types/obchodnyPartner'

export const loadVztahy = (page = 1, queryParams = {}) => {
	return async (dispatch, getStore) => {
		try {
			const interakcia = getStore().interakcie.detail.data

			dispatch({
				type: OBCHODNY_PARTNER_VZTAHY_LOAD_START,
				payload: { page }
			})

			const res = await getReq(`/api/v2/op/${interakcia.opCislo}/vztahy`, queryParams)

			dispatch({
				type: OBCHODNY_PARTNER_VZTAHY_LOAD_DONE,
				payload: {
					splnomocnenia: get(res, 'response.content', []),
					isLastPage: get(res, 'response.content.length') < get(res, 'response.pageSize'),
					lastLoad: new Date()
				}
			})
			return get(res, 'response.content', [])
		} catch (err) {
			dispatch({
				type: OBCHODNY_PARTNER_VZTAHY_LOAD_FAIL
			})
		}
	}
}

export function vztahDetailLoadStart() {
	return {
		type: OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_START
	}
}

export function vztahDetailLoadDone(vztahDetail) {
	return {
		type: OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_DONE,
		payload: {
			vztahDetail
		}
	}
}

export function vztahDetailLoadFail() {
	return {
		type: OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_FAIL
	}
}

export const loadVztahDetail = (opCislo, op2Cislo, typVztahu) => {
	return async (dispatch) => {
		dispatch(vztahDetailLoadStart())

		try {
			const obchodnyPartnerResponse = await getReq(`/api/v2/op/${op2Cislo}`)
			const vztahyResponse = await getReq(`/api/v2/op/${opCislo}/vztahy`)

			const vztah = get(vztahyResponse, 'response.content', []).find((vztah) => {
				return get(vztah, 'op2.cislo') === op2Cislo && get(vztah, 'typ.id') === typVztahu
			})

			dispatch(
				vztahDetailLoadDone({
					op2: get(obchodnyPartnerResponse, 'response.content'),
					vztah
				})
			)

			return { op2: get(obchodnyPartnerResponse, 'response.content'), vztah }
		} catch (e) {
			dispatch(vztahDetailLoadFail())
		}
	}
}
