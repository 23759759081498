import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

function UkonKonzultaciaUpomienky(props) {
	const { t, ukonData, dokumenty, formatDocuments } = props
	return (
		<tbody>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Číslo zmluvného účtu')}</strong>
				</td>
				<td>{get(ukonData, 'zuCislo')}</td>
			</tr>
			<tr>
				<td colSpan={2}>{formatDocuments(dokumenty)}</td>
			</tr>
		</tbody>
	)
}

UkonKonzultaciaUpomienky.propTypes = {
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	dokumenty: PropTypes.array,
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonKonzultaciaUpomienky
