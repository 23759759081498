import React from 'react'
import { get } from 'lodash'
import { Tooltip } from 'react-tippy'

// resources
import ProductEEGreen from '../resources/img/icons/product-ee-green.svg'
import ProductEERed from '../resources/img/icons/product-ee-red.svg'
import ProductGasGreen from '../resources/img/icons/product-gas-green.svg'
import ProductGasRed from '../resources/img/icons/product-gas-red.svg'

// utils
import { KOMODITA_TYP } from './enums'

export const getStateIcon = (odberneMiesto, t) => {
	if (get(odberneMiesto, 'aktivnaKomoditnaZmluva') == true) {
		return (
			<Tooltip html={t('translation:ZmluvneUcty.Existuje zmluva')} position='left' trigger='mouseenter' theme='light'>
				<div className='label circle' data-color='green' />
			</Tooltip>
		)
	}
	if (get(odberneMiesto, 'aktivnaKomoditnaZmluva') == false) {
		return (
			<Tooltip html={t('translation:ZmluvneUcty.Neexistuje zmluva')} position='left' trigger='mouseenter' theme='light'>
				<div className='label circle' data-color='red' />
			</Tooltip>
		)
	}
	return null
}

export const getKomoditaTypIcon = (odberneMiesto, onClick, t) => {
	let produktIcon
	let stavZmluvy
	const komoditaTyp = get(odberneMiesto, 'komoditaTyp.id')
	const aktivnaKomoditnaZmluva = get(odberneMiesto, 'aktivnaKomoditnaZmluva')
	if (aktivnaKomoditnaZmluva == true) {
		stavZmluvy = t('translation:ZmluvneUcty.Aktívna zmluva')
		if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
			produktIcon = ProductEEGreen
		} else if (komoditaTyp == KOMODITA_TYP.PLYN) {
			produktIcon = ProductGasGreen
		}
	} else if (aktivnaKomoditnaZmluva == false) {
		stavZmluvy = t('translation:ZmluvneUcty.Neaktívna zmluva')
		if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
			produktIcon = ProductEERed
		} else if (komoditaTyp == KOMODITA_TYP.PLYN) {
			produktIcon = ProductGasRed
		}
	}
	return (
		<Tooltip html={stavZmluvy} position='top' trigger='mouseenter' theme='light' style={{ marginLeft: '10px' }}>
			<span>
				{produktIcon && (
					<img
						src={produktIcon}
						alt={get(odberneMiesto, 'komoditaTyp.nazov')}
						style={{
							verticalAlign: 'middle',
							marginRight: '5px',
							height: '20px'
						}}
					/>
				)}
				<a
					className='zmluvny-vztah-op-detail'
					onClick={(e) => {
						e.preventDefault()
						onClick(get(odberneMiesto, 'cislo'), komoditaTyp)
					}}
				>
					{get(odberneMiesto, 'identifikator')}
				</a>
			</span>
		</Tooltip>
	)
}
