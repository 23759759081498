import { isEmpty, isString, isNumber, get, find, trim, split, forEach, head } from 'lodash'
/**
 * @param Object address object from api
 * @return string
 *
 * Format address object to formatted string
 */
export function formatAddress(address, withIntoTheHands = true) {
	if (isEmpty(address)) {
		return ''
	}
	let formattedAddress = ''
	if (address.ulica && isString(address.ulica) && address.ulica != '-') {
		formattedAddress += address.ulica.trim()
	}
	const streetNumber = composeStreetNumber(address)
	if (streetNumber) {
		formattedAddress += formattedAddress ? ` ${streetNumber}` : `${streetNumber}`
	}
	if (address.psc && isString(address.psc) && address.psc != '-') {
		const trimmed = address.psc.trim()
		if (trimmed) {
			formattedAddress += formattedAddress ? `, ${trimmed}` : `${trimmed}`
		}
	}
	if (address.obec && isString(address.obec) && address.obec != '-') {
		const trimmed = address.obec.trim()
		if (trimmed) {
			formattedAddress += formattedAddress ? `, ${trimmed}` : `${trimmed}`
		}
	}

	if (withIntoTheHands && address.doRukMeno && isString(address.doRukMeno) && address.doRukMeno != '-') {
		const trimmed = address.doRukMeno.trim()
		if (trimmed) {
			formattedAddress += formattedAddress ? `, (${trimmed})` : `(${trimmed})`
		}
	}
	// TODO: Attribute "stat" is currently ignored because it's not included in designes.
	/* if (
		address.stat &&
		isString(address.stat) &&
		address.stat != '-'
	) {
		formattedAddress += formattedAddress
			? `, ${address.stat.trim()}`
			: `${address.stat.trim()}`
	} */
	return formattedAddress
}

export function formatAddressCityFirst(address) {
	if (isEmpty(address)) {
		return ''
	}
	let formattedAddress = ''
	if (get(address, 'obec') && isString(get(address, 'obec')) && get(address, 'obec') != '-') {
		formattedAddress += trim(get(address, 'obec'))
	}
	if (get(address, 'ulica') && isString(get(address, 'ulica')) && get(address, 'ulica') != '-') {
		const trimmed = trim(get(address, 'ulica'))
		if (trimmed) {
			formattedAddress += formattedAddress ? `, ${trimmed}` : `${trimmed}`
		}
	}
	if (
		get(address, 'parcelneCislo') &&
		(isString(get(address, 'parcelneCislo')) || isNumber(get(address, 'parcelneCislo'))) &&
		get(address, 'parcelneCislo') != '-'
	) {
		const trimmed = trim(get(address, 'parcelneCislo'))
		if (trimmed) {
			formattedAddress += formattedAddress ? `, parc.č. ${trimmed}` : `parc.č. ${trimmed}`
		}
	}
	const streetNumber = composeStreetNumber(address)
	if (streetNumber) {
		formattedAddress += formattedAddress ? ` ${streetNumber}` : `${streetNumber}`
	}
	if (get(address, 'psc') && isString(get(address, 'psc')) && get(address, 'psc') != '-') {
		const trimmed = trim(get(address, 'psc'))
		if (trimmed) {
			formattedAddress += formattedAddress ? `, ${trimmed}` : `${trimmed}`
		}
	}
	return formattedAddress
}

function composeStreetNumber(address) {
	const streetNumberItems = []
	if (address && address.supisneCislo && (isString(address.supisneCislo) || isNumber(address.supisneCislo)) && address.supisneCislo != '-') {
		const trimmed = isString(address.supisneCislo) ? address.supisneCislo.trim() : address.supisneCislo
		trimmed && streetNumberItems.push(trimmed)
	}
	if (address && address.orientacneCislo && (isString(address.orientacneCislo) || isNumber(address.orientacneCislo)) && address.orientacneCislo != '-') {
		const trimmed = isString(address.orientacneCislo) ? address.orientacneCislo.trim() : address.orientacneCislo
		trimmed && streetNumberItems.push(trimmed)
	}
	return streetNumberItems.join('/')
}
/**
 * @param {Object} miestoSpotreby object from api
 * @return {string} formated poschodie, cisloBytu, adresaDodatok
 *
 */
export const miestoSpotrebyAdresaDoplnokFormat = (miestoSpotreby) => {
	if (!miestoSpotreby) {
		return
	}
	const adresa = []
	if (miestoSpotreby.poschodie && miestoSpotreby.poschodie !== '-') {
		adresa.push(miestoSpotreby.poschodie)
	}
	if (miestoSpotreby.cisloBytu && miestoSpotreby.cisloBytu !== '-') {
		adresa.push(miestoSpotreby.cisloBytu)
	}
	/* if (miestoSpotreby.adresaDodatok  && miestoSpotreby.adresaDodatok !== '-') {
		adresa.push(miestoSpotreby.adresaDodatok)
	} */
	return adresa.join(', ')
}

export const formatAddressForResponse = (fieldName, values, addresses) => {
	let temp = null
	if (get(values, `${fieldName}.id`) && get(values, `${fieldName}.id`) != -1) {
		const address = find(addresses, (e) => e.id == values[fieldName].id)
		temp = {
			...address,
			stat: get(address, 'stat', null),
			obec: get(address, 'obec', null),
			ulica: get(address, 'ulica', null),
			orientacneCislo: get(address, 'orientacneCislo', null),
			supisneCislo: get(address, 'supisneCislo', null),
			psc: get(address, 'psc', null),
			doRukMeno: get(address, 'doRukMeno', null)
		}
		delete temp.globalnaZmena
	} else if (get(values, `adresaZakaznika`)) {
		// CP-3106
		const address = get(values, `adresaZakaznika`)
		temp = {
			...address
		}
		delete temp.globalnaZmena
	} else if (!isEmpty(addresses)) {
		const address = head(addresses)
		temp = {
			...address,
			stat: get(address, 'stat', null),
			obec: get(address, 'obec', null),
			ulica: get(address, 'ulica', null),
			orientacneCislo: get(address, 'orientacneCislo', null),
			supisneCislo: get(address, 'supisneCislo', null),
			psc: get(address, 'psc', null),
			doRukMeno: get(address, 'doRukMeno', null)
		}
		delete temp.globalnaZmena
	} else {
		return null
	}
	/* forOwn(temp, (value, key) => {
		if (!value) {
			temp[key] = ''
		}
	}) */
	return temp
}

export const formatAddressForSearch = (address) => {
	if (isEmpty(address)) {
		return ''
	}
	let formattedAddress = ''
	if (get(address, 'obec') && isString(get(address, 'obec')) && get(address, 'obec') != '-') {
		formattedAddress += `"${trim(get(address, 'obec'))}"`
	}
	if (get(address, 'ulica') && isString(get(address, 'ulica')) && get(address, 'ulica') != '-') {
		const trimmed = trim(get(address, 'ulica'))
		if (trimmed) {
			formattedAddress += formattedAddress ? ` "${trimmed}"` : `"${trimmed}"`
		}
	}
	if (get(address, 'psc') && isString(get(address, 'psc')) && get(address, 'psc') != '-') {
		const trimmed = trim(get(address, 'psc')).replace(/\s/g, '')
		if (trimmed) {
			formattedAddress += formattedAddress ? ` "${trimmed}"` : `"${trimmed}"`
		}
	}
	if (
		get(address, 'orientacneCislo') &&
		(isString(get(address, 'orientacneCislo')) || isNumber(get(address, 'orientacneCislo'))) &&
		get(address, 'orientacneCislo') != '-'
	) {
		const trimmed = trim(get(address, 'orientacneCislo'))
		if (trimmed) {
			formattedAddress += formattedAddress ? ` "${trimmed}"` : `"${trimmed}"`
		}
	}
	if (
		get(address, 'supisneCislo') &&
		(isString(get(address, 'supisneCislo')) || isNumber(get(address, 'supisneCislo'))) &&
		get(address, 'supisneCislo') != '-'
	) {
		const trimmed = trim(get(address, 'supisneCislo'))
		if (trimmed) {
			formattedAddress += formattedAddress ? ` "${trimmed}"` : `"${trimmed}"`
		}
	}
	if (
		get(address, 'parcelneCislo') &&
		(isString(get(address, 'parcelneCislo')) || isNumber(get(address, 'parcelneCislo'))) &&
		get(address, 'parcelneCislo') != '-'
	) {
		const splitted = split(get(address, 'parcelneCislo'), '/')
		forEach(splitted, (item) => {
			const trimmed = trim(item)
			formattedAddress += formattedAddress ? ` "${trimmed}"` : `"${trimmed}"`
		})
	}
	return formattedAddress
}
