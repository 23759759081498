import React from 'react'
import { connect } from 'react-redux'
import { Field, touch } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { get, isEmpty, forEach, size, map, keys, find, pull, filter } from 'lodash'

// atoms
import { SelectField, DropZoneField } from '../../../atoms'

// components
import DefaultModal from '../../Modals/DefaultModal'
import WrapperPodpisovanieDokumentov from '../../PodpisovanieDokumentov/WrapperPodpisovanieDokumentov'

// utils
import { FORMS } from '../../../utils/enums'
import { deleteReq } from '../../../utils/request'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/attributesConfig'

class VstupneDokumentySystemoveField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		change: PropTypes.func.isRequired,
		field: PropTypes.string,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		ukonNovy: PropTypes.shape().isRequired,
		formValues: PropTypes.shape(),
		originalValues: PropTypes.shape(),
		moznosti: PropTypes.arrayOf(PropTypes.shape()),
		povinny: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		scenarioOption: PropTypes.shape()
	}

	state = {
		showZiadostTypModal: false,
		ziadostTypOptions: []
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { dispatch, editMode, moznosti } = this.props
		if (editMode == EDIT_MODE.EDIT) {
			dispatch(touch(FORMS.GENERIC_UKON, 'dokumenty'))
		}

		const ziadostTypOptions = map(keys(moznosti), (key) => {
			return {
				label: get(moznosti, key),
				value: key
			}
		})

		if (this._mounted) {
			this.setState({
				ziadostTypOptions
			})
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	onDeleteFile = async (fileID) => {
		const { ukonNovy, formValues, field, dispatch, change } = this.props

		const documents = get(formValues, 'dokumenty', [])
		const original = find(documents, (document) => get(document, 'id') == fileID)
		const duplicate = original
			? find(
					filter(get(formValues, 'dokumenty', []), (document) => {
						return get(document, 'name') && get(document, 'dataAsBase64')
					}),
					(document) => {
						return (
							get(document, 'name') == get(original, 'name') &&
							get(document, 'dataAsBase64') == get(original, 'dataAsBase64') &&
							get(document, 'type') == get(original, 'type') &&
							get(document, 'id') != get(original, 'id') &&
							get(document, 'typ.id') != get(original, 'typ.id')
						)
					}
			  )
			: null
		try {
			await deleteReq(`/api/v0/ukony/${get(ukonNovy, 'id')}/dokumenty/${fileID}`)
			if (duplicate) {
				await deleteReq(`/api/v0/ukony/${get(ukonNovy, 'id')}/dokumenty/${get(duplicate, 'id')}`)
				dispatch(change(`${field}.dokumenty`, pull([...get(formValues, `${field}.dokumenty`, [])], get(duplicate, 'id'))))
				return [fileID, get(duplicate, 'id')]
			}
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	validate = (dokumenty) => {
		const {
			povinny,
			originalValues,
			/* value, */
			t
		} = this.props

		if (povinny && size(dokumenty) <= size(get(originalValues, 'dokumenty', []))) {
			return t('translation:Common.validate.Dokumenty sú povinné')
		}
		if (!isEmpty(dokumenty)) {
			forEach(dokumenty, (document) => {
				if (!get(document, 'id') && !get(document, 'dataAsBase64')) {
					return t('translation:Common.validate.Neplatný dokument', {
						nazovDokumentu: get(document, 'name')
					})
				}
			})
		}
		/* if (field && povinny && !value) {
			return t('translation:Common.validate.Typ dokumentu je povinný')
		} */
	}

	onChangeDokumenty = (e, newValue, originalValue) => {
		const { dispatch, change, field, originalValues } = this.props

		if (!field) {
			return
		}

		if (size(newValue) > size(originalValue)) {
			this.setState({
				showZiadostTypModal: true
			})
		}
		if (size(newValue) == size(get(originalValues, 'dokumenty', []))) {
			dispatch(change(`${field}.ziadostTyp`, null))
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.TWO, value, t, moznosti, scenarioOption } = this.props
		const { showZiadostTypModal, ziadostTypOptions } = this.state

		if (editMode == EDIT_MODE.EDIT) {
			let valueText = null
			if (get(value, 'ziadostTyp')) {
				valueText = get(moznosti, get(value, 'ziadostTyp'), null)
			}

			return (
				<>
					{showZiadostTypModal && (
						<DefaultModal
							modalTitle={t('translation:Common.Zvoľte typ vstupného dokumentu')}
							modalContent={
								<Field
									name={`${field}.ziadostTyp`}
									component={SelectField}
									options={ziadostTypOptions}
									isSearchable={false}
									isDisabled={isEmpty(ziadostTypOptions)}
									classNamePrefix='react-select'
								/>
							}
							leftButton={{
								onClick: () => {
									this.setState({
										showZiadostTypModal: false
									})
								},
								text: t('translation:Common.Pokračovať'),
								disabled: isEmpty(value)
							}}
							visible
						/>
					)}
					<table className='content-table padded bordered' cellSpacing='0'>
						<tbody>
							<tr>
								<td>
									<strong>{t('translation:Common.Vstupné dokumenty k úkonu')}</strong>
								</td>
								{/* {columnsCount == COLUMNS_COUNT.THREE && <td />} */}
								<td colSpan={columnsCount == COLUMNS_COUNT.THREE ? 2 : 1}>
									<Field
										name={'dokumenty'}
										onDelete={this.onDeleteFile}
										component={DropZoneField}
										onChange={this.onChangeDokumenty}
										validate={this.validate}
										placeholder={t('translation:Common.Klikni alebo presuň súbory pre nahratie')}
										labelText={valueText}
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</>
			)
		}
		if (editMode == EDIT_MODE.CONFIRM) {
			return <WrapperPodpisovanieDokumentov scenarioOption={scenarioOption} />
		}

		return null
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(null, mapDispatchToProps))(VstupneDokumentySystemoveField)
