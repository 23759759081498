import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { get, isEmpty, join, map } from 'lodash'

// components
import Modal from './Modal'
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'

// resources
import crossIcon from '../../resources/img/icons/cross-black.svg'

// utils
import { formatDate } from '../../utils/date'
import { getReq } from '../../utils/request'
import { getAccessToken } from '../../utils/auth'
import { openDataUriWindow } from '../../utils/files'

class ArchivaciaEmailDetail extends React.Component {
	static propTypes = {
		modalTitle: PropTypes.string,
		id: PropTypes.string.isRequired,
		onCloseButton: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	state = {
		isLoading: false,
		isFailure: false,
		data: null
	}

	_mounted = false

	async componentDidMount() {
		this._mounted = true

		const { id } = this.props

		this.setState({
			isLoading: true,
			isFailure: false,
			data: null
		})

		try {
			const accessToken = getAccessToken()

			const emailData = await getReq(`/api/v0/dokumenty/${id}?accessToken=${accessToken}`)

			if (this._mounted) {
				this.setState({
					isLoading: false,
					isFailure: false,
					data: emailData
				})
			}
		} catch (err) {
			this.setState({
				isLoading: false,
				isFailure: true,
				data: null
			})
		}
	}

	render() {
		const { modalTitle, onCloseButton, t } = this.props
		const { isLoading, isFailure, data } = this.state

		const attachments = map(get(data, 'attachments', []), (attachment, index) => (
			<a
				key={`email-attachment-${index}`}
				className='file'
				onClick={() => openDataUriWindow(get(attachment, 'id'), get(attachment, 'nazov'))}
				data-type='general'
				style={{
					cursor: 'pointer',
					marginRight: '20px'
				}}
			>
				{get(attachment, 'nazov')}
			</a>
		))

		let content
		if (isLoading) {
			content = <ElementLoading />
		} else if (isFailure || isEmpty(data)) {
			content = <ElementFailure text={t('translation:Email.Nepodarilo sa načítať detail emailu')} />
		} else {
			content = (
				<>
					<table>
						<tbody>
							<tr>
								<td>
									<strong>{t('translation:Email.Od')}</strong>
								</td>
								<td>{get(data, 'from') || '-'}</td>
							</tr>
							{get(data, 'replyTo') && (
								<tr>
									<td>
										<strong>{t('translation:Email.Odpovedať na')}</strong>
									</td>
									<td>{get(data, 'replyTo') || '-'}</td>
								</tr>
							)}
							<tr>
								<td>
									<strong>{t('translation:Email.Komu')}</strong>
								</td>
								<td>{join(get(data, 'to', []), ', ')}</td>
							</tr>
							{!isEmpty(get(data, 'cc', [])) && (
								<tr>
									<td>
										<strong>{t('translation:Email.Kópia (cc)')}</strong>
									</td>
									<td>{join(get(data, 'cc', []), ', ')}</td>
								</tr>
							)}
							{!isEmpty(get(data, 'bcc', [])) && (
								<tr>
									<td>
										<strong>{t('translation:Email.Skrytá kópia (bcc)')}</strong>
									</td>
									<td>{join(get(data, 'bcc', []), ', ')}</td>
								</tr>
							)}
							<tr>
								<td>
									<strong>{t('translation:Email.Dátum a čas odoslania')}</strong>
								</td>
								<td>{formatDate(get(data, 'sentDate'), '-', 'DD.MM.YYYY HH:mm')}</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:Email.Predmet')}</strong>
								</td>
								<td>{get(data, 'subject') || '-'}</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<strong>{t('translation:Email.Správa')}</strong>
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<div className='inner-box'>
										<div
											dangerouslySetInnerHTML={{
												__html: get(data, 'body')
											}}
										/>
									</div>
								</td>
							</tr>
							{!isEmpty(attachments) && (
								<>
									<tr>
										<td colSpan={2}>
											<strong>{t('translation:Email.Prílohy')}</strong>
										</td>
									</tr>
									<tr>
										<td colSpan={2}>{attachments}</td>
									</tr>
								</>
							)}
						</tbody>
					</table>
				</>
			)
		}

		return (
			<Modal shown size='l'>
				{modalTitle && (
					<div className='modal-header'>
						<h3>{modalTitle}</h3>
						<div className='close' onClick={onCloseButton}>
							<img src={crossIcon} width='25' />
						</div>
					</div>
				)}
				<div className='modal-content'>{content}</div>
			</Modal>
		)
	}
}

export default compose(withTranslation('components'))(ArchivaciaEmailDetail)
