import { SEARCH_USERS_START, SEARCH_USERS_DONE, SEARCH_USERS_FAIL, SEARCH_USERS_CLEAR } from '../types/search'
import { getReq } from '../utils/request'
import config from '../utils/config'

export function searchUsersStart() {
	return {
		type: SEARCH_USERS_START
	}
}

export function searchUsersDone(obchodniPartneri, strankovanie) {
	return {
		type: SEARCH_USERS_DONE,
		payload: {
			obchodniPartneri,
			strankovanie
		}
	}
}

export function searchUsersFail() {
	return {
		type: SEARCH_USERS_FAIL
	}
}

export const searchUsersClear = () => {
	return (dispatch) => {
		dispatch({
			type: SEARCH_USERS_CLEAR
		})
	}
}

export function searchUsers(searchTerm, query = {}) {
	return async (dispatch, getStore) => {
		const { loading } = getStore().search
		if (loading) {
			return
		}
		// TODO: doriesit ako sa ma posielat empty hodnota
		const customQuery = {
			...query,
			pageSize: config.searchUserLimit,
			fulltext: searchTerm
		}

		dispatch(searchUsersStart())
		try {
			const data = await getReq('/api/v2/op', customQuery)
			const obchodniPartneri = data.response

			return dispatch(searchUsersDone(obchodniPartneri))
		} catch (e) {
			return dispatch(searchUsersFail())
		}
	}
}
