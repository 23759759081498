import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { get, map, find } from 'lodash'
import IBAN from 'iban'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tippy'

import { setRouteParams, BANKOVE_UCTY_DETAIL } from '../../utils/routes'

function BankoveUctyTableRow(props) {
	const isActive = get(props, 'pouzitie.length') > 0

	const zmluvneUcty = map(get(props, 'pouzitie', []), (item, index) => {
		const obchodnyPartner = get(props, 'opCislo') !== get(item, 'opCislo') ? <strong>{get(item, 'opCislo', '-')}</strong> : get(item, 'opCislo', '-')
		return (
			<React.Fragment key={index}>
				<br />
				<span>
					{get(item, 'zuCislo', '-')} / {obchodnyPartner}
				</span>
			</React.Fragment>
		)
	})

	// NOTE: CP-878 1) Do zoznamu (aj detailu) Bankových spojení pridať stĺpec / atribút "Zdroj dát" s hodnotami SAP vs. Flores (pri tom druhom bude treba fixne naviazať text na inú hodnotu = 3 namiesto 4).
	let zdrojDat
	if (get(props, 'zdrojDat.id') == 3) {
		const zdrojDatCiselnik4 = find(get(props, 'ciselniky.zdrojDat'), { id: 4 })
		zdrojDat = get(zdrojDatCiselnik4, 'nazov') || '-'
	} else {
		zdrojDat = get(props, 'zdrojDat.nazov') || '-'
	}

	return (
		<tr className={cx({ inactive: false })}>
			<td>{get(props, 'banka.nazov', '-')}</td>
			<td>{IBAN.printFormat(get(props, 'IBAN') || '') || '-'}</td>
			<td>{zdrojDat}</td>
			<td style={{ textAlign: 'center' }}>
				<Tooltip
					html={
						<>
							<strong>
								{isActive
									? props.t('components:BankoveUctyTableRow.Aktívne bankové spojenie')
									: props.t('components:BankoveUctyTableRow.Neaktívne bankové spojenie')}
							</strong>
							{isActive && (
								<>
									<br />
									<span>{props.t('components:BankoveUctyTableRow.Zmluvný účet / obchodný partner')}</span>
									{zmluvneUcty}
									<br />
								</>
							)}
						</>
					}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					<div className='label circle' data-color={isActive ? 'green' : 'red'} />
				</Tooltip>
			</td>
			<td style={{ textAlign: 'right' }}>
				<Link
					to={setRouteParams(BANKOVE_UCTY_DETAIL, get(props, 'opCislo', ''), get(props, 'id', ''))}
					className='button'
					data-type='icon-button'
					data-icon='view'
				/>
			</td>
		</tr>
	)
}

BankoveUctyTableRow.propTypes = {
	id: PropTypes.number.isRequired,
	IBAN: PropTypes.string.isRequired,
	opCislo: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
}

export default BankoveUctyTableRow
