import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'
import { get, isEmpty, size, find, head } from 'lodash'
import dayjs from 'dayjs'

// actions
import * as TrackingActions from '../../actions/TrackingActions'

// components
import Modal from './Modal'
import ElementLoading from '../ElementLoading'

// resources
import crossIcon from '../../resources/img/icons/cross-black.svg'

// utils
import { postReq } from '../../utils/request'
import { UKONY_CISELNIK, UKONY } from '../../utils/enums'
import { getUkonVstupByBiznisKanal } from '../../utils/scenar'

class ValidatePin extends React.Component {
	static propTypes = {
		onCloseButton: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		interakcia: PropTypes.shape().isRequired,
		auth: PropTypes.shape().isRequired,
		ciselniky: PropTypes.shape().isRequired,
		tracking: PropTypes.shape().isRequired,
		trackingActions: PropTypes.shape().isRequired
	}

	_mounted = false

	state = {
		pin: '',
		isSuccess: false,
		isLoading: false,
		isFailure: false,
		status: null
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { tracking, trackingActions } = this.props

		if (!get(tracking, 'startTime') || (get(tracking, 'startTime') && get(tracking, 'type') !== UKONY.OVERENIE_PIN)) {
			trackingActions.tryToStartTracking(UKONY.OVERENIE_PIN)
		}
	}

	componentWillUnmount() {
		this._mounted = false

		const { trackingActions } = this.props

		trackingActions.clearTracking()
	}

	validatePin = async () => {
		const { interakcia, auth, ciselniky, tracking } = this.props
		const { pin } = this.state

		this.setState({
			isSuccess: false,
			isLoading: true,
			isFailure: false,
			status: null
		})

		const opCislo = get(interakcia, 'opCislo')
		const typ = find(get(ciselniky, 'ukonTyp', []), (ukonTyp) => {
			return get(ukonTyp, 'id') == UKONY_CISELNIK.OVERENIE_PIN
		})
		const startTime = get(tracking, 'startTime')

		const body = {
			vstup: getUkonVstupByBiznisKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'ukonVstup')),
			podpisMiesto: get(auth, 'user.podpisMiesto', ''),
			splnomocnenec: null,
			trvanie: dayjs().diff(dayjs(startTime), 'millisecond'),
			zacatyOd: dayjs(startTime).toISOString(),
			typ,
			opCislo,
			interakciaId: get(interakcia, 'id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			ziadanyOd: dayjs(new Date()).toISOString(),
			data: {
				pinNaOverenie: pin
			}
		}

		try {
			await postReq(`/api/v2/op/${opCislo}/pin/check`, null, body)

			this.setState({
				isSuccess: true,
				isLoading: false,
				isFailure: false,
				status: null
			})
		} catch (e) {
			this.setState({
				isSuccess: false,
				isLoading: false,
				isFailure: true,
				status: get(head(get(e, 'response.data.response.status', [])), 'popis', null)
			})
		}
	}

	render() {
		const { onCloseButton, t } = this.props
		const { pin, isSuccess, isLoading, isFailure, status } = this.state

		let content
		if (isLoading) {
			content = <ElementLoading />
		} else if (isSuccess) {
			content = t('translation:Common.PIN kód bol úspešne overený')
		} else {
			content = (
				<div className='input-wrapper no-icon' style={{ paddingBottom: 0 }}>
					<input
						type='text'
						className='input-field'
						data-type='number'
						onChange={(e) =>
							this.setState({
								pin: get(e, 'target.value', '')
									.replace(/[^0-9]+/g, '')
									.slice(0, 4)
							})
						}
						placeholder={t('translation:Common.Zadajte PIN')}
						value={pin}
					/>
					{isFailure && <label style={{ color: '#E70000', marginTop: '10px' }}>{status || t('translation:Common.Nepodarilo sa overiť PIN')}</label>}
				</div>
			)
		}

		return (
			<Modal shown size='l' handleOutsideClick={() => onCloseButton(isSuccess)}>
				<div className='modal-header'>
					<h3>{t('translation:Common.Overenie PIN')}</h3>
					<div className='close' onClick={() => onCloseButton(isSuccess)}>
						<img src={crossIcon} width='25' />
					</div>
				</div>
				<div className='modal-content'>{content}</div>
				<div className='modal-footer clearfix'>
					{!isSuccess && (
						<button
							className={`button pull-right ${isEmpty(pin) || size(pin) != 4 || isSuccess ? 'disabled' : ''}`}
							onClick={this.validatePin}
							data-color='blue'
							disabled={isEmpty(pin) || size(pin) != 4}
						>
							{t('translation:Common.Overiť PIN')}
						</button>
					)}
					<button
						className={`button pull-right ${isLoading ? 'disabled' : ''}`}
						onClick={() => onCloseButton(isSuccess)}
						data-type='outline'
						data-color='blue'
						disabled={isLoading}
					>
						{t('translation:Common.Zavrieť')}
					</button>
				</div>
			</Modal>
		)
	}
}
const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth'),
	ciselniky: get(state, 'ciselniky.data'),
	tracking: get(state, 'tracking')
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	trackingActions: bindActionCreators(TrackingActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(ValidatePin)
