import { get, find } from 'lodash'

export function getSposobPlatbyFromCiselnik(ciselnikCode, sposobPlatbyId, ciselniky) {
	const sposobPlatby = find(get(ciselniky, ciselnikCode, []), (sposob) => {
		return get(sposob, 'id') == sposobPlatbyId
	})

	return sposobPlatby
}

export function calculateYearAdvancePayment(zalohyCyklusOption, zalohyVyska) {
	let sumaZaloh
	// based on ciselniky.zalohaCyklus
	switch (zalohyCyklusOption) {
		case 6:
			sumaZaloh = `${zalohyVyska * 1}€`
			break
		case 5:
			sumaZaloh = `${zalohyVyska * 2}€`
			break
		case 4:
			sumaZaloh = `${zalohyVyska * 4}€`
			break
		case 3:
			sumaZaloh = `${zalohyVyska * 11}€`
			break
		default:
			sumaZaloh = 'Neznámy cyklus'
			break
	}

	return sumaZaloh
}
