import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

function TextInputField({ input, label, placeholder, required, showLabel, meta: { touched, error }, type = 'text', min, disabled, readOnly, onKeyPress }) {
	return (
		<div className={cx('input-wrapper', { 'has-error': touched && error })}>
			{showLabel && <label htmlFor={label}>{label}</label>}
			<input
				{...input}
				placeholder={placeholder}
				type={type}
				min={min}
				className={cx('form-control', 'input-field')}
				required={required}
				disabled={disabled}
				readOnly={readOnly}
				onKeyPress={onKeyPress}
			/>
			<div className='text-danger'>{touched ? error : ''}</div>
		</div>
	)
}

TextInputField.propTypes = {
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({}).isRequired,
	required: PropTypes.bool,
	type: PropTypes.string,
	min: PropTypes.string,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	onKeyPress: PropTypes.func
}

TextInputField.defaultProps = {
	showLabel: false,
	placeholder: '',
	label: '',
	required: false
}

export default TextInputField
