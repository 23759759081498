import { RESET_STORAGE } from '../types/search'
import { CHECK_ADDRESS_LOAD_DONE, CHECK_ADDRESS_LOAD_FAIL, CHECK_ADDRESS_LOAD_START, CHECK_ADDRESS_CLEAR } from '../types/addresses'

export const initState = {
	checkAddress: {
		isLoading: false,
		isFailure: false,
		data: null
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case CHECK_ADDRESS_LOAD_START:
			return {
				...state,
				checkAddress: {
					...initState.checkAddress,
					isLoading: true
				}
			}
		case CHECK_ADDRESS_LOAD_FAIL:
			return {
				...state,
				checkAddress: {
					...initState.checkAddress,
					isFailure: true
				}
			}
		case CHECK_ADDRESS_LOAD_DONE:
			return {
				...state,
				checkAddress: {
					...initState.checkAddress,
					data: action.payload
				}
			}
		case CHECK_ADDRESS_CLEAR:
			return {
				...state,
				checkAddress: {
					...initState.checkAddress
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
