// utils
import { get, isEmpty } from 'lodash'
import { SUHLAS_TYP_VYJADRENIA } from '../../utils/enums'

const validate = (values, props) => {
	const { signedPdf, formValues } = props

	const typVyjadrenieSuhlasValue = get(formValues, 'typVyjadrenieSuhlas')
	const errors = {}

	if (isEmpty(signedPdf?.data) && typVyjadrenieSuhlasValue === SUHLAS_TYP_VYJADRENIA.SUHLAS) {
		if (!values.biznisReferencia) errors.biznisReferencia = props.t('translation:Common.Toto pole je povinné')
	}

	return errors
}

export default validate
