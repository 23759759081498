export const PONUKA_MIESTO_SPOTREBY_LOAD_START = 'PONUKA_MIESTO_SPOTREBY_LOAD_START'
export const PONUKA_MIESTO_SPOTREBY_LOAD_DONE = 'PONUKA_MIESTO_SPOTREBY_LOAD_DONE'
export const PONUKA_MIESTO_SPOTREBY_LOAD_FAIL = 'PONUKA_MIESTO_SPOTREBY_LOAD_FAIL'

export const PONUKA_OBCHODNY_PARTNER_LOAD_START = 'PONUKA_OBCHODNY_PARTNER_LOAD_START'
export const PONUKA_OBCHODNY_PARTNER_LOAD_DONE = 'PONUKA_OBCHODNY_PARTNER_LOAD_DONE'
export const PONUKA_OBCHODNY_PARTNER_LOAD_FAIL = 'PONUKA_OBCHODNY_PARTNER_LOAD_FAIL'

export const PONUKA_ZMLUVNY_VZTAH_LOAD_START = 'PONUKA_ZMLUVNY_VZTAH_LOAD_START'
export const PONUKA_ZMLUVNY_VZTAH_LOAD_DONE = 'PONUKA_ZMLUVNY_VZTAH_LOAD_DONE'
export const PONUKA_ZMLUVNY_VZTAH_LOAD_FAIL = 'PONUKA_ZMLUVNY_VZTAH_LOAD_FAIL'

export const PONUKA_SEARCH_PAGE_LOAD_START = 'PONUKA_SEARCH_PAGE_LOAD_START'
export const PONUKA_SEARCH_PAGE_LOAD_DONE = 'PONUKA_SEARCH_PAGE_LOAD_DONE'
export const PONUKA_SEARCH_PAGE_LOAD_FAIL = 'PONUKA_SEARCH_PAGE_LOAD_FAIL'
