import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { capitalize, map, get, isEqual, some, values, keyBy, forEach, sortBy, upperFirst, isEmpty } from 'lodash'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'
import * as IBAN from 'iban'
import { Tooltip } from 'react-tippy'
import { formatDate } from '../../utils/date'

import ElementLoading from '../ElementLoading'

// utils
import { formatAddress } from '../../utils/address'
import { formatZmluvnyUcetTyp, getSepaMandatStavColor, formatZmluvnyVztahTyp } from '../../utils/zmluvnyUcet'
import { getReq } from '../../utils/request'
import { KOMODITA_TYP, SPOSOB_PLATBY_DOSLA_PLATBA, SPOSOB_PLATBY_ODOSLANA_PLATBA } from '../../utils/enums'
import { setRouteParams, ZMLUVNE_UCTY_DETAIL, ZMLUVNE_UCTY_UPRAVIT } from '../../utils/routes'
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'

class ZmluvnyUcetTableRow extends React.Component {
	static propTypes = {
		zmluvnyUcet: PropTypes.shape.isRequired,
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		selectedFilters: PropTypes.any.isRequired,
		emptyCallbackHandler: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	state = {
		isLoading: true,
		isReady: false,
		zmluvneVztahy: [],
		page: 1,
		hasNextPage: false,
		limit: 5
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true

		const { page, isReady } = this.state

		if (!isReady) {
			this.loadZmluvneVztahy(page)
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	setLoading = (loading) => {
		if (this._mounted) {
			this.setState({
				isLoading: loading
			})
		}
	}

	loadZmluvneVztahy = (page, disableJoin) => {
		const { zmluvnyUcet, interakcia, selectedFilters, emptyCallbackHandler } = this.props
		const { limit, zmluvneVztahy } = this.state

		this.setLoading(true)

		const query = {
			zmluvnyUcetCislo: get(zmluvnyUcet, 'cislo'),
			komoditaTyp: get(selectedFilters, 'komoditnyTyp'),
			stav: get(selectedFilters, 'stavZmluvy'),
			adresaIsuId: get(selectedFilters, 'adresaIsuId'),
			stranka: page,
			velkostStranky: limit
		}

		getReq(`/api/v0/obchodni-partneri/${get(interakcia, 'opCislo')}/zmluvne-vztahy`, query, (err, res) => {
			if (this._mounted) {
				if (err) {
					return this.setLoading(false)
				}

				const zmluvneVztahyData = get(res, 'response.obsah.zmluvneVztahy', [])
				const miestaSpotrebyData = keyBy(get(res, 'response.obsah.miestaSpotreby', []), (miestoSpotreby) => get(miestoSpotreby, 'cislo'))
				const odberneMiestaData = keyBy(get(res, 'response.obsah.odberneMiesta', []), (odberneMiesto) => get(odberneMiesto, 'cislo'))
				forEach(zmluvneVztahyData, (vztah) => {
					vztah.miestoSpotreby = null
					vztah.odberneMiesto = get(odberneMiestaData, `${get(vztah, 'odberneMiestoCislo')}`, null)
					if (get(vztah, 'odberneMiesto') && get(miestaSpotrebyData, `${get(vztah, 'odberneMiesto.miestoSpotrebyCislo')}`)) {
						vztah.miestoSpotreby = get(miestaSpotrebyData, `${get(vztah, 'odberneMiesto.miestoSpotrebyCislo')}`, null)
					}
				})

				const temp = !disableJoin ? [...zmluvneVztahy, ...zmluvneVztahyData] : [...zmluvneVztahyData]

				if (isEmpty(temp)) {
					emptyCallbackHandler(get(zmluvnyUcet, 'cislo'), true)
				} else {
					emptyCallbackHandler(get(zmluvnyUcet, 'cislo'), false)
				}

				if (this._mounted) {
					this.setState({
						zmluvneVztahy: temp,
						isLoading: false,
						isReady: true,
						page: get(res, 'response.strankovanie.aktualnaStranka', 0),
						hasNextPage:
							get(res, 'response.strankovanie.zaznamov', Number.MAX_SAFE_INTEGER) > get(res, 'response.strankovanie.aktualnaStranka', 0) * limit
					})
				}
			}
		})
	}

	componentDidUpdate(prevProps) {
		const { selectedFilters } = this.props

		if (!isEqual(prevProps.selectedFilters, selectedFilters)) {
			this.loadZmluvneVztahy(1, true)
		}
	}

	loadMore = () => {
		const { page, isLoading } = this.state

		if (isLoading) {
			return
		}

		this.loadZmluvneVztahy(page + 1)
	}

	sortKomoditneZmluvy = (a, b) => {
		if (get(a, 'typ.id') == get(b, 'typ.id')) {
			return get(b, 'stav.id', 0) - get(a, 'stav.id', 0)
		}
		return get(a, 'typ.id') > get(b, 'typ.id') ? 1 : -1
	}

	render() {
		const { zmluvnyUcet, interakcia, selectedFilters, t } = this.props
		const { isLoading, zmluvneVztahy, hasNextPage, isReady } = this.state

		const sortedZmluvneVztahy = sortBy(zmluvneVztahy, ['stav.id', 'typ.id'])

		const zmluvy = !isLoading
			? map(sortedZmluvneVztahy, (vztah, index) => {
					return (
						<div key={`c${get(zmluvnyUcet, 'cislo')}-${get(vztah, 'cislo')}`} id={get(vztah, 'cislo')} className='row bordered'>
							<div className='col-6'>
								<table className='content-table'>
									<thead>
										<tr>
											<th data-text-size='large'>{formatZmluvnyVztahTyp(vztah)}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{t('translation:ZmluvneUcty.Číslo zmluvy CRM')}</td>
											<td>
												<strong>{get(vztah, 'cislo')}</strong>
											</td>
										</tr>
										{get(vztah, 'typ.id') == 3 && (
											<tr>
												<td>{t('translation:ZmluvneUcty.Číslo zmluvy ISU')}</td>
												<td>
													<strong>{get(vztah, 'cisloISU')}</strong>
												</td>
											</tr>
										)}
										<tr>
											<td>{t('translation:ZmluvneUcty.Miesto spotreby')}</td>
											<td>
												<strong>
													{get(vztah, 'miestoSpotreby.adresa', null)
														? formatAddress(get(vztah, 'miestoSpotreby.adresa', null))
														: get(vztah, 'miestoSpotrebyCislo') || '-'}
												</strong>
											</td>
										</tr>
										<tr>
											<td>{t('translation:ZmluvneUcty.Aktuálny stav zmluvy')}</td>
											<td>
												<strong className='green-text'>{get(vztah, 'stav') ? capitalize(`${get(vztah, 'stav.nazov')}`) : '-'}</strong>
											</td>
										</tr>
										{get(vztah, 'typ.id') == 3 && (
											<tr>
												<td>{t('translation:ZmluvneUcty.Číslo odberného miesta')}</td>
												<td>
													<strong>{get(vztah, 'odberneMiesto.cislo', '-')}</strong>
												</td>
											</tr>
										)}
										{get(vztah, 'odberneMiesto.komoditaTyp.id') == KOMODITA_TYP.ELEKTRINA && (
											<tr>
												<td>{t('translation:ZmluvneUcty.EIC')}</td>
												<td>
													<strong>{get(vztah, 'odberneMiesto.identifikator', '-')}</strong>
												</td>
											</tr>
										)}
										{get(vztah, 'odberneMiesto.komoditaTyp.id') == KOMODITA_TYP.PLYN && (
											<tr>
												<td>{t('translation:ZmluvneUcty.POD')}</td>
												<td>
													<strong>{get(vztah, 'odberneMiesto.identifikator', '-')}</strong>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className='col-6 clearfix'>
								<table className='content-table'>
									<thead>
										<tr>
											<th />
											<th />
										</tr>
									</thead>
									<tbody>
										{get(vztah, 'vytvorenyOd') && (
											<tr>
												<td>{t('translation:ZmluvneUcty.Zmluva vytvorená od')}</td>
												<td>
													<strong>{formatDate(get(vztah, 'vytvorenyOd'), null, 'DD.MM.YYYY')}</strong>
												</td>
											</tr>
										)}

										{get(vztah, 'stornovanyOd') && (
											<tr>
												<td>{t('translation:ZmluvneUcty.Zmluva stornovaná od')}</td>
												<td>
													<strong>{formatDate(get(vztah, 'stornovanyOd'), null, 'DD.MM.YYYY')}</strong>
												</td>
											</tr>
										)}

										{get(vztah, 'platnyOd') && (
											<tr>
												<td>{t('translation:ZmluvneUcty.Zmluva platná od')}</td>
												<td>
													<strong>{formatDate(get(vztah, 'platnyOd'), null, 'DD.MM.YYYY')}</strong>
												</td>
											</tr>
										)}

										{get(vztah, 'ucinnyOd') && (
											<tr>
												<td>{t('translation:ZmluvneUcty.Zmluva účinná od')}</td>
												<td>
													<strong>{formatDate(get(vztah, 'ucinnyOd'), null, 'DD.MM.YYYY')}</strong>
												</td>
											</tr>
										)}

										{get(vztah, 'viazanostDo') && (
											<tr>
												<td>{t('translation:ZmluvneUcty.Viazanosť do')}</td>
												<td>
													<strong>{formatDate(get(vztah, 'viazanostDo'), null, 'DD.MM.YYYY')}</strong>
												</td>
											</tr>
										)}

										{get(vztah, 'ukoncenyOd') && (
											<tr>
												<td>{t('translation:ZmluvneUcty.Zmluva ukončená')}</td>
												<td>
													<strong>{formatDate(get(vztah, 'ukoncenyOd'), null, 'DD.MM.YYYY')}</strong>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							{index !== zmluvneVztahy.length - 1 && <div className='hr' />}
						</div>
					)
			  })
			: null

		if (!isLoading && isReady) {
			if (some(values(selectedFilters)) && isEmpty(zmluvneVztahy)) {
				return null
			}
		}

		const backUrl = btoa(`${window.location.pathname}${window.location.search}`)

		return (
			<div key={`b${get(zmluvnyUcet, 'cislo')}`} className='box'>
				<div className='box-header' style={{ fontWeight: 'normal' }}>
					<div className='row'>
						<div className='col-3 vertical-center'>
							<strong>
								{t('translation:ZmluvneUcty.Zmluvný účet')} {get(zmluvnyUcet, 'cislo')} {formatZmluvnyUcetTyp(get(zmluvnyUcet, 'typ.id'))}
							</strong>
						</div>
						<div className='col-6 vertical-center'>
							{get(zmluvnyUcet, 'oznacenie') && (
								<strong>
									{t('translation:ZmluvneUcty.Označenie')} {get(zmluvnyUcet, 'oznacenie')}
								</strong>
							)}
						</div>
						<div className='col-3 clearfix'>
							<Permissions
								allowed={[PERMISSIONS.UKON_UDAJE_ZU]}
								render={(hasPerm, actions) => {
									if (hasPerm) {
										return (
											<Link
												to={`${setRouteParams(
													ZMLUVNE_UCTY_UPRAVIT,
													get(interakcia, 'opCislo'),
													get(zmluvnyUcet, 'cislo')
												)}?backUrl=${backUrl}`}
												className='button pull-right'
												data-color='blue'
											>
												{t('translation:ZmluvneUcty.Upraviť nastavenie ZÚ')}
											</Link>
										)
									}

									return (
										<Link
											to={`${setRouteParams(
												ZMLUVNE_UCTY_UPRAVIT,
												get(interakcia, 'opCislo'),
												get(zmluvnyUcet, 'cislo')
											)}?backUrl=${backUrl}`}
											className={cx('button', 'pull-right', { disabled: true })}
											data-color='blue'
											onClick={(e) => {
												e.preventDefault()
												actions.openForbiddenModal()
											}}
										>
											<Tooltip
												html={<span>{t('containers:ZmluvnyUcetDetailPage.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>}
												position='left'
												trigger='mouseenter'
												theme='light'
											>
												{t('translation:ZmluvneUcty.Upraviť nastavenie ZÚ')}
											</Tooltip>
										</Link>
									)
								}}
							/>
							<Tooltip
								html={t('translation:ZmluvneUcty.Detail zmluvného účtu')}
								className='pull-left'
								position='left'
								trigger='mouseenter'
								theme='light'
							>
								<Link
									to={setRouteParams(ZMLUVNE_UCTY_DETAIL, get(interakcia, 'opCislo'), get(zmluvnyUcet, 'cislo'))}
									className='button pull-left'
									data-type='icon-button'
									data-icon='view'
									style={{ marginTop: '4px' }}
								/>
							</Tooltip>
						</div>
					</div>
					<div className='row' style={{ marginTop: '20px' }}>
						<div className='col-3'>
							<div>
								<strong>{t('translation:ZmluvneUcty.Prijaté platby')}</strong>
							</div>
							<div style={{ marginTop: '10px' }}>
								<strong
									className={cx(
										'zmluvny-ucet-header',
										get(zmluvnyUcet, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA ? 'red' : 'green'
									)}
								>
									{upperFirst(get(zmluvnyUcet, 'doslePlatbySposob.nazov', '-'))}
								</strong>
								{get(zmluvnyUcet, 'sepaMandat') && (
									<Tooltip
										html={
											<div style={{ textAlign: 'left' }}>
												{t('translation:ZmluvneUcty.Číslo SEPA mandátu')}&nbsp;
												<strong>{get(zmluvnyUcet, 'sepaMandat.cislo', '-')}</strong>
												<br />
												{t('translation:ZmluvneUcty.Stav SEPA mandátu')}&nbsp;
												<strong>{get(zmluvnyUcet, 'sepaMandat.sepaMandatStav.nazov', '-')}</strong>
												<br />
												{t('translation:ZmluvneUcty.Dátum podpisu')}&nbsp;
												{formatDate(get(zmluvnyUcet, 'sepaMandat.podpisDatum'), '-', 'DD.MM.YYYY')}
											</div>
										}
										position='right'
										trigger='mouseenter'
										theme='light'
									>
										<div className='label circle' data-color={getSepaMandatStavColor(get(zmluvnyUcet, 'sepaMandat.sepaMandatStav.id'))} />
									</Tooltip>
								)}
								<div style={{ marginTop: '5px' }}>
									{get(zmluvnyUcet, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA
										? formatAddress(get(zmluvnyUcet, 'adresaDoslePlatby')) || '-'
										: IBAN.printFormat(get(zmluvnyUcet, 'doslePlatbyBankoveSpojenie.IBAN') || '') || '-'}
								</div>
								{/* {get(zmluvnyUcet, 'sepaMandat.dokumentId') && <div style={{ marginTop: '10px' }}>
								<a className="file" onClick={() => openDataUriWindow(get(zmluvnyUcet, 'sepaMandat.dokumentId'), t('containers:ZmluvnyUcetDetailPage.SEPA Mandát'))} data-type="general" style={{ cursor: 'pointer' }}>
									{t('containers:ZmluvnyUcetDetailPage.SEPA Mandát')}
								</a>
							</div>} */}
							</div>
						</div>
						<div className='col-3'>
							<div>
								<strong>{t('translation:ZmluvneUcty.Odoslané platby')}</strong>
							</div>
							<div style={{ marginTop: '10px' }}>
								<strong
									className={cx(
										'zmluvny-ucet-header',
										get(zmluvnyUcet, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA ? 'red' : 'green'
									)}
								>
									{upperFirst(get(zmluvnyUcet, 'odoslanePlatbySposob.nazov', '-'))}
								</strong>
								<div style={{ marginTop: '5px' }}>
									{get(zmluvnyUcet, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA
										? formatAddress(get(zmluvnyUcet, 'adresaOdoslanePlatby')) || '-'
										: IBAN.printFormat(get(zmluvnyUcet, 'odoslanePlatbyBankoveSpojenie.IBAN') || '') || '-'}
								</div>
							</div>
						</div>
						<div className='col-3'>
							<div>
								<strong>{t('translation:ZmluvneUcty.e-Faktúra')}</strong>
							</div>
							<div style={{ marginTop: '10px' }}>
								{!isEmpty(get(zmluvnyUcet, 'eFakturaEmaily', [])) ? (
									map(get(zmluvnyUcet, 'eFakturaEmaily', []), (item) => (
										<div className='green-text'>
											<strong>{get(item, 'email')}</strong>
										</div>
									))
								) : (
									<span className='red-text'>
										<strong>{t('translation:Common.Neaktívna')}</strong>
									</span>
								)}
							</div>
						</div>
						<div className='col-3 clearfix'>
							<div>
								<strong>{t('translation:ZmluvneUcty.Pripomienka platby')}</strong>
							</div>
							<div style={{ marginTop: '10px' }}>
								{get(zmluvnyUcet, 'neposielatPripomienkuPlatby') ? (
									<span>
										<strong>{t('translation:Common.Neaktívne')}</strong>
									</span>
								) : (
									<span className='green-text'>
										<strong>{t('translation:Common.Aktívne')}</strong>
									</span>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className={cx({ 'box-content': true })} style={{ minHeight: '110px' }}>
					{isLoading && <ElementLoading />}
					{!isEmpty(zmluvy) ? (
						zmluvy
					) : (
						<div className='row bordered'>
							<div className='col-12'>{t('translation:ZmluvneUcty.Zmluvný účet neobsahuje žiadne zmluvy')}</div>
						</div>
					)}
				</div>

				{!isLoading && hasNextPage && (
					<div className='text-center'>
						<button className='button' data-color='blue' data-type='outline' style={{ margin: '0px 0 20px 0' }} onClick={this.loadMore}>
							{t('translation:ZmluvneUcty.Načítať ďalšie zmluvné vzťahy')}
						</button>
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data')
})

export default compose(withTranslation('components'), connect(mapStateToProps))(ZmluvnyUcetTableRow)
