import { get } from 'lodash'
import {
	PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_START,
	PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_DONE,
	PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_FAIL
} from '../types/procesnyKonfigurator'

export const initState = {
	context: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_START:
			return {
				...state,
				context: {
					...initState.context,
					isLoading: true,
					isFailure: false
				}
			}
		case PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_DONE:
			return {
				...state,
				context: {
					data: get(action, 'payload.otvoreneFaktury'),
					isLoading: false,
					isFailure: false
				}
			}
		case PK_CONTEXT_UKON_PLAN_SPLATOK_LOAD_FAIL:
			return {
				...state,
				context: {
					...initState.context,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
