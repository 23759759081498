export const HISTORIA_UKONOV_LOAD_START = 'HISTORIA_UKONOV_LOAD_START'
export const HISTORIA_UKONOV_LOAD_FAIL = 'HISTORIA_UKONOV_LOAD_FAIL'
export const HISTORIA_UKONOV_LOAD_DONE = 'HISTORIA_UKONOV_LOAD_DONE'

export const UKONY_BY_INTERAKCIA_ID_LOAD_START = 'UKONY_BY_INTERAKCIA_ID_LOAD_START'
export const UKONY_BY_INTERAKCIA_ID_LOAD_FAIL = 'UKONY_BY_INTERAKCIA_ID_LOAD_FAIL'
export const UKONY_BY_INTERAKCIA_ID_LOAD_DONE = 'UKONY_BY_INTERAKCIA_ID_LOAD_DONE'

export const UKON_SET_ID = 'UKON_SET_ID'
export const UKON_CLEAR_ID = 'UKON_CLEAR_ID'

export const UKONY_ODBERNE_MIESTO_LOAD_START = 'UKONY_ODBERNE_MIESTO_LOAD_START'
export const UKONY_ODBERNE_MIESTO_LOAD_FAIL = 'UKONY_ODBERNE_MIESTO_LOAD_FAIL'
export const UKONY_ODBERNE_MIESTO_LOAD_DONE = 'UKONY_ODBERNE_MIESTO_LOAD_DONE'
export const UKONY_ODBERNE_MIESTO_CLEAR = 'UKONY_ODBERNE_MIESTO_CLEAR'
