import { getReq } from '../utils/request'
import { CHECK_ADDRESS_LOAD_START, CHECK_ADDRESS_LOAD_FAIL, CHECK_ADDRESS_LOAD_DONE, CHECK_ADDRESS_CLEAR } from '../types/addresses'

/**
 * @param {int} addressId
 */
export const checkUsedAddress = (addressId) => {
	return async (dispatch, getStore) => {
		try {
			dispatch({ type: CHECK_ADDRESS_LOAD_START })

			const store = getStore()
			const interakcia = store.interakcie.detail
			const res = await getReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/adresy/${addressId}/pouzitie`)

			const payload = res.response

			dispatch({ type: CHECK_ADDRESS_LOAD_DONE, payload })
		} catch (e) {
			dispatch({ type: CHECK_ADDRESS_LOAD_FAIL })
		}
	}
}

export const checkUsedAddressClear = () => {
	return (dispatch) => {
		dispatch({ type: CHECK_ADDRESS_CLEAR })
	}
}
