import { SET_NOTIFICATION, SET_ADDITIONAL_NOTIFICATION, CLEAR_NOTIFICATION } from '../../types/podpisovanieDokumentov'

export const setNotification = (notification) => {
	return (dispatch) => {
		dispatch({
			type: SET_NOTIFICATION,
			payload: {
				notification
			}
		})
	}
}

export const setAdditionalNotification = (notification) => {
	return (dispatch) => {
		dispatch({
			type: SET_ADDITIONAL_NOTIFICATION,
			payload: {
				additionalNotification: notification
			}
		})
	}
}

export const clearNotification = () => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_NOTIFICATION
		})
	}
}
