import React from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get, isEmpty, map } from 'lodash'
import { withTranslation } from 'react-i18next'

// actions
import * as ZmluvneVztahyActions from '../../actions/ZmluvneVztahyActions'

// components
import ElementLoading from '../ElementLoading'

// utils
import { formatDate } from '../../utils/date'
import { openDataUriWindow } from '../../utils/files'

class ZmluvnyVztahDetailDocuments extends React.Component {
	static propTypes = {
		zvCislo: PropTypes.string.isRequired,
		t: PropTypes.func.isRequired,
		documents: PropTypes.shape().isRequired,
		zmluvneVztahyActions: PropTypes.shape().isRequired
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true

		const { zvCislo, zmluvneVztahyActions } = this.props

		zmluvneVztahyActions.loadDocuments(zvCislo)
	}

	componentWillUnmount() {
		this._mounted = false

		const { zvCislo, zmluvneVztahyActions } = this.props

		zmluvneVztahyActions.clearDocuments(zvCislo)
	}

	formatDocumentTableRow = (document, index) => {
		const { zvCislo } = this.props

		const id = get(document, 'id')
		const pridanyOd = get(document, 'pridanyOd')
		const nazov = get(document, 'nazov', '-')

		return (
			<tr key={`document-${zvCislo}-ukon-${index}}`}>
				<td style={{ paddingLeft: 0, width: '100px' }}>{formatDate(pridanyOd, '-', 'DD.MM.YYYY')}</td>
				<td style={{ wordBreak: 'break-all' }}>
					<a
						key={id}
						className='file'
						data-type='general'
						style={{ cursor: 'pointer', marginRight: '20px' }}
						target='_blank'
						onClick={(e) => {
							e.preventDefault()
							openDataUriWindow(id, nazov)
						}}
					>
						{nazov}
					</a>
				</td>
			</tr>
		)
	}

	render() {
		const { documents, t } = this.props

		if (!this._mounted || get(documents, 'isLoading')) {
			return (
				<div style={{ position: 'relative', minHeight: '100px' }}>
					<ElementLoading backgroundColor={'#F8F8F8'} />
				</div>
			)
		}

		if (get(documents, 'isFailure')) {
			return (
				<div style={{ position: 'relative', minHeight: '100px' }}>
					<div className='custom-empty-state'>{t('translation:ZmluvneUcty.Nepodarilo sa načítať dokumenty')}</div>
				</div>
			)
		}

		if (isEmpty(get(documents, 'data', []))) {
			return (
				<div style={{ position: 'relative', minHeight: '100px' }}>
					<div className='custom-empty-state'>{t('translation:ZmluvneUcty.Žiadne dokumenty')}</div>
				</div>
			)
		}

		const documentsFormatted = map(get(documents, 'data', []), (document, index) => this.formatDocumentTableRow(document, index))

		return (
			<table className='content-table'>
				<tbody>{documentsFormatted}</tbody>
			</table>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const zvCislo = get(ownProps, 'zvCislo')
	return {
		documents: get(state, `zmluvneVztahy.documents[${zvCislo}]`)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	zmluvneVztahyActions: bindActionCreators(ZmluvneVztahyActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(ZmluvnyVztahDetailDocuments)
