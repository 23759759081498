import { get } from 'lodash'
import {
	PONUKA_MIESTO_SPOTREBY_LOAD_START,
	PONUKA_MIESTO_SPOTREBY_LOAD_DONE,
	PONUKA_MIESTO_SPOTREBY_LOAD_FAIL,
	PONUKA_OBCHODNY_PARTNER_LOAD_START,
	PONUKA_OBCHODNY_PARTNER_LOAD_DONE,
	PONUKA_OBCHODNY_PARTNER_LOAD_FAIL,
	PONUKA_ZMLUVNY_VZTAH_LOAD_START,
	PONUKA_ZMLUVNY_VZTAH_LOAD_DONE,
	PONUKA_ZMLUVNY_VZTAH_LOAD_FAIL,
	PONUKA_SEARCH_PAGE_LOAD_START,
	PONUKA_SEARCH_PAGE_LOAD_DONE,
	PONUKA_SEARCH_PAGE_LOAD_FAIL
} from '../types/ponukaTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	miestaSpotreby: {},
	obchodnyPartner: {
		data: [],
		isLoaded: false,
		isLoading: false,
		isFailure: false
	},
	searchPage: {
		data: [],
		isLoaded: false,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case PONUKA_MIESTO_SPOTREBY_LOAD_START:
			return {
				...state,
				miestaSpotreby: {
					...state.miestaSpotreby,
					...get(action, 'payload.miestoSpotreby')
				}
			}
		case PONUKA_MIESTO_SPOTREBY_LOAD_DONE:
			return {
				...state,
				miestaSpotreby: {
					...state.miestaSpotreby,
					...get(action, 'payload.miestoSpotreby')
				}
			}
		case PONUKA_MIESTO_SPOTREBY_LOAD_FAIL:
			return {
				...state,
				miestaSpotreby: {
					...state.miestaSpotreby,
					...get(action, 'payload.miestoSpotreby')
				}
			}
		case PONUKA_OBCHODNY_PARTNER_LOAD_START:
			return {
				...state,
				obchodnyPartner: {
					...initState.obchodnyPartner,
					isLoading: true
				}
			}
		case PONUKA_OBCHODNY_PARTNER_LOAD_DONE:
			return {
				...state,
				obchodnyPartner: {
					...initState.obchodnyPartner,
					data: get(action, 'payload.ponuka'),
					isLoaded: true,
					isLoading: false
				}
			}
		case PONUKA_OBCHODNY_PARTNER_LOAD_FAIL:
			return {
				...state,
				obchodnyPartner: {
					...initState.obchodnyPartner,
					data: get(action, 'payload.ponuka'),
					isLoaded: true,
					isFailure: true
				}
			}
		case PONUKA_ZMLUVNY_VZTAH_LOAD_START:
			return {
				...state,
				zmluvneVztahy: {
					...state.zmluvneVztahy,
					...get(action, 'payload.zmluvnyVztah')
				}
			}
		case PONUKA_ZMLUVNY_VZTAH_LOAD_DONE:
			return {
				...state,
				zmluvneVztahy: {
					...state.zmluvneVztahy,
					...get(action, 'payload.zmluvnyVztah')
				}
			}
		case PONUKA_ZMLUVNY_VZTAH_LOAD_FAIL:
			return {
				...state,
				zmluvneVztahy: {
					...state.zmluvneVztahy,
					...get(action, 'payload.zmluvnyVztah')
				}
			}
		case PONUKA_SEARCH_PAGE_LOAD_START:
			return {
				...state,
				searchPage: {
					...initState.searchPage,
					isLoading: true
				}
			}
		case PONUKA_SEARCH_PAGE_LOAD_DONE:
			return {
				...state,
				searchPage: {
					...initState.searchPage,
					data: get(action, 'payload.ponuka'),
					isLoaded: true,
					isLoading: false,
					isFailure: false
				}
			}
		case PONUKA_SEARCH_PAGE_LOAD_FAIL:
			return {
				...state,
				searchPage: {
					...initState.searchPage,
					data: get(action, 'payload.ponuka'),
					isLoaded: true,
					isFailure: true
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
