import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field, touch } from 'redux-form'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'

import { get, size, map, isEmpty, groupBy, upperFirst, orderBy, isEqual, omit, filter, indexOf, sortBy, find, deburr, toUpper } from 'lodash'

// utils
import { formatAddressForSearch, formatAddressCityFirst } from '../../../utils/address'
import { formatIdentifikatorTyp } from '../../../utils/zmluvneVztahy'
import { ADDRESS_SOURCE, KOMODITA_TYP, DRUH_MIESTA_SPOTREBY_HLAVNE, RADIO_GROUP_FIELD_TYPE, LOKALIZACIA_TYP } from '../../../utils/enums'
import { formatFormValue } from '../../../utils/form'

// components
import ElementLoading from '../../ElementLoading'
import MiestaSpotrebySearchRow from '../../ZmluvneVztahy/MiestaSpotrebySearchRow'

// atoms
import { RadioGroupField, TextInputField, AddressSearchField, CheckboxField } from '../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/attributesConfig'

// actions
import * as StatusActions from '../../../actions/StatusActions'
import * as OdberneMiestaActions from '../../../actions/OdberneMiestaActions'
import * as MiestaSpotrebyActions from '../../../actions/MiestaSpotrebyActions'

const ORDER_DRUH_MIESTA_SPOTREBY = [
	DRUH_MIESTA_SPOTREBY_HLAVNE.BYTY,
	DRUH_MIESTA_SPOTREBY_HLAVNE.RODINNY_DOM,
	DRUH_MIESTA_SPOTREBY_HLAVNE.SPOLOCNE_PRIESTORY,
	DRUH_MIESTA_SPOTREBY_HLAVNE.GARAZ,
	DRUH_MIESTA_SPOTREBY_HLAVNE.ZAHRADA
]

const HLAVNE_DRUH_MIESTA_SPOTREBY = [
	DRUH_MIESTA_SPOTREBY_HLAVNE.BYTY,
	DRUH_MIESTA_SPOTREBY_HLAVNE.RODINNY_DOM,
	DRUH_MIESTA_SPOTREBY_HLAVNE.SPOLOCNE_PRIESTORY,
	DRUH_MIESTA_SPOTREBY_HLAVNE.GARAZ,
	DRUH_MIESTA_SPOTREBY_HLAVNE.ZAHRADA
]

const LOKALIZACIA_TYP_RODINNY_DOM = [LOKALIZACIA_TYP.VEDLA, LOKALIZACIA_TYP.OPROTI, LOKALIZACIA_TYP.ZA, LOKALIZACIA_TYP.BLIZKO]

const LOKALIZACIA_TYP_BYTY = [LOKALIZACIA_TYP.VLAVO, LOKALIZACIA_TYP.VPRAVO]

const LOKALIZACIA_TYP_OSTATNE = [
	[LOKALIZACIA_TYP.VEDLA, LOKALIZACIA_TYP.OPROTI, LOKALIZACIA_TYP.ZA, LOKALIZACIA_TYP.BLIZKO, LOKALIZACIA_TYP.PRI],
	[LOKALIZACIA_TYP.VLAVO, LOKALIZACIA_TYP.TRAFOSTANICA, LOKALIZACIA_TYP.VPRAVO, LOKALIZACIA_TYP.VELKOODBER],
	[LOKALIZACIA_TYP.A, LOKALIZACIA_TYP.B, LOKALIZACIA_TYP.C, LOKALIZACIA_TYP.D, LOKALIZACIA_TYP.D1, LOKALIZACIA_TYP.D2, LOKALIZACIA_TYP.D3, LOKALIZACIA_TYP.D4]
]

class MiestoSpotrebyField extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape().isRequired,
		dispatch: PropTypes.func.isRequired,
		field: PropTypes.string.isRequired,
		form: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		formValues: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		searchResultsOM: PropTypes.array.isRequired,
		searchResultsMS: PropTypes.array.isRequired,
		change: PropTypes.func.isRequired,
		povinny: PropTypes.bool.isRequired,
		vstupny: PropTypes.bool.isRequired,
		ciselniky: PropTypes.shape().isRequired,
		addresses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		statuses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		statusActions: PropTypes.shape().isRequired,
		odberneMiestaActions: PropTypes.shape().isRequired,
		miestaSpotrebyActions: PropTypes.shape().isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			showOnlyAvailable: true,
			searchedAddress: null,
			createNewMSFormVisible: false,
			lokalizaciaOptions: []
		}
	}

	componentDidMount() {
		this._mounted = true

		const { dispatch, form, field, value } = this.props

		dispatch(touch(form, `${field}.adresa`))

		const selectedDruh = get(value, 'druh.id')
		const lokalizaciaOptions = this.formatLokalizaciaOptions(selectedDruh)
		this.setState({
			lokalizaciaOptions
		})
	}

	componentWillUnmount() {
		const { odberneMiestaActions, miestaSpotrebyActions } = this.props

		odberneMiestaActions.searchOdberneMiestaClear()
		miestaSpotrebyActions.searchMiestaSpotrebyClear()

		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		const { dispatch, change, field, value, statuses, statusActions, odberneMiestaActions, miestaSpotrebyActions, formValues } = this.props

		if (get(value, 'adresa.parcelneCislo') && !isEqual(get(value, 'adresa.parcelneCislo'), get(prevProps, 'value.adresa.parcelneCislo'))) {
			dispatch(change(`${field}.adresa.budova`, { id: get(value, 'adresa.parcelneCislo') }))
		}

		if (!get(value, 'adresa.idIsu') && !get(value, 'cislo') && !isEqual(get(value, 'druh'), get(prevProps, 'value.druh'))) {
			const selectedDruh = get(value, 'druh.id')
			const lokalizaciaOptions = this.formatLokalizaciaOptions(selectedDruh)
			this.setState({
				lokalizaciaOptions
			})
		}

		if (get(value, 'adresa.id') == -1 && !isEqual(omit(get(value, 'adresa'), ['budova']), omit(get(prevProps, 'value.adresa'), ['budova']))) {
			if (get(value, 'adresa.obec', '') === '') return

			// clean previous error messages
			if (size(statuses) > 0) {
				statusActions.statusPop(size(statuses))
			}

			const cislaDomu = `${[
				get(value, 'adresa.orientacneCislo', ''),
				get(value, 'adresa.supisneCislo', ''),
				...get(value, 'adresa.parcelneCislo', '').split('/')
			]
				.filter((value) => value !== '')
				.join(',')}`

			Promise.all([
				odberneMiestaActions.searchOdberneMiesta({
					obec: get(value, 'adresa.obec', ''),
					ulica: get(value, 'adresa.ulica', ''),
					cislaDomu
				}),
				miestaSpotrebyActions.searchMiestaSpotreby({
					obecNazov: get(value, 'adresa.obec', ''),
					ulicaNazov: get(value, 'adresa.ulica', ''),
					cislaDomu
				})
			]).then((results) => {
				const miestaSpotreby = results[1]
				if (isEmpty(miestaSpotreby)) {
					const komoditaTyp = get(formValues, 'data.odberneMiesto.komoditaTyp')
					this.novaAdresaClickAction(komoditaTyp)
				}
			})

			this.setState({
				showOnlyAvailable: true
			})
		}
	}

	novaAdresaClickAction = (komoditaTyp) => {
		const { dispatch, field, form, change } = this.props

		this.setState({
			readOnlyMSFormVisible: false,
			createNewMSFormVisible: true
		})
		dispatch(touch(form, `${field}.adresa.budova`))
		dispatch(touch(form, `${field}.druh`))
		dispatch(change(`${field}.druh`, this.getDruhMiestaSpotrebyDefaultOption()))
		dispatch(touch(form, `${field}.poschodie`))
		dispatch(touch(form, `${field}.cisloBytu`))
		if (get(komoditaTyp, 'id') == KOMODITA_TYP.PLYN) {
			dispatch(touch(form, `${field}.adresaSPPD`))
		}
	}

	formatSearchResult = (komoditaTyp) => {
		const { dispatch, field, form, searchResultsOM, searchResultsMS, value, change, t } = this.props

		const { showOnlyAvailable, searchedAddress } = this.state

		if (!get(searchResultsOM, 'isReady') || !get(searchResultsMS, 'isReady')) {
			return null
		}
		if (get(searchResultsOM, 'isLoading') || get(searchResultsMS, 'isLoading')) {
			return (
				<div className='cornered-loading' style={{ minHeight: '120px' }}>
					<ElementLoading />
				</div>
			)
		}
		if (get(searchResultsOM, 'isFailure') || get(searchResultsMS, 'isFailure')) {
			return (
				<div className='alert' data-color='red' style={{ width: 'auto', margin: '20px' }}>
					{t('translation:ZmluvneUcty.Pri vyhľadávaní miest spotreby nastala chyba')}
				</div>
			)
		}

		const odberneMiesta = groupBy(get(searchResultsOM, 'data', []), 'msCislo')
		const miestaSpotreby = groupBy(get(searchResultsMS, 'data', []), (miestoSpotreby) => {
			const adresa = get(miestoSpotreby, 'adresa')
			const obec = get(adresa, 'obec', '-')
			const ulica = get(adresa, 'ulica', '-')
			const orientacneCislo = get(adresa, 'orientacneCislo', '-')
			const supisneCislo = get(adresa, 'supisneCislo', '-')
			const psc = get(adresa, 'psc', '-')
			const budova = get(adresa, 'budova.nazov') || get(adresa, 'budova.id') || '-'
			return `${obec}-${ulica}-${orientacneCislo}-${supisneCislo}-${psc}-${budova}`
		})

		return (
			<div className='box-content'>
				<div style={{ padding: '0 10px 10px 10px', float: 'right' }}>
					<CheckboxField
						input={{
							value: showOnlyAvailable,
							onChange: () =>
								this.setState({
									showOnlyAvailable: !showOnlyAvailable
								})
						}}
						label='Zobraz iba voľné miesta spotreby'
					/>
				</div>
				<table className='search-results-table no-pointer' cellSpacing='0'>
					<tbody data-empty={t('translation:ZmluvneUcty.Nenašli sa žiadne výsledky')}>
						{orderBy(
							map(miestaSpotreby, (group, index) => (
								<MiestaSpotrebySearchRow
									checkRows
									showOnlyAvailable={showOnlyAvailable}
									key={`search-result-${index}`}
									selectedAdresaIdIsu={get(value, 'adresa.idIsu')}
									selectedMsCislo={get(value, 'cislo')}
									odberneMiesta={odberneMiesta}
									miestaSpotreby={group}
									komoditaTyp={komoditaTyp}
									onClick={() => {}}
									onClickCheckbox={(params) => {
										let selectedMs = null
										if (get(params, 'type') == 'MS') {
											const ms = get(params, 'data')
											if (get(value, 'cislo') != get(ms, 'cislo')) {
												selectedMs = {
													...ms
												}
											}
										} else if (get(params, 'type') == 'ADDRESS') {
											const adresa = get(params, 'data')
											if (get(value, 'adresa.idIsu') != get(adresa, 'idIsu')) {
												selectedMs = {
													adresa: {
														...adresa
													}
												}
											}
										}
										if (selectedMs) {
											if (get(value, 'adresa.id') == -1) {
												this.setState({
													searchedAddress: get(value, 'adresa')
												})
											}
											dispatch(change(field, selectedMs))
											this.setState({
												readOnlyMSFormVisible: !!get(selectedMs, 'cislo'),
												createNewMSFormVisible: !get(selectedMs, 'cislo')
											})
											dispatch(touch(form, `${field}.budova`))
											dispatch(touch(form, `${field}.druh`))
											dispatch(change(`${field}.druh`, this.getDruhMiestaSpotrebyDefaultOption()))
											dispatch(touch(form, `${field}.poschodie`))
											dispatch(touch(form, `${field}.cisloBytu`))
											if (get(komoditaTyp, 'id') == KOMODITA_TYP.PLYN) {
												dispatch(touch(form, `${field}.adresaSPPD`))
											}
										} else {
											dispatch(
												change(field, {
													adresa: searchedAddress
												})
											)
											this.setState({
												searchedAddress: null,
												readOnlyMSFormVisible: false,
												createNewMSFormVisible: false
											})
										}
									}}
									t={t}
								/>
							)),
							[
								(miestoSpotreby) => {
									return get(miestoSpotreby, 'adresa.budova.nazov') || get(miestoSpotreby, 'adresa.budova.id')
								}
							],
							['asc']
						)}
					</tbody>
				</table>
			</div>
		)
	}

	getDruhMiestaSpotrebyDefaultOption = () => {
		const { ciselniky } = this.props

		return find(get(ciselniky, 'miestoSpotrebyDruh', []), (druh) => {
			return get(druh, 'id') == DRUH_MIESTA_SPOTREBY_HLAVNE.BYTY
		})
	}

	formatLokalizaciaOptions = (selectedDruh) => {
		const { ciselniky } = this.props

		let options = []
		if (selectedDruh == DRUH_MIESTA_SPOTREBY_HLAVNE.BYTY) {
			options = map(
				filter(get(ciselniky, 'adresaIsuBudovaSkratka', []), (lokalizacia) => {
					return indexOf(LOKALIZACIA_TYP_BYTY, get(lokalizacia, 'id')) >= 0
				}),
				(lokalizacia) => ({
					value: lokalizacia,
					label: upperFirst(get(lokalizacia, 'nazov'))
				})
			)
		} else if (selectedDruh == DRUH_MIESTA_SPOTREBY_HLAVNE.RODINNY_DOM) {
			options = map(
				filter(get(ciselniky, 'adresaIsuBudovaSkratka', []), (lokalizacia) => {
					return indexOf(LOKALIZACIA_TYP_RODINNY_DOM, get(lokalizacia, 'id')) >= 0
				}),
				(lokalizacia) => ({
					value: lokalizacia,
					label: upperFirst(get(lokalizacia, 'nazov'))
				})
			)
		} else {
			options = map(LOKALIZACIA_TYP_OSTATNE, (group) => {
				return map(
					filter(get(ciselniky, 'adresaIsuBudovaSkratka', []), (lokalizacia) => {
						return indexOf(group, get(lokalizacia, 'id')) >= 0
					}),
					(lokalizacia) => ({
						value: lokalizacia,
						label: upperFirst(get(lokalizacia, 'nazov'))
					})
				)
			})
		}
		return options
	}

	formatDruhMiestaSpotrebyOptions = (hlavne) => {
		const { ciselniky } = this.props

		let filtered = []
		if (hlavne) {
			filtered = sortBy(
				filter(get(ciselniky, 'miestoSpotrebyDruh', []), (druh) => {
					return indexOf(HLAVNE_DRUH_MIESTA_SPOTREBY, get(druh, 'id')) >= 0
				}),
				[
					(druh) => {
						return indexOf(ORDER_DRUH_MIESTA_SPOTREBY, get(druh, 'id'))
					}
				]
			)
		} else {
			filtered = orderBy(
				filter(get(ciselniky, 'miestoSpotrebyDruh', []), (druh) => {
					return indexOf(HLAVNE_DRUH_MIESTA_SPOTREBY, get(druh, 'id')) < 0
				}),
				[(druh) => deburr(get(druh, 'nazov'))],
				['asc']
			)
		}

		return map(filtered, (druh) => ({
			label: upperFirst(get(druh, 'nazov')),
			value: druh
		}))
	}

	// NOTE: Duplikator vieme editovat iba v pripade, ze vytvarame novu adresu. V pripade vyberu adresu alebo PO needitujeme.
	formatLokalizaciaField = (value) => {
		const { field, t } = this.props

		const { lokalizaciaOptions } = this.state

		if (get(value, 'adresa.idIsu') || get(value, 'cislo')) {
			if (get(value, 'adresa.budova')) {
				return (
					<tr>
						<td>
							<strong>{t('translation:Common.Lokalizácia (skratka budovy)')}</strong>
						</td>
						<td>{get(value, 'adresa.budova.nazov') || get(value, 'adresa.id')}</td>
					</tr>
				)
			}
		} else {
			return (
				<tr>
					<td>
						<strong>{t('translation:Common.Lokalizácia (skratka budovy)')}</strong>
					</td>
					<td>
						<Field
							name={`${field}.adresa.budova`}
							component={RadioGroupField}
							placeholder={t('translation:Common.Zadajte lokalizáciu (skratku budovy)')}
							options={lokalizaciaOptions}
							compare={(option, val) => {
								return get(option, 'value.id') == get(val, 'id') && get(option, 'value.nazov') == get(val, 'nazov')
							}}
							validate={this.validateLokalizacia}
							editableOption
							editableOptionName={'id'}
							editableOptionType={RADIO_GROUP_FIELD_TYPE.TEXT}
							classNamePrefix='react-select'
						/>
					</td>
				</tr>
			)
		}
	}

	validateDruhMiestaSpotreby = (fieldValue) => {
		const { povinny, t } = this.props

		if (povinny) {
			if (fieldValue == null || fieldValue == undefined) {
				return t('translation:Common.validate.Druh miesta spotreby je povinný')
			}
		}
	}

	validateAdresa = (fieldValue) => {
		const { t } = this.props

		if (
			isEmpty(fieldValue) ||
			(get(fieldValue, 'id') == -1 &&
				(!get(fieldValue, 'stat') ||
					!get(fieldValue, 'obec') ||
					(!get(fieldValue, 'supisneCislo') && !get(fieldValue, 'orientacneCislo') && !get(fieldValue, 'parcelneCislo')) ||
					!get(fieldValue, 'psc')))
		) {
			return t('translation:Common.validate.Adresa je povinná')
		}
	}

	validatePoschodie = (fieldValue) => {
		const { t } = this.props

		if (fieldValue == null || fieldValue == undefined) {
			return null
		}

		const val = parseInt(fieldValue)
		if (isNaN(val) || val < 0 || val > 9999999999) {
			return t('translation:Common.validate.Označenie poschodia môže byť len kladné celé číslo dlhé najviac 10 znakov')
		}
	}

	validateLokalizacia = (fieldValue) => {
		const { searchResultsMS, t } = this.props

		if (get(fieldValue, 'id') && !get(fieldValue, 'nazov')) {
			if (size(get(fieldValue, 'id')) > 20) {
				return t('translation:Common.validate.Lokalizácia (skratka budovy) môže byť dlhá najviac 20 znakov')
			}
		}

		if (get(fieldValue, 'id')) {
			const valueId = toUpper(deburr(get(fieldValue, 'id')))
			const valueNazov = toUpper(deburr(get(fieldValue, 'nazov')))
			const existingLokalizacia = find(get(searchResultsMS, 'data', []), (miestoSpotreby) => {
				const budovaId = toUpper(deburr(get(miestoSpotreby, 'adresa.budova.id')))
				const budovaNazov = toUpper(deburr(get(miestoSpotreby, 'adresa.budova.nazov')))
				return (budovaId && valueId == budovaId) || (budovaNazov && (valueId == budovaNazov || valueNazov == budovaNazov))
			})
			if (existingLokalizacia) {
				return t('translation:Common.validate.Hodnota už existuje Zvoľte adresu zo zoznamu vyššie')
			}
		} else if (searchResultsMS.data.length && searchResultsMS.data.length > 0) {
			return t('translation:Common.validate.Hodnota už existuje Zvoľte adresu zo zoznamu vyššie')
		}
	}

	validateCisloMiestnosti = (fieldValue) => {
		const { t } = this.props

		const validCisloMiestnosti = new RegExp(/^[\w\d\s./\\]*$/gi)
		if (size(fieldValue) > 10) {
			return t('translation:Common.validate.Číslo miestnosti môže byť dlhé najviac 10 znakov')
		}
		if (fieldValue && !validCisloMiestnosti.test(fieldValue)) {
			return t('translation:Common.validate.Číslo miestnosti má nesprávny formát, povolené znaky sú a-Z, 0-9 a špeciálne znaky (medzera) / \\')
		}
	}

	validate = (value) => {
		const { povinny, t } = this.props

		if (povinny) {
			if (!value) {
				return t('translation:Common.validate.Miesto spotreby je povinné')
			}
			if (!get(value, 'adresa')) {
				return t('translation:Common.validate.Adresa je povinná')
			}
			if (!get(value, 'druh')) {
				return t('translation:Common.validate.Druh miesta spotreby je povinný')
			}
		}
	}

	render() {
		const { field, editMode, t, value, originalValue, vstupny, columnsCount, searchResultsOM, searchResultsMS, formValues } = this.props

		const komoditaTyp = get(formValues, 'data.odberneMiesto.komoditaTyp')

		const druhMiestaSpotrebyHlavneOptions = this.formatDruhMiestaSpotrebyOptions(true)
		const druhMiestaSpotrebyIneOptions = this.formatDruhMiestaSpotrebyOptions()

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			const adresaOriginalValue = formatAddressCityFirst(get(originalValue, 'adresa', null))

			const valueBefore = !vstupny && (
				<>
					<div>{get(originalValue, 'cislo', t('translation:Common.Nové MS'))}</div>
					<div>{adresaOriginalValue}</div>
					<div>
						{t('translation:Common.Druh MS')}: {get(originalValue, 'druh.nazov')}
					</div>
					{get(originalValue, 'adresa.budova') && (
						<div>
							{t('translation:Common.Lokalizácia')}: {get(originalValue, 'adresa.budova.nazov') || get(originalValue, 'adresa.budova.id')}
						</div>
					)}
					{get(originalValue, 'poschodie') && (
						<div>
							{t('translation:Common.Poschodie')}: {get(originalValue, 'poschodie')}
						</div>
					)}
					{get(originalValue, 'cisloBytu') && (
						<div>
							{t('translation:Common.Číslo miestnosti')}: {get(originalValue, 'cisloBytu')}
						</div>
					)}
				</>
			)

			const adresaValue = formatAddressCityFirst(get(value, 'adresa', null))

			const valueAfter = (
				<>
					<div>
						{columnsCount == COLUMNS_COUNT.THREE && !vstupny
							? formatFormValue(get(originalValue, 'cislo'), get(value, 'cislo'), get(value, 'cislo', t('translation:Common.Nové MS')))
							: get(value, 'cislo', t('translation:Common.Nové MS'))}
					</div>
					<div>{columnsCount == COLUMNS_COUNT.THREE && !vstupny ? formatFormValue(adresaOriginalValue, adresaValue) : adresaValue}</div>
					<div>
						{t('translation:Common.Druh MS')}:{' '}
						{columnsCount == COLUMNS_COUNT.THREE && !vstupny
							? formatFormValue(get(originalValue, 'druh.id'), get(value, 'druh.id'), get(value, 'druh.nazov'))
							: get(value, 'druh.nazov')}
					</div>
					{(get(originalValue, 'adresa.budova') || get(value, 'adresa.budova')) && (
						<div>
							{t('translation:Common.Lokalizácia')}:{' '}
							{columnsCount == COLUMNS_COUNT.THREE && !vstupny
								? formatFormValue(
										get(originalValue, 'adresa.budova.nazov') || get(originalValue, 'adresa.id'),
										get(value, 'adresa.budova.nazov') || get(value, 'adresa.budova.id')
								  )
								: get(value, 'adresa.budova.nazov') || get(value, 'adresa.budova.id')}
						</div>
					)}
					{(get(originalValue, 'poschodie') || get(value, 'poschodie')) && (
						<div>
							{t('translation:Common.Poschodie')}:{' '}
							{columnsCount == COLUMNS_COUNT.THREE && !vstupny
								? formatFormValue(get(originalValue, 'poschodie'), get(value, 'poschodie'))
								: get(value, 'poschodie')}
						</div>
					)}
					{(get(originalValue, 'cisloBytu') || get(value, 'cisloBytu')) && (
						<div>
							{t('translation:Common.Číslo miestnosti')}:{' '}
							{columnsCount == COLUMNS_COUNT.THREE && !vstupny
								? formatFormValue(get(originalValue, 'cisloBytu'), get(value, 'cisloBytu'))
								: get(value, 'cisloBytu')}
						</div>
					)}
				</>
			)

			return (
				<div className='inner-box'>
					<table className='content-table padded bordered' cellSpacing='0'>
						<tbody>
							<tr>
								<td>
									<strong>{t('translation:Common.Miesto spotreby')}</strong>
								</td>
								{columnsCount == COLUMNS_COUNT.THREE && !vstupny && <td>{valueBefore}</td>}
								<td colSpan={vstupny ? 2 : 1}>{valueAfter}</td>
							</tr>
						</tbody>
					</table>
				</div>
			)
		}

		const { readOnlyMSFormVisible, createNewMSFormVisible } = this.state

		let createMSBtn
		if (
			get(searchResultsOM, 'isReady') &&
			get(searchResultsMS, 'isReady') &&
			!get(searchResultsOM, 'isLoading') &&
			!get(searchResultsMS, 'isLoading') &&
			!createNewMSFormVisible &&
			!readOnlyMSFormVisible
		) {
			createMSBtn = (
				<button
					className='button'
					type='button'
					data-type='outline'
					data-color='blue'
					style={{ margin: '30px 0 20px 0' }}
					onClick={() => this.novaAdresaClickAction(komoditaTyp)}
				>
					{t('translation:Common.Nová adresa')}
				</button>
			)
		}

		// NOTE: EDIT_MODE == LOOKUP
		return (
			<>
				<div className='box'>
					<div className='box-content'>
						<table className='content-table padded bordered' cellSpacing='0'>
							<tbody>
								<tr>
									<td>
										<strong>{formatIdentifikatorTyp({ komoditaTyp }, t)}</strong>
									</td>
									<td>{get(formValues, 'data.odberneMiesto.identifikator')}</td>
								</tr>
								<tr>
									<td>
										<strong>{t('translation:Common.Adresa miesta spotreby')}</strong>
									</td>
									<td>
										<Field
											name={`${field}.adresa`}
											component={AddressSearchField}
											props={{
												source: ADDRESS_SOURCE.ZSEE
											}}
											onChange={() =>
												this.setState({
													createNewMSFormVisible: false
												})
											}
											parcelneCislo
											placeholder={t('translation:Common.Zadajte adresu')}
											disabled={!!get(value, 'adresa.idIsu')}
											validate={this.validateAdresa}
										/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					{this.formatSearchResult(komoditaTyp)}
					<Field name={field} validate={this.validate} component='input' type='hidden' />
					<div className='text-center'>
						<Tooltip
							html={
								<span>
									{t(
										'translation:Common.Vytvorí nové odberné miesto a nové miesto spotreby na novej adrese (prípojnom objekte) podľa vyššie zadaných adresných údajov'
									)}
								</span>
							}
							position='top'
							trigger='mouseenter'
							theme='light'
							distance='30'
						>
							{createMSBtn}
						</Tooltip>
					</div>
				</div>
				{createNewMSFormVisible && (
					<div className='box'>
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Druh miesta spotreby')}</strong>
										</td>
										<td>
											<Field
												name={`${field}.druh`}
												component={RadioGroupField}
												options={druhMiestaSpotrebyHlavneOptions}
												validate={this.validateDruhMiestaSpotreby}
												compare={(option, val) => {
													return get(option, 'value.id') == get(val, 'id')
												}}
												editableOption
												editableOptionType={RADIO_GROUP_FIELD_TYPE.SELECT}
												editableOptionOptions={druhMiestaSpotrebyIneOptions}
												classNamePrefix='react-select'
											/>
										</td>
									</tr>
									{this.formatLokalizaciaField(value)}
									<tr>
										<td>
											<strong>{t('translation:Common.Poschodie')}</strong>
										</td>
										<td>
											<Field
												name={`${field}.poschodie`}
												component={TextInputField}
												onKeyPress={(e) => {
													if (!/[0-9]/.test(e.key)) {
														e.preventDefault()
													}
												}}
												type='number'
												min={0}
												validate={this.validatePoschodie}
												placeholder={t('translation:Common.Zadajte poschodie')}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Číslo miestnosti')}</strong>
										</td>
										<td>
											<Field
												name={`${field}.cisloBytu`}
												component={TextInputField}
												validate={this.validateCisloMiestnosti}
												placeholder={t('translation:Common.Zadajte číslo miestnosti')}
											/>
										</td>
									</tr>
									{get(komoditaTyp, 'id') == KOMODITA_TYP.PLYN && (
										<tr>
											<td>
												<strong>{t('translation:Common.Adresa SPP-D')}</strong>
											</td>
											<td>
												<Field
													name={`${field}.adresaSPPD`}
													component={AddressSearchField}
													props={{
														source: ADDRESS_SOURCE.SPPD
													}}
													placeholder={t('translation:Common.Zadajte adresu')}
													validate={this.validateAdresa}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				)}
				{readOnlyMSFormVisible && (
					<div className='box'>
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									{get(value, 'druh') && (
										<tr>
											<td>
												<strong>{t('translation:Common.Druh miesta spotreby')}</strong>
											</td>
											<td>{get(value, 'druh.nazov')}</td>
										</tr>
									)}
									{get(value, 'adresa.budova') && (
										<tr>
											<td>
												<strong>{t('translation:Common.Lokalizácia (skratka budovy)')}</strong>
											</td>
											<td>{get(value, 'adresa.budova.nazov') || get(value, 'adresa.budova.id')}</td>
										</tr>
									)}
									{get(value, 'poschodie') && (
										<tr>
											<td>
												<strong>{t('translation:Common.Poschodie')}</strong>
											</td>
											<td>{get(value, 'poschodie')}</td>
										</tr>
									)}
									{get(value, 'cisloBytu') && (
										<tr>
											<td>
												<strong>{t('translation:Common.Číslo miestnosti')}</strong>
											</td>
											<td>{get(value, 'cisloBytu')}</td>
										</tr>
									)}
									{get(komoditaTyp, 'id') == KOMODITA_TYP.PLYN && (
										<tr>
											<td>
												<strong>{t('translation:Common.Adresa SPP-D')}</strong>
											</td>
											<td>
												<Field
													name={`${field}.adresaSPPD`}
													component={AddressSearchField}
													props={{
														source: ADDRESS_SOURCE.SPPD
													}}
													placeholder={t('translation:Common.Zadajte adresu')}
													validate={this.validateAdresa}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	ciselniky: get(state, 'ciselniky.data'),
	statuses: get(state, 'statuses.statuses'),
	addresses: get(state, 'formAddresses.data'),
	searchResultsOM: get(state, 'odberneMiesta.searchResults'),
	searchResultsMS: get(state, 'miestaSpotreby.searchResults')
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	odberneMiestaActions: bindActionCreators(OdberneMiestaActions, dispatch),
	miestaSpotrebyActions: bindActionCreators(MiestaSpotrebyActions, dispatch),
	statusActions: bindActionCreators(StatusActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(MiestoSpotrebyField)
