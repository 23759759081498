import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { get } from 'lodash'
import { Link } from 'react-router-dom'

// actions
import * as HistoriaSuhlasuActions from '../../actions/ObchodniPartneri/HistoriaSuhlasu'

// utils
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { setRouteParams, ZOZNAM_SUHLASOV } from '../../utils/routes'

// components
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import UdelenySuhlas from '../../components/SpravaSuhlasov/UdelenySuhlas'

// resources

class HistoriaSuhlasuPage extends React.Component {
	static propTypes = {
		t: PropTypes.func,
		auth: PropTypes.shape(),
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		historiaSuhlasu: PropTypes.shape(),
		computedMatch: PropTypes.shape({
			params: PropTypes.shape({
				definiciaId: PropTypes.any.isRequired
			}).isRequired
		}).isRequired
	}

	constructor(props) {
		super(props)

		this.state = {}
	}

	_mounted = false

	componentDidMount() {
		const { historiaSuhlasuActions, auth, computedMatch } = this.props
		const { params } = computedMatch

		// TODO: Check if I shoud use id or name of businessChannel (CES ciselnik doesnt match BB ciselnik)
		const activeBusinessChannel = get(auth, 'businessChannel.actual.id')
		historiaSuhlasuActions.loadHistoriaSuhlasu(params.definiciaId, activeBusinessChannel)
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate() {}

	commonContentContainer = (content) => {
		return <div className='box-content'>{content}</div>
	}

	render() {
		const { t, interakcia, historiaSuhlasu } = this.props

		if (!historiaSuhlasu || historiaSuhlasu?.isLoading) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (historiaSuhlasu.isFailure) {
			return this.commonContentContainer(<ElementFailure text={t('containers:HistoriaSuhlasuPage.Nepodarilo sa načítať históriu vyjadrení')} />)
		}

		if (historiaSuhlasu.isPrepared === false) {
			return this.commonContentContainer(<ElementLoading />)
		}

		// TODO: Nemal by to byt array
		const suhlasDetail = historiaSuhlasu?.data[0]
		const udeleneSuhlasy = []

		if (suhlasDetail) {
			suhlasDetail.suhlasVerzia.forEach((verziaSuhlasu) => {
				verziaSuhlasu.suhlas.forEach((udelenySuhlas) => {
					udeleneSuhlasy.push({ ...udelenySuhlas, verzia: verziaSuhlasu.verzia })
				})
			})
		}

		if (udeleneSuhlasy.length === 0) {
			return this.commonContentContainer(<ElementFailure text={t('containers:HistoriaSuhlasuPage.História vyjadrení je prázdna')} />)
		}

		return (
			<>
				<div className='content-header'>
					<div className='header-row clearfix'>
						<Link to={setRouteParams(ZOZNAM_SUHLASOV, interakcia?.opCislo)} className='button pull-left' data-type='back-button' data-color='blue'>
							{t('translation:Common.Späť')}
						</Link>
						<div className='header-title pull-left'>
							<span style={{ fontWeight: 400 }}>{suhlasDetail.nazov}:</span> {t('translation:Suhlasy.História vyjadrení')}
						</div>
					</div>
				</div>
				<div className='content-wrapper'>
					<div className='box consent-history'>
						<div className='consent-history-header'>{t('translation:Suhlasy.Posledné vyjadrenia')}</div>
						<div className='consent-history-body'>
							{udeleneSuhlasy.map((udelenySuhlas) => {
								return (
									<div className='consent-last-statement-container'>
										<UdelenySuhlas udelenySuhlas={udelenySuhlas} />
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	interakcia: state.interakcie.detail.data,
	historiaSuhlasu: state.obchodnyPartner.historiaSuhlasu
})

const mapDispatchToProps = (dispatch) => ({
	historiaSuhlasuActions: bindActionCreators(HistoriaSuhlasuActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_SUHLASY])
)(HistoriaSuhlasuPage)
