import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link } from 'react-router-dom'
import { map, get } from 'lodash'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

// components
import ObchodnyPartnerBankoveUctyTableRow from '../../components/TableRows/BankoveUctyTableRow'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'

// utils
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { setRouteParams, OP_DETAIL } from '../../utils/routes'

class BankoveUctyPage extends React.Component {
	static propTypes = {
		obchodnyPartnerActions: PropTypes.shape(),
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				opCislo: PropTypes.string.isRequired
			})
		}).isRequired,
		ciselniky: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		bankoveUcty: PropTypes.shape()
	}

	componentDidMount() {
		const { obchodnyPartnerActions } = this.props

		obchodnyPartnerActions.loadBankoveUcty(1)
	}

	loadMoreBankoveSpojenia = () => {
		const { bankoveUcty, obchodnyPartnerActions } = this.props
		const nextPage = bankoveUcty.page + 1

		obchodnyPartnerActions.loadBankoveUcty(nextPage)
	}

	render() {
		const { interakcia, bankoveUcty, ciselniky, t } = this.props

		const bankoveSpojeniaTableRows = map(get(bankoveUcty, 'data'), (bankovyUcet) => {
			return (
				<ObchodnyPartnerBankoveUctyTableRow
					key={get(bankovyUcet, 'id')}
					{...bankovyUcet}
					ciselniky={ciselniky}
					opCislo={interakcia.data.opCislo}
					t={t}
				/>
			)
		})

		const loadMoreBtnText = get(bankoveUcty, 'isLoading') ? t('containers:BankoveUctyPage.Načítavam') : t('containers:BankoveUctyPage.Načítať ďalšie')

		return (
			<>
				<div className='content-header'>
					<div className='header-row clearfix'>
						<Link
							to={setRouteParams(OP_DETAIL, get(interakcia, 'data.opCislo'))}
							className='button pull-left'
							data-type='back-button'
							data-color='blue'
						>
							{t('containers:BankoveUctyPage.Späť')}
						</Link>
						<div className='header-title pull-left'>{t('containers:BankoveUctyPage.Zoznam bankových spojení')}</div>
					</div>
				</div>

				<div className='content-wrapper'>
					<div className='row'>
						<div className='col-12'>
							<div className='box'>
								<div className='box-content'>
									<table id='partner-accounts-table' className='content-table bordered hoverable' cellSpacing='0'>
										<thead>
											<tr>
												<th>{t('containers:BankoveUctyPage.Banka')}</th>
												<th>{t('containers:BankoveUctyPage.IBAN')}</th>
												<th>{t('containers:BankoveUctyPage.Zdroj dát')}</th>
												<th style={{ textAlign: 'center' }}>{t('containers:BankoveUctyPage.Stav')}</th>
												<th />
											</tr>
										</thead>
										<tbody>{bankoveSpojeniaTableRows}</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					{!get(bankoveUcty, 'isLastPage') && (
						<div className='text-center' style={{ marginTop: '20px' }}>
							<div className='button' data-type='outline' data-color='blue' onClick={this.loadMoreBankoveSpojenia}>
								{loadMoreBtnText}
							</div>
						</div>
					)}
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		interakcia: state.interakcie.detail,
		bankoveUcty: state.obchodnyPartner.bankoveUcty,
		ciselniky: state.ciselniky.data
	}
}

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_OP_ZMLUVY])
)(BankoveUctyPage)
