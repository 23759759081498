import dayjs from 'dayjs'
import { get } from 'lodash'

function validDates(startDayjsObj, endDayjsObj) {
	return startDayjsObj.isValid() && endDayjsObj.isValid()
}

function formatTimeOptionFromDayjsObj(dayjsObj) {
	const hour = dayjsObj.hour()
	const minute = dayjsObj.minute()

	return {
		value: `0${hour}`.slice(-2) + `0${minute}`.slice(-2),
		HH: `0${hour}`.slice(-2),
		MM: `0${minute}`.slice(-2),
		hh: hour === 0 ? '12' : (hour === 12 ? '12' : hour > 12 ? `0${hour - 12}` : `0${hour}`).slice(-2),
		mm: `0${minute}`.slice(-2),
		active: true,
		period: hour >= 12 ? 'PM' : 'AM'
	}
}

function generateTimeIncrement(minIncrementProp, startDayjsObj = dayjs().startOf('day'), endDayjsObj = dayjs().endOf('day')) {
	// Create an array of all possible times that can be selected
	const timeArray = []
	let tempDayjsObj = startDayjsObj.clone()

	while (tempDayjsObj <= endDayjsObj) {
		timeArray.push(formatTimeOptionFromDayjsObj(tempDayjsObj))
		tempDayjsObj = tempDayjsObj.add(minIncrementProp, 'minutes')
	}

	return timeArray
}

function calculateRoundedTimeValue(dayjsObj, minIncrementProp) {
	// If we receive a dayjs value, find nearest time increment
	const roundedTime = Math.round((dayjsObj.hour() * 60 + dayjsObj.minute()) / minIncrementProp) * minIncrementProp
	return `0${Math.floor(roundedTime / 60)}`.slice(-2) + `0${roundedTime % 60}`.slice(-2)
}

export function generateTimeObjects(props) {
	const {
		input: { value },
		minuteIncrement,
		startRange,
		endRange
	} = props

	let startTimeDayjsObj
	let endTimeDayjsObj
	let startTimeIncrement
	let endTimeIncrement
	let startTimeValue
	let endTimeValue
	let error

	let startDayjsObject = dayjs(get(value, 'startTime'))
	let endDayjsObject = dayjs(get(value, 'endTime'))

	// Check if two dayjs objects are valid
	if (validDates(startDayjsObject, endDayjsObject)) {
		startTimeDayjsObj = startDayjsObject.set('second', 0).set('millisecond', 0)
		endTimeDayjsObj = endDayjsObject.set('second', 0).set('millisecond', 0)
	} else {
		startTimeDayjsObj = dayjs().set('hour', 8)
		endTimeDayjsObj = dayjs().set('hour', 10)
	}
	startTimeValue = calculateRoundedTimeValue(startTimeDayjsObj, minuteIncrement)
	endTimeValue = calculateRoundedTimeValue(endTimeDayjsObj, minuteIncrement)

	// Set our dayjs objects hours and minutes to the rounded time value
	const startHours = startTimeValue.substring(0, 2)
	const startMinutes = startTimeValue.substring(2, 4)
	startDayjsObject = startDayjsObject.hour(startHours).minute(startMinutes).second(0).millisecond(0)

	const endHours = endTimeValue.substring(0, 2)
	const endMinutes = endTimeValue.substring(2, 4)
	endDayjsObject = endDayjsObject.hour(endHours).minute(endMinutes).second(0).millisecond(0)

	// Calculate time increments
	startTimeIncrement = generateTimeIncrement(minuteIncrement, startRange, endRange)
	endTimeIncrement = generateTimeIncrement(minuteIncrement, startRange, endRange)

	// Return times back to the select object
	return {
		startTimeIncrement,
		endTimeIncrement,
		startTimeValue,
		endTimeValue,
		error
	}
}

export function manipulateTimeObject(dayjsObject, newTimeValue) {
	const hours = newTimeValue.substring(0, 2)
	const minutes = newTimeValue.substring(2, 4)
	let time = dayjs(dayjsObject).hour(hours).minute(minutes).second(0)
	return time.toISOString()
}
