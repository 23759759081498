import { get } from 'lodash'
import { getReq } from '../../utils/request'
import { PLATBA_DETAIL_LOAD_START, PLATBA_DETAIL_LOAD_DONE, PLATBA_DETAIL_LOAD_FAIL } from '../../types/platbyTypes'

export const loadPlatbaDetail = (platbaCislo) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: PLATBA_DETAIL_LOAD_START
		})

		try {
			const platbaDetailData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/platby/${platbaCislo}`)
			dispatch({
				type: PLATBA_DETAIL_LOAD_DONE,
				payload: {
					detail: get(platbaDetailData, 'response.content', null)
				}
			})
			return platbaDetailData
		} catch (e) {
			dispatch({
				type: PLATBA_DETAIL_LOAD_FAIL
			})
		}
	}
}
