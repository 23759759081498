import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { NumericFormat } from 'react-number-format'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'

// utils
import { formatFormValue } from '../../utils/form'
import { formatDate } from '../../utils/date'
import { getContexForRozpisyZaloh } from '../../utils/rozpisyZaloh'
import { formatAddress } from '../../utils/address'

// components
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'

class UkonZmenaRozpisovZaloh extends React.Component {
	componentDidMount() {
		const { obchodnyPartnerActions } = this.props
		obchodnyPartnerActions.loadAllOdberneMiesta(0)
	}

	render() {
		const { t, ukonData, ukonDataPred, dokumenty, formatDocuments, odberneMiesta } = this.props

		if (odberneMiesta.isLoading || !odberneMiesta.allDataPrepared) {
			return this.loadingContentElement()
		}
		if (odberneMiesta.isFailure) {
			return this.failureContentElement()
		}

		const rozpisZalohDTO = {
			zmluvnyVztahCislo: get(ukonData, 'zmluvnyVztahCislo'),
			zmluvnyUcetCislo: get(ukonData, 'zmluvnyUcetCislo')
		}
		const data = getContexForRozpisyZaloh(rozpisZalohDTO, odberneMiesta.allData)

		const rozpisZalohDTOPred = {
			zmluvnyVztahCislo: get(ukonDataPred, 'zmluvnyVztahCislo'),
			zmluvnyUcetCislo: get(ukonDataPred, 'zmluvnyUcetCislo')
		}
		const dataPred = getContexForRozpisyZaloh(rozpisZalohDTOPred, odberneMiesta.allData)

		const miestoSpotrebyString = `${get(data, 'miestoSpotreby.cislo')} - ${formatAddress(get(data, 'miestoSpotreby.adresa'))}`
		const miestoSpotrebyPredString = `${get(dataPred, 'miestoSpotreby.cislo')} - ${formatAddress(get(dataPred, 'miestoSpotreby.adresa'))}`

		const zalohyNeznizovatSumuPred =
			get(ukonDataPred, 'zalohyNeznizovatSumu', null) != null
				? get(ukonDataPred, 'zalohyNeznizovatSumu', null)
					? t('containers:UkonDetailPage.áno')
					: t('containers:UkonDetailPage.nie')
				: null
		const zalohyNeznizovatSumu =
			get(ukonData, 'zalohyNeznizovatSumu', null) != null
				? get(ukonData, 'zalohyNeznizovatSumu', null)
					? t('containers:UkonDetailPage.áno')
					: t('containers:UkonDetailPage.nie')
				: null

		return (
			<tbody>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Číslo zmluvného účtu')}</strong>
					</td>
					<td>{get(ukonDataPred, 'zmluvnyUcetCislo')}</td>
					<td>{formatFormValue(get(ukonData, 'zmluvnyUcetCislo'), get(ukonDataPred, 'zmluvnyUcetCislo'))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Číslo zmluvy')}</strong>
					</td>
					<td>{get(ukonDataPred, 'zmluvnyVztahCislo')}</td>
					<td>{formatFormValue(get(ukonData, 'zmluvnyVztahCislo'), get(ukonDataPred, 'zmluvnyVztahCislo'))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Číslo rozpisu záloh')}</strong>
					</td>
					<td>{get(ukonDataPred, 'rozpisZalohCislo')}</td>
					<td>{formatFormValue(get(ukonData, 'rozpisZalohCislo'), get(ukonDataPred, 'rozpisZalohCislo'))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Miesto spotreby')}</strong>
					</td>
					<td>{miestoSpotrebyPredString}</td>
					<td>{formatFormValue(miestoSpotrebyString, miestoSpotrebyPredString)}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Komodita')}</strong>
					</td>
					<td>{get(dataPred, 'odberneMiesto.komoditaTyp.nazov')}</td>
					<td>{formatFormValue(get(data, 'odberneMiesto.komoditaTyp.nazov'), get(dataPred, 'odberneMiesto.komoditaTyp.nazov'))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Výška preddavkových platieb')}</strong>
					</td>
					<td>
						<NumericFormat
							thousandSeparator={' '}
							decimalSeparator={','}
							decimalScale={2}
							displayType='text'
							suffix=' €'
							value={get(ukonDataPred, 'zalohySuma')}
							defaultValue='-'
						/>
					</td>
					<td>
						{formatFormValue(
							get(ukonData, 'zalohySuma'),
							get(ukonDataPred, 'zalohySuma'),
							<NumericFormat
								thousandSeparator={' '}
								decimalSeparator={','}
								decimalScale={2}
								displayType='text'
								suffix=' €'
								value={get(ukonData, 'zalohySuma')}
								defaultValue='-'
							/>
						)}
					</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Neznižovať výšku záloh')}</strong>
					</td>
					<td>{zalohyNeznizovatSumuPred}</td>
					<td>{formatFormValue(zalohyNeznizovatSumu, zalohyNeznizovatSumuPred)}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Cyklus preddavkových platieb')}</strong>
					</td>
					<td>{get(ukonDataPred, 'zalohyCyklus.nazov')}</td>
					<td>{formatFormValue(get(ukonData, 'zalohyCyklus.nazov'), get(ukonDataPred, 'zalohyCyklus.nazov'))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Deň splatnosti záloh')}</strong>
					</td>
					<td>{get(ukonDataPred, 'zalohyDenSplatnosti.nazov')}</td>
					<td>{formatFormValue(get(ukonData, 'zalohyDenSplatnosti.nazov'), get(ukonDataPred, 'zalohyDenSplatnosti.nazov'))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('containers:UkonDetailPage.Dátum požadovanej zmeny')}</strong>
					</td>
					<td>{formatDate(get(ukonDataPred, 'datumZmeny'), null, 'DD.MM.YYYY')}</td>
					<td>
						{formatFormValue(
							formatDate(get(ukonData, 'datumZmeny'), null, 'DD.MM.YYYY'),
							formatDate(get(ukonDataPred, 'datumZmeny'), null, 'DD.MM.YYYY')
						)}
					</td>
				</tr>
				<tr>
					<td colSpan={3}>{formatDocuments(dokumenty)}</td>
				</tr>
			</tbody>
		)
	}

	commonContentContainer = (content) => {
		return <>{content}</>
	}

	loadingContentElement = () => {
		return this.commonContentContainer(
			<tbody>
				<tr>
					<td colSpan='3'>
						<ElementLoading />
					</td>
				</tr>
			</tbody>
		)
	}

	failureContentElement = () => {
		const { t } = this.props
		return this.commonContentContainer(
			<tbody>
				<tr>
					<td colSpan='3'>
						<ElementFailure text={t('containers:UkonDetailPage.Nepodarilo sa načítať odberné miesta')} />
					</td>
				</tr>
			</tbody>
		)
	}
}

UkonZmenaRozpisovZaloh.propTypes = {
	ukon: PropTypes.shape(),
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	dokumenty: PropTypes.array,
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired,
	obchodnyPartnerActions: PropTypes.shape().isRequired,
	odberneMiesta: PropTypes.shape()
}

const mapStateToProps = (state) => ({
	odberneMiesta: state.obchodnyPartner.odberneMiesta
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(UkonZmenaRozpisovZaloh)
