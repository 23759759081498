import React from 'react'
import { compose, bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { get, set, head, filter, map, find, includes, isEmpty /* , maxBy */, upperFirst, unescape } from 'lodash'
import { initialize, reset, destroy, change, touch, getFormValues } from 'redux-form'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

// components
import SystemoveListyUkonInfo from '../../components/SystemoveListyUkon/SystemoveListyUkonInfo/SystemoveListyUkonInfo'
import SystemoveListyUkonText from '../../components/SystemoveListyUkon/SystemoveListyUkonText/SystemoveListyUkonText'
import SystemoveListyUkonConfirm from '../../components/SystemoveListyUkon/SystemoveListyUkonConfirm'
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementLoading from '../../components/ElementLoading'
import ElementEmptyContent from '../../components/ElementEmptyContent'
/* import initProcesnyKonfigurator from '../../components/ProcesnyKonfigurator' */

// actions
import * as TrackingActions from '../../actions/TrackingActions'
import * as UkonyActions from '../../actions/UkonyActions'
import * as CiselnikySelectBoxActions from '../../actions/CiselnikySelectBoxActions'
import PodpisovanieDokumentovActions from '../../actions/PodpisovanieDokumentov'
import * as DataActions from '../../actions/DataActions'
import * as FiltersActions from '../../actions/SelectedFiltersActions'
import * as StatusActions from '../../actions/StatusActions'
import * as SablonyActions from '../../actions/SablonyActions'

// utils
import { postReq, deleteReq, putReq } from '../../utils/request'
import { BUSINESS_CHANNELS, SCENARE, NOTIFICATION_TYPES, FORMS, UKONY, UKONY_CISELNIK, FILTER_SELECTORS, DOKUMENT_TYP } from '../../utils/enums'
import { setRouteParams, PREHLAD } from '../../utils/routes'
import { history } from '../../utils/history'
import { createFormInitValues } from '../../utils/form'
import { getUkonVstupByKanal } from '../../utils/scenar'
import { withPermissions, checkPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { formatHTML } from '../../utils/systemoveListy'

const scenarioOptions = [
	{
		kanal: BUSINESS_CHANNELS.ZSE_CENTRUM,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS]
	},
	{
		kanal: BUSINESS_CHANNELS.ZAKAZNICKA_LINKA,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS]
	},
	{
		kanal: BUSINESS_CHANNELS.BACK_OFFICE,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS]
	}
]

class SystemoveListyUkonPage extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		interakcia: PropTypes.shape({
			id: PropTypes.any.isRequired
		}).isRequired,
		auth: PropTypes.shape().isRequired,
		trackingActions: PropTypes.shape().isRequired,
		ukonyActions: PropTypes.shape({
			clearUkonId: PropTypes.func.isRequired
		}).isRequired,
		ciselnikySelectBoxActions: PropTypes.shape().isRequired,
		podpisovanieDokumentovActions: PropTypes.shape({
			resetTemplatePdf: PropTypes.func.isRequired
		}).isRequired,
		dataActions: PropTypes.shape().isRequired,
		filtersActions: PropTypes.shape().isRequired,
		statusActions: PropTypes.shape().isRequired,
		tracking: PropTypes.shape().isRequired,
		ukonNovy: PropTypes.shape().isRequired,
		ciselniky: PropTypes.shape().isRequired,
		ciselnikySelectBox: PropTypes.shape().isRequired,
		signedPdf: PropTypes.shape(),
		notification: PropTypes.shape(),
		templatePdf: PropTypes.shape(),
		/* procesnyKonfigurator: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}).isRequired, */
		statuses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		t: PropTypes.func.isRequired,
		formValues: PropTypes.shape(),
		selectedFiltersUkony: PropTypes.shape()
	}

	_mounted = false

	constructor(props) {
		super(props)

		/* const ProcesnyKonfigurator = initProcesnyKonfigurator(FORMS.VSEOBECNY_UKON_AKTIVNY, 'vseobecnyUkonAktivny') */

		// filter possible scenars for signing pdf files
		const scenarioOption = filter(scenarioOptions, (scenarioOption) => {
			return scenarioOption.kanal == get(props, 'auth.businessChannel.actual.id')
		})

		this.state = {
			step: 1,
			scenarioOption: head(scenarioOption),
			isLoading: true,
			/* ProcesnyKonfigurator,
			koKriteria: [],
			isKoKriteriumChecked: false,
			validacneKriteria: [],
			schvalovacieKriteria: [],
			context: null */
			sablona: '',
			sablony: [],
			sablonaChangeDialog: false,
			vstupneDokumentyDialog: false,
			inputsNotFilledDialog: false
		}
	}

	async componentDidMount() {
		this._mounted = true

		const { dispatch, auth, trackingActions, dataActions, ciselnikySelectBoxActions, sablonyActions, tracking, ciselniky, t } = this.props

		ciselnikySelectBoxActions.loadCiselnikMiestaSpotreby()
		dataActions.registerLeavePageModal()

		if (!tracking.startTime || (tracking.startTime && tracking.type !== UKONY.SYSTEMOVE_LISTY)) {
			trackingActions.tryToStartTracking(UKONY.SYSTEMOVE_LISTY)
		}

		const initValues = createFormInitValues({
			ukonVstup: getUkonVstupByKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'ukonVstup')),
			datumPrijatiaZiadosti: new Date(),
			// podpisMiesto: get(auth, 'user.podpisMiesto', ''),
			msCislo: null,
			kategoria1: null,
			kategoria2: null,
			kategoria3: null,
			kategoria4: null,
			znacka: null,
			sablona: '',
			odpovedText: null,
			step: 1
		})

		dispatch(initialize(FORMS.SYSTEMOVE_LISTY, initValues, true))
		dispatch(reset(FORMS.SYSTEMOVE_LISTY))

		if (!get(initValues, 'ukonVstup')) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.SYSTEMOVE_LISTY, 'ukonVstup'))
		}

		/* if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(get(initValues, 'dokumenty'))) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.SYSTEMOVE_LISTY, 'dokumenty'))
		} */

		dispatch(touch(FORMS.SYSTEMOVE_LISTY, 'kategoria1'))

		/* dispatch(touch(FORMS.SYSTEMOVE_LISTY, 'kategoria2'))
		dispatch(touch(FORMS.SYSTEMOVE_LISTY, 'kategoria3'))
		dispatch(touch(FORMS.SYSTEMOVE_LISTY, 'kategoria4')) */

		const sablony = await sablonyActions.loadSablony()

		this.setState({
			sablony,
			sablonyOptions: [
				{
					label: t('translation:SystemoveListyUkon.Prázdna šablóna'),
					value: ''
				},
				...map(sablony, (sablona) => ({
					label: upperFirst(get(sablona, 'nazov')),
					value: get(sablona, 'id')
				}))
			],
			isLoading: false
		})
	}

	componentWillUnmount() {
		const { dispatch, trackingActions, ukonyActions, dataActions } = this.props

		dispatch(destroy(FORMS.SYSTEMOVE_LISTY))

		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()

		// clear ukonId from storage
		ukonyActions.clearUkonId()

		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		const { dispatch, formValues } = this.props
		const { sablony } = this.state

		if (get(formValues, 'sablona') != get(prevProps, 'formValues.sablona')) {
			const sablona = find(sablony, (sablona) => get(sablona, 'id') == get(formValues, 'sablona'))
			const odpovedText = unescape(get(sablona, 'obsah')).replace(/\[\[/g, '<span class="editor-placeholder">[[').replace(/]]/g, ']]</span>')
			dispatch(change(FORMS.SYSTEMOVE_LISTY, 'odpovedText', odpovedText || null))
		}
	}

	formatDataForUkon = (values) => {
		const { interakcia, auth, tracking, ciselniky } = this.props

		/* const {
			schvalovacieKriteria
		} = this.state */

		const typ = find(get(ciselniky, 'ukonTyp', []), (ukonTyp) => get(ukonTyp, 'id') == UKONY_CISELNIK.SYSTEMOVE_LISTY)
		const vstup = find(get(ciselniky, 'ukonVstup', []), (vstup) => get(vstup, 'id') == get(values, 'ukonVstup'))

		const schvalovaciRezim = null
		/* if (!isEmpty(schvalovacieKriteria)) {
			const maxUrovenSchvalovania = get(
				maxBy(
					filter(schvalovacieKriteria, (schvalovacieKriterium) => !schvalovacieKriterium.vysledok), 'urovenSchvalovania'
				), 'urovenSchvalovania', 0
			)
			const schvalovacieKriteriaNormalize = map(schvalovacieKriteria, (schvalovacieKriterium) => ({
				id: schvalovacieKriterium.nazov,
				vysledok: schvalovacieKriterium.vysledok,
				dovod: schvalovacieKriterium.popis
			}))
			schvalovaciRezim = {
				kod: maxUrovenSchvalovania,
				pravidla: schvalovacieKriteriaNormalize
			}
		} */

		const kategoria1 = find(get(ciselniky, 'sapKontaktKategoria1', []), (kategoria) => get(kategoria, 'id') == get(values, 'kategoria1')) || null
		const kategoria2 = find(get(ciselniky, 'sapKontaktKategoria2', []), (kategoria) => get(kategoria, 'id') == get(values, 'kategoria2')) || null
		const kategoria3 = find(get(ciselniky, 'sapKontaktKategoria3', []), (kategoria) => get(kategoria, 'id') == get(values, 'kategoria3')) || null
		const kategoria4 = find(get(ciselniky, 'sapKontaktKategoria4', []), (kategoria) => get(kategoria, 'id') == get(values, 'kategoria4')) || null

		return {
			typ,
			opCislo: get(interakcia, 'opCislo'),
			interakciaId: get(interakcia, 'id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
			zacatyOd: dayjs(tracking.startTime).toISOString(),
			vstup,
			// podpisMiesto: get(values, 'podpisMiesto'),
			poznamka: get(values, 'poznamka'),
			ziadanyOd: dayjs(get(values, 'datumPrijatiaZiadosti')).toISOString(),
			splnomocnenec: get(values, 'splnomocnenec'),
			data: {
				schvalovaciRezim,
				msCislo: get(values, 'msCislo', null),
				kategoria1,
				kategoria2,
				kategoria3,
				kategoria4,
				znacka: get(values, 'znacka', null),
				sablona: get(values, 'sablona', null) || null,
				odpovedText: formatHTML(get(values, 'odpovedText'))
			}
		}
	}

	setLoading = (value) => {
		if (this._mounted) {
			this.setState({
				isLoading: value
			})
		}
	}

	onDeleteFile = async (fileID) => {
		const { ukonNovy } = this.props

		try {
			await deleteReq(`/api/v0/ukony/${ukonNovy.id}/dokumenty/${fileID}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	stepOneClickHandler = async (values) => {
		if (isEmpty(get(values, 'dokumenty', []))) {
			this.setState({
				vstupneDokumentyDialog: true
			})
		} else {
			this.continueToStepTwo()
		}
	}

	continueToStepTwo = () => {
		const { dispatch } = this.props
		this.setState({
			step: 2,
			isLoading: false,
			vstupneDokumentyDialog: false
		})
		dispatch(change(FORMS.SYSTEMOVE_LISTY, 'step', 2))
		dispatch(touch(FORMS.SYSTEMOVE_LISTY, 'odpovedText'))
	}

	stepTwoClickHandler = (values) => {
		const body = this.formatDataForUkon(values)
		if (body.data.odpovedText.indexOf('editor-placeholder') >= 0) {
			this.setState({
				inputsNotFilledDialog: true
			})
		} else {
			this.continueToStepThree(values)
		}
	}

	continueToStepThree = async (values) => {
		const { dispatch, interakcia, ukonyActions, tracking, ukonNovy } = this.props

		this.setLoading(true)

		const body = this.formatDataForUkon(values)

		try {
			let ukonId
			// if ukon not exist yet create a new one else update it
			if (!ukonNovy.id) {
				const res = await postReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ukony`, null, body)

				// set podpis Miesto from response CP-588
				// const podpisMiesto = get(res, 'response.content.podpisMiesto')
				// dispatch(change(FORMS.SYSTEMOVE_LISTY, 'podpisMiesto', podpisMiesto))

				ukonId = get(res, 'response.content.id')
				ukonyActions.setUkonId(ukonId)
			} else {
				ukonId = ukonNovy.id
				const updateBody = {
					...body,
					trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
					id: ukonId
				}
				await putReq(`/api/v2/ukony/${ukonId}`, null, updateBody)
			}

			// dokumenty which are not upload does not have ID
			const notUploadedDokumenty = filter(get(values, 'dokumenty', []), (dokument) => !get(dokument, 'id'))
			const uploadedDokumenty = filter(get(values, 'dokumenty', []), (dokument) => get(dokument, 'id'))
			const notUploadedPrilohy = filter(get(values, 'prilohy', []), (dokument) => !get(dokument, 'id'))
			const uploadedPrilohy = filter(get(values, 'prilohy', []), (dokument) => get(dokument, 'id'))

			const dokumenty = map(notUploadedDokumenty, async (file) => {
				const dokumentResponse = await postReq(`/api/v0/ukony/${ukonId}/prilozit-dokument`, null, {
					contentType: file.type,
					nazov: file.name,
					data: file.dataAsBase64,
					typ: {
						id: DOKUMENT_TYP.VSTUPNY
					}
				})
				return {
					type: get(dokumentResponse, 'response.contentType'),
					id: get(dokumentResponse, 'response.id'),
					dataAsBase64: get(file, 'dataAsBase64'),
					typ: get(dokumentResponse, 'response.typ'),
					name: get(dokumentResponse, 'response.nazov')
				}
			})

			const prilohy = map(notUploadedPrilohy, async (file) => {
				const dokumentResponse = await postReq(`/api/v0/ukony/${ukonId}/prilozit-dokument`, null, {
					contentType: file.type,
					nazov: file.name,
					data: file.dataAsBase64,
					typ: {
						id: DOKUMENT_TYP.VYSTUPNY
					},
					poradie: 200
				})
				return {
					type: get(dokumentResponse, 'response.contentType'),
					id: get(dokumentResponse, 'response.id'),
					dataAsBase64: get(file, 'dataAsBase64'),
					typ: get(dokumentResponse, 'response.typ'),
					name: get(dokumentResponse, 'response.nazov')
				}
			})

			const dokumentyPromises = await Promise.all(dokumenty)
			const prilohyPromises = await Promise.all(prilohy)

			// merge already uploaded dokumenty and fresh uploaded
			dispatch(change(FORMS.SYSTEMOVE_LISTY, 'dokumenty', [...uploadedDokumenty, ...uploadedPrilohy, ...dokumentyPromises, ...prilohyPromises]))

			if (this._mounted) {
				this.setState({
					step: 3,
					isLoading: false,
					inputsNotFilledDialog: false
				})
			}
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	stepThreeClickHandler = async (values) => {
		const { tracking, dataActions, ukonNovy, signedPdf, notification, templatePdf, t } = this.props

		this.setLoading(true)

		let notifikacie = {}
		if (get(notification, 'typ') == NOTIFICATION_TYPES.ADDRESS) {
			set(notifikacie, 'adresyPosta', [get(notification, 'address')])
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.EMAIL) {
			set(notifikacie, 'adresyUri', [
				{
					typ: NOTIFICATION_TYPES.EMAIL,
					hodnota: get(notification, 'email')
				}
			])
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.PRINTER) {
			set(notifikacie, 'adresyUri', [
				{
					typ: NOTIFICATION_TYPES.PRINTER,
					poradie: 0
				}
			])
		}

		notifikacie = isEmpty(notifikacie) ? undefined : notifikacie
		const documentForUpload = get(signedPdf, 'data.dataAsBase64') ? get(signedPdf, 'data') : get(templatePdf, 'data')

		try {
			if (documentForUpload) {
				await postReq(`/api/v0/ukony/${ukonNovy.id}/prilozit-dokument`, null, {
					contentType: get(documentForUpload, 'type'),
					typ: {
						id: DOKUMENT_TYP.VYSTUPNY
					},
					nazov: get(documentForUpload, 'name'),
					data: get(documentForUpload, 'dataAsBase64')
				})
			}

			const body = this.formatDataForUkon(values)
			const spustitBody = {
				...body,
				trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
				notifikacie,
				id: ukonNovy.id
			}
			await postReq(`/api/v2/ukony/${ukonNovy.id}/spustit`, null, spustitBody)

			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Úkon bol úspešne odoslaný'),
					success: true,
					isLoading: false
				})
			}
		} catch (e) {
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
					success: false,
					isLoading: false
				})
			}
		}
	}

	confirmModal = () => {
		const { interakcia, ukonyActions, selectedFiltersUkony } = this.props
		const { success } = this.state

		if (this._mounted) {
			if (success) {
				this.setState(
					{
						success: false
					},
					() => {
						// clear ukonId from storage
						ukonyActions.clearUkonId()
						// refetch new data for historia ukonov
						ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)
						history.replace(setRouteParams(PREHLAD, get(interakcia, 'opCislo')))
					}
				)
			} else {
				this.setState({
					result: null
				})
			}
		}
	}

	/* koKriteriaHandler = (isKoKriteriumChecked, koKriteria) => this.setState({
		isKoKriteriumChecked,
		koKriteria
	})

	validacneKriteriaHandler = (validacneKriteria) => this.setState({
		validacneKriteria
	})

	schvalovacieKriteriaHandler = (schvalovacieKriteria) => this.setState({
		schvalovacieKriteria
	}) */

	setStep = (step) => this.setState({ step })

	/* koKriteriumContentElement = (koKriteria) => (<div className="failure-content-container">
		<img src={failureStateIcon} />
		{map(koKriteria, (koKriterium, index) => <React.Fragment key={index}>
			<p>{koKriterium.nazov}:</p>
			<p className="message fail">{koKriterium.popis}</p>
		</React.Fragment>)}
	</div>) */

	sablonaChanged = (selectedSablona) => {
		const { sablona } = this.state
		if (sablona != selectedSablona) {
			this.setState({
				sablona: selectedSablona,
				sablonaChangeDialog: true
			})
		}
	}

	render() {
		const {
			auth,
			signedPdf,
			notification,
			/* procesnyKonfigurator, */
			interakcia,
			t,
			dispatch,
			formValues
		} = this.props
		const {
			step,
			scenarioOption,
			isLoading,
			/* koKriteria,
			validacneKriteria,
			schvalovacieKriteria,
			context, */
			success,
			result,
			dissallowedScenarios,
			sablona,
			sablony,
			sablonyOptions,
			sablonaChangeDialog,
			vstupneDokumentyDialog,
			inputsNotFilledDialog
		} = this.state

		if (isLoading /*  || get(procesnyKonfigurator, 'isLoading') */) {
			return <ElementLoading />
		}

		/* if (get(procesnyKonfigurator, 'isFailure') || isEmpty(procesnyKonfigurator, 'data.ukony')) {
			return <ElementEmptyContent text={t('translation:ProcesnyKonfigurator.Ľutujeme ale nepodarilo sa načítať Procesný konfigurátor')} />
		} */
		if (isEmpty(sablony)) {
			return <ElementEmptyContent text={t('translation:SystemoveListyUkon.Ľutujeme ale nepodarilo sa načítať šablóny systémových listov')} />
		}

		// check if every Ko kriterium pass condition, if not navigate user back to step 1
		const nextStep = step
		/* const invalidKoKriteria = filter(koKriteria, {
			vysledok: false
		})
		if (!isEmpty(invalidKoKriteria)) {
			nextStep = 1
		} */

		let component
		switch (nextStep) {
			case 1:
				component = (
					<SystemoveListyUkonInfo
						{...this.props}
						formTitle={t('translation:SystemoveListyUkon.Krok 1 z 3 Odpoveď zákazníkovi')}
						onSubmit={this.stepOneClickHandler}
						onDeleteFile={this.onDeleteFile}
						onBackClick={() => history.push(setRouteParams(PREHLAD, get(interakcia, 'opCislo')))}
						onCancelClick={() => history.push(setRouteParams(PREHLAD, get(interakcia, 'opCislo')))}
						/* schvalovacieKriteria={schvalovacieKriteria}
				validacneKriteria={validacneKriteria} */
					/>
				)
				break
			case 2:
				component = (
					<SystemoveListyUkonText
						{...this.props}
						formTitle={t('translation:SystemoveListyUkon.Krok 2 z 3 Odpoveď zákazníkovi')}
						onSubmit={this.stepTwoClickHandler}
						onDeleteFile={this.onDeleteFile}
						onBackClick={() => {
							this.setState({ step: 1 })
						}}
						onCancelClick={() => history.push(setRouteParams(PREHLAD, get(interakcia, 'opCislo')))}
						sablona={sablona}
						sablonyOptions={sablonyOptions}
						sablonaChanged={this.sablonaChanged}
					/>
				)
				break
			case 3: {
				// check if scenar is define
				const customizedScenarioOption = {
					...scenarioOption,
					scenarios: filter(get(scenarioOption, 'scenarios', []), (scenar) => {
						return (
							!includes(dissallowedScenarios, get(scenar, 'typ')) &&
							isEmpty(get(scenar, 'allowedRoles', []) || checkPermissions(get(auth, 'user.roles', []), get(scenar, 'allowedRoles', [])))
						)
					})
				}

				// if notification is required and not selected yet
				const isDisabledNotification = get(customizedScenarioOption, 'notificationRequired') && !get(notification, 'typ')

				// for scenarios VYTLACIT_A_NAHRAT and PODPISANIE_NA_MIESTE is required signedPDF
				let isDisabledSignedPdf = true
				if (
					(find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.VYTLACIT_A_NAHRAT }) ||
						find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.PODPISANIE_NA_MIESTE })) &&
					get(signedPdf, 'data.dataAsBase64')
				) {
					isDisabledSignedPdf = false
				} else if (
					find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.ODOSLAT_BEZ_PODPISU }) ||
					find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.ODOSLAT_NA_PODPIS })
				) {
					isDisabledSignedPdf = false
				}

				const isDisabledScenarNotSelected = get(customizedScenarioOption, 'scenarios', []).length > get(customizedScenarioOption, 'maxAllowedScenars')
				component = (
					<SystemoveListyUkonConfirm
						{...this.props}
						formTitle={t('translation:SystemoveListyUkon.Krok 3 z 3 Odpoveď zákazníkovi')}
						scenarioOption={customizedScenarioOption}
						onSubmit={this.stepThreeClickHandler}
						onBackClick={() => {
							this.setState({ step: 2 })
						}}
						onCancelClick={() => history.push(setRouteParams(PREHLAD, get(interakcia, 'opCislo')))}
						notification={notification}
						isDisabledNotification={isDisabledNotification}
						isDisabledSignedPdf={isDisabledSignedPdf}
						isDisabledScenarNotSelected={isDisabledScenarNotSelected}
						/* schvalovacieKriteria={schvalovacieKriteria} */
						disallowScenarios={this.disallowScenarios}
						sablony={sablony}
					/>
				)
				break
			}
			default:
		}

		return (
			<>
				{result && (
					<DefaultModal
						modalTitle={success ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
						modalContent={result}
						leftButton={{
							onClick: this.confirmModal,
							text: t('translation:Common.Zavrieť'),
							color: success ? 'green' : 'red'
						}}
						visible
					/>
				)}
				{sablonaChangeDialog && (
					<DefaultModal
						modalTitle={t('translation:SystemoveListyUkon.Zmena šablóny')}
						modalContent={t(
							'translation:SystemoveListyUkon.Zmenou šablóny budú odstránený aj doteraz zadaný text odpovede Naozaj chcete pokračovať?'
						)}
						leftButton={{
							onClick: () => {
								this.setState({
									sablona: get(formValues, 'sablona'),
									sablonaChangeDialog: false
								})
							},
							text: t('translation:Common.Zrušiť'),
							outline: true,
							color: 'red'
						}}
						rightButton={{
							onClick: () => {
								dispatch(change(FORMS.SYSTEMOVE_LISTY, 'sablona', sablona))
								this.setState({
									sablona,
									sablonaChangeDialog: false
								})
							},
							text: t('translation:Common.Potvrdiť'),
							color: 'blue'
						}}
						visible
					/>
				)}
				{vstupneDokumentyDialog && (
					<DefaultModal
						modalTitle={t('translation:SystemoveListyUkon.Chcete pokračovať?')}
						modalContent={t('translation:SystemoveListyUkon.Nevložili ste žiadny vstupný dokument Chcete pokračovať?')}
						leftButton={{
							onClick: () => {
								this.setState({
									vstupneDokumentyDialog: false
								})
							},
							text: t('translation:Common.Nie'),
							outline: true,
							color: 'red'
						}}
						rightButton={{
							onClick: this.continueToStepTwo,
							text: t('translation:Common.Áno'),
							color: 'blue'
						}}
						visible
					/>
				)}
				{inputsNotFilledDialog && (
					<DefaultModal
						modalTitle={t('translation:Common.Upozornenie!')}
						modalContent={t('translation:SystemoveListyUkon.Nevyplnili ste všetky povinné informácie v texte odpovede')}
						rightButton={{
							onClick: () => {
								this.setState({
									inputsNotFilledDialog: false
								})
							},
							text: t('translation:Common.Ok'),
							color: 'blue'
						}}
						visible
					/>
				)}
				{component}
				{/* <this.state.ProcesnyKonfigurator
				koKriteriaHandler={this.koKriteriaHandler}
				validacneKriteriaHandler={this.validacneKriteriaHandler}
				schvalovacieKriteriaHandler={this.schvalovacieKriteriaHandler}
				formatDataForUkon={this.formatDataForUkon}
				context={context}
			/> */}
			</>
		)
	}

	disallowScenarios = (scenarios) =>
		this.setState({
			disallowedScenarios: scenarios
		})
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth'),
	tracking: get(state, 'tracking'),
	ukonNovy: get(state, 'ukony.ukonNovy'),
	ciselniky: get(state, 'ciselniky.data'),
	ciselnikySelectBox: get(state, 'ciselnikySelectBox'),
	signedPdf: get(state, 'podpisovanieDokumentov.signedPdf'),
	notification: get(state, 'podpisovanieDokumentov.notification'),
	templatePdf: get(state, 'podpisovanieDokumentov.templatePdf'),
	procesnyKonfigurator: get(state, 'procesnyKonfigurator.procesnyKonfigurator'),
	statuses: get(state, 'statuses.statuses'),
	formValues: getFormValues(FORMS.SYSTEMOVE_LISTY)(state),
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	ciselnikySelectBoxActions: bindActionCreators(CiselnikySelectBoxActions, dispatch),
	podpisovanieDokumentovActions: bindActionCreators(PodpisovanieDokumentovActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch),
	statusActions: bindActionCreators(StatusActions, dispatch),
	sablonyActions: bindActionCreators(SablonyActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.UKON_ODPOVED_ZAKAZNIKOVI])
)(SystemoveListyUkonPage)
