import { get } from 'lodash'
import {
	UKON_EDIT_OP_LOAD_START,
	UKON_EDIT_OP_LOAD_DONE,
	UKON_EDIT_OP_LOAD_FAIL,
	UKON_OP_KONTAKTY_NESUHLASY_LOAD_START,
	UKON_OP_KONTAKTY_NESUHLASY_LOAD_DONE,
	UKON_OP_KONTAKTY_NESUHLASY_LOAD_FAIL
} from '../types/ukonEditOpTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	obchodnyPartner: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	nesuhlasyKontakty: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case UKON_EDIT_OP_LOAD_START: {
			return {
				...state,
				obchodnyPartner: {
					...state.obchodnyPartner,
					isLoading: true,
					isFailure: false
				}
			}
		}
		case UKON_EDIT_OP_LOAD_DONE:
			return {
				...state,
				obchodnyPartner: {
					data: get(action, 'payload.content', {}),
					isLoading: false,
					isFailure: false
				}
			}
		case UKON_EDIT_OP_LOAD_FAIL:
			return {
				...state,
				obchodnyPartner: {
					...initState.obchodnyPartner,
					isFailure: true
				}
			}
		case UKON_OP_KONTAKTY_NESUHLASY_LOAD_START: {
			return {
				...state,
				nesuhlasyKontakty: {
					...state.nesuhlasyKontakty,
					isLoading: true,
					isFailure: false
				}
			}
		}
		case UKON_OP_KONTAKTY_NESUHLASY_LOAD_DONE:
			return {
				...state,
				nesuhlasyKontakty: {
					data: get(action, 'payload.content', {}),
					isLoading: false,
					isFailure: false
				}
			}
		case UKON_OP_KONTAKTY_NESUHLASY_LOAD_FAIL:
			return {
				...state,
				nesuhlasyKontakty: {
					...initState.nesuhlasyKontakty,
					isFailure: true
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
