import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

class Input extends React.Component {
	render() {
		const { refs, value, label, placeholder, required, showLabel, onChange, error, disabled, type = 'text', style, maxLength } = this.props
		return (
			<div className={cx('input-wrapper', { 'has-error': !!error })}>
				{showLabel && <label htmlFor={label}>{label}</label>}
				<input
					style={style}
					ref={refs}
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					type={type}
					className={cx('form-control', 'input-field')}
					required={required}
					disabled={disabled}
					maxLength={maxLength}
				/>
				<div className='text-danger'>{error || ''}</div>
			</div>
		)
	}
}

Input.propTypes = {
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	error: PropTypes.string,
	value: PropTypes.string,
	required: PropTypes.bool,
	type: PropTypes.string,
	disabled: PropTypes.bool,
	maxLength: PropTypes.number,
	refs: PropTypes.func,
	style: PropTypes.shape(),
	onChange: PropTypes.func.isRequired
}

Input.defaultProps = {
	showLabel: false,
	placeholder: '',
	label: '',
	required: false,
	disabled: false
}

export default Input
