import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash'

// atoms
import { SelectField } from '../../../atoms'

// utils
import { formatAddress, miestoSpotrebyAdresaDoplnokFormat } from '../../../utils/address'

class MiestoSpotrebyVyberField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		field: PropTypes.string.isRequired,
		form: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		formValues: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		miestaSpotreby: PropTypes.array.isRequired,
		povinny: PropTypes.bool.isRequired,
		vstupny: PropTypes.bool.isRequired
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { povinny, t } = this.props

		if (povinny && !value) {
			return t('translation:Common.validate.Zvoľte adresu miesta spotreby')
		}
	}

	getOptions = () => {
		const { miestaSpotreby } = this.props

		const options = miestaSpotreby?.map((miestoSpotreby) => {
			return {
				label: `${formatAddress(get(miestoSpotreby, 'adresa'))}, ${miestoSpotrebyAdresaDoplnokFormat(miestoSpotreby)}`,
				value: { cislo: miestoSpotreby.cislo }
			}
		})

		if (isEmpty(options)) {
			return []
		}

		return options
	}

	render() {
		const { field, label, t } = this.props

		const miestaSpotrebyOptions = this.getOptions()

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{label}</strong>
							</td>

							<td>
								<Field
									name={field}
									component={SelectField}
									options={miestaSpotrebyOptions}
									validate={this.validate}
									placeholder={t('translation:Common.Vyberte adresu')}
									isDisabled={isEmpty(miestaSpotrebyOptions)}
									classNamePrefix='react-select'
									compare={(option, val) => {
										return get(option, 'value.cislo') === get(val, 'cislo')
									}}
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(MiestoSpotrebyVyberField)
