import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, propTypes, getFormValues } from 'redux-form'
import PropTypes from 'prop-types'
import { get, find, upperFirst } from 'lodash'
import { Tooltip } from 'react-tippy'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

// components
import FormInfo from '../FormInfo'
import WrapperPodpisovanieDokumentov from '../PodpisovanieDokumentov/WrapperPodpisovanieDokumentov'

// utils
import { FORMS } from '../../utils/enums'
import { formatAddress, miestoSpotrebyAdresaDoplnokFormat } from '../../utils/address'
import { formatDate } from '../../utils/date'

class SystemoveListyUkonConfirm extends React.Component {
	static propTypes = {
		...propTypes,
		formValues: PropTypes.shape()
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	render() {
		const {
			ciselniky,
			formTitle,
			handleSubmit,
			scenarioOption,
			isDisabledNotification,
			isDisabledSignedPdf,
			isDisabledScenarNotSelected,
			templatePdf,
			onBackClick,
			onCancelClick,
			schvalovacieKriteria,
			disallowScenarios,
			ciselnikySelectBox,
			formValues,
			sablony,
			t
		} = this.props

		const ukonVstup = find(get(ciselniky, 'ukonVstup', []), (item) => {
			return get(item, 'id') == get(formValues, 'ukonVstup')
		})

		const disabledBtn = (
			<button className='button' type='submit' disabled data-color='blue' style={{ marginLeft: '20px' }}>
				{t('translation:Common.Dokončiť')}
			</button>
		)

		let submitBtn = null
		if (get(templatePdf, 'isLoading')) {
			submitBtn = (
				<Tooltip
					html={<span>{t('translation:Common.Prebieha načítavanie dokumentu pre podpisovanie')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (isDisabledScenarNotSelected) {
			submitBtn = (
				<Tooltip html={<span>{t('translation:Common.Pre pokračovanie zvoľte scenár')}</span>} position='left' trigger='mouseenter' theme='light'>
					{disabledBtn}
				</Tooltip>
			)
		} else if (isDisabledSignedPdf) {
			submitBtn = (
				<Tooltip html={<span>{t('translation:Common.Dokument musí byť podpísaný')}</span>} position='left' trigger='mouseenter' theme='light'>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledSignedPdf && isDisabledNotification) {
			submitBtn = (
				<Tooltip
					html={<span>{t('translation:Common.Na dokončenie úkonu je potrebné nastaviť notifikáciu')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledNotification && !isDisabledSignedPdf) {
			submitBtn = (
				<button className='button pull-right' type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
					{t('translation:Common.Dokončiť')}
				</button>
			)
		}

		const miestoSpotreby = find(get(ciselnikySelectBox, 'miestaSpotreby.data', []), (miestoSpotreby) => {
			return get(miestoSpotreby, 'cislo') == get(formValues, 'msCislo')
		})

		const kategoria1 = find(get(ciselniky, 'sapKontaktKategoria1', []), (kategoria) => get(kategoria, 'id') == get(formValues, 'kategoria1')) || null
		const kategoria2 = find(get(ciselniky, 'sapKontaktKategoria2', []), (kategoria) => get(kategoria, 'id') == get(formValues, 'kategoria2')) || null
		const kategoria3 = find(get(ciselniky, 'sapKontaktKategoria3', []), (kategoria) => get(kategoria, 'id') == get(formValues, 'kategoria3')) || null
		const kategoria4 = find(get(ciselniky, 'sapKontaktKategoria4', []), (kategoria) => get(kategoria, 'id') == get(formValues, 'kategoria4')) || null
		const sablona = find(sablony, (sablona) => get(sablona, 'id') == get(formValues, 'sablona')) || null

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{submitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<FormInfo schvalovacieKriteria={schvalovacieKriteria} />
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstup do úkonu')}</strong>
										</td>
										<td>{upperFirst(get(ukonVstup, 'nazov', ''))}</td>
									</tr>
									{/* <tr>
									<td>
										<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
									</td>
									<td>
										{get(formValues, 'podpisMiesto')}
									</td>
								</tr> */}
									<tr>
										<td>
											<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
										</td>
										<td>{formatDate(get(formValues, 'datumPrijatiaZiadosti'), null, 'DD.MM.YYYY')}</td>
									</tr>
								</tbody>
							</table>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:Common.Miesto spotreby')}</strong>
											</td>
											<td>
												{miestoSpotreby
													? `${formatAddress(get(miestoSpotreby, 'adresa'))}, ${miestoSpotrebyAdresaDoplnokFormat(miestoSpotreby)}`
													: null}
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:SystemoveListyUkon.Kategória 1')}</strong>
											</td>
											<td>{upperFirst(get(kategoria1, 'nazov', ''))}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:SystemoveListyUkon.Kategória 2')}</strong>
											</td>
											<td>{upperFirst(get(kategoria2, 'nazov', ''))}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:SystemoveListyUkon.Kategória 3')}</strong>
											</td>
											<td>{upperFirst(get(kategoria3, 'nazov', ''))}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:SystemoveListyUkon.Kategória 4')}</strong>
											</td>
											<td>{upperFirst(get(kategoria4, 'nazov', ''))}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:SystemoveListyUkon.Značka')}</strong>
											</td>
											<td>{get(formValues, 'znacka', '')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:SystemoveListyUkon.Šablóna')}</strong>
											</td>
											<td>{upperFirst(get(sablona, 'nazov', ''))}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Poznámka')}</strong>
										</td>
										<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
											<div className='input-wrapper'>
												<textarea
													disabled='disabled'
													rows='5'
													className='form-control static'
													style={{ backgroundColor: 'white' }}
													value={get(formValues, 'poznamka')}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>

							<WrapperPodpisovanieDokumentov scenarioOption={scenarioOption} disallowScenarios={disallowScenarios} />
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.SYSTEMOVE_LISTY,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true
})(SystemoveListyUkonConfirm)

const mapStateToProps = (state) => ({
	formValues: getFormValues(FORMS.SYSTEMOVE_LISTY)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
