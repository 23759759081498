export const INTERAKCIE_CLOSE_START = 'INTERAKCIE_CLOSE_START'
export const INTERAKCIE_CLOSE_FAIL = 'INTERAKCIE_CLOSE_FAIL'
export const INTERAKCIE_CLOSE_DONE = 'INTERAKCIE_CLOSE_DONE'

export const INTERAKCIE_START_LOAD_START = 'INTERAKCIE_START_LOAD_START'
export const INTERAKCIE_START_LOAD_DONE = 'INTERAKCIE_START_LOAD_DONE'
export const INTERAKCIE_START_LOAD_FAIL = 'INTERAKCIE_START_LOAD_FAIL'

export const INTERAKCIE_LOAD_START = 'INTERAKCIE_LOAD_START'
export const INTERAKCIE_LOAD_DONE = 'INTERAKCIE_LOAD_DONE'
export const INTERAKCIE_LOAD_FAIL = 'INTERAKCIE_LOAD_FAIL'
