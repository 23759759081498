/* eslint-disable no-cond-assign */
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes, getFormValues } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { /* every, */ get, find, isEmpty, forEach /* some, */ } from 'lodash'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Tooltip } from 'react-tippy'
import ReactQuill from 'react-quill'

import validate from '../validateForm'

// components
/* import FormInfo from '../../FormInfo' */

// atoms
import { DropZoneField, TextInputField } from '../../../atoms'
import Select from '../../../atoms/BasicSelect'

// utils
import { FORMS } from '../../../utils/enums'

import QuillEditorField from '../../../atoms/QuillEditorField'

const Clipboard = ReactQuill.Quill.import('modules/clipboard')
const Delta = ReactQuill.Quill.import('delta')

class PlainClipboard extends Clipboard {
	onPaste(e) {
		if (e.defaultPrevented || !this.quill.isEnabled()) return
		const range = this.quill.getSelection()

		const indices = findPlaceholderOccurencies(this.quill.getText())
		const occurence = normalizePlaceholderOccurence(indices, this.quill, range.index)

		const index = occurence ? occurence.from : range.index
		const length = occurence ? 0 : range.length

		let delta = new Delta().retain(index)
		const { scrollTop } = this.quill.scrollingContainer
		this.container.focus()
		this.quill.selection.update('silent')
		setTimeout(() => {
			delta = delta.concat(this.convert()).delete(length)
			this.quill.updateContents(delta, 'user')
			// range.length contributes to delta.length()
			this.quill.setSelection(delta.length() - length, 'silent')
			this.quill.scrollingContainer.scrollTop = scrollTop
			this.quill.focus()
		}, 1)
	}
}
ReactQuill.Quill.register('modules/clipboard', PlainClipboard, true)

function findPlaceholderOccurencies(html) {
	const regex = /\[\[.[^([[)]+\]\]/gi
	let result
	const indices = []
	while ((result = regex.exec(html))) {
		indices.push({
			from: result.index,
			to: result.index + get(result, '0.length', 0),
			length: get(result, '0.length', 0)
		})
	}
	return indices
}

function normalizePlaceholderOccurence(indices, quillRef, keyIndex, offset = 0) {
	const occurence = find(indices, (index) => {
		return index.from <= keyIndex && index.to >= keyIndex
	})
	if (occurence) {
		quillRef.deleteText(occurence.from, occurence.length - offset)
		return occurence
	}
	return false
}

class SystemoveListyUkonText extends React.Component {
	static propTypes = {
		...propTypes,
		formValues: PropTypes.shape()
	}

	_defaultKeyBindings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+ľščťžýáíé=úä(/ň§ô)!"-.,_:?>'

	_numpadKeyCodes = []

	_mounted = false

	constructor(props) {
		super(props)

		this.state = {
			ukonVstupOptions: [],
			quillRef: null,
			odpovedTextHTML: null,
			odovedTextPlaceholderIndices: [],
			bindings: this.initKeyBindings()
		}
	}

	initKeyBindings = () => {
		// NOTE: 96 - 111 are codes for NumPad
		for (let i = 96; i < 112; i++) {
			this._numpadKeyCodes.push(i)
		}

		return {
			delete: {
				// NOTE: 46 is code for DELETE button
				key: 46,
				collapsed: true,
				handler: (range) => {
					if (!normalizePlaceholderOccurence(this.state.odovedTextPlaceholderIndices, this.state.quillRef, range.index)) {
						return true
					}
				}
			},
			delete_collapsed: {
				// NOTE: 46 is code for DELETE button
				key: 46,
				collapsed: false,
				handler: (range) => {
					if (!normalizePlaceholderOccurence(this.state.odovedTextPlaceholderIndices, this.state.quillRef, range.index)) {
						return true
					}
				}
			},
			backspace: {
				// NOTE: 8 is code for BACKSPACE button
				key: 8,
				collapsed: true,
				handler: (range) => {
					if (!normalizePlaceholderOccurence(this.state.odovedTextPlaceholderIndices, this.state.quillRef, range.index)) {
						return true
					}
				}
			},
			backspace_collapsed: {
				// NOTE: 8 is code for BACKSPACE button
				key: 8,
				collapsed: false,
				handler: (range) => {
					if (!normalizePlaceholderOccurence(this.state.odovedTextPlaceholderIndices, this.state.quillRef, range.index)) {
						return true
					}
				}
			}
		}
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.quillRef && this.state.quillRef) {
			forEach([...this._defaultKeyBindings, ...this._numpadKeyCodes], (key) => {
				this.state.quillRef.keyboard.addBinding({ key }, (range) => {
					normalizePlaceholderOccurence(this.state.odovedTextPlaceholderIndices, this.state.quillRef, range.index)
					return true
				})
			})
			// Combination Shift + <Numpad Key> is not required
			forEach([...this._defaultKeyBindings], (key) => {
				this.state.quillRef.keyboard.addBinding(
					{
						key,
						shiftKey: true
					},
					(range) => {
						normalizePlaceholderOccurence(this.state.odovedTextPlaceholderIndices, this.state.quillRef, range.index)
						return true
					}
				)
			})
		}
	}

	render() {
		const {
			formTitle,
			handleSubmit,
			invalid,
			sablona,
			sablonyOptions,
			sablonaChanged,
			/* validacneKriteria,
			schvalovacieKriteria, */
			onDeleteFile,
			onBackClick,
			onCancelClick,
			t
		} = this.props

		const { quillRef, bindings } = this.state

		// result from procesnyKonfigurator if form is valid
		/* const passValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumError), {
			vysledok: true
		})
		const errorSchvalovacieKriterium = some(schvalovacieKriteria, {
			error: true
		}) */

		const isValid = true /* passValidacneKriterium && !errorSchvalovacieKriterium */
		const disableSubmit = !isValid || invalid

		const submitBtn = (
			<button
				className={cx('button', 'pull-right', { disabled: disableSubmit })}
				disabled={disableSubmit}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('translation:Common.Pokračovať')}
			</button>
		)

		let wrappedSubmitBtn
		if (!isValid) {
			let btnTooltipText
			/* if (!passValidacneKriterium) {
				const valKrit = filter(validacneKriteria, (validacneKriterium) => !validacneKriterium.vysledok)
				btnTooltipText = map(valKrit, (validacneKriterium) => <div>{validacneKriterium.nazov}: {validacneKriterium.popis}</div>)
			}
			else if (errorSchvalovacieKriterium) {
				const schvalKrit = filter(schvalovacieKriteria, (schvalovacieKriterium) => !schvalovacieKriterium.vysledok || schvalovacieKriterium.error)
				btnTooltipText = map(schvalKrit, (schvalovacieKriterium) => <div>{schvalovacieKriterium.nazov}: {schvalovacieKriterium.popis}</div>)
			} */
			wrappedSubmitBtn = (
				<Tooltip html={btnTooltipText} position='bottom' trigger='mouseenter' theme='light'>
					{submitBtn}
				</Tooltip>
			)
		} else {
			wrappedSubmitBtn = submitBtn
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{wrappedSubmitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						{/* <FormInfo schvalovacieKriteria={schvalovacieKriteria} />
					<FormInfo validacneKriteria={validacneKriteria} /> */}
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								{/* <thead>
								<tr>
									<th></th>
									<th>{t('translation:Common.Nové hodnoty')}</th>
								</tr>
							</thead> */}
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:SystemoveListyUkon.Značka')}</strong>
										</td>
										<td>
											<Field name='znacka' component={TextInputField} placeholder={t('translation:SystemoveListyUkon.Značka')} />
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:SystemoveListyUkon.Šablóna')}</strong>
										</td>
										<td>
											<Select
												classNamePrefix='react-select'
												value={find(sablonyOptions, (option) => option.value == sablona)}
												options={sablonyOptions}
												onChange={(sablona) => sablonaChanged(get(sablona, 'value'))}
												isSearchable={true}
												isDisabled={isEmpty(sablonyOptions)}
												isClearable={false}
											/>
										</td>
									</tr>
								</tbody>
							</table>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td colSpan='2'>
											<div style={{ paddingBottom: '10px' }}>
												<strong>{t('translation:SystemoveListyUkon.Text odpovede')}</strong>
											</div>
											<Field
												name='odpovedText'
												component={QuillEditorField}
												modules={{
													toolbar: [['bold', 'italic', 'underline', { list: 'bullet' }]],
													keyboard: { bindings }
												}}
												formats={['bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'color', 'background', 'placeholder']}
												reference={(ref) => {
													if (!quillRef && ref) {
														this.setState({
															quillRef: ref.getEditor()
														})
													}
												}}
												handleChange={(html, delta, source, editor) => {
													const text = editor.getText()
													this.setState({
														odpovedTextHTML: text,
														odovedTextPlaceholderIndices: findPlaceholderOccurencies(text)
													})
												}}
												theme='snow'
											/>
										</td>
									</tr>
								</tbody>
							</table>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Prílohy')}</strong>
										</td>
										<td>
											<Field
												name='prilohy'
												onDelete={onDeleteFile}
												component={DropZoneField}
												placeholder={t('translation:Common.Klikni alebo presuň súbory pre nahratie')}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.SYSTEMOVE_LISTY,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(SystemoveListyUkonText)

const mapStateToProps = (state) => ({
	formValues: getFormValues(FORMS.SYSTEMOVE_LISTY)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
