import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { map, isEmpty, get, filter, find } from 'lodash'
import PropTypes from 'prop-types'
import cx from 'classnames'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'

// components
import HistoryItem from './HistoryItem'
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'
import ElementEmptyContent from '../ElementEmptyContent'

// atoms
import CheckBoxField from '../../atoms/CheckboxField'

// actions
import * as HistoriaUkonovActions from '../../actions/UkonyActions'
import * as FiltersActions from '../../actions/SelectedFiltersActions'

import Select from '../../atoms/BasicSelect'
import { FILTER_SELECTORS } from '../../utils/enums'
import { getRequiredPermissionsForUkonRead } from '../../utils/permissionsHoc'

const Scroll = require('react-scroll')

const { scroller } = Scroll

const defaultQueryParams = {
	fulltext: null,
	kanalId: null,
	vytvorenyOd: null,
	vytvorenyDo: null,
	riesitel: null,
	typUkonu: null,
	dolezite: 'true'
}

const queryParams = {
	fulltext: 'fulltext',
	kanalId: 'kanalId',
	vytvorenyOd: 'vytvorenyOd',
	vytvorenyDo: 'vytvorenyDo',
	riesitel: 'riesitel',
	typUkonu: 'typId',
	dolezite: 'dolezite'
}

class SidebarHistory extends React.Component {
	static propTypes = {
		historiaUkonovActions: PropTypes.shape({
			loadHistoriaUkonov: PropTypes.func
		}),
		historiaUkonov: PropTypes.shape(),
		ciselniky: PropTypes.shape(),
		selectedFilters: PropTypes.shape().isRequired,
		filtersActions: PropTypes.shape({
			selectedFiltersChanged: PropTypes.func.isRequired
		}).isRequired,
		interakcia: PropTypes.shape(),
		toggleSidebar: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		computedMatch: PropTypes.shape().isRequired
	}

	state = {
		filterOpened: false,
		filterActive: false
	}

	filterChanged = (value, fieldName) => {
		const { historiaUkonovActions, filtersActions, selectedFilters } = this.props
		// stringify value or set a default value null
		const queryParams = {
			...selectedFilters,
			[fieldName]: value ? `${value}` : defaultQueryParams[fieldName]
		}
		historiaUkonovActions.loadHistoriaUkonov(1, undefined, queryParams)
		// update filter state

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.SIDEBAR_HISTORY, queryParams)

		const filterCounter = filter(queryParams, (el) => el !== defaultQueryParams[fieldName]).length
		this.setState({
			filterActive: filterCounter > 0
		})
	}

	componentDidMount() {
		const { computedMatch, selectedFilters } = this.props
		this.refetchHistorySideBar(computedMatch.params.ukonCislo)

		const filterCounter = filter(Object.keys(selectedFilters), (key) => {
			return selectedFilters[key] !== defaultQueryParams[key]
		}).length
		this.setState({
			filterActive: filterCounter > 0
		})
	}

	refetchHistorySideBar = async (ukonId) => {
		const { historiaUkonov, historiaUkonovActions, selectedFilters } = this.props
		await historiaUkonovActions.loadHistoriaUkonov(historiaUkonov.page, undefined, selectedFilters)
		// after loading elements scroll on selected
		const elmnt = document.getElementById(ukonId)
		if (elmnt) {
			scroller.scrollTo(ukonId, {
				delay: 1000,
				isDynamic: true,
				smooth: true,
				containerId: 'scroll-container',
				offset: -300
			})
		}
	}

	failureContentElement = () => this.commonContentContainer(<ElementFailure text='Nepodarilo sa načítať históriu úkonov.' />)

	loadingContentElement = () => this.commonContentContainer(<ElementLoading />)

	emptyContentElement = () => this.commonContentContainer(<ElementEmptyContent text='História úkonov neexistuje' />)

	clearFiler = () => {
		this.props.filtersActions.selectedFiltersChanged(FILTER_SELECTORS.SIDEBAR_HISTORY, defaultQueryParams)
		this.props.historiaUkonovActions.loadHistoriaUkonov(1, undefined, defaultQueryParams)

		this.setState({
			filterActive: false
		})
	}

	scrollHandler = (e) => {
		const { historiaUkonov, historiaUkonovActions, selectedFilters } = this.props
		const { isLoading, isLastPage } = historiaUkonov
		const { clientHeight, scrollTop, scrollHeight } = e.target
		// trigger top 5px from bottom border
		if (scrollHeight - clientHeight >= scrollTop - 5 && !isLastPage && !isLoading) {
			const historiaUkonovNextPage = historiaUkonov.page + 1

			historiaUkonovActions.loadHistoriaUkonov(historiaUkonovNextPage, undefined, selectedFilters)
		}
	}

	datePickerRaw = (value, type) => {
		const pickedDate = dayjs(value, 'l', true)
		if (pickedDate.isValid()) {
			this.filterChanged(pickedDate.format('YYYY-MM-DD'), type)
		} else if (!value) {
			this.filterChanged(null, type)
		}
	}

	filtersElement = () => {
		const { ciselniky, t, selectedFilters } = this.props
		const ukoTypFilter = map(get(ciselniky, 'data.ukonTyp', []), (ukonTyp) => ({ value: ukonTyp.id, label: ukonTyp.nazov }))

		// remove kanal with ID = 3
		const kanalFilters = map(get(ciselniky, 'data.kanal', []), (kanal) => ({ value: kanal.id, label: kanal.popis }))
		const kanalFilter = filter(kanalFilters, (kanal) => kanal.value != 3)

		return (
			<div className='sidebar-header clearfix'>
				<div className='sidebar-header-top clearfix'>
					<h4 className='pull-left sidebar-title' onClick={this.props.toggleSidebar}>
						História
					</h4>
					<Tooltip className='pull-right' html={t('translation:Common.Filter')} position='left' trigger='mouseenter' theme='light'>
						<div
							className={cx('button', 'pull-right', 'noselect', 'filter', { empty: !this.state.filterActive })}
							data-type='icon-button'
							data-icon='filter'
							onClick={this.onFilterClick}
						/>
					</Tooltip>
				</div>
				<div className={cx('filter-content', 'content-wrapper', { hidden: !this.state.filterOpened })}>
					<div className='row'>
						<div className='col-12'>
							<div className='input-wrapper'>
								<input
									value={selectedFilters[queryParams.fulltext] || ''}
									name='fulltext'
									type='text'
									className='form-control input-field'
									placeholder={t('components:SidebarHistory.Napíšte hľadané kľúčové slová')}
									onChange={(el) => this.filterChanged(el.target.value, queryParams.fulltext)}
								/>
							</div>
						</div>
						<div className='col-6'>
							<div className='select-wrapper'>
								<Select
									value={find(kanalFilter, (element) => element.value == selectedFilters[queryParams.kanalId]) || ''}
									classNamePrefix='react-select'
									placeholder={t('components:SidebarHistory.Kanál')}
									options={kanalFilter}
									isClearable={true}
									isLoading={!get(ciselniky, 'data.kanal')}
									isDisabled={!get(ciselniky, 'data.kanal')}
									onChange={(el) => this.filterChanged(el ? el.value : null, queryParams.kanalId)}
								/>
							</div>
						</div>
						<div className='col-6'>
							<div className='input-wrapper'>
								<input
									name='riesitel'
									type='text'
									className='form-control input-field'
									placeholder={t('components:SidebarHistory.Pracovník')}
									value={selectedFilters[queryParams.riesitel] ? selectedFilters[queryParams.riesitel] : ''}
									onChange={(el) => this.filterChanged(el.target.value, queryParams.riesitel)}
								/>
							</div>
						</div>
						<div className='col-6'>
							<div className='input-wrapper'>
								<DatePicker
									locale='sk'
									dateFormat={'dd.MM.yyyy'}
									showYearDropdown
									scrollableYearDropdown
									selected={selectedFilters[queryParams.vytvorenyOd] ? dayjs(selectedFilters[queryParams.vytvorenyOd]).toDate() : null}
									onChangeRaw={(e) => this.datePickerRaw(e.target.value, queryParams.vytvorenyOd)}
									placeholderText={t('components:SidebarHistory.Dátum od')}
									isClearable
									onChange={(el) => this.filterChanged(el ? dayjs(el).format('YYYY-MM-DD') : null, queryParams.vytvorenyOd)}
								/>
							</div>
						</div>
						<div className='col-6'>
							<div className='input-wrapper tooltip-right'>
								<DatePicker
									locale='sk'
									dateFormat={'dd.MM.yyyy'}
									selected={selectedFilters[queryParams.vytvorenyDo] ? dayjs(selectedFilters[queryParams.vytvorenyDo]).toDate() : null}
									showYearDropdown
									scrollableYearDropdown
									placeholderText={t('components:SidebarHistory.Dátum do')}
									onChange={(el) => this.filterChanged(el ? dayjs(el).format('YYYY-MM-DD') : null, queryParams.vytvorenyDo)}
									onChangeRaw={(e) => this.datePickerRaw(e.target.value, queryParams.vytvorenyDo)}
									isClearable
									popperPlacement='bottom-end'
								/>
							</div>
						</div>
						<div className='col-6'>
							<Select
								value={find(ukoTypFilter, (element) => element.value == this.props.selectedFilters[queryParams.typUkonu]) || ''}
								placeholder={t('components:SidebarHistory.Typ úkonu')}
								options={ukoTypFilter}
								isClearable={true}
								isLoading={!get(ciselniky, 'data.ukonTyp')}
								isDisabled={!get(ciselniky, 'data.ukonTyp')}
								onChange={(el) => this.filterChanged(el ? el.value : null, queryParams.typUkonu)}
							/>
						</div>
						<div className='col-6' style={{ paddingTop: '5px' }}>
							<CheckBoxField
								input={{
									value: selectedFilters[queryParams.dolezite] ? JSON.parse(selectedFilters[queryParams.dolezite]) : true,
									onChange: (value) => this.filterChanged(value.toString(), queryParams.dolezite)
								}}
								label={t('components:SidebarHistory.Iba dôležité')}
							/>
						</div>
					</div>
					{this.state.filterActive && (
						<div className='row'>
							<div className='col-12'>
								<button
									className='button pull-right small noselect subtle'
									/*  data-type="outline" */ data-color='blue'
									onClick={this.clearFiler}
								>
									Zrušiť filter
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		)
	}

	commonContentContainer = (content) => (
		<div className={cx('sidebar', 'history-sidebar', { 'filter-opened': this.state.filterOpened, active: this.state.filterActive })}>
			{this.filtersElement()}
			<div id='scroll-container' className='sidebar-content' onScroll={this.scrollHandler}>
				<div className='history-wrapper'>{content}</div>
			</div>
		</div>
	)

	onFilterClick = () => {
		this.setState({
			filterOpened: !this.state.filterOpened
		})
	}

	render() {
		const { historiaUkonov, interakcia } = this.props
		const { isLoading, isFailure, page, data } = historiaUkonov

		if (isLoading && page === 1) {
			return this.loadingContentElement()
		}

		if (isFailure) {
			return this.failureContentElement()
		}
		if (isEmpty(data)) {
			return this.emptyContentElement()
		}

		let previousEvenOdd = 'even'
		let previousInterakcia = null
		const historiaUkonovRows = map(data, (ukon) => {
			let newEvenOdd
			if (get(ukon, 'interakciaId') == previousInterakcia) {
				newEvenOdd = previousEvenOdd
			} else {
				newEvenOdd = previousEvenOdd === 'even' ? 'odd' : 'even'
			}
			const item = (
				<HistoryItem
					{...this.props}
					key={ukon.id}
					evenOdd={newEvenOdd}
					ukon={ukon}
					refetchHistrySideBar={() => this.refetchHistorySideBar(ukon.id)}
					interakcia={interakcia}
					permissions={getRequiredPermissionsForUkonRead(ukon)}
				/>
			)
			previousInterakcia = ukon.interakciaId
			previousEvenOdd = newEvenOdd
			return item
		})

		if (isLoading) {
			historiaUkonovRows.push(
				<div key='widget-loading' className='history-item' style={{ height: '150px' }}>
					<ElementLoading />
				</div>
			)
		}
		return this.commonContentContainer(historiaUkonovRows)
	}
}

const mapStateToProps = (state) => ({
	historiaUkonov: state.ukony.historiaUkonov,
	ciselniky: state.ciselniky,
	interakcia: state.interakcie.detail,
	selectedFilters: get(state.selectedFilters, FILTER_SELECTORS.SIDEBAR_HISTORY, {})
})

const mapDispatchToProps = (dispatch) => ({
	historiaUkonovActions: bindActionCreators(HistoriaUkonovActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(SidebarHistory)
