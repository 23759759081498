import React from 'react'
import { map, get, isEmpty, pick, keys, indexOf, values, isEqual, trim, debounce, find, filter, orderBy, set } from 'lodash'
import { compose, bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import reactQueryParams from 'react-query-params'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import qs from 'qs'
import { Link } from 'react-router-dom'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as FiltersActions from '../../actions/SelectedFiltersActions'
import * as TrackingActions from '../../actions/TrackingActions'
import * as HistoriaUkonovActions from '../../actions/UkonyActions'
import * as CiselnikySelectBoxActions from '../../actions/CiselnikySelectBoxActions'

// atoms
import Select from '../../atoms/BasicSelect'

// components
import UpomienkaRow from '../../components/TableRows/UpomienkaRow'
import ElementLoading from '../../components/ElementLoading'
import DefaultModal from '../../components/Modals/DefaultModal'
import ModalSendItemDuplicate from '../../components/Modals/ModalSendItemDuplicate'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'

// utils
import { PERMISSIONS, withPermissions } from '../../utils/permissionsHoc'
import { UKONY, FILTER_SELECTORS, NOTIFICATION_TYPES } from '../../utils/enums'
import { setRouteParams, UPOMIENKY_ZOZNAM } from '../../utils/routes'
import extractProperties from '../../utils/extractProperties'
import { postReq } from '../../utils/request'

const queryParams = {
	backUrl: 'backUrl',
	pohladavkaCislo: 'pohladavkaCislo',
	zmluvnyUcetCislo: 'zuCislo',
	vystavenieDatumOd: 'vystavenieDatumOd',
	vystavenieDatumDo: 'vystavenieDatumDo',
	upominanieMetoda: 'upominanieMetoda',
	upominanieStupen: 'upominanieStupen',
	triedenie: 'triedenie'
}

const emptySelectState = {
	value: null,
	label: 'Všetky'
}

class UpomienkyPage extends reactQueryParams {
	static propTypes = {
		obchodnyPartnerActions: PropTypes.shape(),
		upomienky: PropTypes.shape()
	}

	state = {}

	defaultQueryParams = {
		backUrl: '',
		[queryParams.pohladavkaCislo]: null,
		[queryParams.zmluvnyUcetCislo]: null,
		[queryParams.triedenie]: null,
		[queryParams.vystavenieDatumDo]: null,
		[queryParams.vystavenieDatumOd]: null,
		[queryParams.upominanieMetoda]: null,
		[queryParams.upominanieStupen]: null
	}

	componentDidUpdate = (prevProps) => {
		const { selectedFilters, filtersActions, obchodnyPartnerActions } = this.props

		// prevent to inject not allowed query param
		const query = extractProperties(this.queryParams, values(queryParams))

		if (!isEqual(selectedFilters, query)) {
			this.setQueryParams({
				...selectedFilters
			})
		}

		if (get(selectedFilters, queryParams.upominanieMetoda) != get(prevProps, `selectedFilters.${queryParams.upominanieMetoda}`)) {
			const newFilter = {
				...selectedFilters,
				upominanieStupen: this.defaultQueryParams[queryParams.upominanieMetoda]
			}
			obchodnyPartnerActions.loadUpomienky(1, newFilter)
			filtersActions.selectedFiltersChanged(FILTER_SELECTORS.UPOMIENKY_PAGE, newFilter)
		}
	}

	componentDidMount = () => {
		const { obchodnyPartnerActions, ciselnikySelectBoxActions, selectedFilters } = this.props

		const { backUrl, pohladavkaCislo } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		}

		const newFilter = {
			...selectedFilters
		}
		if (pohladavkaCislo && get(selectedFilters, 'pohladavkaCislo') !== pohladavkaCislo) {
			newFilter[queryParams.pohladavkaCislo] = pohladavkaCislo
		}
		if (backUrlLink) {
			newFilter[queryParams.upominanieMetoda] = null
			newFilter[queryParams.upominanieStupen] = null
			newFilter[queryParams.zmluvnyUcetCislo] = null
			newFilter[queryParams.vystavenieDatumDo] = null
			newFilter[queryParams.vystavenieDatumOd] = null
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			backUrl: ''
		})

		this.setState({
			backUrlLink
		})

		const filteredParams = pick(
			this.queryParams,
			keys(this.queryParams).filter((key) => {
				return indexOf(values(queryParams), key) !== -1 && !isEmpty(this.queryParams[key])
			})
		)

		const filters = {
			...newFilter,
			...filteredParams
		}

		obchodnyPartnerActions.loadUpomienky(1, filters)
		ciselnikySelectBoxActions.loadCiselnikZmluvneUcty()

		// update filter state
		this.props.filtersActions.selectedFiltersChanged(FILTER_SELECTORS.UPOMIENKY_PAGE, newFilter)
	}

	loadMore = () => {
		const { upomienky, obchodnyPartnerActions, selectedFilters } = this.props
		const nextPage = get(upomienky, 'page') + 1

		obchodnyPartnerActions.loadUpomienky(nextPage, selectedFilters)
	}

	confirmModal = () => {
		const { interakcia } = this.props
		const { success, redirect } = this.state

		if (success) {
			this.setState(
				{
					result: null,
					success: false
				},
				() => {
					if (redirect) {
						history.push(setRouteParams(UPOMIENKY_ZOZNAM, get(interakcia, 'data.opCislo')))
					}
				}
			)
		} else {
			this.setState({
				result: null
			})
		}
	}

	onSubmitUkonUC05b = async (data) => {
		const { interakcia, t, auth, tracking, trackingActions, historiaUkonovActions, selectedFiltersUkony } = this.props
		const { upomienka } = this.state

		try {
			let notifikacie = {}
			if (get(data, 'address')) {
				set(notifikacie, 'adresyPosta', [get(data, 'address')])
			}

			if (get(data, 'email')) {
				set(notifikacie, 'adresyUri', [
					{
						typ: NOTIFICATION_TYPES.EMAIL,
						hodnota: get(data, 'email')
					}
				])
			}

			if (get(data, 'typ') === NOTIFICATION_TYPES.PRINTER) {
				set(notifikacie, 'adresyUri', [
					{
						typ: NOTIFICATION_TYPES.PRINTER,
						poradie: 0
					}
				])
			}

			notifikacie = isEmpty(notifikacie) ? undefined : notifikacie
			const body = {
				ukon: {
					interakciaId: interakcia.data.id,
					riesitel: auth.user.id,
					kanal: auth.businessChannel.actual,
					trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
					poznamka: get(data, 'poznamka.value'),
					zacatyOd: dayjs(tracking.startTime).toISOString(),
					vstup: {
						id: get(data, 'ukonVstup.value.value'),
						nazov: get(data, 'ukonVstup.value.label')
					}
				},
				ukonData: {
					opCislo: get(interakcia, 'data.opCislo'),
					upomienkaId: get(upomienka, 'id')
				}
			}
			const res = await postReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/ukon-duplikat-upomienky/vytvorit`, null, body)
			const ukonId = get(res, 'response.obsah.ukon.id')
			const spustitBody = {
				...body.ukon,
				trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
				id: ukonId,
				notifikacie
			}
			await postReq(`/api/v0/ukony/${ukonId}/spustit`, null, spustitBody)
			// clear tracking timer
			trackingActions.clearTracking()
			// refetch new data for historia ukonov
			historiaUkonovActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)

			this.setState({
				result: t('containers:UpomienkaDetailPage.Duplikát upomienky bol úspešne odoslaný'),
				success: true,
				showModalUkonUC05b: false
			})
		} catch (e) {
			this.setState({
				result: t('containers:UpomienkaDetailPage.Počas odosielania úkonu nastala chyba'),
				success: false
			})
		}
	}

	render = () => {
		const { upomienky, t, interakcia, ciselniky } = this.props
		const { showModalUkonUC05b, result, success, upomienka } = this.state

		if (get(upomienky, 'page') === 1 && get(upomienky, 'isLoading')) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (get(upomienky, 'isFailure')) {
			return this.commonContentContainer(<ElementFailure text={t('containers:UpomienkyPage.Nepodarilo sa načítať zoznam upomienok')} />)
		}

		if (isEmpty(get(upomienky, 'data'))) {
			return this.commonContentContainer(
				<ElementEmptyContent
					text={t(
						'containers:UpomienkyPage.Pre obchodného partnera neevidujeme žiadne upomienky, alebo zadanému filtru nekorešponduje žiadna upomienka'
					)}
				/>
			)
		}

		const loadMoreBtnText = get(upomienky, 'isLoading') ? t('containers:UpomienkyPage.Načítavam') : t('containers:UpomienkyPage.Načítať ďalšie upomienky')

		let modal = null
		if (showModalUkonUC05b) {
			modal = (
				<ModalSendItemDuplicate
					zuCislo={get(upomienka, 'zmluvnyUcetCislo')}
					ukonTyp={UKONY.DUPLIKAT_UPOMIENKY}
					dokumentId={get(upomienka, 'dokumentId')}
					dokumentName={`${t('translation:Upomienky.upomienka')}.pdf`}
					notificationTypes={[NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.PRINTER]}
					modalTitle={t('containers:UpomienkaDetailPage.Zaslanie duplikátu upomienky')}
					onCloseButton={() => this.setState({ showModalUkonUC05b: false })}
					onSubmit={this.onSubmitUkonUC05b}
				/>
			)
		}

		if (result) {
			modal = (
				<DefaultModal
					modalTitle={success ? t('containers:Odoslané') : t('containers:Chyba')}
					modalContent={result}
					leftButton={{
						onClick: this.confirmModal,
						text: t('containers:Zavrieť'),
						color: success ? 'green' : 'red'
					}}
					visible
				/>
			)
		}

		const upomienkyRows = map(get(upomienky, 'data'), (upomienka) => {
			const upominanieStupenNazvy = orderBy(
				filter(get(ciselniky, 'data.upominanieStupen'), (stupen) => {
					return get(stupen, 'id.upominanieMetodaId') == get(upomienka, 'upominanieStupen.id.upominanieMetodaId')
				}),
				['id.id'],
				['asc']
			)
			return (
				<UpomienkaRow
					key={get(upomienka, 'id')}
					upomienka={upomienka}
					interakcia={interakcia}
					upominanieStupenNazvy={upominanieStupenNazvy}
					onClickUkonUC05b={() => this.setState({ showModalUkonUC05b: true, upomienka })}
					t={t}
				/>
			)
		})

		return this.commonContentContainer(
			<div className='content-wrapper'>
				<div className='row'>
					<div className='col-12'>
						<div className='box'>
							<div className='box-content'>
								{modal}
								<table className='content-table bordered hoverable' cellSpacing='0'>
									<thead>
										<tr>
											<th style={{ width: '110px' }}>{t('containers:UpomienkyPage.Dátum vystavenia')}</th>
											<th style={{ width: '110px' }}>{t('containers:UpomienkyPage.Číslo ZÚ')}</th>
											<th style={{ width: '110px' }}>{t('containers:UpomienkyPage.Upomínaná suma')}</th>
											<th style={{ width: '110px' }}>{t('containers:UpomienkyPage.Poplatok')}</th>
											<th>{t('containers:UpomienkyPage.Metóda upomínania')}</th>
											<th>{t('containers:UpomienkyPage.Forma a stupeň upomienky')}</th>
											<th style={{ width: '180px' }}>{t('containers:UpomienkyPage.Podacie číslo zásielky')}</th>
											<th style={{ width: '160px' }} />
										</tr>
									</thead>
									<tbody data-empty={t('containers:UpomienkyPage.Pre tohoto OP nie sú evidované žiadne upomienky')}>{upomienkyRows}</tbody>
								</table>
								{!get(upomienky, 'isLastPage') && (
									<div className='text-center'>
										<button className='button' data-color='blue' data-type='outline' style={{ margin: '20px 0' }} onClick={this.loadMore}>
											{loadMoreBtnText}
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	searchLoadUpomienky = debounce((newFilter) => {
		const { obchodnyPartnerActions } = this.props

		obchodnyPartnerActions.loadUpomienky(1, newFilter)
	}, 600)

	onChangeSearchInput = (value, fieldName) => {
		const trimmed = trim(value)

		const { filtersActions, selectedFilters } = this.props
		// stringify value or set a default value null
		const newFilter = {
			...selectedFilters,
			[fieldName]: trimmed ? `${trimmed}` : null
		}
		if (isEqual(newFilter[fieldName], selectedFilters[fieldName])) {
			return
		}

		this.searchLoadUpomienky(newFilter)

		// update filter state
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.UPOMIENKY_PAGE, newFilter)
	}

	removeFilters = () => {
		const { obchodnyPartnerActions, filtersActions, selectedFilters } = this.props

		const newFilter = {
			...this.defaultQueryParams
		}

		if (isEqual(selectedFilters, newFilter)) {
			return
		}

		obchodnyPartnerActions.loadUpomienky(1, newFilter)

		// update filter state
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.UPOMIENKY_PAGE, newFilter)
	}

	filterChanged = (filter, fieldName) => {
		const { obchodnyPartnerActions, filtersActions, selectedFilters } = this.props

		const newFilter = {
			...selectedFilters,
			[fieldName]: null
		}

		switch (fieldName) {
			case queryParams.upominanieMetoda:
			case queryParams.upominanieStupen:
			case queryParams.zmluvnyUcetCislo:
				newFilter[fieldName] = filter
				break
			case queryParams.vystavenieDatumOd:
			case queryParams.vystavenieDatumDo:
				if (filter) {
					newFilter[fieldName] = dayjs(filter).format('YYYY-MM-DD')
				}
				break
		}
		if (isEqual(newFilter[fieldName], selectedFilters[fieldName])) {
			return
		}
		obchodnyPartnerActions.loadUpomienky(1, newFilter)

		// update filter state
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.UPOMIENKY_PAGE, newFilter)
	}

	datePickerRaw = (value, type) => {
		const pickedDate = dayjs(value)
		if (pickedDate.isValid()) {
			this.filterChanged(pickedDate, type)
		}
	}

	filterElement = () => {
		const { t, selectedFilters, ciselniky, ciselnikySelectBox } = this.props
		const { backUrlLink } = this.state

		const stupenUpominaniaOptions = get(selectedFilters, queryParams.upominanieMetoda)
			? map(
					orderBy(
						filter(
							get(ciselniky, 'data.upominanieStupen'),
							(upominanieStupen) => get(upominanieStupen, 'id.upominanieMetodaId') == get(selectedFilters, queryParams.upominanieMetoda)
						),
						['id.id'],
						['asc']
					),
					(upominanieStupen) => ({
						value: `${get(upominanieStupen, 'id.id')}`,
						label: `${get(upominanieStupen, 'id.id')} - ${get(upominanieStupen, 'nazov')}`
					})
			  )
			: []

		const metodaUpominaniaOptions = map(get(ciselniky, 'data.upominanieMetoda'), (metodaUpominania) => ({
			value: metodaUpominania.id,
			label: metodaUpominania.nazov
		}))

		// build data for select box filter zmluvne ucty
		let zmluvnyUcetCisloFilterOptions = []
		if (get(ciselnikySelectBox, 'zmluvneUcty.data')) {
			zmluvnyUcetCisloFilterOptions = map(get(ciselnikySelectBox, 'zmluvneUcty.data'), (zmluvnyUcet) => ({
				value: zmluvnyUcet.cislo,
				label: zmluvnyUcet.cislo
			}))
		}
		const datumVystaveniaOdFilter = selectedFilters[queryParams.vystavenieDatumOd] ? dayjs(selectedFilters[queryParams.vystavenieDatumOd]).toDate() : null
		const datumVystaveniaDoFilter = selectedFilters[queryParams.vystavenieDatumDo] ? dayjs(selectedFilters[queryParams.vystavenieDatumDo]).toDate() : null

		return (
			<div className='content-header'>
				{backUrlLink && (
					<Link to={backUrlLink} className='button pull-left' data-type='back-button' data-color='blue'>
						{t('containers:Späť')}
					</Link>
				)}
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ width: '13.5%' }}>
						<div className='input-wrapper no-icon'>
							<label>{t('containers:UpomienkyPage.Vyhľadávanie')}</label>
							<input
								type='text'
								className='input-field'
								data-type='search'
								placeholder='Zadajte hľadaný výraz ...'
								value={get(selectedFilters, queryParams.pohladavkaCislo) || ''}
								onChange={(e) => this.onChangeSearchInput(e.target.value, queryParams.pohladavkaCislo)}
							/>
						</div>
					</div>
					<div style={{ width: '13.5%' }}>
						<div className='select-wrapper'>
							<label>{t('containers:UpomienkyPage.Zmluvný účet')}</label>
							<Select
								value={
									find(zmluvnyUcetCisloFilterOptions, (element) => element.value == get(selectedFilters, queryParams.zmluvnyUcetCislo)) ||
									emptySelectState
								}
								onChange={(e) => this.filterChanged(e.value, queryParams.zmluvnyUcetCislo)}
								options={[emptySelectState, ...zmluvnyUcetCisloFilterOptions]}
								isLoading={!ciselnikySelectBox.zmluvneUcty.data}
								isDisabled={!ciselnikySelectBox.zmluvneUcty.data}
								classNamePrefix='react-select'
							/>
						</div>
					</div>
					<div style={{ width: '13.5%' }}>
						<div className='select-wrapper'>
							<label>{t('containers:UpomienkyPage.Metóda upomínania')}</label>
							<Select
								value={
									find(metodaUpominaniaOptions, (option) => option.value == get(selectedFilters, queryParams.upominanieMetoda)) ||
									emptySelectState
								}
								classNamePrefix='react-select'
								options={[emptySelectState, ...metodaUpominaniaOptions]}
								onChange={(e) => this.filterChanged(e.value, queryParams.upominanieMetoda)}
							/>
						</div>
					</div>
					<div style={{ width: '13.5%' }}>
						<div className='select-wrapper'>
							<label>{t('containers:UpomienkyPage.Stupeň upomínania')}</label>
							<Select
								value={
									find(stupenUpominaniaOptions, (option) => option.value == get(selectedFilters, queryParams.upominanieStupen)) ||
									emptySelectState
								}
								classNamePrefix='react-select'
								options={[emptySelectState, ...stupenUpominaniaOptions]}
								onChange={(e) => this.filterChanged(e.value, queryParams.upominanieStupen)}
								isDisabled={!get(selectedFilters, queryParams.upominanieMetoda)}
							/>
						</div>
					</div>
					<div style={{ width: '13.5%' }}>
						<div className='input-wrapper'>
							<label>{t('containers:UpomienkyPage.Dátum vystavenia od')}</label>
							<DatePicker
								locale='sk'
								dateFormat={'dd.MM.yyyy'}
								selected={datumVystaveniaOdFilter}
								onChange={(value) => this.filterChanged(value, queryParams.vystavenieDatumOd)}
								onChangeRaw={(e) => this.datePickerRaw(e.target.value, queryParams.vystavenieDatumOd)}
								tabIndex={50}
								showYearDropdown
								scrollableYearDropdown
								placeholderText='Zvoľte dátum'
								isClearable
								maxDate={datumVystaveniaDoFilter}
							/>
						</div>
					</div>
					<div style={{ width: '13.5%' }}>
						<div className='input-wrapper'>
							<label>{t('containers:UpomienkyPage.Dátum vystavenia do')}</label>
							<DatePicker
								locale='sk'
								dateFormat={'dd.MM.yyyy'}
								selected={datumVystaveniaDoFilter}
								onChange={(value) => this.filterChanged(value, queryParams.vystavenieDatumDo)}
								onChangeRaw={(e) => this.datePickerRaw(e.target.value, queryParams.vystavenieDatumDo)}
								tabIndex={50}
								showYearDropdown
								scrollableYearDropdown
								placeholderText='Zvoľte dátum'
								isClearable
								minDate={datumVystaveniaOdFilter}
							/>
						</div>
					</div>
					<div style={{ width: '13.5%' }}>
						<div className='input-wrapper'>
							<button
								className='button full-width'
								type='button'
								data-type='outline'
								data-color='blue'
								onClick={this.removeFilters}
								style={{ marginTop: '41px' }}
							>
								{t('translation:Common.filter.Zrušiť filtre')}
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	}

	commonContentContainer = (content) => {
		return (
			<>
				{this.filterElement()}
				{content}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	upomienky: state.obchodnyPartner.upomienky,
	ciselniky: state.ciselniky,
	interakcia: state.interakcie.detail,
	tracking: state.tracking,
	ciselnikySelectBox: state.ciselnikySelectBox,
	selectedFilters: get(state.selectedFilters, FILTER_SELECTORS.UPOMIENKY_PAGE, {}),
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch),
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	historiaUkonovActions: bindActionCreators(HistoriaUkonovActions, dispatch),
	ciselnikySelectBoxActions: bindActionCreators(CiselnikySelectBoxActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_UPOMIENKY])
)(UpomienkyPage)
