import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import { compose } from 'redux'
import { map } from 'lodash'

class DigiScore extends React.Component {
	static propTypes = {
		skore: PropTypes.number,
		t: PropTypes.func.isRequired
	}

	render() {
		const { skore, t } = this.props

		if (skore === null || skore < 0 || skore > 100) {
			return null
		}
		const skoreString = `${skore}p`
		const skoreElement = map(skoreString, (number, index) => {
			if (index == 0 && number == '1') {
				return <span key={index} className={`digi-score n1_thin`} />
			}
			return <span key={index} className={`digi-score n${number}`} />
		})
		return (
			<Tooltip
				html={
					<p>
						<strong>{skore}</strong> - {t('translation:Digitalizacia.Digitalizačné skóre OP')}
					</p>
				}
				position='bottom'
				trigger='mouseenter'
				theme='light'
				disabled={false}
			>
				<div className='square-label blue' style={{ padding: '5px 0 3px 0', minWidth: '50px' }}>
					{skoreElement}
				</div>
			</Tooltip>
		)
	}
}

export default compose(withTranslation('components'))(DigiScore)
