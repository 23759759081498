import { SEARCH_USERS_START, SEARCH_USERS_FAIL, SEARCH_USERS_DONE, RESET_STORAGE, SEARCH_USERS_CLEAR } from '../types/search'

import config from '../utils/config'

export const initStrankovanie = {
	aktualnaStranka: 0,
	velkostStranky: config.searchUserLimit,
	zaznamov: 0
}

export const initState = {
	obchodniPartneri: [],
	strankovanie: {
		...initStrankovanie
	},
	status: [],
	isLoading: false,
	isReady: false,
	isFailure: false
}

export default (state = initState, action) => {
	switch (action.type) {
		case SEARCH_USERS_START:
			return {
				...state,
				chosen: true,
				isReady: true,
				isLoading: true,
				isFailure: false
			}
		case SEARCH_USERS_DONE:
			return {
				...state,
				isLoading: false,
				isReady: true,
				obchodniPartneri: action.payload.obchodniPartneri.content,
				strankovanie: {
					aktualnaStranka: action.payload.obchodniPartneri.content.page,
					velkostStranky: action.payload.obchodniPartneri.content.pageSize
				},
				status: action.payload.obchodniPartneri.status,
				isFailure: false
			}
		case SEARCH_USERS_FAIL:
			return {
				...state,
				isLoading: false,
				isReady: true,
				obchodniPartneri: [],
				strankovanie: {
					...initStrankovanie
				},
				isFailure: true
			}
		case SEARCH_USERS_CLEAR:
			return {
				...initState
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
