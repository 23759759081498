import { get } from 'lodash'

const validate = (values, props) => {
	const { t, originalValues } = props

	const warnings = {}

	if (!get(originalValues, 'narodenieDatum') && !get(values, 'narodenieDatum')) {
		warnings.narodenieDatum = t('translation:Common.validate.Dátum narodenia je povinný')
	}

	return warnings
}

export default validate
