import React from 'react'
import PropTypes from 'prop-types'
import DefaultModal from './DefaultModal'

function LeavePageModal(props) {
	const { t, onConfirm, onCancel } = props
	const content = (
		<div>
			{t('components:LeavePageModal.Chystáte sa opustiť spracovanie úkonu')}
			{t('components:LeavePageModal.Táto zmena zahodí všetky neuložené zmeny')}
			<br />
			{t('components:LeavePageModal.Prajete si pokračovať a opustiť úkon?')}
		</div>
	)
	return (
		<DefaultModal
			modalTitle={t('components:LeavePageModal.Upozornenie!')}
			modalContent={content}
			rightButton={{
				onClick: onCancel,
				text: t('components:LeavePageModal.Naspäť do úkonu'),
				color: 'blue'
			}}
			leftButton={{
				onClick: onConfirm,
				text: t('components:LeavePageModal.Opustiť úkon'),
				color: 'red',
				outline: true
			}}
			visible
		/>
	)
}

LeavePageModal.propTypes = {
	t: PropTypes.string.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
}

export default LeavePageModal
