import { get } from 'lodash'
import { PK_GENERIC_UKON_CONTEXT_LOAD_START, PK_GENERIC_UKON_CONTEXT_LOAD_DONE, PK_GENERIC_UKON_CONTEXT_LOAD_FAIL } from '../../types/procesnyKonfigurator'

import { postReq } from '../../utils/request'

export const loadContext = (ukon) => {
	return async (dispatch) => {
		dispatch({
			type: PK_GENERIC_UKON_CONTEXT_LOAD_START
		})

		try {
			const contextData = await postReq(`/api/v2/ukony/process-context`, null, ukon)
			const context = get(contextData, 'response.content', null)

			dispatch({
				type: PK_GENERIC_UKON_CONTEXT_LOAD_DONE,
				payload: {
					context
				}
			})

			return context
		} catch (e) {
			dispatch({
				type: PK_GENERIC_UKON_CONTEXT_LOAD_FAIL
			})
		}
	}
}
