export const PREPLATKY_ALL_LOAD_START = 'PREPLATKY_ALL_LOAD_START'
export const PREPLATKY_LOAD_START = 'PREPLATKY_LOAD_START'
export const PREPLATKY_ALL_LOAD_PART_DONE = 'PREPLATKY_ALL_LOAD_PART_DONE'
export const PREPLATKY_LOAD_DONE = 'PREPLATKY_LOAD_DONE'
export const PREPLATKY_LOAD_FAIL = 'PREPLATKY_LOAD_FAIL'
export const PREPLATKY_CLEAR = 'PREPLATKY_CLEAR'

export const BLOKOVANIE_UPOMINANIA_ALL_LOAD_START = 'BLOKOVANIE_UPOMINANIA_ALL_LOAD_START'
export const BLOKOVANIE_UPOMINANIA_LOAD_START = 'BLOKOVANIE_UPOMINANIA_LOAD_START'
export const BLOKOVANIE_UPOMINANIA_ALL_LOAD_PART_DONE = 'BLOKOVANIE_UPOMINANIA_ALL_LOAD_PART_DONE'
export const BLOKOVANIE_UPOMINANIA_LOAD_DONE = 'BLOKOVANIE_UPOMINANIA_LOAD_DONE'
export const BLOKOVANIE_UPOMINANIA_LOAD_FAIL = 'BLOKOVANIE_UPOMINANIA_LOAD_FAIL'
export const BLOKOVANIE_UPOMINANIA_CLEAR = 'BLOKOVANIE_UPOMINANIA_CLEAR'
