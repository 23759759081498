import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { get, join, map, isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { Tooltip } from 'react-tippy'

import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'

// utils
import { formatAddress } from '../../utils/address'
import { formatDate } from '../../utils/date'
import { SPLNOMOCNENIE_STAV_ICON, OBCHODNY_PARTNER_TYP } from '../../utils/enums'
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { openDataUriWindow } from '../../utils/files'
import { setRouteParams, VZTAHY_ZOZNAM, OP_DETAIL } from '../../utils/routes'
import { celeMeno } from '../../utils/obchodnyPartner'

class VztahDetailPage extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape(),
		obchodnyPartnerActions: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		vztahDetail: PropTypes.shape(),
		computedMatch: PropTypes.shape().isRequired
	}

	componentDidMount() {
		const { obchodnyPartnerActions, computedMatch } = this.props
		const { params } = computedMatch

		const cisloOP = get(params, 'cisloOP')
		const cisloSplnomocnenca = get(params, 'cisloSplnomocnenca')
		const typVztahu = get(params, 'typVztahu')

		obchodnyPartnerActions.loadVztahDetail(cisloOP, cisloSplnomocnenca, typVztahu)
	}

	showOP2Link = () => {
		const { vztahDetail } = this.props
		const op2Typ = get(vztahDetail, 'data.op2.typ', null)
		const vztahTyp = get(vztahDetail, 'data.vztah.typ.id', null)

		const OBCHODNICI = ['ZZPSME', 'ZZPSMT', 'ZMCAM', 'ZMCKAM']

		if (OBCHODNICI.includes(vztahTyp)) {
			return false
		}

		if (op2Typ !== OBCHODNY_PARTNER_TYP.MOO) {
			return false
		}

		return true
	}

	render() {
		const { interakcia, t, vztahDetail } = this.props

		if (vztahDetail.isFailure) {
			return <ElementFailure text={t('containers:VztahDetailPage.Nepodarilo sa načítať detail vzťahu')} />
		}

		if (vztahDetail.isLoading) {
			return <ElementLoading />
		}

		const vztahDetailData = get(vztahDetail, 'data.vztah', null)
		const op2 = get(vztahDetail, 'data.op2', null)

		if (isEmpty(vztahDetailData)) {
			return <ElementEmptyContent text={t('containers:SplnomocneniaCancelPage.Vzťah neexistuje')} />
		}

		let stav = SPLNOMOCNENIE_STAV_ICON.ZATIAL_NEAKTIVNE

		const platnostDatum = []
		if (get(vztahDetailData, 'platnostOd')) {
			platnostDatum.push(formatDate(get(vztahDetailData, 'platnostOd'), null, 'DD.MM.YYYY'))
			if (dayjs(get(vztahDetailData, 'platnostOd')).endOf('day') > dayjs().endOf('day')) {
				stav = SPLNOMOCNENIE_STAV_ICON.ZATIAL_NEAKTIVNE
			} else {
				stav = SPLNOMOCNENIE_STAV_ICON.AKTIVNE
			}
		}
		if (get(vztahDetailData, 'platnostDo')) {
			platnostDatum.push(formatDate(get(vztahDetailData, 'platnostDo'), null, 'DD.MM.YYYY'))
			if (dayjs(get(vztahDetailData, 'platnostDo')).endOf('day') < dayjs().endOf('day')) {
				stav = SPLNOMOCNENIE_STAV_ICON.NEAKTIVNE
			}
		} else {
			platnostDatum.push(t('components:SplnomocneniaTableRow.neurčito'))
		}

		const isOP2LinkVisible = this.showOP2Link()

		return (
			<>
				<div className='content-header clearfix'>
					<Link
						to={setRouteParams(VZTAHY_ZOZNAM, get(interakcia, 'data.opCislo'))}
						className='button pull-left'
						data-type='back-button'
						data-color='blue'
					>
						{t('containers:VztahDetailPage.Späť')}
					</Link>
					<div className='header-title pull-left'>{t('containers:VztahDetailPage.Detail vzťahu')}</div>
				</div>

				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-content'>
							<div className='inner-box'>
								<div className='row'>
									<div className='col-6'>
										<table className='content-table'>
											<tbody>
												<tr>
													<td>{t('containers:VztahDetailPage.Typ vzťahu')}</td>
													<td>
														<strong>{get(vztahDetailData, 'typ.nazov', '-')}</strong>
													</td>
												</tr>
												{get(op2, 'typ') === OBCHODNY_PARTNER_TYP.MOP && (
													<tr>
														<td>{t('containers:VztahDetailPage.Obchodné meno')}</td>
														<td>
															<strong>{get(op2, 'obchodneMeno', '-')}</strong>
														</td>
													</tr>
												)}
												{(get(op2, 'typ') === OBCHODNY_PARTNER_TYP.MOO || get(op2, 'typ') === OBCHODNY_PARTNER_TYP.OSTATNI) && (
													<tr>
														<td>{t('containers:VztahDetailPage.Meno OP')}</td>
														<td>
															<strong>{celeMeno(op2) || '-'}</strong>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
									<div className='col-6'>
										<table className='content-table'>
											<tbody>
												<tr>
													<td>{t('containers:VztahDetailPage.Číslo OP')}</td>
													<td style={{ display: 'flex', alignItems: 'center' }}>
														<strong>{get(op2, 'cislo', '-')}</strong>
														{isOP2LinkVisible && (
															<Tooltip
																html={t('containers:VztahDetailPage.Zobraziť detail OP v novom tabe')}
																position='bottom'
																trigger='mouseenter'
																theme='light'
																style={{ marginLeft: '10px' }}
															>
																<Link
																	to={setRouteParams(OP_DETAIL, get(op2, 'cislo'))}
																	className='button'
																	data-type='icon-button'
																	data-icon='view'
																	target='_blank'
																/>
															</Tooltip>
														)}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-6'>
									<table className='content-table'>
										<thead>
											<tr>
												<th colSpan='2'>{t('containers:VztahDetailPage.Základné informácie')}</th>
											</tr>
										</thead>
										<tbody>
											{get(op2, 'typ') === OBCHODNY_PARTNER_TYP.MOO && (
												<tr>
													<td>{t('containers:VztahDetailPage.Adresa')}</td>
													<td>
														<strong>{formatAddress(get(op2, 'adresaZakaznika')) || '-'}</strong>
													</td>
												</tr>
											)}
											<tr>
												<td>{t('containers:VztahDetailPage.Tel číslo')}</td>
												<td>
													<strong>{get(op2, 'kontaktnyTelefon', '-')}</strong>
												</td>
											</tr>
											<tr>
												<td>{t('containers:VztahDetailPage.E-mail')}</td>
												<td>
													<strong>{get(op2, 'kontaktnyEmail', '-')}</strong>
												</td>
											</tr>
											{get(op2, 'typ') === OBCHODNY_PARTNER_TYP.MOO && (
												<tr>
													<td>{t('containers:VztahDetailPage.Dátum narodenia')}</td>
													<td>
														<strong>{formatDate(get(op2, 'narodenieDatum'), '-', 'DD.MM.YYYY')}</strong>
													</td>
												</tr>
											)}
											{get(op2, 'typ') === OBCHODNY_PARTNER_TYP.MOP && (
												<tr>
													<td>{t('containers:VztahDetailPage.IČO')}</td>
													<td>
														<strong>{get(op2, 'ICO', '-')}</strong>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								<div className='col-6'>
									{!isEmpty(platnostDatum) && (
										<table className='content-table' style={{ height: 'auto' }}>
											<thead>
												<tr>
													<th>{t('containers:VztahDetailPage.Platnosť od - do')}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														<span>{join(platnostDatum, ' - ')}</span>
														<Tooltip
															html={
																<strong>
																	{stav == SPLNOMOCNENIE_STAV_ICON.AKTIVNE
																		? t('containers:VztahDetailPage.Aktívny vzťah')
																		: t('containers:VztahDetailPage.Neaktívny vzťah')}
																</strong>
															}
															position='left'
															trigger='mouseenter'
															theme='light'
														>
															<div
																className='label circle'
																data-color={stav}
																style={{ marginLeft: '15px', verticalAlign: '-3px' }}
															/>
														</Tooltip>
													</td>
												</tr>
											</tbody>
										</table>
									)}
									{!isEmpty(get(vztahDetailData, 'dokumenty', [])) && (
										<table className='content-table' style={{ height: 'auto' }}>
											<thead>
												<tr>
													<th>{t('containers:VztahDetailPage.Dokumenty vzťahu')}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														{map(get(vztahDetailData, 'dokumenty', []), (dokument) => (
															<a
																key={get(dokument, 'id')}
																className='file'
																data-type='general'
																style={{ cursor: 'pointer', marginRight: '20px' }}
																target='_blank'
																onClick={(e) => {
																	e.preventDefault()
																	openDataUriWindow(get(dokument, 'id'), get(dokument, 'nazov', '-'))
																}}
															>
																{get(dokument, 'nazov', '-')}
															</a>
														))}
													</td>
												</tr>
											</tbody>
										</table>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	vztahDetail: state.obchodnyPartner.vztahDetail
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_SPLNOMOCNENIE])
)(VztahDetailPage)
