import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'

// atoms
import CheckBoxField from '../../atoms/CheckboxField'

// utils
import { formatAddress } from '../../utils/address'
import { getStateIcon } from '../../utils/odberneMiesta'
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'

function OdberneMiestoSearchRow(props) {
	const {
		cislo,
		identifikator,
		adresa,
		selectedOmCislo,
		disabledCheckbox,
		disabledCheckboxText,
		onClickCheckbox,
		onClick,
		checkRows,
		aktivnaKomoditnaZmluva,
		t
	} = props

	let checkbox = (
		<CheckBoxField
			disabled={disabledCheckbox}
			input={{
				value: cislo == selectedOmCislo,
				onChange: onClickCheckbox,
				label: ''
			}}
		/>
	)
	if (disabledCheckbox && disabledCheckboxText) {
		checkbox = (
			<Tooltip html={<span>{disabledCheckboxText}</span>} position='left' trigger='mouseenter' theme='light'>
				{checkbox}
			</Tooltip>
		)
	}
	return (
		<tr key={cislo} onClick={onClick}>
			<td style={{ paddingLeft: '25px' }}>{identifikator || '-'}</td>
			<td>{formatAddress(adresa) || '-'}</td>
			<td style={{ textAlign: 'center' }}>
				{getStateIcon(
					{
						aktivnaKomoditnaZmluva
					},
					t
				)}
			</td>
			<Permissions
				allowed={[PERMISSIONS.UKON_OBNOVA_MS]}
				render={(hasPerm) => {
					if (!hasPerm && !aktivnaKomoditnaZmluva) {
						return <td />
					}
					return checkRows ? <td>{checkbox}</td> : null
				}}
			/>
		</tr>
	)
}

OdberneMiestoSearchRow.propTypes = {
	cislo: PropTypes.string,
	identifikator: PropTypes.string,
	adresa: PropTypes.shape({
		stat: PropTypes.string,
		obec: PropTypes.string,
		ulica: PropTypes.string,
		orientacneCislo: PropTypes.string,
		supisneCislo: PropTypes.string,
		psc: PropTypes.string
	}),
	selectedOmCislo: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	onClickCheckbox: PropTypes.func,
	disabledCheckbox: PropTypes.bool,
	disabledCheckboxText: PropTypes.string,
	checkRows: PropTypes.bool,
	aktivnaKomoditnaZmluva: PropTypes.bool,
	t: PropTypes.func.isRequired
}

export default OdberneMiestoSearchRow
