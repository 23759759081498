import React from 'react'
import { map, get, uniqBy, keyBy, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// utils
import { getReq } from '../../utils/request'
import { formatAddress, miestoSpotrebyAdresaDoplnokFormat } from '../../utils/address'
import { checkPermissions } from '../../utils/permissionsHoc'
import { getIconByKomoditaTyp } from '../../utils/rozpisyZaloh'

// components
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'
import ElementEmptyContent from '../ElementEmptyContent'

class OdberneMiestaWidget extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape(),
		permission: PropTypes.string
	}

	constructor(props) {
		super(props)
		this.state = {
			hasPermission: checkPermissions(get(props, 'authUser.roles', []), [props.permission]),
			odberneMiesta: {
				data: null,
				stranka: 1,
				velkostStranky: 3,
				isLoading: false,
				isFailure: false,
				isLastPage: false
			}
		}
	}

	_mounted = false

	onScrollHandler = (e) => {
		const { odberneMiesta } = this.state
		const { isLoading, isLastPage, stranka } = odberneMiesta
		const { clientHeight, scrollTop, scrollHeight } = e.target

		if (scrollHeight - clientHeight >= scrollTop - 5 && !isLastPage && !isLoading) {
			const odberneMiestaNextPage = stranka + 1
			this.loadOdberneMiestaByParts(odberneMiestaNextPage)
		}
	}

	loadOdberneMiestaByParts = (page = 1) => {
		const { interakcia } = this.props
		const { odberneMiesta } = this.state
		try {
			this.setState(
				{
					odberneMiesta: {
						...this.state.odberneMiesta,
						stranka: page,
						isLoading: true
					}
				},
				async () => {
					if (!this._mounted) {
						return
					}
					const response = await getReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/odberne-miesta`, {
						stranka: page,
						velkostStranky: odberneMiesta.velkostStranky
					})
					const { velkostStranky, aktualnaStranka, zaznamov } = get(response, 'response.strankovanie')

					const isLastPage = !(velkostStranky * aktualnaStranka < zaznamov)

					const data = {
						odberneMiesta: uniqBy(
							[...get(this.state.odberneMiesta.data, 'odberneMiesta', []), ...get(response, 'response.obsah.odberneMiesta', [])],
							'cislo'
						),
						miestaSpotreby: uniqBy(
							[...get(this.state.odberneMiesta.data, 'miestaSpotreby', []), ...get(response, 'response.obsah.miestaSpotreby', [])],
							'cislo'
						),
						zmluvneUcty: uniqBy(
							[...get(this.state.odberneMiesta.data, 'zmluvneUcty', []), ...get(response, 'response.obsah.zmluvneUcty', [])],
							'cislo'
						),
						zmluvneVztahy: uniqBy(
							[...get(this.state.odberneMiesta.data, 'zmluvneVztahy', []), ...get(response, 'response.obsah.zmluvneVztahy', [])],
							'cislo'
						)
					}
					this.setState({
						odberneMiesta: {
							...this.state.odberneMiesta,
							data,
							isLoading: false,
							isLastPage
						}
					})
				}
			)
		} catch (e) {
			this.setState({
				odberneMiesta: {
					...this.state.odberneMiesta,
					isLoading: false,
					isFailure: true
				}
			})
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidMount() {
		this._mounted = true
		const { hasPermission } = this.state
		if (hasPermission) {
			this.loadOdberneMiestaByParts()
		}
	}

	commonContentContainer = (content) => (
		<div className='box-content' onScroll={this.onScrollHandler}>
			{content}
		</div>
	)

	render() {
		const { odberneMiesta, hasPermission } = this.state

		if (!hasPermission) {
			return this.commonContentContainer(<ElementFailure text='Na zobrazenie informácií nemáte potrebné oprávnenia.' />)
		}

		if (odberneMiesta.isLoading && odberneMiesta.stranka === 1) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (odberneMiesta.isFailure) {
			return this.commonContentContainer(<ElementFailure text='Odberné miesta sa nepodarilo načítať.' />)
		}

		if (isEmpty(get(odberneMiesta, 'data.odberneMiesta'))) {
			return this.commonContentContainer(<ElementEmptyContent text='Pre obchodného partnera neevidujeme žiadne odberné miesta.' />)
		}
		const miestaSpotrebyKeyBy = keyBy(odberneMiesta.data.miestaSpotreby, (miestoSpotreby) => miestoSpotreby.cislo)

		const odberneMiestaList = map(get(odberneMiesta, 'data.odberneMiesta', []), (odberneMiesto) => {
			const miestoSpotreby = miestaSpotrebyKeyBy[odberneMiesto.miestoSpotrebyCislo]
			const productIcon = getIconByKomoditaTyp(get(odberneMiesto, 'komoditaTyp'))

			return (
				<Link key={`odberneMiesto-${odberneMiesto.cislo}`} to='#' className='inner-box'>
					<table>
						<tbody>
							<tr>
								<td>Miesto spotreby</td>
								<td className='text-right'>
									<strong>
										{formatAddress(get(miestoSpotreby, 'adresa'))}, {miestoSpotrebyAdresaDoplnokFormat(miestoSpotreby)}
									</strong>
								</td>
							</tr>
							{get(odberneMiesto, 'komoditaTyp.id') == 1 && (
								<tr>
									<td>EIC</td>
									<td className='text-right'>
										<strong>{get(odberneMiesto, 'identifikator', '-')}</strong>
									</td>
								</tr>
							)}
							{get(odberneMiesto, 'komoditaTyp.id') == 2 && (
								<tr>
									<td>POD</td>
									<td className='text-right'>
										<strong>{get(odberneMiesto, 'identifikator', '-')}</strong>
									</td>
								</tr>
							)}
							<tr>
								<td>Produkt</td>
								<td className='text-right'>
									<strong className='icon'>
										{productIcon && <img src={productIcon} alt={get(odberneMiesto, 'komoditaTyp.nazov') || '-'} />}
										<span>{get(odberneMiesto, 'komoditaTyp.nazov') || '-'}</span>
									</strong>
								</td>
							</tr>
							{/* <tr>
							<td>Stav zapojenia</td>
							<td className="text-right">
								<div className="label" data-color="red">TODO:</div>
							</td>
						</tr> */}
						</tbody>
					</table>
				</Link>
			)
		})
		if (odberneMiesta.isLoading) {
			odberneMiestaList.push(
				<div key='odberneMiesta-loading' className='inner-box'>
					<table>
						<tbody>
							<tr>
								<td>
									<ElementLoading />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			)
		}
		return this.commonContentContainer(odberneMiestaList)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	authUser: state.auth.user
})

export default connect(mapStateToProps, null)(OdberneMiestaWidget)
