import dayjs from 'dayjs'

import { TRACKING_START, TRACKING_CLEAR } from '../types/tracking'

export const tryToStartTracking = (type, data) => {
	return async (dispatch) => {
		dispatch(startTracking(type, data))
	}
}

export const startTracking = (type = null, data = null) => {
	return async (dispatch) => {
		dispatch(clearTracking())
		dispatch({
			type: TRACKING_START,
			payload: {
				startTime: dayjs().toISOString(),
				type,
				data
			}
		})
	}
}

export const clearTracking = () => {
	return (dispatch) => {
		dispatch({
			type: TRACKING_CLEAR
		})
	}
}
