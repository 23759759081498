import Axios from 'axios'
import { get } from 'lodash'

import { TEMPLATE_PDF_LOAD_START, TEMPLATE_PDF_LOAD_DONE, TEMPLATE_PDF_LOAD_FAIL } from '../../types/podpisovanieDokumentov'
import { getAccessToken } from '../../utils/auth'
import config from '../../utils/config'

const templatePdfLoadStart = () => {
	return {
		type: TEMPLATE_PDF_LOAD_START
	}
}

const templatePdfDone = (templatePdf) => {
	return {
		type: TEMPLATE_PDF_LOAD_DONE,
		payload: {
			templatePdf
		}
	}
}

const templatePdfFail = () => {
	return {
		type: TEMPLATE_PDF_LOAD_FAIL
	}
}

/**
 *
 * @param {string} opCislo
 * @param {integer} verziaId
 * @param {string} podpisMiesto
 */
export const loadPdfForSigningFromCes = (opCislo, verziaId, podpisMiesto) => {
	return async (dispatch) => {
		try {
			dispatch(templatePdfLoadStart())

			const accessToken = getAccessToken()
			const query = {
				opCislo,
				verziaId,
				podpisMiesto
			}

			const url = '/api/ces/suhlas/generateDocument'
			const res = await Axios({
				baseURL: config.baseUrl,
				url,
				method: 'GET',
				params: {
					opCislo,
					verziaId,
					podpisMiesto
				},
				headers: {
					'Cache-Control': 'no-cache, no-store',
					Pragma: 'no-cache',
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
					'Access-Control-Allow-Credentials': true
				}
			})

			dispatch(
				templatePdfDone({
					dataAsBase64: get(res, 'data.response.data'),
					url: config.baseUrl + url,
					type: get(res, 'data.response.contentType'),
					name: get(res, 'data.response.nazov', '')
				})
			)
		} catch (e) {
			dispatch(templatePdfFail())
		}
	}
}

/**
 *
 * @param {integer} ukonNovyId
 * @param {integer} kanal
 */
export const loadPdfForSigning = (ukonNovyId, kanalId, bezPodpisu) => {
	return async (dispatch) => {
		try {
			dispatch(templatePdfLoadStart())

			const accessToken = getAccessToken()
			const url = `/api/v2/ukony/${ukonNovyId}/generovat-formular`
			const res = await Axios({
				baseURL: config.baseUrl,
				url,
				method: 'GET',
				params: {
					kanalId,
					bezPodpisu
				},
				headers: {
					'Cache-Control': 'no-cache, no-store',
					Pragma: 'no-cache',
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
					'Access-Control-Allow-Credentials': true
				}
			})
			dispatch(
				templatePdfDone({
					dataAsBase64: get(res, 'data.response.content.data'),
					url: config.baseUrl + url,
					type: get(res, 'data.response.content.contentType'),
					name: get(res, 'data.response.content.nazov', '') ? `${get(res, 'data.response.content.nazov', '')}` : ''
				})
			)
		} catch (e) {
			dispatch(templatePdfFail())
		}
	}
}

export const resetTemplatePdf = () => {
	return async (dispatch) => {
		dispatch(templatePdfDone(null))
	}
}
