import {
	PROCESNY_KONFIGURATOR_LOAD_DONE,
	PROCESNY_KONFIGURATOR_LOAD_FAIL,
	PROCESNY_KONFIGURATOR_LOAD_START,
	PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_START,
	PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_DONE,
	PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_FAIL
} from '../types/procesnyKonfigurator'

export const initState = {
	procesnyKonfigurator: {
		data: null,
		isLoading: false,
		isFailure: false,
		lastLoad: new Date(0)
	},
	ukonZmenaPreddavkovychPlatieb: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case PROCESNY_KONFIGURATOR_LOAD_DONE:
			return {
				...state,
				procesnyKonfigurator: {
					...state.procesnyKonfigurator,
					data: action.payload.procesnyKonfigurator,
					isLoading: false,
					isFailure: false,
					lastLoad: new Date()
				}
			}
		case PROCESNY_KONFIGURATOR_LOAD_FAIL:
			return {
				...state,
				procesnyKonfigurator: {
					...state.procesnyKonfigurator,
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case PROCESNY_KONFIGURATOR_LOAD_START:
			return {
				...state,
				procesnyKonfigurator: {
					...state.procesnyKonfigurator,
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_DONE:
			return {
				...state,
				ukonZmenaPreddavkovychPlatieb: {
					...state.ukonZmenaPreddavkovychPlatieb,
					data: action.payload.context,
					isLoading: false,
					isFailure: false
				}
			}
		case PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_FAIL:
			return {
				...state,
				ukonZmenaPreddavkovychPlatieb: {
					...state.ukonZmenaPreddavkovychPlatieb,
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case PK_CONTEXT_UKON_ZMENA_PREDDAVKOVYCH_PLATIEB_LOAD_START:
			return {
				...state,
				ukonZmenaPreddavkovychPlatieb: {
					...state.ukonZmenaPreddavkovychPlatieb,
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		default:
			return state
	}
}
