import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { isNumber } from 'lodash'
import NumericInput from 'react-numeric-input'

function NumericInputField({ input: { onChange, value }, meta: { touched, error }, formatValue, customParse, placeholder, ...props }) {
	const handleChange = (value) => {
		onChange(value)
	}

	let val = null
	if (isNumber(value)) {
		val = value
	} else if (value) {
		const num = parseInt(value)
		if (num == value) {
			val = num
		}
	}

	return (
		<div className={cx('input-wrapper', { 'has-error': touched && error })}>
			<NumericInput {...props} value={val} onChange={handleChange} format={formatValue || undefined} parse={customParse} placeholder={placeholder} />
			<div className='text-danger'>{touched ? error : ''}</div>
		</div>
	)
}

NumericInputField.propTypes = {
	input: PropTypes.shape({
		value: PropTypes.any,
		onChange: PropTypes.func.isRequired
	}).isRequired,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	formatValue: PropTypes.func,
	customParse: PropTypes.func,
	placeholder: PropTypes.string
}

NumericInputField.defaultProps = {
	input: {
		value: null
	},
	formatValue: null
}

export default NumericInputField
