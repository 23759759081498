import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

class SubmitButton extends React.Component {
	static propTypes = {
		t: PropTypes.func,
		requiredSignature: PropTypes.bool,
		isDocumentSigned: PropTypes.bool
	}

	constructor(props) {
		super(props)
	}

	render() {
		const { t, requiredSignature, isDocumentSigned } = this.props

		if (requiredSignature && !isDocumentSigned) {
			return (
				<Tooltip
					html={<span>{t('translation:Common.Dokument musí byť podpísaný alebo musí byť zadaný identifikátor nahrávky')}</span>}
					position='top'
					trigger='mouseenter'
					theme='light'
				>
					<button className='button pull-right' disabled type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
						{t('translation:Common.Dokončiť')}
					</button>
				</Tooltip>
			)
		}

		return (
			<button className='button pull-right' type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
				{t('translation:Common.Dokončiť')}
			</button>
		)
	}
}

export default compose(withTranslation('components'))(SubmitButton)
