export const MIESTA_SPOTREBY_LOAD_START = 'MIESTA_SPOTREBY_LOAD_START'
export const MIESTA_SPOTREBY_LOAD_PART_DONE = 'MIESTA_SPOTREBY_LOAD_PART_DONE'
export const MIESTA_SPOTREBY_LOAD_DONE = 'MIESTA_SPOTREBY_LOAD_DONE'
export const MIESTA_SPOTREBY_LOAD_FAIL = 'MIESTA_SPOTREBY_LOAD_FAIL'
export const MIESTA_SPOTREBY_CLEAR = 'MIESTA_SPOTREBY_CLEAR'

export const MIESTA_SPOTREBY_SEARCH_START = 'MIESTA_SPOTREBY_SEARCH_START'
export const MIESTA_SPOTREBY_SEARCH_DONE = 'MIESTA_SPOTREBY_SEARCH_DONE'
export const MIESTA_SPOTREBY_SEARCH_FAIL = 'MIESTA_SPOTREBY_SEARCH_FAIL'
export const MIESTA_SPOTREBY_SEARCH_CLEAR = 'MIESTA_SPOTREBY_SEARCH_CLEAR'
