import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Tooltip } from 'react-tippy'
import { compose } from 'redux'
import { Field } from 'redux-form'
import { get, head, map } from 'lodash'

// atoms
import { connect } from 'react-redux'
import ReasonSelector from '../../../atoms/generics/ReasonSelector'
import ConfirmPreferredValue from '../../../atoms/generics/ConfirmPreferredValue'
import TextInputField from '../../../atoms/TextInputField'

// utils
import { createSuhlasDTO, formatOriginalSuhlas, formatSuhlas, isConfirmValid } from '../../../utils/suhlas'
import { DEFINICIA_TYP, SUHLAS_HODNOTA } from '../../../utils/enums'
import { EDIT_MODE, COLUMNS_COUNT } from '../../../containers/GenericUkon/attributesConfig'
import { formatFormValue } from '../../../utils/form'
import { isPhoneNumberValid } from '../../../utils/phone'

class ContactPhoneField extends React.Component {
	static propTypes = {
		field: PropTypes.string,
		change: PropTypes.func.isRequired,
		dispatch: PropTypes.func.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		nesuhlasyKontakty: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		originalSuhlas: PropTypes.shape().isRequired,
		aktualnostTelefon: PropTypes.string,
		originalAktualnostTelefon: PropTypes.string,
		newSuhlas: PropTypes.shape(),
		onChangeSuhlas: PropTypes.func
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		const { value, onChangeSuhlas, aktualnostTelefon } = this.props

		if (!(onChangeSuhlas instanceof Function)) {
			return
		}

		if (isPhoneNumberValid(value) && isConfirmValid(aktualnostTelefon)) {
			onChangeSuhlas(createSuhlasDTO(SUHLAS_HODNOTA.SUHLAS, null, null, aktualnostTelefon))
		}
	}

	componentDidUpdate(prevProps) {
		const { dispatch, field, value, change, onChangeSuhlas } = this.props

		if (value == '') {
			dispatch(change(field, null))
		}

		if (value != get(prevProps, 'value')) {
			if (isPhoneNumberValid(value)) {
				onChangeSuhlas(createSuhlasDTO(SUHLAS_HODNOTA.SUHLAS, null))
			} else {
				onChangeSuhlas(createSuhlasDTO(SUHLAS_HODNOTA.NAMIETKA, null))
			}
		}
	}

	getEditModeContent = (originalNonPreferredValue, newNonPreferredValue, nesuhlasDovod) => {
		const { field, originalValue, value, originalSuhlas, newSuhlas, onChangeSuhlas, t, aktualnostTelefon } = this.props

		const confirmed = isConfirmValid(aktualnostTelefon)

		const reasonOptions = map(nesuhlasDovod, (option) => {
			return {
				value: option.id,
				label: option.dovod
			}
		})

		let suhlasContent
		if (newNonPreferredValue) {
			let reasonDovod = null
			if (newSuhlas && newSuhlas.hodnota == SUHLAS_HODNOTA.NAMIETKA && !confirmed) {
				if (newSuhlas.nesuhlasDovodIny) {
					reasonDovod = newSuhlas.nesuhlasDovodIny
				} else if (newSuhlas.nesuhlasDovod && newSuhlas.nesuhlasDovod.id) {
					reasonDovod = newSuhlas.nesuhlasDovod.id
				}
			}
			suhlasContent = <ReasonSelector selectOptions={reasonOptions} onChange={onChangeSuhlas} value={reasonDovod} reasonIsNotActual={!confirmed} />
		} else if (!originalNonPreferredValue && originalValue == value) {
			suhlasContent = <ConfirmPreferredValue onChange={onChangeSuhlas} required={!confirmed} confirmed={confirmed} />
		}

		const wrapper = <div style={{ marginTop: '5px' }}>{suhlasContent}</div>

		return (
			<tr className={cx({ 'field-has-invalid-pattern': newNonPreferredValue || !confirmed })}>
				<td>
					<strong>{t('components:EditContactInfo.Kontaktné telefónne číslo')}</strong>
				</td>
				<td>
					<span style={{ verticalAlign: 'sub' }}>{originalValue}&nbsp;</span>
					{originalNonPreferredValue && (
						<Tooltip html={<span>{t('translation:Common.Nedoporučené nastavenie')}</span>} position='left' trigger='mouseenter' theme='light'>
							<div className='info-icon' style={{ float: 'right', paddingTop: '15%' }} />
						</Tooltip>
					)}
					<p>{formatOriginalSuhlas(originalNonPreferredValue, originalSuhlas)}</p>
				</td>
				<td>
					<Field name={field} component={TextInputField} placeholder={t('components:EditContactInfo.Zadajte kontaktné telefónne číslo')} />
					{wrapper}
				</td>
			</tr>
		)
	}

	render() {
		const {
			t,
			editMode,
			columnsCount = COLUMNS_COUNT.THREE,
			originalValue,
			value,
			originalSuhlas,
			newSuhlas,
			nesuhlasyKontakty,
			originalAktualnostTelefon,
			aktualnostTelefon
		} = this.props

		const originalNonPreferredValue = !isPhoneNumberValid(originalValue)
		const newNonPreferredValue = !isPhoneNumberValid(value)

		const nesuhlasDovod = head(nesuhlasyKontakty.data?.definicie?.filter((definicia) => definicia.id === DEFINICIA_TYP.KONTAKTNY_TELEFON))?.nesulasy

		let content = (
			<td>
				<strong>{t('translation:Common.Kontaktné telefónne číslo')}</strong>
			</td>
		)

		if (columnsCount == COLUMNS_COUNT.THREE) {
			if (editMode == EDIT_MODE.EDIT) {
				content = this.getEditModeContent(originalNonPreferredValue, newNonPreferredValue, nesuhlasDovod)
			} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
				let formattedFormValue = formatFormValue(value, originalValue)
				content = (
					<tr>
						{content}
						<td>
							<p>{originalValue}</p>
							<p>{formatOriginalSuhlas(originalNonPreferredValue, originalSuhlas)}</p>
						</td>
						<td>
							<p>{formattedFormValue}</p>
							<p>{formatSuhlas(newNonPreferredValue, newSuhlas, originalSuhlas, nesuhlasDovod, originalAktualnostTelefon, aktualnostTelefon)}</p>
						</td>
					</tr>
				)
			}
		}

		return content
	}
}

const mapStateToProps = (state) => ({
	nesuhlasyKontakty: get(state, 'ukonEditOp.nesuhlasyKontakty')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(ContactPhoneField)
