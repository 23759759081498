import React from 'react'
import cx from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import { map, get, find } from 'lodash'
import * as AuthActions from '../actions/AuthActions'

import * as DataActions from '../actions/DataActions'

import DefaultModal from './Modals/DefaultModal'
import ElementLoading from './ElementLoading'
import { filterAvailableBusinessChannels } from '../utils/auth'

export const CHANNEL_TO_CHANGE_SELECTOR = 'CHANNEL_TO_CHANGE_SELECTOR'

class BusinessChannelDropdownMenu extends React.Component {
	static propTypes = {
		actions: PropTypes.shape({
			changeBusinessChannel: PropTypes.func.isRequired
		}).isRequired,
		actualBusinessChannel: PropTypes.shape({
			id: PropTypes.any.isRequired,
			nazov: PropTypes.any.isRequired,
			popis: PropTypes.any.isRequired
		}),
		ciselniky: PropTypes.shape({
			data: PropTypes.shape({}),
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		partner: PropTypes.shape({}).isRequired,
		channelToSwitch: PropTypes.shape(),
		dataActions: PropTypes.shape({
			dataChanged: PropTypes.func.isRequired
		}).isRequired,
		interakcia: PropTypes.shape()
	}

	state = {
		visible: false
	}

	toggle = () => {
		this.setState({
			visible: !this.state.visible
		})
	}

	handleClickOutside() {
		this.setState({
			visible: false
		})
	}

	handleClickOnOptionClick = (channel) => {
		this.setState(
			{
				visible: false
			},
			() => {
				this.props.dataActions.dataChanged(CHANNEL_TO_CHANGE_SELECTOR, channel)
			}
		)
	}

	switchChannel = () => {
		const { channelToSwitch } = this.props
		this.setState(
			{
				visible: false
			},
			() => {
				this.props.actions.changeBusinessChannel(channelToSwitch)
				this.props.dataActions.dataChanged(CHANNEL_TO_CHANGE_SELECTOR, null)
			}
		)
	}

	cancelSwitch = () => {
		this.setState({ visible: false }, () => {
			this.props.dataActions.dataChanged(CHANNEL_TO_CHANGE_SELECTOR, null)
		})
	}

	render() {
		const { visible } = this.state
		const { actualBusinessChannel, ciselniky, channelToSwitch, interakcia } = this.props

		const available = get(ciselniky, 'data.kanal', [])

		const actual = find(available, {
			id: get(actualBusinessChannel, 'id')
		})
		const filtered = filterAvailableBusinessChannels(available)
		const options = map(filtered, (channel) => {
			// prevent to change on the same channel as is actual active
			let onClick = () => {}
			let active = true
			if (get(actualBusinessChannel, 'id') !== get(channel, 'id')) {
				onClick = () => this.handleClickOnOptionClick(channel)
				active = false
			}

			return (
				<li className={cx({ active })} key={`channel-${channel.id}`} onClick={onClick}>
					<span>{channel.popis}</span>
				</li>
			)
		})

		let modalText = ''
		if (channelToSwitch) {
			if (interakcia.data && interakcia.data.id) {
				modalText = 'Ste si istý, že naozaj chcete zmeniť biznis kanál? Táto akcia zahodí všetky neuložené zmeny.'
			} else {
				modalText = 'Ste si istý, že naozaj chcete zmeniť biznis kanál?'
			}
		}

		return (
			<div>
				<span className='dropdownToggle' onClick={this.toggle}>
					{actual ? actual.popis : '-'} <div className={cx({ 'arrow-down': !visible, 'arrow-up': visible })} />
				</span>
				<div className={cx('dropdown', 'business-canal-dropdown', { shown: visible })}>
					{ciselniky.loading && (
						<div style={{ minHeight: '118px' }}>
							<ElementLoading />
						</div>
					)}
					{!ciselniky.isLoading && <ul>{options}</ul>}
				</div>
				{channelToSwitch && (
					<DefaultModal
						modalTitle='Upozornenie'
						modalContent={modalText}
						leftButton={{
							onClick: () => this.cancelSwitch(),
							text: 'Nie',
							color: 'red',
							outline: true
						}}
						rightButton={{
							onClick: () => this.switchChannel(),
							text: 'Áno',
							color: 'green'
						}}
						visible
					/>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	partner: state.obchodnyPartner.detail,
	actualBusinessChannel: state.auth.businessChannel.actual,
	ciselniky: state.ciselniky,
	channelToSwitch: state.data[CHANNEL_TO_CHANGE_SELECTOR]
})

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(AuthActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(BusinessChannelDropdownMenu))
