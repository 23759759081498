import { get } from 'lodash'
import { getAccessToken } from './auth'
import config from './config'

/**
 * @param {File} file input
 * @return {Promise} base64
 */
export const getBase64FromFile = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})
}

export const openDataUriWindow = (id, title) => {
	const accessToken = getAccessToken()

	if (window.navigator && window.navigator.msSaveOrOpenBlob && id.startsWith('data')) {
		// IE workaround
		const byteCharacters = atob(id.split(',')[1])
		const byteNumbers = new Array(byteCharacters.length)
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i)
		}
		const byteArray = new Uint8Array(byteNumbers)
		const blob = new Blob([byteArray])
		window.navigator.msSaveOrOpenBlob(blob, title)
	} else if (id.indexOf('data:') === 0) {
		// much easier if not IE
		const [header] = id.split(';')
		const isImageOrPdf = /image|pdf/.test(header)

		if (isImageOrPdf) {
			const html =
				`<html>` +
				`<title>${title}</title>` +
				`<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0; }</style>` +
				`<body>` +
				`<iframe src="${id}"></iframe>` +
				`</body></html>`

			const tab = window.open('about:blank', '_blank')
			tab.document.write(html)
		} else {
			const a = document.createElement('a')
			a.href = id
			a.target = '_blank'
			a.download = title
			document.body.appendChild(a)
			a.click()
		}
	} else {
		const a = document.createElement('a')
		a.href = `${get(config, 'baseUrl')}/api/v0/dokumenty/${id}?accessToken=${accessToken}`
		a.target = '_blank'
		document.body.appendChild(a)
		a.click()
	}
}

export const openLiveAgentTicketPreview = (id) => {
	const accessToken = getAccessToken()

	const a = document.createElement('a')
	a.href = `${get(config, 'baseUrl')}/api/v2/tickety/${id}?accessToken=${accessToken}`
	a.target = '_blank'
	document.body.appendChild(a)
	a.click()
}
