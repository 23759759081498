import { get } from 'lodash'
import {
	ZMLUVNE_VZTAHY_LOAD_START,
	ZMLUVNE_VZTAHY_LOAD_PART_DONE,
	ZMLUVNE_VZTAHY_LOAD_DONE,
	ZMLUVNE_VZTAHY_LOAD_FAIL,
	ZMLUVNE_VZTAHY_CLEAR,
	ZMLUVNY_VZTAH_LOAD_START,
	ZMLUVNY_VZTAH_LOAD_DONE,
	ZMLUVNY_VZTAH_LOAD_FAIL,
	ZMLUVNY_VZTAH_DETAIL_CLEAR,
	ZMLUVNY_VZTAH_LIST_CLEAR,
	KOMODITA_ROZPIS_ZALOH_LOAD_START,
	KOMODITA_ROZPIS_ZALOH_LOAD_DONE,
	KOMODITA_ROZPIS_ZALOH_LOAD_FAIL,
	ZMLUVNY_VZTAH_DOCUMENTS_LOAD_START,
	ZMLUVNY_VZTAH_DOCUMENTS_LOAD_DONE,
	ZMLUVNY_VZTAH_DOCUMENTS_LOAD_FAIL,
	ZMLUVNY_VZTAH_DOCUMENTS_LIST_CLEAR,
	ZMLUVNY_VZTAH_LOAD_DETAIL_START,
	ZMLUVNY_VZTAH_LOAD_DETAIL_DONE,
	ZMLUVNY_VZTAH_LOAD_DETAIL_FAIL
} from '../types/zmluvneVztahyTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	miestaSpotreby: {},
	detail: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	details: {},
	rozpisZaloh: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	documents: {}
}

export default (state = initState, action) => {
	switch (action.type) {
		case ZMLUVNE_VZTAHY_LOAD_START: {
			return {
				...state,
				miestaSpotreby: {
					...state.miestaSpotreby,
					...get(action, 'payload.miestoSpotreby')
				}
			}
		}
		case ZMLUVNE_VZTAHY_LOAD_PART_DONE:
			return {
				...state,
				miestaSpotreby: {
					...state.miestaSpotreby,
					...get(action, 'payload.miestoSpotreby')
				}
			}
		case ZMLUVNE_VZTAHY_LOAD_DONE:
			return {
				...state,
				miestaSpotreby: {
					...state.miestaSpotreby,
					...get(action, 'payload.miestoSpotreby')
				}
			}
		case ZMLUVNE_VZTAHY_LOAD_FAIL:
			return {
				...state,
				miestaSpotreby: {
					...state.miestaSpotreby,
					...get(action, 'payload.miestoSpotreby')
				}
			}
		case ZMLUVNY_VZTAH_LOAD_START:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				},
				details: {
					...state.details,
					...get(action, 'payload.zmluvnyVztahList')
				}
			}
		case ZMLUVNY_VZTAH_LOAD_DONE:
			return {
				...state,
				detail: {
					...state.detail,
					data: get(action, 'payload.zmluvnyVztah', []),
					isLoading: false,
					isFailure: false
				},
				details: {
					...state.details,
					...get(action, 'payload.zmluvnyVztahList')
				}
			}
		case ZMLUVNY_VZTAH_LOAD_FAIL:
			return {
				...state,
				detail: {
					...initState.detail,
					isLoading: false,
					isFailure: true
				},
				details: {
					...state.details,
					...get(action, 'payload.zmluvnyVztahList')
				}
			}
		case ZMLUVNY_VZTAH_LOAD_DETAIL_START:
			return {
				...state,
				detailFakty: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case ZMLUVNY_VZTAH_LOAD_DETAIL_DONE:
			return {
				...state,
				detailFakty: {
					data: get(action, 'payload.zmluvnyVztahDetail', []),
					isLoading: false,
					isFailure: false
				}
			}
		case ZMLUVNY_VZTAH_LOAD_DETAIL_FAIL:
			return {
				...state,
				detailFakty: {
					...initState.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case ZMLUVNY_VZTAH_DETAIL_CLEAR:
			return {
				...state,
				detail: {
					...initState.detail
				}
			}
		case ZMLUVNY_VZTAH_LIST_CLEAR:
			return {
				...state,
				details: {
					...state.details,
					...get(action, 'payload.zmluvnyVztahList')
				}
			}
		case KOMODITA_ROZPIS_ZALOH_LOAD_START:
			return {
				...state,
				rozpisZaloh: {
					...state.rozpisZaloh,
					isLoading: true,
					isFailure: false
				}
			}
		case KOMODITA_ROZPIS_ZALOH_LOAD_DONE:
			return {
				...state,
				rozpisZaloh: {
					...state.rozpisZaloh,
					data: get(action, 'payload.rozpisZaloh', []),
					isLoading: false,
					isFailure: false
				}
			}
		case KOMODITA_ROZPIS_ZALOH_LOAD_FAIL:
			return {
				...state,
				rozpisZaloh: {
					...initState.rozpisZaloh,
					isLoading: false,
					isFailure: true
				}
			}
		case ZMLUVNY_VZTAH_DOCUMENTS_LOAD_START:
			return {
				...state,
				documents: {
					...state.documents,
					...get(action, 'payload.documents')
				}
			}
		case ZMLUVNY_VZTAH_DOCUMENTS_LOAD_DONE:
			return {
				...state,
				documents: {
					...state.documents,
					...get(action, 'payload.documents')
				}
			}
		case ZMLUVNY_VZTAH_DOCUMENTS_LOAD_FAIL:
			return {
				...state,
				documents: {
					...state.documents,
					...get(action, 'payload.documents')
				}
			}
		case ZMLUVNY_VZTAH_DOCUMENTS_LIST_CLEAR:
			return {
				...state,
				documents: {
					...state.documents,
					...get(action, 'payload.documents')
				}
			}
		case ZMLUVNE_VZTAHY_CLEAR:
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
