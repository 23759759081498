import { get } from 'lodash'
import { OP_SEGMENT, OP_SKUPINA_OPRAVNENI } from './enums'

export const celeMeno = (op) => {
	return [get(op, 'meno', ''), get(op, 'priezvisko', '')].join(' ').trim() || null
}

export const getSegmentFromSkupinaOpravneni = (skupinaOpravneni) => {
	switch (skupinaOpravneni) {
		case OP_SKUPINA_OPRAVNENI.MOO:
			return OP_SEGMENT[OP_SKUPINA_OPRAVNENI.MOO]
		case OP_SKUPINA_OPRAVNENI.MOP_KAM:
			return OP_SEGMENT[OP_SKUPINA_OPRAVNENI.MOP_KAM]
		case OP_SKUPINA_OPRAVNENI.MOP_SME:
			return OP_SEGMENT[OP_SKUPINA_OPRAVNENI.MOP_SME]
		case OP_SKUPINA_OPRAVNENI.MOP_AM:
			return OP_SEGMENT[OP_SKUPINA_OPRAVNENI.MOP_AM]
		case OP_SKUPINA_OPRAVNENI.MOP_TOP_SME:
			return OP_SEGMENT[OP_SKUPINA_OPRAVNENI.MOP_TOP_SME]
		default:
			return '-'
	}
}

export const getIsMop = (skupinaOpravneni) => {
	if (typeof skupinaOpravneni !== 'number') throw new Error('skupinaOpravneni atribute is required')

	return skupinaOpravneni !== OP_SKUPINA_OPRAVNENI.MOO
}
