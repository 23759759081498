import React from 'react'
import PropTypes from 'prop-types'
import { uniqueId } from 'lodash'

class CheckboxField extends React.Component {
	static propTypes = {
		onChange: PropTypes.func
	}

	constructor(props) {
		super(props)
		this.id = uniqueId('checkbox-')
	}

	handleChange = (value) => {
		const { onChange } = this.props
		this.props.input.onChange(value)
		if (onChange) {
			onChange(value)
		}
	}

	render() {
		const {
			label,
			input: { value },
			disabled
		} = this.props

		const val = value || false
		const { id } = this

		return (
			<div className='input-wrapper checkbox-wrapper'>
				<input id={id} type='checkbox' checked={val} onChange={() => this.handleChange(!val)} disabled={disabled} />
				<label htmlFor={id}>{label}</label>
			</div>
		)
	}
}

CheckboxField.propTypes = {
	input: PropTypes.shape({
		value: PropTypes.any,
		onChange: PropTypes.func.isRequired
	}).isRequired,
	label: PropTypes.string.isRequired,
	disabled: PropTypes.bool
}

CheckboxField.defaultProps = {
	input: {
		value: false
	}
}

export default CheckboxField
