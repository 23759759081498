import { find, get } from 'lodash'
import { BUSINESS_CHANNELS, UKON_VSTUP } from './enums'

export const getUkonVstupByKanal = (actualKanalId, ukonVstup) => {
	switch (actualKanalId) {
		case 1: {
			return get(
				find(ukonVstup, (vstup) => vstup.id == 4),
				'id'
			) // osobne
		}
		case 2: {
			return get(
				find(ukonVstup, (vstup) => vstup.id == 5),
				'id'
			) // telefon
		}
		default: {
			return null
		}
	}
}

export const getUkonVstupByBiznisKanal = (biznisKanalID, ukonVstupOptions) => {
	switch (biznisKanalID) {
		case BUSINESS_CHANNELS.ZSE_CENTRUM: {
			return find(ukonVstupOptions, (vstup) => get(vstup, 'id') == UKON_VSTUP.OSOBNE)
		}
		case BUSINESS_CHANNELS.ZAKAZNICKA_LINKA: {
			return find(ukonVstupOptions, (vstup) => get(vstup, 'id') == UKON_VSTUP.TELEFON)
		}
		case BUSINESS_CHANNELS.BACK_OFFICE: {
			return find(ukonVstupOptions, (vstup) => get(vstup, 'id') == UKON_VSTUP.EMAIL)
		}
		default: {
			return null
		}
	}
}
