import { get } from 'lodash'
import { SEPA_MANDAT_STAV, ZMLUVNY_UCET_TYP, ZMLUVNY_VZTAH_TYP } from './enums'

export const formatZmluvnyVztahTyp = (zmluvnyVztah) => {
	const id = get(zmluvnyVztah, 'typ.id')
	switch (id) {
		case ZMLUVNY_VZTAH_TYP.PREDAJ:
			return `NCB predaj ${get(zmluvnyVztah, 'produkt') || ''}`
		case ZMLUVNY_VZTAH_TYP.SLUZBA_KOMODITA:
			return `SAP VAS ${get(zmluvnyVztah, 'produkt') || ''}`
		case ZMLUVNY_VZTAH_TYP.SLUZBA_NCB:
			return `NCB VAS ${get(zmluvnyVztah, 'produkt') || ''}`
		default:
			return get(zmluvnyVztah, 'komoditaTyp.nazov', '-')
	}
}

export const getSepaMandatStavColor = (sepaMandatStav) => {
	switch (sepaMandatStav) {
		case SEPA_MANDAT_STAV.ZADANY:
		case SEPA_MANDAT_STAV.NA_POTVRDENIE:
		case SEPA_MANDAT_STAV.ZASTARANY:
			return 'orange'
		case SEPA_MANDAT_STAV.AKTIVNY:
			return 'green'
		case SEPA_MANDAT_STAV.ZABLOKOVANY:
		case SEPA_MANDAT_STAV.STORNOVANY:
		case SEPA_MANDAT_STAV.UKONCENY:
			return 'red'
		default:
			return ''
	}
}

export const formatZmluvnyUcetTyp = (typ) => {
	switch (typ) {
		case ZMLUVNY_UCET_TYP.KOMODITNY:
			return 'Komodita'
		case ZMLUVNY_UCET_TYP.NCB:
			return 'NCB'
		default:
			return ''
	}
}
