import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link } from 'react-router-dom'
import { get, map, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import reactQueryParams from 'react-query-params'
import { withTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import qs from 'qs'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as PlanySplatokActions from '../../actions/ObchodniPartneri/PlanySplatokActions'

// components
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'

// utils
import { getContextForPlanySplatok, getColorByPlanSplatokStav } from '../../utils/planySplatok'
import { getIconByKomoditaTyp } from '../../utils/rozpisyZaloh'
import { formatAddress } from '../../utils/address'
import { formatDate } from '../../utils/date'
import { setRouteParams, PLANY_SPLATOK_ZOZNAM, POHLADAVKY_DETAIL, FAKTURY_DETAIL } from '../../utils/routes'
import { openDataUriWindow } from '../../utils/files'
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { KOMODITA_TYP, FAKTURA_TYP, ZMLUVNY_UCET_TYP, OBCHODNY_PARTNER_TYP } from '../../utils/enums'

class PlanSplatokDetailPage extends reactQueryParams {
	static propTypes = {
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		obchodnyPartnerActions: PropTypes.shape().isRequired,
		planySplatokActions: PropTypes.shape().isRequired,
		planSplatok: PropTypes.shape(),
		odberneMiesta: PropTypes.shape(),
		computedMatch: PropTypes.shape(),
		obchodnyPartner: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired
	}

	defaultQueryParams = {
		backUrl: ''
	}

	_mounted = false

	constructor(props) {
		super(props)

		const { backUrl } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			backUrl: ''
		})

		this.state = {
			backUrlLink
		}
	}

	componentDidMount = () => {
		this._mounted = true

		const { obchodnyPartnerActions, planySplatokActions, computedMatch } = this.props

		const { id } = computedMatch.params

		obchodnyPartnerActions.loadAllOdberneMiesta(0)
		planySplatokActions.loadPlanSplatok(id)
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	render = () => {
		const { interakcia, planSplatok, odberneMiesta, obchodnyPartner, t } = this.props

		const { backUrlLink } = this.state

		if (get(odberneMiesta, 'isLoading') || !get(odberneMiesta, 'allDataPrepared') || get(planSplatok, 'isLoading')) {
			return <ElementLoading />
		}

		if (get(odberneMiesta, 'isFailure') || get(planSplatok, 'isFailure')) {
			return <ElementFailure text={t('translation:PlanySplatok.Nepodarilo sa načítať plán splátok')} />
		}

		if (isEmpty(get(planSplatok, 'data'))) {
			return <ElementEmptyContent text={t('translation:PlanySplatok.Obchodný partner nemá plán splátok s týmto číslom')} />
		}

		const { odberneMiesto, miestoSpotreby, zmluvnyUcet } = getContextForPlanySplatok(get(planSplatok, 'data'), get(odberneMiesta, 'allData', []))

		const productIcon = getIconByKomoditaTyp(get(odberneMiesto, 'komoditaTyp'))
		const stavColor = getColorByPlanSplatokStav(get(planSplatok, 'data.stav.id'))

		const stavUctuID = get(zmluvnyUcet, 'typ.id') == ZMLUVNY_UCET_TYP.KOMODITNY ? get(planSplatok, 'data.id') : get(planSplatok, 'data.cislo')

		const backUrl = btoa(`${window.location.pathname}${window.location.search}`)

		const faktury = map(get(planSplatok, 'data.zdrojoveFaktury'), (faktura) => (
			<tr key={get(faktura, 'cislo')} style={{ height: '37px' }}>
				<td>{get(faktura, 'cislo')}</td>
				<td style={{ textAlign: 'right' }}>
					<NumericFormat
						thousandSeparator={' '}
						decimalSeparator={','}
						decimalScale={2}
						fixedDecimalScale={true}
						displayType='text'
						suffix=' €'
						value={get(faktura, 'sumaSpolu', '-')}
						defaultValue='-'
					/>
				</td>
				<td style={{ textAlign: 'right' }}>
					{get(zmluvnyUcet, 'typ.id') == ZMLUVNY_UCET_TYP.KOMODITNY ? formatDate(get(faktura, 'splatnostDatum'), null, 'DD.MM.YYYY') : '-'}
				</td>
				<td style={{ width: '55px' }}>
					{/* NOTE: CP-1061 subtask 4 */}
					{get(faktura, 'fakturaTyp.id') !== FAKTURA_TYP.ZALOHOVA && (
						<Link
							to={`${setRouteParams(FAKTURY_DETAIL, get(interakcia, 'opCislo'), get(faktura, 'cislo'))}?backUrl=${backUrl}`}
							className='button'
							data-type='icon-button'
							data-icon='view'
						/>
					)}
				</td>
			</tr>
		))

		return (
			<>
				<div className='content-header clearfix'>
					<Link
						to={backUrlLink || setRouteParams(PLANY_SPLATOK_ZOZNAM, get(interakcia, 'opCislo'))}
						className='button pull-left'
						data-type='back-button'
						data-color='blue'
					>
						{t('translation:Common.Späť')}
					</Link>
					<div className='header-title pull-left'>{t('translation:PlanySplatok.Detail plánu splátok')}</div>
					<Link
						to={`${setRouteParams(POHLADAVKY_DETAIL, get(interakcia, 'opCislo'), stavUctuID)}?backUrl=${backUrl}`}
						className='button pull-right'
						data-color='blue'
					>
						{t('translation:PlanySplatok.Zobraziť v stave účtu')}
					</Link>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-content'>
							<div className='inner-box'>
								<div className='row'>
									<div className='col-6'>
										<table>
											<tbody>
												<tr>
													<td>{t('translation:PlanySplatok.Číslo plánu splátok')}</td>
													<td>
														<strong>{get(planSplatok, 'data.cislo') || '-'}</strong>
													</td>
												</tr>
												<tr>
													<td>{t('translation:Common.Zmluvný účet')}</td>
													<td>
														<strong>{get(planSplatok, 'data.zmluvnyUcetCislo') || '-'}</strong>
													</td>
												</tr>
												<tr>
													<td>{t('translation:Common.Produkt')}</td>
													<td>
														<strong className='icon'>
															{productIcon && <img src={productIcon} alt={get(odberneMiesto, 'komoditaTyp.nazov') || '-'} />}
															<span>{get(odberneMiesto, 'komoditaTyp.nazov') || '-'}</span>
														</strong>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className='col-6'>
										<table>
											<tbody>
												{obchodnyPartner?.typ === OBCHODNY_PARTNER_TYP.MOO && (
													<>
														<tr>
															<td>{t('translation:Common.Miesto spotreby')}</td>
															<td>
																<strong>{formatAddress(get(miestoSpotreby, 'adresa')) || '-'}</strong>
															</td>
														</tr>
														{get(odberneMiesto, 'komoditaTyp.id') == KOMODITA_TYP.ELEKTRINA && (
															<tr>
																<td>{t('translation:Common.EIC')}</td>
																<td>
																	<strong>{get(odberneMiesto, 'identifikator', '-')}</strong>
																</td>
															</tr>
														)}
														{get(odberneMiesto, 'komoditaTyp.id') == KOMODITA_TYP.PLYN && (
															<tr>
																<td>{t('translation:Common.POD')}</td>
																<td>
																	<strong>{get(odberneMiesto, 'identifikator', '-')}</strong>
																</td>
															</tr>
														)}
													</>
												)}
												<tr>
													<td>{t('translation:PlanySplatok.Stav plánu splátok')}</td>
													<td>
														<div className='label' data-color={stavColor}>
															{get(planSplatok, 'data.stav.nazov')}
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-4'>
									<table className='content-table'>
										<thead>
											<tr>
												<th colSpan='2'>{t('translation:Common.Základné informácie')}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{t('translation:PlanySplatok.Počet splátok')}</td>
												<td>
													<strong>{get(planSplatok, 'data.splatkyPocet', '-')}</strong>
												</td>
											</tr>
											<tr>
												<td>{t('translation:Common.Dátum od - do')}</td>
												<td>
													<strong>{`${formatDate(get(planSplatok, 'data.datumOd'), null, 'DD.MM.YYYY')} - ${formatDate(
														get(planSplatok, 'data.datumDo'),
														null,
														'DD.MM.YYYY'
													)}`}</strong>
												</td>
											</tr>
											{get(planSplatok, 'data.dokumentId') && (
												<tr>
													<td>{t('translation:Common.Dokument')}</td>
													<td>
														<a
															className='file'
															data-type='general'
															style={{ cursor: 'pointer' }}
															target='_blank'
															onClick={(e) => {
																e.preventDefault()
																openDataUriWindow(
																	get(planSplatok, 'data.dokumentId'),
																	t('translation:PlanySplatok.Splátkový kalendár')
																)
															}}
														>
															{t('translation:PlanySplatok.Plán splátok')}.pdf
														</a>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								<div className='col-8'>
									<p>
										<strong style={{ marginLeft: '10px' }}>{t('translation:PlanySplatok.Zahrnuté faktúry')}</strong>
									</p>
									<table cellSpacing='0' className='content-table slim bordered striped'>
										<thead>
											<tr>
												<th>{t('translation:PlanySplatok.Číslo faktúry')}</th>
												<th style={{ width: '150px', textAlign: 'right' }}>{t('translation:Common.Suma')}</th>
												<th style={{ textAlign: 'right' }}>{t('translation:Common.Dátum splatnosti')}</th>
												<th style={{ width: '55px' }} />
											</tr>
										</thead>
										<tbody
											data-empty={t(
												'translation:PlanySplatok.Pre tento plán splátok nie je k dispozícií zoznam zahrnutých faktúr Konzultujte ich v priloženej dokumentácii'
											)}
										>
											{faktury}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	odberneMiesta: get(state, 'obchodnyPartner.odberneMiesta'),
	planSplatok: get(state, 'planySplatok.planySplatokDetail'),
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data')
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
	planySplatokActions: bindActionCreators(PlanySplatokActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_PLAN_SPLATOK])
)(PlanSplatokDetailPage)
