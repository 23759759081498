import { get, concat, size, keyBy } from 'lodash'
import {
	ODBERNE_MIESTA_LOAD_START,
	ODBERNE_MIESTA_LOAD_PART_DONE,
	ODBERNE_MIESTA_LOAD_DONE,
	ODBERNE_MIESTA_LOAD_FAIL,
	ODBERNE_MIESTO_LOAD_START,
	ODBERNE_MIESTO_LOAD_DONE,
	ODBERNE_MIESTO_LOAD_FAIL,
	ODBERNE_MIESTO_DETAIL_CLEAR,
	ODBERNE_MIESTO_LIST_CLEAR,
	ODBERNE_MIESTA_SEARCH_START,
	ODBERNE_MIESTA_SEARCH_DONE,
	ODBERNE_MIESTA_SEARCH_FAIL,
	ODBERNE_MIESTA_SEARCH_CLEAR,
	ODBERNE_MIESTO_LOAD_DETAIL_START,
	ODBERNE_MIESTO_LOAD_DETAIL_DONE,
	ODBERNE_MIESTO_LOAD_DETAIL_FAIL
} from '../types/odberneMiestaTypes'

import { getReq } from '../utils/request'

const initStatePartOdberneMiesto = {
	data: null,
	isLoading: false,
	isFailure: false
}

async function loadOdberneMiestaByParts(opCislo, dispatch, pageSize = 100, queryParams = {}, getStore) {
	const loadPage = async (page) => {
		const store = getStore()
		const query = {
			...queryParams,
			pageSize,
			page
		}
		try {
			const odberneMiestaData = await getReq(`/api/v2/op/${opCislo}/odberne-miesta`, query)

			// if is page one assing current data if page is bigger merge with pevious data
			const odberneMiesta =
				page == 1
					? get(odberneMiestaData, 'response.content', [])
					: concat(get(store, 'odberneMiesta.collection.data', []), get(odberneMiestaData, 'response.content', []))

			dispatch({
				type: ODBERNE_MIESTA_LOAD_PART_DONE,
				payload: {
					odberneMiesta
				}
			})

			if (size(get(odberneMiestaData, 'response.content', [])) >= pageSize) {
				await loadPage(page + 1)
			} else {
				dispatch({
					type: ODBERNE_MIESTA_LOAD_DONE,
					payload: {
						odberneMiesta,
						byCislo: keyBy(odberneMiesta, 'cislo')
					}
				})
				return odberneMiesta
			}
		} catch (e) {
			dispatch({
				type: ODBERNE_MIESTA_LOAD_FAIL
			})
		}
	}
	return await loadPage(1)
}

export const loadAllOdberneMiesta = (queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: ODBERNE_MIESTA_LOAD_START
		})

		return await loadOdberneMiestaByParts(get(interakcia, 'opCislo'), dispatch, 100, queryParams, getStore)
	}
}

export const loadOdberneMiesto = (omCislo, withUkony, queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: ODBERNE_MIESTO_LOAD_START,
			payload: {
				odberneMiestoList: {
					[omCislo]: {
						...initStatePartOdberneMiesto,
						isLoading: true
					}
				}
			}
		})

		const query = {
			...queryParams
		}
		try {
			const odberneMiestoDetailData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/odberne-miesta/${omCislo}`, query)
			const odberneMiestoDetail = get(odberneMiestoDetailData, 'response.content', null)

			let ukony = []
			if (withUkony) {
				const ukonyData = await getReq('/api/v2/ukony', {
					objektTyp: 'OdberneMiestoDTO',
					objektId: omCislo,
					sort: 'vytvorenyOd:DESC'
				})
				ukony = get(ukonyData, 'response.content', [])
			}

			const odberneMiesto = {
				...odberneMiestoDetail,
				ukony
			}

			dispatch({
				type: ODBERNE_MIESTO_LOAD_DONE,
				payload: {
					odberneMiesto,
					odberneMiestoList: {
						[omCislo]: {
							...initStatePartOdberneMiesto,
							data: odberneMiesto,
							isLoading: false
						}
					}
				}
			})

			return odberneMiesto
		} catch (e) {
			dispatch({
				type: ODBERNE_MIESTO_LOAD_FAIL,
				payload: {
					odberneMiestoList: {
						[omCislo]: {
							...initStatePartOdberneMiesto,
							isFailure: true
						}
					}
				}
			})
		}
	}
}

export const loadOdberneMiestoDetail = (omCislo, withUkony, queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: ODBERNE_MIESTO_LOAD_DETAIL_START
		})

		const query = {
			...queryParams
		}
		try {
			const odberneMiestoDetailData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/odberne-miesta/${omCislo}`, query)
			const odberneMiestoDetail = get(odberneMiestoDetailData, 'response.content', null)

			dispatch({
				type: ODBERNE_MIESTO_LOAD_DETAIL_DONE,
				payload: {
					odberneMiestoDetail
				}
			})

			return odberneMiestoDetail
		} catch (e) {
			dispatch({
				type: ODBERNE_MIESTO_LOAD_DETAIL_FAIL,
				payload: {
					odberneMiestoDetail: null
				}
			})
		}
	}
}

export const clearOdberneMiesto = (omCislo) => {
	return async (dispatch) => {
		dispatch({
			type: ODBERNE_MIESTO_DETAIL_CLEAR
		})
		if (omCislo) {
			dispatch({
				type: ODBERNE_MIESTO_LIST_CLEAR,
				payload: {
					odberneMiestoList: {
						[omCislo]: undefined
					}
				}
			})
		}
	}
}

export const loadOdberneMiestoMimoOP = (omCislo, queryParams = {}) => {
	return async (dispatch) => {
		dispatch({
			type: ODBERNE_MIESTO_LOAD_START
		})

		const query = {
			...queryParams
		}
		try {
			const odberneMiestoData = await getReq(`/api/v2/om/${omCislo}`, query)
			const odberneMiesto = get(odberneMiestoData, 'response.content', null)

			dispatch({
				type: ODBERNE_MIESTO_LOAD_DONE,
				payload: {
					odberneMiesto
				}
			})

			return odberneMiesto
		} catch (e) {
			dispatch({
				type: ODBERNE_MIESTO_LOAD_FAIL
			})
		}
	}
}

export const searchOdberneMiesta = (queryParams = {}, pageSize = 250, page = 1) => {
	return async (dispatch) => {
		dispatch({
			type: ODBERNE_MIESTA_SEARCH_START
		})

		const query = {
			...queryParams,
			pageSize,
			page
		}
		try {
			const searchResultsData = await getReq('/api/v2/om', query)
			const searchResults = get(searchResultsData, 'response.content', null)

			dispatch({
				type: ODBERNE_MIESTA_SEARCH_DONE,
				payload: {
					searchResults
				}
			})

			return searchResults
		} catch (e) {
			dispatch({
				type: ODBERNE_MIESTA_SEARCH_FAIL
			})
		}
	}
}

export const searchOdberneMiestaClear = () => {
	return (dispatch) => {
		dispatch({
			type: ODBERNE_MIESTA_SEARCH_CLEAR
		})
	}
}
