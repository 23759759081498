import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()
// Listen for changes to the current location.
// history.listen((location, action) => {
// 	// location is an object like window.location
// 	console.log(action, location)
// })

// TODO: CARE-624 v pripade ze sa bude robit dana funkcionalita pouzit nasledujuci kod
// window.addEventListener('beforeunload', function (e) {
// 	// // Cancel the event as stated by the standard.
// 	e.preventDefault()
// 	// // Chrome requires returnValue to be set.
// 	e.returnValue = 'Prajete si ukoncit interakciu? Ukoncit / Neukoncit'
// })
