// This must be the first line in src/index.js
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { PersistGate } from 'redux-persist/es/integration/react'
import configureStore from './store/configureStore'
import { history } from './utils/history'
import Root from './components/Root'

import './styles/grid-min.css'
import './styles/global.sass'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-tippy/dist/tippy.css'

const { store, persistor } = configureStore()

const root = ReactDOM.createRoot(document.getElementById('app'))
root.render(
	<PersistGate loading='Loading' persistor={persistor}>
		<Root store={store} history={history} />
	</PersistGate>
)
