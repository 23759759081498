import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next'
import { connect } from 'react-redux'

// components
import SuhlasBadge from './SuhlasBadge'

// utils

// resources
import infoIcon from '../../resources/img/icons/icon-info.svg'
import arrowRightIcon from '../../resources/img/icons/consent-right-arrow.svg'
import arrowSubIcon from '../../resources/img/icons/consent-sub-arrow.svg'

class PodradeneSuhlasyWarning extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		originalSuhlas: PropTypes.shape(),
		formValues: PropTypes.shape(),
		podradeneSuhlasy: PropTypes.array.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {}
	}

	render() {
		const { t, originalSuhlas, formValues, podradeneSuhlasy } = this.props

		return (
			<div className='consent-warning'>
				<div className='consent-warning-header'>
					<img className='consent-warning-icon' src={infoIcon} />
					<strong>{t('translation:Common.Upozornenie')}</strong>
				</div>
				<div className='consent-warning-content'>
					<div className='consent-warning-description'>
						<Trans
							defaults={t('translation:Suhlasy.Zmena vyjadrenia k definícii ovplyvňuje podriadené vyjadrenia k definíciám', {
								value: originalSuhlas?.nazov
							})}
							components={{ strong: <strong /> }}
						/>
					</div>
					<div className='consent-warning-box'>
						<div>
							<div className='consent-warning-box-category-name'>{originalSuhlas?.suhlasKategoria?.nazov}</div>
							<div className='consent-warning-box-consent-name'>{originalSuhlas?.nazov}</div>
						</div>
						<div className='consent-warning-box-badges'>
							<div className='consent-badge'>
								<SuhlasBadge typVyjadrenieSuhlas={originalSuhlas?.typVyjadrenieSuhlas} />
							</div>
							<div>
								<img className='consent-warning-change-icon' src={arrowRightIcon} />
							</div>
							<div className='consent-badge'>
								<SuhlasBadge typVyjadrenieSuhlas={formValues?.typVyjadrenieSuhlas} />
							</div>
						</div>
					</div>
					<div>
						<div className='consent-warning-sub-description'>{t('translation:Suhlasy.Zmena stavu podradených súhlasov')}</div>
						<div className='consent-warning-sub-container'>
							{podradeneSuhlasy.map((podradenySuhlas) => {
								return (
									<div className='consent-warning-sub-box-container'>
										<div className='consent-warning-sub-arrow-container'>
											<img className='consent-warning-sub-arrow' src={arrowSubIcon} />
										</div>
										<div className='consent-warning-box'>
											<div>
												<div className='consent-warning-box-category-name'>{podradenySuhlas?.suhlasKategoria?.nazov}</div>
												<div className='consent-warning-box-consent-name'>{podradenySuhlas?.nazov}</div>
											</div>
											<div className='consent-warning-box-badges'>
												<div className='consent-badge'>
													<SuhlasBadge typVyjadrenieSuhlas={podradenySuhlas?.typVyjadrenieSuhlasActual} />
												</div>
												<div>
													<img className='consent-warning-change-icon' src={arrowRightIcon} />
												</div>
												<div className='consent-badge'>
													<SuhlasBadge typVyjadrenieSuhlas={podradenySuhlas?.typVyjadrenieSuhlasNew} />
												</div>
											</div>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({})

export default withTranslation('components')(connect(mapStateToProps)(PodradeneSuhlasyWarning))
