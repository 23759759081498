import React from 'react'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import PropTypes from 'prop-types'

// utils
import { formatDate } from '../../utils/date'
import { getContextForPlanySplatok, getColorByPlanSplatokStav } from '../../utils/planySplatok'
import { getIconByKomoditaTyp } from '../../utils/rozpisyZaloh'
import { formatAddress } from '../../utils/address'
import { setRouteParams, PLANY_SPLATOK_DETAIL } from '../../utils/routes'
import { KOMODITA_TYP, OBCHODNY_PARTNER_TYP } from '../../utils/enums'

PlanySplatokTableRow.propTypes = {
	interakcia: PropTypes.shape().isRequired,
	planSplatok: PropTypes.shape().isRequired,
	odberneMiesta: PropTypes.shape().isRequired,
	obchodnyPartner: PropTypes.shape().isRequired,
	t: PropTypes.func.isRequired
}

function PlanySplatokTableRow({ interakcia, planSplatok, odberneMiesta, obchodnyPartner, t }) {
	const { odberneMiesto, miestoSpotreby } = getContextForPlanySplatok(planSplatok, odberneMiesta)

	const productIcon = getIconByKomoditaTyp(get(odberneMiesto, 'komoditaTyp'))
	const stavColor = getColorByPlanSplatokStav(get(planSplatok, 'stav.id'))

	return (
		<div className='box'>
			<div className='box-content'>
				<div className='row bordered'>
					<div className='col-6'>
						<table className='content-table'>
							<tbody>
								<tr>
									<td style={{ width: '33%' }}>{t('translation:PlanySplatok.Číslo plánu splátok')}</td>
									<td style={{ width: '67%' }}>
										<strong>{get(planSplatok, 'cislo') || '-'}</strong>
									</td>
								</tr>
								<tr>
									<td>{t('translation:Common.Zmluvný účet')}</td>
									<td>
										<strong>{get(planSplatok, 'zmluvnyUcetCislo') || '-'}</strong>
									</td>
								</tr>
								{obchodnyPartner?.typ === OBCHODNY_PARTNER_TYP.MOO && (
									<>
										<tr>
											<td>{t('translation:Common.Miesto spotreby')}</td>
											<td>
												<strong>{get(miestoSpotreby, 'adresa') ? formatAddress(get(miestoSpotreby, 'adresa')) : '-'}</strong>
											</td>
										</tr>
										{get(odberneMiesto, 'komoditaTyp.id') == KOMODITA_TYP.ELEKTRINA && (
											<tr>
												<td>{t('translation:Common.EIC')}</td>
												<td>
													<strong>{get(odberneMiesto, 'identifikator', '-')}</strong>
												</td>
											</tr>
										)}
										{get(odberneMiesto, 'komoditaTyp.id') == KOMODITA_TYP.PLYN && (
											<tr>
												<td>{t('translation:Common.POD')}</td>
												<td>
													<strong>{get(odberneMiesto, 'identifikator', '-')}</strong>
												</td>
											</tr>
										)}
									</>
								)}
							</tbody>
						</table>
						<div className='inner-box' style={{ marginRight: '10px' }}>
							<table>
								<tbody>
									<tr>
										<td style={{ width: '33%' }}>{t('translation:Common.Produkt')}</td>
										<td style={{ width: '67%' }}>
											<strong className='icon'>
												{productIcon && <img src={productIcon} alt={get(odberneMiesto, 'komoditaTyp.nazov') || '-'} />}
												<span>{get(odberneMiesto, 'komoditaTyp.nazov') || '-'}</span>
											</strong>
										</td>
									</tr>
									<tr>
										<td>{t('translation:PlanySplatok.Stav plánu splátok')}</td>
										<td>
											<div className='label' data-color={stavColor}>
												{get(planSplatok, 'stav.nazov') || '-'}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className='col-6'>
						<table className='content-table'>
							<tbody>
								<tr>
									<td>{t('translation:PlanySplatok.Počet splátok')}</td>
									<td>
										<strong>{get(planSplatok, 'splatkyPocet', '-')}</strong>
									</td>
								</tr>
								<tr>
									<td>{t('translation:PlanySplatok.Dátum od - do')}</td>
									<td>
										<strong>{`${formatDate(get(planSplatok, 'datumOd'), '', 'DD.MM.YYYY')} - ${formatDate(
											get(planSplatok, 'datumDo'),
											'',
											'DD.MM.YYYY'
										)}`}</strong>
									</td>
								</tr>
								<tr>
									<td>{t('translation:PlanySplatok.Počet faktúr')}</td>
									<td>
										<strong>{get(planSplatok, 'zdrojoveFaktury.length', '-')}</strong>
									</td>
								</tr>
							</tbody>
						</table>
						<div className='row'>
							<div className='col-6 offset-6'>
								<Link
									to={setRouteParams(PLANY_SPLATOK_DETAIL, get(interakcia, 'opCislo'), get(planSplatok, 'id'))}
									className='button full-width'
									data-color='blue'
									data-type='outline'
								>
									{t('translation:PlanySplatok.Detail plánu splátok')}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PlanySplatokTableRow
