export const PLANY_SPLATOK_LOAD_START = 'PLANY_SPLATOK_LOAD_START'
export const PLANY_SPLATOK_LOAD_DONE = 'PLANY_SPLATOK_LOAD_DONE'
export const PLANY_SPLATOK_LOAD_FAIL = 'PLANY_SPLATOK_LOAD_FAIL'

export const PLAN_SPLATOK_LOAD_START = 'PLAN_SPLATOK_LOAD_START'
export const PLAN_SPLATOK_LOAD_DONE = 'PLAN_SPLATOK_LOAD_DONE'
export const PLAN_SPLATOK_LOAD_FAIL = 'PLAN_SPLATOK_LOAD_FAIL'

export const PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_START = 'PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_START'
export const PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_DONE = 'PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_DONE'
export const PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_FAIL = 'PLAN_SPLATOK_AKTUALNE_NASTAVENIE_LOAD_FAIL'
export const PLAN_SPLATOK_AKTUALNE_NASTAVENIE_CLEAR = 'PLAN_SPLATOK_AKTUALNE_NASTAVENIE_CLEAR'
