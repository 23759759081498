import { get } from 'lodash'
import {
	ODBERNE_MIESTA_LOAD_START,
	ODBERNE_MIESTA_LOAD_PART_DONE,
	ODBERNE_MIESTA_LOAD_DONE,
	ODBERNE_MIESTA_LOAD_FAIL,
	ODBERNE_MIESTO_LOAD_START,
	ODBERNE_MIESTO_LOAD_DONE,
	ODBERNE_MIESTO_LOAD_FAIL,
	ODBERNE_MIESTO_DETAIL_CLEAR,
	ODBERNE_MIESTO_LIST_CLEAR,
	ODBERNE_MIESTA_SEARCH_START,
	ODBERNE_MIESTA_SEARCH_DONE,
	ODBERNE_MIESTA_SEARCH_FAIL,
	ODBERNE_MIESTA_SEARCH_CLEAR,
	ODBERNE_MIESTO_LOAD_DETAIL_START,
	ODBERNE_MIESTO_LOAD_DETAIL_DONE,
	ODBERNE_MIESTO_LOAD_DETAIL_FAIL
} from '../types/odberneMiestaTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	collection: {
		data: [],
		byCislo: null,
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	details: {},
	searchResults: {
		data: [],
		isLoading: false,
		isFailure: false,
		isReady: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case ODBERNE_MIESTA_LOAD_START:
			return {
				...state,
				collection: {
					...state.collection,
					isLoading: true,
					isFailure: false
				}
			}
		case ODBERNE_MIESTA_LOAD_PART_DONE:
			return {
				...state,
				collection: {
					...state.collection,
					data: get(action, 'payload.odberneMiesta', [])
				}
			}
		case ODBERNE_MIESTA_LOAD_DONE:
			return {
				...state,
				collection: {
					data: get(action, 'payload.odberneMiesta', []),
					byCislo: get(action, 'payload.byCislo', {}),
					isLoading: false,
					isFailure: false
				}
			}
		case ODBERNE_MIESTA_LOAD_FAIL:
			return {
				...state,
				collection: {
					...initState.collection,
					isFailure: true
				}
			}
		case ODBERNE_MIESTO_LOAD_START:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				},
				details: {
					...state.details,
					...get(action, 'payload.odberneMiestoList')
				}
			}
		case ODBERNE_MIESTO_LOAD_DONE:
			return {
				...state,
				detail: {
					...state.detail,
					data: get(action, 'payload.odberneMiesto', []),
					isLoading: false,
					isFailure: false
				},
				details: {
					...state.details,
					...get(action, 'payload.odberneMiestoList')
				}
			}
		case ODBERNE_MIESTO_LOAD_FAIL:
			return {
				...state,
				detail: {
					...initState.detail,
					isLoading: false,
					isFailure: true
				},
				details: {
					...state.details,
					...get(action, 'payload.odberneMiestoList')
				}
			}
		case ODBERNE_MIESTO_LOAD_DETAIL_START:
			return {
				...state,
				detailFakty: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case ODBERNE_MIESTO_LOAD_DETAIL_DONE:
			return {
				...state,
				detailFakty: {
					data: get(action, 'payload.odberneMiestoDetail', []),
					isLoading: false,
					isFailure: false
				}
			}
		case ODBERNE_MIESTO_LOAD_DETAIL_FAIL:
			return {
				...state,
				detailFakty: {
					...initState.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case ODBERNE_MIESTO_DETAIL_CLEAR:
			return {
				...state,
				detail: {
					...initState.detail
				}
			}
		case ODBERNE_MIESTO_LIST_CLEAR:
			return {
				...state,
				details: {
					...state.details,
					...get(action, 'payload.odberneMiestoList')
				}
			}
		case ODBERNE_MIESTA_SEARCH_START:
			return {
				...state,
				searchResults: {
					...state.searchResults,
					isLoading: true,
					isFailure: false,
					isReady: true
				}
			}
		case ODBERNE_MIESTA_SEARCH_DONE:
			return {
				...state,
				searchResults: {
					...state.searchResults,
					data: get(action, 'payload.searchResults', []),
					isLoading: false,
					isFailure: false,
					isReady: true
				}
			}
		case ODBERNE_MIESTA_SEARCH_FAIL:
			return {
				...state,
				searchResults: {
					...initState.searchResults,
					isLoading: false,
					isFailure: true,
					isReady: true
				}
			}
		case ODBERNE_MIESTA_SEARCH_CLEAR:
			return {
				...state,
				searchResults: {
					...initState.searchResults
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
