import dayjs from 'dayjs'
import { get } from 'lodash'

import {
	INTERAKCIE_CLOSE_START,
	INTERAKCIE_CLOSE_FAIL,
	INTERAKCIE_CLOSE_DONE,
	INTERAKCIE_LOAD_START,
	INTERAKCIE_LOAD_DONE,
	INTERAKCIE_LOAD_FAIL
} from '../../types/interakcie'
import { getReq, postReq } from '../../utils/request'

export const interakcieCloseStart = () => {
	return {
		type: INTERAKCIE_CLOSE_START
	}
}

export const interakcieCloseFail = () => {
	return {
		type: INTERAKCIE_CLOSE_FAIL
	}
}

export const interakcieCloseDone = () => {
	return {
		type: INTERAKCIE_CLOSE_DONE
	}
}

export const endInteraction = (body) => {
	return async (dispatch, getStore) => {
		try {
			dispatch(interakcieCloseStart())
			const interakcia = getStore().interakcie.detail.data
			await postReq(`/api/v0/interakcie/${interakcia.id}/ukoncit`, null, body)
			dispatch(interakcieCloseDone())
		} catch (e) {
			// eslint-disable-next-line
			console.log(e, 'ERROR')
			dispatch(interakcieCloseFail())
		}
	}
}

export function interakcieLoadStart(page) {
	return {
		type: INTERAKCIE_LOAD_START,
		payload: { page }
	}
}

export function interakcieLoadDone(interakcie, isLastPage) {
	return {
		type: INTERAKCIE_LOAD_DONE,
		payload: {
			interakcie,
			isLastPage
		}
	}
}

export function interakcieLoadFail() {
	return {
		type: INTERAKCIE_LOAD_FAIL
	}
}

export function loadDnesnaHistoriaInterakcii(page = 1, pageSize = 5, queryParams) {
	return async (dispatch, getStore) => {
		const store = getStore()
		dispatch(interakcieLoadStart(page))
		const query = {
			...queryParams,
			vytvorenaOd: dayjs().startOf('day').toISOString(),
			stranka: page,
			velkostStranky: pageSize,
			riesitel: get(store, 'auth.user.id'),
			triedenie: 'vytvorenaOd:DESC'
		}
		try {
			const res = await getReq('/api/v0/interakcie', query)
			const { obsah, strankovanie } = res.response
			const interakcie = obsah
			const { velkostStranky } = strankovanie
			const isLastPage = get(interakcie, 'length') < velkostStranky

			return dispatch(interakcieLoadDone(interakcie, isLastPage))
		} catch (e) {
			return dispatch(interakcieLoadFail())
		}
	}
}
