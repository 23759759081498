import React from 'react'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { get } from 'lodash'

// atoms
import OptionsPickerField from '../../../atoms/OptionsPickerField'

class UkonKategoriaField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired,
		optionsData: PropTypes.string.isRequired
	}

	constructor(props) {
		super(props)
	}

	getOptions(optionsData) {
		const optionsToFormat = get(this.props, optionsData, [])

		return optionsToFormat.map((option) => {
			return {
				id: option?.id?.id,
				originalValue: option,
				label: option?.nazov
			}
		})
	}

	parseValue(value) {
		return value.originalValue
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (povinny && !value) {
			return t('translation:Common.validate.Zvoľte kategóriu')
		}
	}

	render() {
		const { field, optionsData, value, label } = this.props

		const options = this.getOptions(optionsData)

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{label}</strong>
							</td>

							<td>
								<Field
									name={field}
									selectedOptionId={value?.id?.id}
									component={OptionsPickerField}
									options={options}
									parse={this.parseValue}
									validate={this.validate}
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(UkonKategoriaField)
