import React from 'react'
import PropTypes from 'prop-types'
import { find, findIndex, throttle } from 'lodash'
import Async from 'react-select/async'
import cx from 'classnames'

class AsyncSelect extends React.Component {
	state = {
		isLoading: false
	}

	state = {
		open: false
	}

	constructor(props) {
		super(props)
		this.selectRef = React.createRef()
	}

	handleMenuOpen = () => {
		this.setState({
			open: true
		})
	}

	handleBlur = () => {
		const focusedElement = document.activeElement
		if (focusedElement && !focusedElement.className.includes('react-select')) {
			this.setState({
				open: false
			})
		} else {
			throttle(this.selectRef.current.focus(), 150)
		}
	}

	handleChange = (option) => {
		this.setState(
			{
				open: false
			},
			() => {
				const val = option ? option.value : null
				this.props.onChange(val)
			}
		)
	}

	loadOptionsWrapper = async (input) => {
		const data = await this.props.loadOptions(input, this)
		return data
	}

	render() {
		const { value, selectOptions, ...otherProps } = this.props
		const { open } = this.state
		let val = value || null

		const options = selectOptions || []

		if (val && findIndex(options, (option) => option.value === val) === -1) {
			options.push({
				value: val,
				label: val
			})
		}

		val = find(options, function (o) {
			return o.value == val
		})

		return (
			<div className={cx('select-wrapper', { 'has-error': this.props.error })}>
				<Async
					value={val || null}
					noOptionsMessage={() => 'Nič sa nenašlo.'}
					placeholder={this.props.placeholder || 'Zvoľte možnosť'}
					defaultOptions={options}
					loadOptions={this.loadOptionsWrapper}
					isClearable
					classNamePrefix='react-select'
					cacheOptions={false}
					{...otherProps}
					// NOTE: next lines are for IE hot fix https://github.com/JedWatson/react-select/issues/1020
					onMenuOpen={this.handleMenuOpen}
					menuIsOpen={open}
					onBlur={this.handleBlur}
					ref={this.selectRef}
					onChange={this.handleChange}
				/>
			</div>
		)
	}
}

AsyncSelect.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	selectOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any.isRequired,
			label: PropTypes.string.isRequired
		})
	),
	loadOptions: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	error: PropTypes.any
}

AsyncSelect.defaultProps = {
	input: {
		value: null
	}
}

export default AsyncSelect
