import React from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field, change } from 'redux-form'
import { get, filter, upperFirst } from 'lodash'

// atoms
import TimeRangeField from '../../../atoms/TimeRangeField'
import SelectCategoryOneField from './fields/SelectCategoryOneField'

// utils
import GenericFields from './GenericFields'
import { CheckboxField } from '../../../atoms'
import { FORMS } from '../../../utils/enums'
import { formatDate } from '../../../utils/date'

export const UKON_CATEGORY_ONE_KEY = 'ukonKategoria1'
export const UKON_TIME_RANGE_KEY = 'timeRange'

class GenericFieldArray extends React.Component {
	static propTypes = {
		interakciaStartTime: PropTypes.any.isRequired,
		currentTime: PropTypes.any.isRequired,
		ukonOptions: PropTypes.array.isRequired,
		isMOP: PropTypes.bool.isRequired,
		formValues: PropTypes.shape(),
		ukonyByInterakciaId: PropTypes.shape()
	}

	constructor(props) {
		super(props)
		this.state = {}
	}

	onChangePrimarnyUkonId = (ukonId) => {
		const { dispatch, formValues } = this.props

		if (ukonId === formValues?.primaryUkonId) {
			dispatch(change(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU, 'primaryUkonId', null))
			return
		}

		dispatch(change(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU, 'primaryUkonIndex', null))
		dispatch(change(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU, 'primaryUkonId', ukonId))
	}

	onChangePrimarnyUkonIndex = (index) => {
		const { dispatch, formValues } = this.props

		if (index === formValues?.primaryUkonIndex) {
			dispatch(change(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU, 'primaryUkonIndex', null))
			return
		}

		dispatch(change(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU, 'primaryUkonId', null))
		dispatch(change(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU, 'primaryUkonIndex', index))
	}

	onRemoveUkon = (index) => {
		const { fields, formValues, dispatch } = this.props

		if (index === formValues?.primaryUkonIndex) {
			dispatch(change(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU, 'primaryUkonIndex', null))
		}
		fields.remove(index)
	}

	render() {
		const { t, fields, interakciaStartTime, currentTime, ukonOptions, isMOP, formValues, ukonyByInterakciaId } = this.props

		const ukonyInInteraction = get(ukonyByInterakciaId, 'data', [])

		return (
			<>
				{fields.map((member, index) => {
					const fieldValues = fields.get(index)
					const timeRangeFieldName = `${member}.${UKON_TIME_RANGE_KEY}`
					const ukonCategory1FieldName = `${member}.${UKON_CATEGORY_ONE_KEY}`
					const selectedUkonCategory1 = fieldValues?.[UKON_CATEGORY_ONE_KEY]?.value

					return (
						<>
							<tr key={`generic-field-row-${index}`}>
								<td>
									<div style={{ marginBottom: '16px' }}>
										<Field
											name={timeRangeFieldName}
											component={TimeRangeField}
											startRange={interakciaStartTime}
											endRange={currentTime}
											minuteIncrement={1}
											use24Hours={true}
											placeholder={t('translation:Common.Zvoľte čas')}
											showErrorOnUntouch
										/>
									</div>
									<div>
										<Field
											input={{
												value: index === formValues?.primaryUkonIndex,
												onChange: () => this.onChangePrimarnyUkonIndex(index)
											}}
											component={CheckboxField}
											label={t('containers:UkoncenieInterakcie.Označiť ako primárny úkon')}
										/>
									</div>
								</td>
								<td>
									<div className='row'>
										<div className='col-12'>
											<Field name={ukonCategory1FieldName} component={SelectCategoryOneField} ukonOptions={ukonOptions} />
										</div>
									</div>
									<div className='row'>
										<div className='col-12'>
											<GenericFields
												parentIndex={index}
												parentFieldName={member}
												selectedUkon={selectedUkonCategory1}
												formValues={formValues}
											/>
										</div>
									</div>
								</td>
								<td>
									<div className='remove-button' onClick={() => this.onRemoveUkon(index)} />
								</td>
							</tr>
						</>
					)
				})}
				{ukonyInInteraction.map((ukonInInteraction) => {
					const zacatyOd = formatDate(get(ukonInInteraction, 'zacatyOd'), null, 'HH:mm')
					const spustenyOd = formatDate(get(ukonInInteraction, 'spustenyOd'), null, 'HH:mm')
					const timeRange = filter([zacatyOd, spustenyOd], (item) => !!item).join(' - ')

					const popis = get(ukonInInteraction, 'popis') ?? get(ukonInInteraction, 'typ.nazov', '-')

					return (
						<tr key={`ukon-${ukonInInteraction.id}`}>
							<td>
								<strong>{timeRange}</strong>
								<div style={{ marginBottom: '12px' }} />
								<Field
									input={{
										value: ukonInInteraction.id === formValues?.primaryUkonId,
										onChange: () => this.onChangePrimarnyUkonId(ukonInInteraction.id)
									}}
									component={CheckboxField}
									label={t('containers:UkoncenieInterakcie.Označiť ako primárny úkon')}
								/>
							</td>
							<td>{upperFirst(popis)}</td>
						</tr>
					)
				})}
				<br />

				<tr>
					<td colSpan='2'>
						<div className='text-center'>
							<div
								className='button with-icon'
								data-type='outline'
								onClick={() =>
									fields.push({
										[UKON_TIME_RANGE_KEY]: {
											startTime: interakciaStartTime.startOf('minute'),
											endTime: currentTime.endOf('minute')
										}
									})
								}
								data-icon='plus'
								data-color='blue'
							>
								{t('containers:UkoncenieInterakcie.Vložiť úkon manuálne')}
							</div>
						</div>
					</td>
				</tr>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie,
	ukonyByInterakciaId: state.ukony.ukonyByInterakciaId
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(GenericFieldArray)
