/**
 * @param {Object} object form extraction
 * @param {Array} allowedProperty allowed keys for new object
 *
 * @return {Object} newObject only with allowed keys
 */
export default (object, allowedProperty) => {
	return Object.keys(object)
		.filter((key) => allowedProperty.includes(key))
		.reduce((obj, key) => {
			return {
				...obj,
				[key]: object[key]
			}
		}, {})
}
