import React from 'react'
import PropTypes from 'prop-types'
import { find, findIndex, throttle } from 'lodash'
import AsyncCreatable from 'react-select/async-creatable'
import cx from 'classnames'

class AsyncCreatableSelect extends React.Component {
	state = {
		isLoading: false,
		open: false
	}

	constructor(props) {
		super(props)
		this.selectRef = React.createRef()
	}

	static getDerivedStateFromProps(props, prevState) {
		if (props.isDisabled && prevState.open) {
			return {
				open: false
			}
		}
		return null
	}

	handleMenuOpen = () => {
		this.setState(
			{
				open: true
			},
			() => {
				if (this.props.onMenuOpen) {
					this.props.onMenuOpen()
				}
			}
		)
	}

	handleBlur = () => {
		const focusedElement = document.activeElement
		if (focusedElement && !focusedElement.className.includes('react-select')) {
			this.setState({
				open: false
			})
		} else {
			throttle(this.selectRef.current.focus(), 150)
		}
	}

	handleChange = (option) => {
		this.setState(
			{
				open: false
			},
			() => {
				const val = option ? option.value : null
				this.props.onChange(val)
			}
		)
	}

	createOption = (label) => ({
		label,
		value: label
	})

	handleCreate = (inputValue) => {
		const newOption = this.createOption(inputValue)
		this.props.onChange(newOption.value)
	}

	loadOptionsWrapper = async (input) => {
		const data = await this.props.loadOptions(input, this)
		return data
	}

	render() {
		const { value, selectOptions, ...otherProps } = this.props
		const { open } = this.state
		let val = value || null

		const options = selectOptions || []

		if (val && findIndex(options, (option) => option.value === val) === -1) {
			options.push({
				value: val,
				label: val
			})
		}

		val = find(options, function (o) {
			return o.value == val
		})

		return (
			<div className={cx('select-wrapper', { 'has-error': this.props.error })}>
				<AsyncCreatable
					value={val || null}
					noOptionsMessage={() => 'Nič sa nenašlo.'}
					handleCreate={this.handleCreate}
					onCreateOption={this.handleCreate}
					placeholder={this.props.placeholder || 'Zvoľte možnosť'}
					defaultOptions={options}
					loadOptions={this.loadOptionsWrapper}
					isClearable
					classNamePrefix='react-select'
					formatCreateLabel={(input) => `Vytvoriť novú možnosť` + ` ${input}`}
					cacheOptions={false}
					{...otherProps}
					// NOTE: next lines are for IE hot fix https://github.com/JedWatson/react-select/issues/1020
					onMenuOpen={this.handleMenuOpen}
					menuIsOpen={!this.props.isDisabled && open}
					onBlur={this.handleBlur}
					ref={this.selectRef}
					onChange={this.handleChange}
				/>
			</div>
		)
	}
}

AsyncCreatableSelect.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	selectOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any.isRequired,
			label: PropTypes.string.isRequired
		})
	),
	loadOptions: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	isDisabled: PropTypes.bool,
	onMenuOpen: PropTypes.func,
	error: PropTypes.any
}

AsyncCreatableSelect.defaultProps = {
	input: {
		value: null
	}
}

export default AsyncCreatableSelect
