import { get } from 'lodash'
import {
	OTVORENE_FAKTURY_SEARCH_START,
	OTVORENE_FAKTURY_SEARCH_FAIL,
	OTVORENE_FAKTURY_SEARCH_DONE,
	OTVORENE_FAKTURY_SEARCH_CLEAR
} from '../types/otvoreneFakturyTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	otvoreneFaktury: {
		data: [],
		isFailure: false,
		isLoading: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case OTVORENE_FAKTURY_SEARCH_START:
			return {
				...state,
				otvoreneFaktury: {
					...initState.otvoreneFaktury,
					isLoading: true,
					isFailure: false
				}
			}
		case OTVORENE_FAKTURY_SEARCH_DONE:
			return {
				...state,
				otvoreneFaktury: {
					data: get(action, 'payload.otvoreneFaktury'),
					isLoading: false,
					isFailure: false
				}
			}
		case OTVORENE_FAKTURY_SEARCH_FAIL:
			return {
				...state,
				otvoreneFaktury: {
					...initState.otvoreneFaktury,
					isLoading: false,
					isFailure: true
				}
			}
		case OTVORENE_FAKTURY_SEARCH_CLEAR:
			return {
				...state,
				otvoreneFaktury: {
					...initState.otvoreneFaktury
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
