// utils
import { SUHLAS_TYP_VYJADRENIA } from '../../utils/enums'

const validate = (values, props) => {
	const errors = {}

	if (!values.typVyjadrenieSuhlas) {
		errors.typVyjadrenieSuhlas = props.t('translation:Common.Toto pole je povinné')
	}

	if (values.typVyjadrenieSuhlas !== SUHLAS_TYP_VYJADRENIA.SUHLAS) {
		if (!values.nesuhlasDovod) errors.nesuhlasDovod = props.t('translation:Common.Toto pole je povinné')
	}

	return errors
}

export default validate
