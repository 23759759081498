import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

class OptionsPickerField extends React.Component {
	static propTypes = {
		meta: PropTypes.shape({
			touched: PropTypes.bool.isRequired,
			error: PropTypes.string
		}).isRequired,
		selectedOptionId: PropTypes.number,
		options: PropTypes.array.isRequired,
		type: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
	}

	handleClick(selectedOption) {
		const { input, t } = this.props

		input.onChange(selectedOption)
	}

	render() {
		const { meta, options, selectedOptionId, t } = this.props

		const { touched, error } = meta || {}

		if (!options || options.length === 0) {
			return <div>{t('atoms:OptionsPickerField.Žiadne položky')}</div>
		}

		return (
			<>
				<div className='picker-field'>
					<div className='picker-field-options'>
						{options.map((option) => {
							return (
								<div key={option.id}>
									<button
										type='button'
										className={cx('picker-field-option', {
											'picker-field-option-selected': selectedOptionId === option.id
										})}
										onClick={() => this.handleClick(option)}
									>
										{option.label}
									</button>
								</div>
							)
						})}
					</div>
					<div className='text-danger'>{touched ? error : ''}</div>
				</div>
			</>
		)
	}
}

export default compose(withTranslation('atoms'))(OptionsPickerField)
