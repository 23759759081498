import React from 'react'
import PropTypes from 'prop-types'
import emptyStateIcon from '../resources/img/icons/empty.svg'

function ElementEmptyContent(props) {
	return <div className='empty-state' style={{ backgroundImage: `url(${emptyStateIcon})`, ...props.style }} data-text={props.text || ''} />
}

ElementEmptyContent.propTypes = {
	text: PropTypes.string,
	style: PropTypes.shape()
}

export default ElementEmptyContent
