import React from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'

function DebounceFieldWrapper(Component, delay, { input, ...props }) {
	const handleChange = (value) => {
		input.onChange(value)
	}

	const onChangeZalohySumaInput = debounce(handleChange, delay)
	return <Component {...props} input={{ ...input, onChange: onChangeZalohySumaInput }} />
}

const DebounceField =
	(Component, delay = 1000) =>
	(props) =>
		DebounceFieldWrapper(Component, delay, props)

DebounceFieldWrapper.propTypes = {
	input: PropTypes.shape({
		value: PropTypes.any,
		onChange: PropTypes.func.isRequired
	}).isRequired,
	formatValue: PropTypes.func
}

export default DebounceField
