import { CISELNIKY_LOAD_DONE, CISELNIKY_LOAD_FAIL, CISELNIKY_LOAD_START } from '../types/ciselniky'

export const initState = {
	data: null,
	isLoading: false,
	isFailure: false,
	lastLoad: new Date(0)
}

export default (state = initState, action) => {
	switch (action.type) {
		case CISELNIKY_LOAD_DONE:
			return {
				...state,
				data: action.payload.ciselniky,
				isLoading: false,
				isFailure: false,
				lastLoad: new Date()
			}
		case CISELNIKY_LOAD_FAIL:
			return {
				...state,
				data: null,
				isLoading: false,
				isFailure: true
			}
		case CISELNIKY_LOAD_START:
			return {
				...state,
				data: null,
				isLoading: true,
				isFailure: false
			}
		default:
			return state
	}
}
