import { get } from 'lodash'
import {
	PREPLATKY_ALL_LOAD_START,
	PREPLATKY_LOAD_START,
	PREPLATKY_ALL_LOAD_PART_DONE,
	PREPLATKY_LOAD_DONE,
	PREPLATKY_LOAD_FAIL,
	PREPLATKY_CLEAR
} from '../types/pohladavkyTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	preplatky: {
		data: [],
		page: 1,
		isFailure: false,
		isLoading: false,
		isLastPage: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case PREPLATKY_ALL_LOAD_START:
			return {
				...state,
				preplatky: {
					...state.preplatky,
					data: initState.preplatky.data,
					page: initState.preplatky.page,
					isLoading: true,
					isFailure: false
				}
			}
		case PREPLATKY_LOAD_START:
			return {
				...state,
				preplatky: {
					...state.preplatky,
					page: get(action, 'payload.page'),
					isLoading: true,
					isFailure: false
				}
			}
		case PREPLATKY_ALL_LOAD_PART_DONE:
			return {
				...state,
				preplatky: {
					...state.preplatky,
					data: get(action, 'payload.preplatky')
				}
			}
		case PREPLATKY_LOAD_DONE:
			return {
				...state,
				preplatky: {
					...state.preplatky,
					data: get(action, 'payload.preplatky'),
					isLoading: false,
					isFailure: false,
					isLastPage: get(action, 'payload.isLastPage')
				}
			}
		case PREPLATKY_LOAD_FAIL:
			return {
				...state,
				preplatky: {
					...initState.preplatky,
					isLoading: false,
					isFailure: true
				}
			}
		case PREPLATKY_CLEAR:
			return {
				...state,
				preplatky: {
					...initState.preplatky
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
