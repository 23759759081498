import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { get, upperFirst } from 'lodash'

// atoms
import OptionsPickerField from '../../../atoms/OptionsPickerField'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/attributesConfig'

class VstupDoUkonuField extends React.Component {
	static propTypes = {
		isTableView: PropTypes.bool.isRequired,
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		ciselniky: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired
	}

	constructor(props) {
		super(props)
	}

	getOptions(optionsData) {
		const optionsToFormat = get(this.props, optionsData, [])

		return optionsToFormat.map((option) => {
			return {
				id: option?.id,
				originalValue: option,
				label: option?.nazov
			}
		})
	}

	parseValue(value) {
		return value.originalValue
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (povinny && !value) {
			return t('translation:Common.validate.Vstup do úkonu je povinný')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, t, value, isTableView } = this.props

		const options = this.getOptions('ciselniky.ukonVstup')

		const valueBefore = null
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT) {
			valueAfter = (
				<Field
					name={field}
					selectedOptionId={value?.id}
					component={OptionsPickerField}
					options={options}
					parse={this.parseValue}
					validate={this.validate}
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter = upperFirst(get(value, 'nazov'))
		}

		const tableRow = (
			<tr>
				<td>
					<strong>{t('translation:Common.Vstup do úkonu')}</strong>
				</td>
				{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
				<td>{valueAfter}</td>
			</tr>
		)

		return isTableView ? (
			tableRow
		) : (
			<table className='content-table padded bordered' cellSpacing='0'>
				<tbody>{tableRow}</tbody>
			</table>
		)
	}
}

const mapStateToProps = (state) => ({
	ciselniky: get(state, 'ciselniky.data')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(VstupDoUkonuField)
