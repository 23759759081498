import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import SimpleLayout from './Layout/SimpleLayout'
import { isLoggedIn } from '../utils/auth'
import { getLogo } from '../utils/environment'

class LoginErrorPage extends React.Component {
	static propTypes = {
		settings: PropTypes.shape().isRequired
	}

	render() {
		const { settings } = this.props

		if (isLoggedIn()) {
			return <Redirect to='/' />
		}

		return (
			<SimpleLayout>
				<Link
					to='/api/v0/pomocnik/login'
					className='button with-icon pull-right'
					data-color='blue'
					data-type='outline'
					data-icon='question'
					target='_blank'
					rel='noopener noreferrer'
					style={{ marginRight: '20px', marginTop: '20px' }}
				>
					Pomocník
				</Link>
				<div className='login-content'>
					<div className='logo-wrapper'>
						<Link to='/prihlasenie'>
							<img src={getLogo(get(settings, 'env'))} alt='ZSE, Súčasť vášho domova' />
						</Link>
					</div>
					<div className='error-wrapper'>
						<h3 className='text-center'>Chyba</h3>
						<p>Údaje neboli vyplnené správne</p>
					</div>
					<Link to='/' className='button rounded with-icon' data-type='outline' data-icon='back' data-color='blue'>
						Vrátiť sa k prihláseniu
					</Link>
				</div>
			</SimpleLayout>
		)
	}
}

const mapStateToProps = (state) => ({
	settings: get(state, 'settings.settings')
})

export default connect(mapStateToProps, null)(LoginErrorPage)
