import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import PropTypes from 'prop-types'
import { map, find, get, findIndex, filter, isEmpty } from 'lodash'

// actions
import * as WidgetyActions from '../actions/WidgetyActions'

// components
import DashboardBox from '../components/DashboardBox'
import ElementLoading from '../components/ElementLoading'
import ElementFailure from '../components/ElementFailure'
import ElementEmptyContent from '../components/ElementEmptyContent'

// utils
import { checkPermissions } from '../utils/permissionsHoc'
import widgets from '../utils/widgets'

class DashboardPage extends React.Component {
	static propTypes = {
		auth: PropTypes.shape().isRequired,
		widgety: PropTypes.shape(),
		widgetyActions: PropTypes.shape()
	}

	constructor(props) {
		super(props)
	}

	/* NOTE: Check if the widgets have changed and if a rerender is necessary. In case it is not checked, it would cause unnecessary calls to the backend. -> https://jira.zsee.sk/jira/browse/CP-2896  */
	shouldComponentUpdate(nextProps) {
		if (JSON.stringify(nextProps.widgety.data) == JSON.stringify(this.props.widgety.data)) {
			return false
		}
		return true
	}

	onSortEnd = ({ oldIndex, newIndex }) => {
		// if element did not change posistion do nothing
		if (oldIndex === newIndex) {
			return
		}

		// first element has fixed position
		if (newIndex === 0) {
			return
		}

		const { widgety, widgetyActions } = this.props
		const sorting = arrayMove(get(widgety, 'data.sorting', []), oldIndex, newIndex)
		widgetyActions.updateWidgetSettings({ sorting })
	}

	headerOnChangeHandler = (e, boxId) => {
		const { widgety, widgetyActions } = this.props
		// TODO: doriesit ak uzivatel vymaze nazov

		// Find item index using
		const title = e.currentTarget.value
		const sorting = get(widgety, 'data.sorting', [])
		const sortingClone = [...sorting]
		const index = findIndex(sorting, { id: boxId })
		if (!title) {
			return
		}
		// Replace item at index using native splice
		sortingClone.splice(index, 1, { title, id: boxId })
		widgetyActions.updateWidgetSettings({ sorting: sortingClone })
	}

	onClickDeleteItem = (boxId) => {
		const { widgety, widgetyActions } = this.props
		// Find item index using
		const sorting = get(widgety, 'data.sorting', [])
		const removedSorting = filter(sorting, (item) => item.id !== boxId)
		widgetyActions.updateWidgetSettings({ sorting: removedSorting })
	}

	onSubmitSettings = (boxId, body) => {
		const { widgety, widgetyActions } = this.props

		// Find item index using
		const sorting = map(get(widgety, 'data.sorting', []), (widget) => {
			if (boxId === widget.id) {
				return {
					...widget,
					settings: {
						...get(widget, 'settings', {}),
						filters: [...get(widget, 'filters', []), ...body]
					}
				}
			}

			return widget
		})

		widgetyActions.updateWidgetSettings({ sorting })
	}

	getSortedBoxes = (customBoxes) => {
		const { auth } = this.props

		return filter(
			map(customBoxes, (box) => {
				if (!box.id) {
					return null
				}
				const boxId = box.id.split('-')
				const originalBox = find(widgets, { id: boxId[0] })

				if (!originalBox) {
					return null
				}
				if (originalBox.permission && !checkPermissions(get(auth, 'user.roles', []), [originalBox.permission])) {
					return null
				}
				const originalBoxWithID = { ...originalBox, id: boxId }
				return {
					...originalBoxWithID,
					...box
				}
			}),
			(box) => !isEmpty(box)
		)
	}

	render() {
		const { widgety } = this.props

		if (widgety.isLoading) {
			return <ElementLoading />
		}
		if (widgety.isFailure) {
			return <ElementFailure text='Widgety sa nepodarilo načítať' />
		}

		const normalizedWidgets = this.getSortedBoxes(get(widgety, 'data.sorting', []))
		if (isEmpty(normalizedWidgets)) {
			return <ElementEmptyContent text='Nemáte priradené žiadne widgety' />
		}

		const SortableItem = SortableElement(({ value }) => (
			<DashboardBox
				onSubmitSettings={this.onSubmitSettings}
				boxId={value.id}
				allowSettings={value.allowSettings}
				title={value.boxHeader || value.title}
				Content={value.Content}
				footer={value.footer}
				permission={value.permission}
				headerOnChangeHandler={this.headerOnChangeHandler}
				onClickDeleteItem={this.onClickDeleteItem}
			/>
		))
		const SortableList = SortableContainer(({ items }) => {
			const sortableItems = items.map((value, index) => <SortableItem key={`widget-${value.id}`} index={index} sortIndex={index} value={value} />)
			return <div className='content-wrapper'>{sortableItems}</div>
		})
		return <SortableList items={normalizedWidgets} onSortEnd={this.onSortEnd} axis='xy' useDragHandle={true} />
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	widgety: state.widgety
})

const mapDispatchToProps = (dispatch) => ({
	widgetyActions: bindActionCreators(WidgetyActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
