import React from 'react'
import { compose, bindActionCreators } from 'redux'
import {
	map,
	get,
	pick,
	keys,
	indexOf,
	values,
	isEmpty,
	isEqual,
	filter,
	deburr,
	forEach,
	includes,
	orderBy,
	split,
	keyBy,
	difference,
	find,
	join,
	head,
	some,
	trim
} from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import cx from 'classnames'
import reactQueryParams from 'react-query-params'
import { Link } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'
import dayjs from 'dayjs'
import qs from 'qs'
import { Tooltip } from 'react-tippy'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as PohladavkyActions from '../../actions/ObchodniPartneri/PohladavkyActions'
import * as FiltersActions from '../../actions/SelectedFiltersActions'
import * as CiselnikySelectBoxActions from '../../actions/CiselnikySelectBoxActions'

// atoms
import Select from '../../atoms/BasicSelect'
import Table from '../../atoms/Table'

// components
import PreplatkyTableRow from '../../components/TableRows/PreplatkyTableRow'
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'

// utils
import { FILTER_SELECTORS, PREPLATOK_TYP } from '../../utils/enums'
import Permissions, { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import extractProperties from '../../utils/extractProperties'
import { setRouteParams, PREPLATKY_VYTVORIT } from '../../utils/routes'
import { formatDate } from '../../utils/date'

const queryParams = {
	zmluvnyUcetCislo: 'zmluvnyUcetCislo',
	variabilnySymbol: 'variabilnySymbol',
	typ: 'typ',
	triedenie: 'triedenie'
}

const emptyMultiSelectState = {
	value: null,
	label: 'Všetky'
}

const initFilters = {
	[queryParams.zmluvnyUcetCislo]: null,
	[queryParams.variabilnySymbol]: null,
	[queryParams.typ]: null,
	[queryParams.triedenie]: 'dokladDatum:desc'
}

class VrateniePreplatkovZoznamPage extends reactQueryParams {
	static propTypes = {
		t: PropTypes.func.isRequired,
		pohladavkyActions: PropTypes.shape().isRequired,
		preplatky: PropTypes.shape().isRequired,
		selectedFilters: PropTypes.shape(),
		interakcia: PropTypes.shape(),
		auth: PropTypes.shape()
	}

	defaultQueryParams = {
		[queryParams.zmluvnyUcetCislo]: null,
		[queryParams.variabilnySymbol]: null,
		[queryParams.typ]: null,
		[queryParams.triedenie]: null,
		pohladavkaID: '',
		backUrl: ''
	}

	_mounted = false

	constructor(props) {
		super(props)

		const { pohladavkaID, backUrl } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			pohladavkaID: this.defaultQueryParams.pohladavkaID,
			backUrl: this.defaultQueryParams.backUrl
		})

		this.state = {
			pohladavkaID,
			backUrl,
			backUrlLink,
			typPolozkyFilterOptions: []
		}
	}

	componentDidMount = () => {
		this._mounted = true

		const { pohladavkyActions, selectedFilters, filtersActions, ciselnikySelectBoxActions, obchodnyPartnerActions, ciselniky } = this.props

		const typPolozkyCiselnikOptions = keyBy(get(ciselniky, 'pohladavkaTyp', []), 'id')
		const typPolozkyFilterOptionsAll = map(values(PREPLATOK_TYP), (typ) => {
			return {
				...typ,
				label: typ.label || get(typPolozkyCiselnikOptions, `${typ.value}.nazov`)
			}
		})
		const typPolozkyFilterOptions = orderBy(typPolozkyFilterOptionsAll, [(typ) => deburr(typ.label)], ['asc'])

		if (this._mounted) {
			this.setState({
				typPolozkyFilterOptions
			})
		}

		const filteredParams = pick(
			this.queryParams,
			keys(this.queryParams).filter((key) => {
				return indexOf(values(queryParams), key) != -1 && !isEmpty(this.queryParams[key])
			})
		)

		const filters = {
			...selectedFilters,
			...filteredParams
		}

		ciselnikySelectBoxActions.loadCiselnikZmluvneUcty()
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.PREPLATKY_PAGE, filters)
		if (filters[queryParams.zmluvnyUcetCislo]) {
			pohladavkyActions.loadPreplatky(1, undefined, filters)
			obchodnyPartnerActions.loadPohladavkyZostatok(filters[queryParams.zmluvnyUcetCislo])
		} else {
			pohladavkyActions.preplatkyClear()
			obchodnyPartnerActions.loadPohladavkyZostatok()
		}
	}

	multiFilterAdd = (filters, fieldName) => {
		const { selectedFilters } = this.props

		if (!get(filters, '[0].value')) {
			return this.clearFilters(fieldName)
		}

		const filterArray = map(filters, (filter) => get(filter, 'value'))
		const oldFilters = split(selectedFilters[fieldName], ',')

		const value = [...oldFilters, ...filterArray].filter((e) => e !== null && e !== '' && e !== '[]').join(',')
		this.filterChanged({ value }, fieldName)
	}

	filterChanged = (filter, fieldName) => {
		const { pohladavkyActions, filtersActions, obchodnyPartnerActions, selectedFilters } = this.props

		// stringify value or set a default value null
		const newFilter = {
			...selectedFilters,
			[fieldName]: get(filter, 'value') ? `${filter.value}` : null
		}
		if (isEqual(newFilter[fieldName], selectedFilters[fieldName])) {
			return
		}

		// update filter state
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.PREPLATKY_PAGE, newFilter)
		if (newFilter[queryParams.zmluvnyUcetCislo]) {
			pohladavkyActions.loadPreplatky(1, undefined, newFilter)
			obchodnyPartnerActions.loadPohladavkyZostatok(newFilter[queryParams.zmluvnyUcetCislo])
		} else {
			pohladavkyActions.preplatkyClear()
			obchodnyPartnerActions.loadPohladavkyZostatok()
		}
	}

	componentDidUpdate = () => {
		const { selectedFilters } = this.props

		// prevent to inject not allowed query param
		const query = extractProperties(this.queryParams, values(queryParams))
		if (!isEqual(selectedFilters, query)) {
			this.setQueryParams({
				...selectedFilters
			})
		}
	}

	loadMore = () => {
		const { pohladavkyActions, selectedFilters, preplatky } = this.props

		// increment pagination page by one
		const pohladavkyNextPage = preplatky.page + 1

		if (selectedFilters[queryParams.zmluvnyUcetCislo]) {
			pohladavkyActions.loadPreplatky(pohladavkyNextPage, undefined, selectedFilters)
		}
	}

	sortingColumn = (stringFormat) => {
		const { pohladavkyActions, filtersActions, selectedFilters } = this.props

		// stringify value or set a default value null
		const newFilter = {
			...selectedFilters,
			[queryParams.triedenie]: stringFormat || initFilters.triedenie
		}
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.PREPLATKY_PAGE, newFilter)
		if (newFilter[queryParams.zmluvnyUcetCislo]) {
			pohladavkyActions.loadPreplatky(1, undefined, newFilter)
		}
		return newFilter[queryParams.triedenie]
	}

	clearFilters = (fieldName) => {
		const { pohladavkyActions, filtersActions, selectedFilters } = this.props

		let filter
		if (fieldName) {
			filter = {
				...selectedFilters
			}
			filter[fieldName] = initFilters[fieldName]
		} else {
			filter = {
				...initFilters,
				[queryParams.zmluvnyUcetCislo]: selectedFilters[queryParams.zmluvnyUcetCislo],
				[queryParams.triedenie]: selectedFilters[queryParams.triedenie]
			}
		}

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.PREPLATKY_PAGE, filter)
		if (filter[queryParams.zmluvnyUcetCislo]) {
			pohladavkyActions.loadPreplatky(1, undefined, filter)
		} else {
			pohladavkyActions.preplatkyClear()
		}
	}

	clearFilter = (value, type) => {
		const { pohladavkyActions, filtersActions, selectedFilters } = this.props

		const filter = split(selectedFilters[type], ',').filter((item) => item != value) // do not check type only value
		const val = !isEmpty(filter) ? filter.join(',') : null

		const newFilter = {
			...selectedFilters,
			[type]: val
		}

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.PREPLATKY_PAGE, newFilter)
		if (newFilter[queryParams.zmluvnyUcetCislo]) {
			pohladavkyActions.loadPreplatky(1, undefined, newFilter)
		}
	}

	failureContentElement = () => {
		const { t } = this.props

		return this.commonContentContainer(
			<div className='content-wrapper'>
				<ElementFailure text={t('translation:Preplatky.Nepodarilo sa načítať zoznam preplatkov')} />
			</div>
		)
	}

	emptyContentElement = (message) => {
		const { t } = this.props
		return this.commonContentContainer(
			<div className='content-wrapper'>
				<ElementEmptyContent
					text={
						message ||
						t('translation:Preplatky.Pre obchodného partnera neevidujeme žiadne preplatky, alebo zadanému filtru nekorešponduje žiadny preplatok')
					}
				/>
			</div>
		)
	}

	commonContentContainer = (content) => {
		const { auth, interakcia, ciselnikySelectBox, selectedFilters, preplatky, pohladavkyZostatok, t } = this.props

		const { pohladavkaID, typPolozkyFilterOptions, backUrl, backUrlLink } = this.state

		const filters = {
			typPolozkyFilterOptions: [emptyMultiSelectState, ...typPolozkyFilterOptions]
		}

		const selectedFiltersValues = {
			[queryParams.variabilnySymbol]: [
				{
					value: selectedFilters[queryParams.variabilnySymbol],
					label: t('translation:Common.Vyhľadávanie')
				}
			],
			[queryParams.typ]: filter(filters.typPolozkyFilterOptions, (el) => includes(split(selectedFilters[queryParams.typ], ','), `${el.value}`))
		}

		const filtersOptions = {
			[queryParams.typ]: filter(
				filters.typPolozkyFilterOptions,
				(el) => difference(split(el.value, ','), split(selectedFilters[queryParams.typ], ',')).length > 0
			),
			[queryParams.zmluvnyUcetCislo]: map(get(ciselnikySelectBox, 'zmluvneUcty.data', []), (zmluvnyUcet) => ({
				value: get(zmluvnyUcet, 'cislo'),
				label: get(zmluvnyUcet, 'cislo')
			}))
		}

		const columns = [
			{
				title: t('translation:Preplatky.filter.Číslo faktúry'),
				name: 'variabilnySymbol',
				width: '10%'
			},
			{
				title: t('translation:Preplatky.filter.Typ'),
				name: 'typ',
				width: '15%'
			},
			{
				title: t('translation:Preplatky.filter.Číslo dokladu'),
				name: 'cislo',
				width: '10%'
			},
			{
				title: t('translation:Preplatky.filter.Dátum dokladu'),
				name: 'dokladDatum',
				width: '11%'
			},
			{
				title: t('translation:Preplatky.filter.Splatnosť'),
				name: 'splatnostDatum',
				width: '11%'
			},
			{
				title: t('translation:Preplatky.filter.Suma'),
				name: 'suma',
				width: '11%'
			},
			{
				title: t('translation:Preplatky.filter.Otvorená suma'),
				name: 'sumaOtvorena',
				width: '11%'
			},
			{
				title: t('translation:Preplatky.filter.Odklad splatnosti'),
				name: 'odkladSplatnostiDo',
				width: '11%'
			},
			{
				title: t('translation:Preplatky.filter.Platobný príkaz'),
				notActive: true,
				width: '10%'
			},
			{
				notActive: true
			}
		]

		const filterItems = []
		forEach(selectedFiltersValues, (values, filter) => {
			let color = 'blue'
			switch (filter) {
				case queryParams.typ:
					color = 'green'
					break
			}

			forEach(values, (value, index) => {
				// prevent to push Empty state
				if (get(value, 'value')) {
					filterItems.push(
						<div
							key={`${filter}-${index}`}
							className='filter-item'
							data-color={color}
							onClick={() => this.clearFilter(get(value, 'value'), filter)}
							style={{ cursor: 'pointer' }}
						>
							<span className='filter-text'>{get(value, 'label')}</span>
							<span className='remove' />
						</div>
					)
				}
			})
		})

		const isActiveTypFilter = !isEmpty(selectedFiltersValues[queryParams.typ])
		let typPolozkyPlaceholder = t('translation:Common.filter.Všetky')
		if (isActiveTypFilter) {
			typPolozkyPlaceholder = `${t('translation:Common.filter.Aktívne filtre')} (${get(selectedFiltersValues, `[${queryParams.typ}].length`)})`
		}

		const zuCislo = selectedFilters[queryParams.zmluvnyUcetCislo]

		const nextBackUrl = [window.location.pathname, window.location.search]
		if (backUrl) {
			if (!isEmpty(window.location.search)) {
				nextBackUrl.push(`&pohladavkaID=${pohladavkaID}&backUrl=${backUrl}`)
			} else {
				nextBackUrl.push(`?pohladavkaID=${pohladavkaID}&backUrl=${backUrl}`)
			}
		}

		const query = qs.stringify(
			{
				...selectedFilters,
				backUrl: btoa(join(nextBackUrl, ''))
			},
			{ skipNulls: true, encode: false }
		)

		const pohladavkaIDItems = split(pohladavkaID, '_')
		const existsEligiblePreplatok = some(get(preplatky, 'data', []), (pohladavka) => {
			return (
				(isEmpty(get(pohladavka, 'odkladSplatnostiDo', [])) ||
					!some(get(pohladavka, 'odkladSplatnostiDo', []), (splatnost) => dayjs(splatnost) >= dayjs().startOf('day'))) &&
				!get(pohladavka, 'platobnyPrikaz') &&
				get(pohladavka, 'typ.id') != PREPLATOK_TYP.PREPLATOK_Z_FAKTURACIE.value
			)
		})

		return (
			<>
				<div className='content-header' style={{ boxShadow: 'none' }}>
					<div className='row' style={{ borderBottom: '1px solid #DFDFDF', paddingBottom: '10px' }}>
						<div className='col-6'>
							{backUrlLink && (
								<Link
									to={{
										pathname: head(split(backUrlLink, '?')),
										state: { fromDetail: true, pohladavkaID: head(pohladavkaIDItems), pohladavkaTyp: get(pohladavkaIDItems, '[1]') }
									}}
									className='button pull-left'
									data-type='back-button'
									data-color='blue'
									style={{ marginTop: '20px' }}
								>
									{t('translation:Common.Späť')}
								</Link>
							)}
							<h2 style={{ display: 'inline-block', marginBottom: '0' }}>
								{get(selectedFilters, queryParams.zmluvnyUcetCislo)
									? t('translation:Preplatky.Preplatky - Zmluvný účet {zuCislo}', { zuCislo })
									: t('translation:Preplatky.Preplatky')}
							</h2>
							<br />
							<small>
								{t('translation:Common.Aktualizované k ')} {formatDate(new Date(), '-', 'DD.MM.YYYY')}{' '}
								{dayjs().subtract(get(auth, 'config.stavUctuUpdateOffset'), 'minute').format('H:mm')}
							</small>
						</div>
						<div className='col-2' />
						<div className='col-2 header-amount'>
							<span>{t('translation:Preplatky.Preplatky')}</span>
							<br />
							<strong>
								<NumericFormat
									thousandSeparator={' '}
									decimalSeparator={','}
									decimalScale={2}
									fixedDecimalScale={true}
									displayType='text'
									suffix=' €'
									value={get(pohladavkyZostatok, 'data.sumaPreplatky')}
								/>
							</strong>
						</div>
						<div className='col-2'>
							<Permissions
								allowed={[PERMISSIONS.UKON_VRATENIE_PREPLATKOV]}
								render={(hasPerm, actions) => {
									const canPerformAction =
										!isEmpty(get(preplatky, 'data', [])) && existsEligiblePreplatok && selectedFilters[queryParams.zmluvnyUcetCislo]
									const actionBtn = (
										<Link
											to={`${setRouteParams(PREPLATKY_VYTVORIT, get(interakcia, 'opCislo'))}?${query}`}
											className={cx('button', 'pull-right', { disabled: !hasPerm || !canPerformAction })}
											style={{ marginTop: '15px' }}
											type='button'
											data-color='blue'
											onClick={(e) => {
												if (!hasPerm || !canPerformAction) {
													e.preventDefault()
													if (!hasPerm) {
														actions.openForbiddenModal()
													}
												}
											}}
										>
											{t('translation:Preplatky.Vrátiť preplatky')}
										</Link>
									)

									let tooltipText
									if (!hasPerm) {
										tooltipText = <span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>
									} else if (!selectedFilters[queryParams.zmluvnyUcetCislo]) {
										tooltipText = <span>{t('translation:Preplatky.Vyberte konkrétny zmluvný účet cez filter')}</span>
									} else if (isEmpty(get(preplatky, 'data', []))) {
										tooltipText = <span>{t('translation:Preplatky.Filtru nevyhovuje žiadny preplatok')}</span>
									} else if (!existsEligiblePreplatok) {
										tooltipText = <span>{t('translation:Preplatky.Neexistuje žiadny preplatok na vrátenie')}</span>
									}
									return tooltipText ? (
										<Tooltip html={tooltipText} position='left' trigger='mouseenter' theme='light'>
											{actionBtn}
										</Tooltip>
									) : (
										actionBtn
									)
								}}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col-3'>
							<div className='input-wrapper no-icon'>
								<label htmlFor='invoices-filter-search'>{t('translation:Common.filter.Hľadanie')}</label>
								<input
									type='text'
									className='input-field'
									data-type='search'
									onChange={(e) => {
										this.filterChanged(
											{
												value: trim(get(e, 'target.value')) || null
											},
											queryParams.variabilnySymbol
										)
									}}
									value={selectedFilters[queryParams.variabilnySymbol] || ''}
									placeholder={t('translation:Preplatky.filter.Napíšte číslo faktúry')}
								/>
							</div>
						</div>
						<div className='col-3'>
							<Select
								classNamePrefix='react-select'
								label={t('translation:Common.Zmluvný účet')}
								options={filtersOptions[queryParams.zmluvnyUcetCislo]}
								onChange={(zuCislo) => this.filterChanged(zuCislo, queryParams.zmluvnyUcetCislo)}
								closeMenuOnSelect={false}
								placeholder={''}
								value={find(filtersOptions[queryParams.zmluvnyUcetCislo], (option) => option.value == zuCislo)}
							/>
						</div>
						<div className='col-3'>
							<Select
								className={cx({ 'active-filters': isActiveTypFilter, green: isActiveTypFilter })}
								label={t('translation:Preplatky.filter.Typ položky')}
								options={filtersOptions[queryParams.typ]}
								onChange={(typ) => this.multiFilterAdd(typ, queryParams.typ)}
								placeholder={typPolozkyPlaceholder}
								isMulti
								closeMenuOnSelect={false}
							/>
						</div>
						<div className='col-3' />
						<div className='col-12'>
							<div className='filter-items clearfix' style={{ paddingRight: '150px' }}>
								{filterItems}
								{!isEmpty(filterItems) && (
									<div
										className='filter-item'
										style={{ margin: 0, position: 'absolute', top: '5px', right: '15px', cursor: 'pointer' }}
										data-color='red'
										onClick={() => this.clearFilters()}
									>
										<span className='filter-text'>{t('translation:Common.filter.Zrušiť filtre')}</span>
										<span className='remove' />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<Table columns={columns} triedenie={selectedFilters[queryParams.triedenie]} sortingColumn={this.sortingColumn} />
				{content}
			</>
		)
	}

	render = () => {
		const { preplatky, ciselnikySelectBox, selectedFilters, t } = this.props

		const { data, isLoading, isFailure, isLastPage, page } = preplatky

		if ((isLoading && page == 1) || get(ciselnikySelectBox, 'zmluvneUcty.isLoading')) {
			return this.commonContentContainer(
				<div className='box' style={{ height: '500px' }}>
					<ElementLoading />
				</div>
			)
		}

		if (isFailure || get(ciselnikySelectBox, 'zmluvneUcty.isFailure')) {
			return this.failureContentElement()
		}

		if (isEmpty(data)) {
			if (!selectedFilters[queryParams.zmluvnyUcetCislo]) {
				return this.emptyContentElement(t('translation:Preplatky.Vyberte konkrétny zmluvný účet cez filter'))
			}
			return this.emptyContentElement()
		}

		const loadMoreBtn = isLoading && page > 1 ? t('translation:Common.Načítavam') : t('translation:Common.Načítať ďalšie položky')

		const rows = map(data, (pohladavka, index) => <PreplatkyTableRow key={`preplatok-${index}`} t={t} pohladavka={pohladavka} />)

		return this.commonContentContainer(
			<div id='scroll-pohladavky-container' className='content-wrapper'>
				<div className='row'>
					<div className='col-12'>
						<div className='box'>
							<div className='box-content table-wrapper' style={{ padding: 0 }}>
								<table id='pohladavky' className='content-table padded bordered' cellSpacing='0' style={{ overflow: 'scroll', height: '100%' }}>
									<tbody style={{ position: 'relative' }}>{rows}</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				{!isLastPage && (
					<div className='text-center'>
						<div className='button' data-color='blue' data-type='outline' style={{ margin: '20px 0' }} onClick={this.loadMore}>
							{loadMoreBtn}
						</div>
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	preplatky: get(state, 'preplatky.preplatky'),
	ciselniky: get(state, 'ciselniky.data'),
	ciselnikySelectBox: get(state, 'ciselnikySelectBox'),
	interakcia: get(state, 'interakcie.detail.data'),
	selectedFilters: get(get(state, 'selectedFilters'), FILTER_SELECTORS.PREPLATKY_PAGE, {}),
	pohladavkyZostatok: get(state, 'obchodnyPartner.pohladavkyZostatok')
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
	pohladavkyActions: bindActionCreators(PohladavkyActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch),
	ciselnikySelectBoxActions: bindActionCreators(CiselnikySelectBoxActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_POHLADAVKY])
)(VrateniePreplatkovZoznamPage)
