import { forEach, trim } from 'lodash'

const empty = ''
const nbsp = '\u00A0' // unicode
const prepositions = [
	'a',
	'o',
	'u',
	'i',
	'v',
	'vo',
	'z',
	'zo',
	'za',
	'do',
	'k',
	'ku',
	's',
	'so',
	'pri',
	'pre',
	'pod',
	'pred',
	'na',
	'nad',
	'A',
	'O',
	'U',
	'I',
	'V',
	'Vo',
	'Z',
	'Zo',
	'Za',
	'Do',
	'K',
	'Ku',
	'S',
	'So',
	'Pri',
	'Pre',
	'Pod',
	'Pred',
	'Na',
	'Nad'
]

const fixInvalidDateFormats = (source) => {
	const dateRegex = /([0-9]{1,2}\.\s+[0-9]{1,2}\.\s*|[0-9]{1,2}\.\s*[0-9]{1,2}\.\s+)[0-9]{4}/g

	let dateGroup = dateRegex.exec(source)

	while (dateGroup) {
		// remove whitespaces from dates
		source = source.replace(dateGroup[0], dateGroup[0].replace(/\s+/g, ''))
		dateGroup = dateRegex.exec(source)
	}

	return source
}

const setPreferredDateFormat = (source) => {
	const dateRegex = /[0-9]{1,2}.[0-9]{1,2}.[0-9]{4}/g

	let dateGroup = dateRegex.exec(source)

	while (dateGroup) {
		// add nbsp (space) after '.'
		source = source.replace(dateGroup[0], dateGroup[0].replace(/\./g, `.${nbsp}`))
		dateGroup = dateRegex.exec(source)
	}

	return source
}

export const formatHTML = (text) => {
	let temp = (text || empty)
		.replace(/<br>/g, empty)
		.replace(/<br\/>/g, empty)
		.replace(/&nbsp;/g, ' ')

	forEach(prepositions, (preposition) => {
		temp = temp.replace(`/\b${preposition}/g`, `${preposition}${nbsp}`)
	})

	temp = trim(temp.replace(/<p>\s+<\/p>/g, '<p></p>'))

	let result = temp == '<p></p>' ? empty : temp

	if (result !== empty) {
		result = fixInvalidDateFormats(result)
		result = setPreferredDateFormat(result)
	}

	return result
}
