import React from 'react'
import { compose, bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { get, filter, map, find, isEmpty, pick, keys, indexOf, values } from 'lodash'
import { initialize, reset, destroy, change, touch } from 'redux-form'
import dayjs from 'dayjs'
import qs from 'qs'
import reactQueryParams from 'react-query-params'
import PropTypes from 'prop-types'

// components
import BlokovanieUpominaniaSearch from '../../components/BlokovanieUpominania/BlokovanieUpominaniaSearch/BlokovanieUpominaniaSearch'
import BlokovanieUpominaniaInfo from '../../components/BlokovanieUpominania/BlokovanieUpominaniaInfo/BlokovanieUpominaniaInfo'
import BlokovanieUpominaniaConfirm from '../../components/BlokovanieUpominania/BlokovanieUpominaniaConfirm'
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'

// actions
import * as TrackingActions from '../../actions/TrackingActions'
import * as UkonyActions from '../../actions/UkonyActions'
import * as CiselnikySelectBoxActions from '../../actions/CiselnikySelectBoxActions'
import * as DataActions from '../../actions/DataActions'
import * as FiltersActions from '../../actions/SelectedFiltersActions'
import * as StatusActions from '../../actions/StatusActions'
import * as PohladavkyActions from '../../actions/ObchodniPartneri/PohladavkyActions'
import * as SearchActions from '../../actions/SearchActions'

// utils
import { postReq, deleteReq, putReq } from '../../utils/request'
import { BUSINESS_CHANNELS, FORMS, UKONY, UKONY_CISELNIK, FILTER_SELECTORS, DOKUMENT_TYP } from '../../utils/enums'
import { setRouteParams, BLOKOVANIE_UPOMINANIA_ZOZNAM } from '../../utils/routes'
import { history } from '../../utils/history'
import { createFormInitValues } from '../../utils/form'
import { getUkonVstupByKanal } from '../../utils/scenar'
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'

const queryParams = {
	zmluvnyUcetCislo: 'zmluvnyUcetCislo',
	variabilnySymbol: 'variabilnySymbol',
	triedenie: 'triedenie'
}

class BlokovanieUpominaniaCreatePage extends reactQueryParams {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		interakcia: PropTypes.shape({
			id: PropTypes.any.isRequired
		}).isRequired,
		auth: PropTypes.shape().isRequired,
		trackingActions: PropTypes.shape().isRequired,
		ukonyActions: PropTypes.shape({
			clearUkonId: PropTypes.func.isRequired
		}).isRequired,
		ciselnikySelectBoxActions: PropTypes.shape().isRequired,
		dataActions: PropTypes.shape().isRequired,
		filtersActions: PropTypes.shape().isRequired,
		statusActions: PropTypes.shape().isRequired,
		pohladavkyActions: PropTypes.shape().isRequired,
		searchActions: PropTypes.shape().isRequired,
		tracking: PropTypes.shape().isRequired,
		ukonNovy: PropTypes.shape().isRequired,
		ciselniky: PropTypes.shape().isRequired,
		ciselnikySelectBox: PropTypes.shape().isRequired,
		statuses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		t: PropTypes.func.isRequired
	}

	defaultQueryParams = {
		[queryParams.zmluvnyUcetCislo]: null,
		[queryParams.variabilnySymbol]: null,
		[queryParams.triedenie]: null,
		backUrl: ''
	}

	_mounted = false

	constructor(props) {
		super(props)

		const { backUrl } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			backUrl: ''
		})

		this.state = {
			step: 1,
			isLoading: false,
			context: {},
			backUrlLink
		}
	}

	componentDidMount = async () => {
		this._mounted = true

		const { dispatch, auth, trackingActions, dataActions, ciselnikySelectBoxActions, tracking, ciselniky } = this.props

		const filters = pick(
			this.queryParams,
			keys(this.queryParams).filter((key) => {
				return indexOf(values(queryParams), key) != -1 && !isEmpty(this.queryParams[key])
			})
		)

		if (this._mounted) {
			this.setState({ filters })
		}

		ciselnikySelectBoxActions.loadCiselnikZmluvneUcty()
		dataActions.registerLeavePageModal()

		if (!tracking.startTime || (tracking.startTime && tracking.type !== UKONY.BLOKOVANIE_UPOMINANIA)) {
			trackingActions.tryToStartTracking(UKONY.BLOKOVANIE_UPOMINANIA)
		}

		dispatch(
			initialize(
				FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE_VYBER_FAKTUR,
				createFormInitValues({
					pohladavky: []
				}),
				true
			)
		)
		dispatch(reset(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE_VYBER_FAKTUR))

		const initValues = createFormInitValues({
			ukonVstup: getUkonVstupByKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'ukonVstup')),
			datumPrijatiaZiadosti: new Date(),
			podpisMiesto: get(auth, 'user.podpisMiesto', ''),
			splnomocnenec: '',
			zuCislo: filters[queryParams.zmluvnyUcetCislo],
			pohladavky: [],
			pripad: null,
			pocetKD: null
		})

		dispatch(initialize(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE, initValues, true))
		dispatch(reset(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE))

		if (!get(initValues, 'ukonVstup')) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE, 'ukonVstup'))
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(get(initValues, 'dokumenty'))) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE, 'dokumenty'))
		}
		dispatch(touch(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE, 'pripad'))
	}

	componentWillUnmount = () => {
		const { dispatch, trackingActions, ukonyActions, dataActions, pohladavkyActions } = this.props

		dispatch(destroy(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE))
		dispatch(destroy(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE_VYBER_FAKTUR))

		pohladavkyActions.blokovanieUpominaniaClear()
		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()

		// clear ukonId from storage
		ukonyActions.clearUkonId()

		this._mounted = false
	}

	formatDataForUkon = (values) => {
		const { interakcia, auth, tracking, ciselniky } = this.props

		const typ = find(get(ciselniky, 'ukonTyp', []), (ukonTyp) => get(ukonTyp, 'id') == UKONY_CISELNIK.BLOKOVANIE_UPOMINANIA)
		const vstup = find(get(ciselniky, 'ukonVstup', []), (vstup) => get(vstup, 'id') == get(values, 'ukonVstup'))
		const pripad = find(get(ciselniky, 'blokovaniePripady', []), (pripad) => get(pripad, 'id') == get(values, 'pripad'))

		return {
			typ,
			opCislo: get(interakcia, 'opCislo'),
			interakciaId: get(interakcia, 'id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
			zacatyOd: dayjs(tracking.startTime).toISOString(),
			vstup,
			podpisMiesto: get(values, 'podpisMiesto'),
			poznamka: get(values, 'poznamka'),
			ziadanyOd: dayjs(get(values, 'datumPrijatiaZiadosti')).toISOString(),
			splnomocnenec: get(values, 'splnomocnenec'),
			data: {
				zuCislo: get(values, 'zuCislo'),
				pohladavky: get(values, 'pohladavky', []),
				pripad,
				pocetKD: get(values, 'pocetKD')
			}
		}
	}

	setLoading = (value) => {
		if (this._mounted) {
			this.setState({
				isLoading: value
			})
		}
	}

	onDeleteFile = async (fileID) => {
		const { ukonNovy } = this.props

		try {
			await deleteReq(`/api/v0/ukony/${ukonNovy.id}/dokumenty/${fileID}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	stepOneClickHandler = async (values) => {
		const { dispatch } = this.props

		try {
			this.setLoading(true)

			dispatch(change(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE, 'pohladavky', get(values, 'pohladavky')))

			if (this._mounted) {
				this.setState({
					step: 2,
					isLoading: false
				})
			}
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	stepTwoClickHandler = async (values) => {
		const { dispatch, interakcia, ukonyActions, tracking, ukonNovy } = this.props

		this.setLoading(true)

		const body = this.formatDataForUkon(values)

		try {
			let ukonId
			// if ukon not exist yet create a new one else update it
			if (!ukonNovy.id) {
				const res = await postReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ukony`, null, body)

				// set podpis Miesto from response CP-588
				const podpisMiesto = get(res, 'response.content.podpisMiesto')
				dispatch(change(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE, 'podpisMiesto', podpisMiesto))

				ukonId = get(res, 'response.content.id')
				ukonyActions.setUkonId(ukonId)
			} else {
				ukonId = ukonNovy.id
				const updateBody = {
					...body,
					trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
					id: ukonId
				}
				await putReq(`/api/v2/ukony/${ukonId}`, null, updateBody)
			}

			// dokumenty which are not upload does not have ID
			const notUploadedDokumenty = filter(get(values, 'dokumenty', []), (dokument) => !get(dokument, 'id'))
			const uploadedDokumenty = filter(get(values, 'dokumenty', []), (dokument) => get(dokument, 'id'))

			const dokumenty = map(notUploadedDokumenty, async (file) => {
				const dokumentResponse = await postReq(`/api/v0/ukony/${ukonId}/prilozit-dokument`, null, {
					contentType: file.type,
					nazov: file.name,
					data: file.dataAsBase64,
					typ: {
						id: DOKUMENT_TYP.VSTUPNY
					}
				})
				return {
					type: get(dokumentResponse, 'response.contentType'),
					id: get(dokumentResponse, 'response.id'),
					dataAsBase64: get(file, 'dataAsBase64'),
					typ: get(dokumentResponse, 'response.typ'),
					name: get(dokumentResponse, 'response.nazov')
				}
			})

			const dokumentyPromises = await Promise.all(dokumenty)

			// merge already uploaded dokumenty and fresh uploaded
			dispatch(change(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE, 'dokumenty', [...uploadedDokumenty, ...dokumentyPromises]))

			if (this._mounted) {
				this.setState({
					step: 3,
					isLoading: false
				})
			}
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	stepThreeClickHandler = async (values) => {
		const { tracking, dataActions, ukonNovy, t } = this.props

		this.setLoading(true)

		try {
			const body = this.formatDataForUkon(values)

			await postReq(`/api/v2/ukony/${ukonNovy.id}/spustit`, null, {
				...body,
				trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
				id: ukonNovy.id
			})

			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Úkon bol úspešne odoslaný'),
					success: true,
					isLoading: false
				})
			}
		} catch (e) {
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
					success: false,
					isLoading: false
				})
			}
		}
	}

	confirmModal = () => {
		const { interakcia, ukonyActions, selectedFiltersUkony } = this.props
		const { success } = this.state

		if (this._mounted) {
			if (success) {
				this.setState(
					{
						success: false
					},
					() => {
						// clear ukonId from storage
						ukonyActions.clearUkonId()
						// refetch new data for historia ukonov
						ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)
						history.replace(setRouteParams(BLOKOVANIE_UPOMINANIA_ZOZNAM, get(interakcia, 'opCislo')))
					}
				)
			} else {
				this.setState({
					result: null
				})
			}
		}
	}

	render = () => {
		const { interakcia, t } = this.props
		const { step, isLoading, success, result, filters, backUrlLink } = this.state

		if (!this._mounted || isLoading) {
			return <ElementLoading />
		}

		if (!filters[queryParams.zmluvnyUcetCislo]) {
			return (
				<div className='content-wrapper'>
					<ElementFailure text={t('translation:BlokovanieUpominania.Vyberte konkrétny zmluvný účet cez filter')} />
				</div>
			)
		}

		let modal

		// check if every Ko kriterium pass condition, if not navigate user back to step 1
		const nextStep = step

		let component
		if (!modal) {
			switch (nextStep) {
				case 1:
					component = (
						<BlokovanieUpominaniaSearch
							{...this.props}
							filters={filters}
							formTitle={t('translation:BlokovanieUpominania.Krok 1 z 3 Blokovanie upomínania')}
							onSubmit={this.stepOneClickHandler}
							onBackClick={() => history.push(backUrlLink || setRouteParams(BLOKOVANIE_UPOMINANIA_ZOZNAM, get(interakcia, 'opCislo')))}
							onCancelClick={() => history.push(backUrlLink || setRouteParams(BLOKOVANIE_UPOMINANIA_ZOZNAM, get(interakcia, 'opCislo')))}
						/>
					)
					break
				case 2:
					component = (
						<BlokovanieUpominaniaInfo
							{...this.props}
							formTitle={t('translation:BlokovanieUpominania.Krok 2 z 3 Blokovanie upomínania')}
							onSubmit={this.stepTwoClickHandler}
							onDeleteFile={this.onDeleteFile}
							onBackClick={() => {
								this.setState({ step: 1 })
							}}
							onCancelClick={() => history.push(backUrlLink || setRouteParams(BLOKOVANIE_UPOMINANIA_ZOZNAM, get(interakcia, 'opCislo')))}
						/>
					)
					break
				case 3: {
					component = (
						<BlokovanieUpominaniaConfirm
							{...this.props}
							formTitle={t('translation:BlokovanieUpominania.Krok 3 z 3 Blokovanie upomínania')}
							onSubmit={this.stepThreeClickHandler}
							onBackClick={() => {
								this.setState({ step: 2 })
							}}
							onCancelClick={() => history.push(backUrlLink || setRouteParams(BLOKOVANIE_UPOMINANIA_ZOZNAM, get(interakcia, 'opCislo')))}
						/>
					)
					break
				}
				default:
			}
		}

		return (
			<>
				{result && (
					<DefaultModal
						modalTitle={success ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
						modalContent={result}
						leftButton={{
							onClick: this.confirmModal,
							text: t('translation:Common.Zavrieť'),
							color: success ? 'green' : 'red'
						}}
						visible
					/>
				)}
				{component}
				{modal}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth'),
	tracking: get(state, 'tracking'),
	ukonNovy: get(state, 'ukony.ukonNovy'),
	ciselniky: get(state, 'ciselniky.data'),
	ciselnikySelectBox: get(state, 'ciselnikySelectBox'),
	statuses: get(state, 'statuses.statuses'),
	blokovanieUpominania: get(state, 'blokovanieUpominania.blokovanieUpominania'),
	addresses: get(state, 'formAddresses.data'),
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	ciselnikySelectBoxActions: bindActionCreators(CiselnikySelectBoxActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch),
	statusActions: bindActionCreators(StatusActions, dispatch),
	pohladavkyActions: bindActionCreators(PohladavkyActions, dispatch),
	searchActions: bindActionCreators(SearchActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.UKON_BLOKOVANIE_UPOMINANIA])
)(BlokovanieUpominaniaCreatePage)
