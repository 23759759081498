import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get, forEach, upperFirst, map, isEmpty, orderBy, filter, keys } from 'lodash'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import cx from 'classnames'
import qs from 'qs'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tippy'

// atoms
import DropZoneField from '../../atoms/DropZoneField'
import Input from '../../atoms/Input'

// components
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import ElementLoading from '../../components/ElementLoading'
import ArchivaciaEmailDetail from '../../components/Modals/ArchivaciaEmailDetail'
import DefaultModal from '../../components/Modals/DefaultModal'

// containers
import UkonZmenaUdajovOP from './UkonZmenaUdajovOP'
import UkonZmenaUdajovZU from './UkonZmenaUdajovZU'
import UkonZmenaRozpisovZaloh from './UkonZmenaRozpisovZaloh'
import UkonKonzultaciaFaktury from './UkonKonzultaciaFaktury'
import UkonKonzultaciaUpomienky from './UkonKonzultaciaUpomienky'
import UkonKonzultaciaUctovnehoDokladu from './UkonKonzultaciaUctovnehoDokladu'
import UkonDuplikatFaktury from './UkonDuplikatFaktury'
import UkonDuplikatUpomienky from './UkonDuplikatUpomienky'
import UkonDuplikatUctovnehoDokladu from './UkonDuplikatUctovnehoDokladu'
import UkonDuplikatSEPAMandatu from './UkonDuplikatSEPAMandatu'
import UkonVseobecnyPasivny from './UkonVseobecnyPasivny'
import UkonVseobecnyAktivny from './UkonVseobecnyAktivny'
import UkonZmenaUdajovMS from './UkonZmenaUdajovMS'
import UkonKonzultaciaPlatby from './UkonKonzultaciaPlatby'
import UkonZmenaZasielaniaFaktur from './UkonZmenaZasielaniaFaktur'
import UkonZmenaSposobuPlatby from './UkonZmenaSposobuPlatby'
import UkonPodpisSEPAMandatu from './UkonPodpisSEPAMandatu'
import UkonSplnomocnenie from './UkonSplnomocnenie'
import UkonSplnomocnenieUkoncenie from './UkonSplnomocnenieUkoncenie'
import UkonPlanSplatok from './UkonPlanSplatok'
import UkonVrateniePreplatkov from './UkonVrateniePreplatkov'
import UkonBlokovanieUpominania from './UkonBlokovanieUpominania'
import UkonZmenaNastaveniZU from './UkonZmenaNastaveniZU'
import UkonSystemoveListy from './UkonSystemoveListy'
import UkonGeneric from './UkonGeneric'
import UkonZalozenieOP from './UkonZalozenieOP'
import UkonVseobecnyAnonymny from './UkonVseobecnyAnonymny'

// utils
import { history } from '../../utils/history'
import {
	FILTER_SELECTORS,
	NOTIFICATION_TYPES,
	DOKUMENT_TYP,
	DOCUMENT_CONTENT_TYPE,
	UKON_STAV,
	BUSINESS_CHANNELS,
	UKONY_CISELNIK,
	STORNO_DOVOD,
	EXTERNAL_INTEGRATIONS
} from '../../utils/enums'
import { getReq, postReq } from '../../utils/request'
import { formatAddress } from '../../utils/address'
import { formatDate, formatDateWithTime } from '../../utils/date'
import { openDataUriWindow, openLiveAgentTicketPreview } from '../../utils/files'
import { getUkonStavString } from '../../utils/notifikacie'
import Permissions, { checkPermissions, getRequiredPermissionsForUkonRead, getRequiredPermissionsForUkonWrite } from '../../utils/permissionsHoc'
import { setRouteParams, PREHLAD, GENERIC_UKON } from '../../utils/routes'
import { isPodpisovyUkon } from '../../utils/ukony'

class UkonDetailPage extends React.Component {
	static propTypes = {
		auth: PropTypes.shape().isRequired,
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		computedMatch: PropTypes.shape({
			params: PropTypes.shape({
				opCislo: PropTypes.any.isRequired,
				ukonCislo: PropTypes.any.isRequired
			}).isRequired
		}).isRequired,
		t: PropTypes.func.isRequired,
		selectedFilters: PropTypes.shape(),
		ciselniky: PropTypes.shape(),
		cesCiselniky: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		const { backUrl } = qs.parse(window.location.search, {
			ignoreQueryPrefix: true
		})

		let backUrlLink = null
		if (backUrl) {
			backUrlLink = atob(backUrl)
		}

		this.state = {
			isLoading: false,
			isFailure: false,
			ukonInterakcia: null,
			ukon: null,
			ukonDataPred: null,
			ukonData: null,
			documents: null,
			documentsForUpload: [],
			documentsIsUploading: false,
			archivaciaEmailDetail: null,
			showMissingPermissionsModal: false,
			backUrlLink,
			showStornoModal: false,
			showStornoResultModal: false,
			stornoPopis: null,
			stornoDovod: STORNO_DOVOD.STORNO_POUZIVATELOM,
			stornoResult: null,
			processingStorno: false
		}
	}

	async componentDidMount() {
		const { auth } = this.props

		const ukon = await this.loadUkon()
		const permissions = getRequiredPermissionsForUkonRead(ukon)
		if (!isEmpty(permissions) && !checkPermissions(get(auth, 'user.roles', []), permissions)) {
			return this.setState({
				showMissingPermissionsModal: true
			})
		}
	}

	async componentDidUpdate() {
		const { auth, computedMatch } = this.props
		const { ukon, isLoading } = this.state

		if (!isLoading && get(ukon, 'id') && get(ukon, 'id') != get(computedMatch, 'params.ukonCislo')) {
			const ukon = await this.loadUkon()
			const permissions = getRequiredPermissionsForUkonRead(ukon)
			if (!isEmpty(permissions) && !checkPermissions(get(auth, 'user.roles', []), permissions)) {
				return this.setState({
					showMissingPermissionsModal: true
				})
			}
		}
	}

	loadUkon = async () => {
		try {
			const { computedMatch } = this.props

			this.setState({
				isLoading: true
			})

			const cisloOP = get(computedMatch, 'params.cisloOP')
			const ukonCislo = get(computedMatch, 'params.ukonCislo')

			// load ukon detail data
			const res = await getReq(`/api/v2/op/${cisloOP}/ukony/${ukonCislo}`)
			// load detail interakcie
			const ukonInterakciaId = get(res, 'response.content.interakciaId')
			let resInterakcia
			if (ukonInterakciaId) {
				resInterakcia = await getReq(`/api/v0/interakcie/${ukonInterakciaId}`)
			}

			const platcaOpCislo = get(res, 'response.content.data.platcaOp.cislo')
			let platcaOpResponse
			let platcaOp
			if (platcaOpCislo) {
				platcaOpResponse = await getReq(`/api/v0/obchodni-partneri/${platcaOpCislo}`)
				const tituly = get(platcaOpResponse, 'response.obsah.tituly', [])
				const titulyPredMenom = filter(tituly, (op) => get(op, 'hodnota.pred_menom'))
				const titulyZaMenom = filter(tituly, (op) => !get(op, 'hodnota.pred_menom'))
				const orderedTitulyPredMenom = orderBy(titulyPredMenom, ['poradie'], ['asc'])
				const orderedTitulyZaMenom = orderBy(titulyZaMenom, ['poradie'], ['asc'])
				platcaOp = {
					...get(platcaOpResponse, 'response.obsah', {}),
					titulyPredMenom: map(orderedTitulyPredMenom, (op) => get(op, 'hodnota.nazov')).join(' '),
					titulyZaMenom: map(orderedTitulyZaMenom, (op) => get(op, 'hodnota.nazov')).join(' ')
				}
			}

			const ukon = get(res, 'response.content') || null

			this.setState({
				isLoading: false,
				isFailure: false,
				ukonInterakcia: get(resInterakcia, 'response.obsah', null),
				attributes: get(res, 'response.content.atributy'),
				ukon: get(res, 'response.content'),
				ukonData: {
					...get(res, 'response.content.data'),
					platcaOp
				},
				ukonDataPred: {
					...get(res, 'response.content.dataPred')
				},
				log: get(res, 'response.content.log'),
				documents: get(res, 'response.content.dokumenty', [])
			})

			return ukon
		} catch (e) {
			this.setState({
				isLoading: false,
				isFailure: true,
				ukon: null,
				ukonInterakcia: null,
				ukonDataPred: null,
				ukonData: null,
				documents: null
			})
		}
	}

	confirmStorno = async () => {
		const { ukon, stornoPopis, stornoDovod } = this.state

		let stornoResult = false

		try {
			this.setState({
				processingStorno: true
			})

			await postReq(`/api/v2/ukony/${get(ukon, 'id')}/storno`, null, {
				popis: stornoPopis,
				stornoDovod
			})

			stornoResult = true
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(`Error, can not storno ukon: ${get(ukon, 'id')}`, error)
		} finally {
			this.setState({
				stornoResult,
				showStornoResultModal: true,
				stornoPopis: null,
				stornoDovod: STORNO_DOVOD.STORNO_POUZIVATELOM,
				showStornoModal: false,
				processingStorno: false
			})
		}
	}

	getNotificationUriType = (type) => {
		const { t } = this.props

		switch (type) {
			case 'PEVNA_LINKA':
				return `(${t('translation:Notifications.Pevná linka')})`
			case 'EMAIL':
				return `(${t('translation:Notifications.Email')})`
			case 'FAX':
				return `(${t('translation:Notifications.Fax')})`
			case 'MOBIL':
				return `(${t('translation:Notifications.SMS')})`
			case 'URL':
				return `(${t('translation:Notifications.URL')})`
			default:
				return ''
		}
	}

	isStornoEnabled = () => {
		const { ukon } = this.state
		const { auth } = this.props

		if (get(ukon, 'stornovatelny') === false) {
			return false
		}

		const ukonId = get(ukon, 'typ.id')
		// CP-3031 - Zablokovať tlačidlo "Stornovať" pre rolu ZL na úkone podpisu zmluvy
		if (isPodpisovyUkon(ukonId) && get(auth, 'businessChannel.actual.id') === BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
			return false
		}

		return true
	}

	getNotificationValue = (notification) => {
		const { t } = this.props

		return get(notification, 'typ') != NOTIFICATION_TYPES.PRINTER ? get(notification, 'hodnota') : t('translation:Notifications.PRINTER')
	}

	uploadFiles = async () => {
		const { documentsForUpload, ukon } = this.state

		if (isEmpty(documentsForUpload)) {
			return
		}
		this.setState({
			documentsIsUploading: true
		})

		try {
			const documentsPromises = map(documentsForUpload, (file) => {
				const body = {
					contentType: get(file, 'type'),
					nazov: get(file, 'name'),
					data: get(file, 'dataAsBase64'),
					typ: {
						id: DOKUMENT_TYP.OSTATNE
					}
				}

				return postReq(`/api/v0/ukony/${get(ukon, 'id')}/prilozit-dokument`, null, body)
			})

			await Promise.all(documentsPromises)

			// after assing new dokuments to ukon refetch fresh data
			if (!isEmpty(documentsForUpload)) {
				this.setState({
					documentsForUpload: [],
					documentIsUploading: false
				})
				this.loadUkon()
			}
		} catch (e) {
			this.setState({
				documentIsUploading: false
			})
		}
	}

	formatDocuments = (documents, withoutUploadContainer) => {
		const { t } = this.props
		const { documentIsUploading, documentsForUpload } = this.state

		let uploadFilesContainer
		if (documentIsUploading) {
			uploadFilesContainer = (
				<div className='box' style={{ height: '200px' }}>
					<ElementLoading />
				</div>
			)
		} else if (!withoutUploadContainer) {
			uploadFilesContainer = (
				<div className='col-12'>
					<h4>{t('translation:Common.Nahrať dokumenty')}</h4>
					<DropZoneField
						meta={{}}
						input={{
							value: documentsForUpload,
							onChange: (documentsForUpload) => this.setState({ documentsForUpload })
						}}
					/>
					<button
						className={cx('button', 'pull-right', { disabled: isEmpty(documentsForUpload) })}
						data-color='blue'
						type='button'
						onClick={this.uploadFiles}
					>
						{t('translation:Common.Nahrať')}
					</button>
				</div>
			)
		}
		if (!documents || isEmpty(documents)) {
			return <div className='row'>{uploadFilesContainer}</div>
		}

		const othersDocumentsRows = []
		const inputDocumentsRows = []
		const outputDocumentsRows = []

		forEach(documents, (document) => {
			const doc = (
				<tr key={get(document, 'id')}>
					<td style={{ wordBreak: 'break-all' }}>
						<a
							className='file'
							onClick={() => openDataUriWindow(get(document, 'id'), get(document, 'nazov'))}
							data-type='general'
							style={{ cursor: 'pointer' }}
						>
							{get(document, 'nazov')}
						</a>
					</td>
				</tr>
			)

			const dokumentTypID = get(document, 'typ.id')
			if (dokumentTypID == DOKUMENT_TYP.VYSTUPNY || dokumentTypID == DOKUMENT_TYP.UKONCENY || dokumentTypID == DOKUMENT_TYP.STORNO) {
				outputDocumentsRows.push(doc)
			} else if (dokumentTypID == DOKUMENT_TYP.OSTATNE) {
				othersDocumentsRows.push(doc)
			} else {
				inputDocumentsRows.push(doc)
			}
		})

		return (
			<div className='row'>
				<div className='col-6'>
					{!isEmpty(inputDocumentsRows) && (
						<div className='inner-box'>
							<h4>{t('translation:Common.Vstupné dokumenty')}</h4>
							<table className='content-table bordered' cellSpacing='0'>
								<tbody>{inputDocumentsRows}</tbody>
							</table>
						</div>
					)}
				</div>
				<div className='col-6'>
					{!isEmpty(outputDocumentsRows) && (
						<div className='inner-box'>
							<h4>{t('translation:Common.Výstupné dokumenty')}</h4>
							<table className='content-table bordered' cellSpacing='0'>
								<tbody>{outputDocumentsRows}</tbody>
							</table>
						</div>
					)}
				</div>
				{!isEmpty(othersDocumentsRows) && (
					<div className='col-12'>
						<div className='row'>
							<div className='col-6' />
							<div className='col-6'>
								<div className='inner-box'>
									<h4>{t('translation:Common.Ostatné dokumenty')}</h4>
									<table className='content-table bordered' cellSpacing='0'>
										<tbody>{othersDocumentsRows}</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				)}
				{uploadFilesContainer}
			</div>
		)
	}

	render() {
		const {
			isLoading,
			isFailure,
			attributes,
			ukon,
			ukonData,
			ukonDataPred,
			documents,
			ukonInterakcia,
			log,
			archivaciaEmailDetail,
			showMissingPermissionsModal,
			backUrlLink,
			showStornoModal,
			showStornoResultModal,
			stornoPopis,
			stornoDovod,
			stornoResult,
			processingStorno
		} = this.state
		const { auth, interakcia, t, ciselniky, cesCiselniky } = this.props

		if (isLoading) {
			return <ElementLoading />
		}
		if (isFailure) {
			return <ElementFailure text={t('translation:Common.Nepodarilo sa načítať detail úkonu')} />
		}
		if (!ukon) {
			return <ElementEmptyContent text={t('translation:Common.Úkon sa nenašiel')} />
		}

		if (showMissingPermissionsModal) {
			return (
				<div className='content-wrapper'>
					<DefaultModal
						modalTitle={t('translation:Common.Chyba')}
						modalContent={t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}
						leftButton={{
							onClick: () => history.replace(setRouteParams(PREHLAD, get(interakcia, 'opCislo'))),
							text: t('translation:Common.Zavrieť'),
							color: 'red'
						}}
						visible
					/>
				</div>
			)
		}

		let odlozenyUkonAction = null
		let ukonDataEmpty = null
		let ukonDataActiveRows = null
		let ukonDataPasiveRows = null

		const logRows = map(log, (item, index) => {
			const attachments = map(get(item, 'dokumenty', []), (attachment, attachmentIndex) => {
				let onClick
				if (get(attachment, 'contentType') == DOCUMENT_CONTENT_TYPE.ZSEE_EMAIL) {
					onClick = () =>
						this.setState({
							archivaciaEmailDetail: get(attachment, 'id')
						})
				} else {
					onClick = () => openDataUriWindow(get(attachment, 'id'), get(attachment, 'nazov'))
				}
				return (
					<a
						key={`log-${index}-attachment-${attachmentIndex}`}
						className='file'
						onClick={onClick}
						data-type='general'
						style={{
							cursor: 'pointer',
							marginRight: '20px'
						}}
					>
						{get(attachment, 'nazov')}
					</a>
				)
			})
			return (
				<tr key={`logy-${index}`}>
					<td>{formatDateWithTime(get(item, 'ts'))}</td>
					<td>{get(item, 'stav.nazov', '-')}</td>
					<td>
						<div>{get(item, 'komentar', '-')}</div>
						{!isEmpty(attachments) && <div>{attachments}</div>}
					</td>
				</tr>
			)
		})

		const notifikacieUri = map(get(ukon, 'notifikacie.adresyUri', []) || [], (notifikacia, index) => {
			return (
				<tr key={`adresyUri-${index}`}>
					<td>
						{t('translation:Common.Notifikácia')} {this.getNotificationUriType(notifikacia.typ)}
					</td>
					<td>
						<strong>{this.getNotificationValue(notifikacia)}</strong>
					</td>
				</tr>
			)
		})
		const notifikaciePosta = map(get(ukon, 'notifikacie.adresyPosta', []) || [], (adresa, index) => {
			const doRukMeno = get(adresa, 'doRukMeno') ? ` (${t('translation:Common.Do rúk')}: ${get(adresa, 'doRukMeno')})` : ''
			return (
				<tr key={`adresyPosta-${index}`}>
					<td>
						{t('translation:Common.Notifikácia')} ({t('translation:Common.Pošta')})
					</td>
					<td>
						<strong>
							{formatAddress(adresa, false)}
							{doRukMeno}
						</strong>
					</td>
				</tr>
			)
		})
		const notifikacieStav = map(keys(get(ukon, 'notifikacie.adresyStav')), (ukonStav) => {
			const stav = getUkonStavString(parseInt(ukonStav), t)
			let notifikacieUri = map(get(get(ukon, 'notifikacie.adresyStav')[ukonStav], 'adresyUri', []) || [], (notifikacia, index) => {
				return (
					<tr key={`adresyUri-${index}`}>
						<td>
							{t('translation:Common.Doplňujúca notifikácia pre stav')} {stav} {this.getNotificationUriType(notifikacia.typ)}
						</td>
						<td>
							<strong>{this.getNotificationValue(notifikacia)}</strong>
						</td>
					</tr>
				)
			})
			let notifikaciePosta = map(get(get(ukon, 'notifikacie.adresyStav')[ukonStav], 'adresyPosta', []) || [], (adresa, index) => {
				const doRukMeno = get(adresa, 'doRukMeno') ? ` (${t('containers:UkonDetailPage.Do rúk')}: ${get(adresa, 'doRukMeno')})` : ''
				return (
					<tr key={`adresyPosta-${index}`}>
						<td>
							{t('containers:UkonDetailPage.Doplňujúca notifikácia pre stav')} {stav} ({t('containers:UkonDetailPage.Pošta')})
						</td>
						<td>
							<strong>
								{formatAddress(adresa, false)}
								{doRukMeno}
							</strong>
						</td>
					</tr>
				)
			})
			return [...notifikacieUri, ...notifikaciePosta]
		})

		let typeSuffix = null
		if (get(ukon, 'typ.id') == 10) {
			if (!isEmpty(get(ukonData, 'eFakturaEmaily', [])) && isEmpty(get(ukonDataPred, 'eFakturaEmaily', []))) {
				typeSuffix = ` (${t('translation:Common.Aktivácia e-faktúry')})`
			} else if (isEmpty(get(ukonData, 'eFakturaEmaily', [])) && !isEmpty(get(ukonDataPred, 'eFakturaEmaily', []))) {
				typeSuffix = ` (${t('translation:Common.Deaktivácia e-faktúry')})`
			}
		}

		const liveAgentTicket = get(ukonInterakcia, 'externeIDs', []).find((externeID) => {
			return externeID.source === EXTERNAL_INTEGRATIONS.LIVE_AGENT
		})

		const finesse = get(ukonInterakcia, 'externeIDs', []).find((externeID) => {
			return externeID.source === EXTERNAL_INTEGRATIONS.FINESSE
		})

		const call250 = get(ukonInterakcia, 'externeIDs', []).find((externeID) => {
			return externeID.source === EXTERNAL_INTEGRATIONS.CALL250
		})

		const header = (
			<div className='box'>
				<div className='box-content'>
					<div className='row'>
						<div className='col-12'>
							<div className='inner-box'>
								<table>
									<tbody>
										<tr>
											<td width='30%'>{t('translation:Common.ID')}</td>
											<td width='70%'>
												<strong>{get(ukon, 'id') || '-'}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Číslo interakcie')}</td>
											<td width='70%'>
												<strong>{get(ukon, 'interakciaId') || '-'}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%' style={{ verticalAlign: 'top' }}>
												{t('translation:Common.Poznámka z interakcie')}
											</td>
											<td width='70%' style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontWeight: 'bold' }}>
												<div className='input-wrapper'>{get(ukonInterakcia, 'poznamka') || '-'}</div>
											</td>
										</tr>
										{liveAgentTicket?.externalId && (
											<tr>
												<td width='30%'>{t('translation:Common.LiveAgent ticket interakcie')}</td>
												<td width='70%'>
													<a
														className='file'
														onClick={() => openLiveAgentTicketPreview(liveAgentTicket.externalId)}
														data-type='general'
														style={{ cursor: 'pointer' }}
													>
														<strong>{liveAgentTicket.externalId}</strong>
													</a>
												</td>
											</tr>
										)}
										{finesse?.externalId && (
											<tr>
												<td width='30%'>{t('translation:Common.ID hovoru z interakcie')}</td>
												<td width='70%'>
													<strong>{finesse.externalId}</strong>
												</td>
											</tr>
										)}
										{call250?.externalId && (
											<tr>
												<td width='30%'>{t('translation:Common.ID vyvolania z interakcie')}</td>
												<td width='70%'>
													<strong>{call250.externalId}</strong>
												</td>
											</tr>
										)}
										<tr>
											<td width='30%'>{t('translation:Common.Typ úkonu')}</td>
											<td width='70%'>
												<strong>
													{upperFirst(get(ukon, 'typ.nazov') || '-')}
													{typeSuffix}
												</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Stav úkonu')}</td>
											<td width='70%'>
												<strong>{upperFirst(get(ukon, 'ukonStav.nazov') || '-')}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Vstup do úkonu')}</td>
											<td width='70%'>
												<strong>{upperFirst(get(ukon, 'vstup.nazov') || '-')}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Splnomocnenec')}</td>
											<td width='70%'>
												<strong>{upperFirst(get(ukon, 'splnomocnenec') || '-')}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Miesto podpisu úkonu')}</td>
											<td width='70%'>
												<strong>{upperFirst(get(ukon, 'podpisMiesto') || '-')}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Dátum prijatia žiadosti')}</td>
											<td width='70%'>
												<strong>{formatDate(get(ukon, 'ziadanyOd'), '-', 'DD.MM.YYYY')}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Pracovník')}</td>
											<td width='70%'>
												<strong>{get(ukon, 'riesitel') || '-'}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Kanál')}</td>
											<td width='70%'>
												<strong>{get(ukon, 'kanal.popis') || '-'}</strong>
											</td>
										</tr>
										{notifikacieUri}
										{notifikaciePosta}
										{isEmpty(notifikacieUri) && isEmpty(notifikaciePosta) && (
											<tr>
												<td>{t('translation:Common.Notifikácia')}</td>
												<td>
													<strong>-</strong>
												</td>
											</tr>
										)}
										{notifikacieStav}
										<tr>
											<td style={{ verticalAlign: 'top' }}>{t('translation:Common.Poznámka')}</td>
											<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
												<div className='input-wrapper'>
													<textarea
														disabled='disabled'
														rows={isEmpty(get(ukon, 'poznamka')) ? 1 : 7}
														className='form-control static'
														value={get(ukon, 'poznamka') || '-'}
													/>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className='col-8' />
					</div>
				</div>
			</div>
		)

		if (ukonData) {
			const ukonType = get(ukon, 'typ.id', -1)
			switch (ukonType) {
				case 1:
					ukonDataActiveRows = (
						<UkonZmenaUdajovOP t={t} ukonData={ukonData} ukonDataPred={ukonDataPred} dokumenty={documents} formatDocuments={this.formatDocuments} />
					)
					break
				case 2:
					ukonDataActiveRows = (
						<UkonZmenaUdajovZU t={t} ukonData={ukonData} ukonDataPred={ukonDataPred} dokumenty={documents} formatDocuments={this.formatDocuments} />
					)
					break
				case 3:
					ukonDataActiveRows = (
						<UkonZmenaRozpisovZaloh
							t={t}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							dokumenty={documents}
							formatDocuments={this.formatDocuments}
						/>
					)
					break
				case 4:
					ukonDataPasiveRows = <UkonKonzultaciaFaktury t={t} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					break
				case 5:
					ukonDataPasiveRows = <UkonVseobecnyPasivny t={t} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					break
				case 6:
					ukonDataPasiveRows = <UkonVseobecnyAktivny t={t} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					break
				case 7:
					ukonDataPasiveRows = (
						<UkonKonzultaciaPlatby t={t} ukon={ukon} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					)
					break
				case 8:
					ukonDataPasiveRows = (
						<UkonKonzultaciaUpomienky t={t} ukon={ukon} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					)
					break
				case 9:
					ukonDataActiveRows = (
						<UkonZmenaUdajovMS
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							dokumenty={documents}
							formatDocuments={this.formatDocuments}
						/>
					)
					break
				case 10:
					ukonDataActiveRows = (
						<UkonZmenaZasielaniaFaktur
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							dokumenty={documents}
							formatDocuments={this.formatDocuments}
						/>
					)
					break
				case 11:
					ukonDataActiveRows = (
						<UkonZmenaSposobuPlatby
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							dokumenty={documents}
							formatDocuments={this.formatDocuments}
						/>
					)
					break
				case 12:
					ukonDataPasiveRows = (
						<UkonSplnomocnenie
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							dokumenty={documents}
							formatDocuments={this.formatDocuments}
						/>
					)
					break
				case 13:
				case 20:
				case 21:
					ukonDataPasiveRows = <UkonPlanSplatok t={t} ukon={ukon} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					break
				case 14:
					ukonDataPasiveRows = <UkonDuplikatFaktury t={t} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					break
				case 15:
					return (
						<UkonPodpisSEPAMandatu
							logRows={logRows}
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							dokumenty={documents}
							formatDocuments={this.formatDocuments}
							header={header}
							loadUkon={this.loadUkon}
						/>
					)
				case 16:
					ukonDataActiveRows = (
						<UkonSplnomocnenieUkoncenie
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							dokumenty={documents}
							formatDocuments={this.formatDocuments}
						/>
					)
					break
				case 17:
					ukonDataPasiveRows = <UkonDuplikatUpomienky t={t} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					break
				case 18:
					ukonDataPasiveRows = <UkonZalozenieOP t={t} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					break
				case 19:
					ukonDataPasiveRows = <UkonDuplikatSEPAMandatu t={t} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					break
				case 22:
					ukonDataPasiveRows = (
						<UkonKonzultaciaUctovnehoDokladu t={t} ukon={ukon} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					)
					break
				case 23:
					ukonDataPasiveRows = (
						<UkonDuplikatUctovnehoDokladu t={t} ukon={ukon} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					)
					break
				case 24:
					ukonDataPasiveRows = (
						<UkonVrateniePreplatkov t={t} ukon={ukon} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					)
					break
				case 25:
					ukonDataPasiveRows = (
						<UkonBlokovanieUpominania t={t} ukon={ukon} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					)
					break
				case 28:
					ukonDataActiveRows = (
						<UkonZmenaNastaveniZU
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							dokumenty={documents}
							formatDocuments={this.formatDocuments}
							cesCiselniky={cesCiselniky}
						/>
					)
					break
				case 29:
					ukonDataPasiveRows = <UkonSystemoveListy t={t} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					break
				case UKONY_CISELNIK.ANONYMNY_VSEOBECNY_UKON:
					ukonDataPasiveRows = <UkonVseobecnyAnonymny t={t} ukonData={ukonData} dokumenty={documents} formatDocuments={this.formatDocuments} />
					break
				case 48:
					ukonDataActiveRows = (
						<UkonZmenaNastaveniZU
							t={t}
							ukon={ukon}
							ukonData={ukonData}
							ukonDataPred={ukonDataPred}
							dokumenty={documents}
							formatDocuments={this.formatDocuments}
							cesCiselniky={cesCiselniky}
						/>
					)
					break
				case 49:
					ukonDataActiveRows = (
						<UkonZmenaUdajovOP t={t} ukonData={ukonData} ukonDataPred={ukonDataPred} dokumenty={documents} formatDocuments={this.formatDocuments} />
					)
					break
				default: {
					if (get(ukon, 'ukonStav.id') == UKON_STAV.ODLOZENY) {
						// NOTE: CP-2107
						if (
							[
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE,
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS,
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_NOVY_ODBER,
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_OBNOVA_MS,
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_DODATOK,
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_VAS,
								UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS_GAS,
								UKONY_CISELNIK.PODPIS_ZMLUVY_GAS_NOVY_ODBER,
								UKONY_CISELNIK.PODPIS_ZMLUVY_GAS_OBNOVA_MS
							].indexOf(get(ukon, 'typ.id')) < 0 ||
							get(auth, 'businessChannel.actual.id') != BUSINESS_CHANNELS.ZAKAZNICKA_LINKA
						) {
							odlozenyUkonAction = (
								<Link
									className='button pull-right'
									data-color='blue'
									style={{ marginLeft: '20px' }}
									to={{
										pathname: setRouteParams(GENERIC_UKON, get(interakcia, 'opCislo')),
										ponuka: {
											nazov: upperFirst(get(ukon, 'typ.nazov')),
											ukon,
											atributy: get(ukon, 'atributy', [])
										}
									}}
								>
									{upperFirst(get(ukon, 'typ.nazov'))}
								</Link>
							)
						}
					}
					if (!isEmpty(ukonDataPred)) {
						ukonDataActiveRows = (
							<UkonGeneric
								t={t}
								attributes={attributes}
								ukon={ukon}
								ukonData={ukonData}
								ukonDataPred={ukonDataPred}
								dokumenty={documents}
								formatDocuments={this.formatDocuments}
							/>
						)
					} else {
						ukonDataPasiveRows = (
							<UkonGeneric
								t={t}
								attributes={attributes}
								ukon={ukon}
								ukonData={ukonData}
								dokumenty={documents}
								formatDocuments={this.formatDocuments}
							/>
						)
					}
				}
			}
		} else {
			ukonDataEmpty = <ElementEmptyContent text={t('translation:Common.Úkon neobsahuje žiadne dáta')} />
		}

		let modal = null
		if (archivaciaEmailDetail) {
			modal = (
				<ArchivaciaEmailDetail
					modalTitle={t('translation:Common.Archivovaný email')}
					onCloseButton={() =>
						this.setState({
							archivaciaEmailDetail: null
						})
					}
					id={archivaciaEmailDetail}
				/>
			)
		} else if (showStornoResultModal) {
			modal = (
				<DefaultModal
					modalTitle={stornoResult ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
					modalContent={
						stornoResult ? t('translation:Common.Storno úkonu bolo úspešne odoslané') : t('translation:Common.Storno úkonu sa nepodarilo odoslať')
					}
					leftButton={{
						onClick: () => {
							this.setState({
								showStornoResultModal: false,
								stornoResult: false
							})
							this.loadUkon()
						},
						text: t('translation:Common.Zavrieť'),
						color: stornoResult ? 'green' : 'red'
					}}
					visible={showStornoResultModal}
				/>
			)
		}

		const stornoModal = (
			<DefaultModal
				modalTitle={t('translation:Common.Naozaj chcete úkon stornovať?')}
				modalContent={
					<>
						<div className='row'>
							<div className='col-12'>
								<Input
									placeholder={t('translation:Common.Uveďte dôvod')}
									onChange={(e) => {
										this.setState({
											stornoPopis: get(e, 'target.value')
										})
									}}
									maxLength={200}
									required
									error={isEmpty(stornoPopis)}
									disabled={processingStorno}
								/>
							</div>
						</div>
						<div className='row'>
							<div className='col-6'>
								<button
									className='button'
									type='button'
									onClick={() =>
										this.setState({
											stornoDovod: STORNO_DOVOD.STORNO_POUZIVATELOM
										})
									}
									data-color='blue'
									data-type={stornoDovod != STORNO_DOVOD.STORNO_POUZIVATELOM ? 'outline' : ''}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								>
									{t('translation:Common.S notifikáciou')}
								</button>
							</div>
							<div className='col-6'>
								<button
									className='button'
									type='button'
									onClick={() =>
										this.setState({
											stornoDovod: STORNO_DOVOD.STORNO_POUZIVATELOM_BEZ_NOTIFIKACIE
										})
									}
									data-color='blue'
									data-type={stornoDovod != STORNO_DOVOD.STORNO_POUZIVATELOM_BEZ_NOTIFIKACIE ? 'outline' : ''}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								>
									{t('translation:Common.Bez notifikácie')}
								</button>
							</div>
						</div>
					</>
				}
				leftButton={{
					onClick: () => {
						this.setState({
							stornoPopis: null,
							showStornoModal: false
						})
					},
					text: t('translation:Common.Naspäť'),
					color: 'red',
					outline: true,
					disabled: processingStorno
				}}
				rightButton={{
					onClick: this.confirmStorno,
					text: t('translation:Common.Pokračovať'),
					color: 'blue',
					disabled: processingStorno || isEmpty(stornoPopis)
				}}
				visible={showStornoModal}
			/>
		)

		const stornoEnabled = this.isStornoEnabled()
		const writePermissions = getRequiredPermissionsForUkonWrite(ukon)

		return (
			<>
				{(backUrlLink || stornoEnabled || odlozenyUkonAction) && (
					<div className='content-header clearfix'>
						{backUrlLink && (
							<Link to={`${backUrlLink}`} className='button pull-left' data-type='back-button' data-color='blue'>
								{t('containers:Späť')}
							</Link>
						)}
						{stornoEnabled && (
							<Permissions
								allowed={writePermissions}
								render={(hasPerm) => {
									if (hasPerm) {
										return (
											<>
												<button
													className='button pull-right'
													data-color='blue'
													style={{ marginLeft: '20px' }}
													onClick={() => {
														this.setState({
															stornoPopis: null,
															showStornoModal: true
														})
													}}
												>
													{t('translation:Common.Stornovať')}
												</button>
												{stornoModal}
											</>
										)
									}
									return (
										<Tooltip
											className='pull-right'
											html={<span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>}
											position='top'
											trigger='mouseenter'
											theme='light'
										>
											<button
												className={cx('button', 'pull-right', { disabled: true })}
												data-color='blue'
												style={{ marginLeft: '20px' }}
												onClick={(e) => e.preventDefault()}
											>
												{t('translation:Common.Stornovať')}
											</button>
										</Tooltip>
									)
								}}
							/>
						)}
						{odlozenyUkonAction}
					</div>
				)}
				<div className='content-wrapper'>
					{modal}
					{header}
					{ukonDataActiveRows && (
						<div className='box'>
							<div className='box-header'>{t('translation:Common.Detaily úkonu')}</div>
							<div className='box-content'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<thead>
										<tr>
											<th />
											<th>{t('translation:Common.Pôvodné hodnoty')}</th>
											<th>{t('translation:Common.Nové hodnoty')}</th>
										</tr>
									</thead>
									{ukonDataActiveRows}
								</table>
							</div>
						</div>
					)}
					{ukonDataPasiveRows && (
						<div className='box'>
							<div className='box-header'>{t('translation:Common.Detaily úkonu')}</div>
							<div className='box-content'>
								<table className='content-table padded bordered' cellSpacing='0'>
									{ukonDataPasiveRows}
								</table>
							</div>
						</div>
					)}
					{ukonDataEmpty && (
						<div className='box'>
							<div className='box-header'>{t('translation:Common.Detaily úkonu')}</div>
							<div className='box-content' style={{ minHeight: '200px' }}>
								{ukonDataEmpty}
							</div>
						</div>
					)}
					{!isEmpty(logRows) && (
						<div className='box'>
							<div className='box-header'>{t('translation:Common.Logy')}</div>
							<div className='box-content' style={{ minHeight: '200px' }}>
								<table className='content-table padded bordered' cellSpacing='0'>
									<thead>
										<tr>
											<th style={{ width: '180px' }}>{t('translation:Common.Dátum')}</th>
											<th style={{ width: '220px' }}>{t('translation:Common.Stav')}</th>
											<th>{t('translation:Common.Komentár')}</th>
										</tr>
									</thead>
									<tbody>{logRows}</tbody>
								</table>
							</div>
						</div>
					)}
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	interakcia: get(state, 'interakcie.detail.data'),
	selectedFilters: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`),
	ciselniky: get(state, 'ciselniky.data'),
	cesCiselniky: get(state, 'cesCiselniky.data')
})

export default compose(withTranslation('containers'), connect(mapStateToProps))(UkonDetailPage)
