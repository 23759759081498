import React from 'react'
import { get, map, isEmpty } from 'lodash'
import PropTypes from 'prop-types'

// utils
import { formatFormValue } from '../../utils/form'
import { formatAddress } from '../../utils/address'

function UkonZmenaZasielaniaFaktur(props) {
	const { t, ukonData, ukonDataPred, dokumenty, formatDocuments } = props

	const fakturacnaAdresa = formatAddress(get(ukonData, 'fakturacnaAdresa', null), false)
	const fakturacnaAdresaPred = formatAddress(get(ukonDataPred, 'fakturacnaAdresa', null), false)
	const eFakturaEmailyPred = map(get(ukonDataPred, 'eFaktura'), (eFaktura, index) => <p key={index}>{eFaktura.email}</p>)
	const eFakturaEmaily = map(get(ukonData, 'eFaktura'), (eFaktura, index) => (
		<p key={index}>
			<strong>{eFaktura.email}</strong>
		</p>
	))

	return (
		<tbody>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Číslo zmluvného účtu')}</strong>
				</td>
				<td>{get(ukonDataPred, 'zuCislo')}</td>
				<td>{formatFormValue(get(ukonData, 'zuCislo'), get(ukonDataPred, 'zuCislo'))}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Fakturačná adresa')}</strong>
					<br />
					<strong>{t('containers:UkonDetailPage.Do rúk')}</strong>
				</td>
				<td>
					{fakturacnaAdresaPred}
					<br />
					<div>{get(ukonDataPred, 'adresaOdoslanePlatby.doRukMeno')}&nbsp;</div>
				</td>
				<td>
					{formatFormValue(fakturacnaAdresa, fakturacnaAdresaPred)}
					<br />
					<div>{formatFormValue(get(ukonData, 'fakturacnaAdresa.doRukMeno'), get(ukonDataPred, 'fakturacnaAdresa.doRukMeno'))}&nbsp;</div>
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.E-mailové adresy pre e-faktúry')}</strong>
				</td>
				<td>{eFakturaEmailyPred}</td>
				<td>
					{isEmpty(eFakturaEmaily) && !isEmpty(eFakturaEmailyPred) ? (
						<strong>{t('containers:UkonDetailPage.e-faktúra deaktivovaná')}</strong>
					) : (
						eFakturaEmaily
					)}
				</td>
			</tr>
			<tr>
				<td colSpan={3}>{formatDocuments(dokumenty)}</td>
			</tr>
		</tbody>
	)
}

UkonZmenaZasielaniaFaktur.propTypes = {
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	dokumenty: PropTypes.array,
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonZmenaZasielaniaFaktur
