import { get } from 'lodash'
import { PLATOBNE_PRIKAZY_LOAD_START, PLATOBNE_PRIKAZY_LOAD_DONE, PLATOBNE_PRIKAZY_LOAD_FAIL } from '../types/platobnePrikazyTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	detail: {
		data: null,
		isFailure: false,
		isLoading: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case PLATOBNE_PRIKAZY_LOAD_START:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case PLATOBNE_PRIKAZY_LOAD_DONE:
			return {
				...state,
				detail: {
					...state.detail,
					data: get(action, 'payload.detail'),
					isLoading: false,
					isFailure: false
				}
			}
		case PLATOBNE_PRIKAZY_LOAD_FAIL:
			return {
				...state,
				detail: {
					...initState.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
