import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { get, map, find, pick, keys, indexOf, isEmpty, isEqual, trim, debounce, toArray, set, values, upperFirst, split } from 'lodash'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import ReactQueryParams from 'react-query-params'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'

// atoms
import Select from '../../atoms/BasicSelect'

// utils
import { UKONY, FILTER_SELECTORS, NOTIFICATION_TYPES, OBCHODNY_PARTNER_TYP } from '../../utils/enums'
import { postReq } from '../../utils/request'

// components
import FakturaRow from '../../components/TableRows/FakturaRow'
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import ModalSendItemDuplicate from '../../components/Modals/ModalSendItemDuplicate'
import DefaultModal from '../../components/Modals/DefaultModal'

// actions
import * as FiltersActions from '../../actions/SelectedFiltersActions'
import * as CiselnikySelectBoxActions from '../../actions/CiselnikySelectBoxActions'
import * as TrackingActions from '../../actions/TrackingActions'
import FakturyActions from '../../actions/Faktury'
import ZmluvneUctyActions from '../../actions/ObchodniPartneri'
import * as HistoriaUkonovActions from '../../actions/UkonyActions'
import extractProperties from '../../utils/extractProperties'
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'

const emptySelectState = {
	value: '',
	label: 'Všetky'
}

const queryParams = {
	zmluvnyUcetCislo: 'zmluvnyUcetCislo',
	datumVystaveniaOd: 'datumVystaveniaOd',
	datumVystaveniaDo: 'datumVystaveniaDo',
	variabilnySymbol: 'variabilnySymbol',
	fakturaCislo: 'fakturaCislo',
	typ3V1: 'typ',
	fakturaTyp: 'fakturaTyp'
}

const SELECTED_FILTERS_SELECTOR = 'fakturyPage'

class FakturyPage extends ReactQueryParams {
	static propTypes = {
		fakturyStore: PropTypes.shape({
			data: PropTypes.array.isRequired,
			page: PropTypes.number.isRequired,
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}).isRequired,
		fakturyActions: PropTypes.shape({
			loadFaktury: PropTypes.func.isRequired
		}).isRequired,
		interakcia: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		obchodnyPartner: PropTypes.shape().isRequired,
		ciselniky: PropTypes.shape().isRequired
	}

	defaultQueryParams = {
		[queryParams.zmluvnyUcetCislo]: null,
		[queryParams.datumVystaveniaOd]: null,
		[queryParams.datumVystaveniaDo]: null,
		[queryParams.variabilnySymbol]: null,
		[queryParams.fakturaCislo]: null,
		[queryParams.typ3V1]: null,
		[queryParams.fakturaTyp]: null
	}

	constructor(props) {
		super(props)

		this.state = {
			variabilnySymbol: props.selectedFilters.variabilnySymbol || null,
			fakturaCislo: props.selectedFilters.fakturaCislo || null,
			fakturaCisloError: null,
			showModalUkonUC03: false,
			selectedFaktura: null
		}
	}

	componentDidMount = () => {
		const self = this
		const { selectedFilters, filtersActions, fakturyActions, ciselnikySelectBoxActions } = this.props

		const filteredParams = pick(
			self.queryParams,
			keys(self.queryParams).filter(function (key) {
				return indexOf(toArray(queryParams), key) !== -1 && !isEmpty(self.queryParams[key])
			})
		)

		const newFilters = {
			...selectedFilters,
			...filteredParams
		}

		fakturyActions.loadFaktury(1, newFilters)
		ciselnikySelectBoxActions.loadCiselnikZmluvneUcty()
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.FAKTURY_PAGE, newFilters)
	}

	componentDidUpdate = () => {
		const { selectedFilters } = this.props
		// prevent to inject not allowed query param
		const query = extractProperties(this.queryParams, values(queryParams))
		if (!isEqual(query, selectedFilters)) {
			this.setQueryParams({
				...selectedFilters
			})

			if (
				selectedFilters[queryParams.fakturaCislo] != this.state.fakturaCislo ||
				selectedFilters[queryParams.variabilnySymbol] != this.state.variabilnySymbol
			) {
				this.setState({
					fakturaCislo: selectedFilters[queryParams.fakturaCislo],
					variabilnySymbol: selectedFilters[queryParams.variabilnySymbol]
				})
			}
		}
	}

	multiFilterAdd = (filters, fieldName) => {
		const { selectedFilters } = this.props

		if (!get(filters, '[0].value')) {
			return this.removeFilters(fieldName)
		}

		const filterArray = map(filters, (filter) => get(filter, 'value'))
		const oldFilters = split(selectedFilters[fieldName], ',')

		const value = [...oldFilters, ...filterArray].filter((e) => e !== null && e !== '' && e !== '[]').join(',')
		this.filterChanged({ value }, fieldName)
	}

	filterChanged = (filter, fieldName) => {
		const { fakturyActions, selectedFilters, filtersActions } = this.props

		const newFilter = {
			...selectedFilters,
			[fieldName]: null
		}
		switch (fieldName) {
			case queryParams.typ3V1:
			case queryParams.fakturaTyp:
			case queryParams.zmluvnyUcetCislo:
				if (filter && filter.value) {
					newFilter[fieldName] = filter.value
				}
				break
			case queryParams.datumVystaveniaOd:
			case queryParams.datumVystaveniaDo:
				if (filter) {
					newFilter[fieldName] = dayjs(filter).format('YYYY-MM-DD')
				}
				break
			case queryParams.variabilnySymbol:
			case queryParams.fakturaCislo:
				if (filter) {
					newFilter[fieldName] = filter
				}
				break
		}
		fakturyActions.loadFaktury(1, newFilter)
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.FAKTURY_PAGE, newFilter)
	}

	onChangeSearchInput = (value, type) => {
		const trimmed = trim(value)
		this.filterChanged(trimmed, type)
	}

	onChangeSearchInput = debounce(this.onChangeSearchInput, 600)

	removeFilters = (fieldName) => {
		const { selectedFilters } = this.props

		let newFilter
		if (fieldName) {
			newFilter = {
				...selectedFilters,
				[fieldName]: this.defaultQueryParams[fieldName]
			}
		} else {
			newFilter = {
				...this.defaultQueryParams
			}
		}

		if (!isEqual(selectedFilters, newFilter)) {
			this.props.filtersActions.selectedFiltersChanged(FILTER_SELECTORS.FAKTURY_PAGE, newFilter)
			this.props.fakturyActions.loadFaktury(1, newFilter)
		}
	}

	removeFilterValue = (filterName, filterValue) => {
		const { selectedFilters, filtersActions, fakturyActions } = this.props

		const filter = selectedFilters[filterName].split(',').filter((item) => item != filterValue)
		const val = !isEmpty(filter) ? filter.join(',') : null

		const newFilter = {
			...selectedFilters,
			[filterName]: val
		}

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.FAKTURY_PAGE, newFilter)
		fakturyActions.loadFaktury(1, newFilter)
	}

	confirmModal = () => {
		this.setState({
			result: null,
			showModalUkonUC03: false
		})
	}

	onSubmitUkonUC03 = async (data) => {
		const { interakcia, t, auth, tracking, trackingActions, historiaUkonovActions, selectedFiltersUkony } = this.props
		const { selectedFaktura } = this.state

		try {
			let notifikacie = {}
			if (get(data, 'address')) {
				set(notifikacie, 'adresyPosta', [get(data, 'address')])
			}

			if (get(data, 'email')) {
				set(notifikacie, 'adresyUri', [
					{
						typ: NOTIFICATION_TYPES.EMAIL,
						hodnota: get(data, 'email')
					}
				])
			}

			if (get(data, 'typ') === NOTIFICATION_TYPES.PRINTER) {
				set(notifikacie, 'adresyUri', [
					{
						typ: NOTIFICATION_TYPES.PRINTER,
						poradie: 0
					}
				])
			}

			notifikacie = isEmpty(notifikacie) ? undefined : notifikacie
			const body = {
				ukon: {
					interakciaId: interakcia.data.id,
					riesitel: auth.user.id,
					kanal: auth.businessChannel.actual,
					trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
					zacatyOd: dayjs(tracking.startTime).toISOString(),
					poznamka: get(data, 'poznamka.value'),
					vstup: {
						id: get(data, 'ukonVstup.value.value'),
						nazov: get(data, 'ukonVstup.value.label')
					}
				},
				ukonData: {
					opCislo: get(interakcia, 'data.opCislo'),
					fakturaCislo: get(selectedFaktura, 'cislo')
				}
			}
			const res = await postReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/ukon-duplikat-faktury/vytvorit`, null, body)
			const ukonId = get(res, 'response.obsah.ukon.id')
			const spustitBody = {
				...body.ukon,
				trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
				id: ukonId,
				notifikacie
			}
			await postReq(`/api/v0/ukony/${ukonId}/spustit`, null, spustitBody)
			// clear tracking timer
			trackingActions.clearTracking()
			// refetch new data for historia ukonov
			historiaUkonovActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)

			this.setState({
				result: t('containers:FakturaPage.Duplikát faktúry bol úspešne odoslaný'),
				success: true
			})
		} catch (e) {
			this.setState({
				result: t('containers:FakturaPage.Počas odosielania úkonu nastala chyba'),
				success: false
			})
		}
	}

	render = () => {
		const { interakcia, t, fakturyStore, obchodnyPartner } = this.props
		const { data, isLoading, isFailure } = fakturyStore
		const { showModalUkonUC03, result, success, selectedFaktura } = this.state

		if (isFailure) {
			return this.failureContentElement()
		}
		if (isLoading && isEmpty(data)) {
			return this.loadingContentElement()
		}
		if (isEmpty(data)) {
			return this.emptyContentElement()
		}

		let modal = null
		if (showModalUkonUC03) {
			modal = (
				<ModalSendItemDuplicate
					zuCislo={get(selectedFaktura, 'zuCisla')}
					ukonTyp={UKONY.DUPLIKAT_FAKTURY}
					dokumentId={get(selectedFaktura, 'dokumentId')}
					dokumentName={`FA-${get(selectedFaktura, 'cislo')}.pdf`}
					notificationTypes={[NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.PRINTER]}
					modalTitle={t('containers:FakturyPage.Zaslanie duplikátu faktúry')}
					onCloseButton={() => this.setState({ showModalUkonUC03: false })}
					onSubmit={this.onSubmitUkonUC03}
				/>
			)
		}
		if (result) {
			modal = (
				<DefaultModal
					modalTitle={success ? t('containers:Odoslané') : t('containers:Chyba')}
					modalContent={result}
					leftButton={{
						onClick: this.confirmModal,
						text: t('containers:Zavrieť'),
						color: success ? 'green' : 'red'
					}}
					visible
				/>
			)
		}
		const fakturyRows = map(data, (faktura) => (
			<FakturaRow
				t={t}
				key={`faktura-${faktura.cislo}`}
				interakcia={interakcia}
				faktura={faktura}
				onClickUkonUC03={() => this.setState({ showModalUkonUC03: true, selectedFaktura: faktura })}
				isMOO={obchodnyPartner?.typ === OBCHODNY_PARTNER_TYP.MOO}
			/>
		))
		const content = (
			<div className='box'>
				<div className='box-content'>
					{modal}
					<table id='invoices-table' className='content-table bordered hoverable' cellSpacing='0'>
						<thead>
							<tr>
								<th>{t('containers:FakturyPage.Číslo faktúry')}</th>
								<th>{t('containers:FakturyPage.Dátum vystavenia')}</th>
								<th>{t('containers:FakturyPage.Typ faktúry')}</th>
								<th>{t('containers:FakturyPage.Číslo ZÚ')}</th>
								<th>{t('containers:FakturyPage.Suma')}</th>
								<th>{t('containers:FakturyPage.Dátum splatnosti')}</th>
								<th>{t('containers:FakturyPage.Variabilný symbol')}</th>
								<th>{t('containers:FakturyPage.Zdroj dát')}</th>
								<th style={{ width: '180px' }} />
							</tr>
						</thead>
						<tbody>{fakturyRows}</tbody>
					</table>
				</div>
			</div>
		)

		return this.commonContentContainer(content)
	}

	datePickerRaw = (value, type) => {
		const pickedDate = dayjs(value)
		if (pickedDate.isValid()) {
			this.filterChanged(pickedDate, type)
		}
	}

	getFakturaTypFilter = () => {
		const { ciselniky, t, selectedFilters } = this.props

		const isActiveFakturaTypFilter = !isEmpty(selectedFilters[queryParams.fakturaTyp])

		let fakturaTypFilterPlaceholder = t('containers:SplnomocneniaPage.Všetky')
		if (isActiveFakturaTypFilter) {
			fakturaTypFilterPlaceholder = `${t('translation:Common.filter.Aktívne filtre')} (${selectedFilters[queryParams.fakturaTyp].split(',').length})`
		}

		let fakturaTypOptions = ciselniky?.fakturaTyp?.map((typOption) => {
			return {
				value: String(typOption.id),
				label: upperFirst(typOption.nazov)
			}
		})
		fakturaTypOptions = [emptySelectState, ...fakturaTypOptions].filter((item) => {
			// filter out selected items
			if (selectedFilters[queryParams.fakturaTyp] && selectedFilters[queryParams.fakturaTyp].split(',').includes(item?.value)) {
				return false
			}

			return true
		})

		return { fakturaTypOptions, fakturaTypFilterPlaceholder, isActiveFakturaTypFilter }
	}

	filterElement = () => {
		const { selectedFilters, ciselnikySelectBox, t, ciselniky, obchodnyPartner } = this.props
		const { fakturaCisloError } = this.state

		const isMOO = obchodnyPartner?.typ === OBCHODNY_PARTNER_TYP.MOO

		const datumVystaveniaOdFilter = selectedFilters.datumVystaveniaOd ? dayjs(selectedFilters.datumVystaveniaOd).toDate() : null
		const datumVystaveniaDoFilter = selectedFilters.datumVystaveniaDo ? dayjs(selectedFilters.datumVystaveniaDo).toDate() : null

		const zmluvneUctyOptions = map(ciselnikySelectBox.zmluvneUcty.data, (zmluvnyUcet) => ({
			value: zmluvnyUcet.cislo,
			label: zmluvnyUcet.cislo
		}))

		const typ3V1Options = [
			{ value: '3v1', label: '3v1' },
			{ value: 'bez3v1', label: 'bez 3v1' }
		]

		const { fakturaTypFilterPlaceholder, fakturaTypOptions, isActiveFakturaTypFilter } = this.getFakturaTypFilter()

		let typFakturaItems = []
		if (get(selectedFilters, queryParams.fakturaTyp)) {
			typFakturaItems = [...get(selectedFilters, queryParams.fakturaTyp).split(',')]

			// Map typFaktura id values in query params to its name
			typFakturaItems = typFakturaItems?.map((selectedTypFakturaId) => {
				return ciselniky?.fakturaTyp.find((fakturaTypOption) => fakturaTypOption.id === Number(selectedTypFakturaId))
			})
		}

		return (
			<div className='content-header'>
				<div className='row'>
					<div className='col-2'>
						<div className={cx('input-wrapper no-icon', { 'has-error': fakturaCisloError })}>
							<label htmlFor='invoices-filter-search'>{t('containers:FakturyPage.Číslo faktúry')}</label>
							<input
								type='text'
								className='input-field'
								data-type='search'
								value={this.state.fakturaCislo || ''}
								onChange={(e) => {
									if (e.target.value.length === 10 || e.target.value.length === 9 || e.target.value.length === 0) {
										this.onChangeSearchInput(e.target.value, queryParams.fakturaCislo)
										this.setState({ fakturaCislo: e.target.value, fakturaCisloError: null })
									} else {
										this.setState({
											fakturaCisloError: { msg: t('containers:FakturyPage.Dĺžka čísla faktúry musí byť 9 alebo 10 znakov') },
											fakturaCislo: e.target.value
										})
									}
								}}
								placeholder={t('containers:FakturyPage.Číslo faktúry')}
							/>
							{fakturaCisloError && (
								<label htmlFor='invoices-filter-search' className='text-danger'>
									{fakturaCisloError.msg}
								</label>
							)}
						</div>
					</div>
					<div className='col-2'>
						<div className='input-wrapper no-icon'>
							<label htmlFor='invoices-filter-search'>{t('containers:FakturyPage.Variabilný symbol')}</label>
							<input
								type='text'
								className='input-field'
								data-type='search'
								value={this.state.variabilnySymbol || ''}
								onChange={(e) => {
									this.setState({ variabilnySymbol: e.target.value })
									this.onChangeSearchInput(e.target.value, queryParams.variabilnySymbol)
								}}
								placeholder={t('containers:FakturyPage.Variabilný symbol')}
							/>
						</div>
					</div>
					<div className='col-2'>
						<div className='input-wrapper'>
							<label htmlFor='invoices-filter-date-of-issue'>{t('containers:FakturyPage.Dátum vystavenia od')}</label>
							<DatePicker
								locale='sk'
								dateFormat={'dd.MM.yyyy'}
								selected={datumVystaveniaOdFilter}
								onChange={(e) => this.filterChanged(e, queryParams.datumVystaveniaOd)}
								onChangeRaw={(e) => this.datePickerRaw(e.target.value, queryParams.datumVystaveniaOd)}
								tabIndex={50}
								showYearDropdown
								scrollableYearDropdown
								placeholderText='Zvoľte dátum'
								isClearable
								maxDate={datumVystaveniaDoFilter || null}
							/>
						</div>
					</div>
					<div className='col-2'>
						<div className='input-wrapper'>
							<label htmlFor='invoices-filter-date-of-issue'>{t('containers:FakturyPage.Dátum vystavenia do')}</label>
							<DatePicker
								locale='sk'
								dateFormat={'dd.MM.yyyy'}
								selected={datumVystaveniaDoFilter}
								onChange={(e) => this.filterChanged(e, queryParams.datumVystaveniaDo)}
								onChangeRaw={(e) => this.datePickerRaw(e.target.value, queryParams.datumVystaveniaDo)}
								tabIndex={50}
								showYearDropdown
								scrollableYearDropdown
								placeholderText='Zvoľte dátum'
								isClearable
								minDate={datumVystaveniaOdFilter || null}
							/>
						</div>
					</div>
					{isMOO ? (
						<div className='col-2'>
							<div className='select-wrapper'>
								<label htmlFor='invoices-filter-account'>{t('containers:FakturyPage.3v1')}</label>
								<Select
									onChange={(value) => this.filterChanged(value, queryParams.typ3V1)}
									options={[emptySelectState, ...typ3V1Options]}
									className={cx({
										'active-filters': selectedFilters[queryParams.typ3V1] !== null,
										green: selectedFilters[queryParams.typ3V1] !== null
									})}
									placeholder={selectedFilters[queryParams.typ3V1] || emptySelectState.label}
									isClearable
								/>
							</div>
						</div>
					) : (
						<div className='col-2'>
							<div className='select-wrapper'>
								<Select
									className={cx({ 'active-filters': isActiveFakturaTypFilter, green: isActiveFakturaTypFilter })}
									label={t('containers:FakturyPage.Typ faktúry')}
									options={fakturaTypOptions}
									onChange={(value) => this.multiFilterAdd(value, queryParams.fakturaTyp)}
									placeholder={fakturaTypFilterPlaceholder}
									isMulti
									closeMenuOnSelect={false}
								/>
							</div>
						</div>
					)}
					<div className='col-2'>
						<div className='select-wrapper'>
							<label htmlFor='invoices-filter-account'>{t('containers:FakturyPage.Zmluvný účet')}</label>
							<Select
								className={cx({
									'active-filters': selectedFilters[queryParams.zmluvnyUcetCislo] !== null,
									orange: selectedFilters[queryParams.zmluvnyUcetCislo] !== null
								})}
								onChange={(ucetFilter) => this.filterChanged(ucetFilter, queryParams.zmluvnyUcetCislo)}
								options={[emptySelectState, ...zmluvneUctyOptions]}
								isClearable
								isDisabled={ciselnikySelectBox.zmluvneUcty.data.length === 0}
								isLoading={ciselnikySelectBox.zmluvneUcty.isLoading}
								placeholder={selectedFilters[queryParams.zmluvnyUcetCislo] || emptySelectState.label}
							/>
						</div>
					</div>
					<div className='col-12'>
						<div className='filter-items clearfix' style={{ paddingRight: '150px' }}>
							{typFakturaItems.map((item, index) => (
								<div
									key={`${item.id}-${index}`}
									className='filter-item'
									data-color={'green'}
									onClick={() => this.removeFilterValue(queryParams.fakturaTyp, String(item.id))}
									style={{ cursor: 'pointer' }}
								>
									<span className='filter-text'>{item.nazov}</span>
									<span className='remove' />
								</div>
							))}
							{get(selectedFilters, queryParams.typ3V1) && (
								<div
									className='filter-item'
									data-color={'green'}
									onClick={() => this.removeFilterValue(queryParams.typ3V1, selectedFilters[queryParams.typ3V1])}
									style={{ cursor: 'pointer' }}
								>
									<span className='filter-text'>{selectedFilters[queryParams.typ3V1]}</span>
									<span className='remove' />
								</div>
							)}
							{get(selectedFilters, queryParams.fakturaCislo) && (
								<div
									className='filter-item'
									data-color={'purple'}
									onClick={() => this.removeFilterValue(queryParams.fakturaCislo, selectedFilters[queryParams.fakturaCislo])}
									style={{ cursor: 'pointer' }}
								>
									<span className='filter-text'>{selectedFilters[queryParams.fakturaCislo]}</span>
									<span className='remove' />
								</div>
							)}
							{get(selectedFilters, queryParams.variabilnySymbol) && (
								<div
									className='filter-item'
									data-color={'blue'}
									onClick={() => this.removeFilterValue(queryParams.variabilnySymbol, selectedFilters[queryParams.variabilnySymbol])}
									style={{ cursor: 'pointer' }}
								>
									<span className='filter-text'>{selectedFilters[queryParams.variabilnySymbol]}</span>
									<span className='remove' />
								</div>
							)}
							{get(selectedFilters, queryParams.zmluvnyUcetCislo) && (
								<div
									className='filter-item'
									data-color={'orange'}
									onClick={() => this.removeFilterValue(queryParams.zmluvnyUcetCislo, selectedFilters[queryParams.zmluvnyUcetCislo])}
									style={{ cursor: 'pointer' }}
								>
									<span className='filter-text'>{selectedFilters[queryParams.zmluvnyUcetCislo]}</span>
									<span className='remove' />
								</div>
							)}
							{(!isEmpty(typFakturaItems) ||
								get(selectedFilters, queryParams.fakturaCislo) ||
								get(selectedFilters, queryParams.variabilnySymbol) ||
								get(selectedFilters, queryParams.typ3V1) ||
								get(selectedFilters, queryParams.zmluvnyUcetCislo)) && (
								<div
									className='filter-item'
									style={{ margin: 0, position: 'absolute', top: '5px', right: '15px', cursor: 'pointer' }}
									data-color='red'
									onClick={() => this.removeFilters()}
								>
									<span className='filter-text'>{t('translation:Common.filter.Zrušiť filtre')}</span>
									<span className='remove' />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}

	commonContentContainer = (content) => {
		const { t, fakturyStore } = this.props
		const { isLoading, hasNextPage } = fakturyStore
		return (
			<>
				{this.filterElement()}
				<div className='content-wrapper'>
					{content}

					{!isLoading && hasNextPage && (
						<div className='text-center'>
							<button className='button' data-color='blue' data-type='outline' style={{ margin: '20px 0' }} onClick={this.loadMore}>
								{t('containers:FakturyPage.Načítať ďalšie faktúry')}
							</button>
						</div>
					)}
				</div>
			</>
		)
	}

	loadingContentElement = () => {
		return this.commonContentContainer(<ElementLoading />)
	}

	failureContentElement = () => {
		const { t } = this.props
		return this.commonContentContainer(<ElementFailure text={t('containers:FakturyPage.Nepodarilo sa načítať zoznam faktúr')} />)
	}

	emptyContentElement = () => {
		const { t } = this.props
		return this.commonContentContainer(
			<ElementEmptyContent
				text={t('containers:FakturyPage.Pre obchodného partnera neevidujeme žiadne faktúry, alebo zadanému filtru nekorešponduje žiadna faktúra')}
			/>
		)
	}

	loadMore = () => {
		const { fakturyActions, fakturyStore, selectedFilters } = this.props
		return fakturyActions.loadFaktury(fakturyStore.page + 1, selectedFilters)
	}
}

const mapStateToProps = (state) => ({
	fakturyStore: state.faktury,
	interakcia: state.interakcie.detail,
	selectedFilters: get(state.selectedFilters, SELECTED_FILTERS_SELECTOR, {}),
	ciselniky: get(state, 'ciselniky.data'),
	ciselnikySelectBox: state.ciselnikySelectBox,
	tracking: state.tracking,
	auth: state.auth,
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data'),
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	fakturyActions: bindActionCreators(FakturyActions, dispatch),
	zmluvneUctyActions: bindActionCreators(ZmluvneUctyActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch),
	ciselnikySelectBoxActions: bindActionCreators(CiselnikySelectBoxActions, dispatch),
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	historiaUkonovActions: bindActionCreators(HistoriaUkonovActions, dispatch)
})

export default compose(withTranslation('containers'), connect(mapStateToProps, mapDispatchToProps), withPermissions([PERMISSIONS.VIEW_FAKTURY]))(FakturyPage)
