import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { get, map } from 'lodash'
import { SelectField, TextInputField } from '../../atoms'
import validate from './validateForm'

// utils
import { FORMS } from '../../utils/enums'
import { filterAvailableBusinessChannels } from '../../utils/auth'

class Nastavenia extends Component {
	static propTypes = {
		shown: PropTypes.bool.isRequired,
		toggleNastaveniaModal: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		ciselniky: PropTypes.shape().isRequired,
		handleSubmit: PropTypes.func.isRequired
	}

	toggleNastaveniaModal = (state) => {
		this.props.toggleNastaveniaModal(state)

		this.setState({
			resetStatus: null
		})
	}

	render() {
		const { shown, ciselniky, handleSubmit, t } = this.props

		// remove D2D kanal
		const filtered = filterAvailableBusinessChannels(get(ciselniky, 'kanal', []))

		// build select options from ciselniky data
		const biznisKanalyOptions = map(filtered, (kanal) => ({
			label: get(kanal, 'popis'),
			value: get(kanal, 'id')
		}))

		return (
			<div className={cx('modal', 'dismissible', { shown })} data-size='m'>
				<div className='modal-wrapper'>
					<div className='modal-header clearfix' style={{ paddingTop: '10px' }}>
						<button className='button pull-left' data-type='back-button' data-color='blue' onClick={() => this.toggleNastaveniaModal(false)}>
							{t('translation:Common.Späť')}
						</button>
						<h3 className='pull-left' style={{ marginTop: '18px' }}>
							{t('translation:Common.Nastavenia')}
						</h3>
					</div>
					<div className='modal-content'>
						<form onSubmit={handleSubmit}>
							<div className='content-wrapper'>
								<div className='box-content'>
									<table className='content-table padded bordered' cellSpacing='0'>
										<tbody>
											<tr>
												<td>
													<strong>{t('translation:Common.Predvolený biznis kanál')}</strong>
												</td>
												<td>
													<Field
														name='biznisKanal'
														component={SelectField}
														options={biznisKanalyOptions}
														isSearchable={false}
														classNamePrefix='react-select'
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Miesto podpisu')}</strong>
												</td>
												<td>
													<Field name='podpisMiesto' component={TextInputField} />
												</td>
											</tr>
										</tbody>
									</table>
									<button style={{ marginBottom: '20px' }} className='button pull-right' data-color='blue' type='submit'>
										{t('translation:Common.Uložiť')}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

const form = reduxForm({
	form: FORMS.NASTAVENIA,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(Nastavenia)

const mapStateToProps = (state) => ({
	ciselniky: get(state, 'ciselniky.data')
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
