import { findIndex } from 'lodash'
import { RESET_STORAGE } from '../types/search'
import { FORM_ADDRESSES_PUSH, FORM_ADDRESSES_UPDATE, FORM_ADDRESSES_INIT, FORM_ADDRESSES_CLEAN } from '../types/formAddresses'

export const initState = {
	data: []
}

export default (state = initState, action) => {
	switch (action.type) {
		case FORM_ADDRESSES_INIT:
			return {
				...initState,
				data: action.payload.addresses
			}
		case FORM_ADDRESSES_UPDATE: {
			const addresses = [...state.data]
			const index = findIndex(addresses, (address) => address.id == action.payload.address.id)

			if (index != -1) {
				addresses[index] = action.payload.address
			}

			return {
				...state,
				data: addresses
			}
		}

		case FORM_ADDRESSES_PUSH: {
			let addresses = [...state.data]

			const index = findIndex(addresses, (address) => address.id == action.payload.address.id)
			if (index == -1) {
				addresses = [...addresses, action.payload.address]
			} else {
				addresses[index] = action.payload.address
			}

			return {
				...state,
				data: addresses
			}
		}
		case FORM_ADDRESSES_CLEAN: {
			return {
				...initState
			}
		}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
