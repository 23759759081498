import React from 'react'
import { Link } from 'react-router-dom'
import { get, find, join, isEmpty, min, filter } from 'lodash'
import { NumericFormat } from 'react-number-format'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'

import { formatDate } from '../../utils/date'
import { setRouteParams, UPOMIENKY_DETAIL, POHLADAVKY_DETAIL } from '../../utils/routes'
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'

function UpomienkaRow({ upomienka, interakcia, upominanieStupenNazvy, onClickUkonUC05b, t }) {
	const listStupen = min(
		filter(
			[
				get(
					find(upominanieStupenNazvy, (upominanieStupen) => get(upominanieStupen, 'nazov') == 'Tlač doporučenej upomienky'),
					'id.id'
				),
				get(
					find(upominanieStupenNazvy, (upominanieStupen) => get(upominanieStupen, 'nazov') == '2. List'),
					'id.id'
				)
			],
			(stupen) => stupen > 0
		)
	)
	const upomienkaStupen = get(
		find(upominanieStupenNazvy, (upomienkaStupen) => get(upomienkaStupen, 'nazov') == get(upomienka, 'upominanieStupen.nazov')),
		'id.id'
	)
	const color = listStupen > 0 && upomienkaStupen >= listStupen ? 'red' : 'orange'

	const backUrl = btoa(`${window.location.pathname}${window.location.search}`)

	return (
		<tr>
			<td>{formatDate(get(upomienka, 'vystavenieDatum'), '-', 'DD.MM.YYYY')}</td>
			<td>{get(upomienka, 'zmluvnyUcetCislo', '-')}</td>
			<td>
				<NumericFormat
					thousandSeparator={' '}
					decimalSeparator={','}
					decimalScale={2}
					fixedDecimalScale={true}
					displayType='text'
					suffix=' €'
					value={get(upomienka, 'upominanaSuma')}
				/>
			</td>
			<td>
				<NumericFormat
					thousandSeparator={' '}
					decimalSeparator={','}
					decimalScale={2}
					fixedDecimalScale={true}
					displayType='text'
					suffix=' €'
					value={get(upomienka, 'poplatokSuma')}
				/>
			</td>
			<td>{get(upomienka, 'upominanieMetoda.nazov', '-')}</td>
			<td style={{ position: 'relative' }}>
				<div style={{ height: '100%', width: '100%', position: 'relative', display: 'flex', alignItems: 'center', paddingRight: '30px' }}>
					<span style={{ marginTop: '-2px' }}>{get(upomienka, 'upominanieStupen.nazov', '-')}</span>
					<div
						className='label circle large'
						data-color={color}
						style={{ position: 'absolute', top: '50%', right: '0', transform: 'translateY(-50%)' }}
					>
						<span>{upomienkaStupen}</span>
					</div>
				</div>
			</td>
			<td>{get(upomienka, 'zasielkaCislo', '-')}</td>
			<td>
				{get(upomienka, 'dokumentId') && (
					<Tooltip
						html={t('containers:UpomienkaDetailPage.Detail upomienky')}
						style={{ marginRight: '10px' }}
						position='bottom'
						trigger='mouseenter'
						theme='light'
					>
						<Link
							to={setRouteParams(UPOMIENKY_DETAIL, get(interakcia, 'data.opCislo'), get(upomienka, 'id'))}
							className='button'
							data-type='icon-button'
							data-icon='view'
						/>
					</Tooltip>
				)}
				{/* <a className="button" data-type="icon-button" data-icon="print"></a> */}
				{!isEmpty(get(upomienka, 'upominaneDoklady', [])) && (
					<Tooltip
						html={t('containers:UpomienkaDetailPage.Preklik na záložku Stav účtu')}
						style={{ marginRight: '10px' }}
						position='bottom'
						trigger='mouseenter'
						theme='light'
					>
						<Link
							to={`${setRouteParams(
								POHLADAVKY_DETAIL,
								get(interakcia, 'data.opCislo'),
								join(get(upomienka, 'upominaneDoklady', []), ',')
							)}?search=true&backUrl=${backUrl}`}
							className='button'
							data-type='icon-button'
							data-icon='pig'
						/>
					</Tooltip>
				)}
				{get(upomienka, 'dokumentId') && (
					<Permissions
						allowed={[PERMISSIONS.UKON_DUPLIKAT_UPOMIENKY]}
						render={(hasPerm, actions) => {
							return (
								<Tooltip
									html={t('containers:UpomienkaDetailPage.Zaslanie duplikátu upomienky')}
									position='bottom'
									trigger='mouseenter'
									theme='light'
								>
									<a
										className='button'
										data-type='icon-button'
										data-icon='send'
										onClick={(e) => {
											if (!hasPerm) {
												e.preventDefault()
												actions.openForbiddenModal()
											} else {
												onClickUkonUC05b()
											}
										}}
									/>
								</Tooltip>
							)
						}}
					/>
				)}
				{/* <a className="button" data-type="icon-button" data-icon="send"></a> */}
			</td>
		</tr>
	)
}

UpomienkaRow.propTypes = {
	upomienka: PropTypes.shape(),
	interakcia: PropTypes.shape(),
	onClickUkonUC05b: PropTypes.func.isRequired,
	upominanieStupenNazvy: PropTypes.array,
	t: PropTypes.func.isRequired
}

export default UpomienkaRow
