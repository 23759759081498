import { get } from 'lodash'
import {
	UKON_EDIT_OP_LOAD_START,
	UKON_EDIT_OP_LOAD_DONE,
	UKON_EDIT_OP_LOAD_FAIL,
	UKON_OP_KONTAKTY_NESUHLASY_LOAD_START,
	UKON_OP_KONTAKTY_NESUHLASY_LOAD_DONE,
	UKON_OP_KONTAKTY_NESUHLASY_LOAD_FAIL
} from '../types/ukonEditOpTypes'

import { getReq } from '../utils/request'

export const loadDataForUkonEditOp = (opCislo) => {
	return async (dispatch) => {
		dispatch({
			type: UKON_EDIT_OP_LOAD_START
		})

		try {
			const response = await getReq(`/api/v2/op/${opCislo}/ukon-zmena-udajov-op`)
			const content = get(response, 'response.content', null)

			dispatch({
				type: UKON_EDIT_OP_LOAD_DONE,
				payload: {
					content
				}
			})

			return content
		} catch (error) {
			dispatch({
				type: UKON_EDIT_OP_LOAD_FAIL
			})
		}
	}
}

export const loadNesuhlasForContact = (opCislo) => {
	return async (dispatch) => {
		dispatch({
			type: UKON_OP_KONTAKTY_NESUHLASY_LOAD_START
		})

		try {
			const response = await getReq(`/api/ces/nesuhlas/${opCislo}/digitalizacia/kontakty`)
			const content = get(response, 'response', null)

			dispatch({
				type: UKON_OP_KONTAKTY_NESUHLASY_LOAD_DONE,
				payload: {
					content
				}
			})

			return content
		} catch (error) {
			dispatch({
				type: UKON_OP_KONTAKTY_NESUHLASY_LOAD_FAIL
			})
		}
	}
}
