import React from 'react'
import PropTypes from 'prop-types'

// atoms
import BasicSelect from '../BasicSelect'

// utils
import { SUHLAS_HODNOTA } from '../../utils/enums'
import { createSuhlasDTO } from '../../utils/suhlas'

const CONFIRMED_OPTIONS = [{ label: 'Zrušiť', value: 'Zrušiť' }]
const REQUIRED_OPTIONS = [{ label: 'Overené - bez zmeny', value: 'Overené - bez zmeny' }]

const FONT_COLOR = '#191919'

const DEFAULT_STYLES = {
	placeholder: (provided) => ({
		...provided,
		color: FONT_COLOR
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		backgroundColor: FONT_COLOR
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		color: FONT_COLOR
	})
}

class ConfirmPreferredValue extends React.Component {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		required: PropTypes.bool,
		confirmed: PropTypes.bool
	}

	constructor(props) {
		super(props)
	}

	handleChange = () => {
		const { onChange, confirmed } = this.props

		if (confirmed) {
			onChange(null)
		} else {
			onChange(createSuhlasDTO(SUHLAS_HODNOTA.SUHLAS))
		}
	}

	render() {
		const { required, confirmed } = this.props

		let backgroundColor

		if (confirmed) {
			backgroundColor = '#BAE2B8 !important'
		}

		if (required) {
			backgroundColor = '#FFB6B6 !important'
		}

		const styles = {
			...DEFAULT_STYLES,
			control: (provided) => ({
				...provided,
				backgroundColor
			})
		}

		const selectProps = {
			placeholder: confirmed ? 'Overené' : 'Potvrdiť',
			options: confirmed ? CONFIRMED_OPTIONS : REQUIRED_OPTIONS,
			styles
		}

		return (
			<div className='select-wrapper'>
				<BasicSelect
					options={selectProps.options}
					placeholder={selectProps.placeholder}
					onChange={this.handleChange}
					isSearchable={false}
					customStyles={selectProps.styles}
				/>
			</div>
		)
	}
}

export default ConfirmPreferredValue
