import { keyBy, get, some, groupBy, map, sumBy, sortBy } from 'lodash'
import dayjs from 'dayjs'

import ProductGas from '../resources/img/icons/product-gas.svg'
import ProductEE from '../resources/img/icons/product-ee.svg'
import { ROZPIS_ZALOH_POLOZKA_STATUS, ROZPIS_ZALOH_VYROVNANIE_STATUS, ROZPIS_ZALOH_CYKLUS, KOMODITA_TYP } from './enums'

/**
 * @param {Object} rozpisZalohDTO
 * @param {Object} OdberneMiestoZoznamRozsirenyDTO
 * @return {Object} {zmluvnyUcetDTO, zmluvnyVztahDTO, odberneMiestoDTO, miestoSpotrebyDTO}
 */
export const getContexForRozpisyZaloh = (rozpisZalohDTO, OdberneMiestoZoznamRozsirenyDTO) => {
	const { zmluvnyVztahCislo, zmluvnyUcetCislo } = rozpisZalohDTO
	const zmluvneUctyKeyBy = keyBy(OdberneMiestoZoznamRozsirenyDTO.zmluvneUcty, (zmluvnyUcet) => zmluvnyUcet.cislo)
	const odberneMiestaKeyBy = keyBy(OdberneMiestoZoznamRozsirenyDTO.odberneMiesta, (odberneMiesto) => odberneMiesto.cislo)
	const zmluvnyVztahKeyBy = keyBy(OdberneMiestoZoznamRozsirenyDTO.zmluvneVztahy, (zmluvnyVztah) => zmluvnyVztah.cislo)
	const miestaSpotrebyKeyBy = keyBy(OdberneMiestoZoznamRozsirenyDTO.miestaSpotreby, (miestoSpotreby) => miestoSpotreby.cislo)

	const zmluvnyUcet = get(zmluvneUctyKeyBy, zmluvnyUcetCislo)
	const zmluvnyVztah = get(zmluvnyVztahKeyBy, zmluvnyVztahCislo)
	const odberneMiesto = get(odberneMiestaKeyBy, get(zmluvnyVztah, 'odberneMiestoCislo'))
	const miestoSpotreby = get(miestaSpotrebyKeyBy, get(odberneMiesto, 'miestoSpotrebyCislo'))

	return {
		zmluvnyUcet,
		zmluvnyVztah,
		odberneMiesto,
		miestoSpotreby
	}
}

export const getRozpisZalohEditableStatus = (rozpisZaloh, procesnyKonfigurator) => {
	const polozky = get(rozpisZaloh, 'data.polozky') || get(rozpisZaloh, 'polozky') || []
	const referencnaVyskaZaloh = getReferencnaVyskaZaloh(rozpisZaloh)
	const polozkyRozpisuZaloh = groupRozpisZalohItems(polozky, get(procesnyKonfigurator, 'data.ukony.zmenaPreddavkovychPlatieb'))
	const isStavRozpisuZalohOtvoreny =
		(get(rozpisZaloh, 'data.vyrovnanieStatus.id') || get(rozpisZaloh, 'vyrovnanieStatus.id')) == ROZPIS_ZALOH_POLOZKA_STATUS.OTVORENY
	const isStavRozpisuZalohOtvorenyPreZmeny = some(polozkyRozpisuZaloh, (polozka) => polozka.state == ROZPIS_ZALOH_VYROVNANIE_STATUS.NESPLATNY)
	const diffDays = dayjs().diff(dayjs(get(rozpisZaloh, 'data.obdobieDo')), 'day')
	const isOutDated = diffDays > 0
	return {
		referencnaVyskaZaloh,
		polozkyRozpisuZaloh,
		isStavRozpisuZalohOtvoreny,
		isStavRozpisuZalohOtvorenyPreZmeny,
		isOutDated
	}
}

export const getIconByKomoditaTyp = (komoditaTypCiselnik) => {
	let productIcon = ''
	if (get(komoditaTypCiselnik, 'id') === KOMODITA_TYP.ELEKTRINA) {
		productIcon = ProductEE
	} else if (get(komoditaTypCiselnik, 'id') === KOMODITA_TYP.PLYN) {
		productIcon = ProductGas
	}
	return productIcon
}

export const getItemState = (polozky, splatnostDatum, pocetKalDniPredSplatnostouPoloziek, pocetKalDniPredSplatnostouPoloziekOffsetSpracovania) => {
	if (!some(polozky, (polozka) => get(polozka, 'vyrovnanieStatus.id') != ROZPIS_ZALOH_POLOZKA_STATUS.VYROVNANY)) {
		return ROZPIS_ZALOH_VYROVNANIE_STATUS.VYROVNANY
	}
	const splatnostDatumOd = dayjs(splatnostDatum)
		.subtract(pocetKalDniPredSplatnostouPoloziek, 'day')
		.subtract(pocetKalDniPredSplatnostouPoloziekOffsetSpracovania, 'day')
		.startOf('day')
	if (
		!some(polozky, (polozka) => get(polozka, 'vyrovnanieStatus.id') == ROZPIS_ZALOH_POLOZKA_STATUS.OTVORENY && splatnostDatumOd <= dayjs().startOf('day'))
	) {
		return ROZPIS_ZALOH_VYROVNANIE_STATUS.NESPLATNY
	}
	const splatnostDatumDo = dayjs(splatnostDatum).startOf('day')
	if (splatnostDatumDo < dayjs().startOf('day')) {
		return ROZPIS_ZALOH_VYROVNANIE_STATUS.SPLATNY_PO
	}
	return ROZPIS_ZALOH_VYROVNANIE_STATUS.SPLATNY_PRED
}

export const getItemColorByState = (state) => {
	switch (state) {
		case ROZPIS_ZALOH_VYROVNANIE_STATUS.VYROVNANY:
			return 'green'
		case ROZPIS_ZALOH_VYROVNANIE_STATUS.SPLATNY_PRED:
			return 'blue'
		case ROZPIS_ZALOH_VYROVNANIE_STATUS.SPLATNY_PO:
			return 'red'
	}
	return 'yellow'
}

export const groupRozpisZalohItems = (polozky, procesnyKonfigurator) => {
	const polozkyGroupByDate = groupBy(polozky, 'splatnostDatum')
	const pocetKalDniPredSplatnostouPoloziek = get(procesnyKonfigurator, 'standardneAtributy.pocetKalDniPredSplatnostouPoloziek', 20)
	const pocetKalDniPredSplatnostouPoloziekOffsetSpracovania = get(
		procesnyKonfigurator,
		'standardneAtributy.pocetKalDniPredSplatnostouPoloziekOffsetSpracovania',
		3
	)
	const items = map(polozkyGroupByDate, (group, key) => ({
		datum: dayjs(key),
		state: getItemState(group, key, pocetKalDniPredSplatnostouPoloziek, pocetKalDniPredSplatnostouPoloziekOffsetSpracovania),
		suma: sumBy(group, 'suma')
	}))
	return sortBy(items, ['date'])
}

const pocetZalohPodlaCyklu = [1, 0, 11, 4, 2, 1]
export const getAktualnaVyskaZaloh = (rozpisZaloh) => {
	const zalohySuma = get(rozpisZaloh, 'data.zalohySuma')
	const zalohyCyklus = get(rozpisZaloh, 'data.zalohyCyklus.id')
	return getVyskaZalohSpolu(zalohySuma, zalohyCyklus)
}
export const getReferencnaVyskaZaloh = (rozpisZaloh) => {
	const zalohySuma = get(rozpisZaloh, 'data.zalohySumaReferencna')
	const zalohyCyklus = get(rozpisZaloh, 'data.zalohyCyklusReferencny.id')
	return getVyskaZalohSpolu(zalohySuma, zalohyCyklus)
}
const getVyskaZalohSpolu = (zalohySuma, zalohyCyklus) => {
	return zalohySuma * pocetZalohPodlaCyklu[zalohyCyklus - 1]
}
export const getPrepocitanaZaloha = (vyskaZaloh, zalohyCyklus) => {
	return vyskaZaloh / pocetZalohPodlaCyklu[zalohyCyklus - 1]
}

export const validateZalohyCyklus = (values, props) => {
	const pocetOtvorenychPlanov = sumBy(get(props, 'originalValues.preddavkovePlatby', []), (plan) => plan.state == ROZPIS_ZALOH_VYROVNANIE_STATUS.NESPLATNY)
	const aktualnyZalohyCyklus = get(props, 'originalValues.zalohyCyklus.value')
	switch (aktualnyZalohyCyklus) {
		case ROZPIS_ZALOH_CYKLUS.ROCNY: {
			switch (values.zalohyCyklus) {
				case ROZPIS_ZALOH_CYKLUS.POLROCNY:
				case ROZPIS_ZALOH_CYKLUS.KVARTALNY:
				case ROZPIS_ZALOH_CYKLUS.MESACNY:
					return pocetOtvorenychPlanov == 1
			}
			break
		}
		case ROZPIS_ZALOH_CYKLUS.POLROCNY: {
			switch (values.zalohyCyklus) {
				case ROZPIS_ZALOH_CYKLUS.ROCNY:
					return pocetOtvorenychPlanov == 2
				case ROZPIS_ZALOH_CYKLUS.KVARTALNY:
				case ROZPIS_ZALOH_CYKLUS.MESACNY:
					return pocetOtvorenychPlanov >= 1
			}
			break
		}
		case ROZPIS_ZALOH_CYKLUS.KVARTALNY: {
			switch (values.zalohyCyklus) {
				case ROZPIS_ZALOH_CYKLUS.ROCNY:
					return pocetOtvorenychPlanov == 4
				case ROZPIS_ZALOH_CYKLUS.POLROCNY:
					return pocetOtvorenychPlanov >= 2
				case ROZPIS_ZALOH_CYKLUS.MESACNY:
					return pocetOtvorenychPlanov >= 1
			}
			break
		}
		case ROZPIS_ZALOH_CYKLUS.MESACNY: {
			switch (values.zalohyCyklus) {
				case ROZPIS_ZALOH_CYKLUS.ROCNY:
					return pocetOtvorenychPlanov == 11
				case ROZPIS_ZALOH_CYKLUS.POLROCNY:
					return pocetOtvorenychPlanov >= 6
				case ROZPIS_ZALOH_CYKLUS.KVARTALNY:
					return pocetOtvorenychPlanov >= 3
			}
			break
		}
	}
	return true
}
