import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'
import { get } from 'lodash'

// atoms
import CheckBoxField from '../../atoms/CheckboxField'

// utils
import { formatDate } from '../../utils/date'
import { formatAddress } from '../../utils/address'
import { OBCHODNY_PARTNER_TYP } from '../../utils/enums'
import { getSegmentFromSkupinaOpravneni } from '../../utils/obchodnyPartner'

function SearchResultTableRow(props) {
	let checkbox = (
		<CheckBoxField
			disabled={props.disabledCheckbox}
			input={{
				value: get(props, 'cislo') == get(props, 'selectedOP'),
				onChange: props.onClickCheckbox,
				label: ''
			}}
		/>
	)
	if (props.disabledCheckbox && props.disabledCheckboxText) {
		checkbox = (
			<Tooltip html={<span>{props.disabledCheckboxText}</span>} position='left' trigger='mouseenter' theme='light'>
				{checkbox}
			</Tooltip>
		)
	}
	return (
		<tr key={props.cislo} onClick={props.onClick}>
			<td className='partner-number'>{get(props, 'cislo', '-')}</td>
			{props.typ === OBCHODNY_PARTNER_TYP.MOP && <td>{props.obchodneMeno || '-'}</td>}
			{props.typ === OBCHODNY_PARTNER_TYP.MOO && <td>{[props.meno || '', props.priezvisko || ''].join(' ').trim() || '-'}</td>}
			<td>{formatAddress(props.adresaZakaznika) || '-'}</td>
			<td className='date-of-birth'>{formatDate(props.narodenieDatum, '-', 'DD.MM.YYYY')}</td>
			{get(props, 'showMOP') && <td>{get(props, 'ICO', '-')}</td>}
			<td className='phone-column'>{get(props, 'kontaktnyTelefon', '-')}</td>
			<td>{get(props, 'kontaktnyEmail', '-')}</td>
			{get(props, 'showMOP') && <td>{getSegmentFromSkupinaOpravneni(get(props, 'skupinaOpravneni', null))}</td>}
			{get(props, 'checkRows') && <td>{checkbox}</td>}
		</tr>
	)
}

SearchResultTableRow.propTypes = {
	cislo: PropTypes.string.isRequired,
	meno: PropTypes.string,
	priezvisko: PropTypes.string,
	adresaZakaznika: PropTypes.shape({
		stat: PropTypes.string,
		obec: PropTypes.string,
		ulica: PropTypes.string,
		orientacneCislo: PropTypes.string,
		supisneCislo: PropTypes.string,
		psc: PropTypes.string
	}),
	narodenieDatum: PropTypes.string,
	kontaktnyEmail: PropTypes.string,
	kontaktnyTelefon: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	onClickCheckbox: PropTypes.func,
	disabledCheckbox: PropTypes.bool,
	disabledCheckboxText: PropTypes.string
}

export default SearchResultTableRow
