import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import failureStateIcon from '../resources/img/icons/warning.svg'

function ElementFailure(props) {
	const size = props.size ? props.size : ''
	return (
		<div className={cx('failure-content-container', size)} style={{ ...props.style }}>
			<img src={failureStateIcon} />
			<p>{props.text || ''}</p>
		</div>
	)
}

ElementFailure.propTypes = {
	text: PropTypes.string,
	size: PropTypes.string,
	style: PropTypes.shape()
}

export default ElementFailure
