import React from 'react'
import { map, get, split, forEach, filter, orderBy } from 'lodash'
import PropTypes from 'prop-types'

// utils
import { DIRECTION } from '../utils/enums'

class DataTable extends React.Component {
	// TODO: refactor and remove local state
	state = {
		columns: []
	}

	componentDidMount() {
		this.normalizeColumns(this.props.triedenie)
	}

	componentDidUpdate(prevProps) {
		const { triedenie } = this.props
		if (prevProps.triedenie !== triedenie) {
			this.normalizeColumns(triedenie)
		}
	}

	normalizeColumns = (triedenie) => {
		const { columns } = this.props
		const triedenieCols = split(triedenie, ',')
		const normalizeCols = map(columns, (col) => {
			let column = col
			forEach(triedenieCols, (triedenieCol, index) => {
				const directionName = split(triedenieCol, ':')
				if (get(directionName, '[0]') === col.name) {
					column = {
						...col,
						order: index + 1,
						direction: get(directionName, '[1]')
					}
				}
			})
			return column
		})
		this.setState({ columns: normalizeCols })
	}

	toogleDirection = (direction) => {
		switch (direction) {
			case DIRECTION.DESC: {
				return DIRECTION.ASC
			}
			case DIRECTION.ASC: {
				return undefined
			}
			default:
				return DIRECTION.DESC
		}
	}

	onChangeDirection = (changedColumn) => {
		const { sortingColumn } = this.props
		const { columns } = this.state

		const newDirection = this.toogleDirection(changedColumn.direction)
		const correction = newDirection ? 1 : -1
		const newColumns = map(columns, (column) => {
			if (column.name == changedColumn.name) {
				return {
					...column,
					order: newDirection ? 1 : undefined,
					direction: newDirection
				}
			}

			return {
				...column,
				order: column.order ? column.order + correction : undefined
			}
		})
		this.setState(
			{
				columns: newColumns
			},
			() => {
				if (!sortingColumn) {
					return
				}
				const stringFormat = map(
					orderBy(
						filter(this.state.columns, (column) => {
							return column.direction && column.name && column.order
						}),
						['order'],
						['asc']
					),
					(column) => `${column.name}:${column.direction}`
				).join(',')
				const normalized = sortingColumn(stringFormat)
				if (normalized != stringFormat) {
					this.normalizeColumns(normalized)
				}
			}
		)
	}

	render() {
		const { columns } = this.state

		return (
			<table className='content-table bordered hoverable' cellSpacing='0' style={{ ...this.props.style }}>
				<thead>
					<tr>
						{columns.map((column, index) => {
							return (
								<th
									className={`${column.direction ? column.direction : ''} ${column.sortable ? 'sortable' : ''}`}
									style={{ ...column.style }}
									key={index}
									onClick={column.sortable ? () => this.onChangeDirection(column) : undefined}
								>
									<span className='title'>{column.title}</span>
									<span className='sort-icon' />
								</th>
							)
						})}
					</tr>
				</thead>
				<tbody>{this.props.children}</tbody>
			</table>
		)
	}
}

DataTable.propTypes = {
	sortingColumn: PropTypes.func,
	columns: PropTypes.shape({
		title: PropTypes.string.isRequired,
		name: PropTypes.string,
		sortable: PropTypes.bool,
		style: PropTypes.shape()
	}).isRequired,
	triedenie: PropTypes.string,
	style: PropTypes.shape()
}

export default DataTable
