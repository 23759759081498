import React from 'react'
import { withTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { compose } from 'redux'
import { FieldArray, getFormValues, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// components
import GenericFieldArray from './GenericFieldArray'

// utils
import { FORMS, PROTECTION_INTERVAL_FOR_SUBMITTING } from '../../../utils/enums'
import validate from './validateForm'

export const GENERIC_FIELDS_KEY = 'ukony'

class GenericUkonForm extends React.Component {
	static propTypes = {
		ukony: PropTypes.shape(),
		obchodnyPartner: PropTypes.shape({
			data: PropTypes.shape()
		}),
		interakciaStartTime: PropTypes.any,
		currentTime: PropTypes.any,
		ukonOptions: PropTypes.array,
		isMOP: PropTypes.bool.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {}
	}

	render() {
		const { t, interakciaStartTime, currentTime, ukonOptions, isMOP, formValues } = this.props

		return (
			<>
				<table className='content-table bordered padded top-aligned'>
					<thead>
						<tr>
							<th style={{ width: '280px' }}>{t('containers:UkoncenieInterakcie.Čas úkonu')}</th>
							<th>{t('containers:UkoncenieInterakcie.Typ úkonu')}</th>
							<th style={{ width: '40px' }} />
						</tr>
					</thead>
					<tbody>
						<FieldArray
							name={GENERIC_FIELDS_KEY}
							props={{ interakciaStartTime, currentTime, ukonOptions, isMOP, formValues }}
							component={GenericFieldArray}
						/>
					</tbody>
				</table>
			</>
		)
	}
}

const form = reduxForm({
	form: FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, PROTECTION_INTERVAL_FOR_SUBMITTING),
	validate
})(GenericUkonForm)

const mapStateToProps = (state) => ({
	obchodnyPartner: state.obchodnyPartner.detail,
	formValues: getFormValues(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU)(state)
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(form)
