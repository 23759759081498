import { get } from 'lodash'

import { getReq } from '../../utils/request'
import { minutesBetween } from '../../utils/date'
import {
	OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_FAIL,
	OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_DONE,
	OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_START,
	OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_DONE,
	OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_FAIL,
	OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_START,
	OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_PART_DONE,
	OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_START,
	OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_DONE,
	OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_FAIL
} from '../../types/obchodnyPartner'

export const loadBankoveUcty = (page, pageSize = 5, queryParams) => {
	return async (dispatch, getStore) => {
		dispatch({
			type: OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_START,
			payload: { page }
		})
		try {
			const query = {
				...queryParams,
				velkostStranky: pageSize,
				stranka: page
			}
			const store = getStore()
			const interakcia = store.interakcie.detail
			const bankoveUcty = get(store, 'obchodnyPartner.bankoveUcty.data', [])

			const res = await getReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/bankove-spojenia`, query)

			const { obsah, strankovanie } = res.response
			const { velkostStranky, zaznamov, aktualnaStranka } = strankovanie
			const isLastPage = !(velkostStranky * aktualnaStranka < zaznamov)

			dispatch({
				type: OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_DONE,
				payload: { bankoveUcty: { ...bankoveUcty, ...obsah }, isLastPage }
			})
		} catch (e) {
			dispatch({
				type: OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_FAIL
			})
		}
	}
}

const loadBankoveUctyByParts = async (cisloOP, dispatch, pageSize) => {
	const loadPage = async (page) => {
		const query = {
			velkostStranky: pageSize,
			stranka: page
		}
		try {
			const res = await getReq(`/api/v0/obchodni-partneri/${cisloOP}/bankove-spojenia`, query)
			const { obsah, strankovanie } = res.response
			if (!strankovanie) {
				return
			}

			dispatch({
				type: OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_PART_DONE,
				payload: {
					bankoveUctyPart: obsah
				}
			})

			const { zaznamov, velkostStranky } = strankovanie
			if (velkostStranky * page < zaznamov) {
				const nextPage = page + 1
				await loadPage(nextPage)
			} else {
				dispatch({ type: OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_DONE })
				return
			}
		} catch (err) {
			dispatch({ type: OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_FAIL })
		}
	}
	await loadPage(1)
}

export const loadAllBankoveUcty = (cacheTime = 20) => {
	return (dispatch, getStore) => {
		const store = getStore()

		const { allDataPrepared, lastLoad } = get(store, 'obchodnyPartner.bankoveUcty', {})
		if (allDataPrepared && minutesBetween(lastLoad, new Date()) < cacheTime) {
			return
		}

		const interakcia = get(store, 'interakcie.detail')
		const opCislo = get(interakcia, 'data.opCislo', null)

		dispatch({ type: OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_START })
		loadBankoveUctyByParts(opCislo, dispatch, 10)
	}
}

export const saveManuallyAddedAccount = (account) => {
	return (dispatch) => {
		dispatch({
			type: OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_PART_DONE,
			payload: {
				bankoveUctyPart: [account]
			}
		})
		dispatch({ type: OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_DONE })
	}
}

export const loadBankoveUctyDetail = (cisloBankovehoSpojenia) => {
	return async (dispatch, getStore) => {
		dispatch({
			type: OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_START
		})
		try {
			const store = getStore()
			const interakcia = store.interakcie.detail

			const res = await getReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/bankove-spojenia/${cisloBankovehoSpojenia}`)

			const { obsah } = res.response

			dispatch({
				type: OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_DONE,
				payload: { bankoveUctyDetail: obsah }
			})
		} catch (e) {
			dispatch({
				type: OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_FAIL
			})
		}
	}
}
