import { get } from 'lodash'

import { getReq } from '../utils/request'

export const loadSablony = () => {
	return async () => {
		try {
			const templates = await getReq('/api/v2/sablony')
			return get(templates, 'response.content', [])
		} catch (e) {
			return []
		}
	}
}
