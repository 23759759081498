import { isEmpty } from 'lodash'

export const EMAIL_REGEX = new RegExp(
	'^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
)

// eslint-disable-next-line no-control-regex
export const DIACRITICS_REGEX = /[^\u0000-\u007F]/

// CP-3252 - Email nesmie obsahovať diakritiku
export function containsDiacritics(value) {
	return DIACRITICS_REGEX.test(value)
}

export function isEmail(value) {
	return !isEmpty(value) && EMAIL_REGEX.test(value)
}
