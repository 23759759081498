import * as IBAN from 'iban'
import { reduce, isString, map, slice } from 'lodash'

function validatePrefix_SK_CZ(value) {
	if (!isString(value) || !/^\d+$/.test(value)) {
		return false
	}
	const missing0 = 6 - value.length
	if (missing0 > 0) {
		for (let i = 0; i <= missing0 - 1; i++) {
			value += '0'
		}
	}
	const weights = [10, 5, 8, 4, 2, 1]
	const parts = map(value.split(''), (part) => parseInt(part, 10))
	const count = reduce(
		weights,
		(total, weight, index) => {
			return (total += weight * parts[index])
		},
		0
	)
	return count % 11 == 0
}

function validateNumber_SK_CZ(value) {
	if (!isString(value) || value.length > 10 || !/^\d+$/.test(value) || !(parseInt(value, 10) > 0)) {
		return false
	}
	const missing0 = 10 - value.length
	if (missing0 > 0) {
		for (let i = 0; i <= missing0 - 1; i++) {
			value = `0${value}`
		}
	}
	const weights = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1]
	const parts = map(value.split(''), (part) => parseInt(part, 10))
	const count = reduce(
		weights,
		(total, weight, index) => {
			return (total += weight * parts[index])
		},
		0
	)
	return count % 11 == 0
}

const validate = (values, props) => {
	const errors = {}

	if (values.countryCode) {
		if (!IBAN.countries[values.countryCode]) {
			errors.countryCode = props.t('components:IBANField.validate.Zvolená krajina nepodporuje IBAN')
		}
	}
	if (values.countryCode == 'SK' || values.countryCode == 'CZ') {
		if (values.prefix && !validatePrefix_SK_CZ(values.prefix)) {
			errors.prefix = props.t('components:IBANField.validate.Neplatné predčíslie čísla účtu')
		}
		if (values.number && !validateNumber_SK_CZ(values.number)) {
			errors.number = props.t('components:IBANField.validate.Neplatné číslo účtu')
		}
	}
	if (!values.iban || !IBAN.isValid(values.iban)) {
		errors.iban = props.t('components:IBANField.validate.Neplatný formát IBAN čísla účtu')
	} else if (values.countryCode == 'SK' || values.countryCode == 'CZ') {
		const tempIBAN = values.iban.replace(/\s/g, '')
		if (!validatePrefix_SK_CZ(slice(tempIBAN, 8, 14).join(''))) {
			errors.iban = props.t('components:IBANField.validate.Neplatný formát IBAN čísla účtu - predčíslie nevyhovuje podmienkam')
		} else if (!validateNumber_SK_CZ(slice(tempIBAN, 14, 24).join(''))) {
			errors.iban = props.t('components:IBANField.validate.Neplatný formát IBAN čísla účtu - národná časť čísla účtu nevyhovuje podmienkam')
		}
	}

	return errors
}

export default validate
