import React from 'react'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'

import { isEmpty } from 'lodash'

// atoms
import { SelectField } from '../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/attributesConfig'

class OnlyYesConfirmField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		ciselniky: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired,
		nested: PropTypes.bool,
		label: PropTypes.string,
		formatLabel: PropTypes.func,
		tooltip: PropTypes.string
	}

	state = {
		confirmOptions: []
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { t } = this.props

		this.setState({
			confirmOptions: [
				{
					value: true,
					label: t('translation:Common.Áno')
				}
			]
		})
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { povinny, t } = this.props

		if (povinny && (value == null || value == undefined)) {
			return t('translation:Common.validate.Potvrdenie je povinné')
		}
	}

	render() {
		const { field, label, editMode, columnsCount = COLUMNS_COUNT.THREE, t, nested, value, formatLabel, tooltip } = this.props

		const { confirmOptions } = this.state

		const valueBefore = null
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			valueAfter = (
				<Field
					name={field}
					component={SelectField}
					options={confirmOptions}
					validate={this.validate}
					isSearchable={false}
					isDisabled={isEmpty(confirmOptions)}
					classNamePrefix='react-select'
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			if (value) {
				valueAfter = t('translation:Common.Áno')
			}
		}

		const component = (
			<tr>
				<td>
					<strong>{formatLabel ? formatLabel(this.props) : label}</strong>
					{tooltip && (
						<Tooltip html={<span>{tooltip}</span>} position='right' trigger='mouseenter' theme='light'>
							<div className='info-icon' />
						</Tooltip>
					)}
				</td>
				{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
				<td>{valueAfter}</td>
			</tr>
		)

		if (nested) {
			return component
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>{component}</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(OnlyYesConfirmField)
