import React from 'react'
import { times } from 'lodash'
import { Link } from 'react-router-dom'
// resources
import EmptyMandates from '../../resources/img/icons/empty-mandates.svg'

export default class RychleUkonyWidget extends React.Component {
	commonContentContainer = (content) => {
		return <div className='box-content'>{content}</div>
	}

	render() {
		const actions = []
		times(6, (i) => {
			actions.push(
				<Link key={`ac${i}`} to='/' className='inner-box'>
					<strong>Názov vybraného úkonu</strong>
				</Link>
			)
		})
		return this.commonContentContainer(
			<div
				className='empty-state'
				data-text='Veľmi nás to mrzí, ale sekciu ešte len pripravujeme...'
				style={{ backgroundImage: `url(${EmptyMandates})`, zIndex: 0 }}
			/>
		)
		// return (<React.Fragment>{actions}</React.Fragment>)
	}
}
