import React from 'react'
// resources
import EmptyMandates from '../../resources/img/icons/empty-mandates.svg'

export default class SplnomocneniaWidget extends React.Component {
	commonContentContainer = (content) => {
		return <div className='box-content'>{content}</div>
	}

	render() {
		return this.commonContentContainer(
			<div className='empty-state' data-text='Obchodný partner nemá zadaných splnomocnencov' style={{ backgroundImage: `url(${EmptyMandates})` }} />
		)
	}
}
