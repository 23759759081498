import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { get, find, filter } from 'lodash'

// components
import PodpisovanieDokumentov from './index'
import CommonContainer from './CommonContainer'

// utils
import { SCENARE } from '../../utils/enums'

// actions
import PodpisovanieDokumentovActions from '../../actions/PodpisovanieDokumentov'

class WrapperPodpisovanieDokumentov extends React.Component {
	componentDidMount() {
		const { podpisovanieDokumentovAction } = this.props

		// Vymazem podpisany subor z reduxu
		podpisovanieDokumentovAction.clearSignFile()

		// Vymazem notifikaciu
		podpisovanieDokumentovAction.clearNotification()
	}

	render() {
		const { scenarioOption, disallowScenarios, schvalovacieKriteria, t } = this.props

		const scenarA =
			find(get(scenarioOption, 'scenarios'), { typ: SCENARE.PODPISANIE_NA_MIESTE }) ||
			find(get(scenarioOption, 'scenarios'), { typ: SCENARE.VYTLACIT_A_NAHRAT })
		const scenarB = find(get(scenarioOption, 'scenarios'), { typ: SCENARE.ODOSLAT_BEZ_PODPISU })
		const scenarC = find(get(scenarioOption, 'scenarios'), { typ: SCENARE.ODOSLAT_NA_PODPIS })

		const allowedScenars = filter([scenarA, scenarB, scenarC], (scenar) => !!scenar)
		if (allowedScenars.length > 1) {
			return (
				<CommonContainer>
					<tr>
						<td style={{ textAlign: 'right' }}>
							<label>
								<strong>{t('components:WrapperPodpisovanieDokumentov.Výber scenára')}</strong>
							</label>
							{scenarA && (
								<button
									type='button'
									className='button small'
									data-color='blue'
									style={{ marginLeft: '10px', height: '32px' }}
									onClick={() => disallowScenarios([SCENARE.ODOSLAT_BEZ_PODPISU, SCENARE.ODOSLAT_NA_PODPIS])}
								>
									{t('components:WrapperPodpisovanieDokumentov.Scenár A')}
								</button>
							)}
							{scenarB && (
								<button
									type='button'
									className='button small'
									data-type='outline'
									data-color='blue'
									style={{ marginLeft: '10px', height: '32px' }}
									onClick={() => disallowScenarios([SCENARE.PODPISANIE_NA_MIESTE, SCENARE.VYTLACIT_A_NAHRAT, SCENARE.ODOSLAT_NA_PODPIS])}
								>
									{t('components:WrapperPodpisovanieDokumentov.Scenár B')}
								</button>
							)}
							{scenarC && (
								<button
									type='button'
									className='button small'
									data-color='blue'
									style={{ marginLeft: '10px', height: '32px' }}
									onClick={() => disallowScenarios([SCENARE.PODPISANIE_NA_MIESTE, SCENARE.VYTLACIT_A_NAHRAT, SCENARE.ODOSLAT_BEZ_PODPISU])}
								>
									{t('components:WrapperPodpisovanieDokumentov.Scenár C')}
								</button>
							)}
						</td>
					</tr>
				</CommonContainer>
			)
		}
		return <PodpisovanieDokumentov scenarioOption={scenarioOption} schvalovacieKriteria={schvalovacieKriteria} suhlasVerzia={this.props.suhlasVerzia} />
	}
}

WrapperPodpisovanieDokumentov.propTypes = {
	scenarioOption: PropTypes.shape(),
	disallowScenarios: PropTypes.shape(),
	schvalovacieKriteria: PropTypes.array,
	podpisovanieDokumentovAction: PropTypes.shape({
		clearSignFile: PropTypes.func.isRequired
	}).isRequired,
	t: PropTypes.func.isRequired,
	suhlasVerzia: PropTypes.shape()
}

const mapDispatchToProps = (dispatch) => ({
	podpisovanieDokumentovAction: bindActionCreators(PodpisovanieDokumentovActions, dispatch)
})

export default compose(withTranslation('components'), connect(null, mapDispatchToProps))(WrapperPodpisovanieDokumentov)
