import React from 'react'
import { compose, bindActionCreators } from 'redux'
import { map, get, pick, keys, indexOf, values, isEmpty, isEqual, forEach, split, find, join, filter, head, trim, upperFirst } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import cx from 'classnames'
import reactQueryParams from 'react-query-params'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import qs from 'qs'
import { Tooltip } from 'react-tippy'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as PohladavkyActions from '../../actions/ObchodniPartneri/PohladavkyActions'
import * as FiltersActions from '../../actions/SelectedFiltersActions'
import * as CiselnikySelectBoxActions from '../../actions/CiselnikySelectBoxActions'

// atoms
import Select from '../../atoms/BasicSelect'
import Table from '../../atoms/Table'

// components
import BlokovanieUpominaniaTableRow from '../../components/TableRows/BlokovanieUpominaniaTableRow'
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'

// utils
import { FILTER_SELECTORS, POHLADAVKA_VYROVNANIE_STATUS } from '../../utils/enums'
import Permissions, { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import extractProperties from '../../utils/extractProperties'
import { setRouteParams, BLOKOVANIE_UPOMINANIA_VYTVORIT } from '../../utils/routes'
import { formatDate } from '../../utils/date'

const queryParams = {
	zmluvnyUcetCislo: 'zmluvnyUcetCislo',
	variabilnySymbol: 'variabilnySymbol',
	vyrovnanieStatus: 'vyrovnanieStatus',
	triedenie: 'triedenie'
}

const initFilters = {
	[queryParams.zmluvnyUcetCislo]: null,
	[queryParams.variabilnySymbol]: null,
	[queryParams.vyrovnanieStatus]: null,
	[queryParams.triedenie]: 'dokladDatum:desc'
}

class BlokovanieUpominaniaZoznamPage extends reactQueryParams {
	static propTypes = {
		t: PropTypes.func.isRequired,
		pohladavkyActions: PropTypes.shape().isRequired,
		blokovanieUpominania: PropTypes.shape().isRequired,
		selectedFilters: PropTypes.shape(),
		interakcia: PropTypes.shape(),
		auth: PropTypes.shape()
	}

	defaultQueryParams = {
		[queryParams.zmluvnyUcetCislo]: null,
		[queryParams.variabilnySymbol]: null,
		[queryParams.vyrovnanieStatus]: null,
		[queryParams.triedenie]: null,
		pohladavkaID: '',
		backUrl: ''
	}

	_mounted = false

	constructor(props) {
		super(props)

		const { pohladavkaID, backUrl } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			pohladavkaID: this.defaultQueryParams.pohladavkaID,
			backUrl: this.defaultQueryParams.backUrl
		})

		this.state = {
			pohladavkaID,
			backUrl,
			backUrlLink,
			vyrovnanieStatusOptions: []
		}
	}

	componentDidMount = () => {
		this._mounted = true

		const { ciselniky, pohladavkyActions, selectedFilters, filtersActions, ciselnikySelectBoxActions } = this.props

		this.setState({
			vyrovnanieStatusOptions: map(get(ciselniky, 'vyrovnanieStatus', []), (vyrovnanieStatus) => ({
				value: get(vyrovnanieStatus, 'id'),
				label: upperFirst(get(vyrovnanieStatus, 'nazov'))
			}))
		})

		const filteredParams = pick(
			this.queryParams,
			keys(this.queryParams).filter((key) => {
				return indexOf(values(queryParams), key) != -1 && !isEmpty(this.queryParams[key])
			})
		)

		const filters = {
			...selectedFilters,
			...filteredParams
		}

		ciselnikySelectBoxActions.loadCiselnikZmluvneUcty()
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.BLOKOVANIE_UPOMINANIA_PAGE, filters)
		if (filters[queryParams.zmluvnyUcetCislo]) {
			pohladavkyActions.loadBlokovanieUpominania(1, undefined, filters, get(ciselniky, 'pohladavkaTyp', []))
		} else {
			pohladavkyActions.blokovanieUpominaniaClear()
		}
	}

	componentDidUpdate = () => {
		const { selectedFilters } = this.props

		// prevent to inject not allowed query param
		const query = extractProperties(this.queryParams, values(queryParams))
		if (!isEqual(selectedFilters, query)) {
			this.setQueryParams({
				...selectedFilters
			})
		}
	}

	filterChanged = (filter, fieldName) => {
		const { ciselniky, pohladavkyActions, filtersActions, selectedFilters } = this.props

		// stringify value or set a default value null
		const newFilter = {
			...selectedFilters,
			[fieldName]: get(filter, 'value') ? `${filter.value}` : null
		}
		if (isEqual(newFilter[fieldName], selectedFilters[fieldName])) {
			return
		}

		// update filter state
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.BLOKOVANIE_UPOMINANIA_PAGE, newFilter)
		if (newFilter[queryParams.zmluvnyUcetCislo]) {
			pohladavkyActions.loadBlokovanieUpominania(1, undefined, newFilter, get(ciselniky, 'pohladavkaTyp', []))
		} else {
			pohladavkyActions.blokovanieUpominaniaClear()
		}
	}

	loadMore = () => {
		const { ciselniky, pohladavkyActions, selectedFilters, blokovanieUpominania } = this.props

		// increment pagination page by one
		const pohladavkyNextPage = blokovanieUpominania.page + 1

		if (selectedFilters[queryParams.zmluvnyUcetCislo]) {
			pohladavkyActions.loadBlokovanieUpominania(pohladavkyNextPage, undefined, selectedFilters, get(ciselniky, 'pohladavkaTyp', []))
		}
	}

	sortingColumn = (stringFormat) => {
		const { ciselniky, pohladavkyActions, filtersActions, selectedFilters } = this.props

		// stringify value or set a default value null
		const newFilter = {
			...selectedFilters,
			[queryParams.triedenie]: stringFormat || initFilters.triedenie
		}
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.BLOKOVANIE_UPOMINANIA_PAGE, newFilter)
		if (newFilter[queryParams.zmluvnyUcetCislo]) {
			pohladavkyActions.loadBlokovanieUpominania(1, undefined, newFilter, get(ciselniky, 'pohladavkaTyp', []))
		}
		return newFilter[queryParams.triedenie]
	}

	clearFilters = (fieldName) => {
		const { ciselniky, pohladavkyActions, filtersActions, selectedFilters } = this.props

		let filter
		if (fieldName) {
			filter = {
				...selectedFilters
			}
			filter[fieldName] = initFilters[fieldName]
		} else {
			filter = {
				...initFilters,
				[queryParams.zmluvnyUcetCislo]: selectedFilters[queryParams.zmluvnyUcetCislo],
				[queryParams.triedenie]: selectedFilters[queryParams.triedenie]
			}
		}

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.BLOKOVANIE_UPOMINANIA_PAGE, filter)
		if (filter[queryParams.zmluvnyUcetCislo]) {
			pohladavkyActions.loadBlokovanieUpominania(1, undefined, filter, get(ciselniky, 'pohladavkaTyp', []))
		} else {
			pohladavkyActions.blokovanieUpominaniaClear()
		}
	}

	clearFilter = (value, type) => {
		const { ciselniky, pohladavkyActions, filtersActions, selectedFilters } = this.props

		const filter = split(selectedFilters[type], ',').filter((item) => item != value) // do not check type only value
		const val = !isEmpty(filter) ? filter.join(',') : null

		const newFilter = {
			...selectedFilters,
			[type]: val
		}

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.BLOKOVANIE_UPOMINANIA_PAGE, newFilter)
		if (newFilter[queryParams.zmluvnyUcetCislo]) {
			pohladavkyActions.loadBlokovanieUpominania(1, undefined, newFilter, get(ciselniky, 'pohladavkaTyp', []))
		}
	}

	failureContentElement = () => {
		const { t } = this.props

		return this.commonContentContainer(
			<div className='content-wrapper'>
				<ElementFailure text={t('translation:BlokovanieUpominania.Nepodarilo sa načítať zoznam pohľadávok')} />
			</div>
		)
	}

	emptyContentElement = (message) => {
		const { t } = this.props
		return this.commonContentContainer(
			<div className='content-wrapper'>
				<ElementEmptyContent
					text={
						message ||
						t(
							'translation:BlokovanieUpominania.Pre obchodného partnera neevidujeme žiadne pohľadávky, alebo zadanému filtru nekorešponduje žiadna pohľadávka'
						)
					}
				/>
			</div>
		)
	}

	commonContentContainer = (content) => {
		const { auth, interakcia, ciselnikySelectBox, selectedFilters, blokovanieUpominania, t } = this.props

		const { pohladavkaID, backUrl, backUrlLink, vyrovnanieStatusOptions } = this.state

		const selectedFiltersValues = {
			[queryParams.variabilnySymbol]: [
				{
					value: selectedFilters[queryParams.variabilnySymbol],
					label: t('translation:Common.Vyhľadávanie')
				}
			],
			[queryParams.vyrovnanieStatus]: filter(vyrovnanieStatusOptions, (item) => item.value == selectedFilters[queryParams.vyrovnanieStatus])
		}

		const filtersOptions = {
			[queryParams.zmluvnyUcetCislo]: map(get(ciselnikySelectBox, 'zmluvneUcty.data', []), (zmluvnyUcet) => ({
				value: get(zmluvnyUcet, 'cislo'),
				label: get(zmluvnyUcet, 'cislo')
			})),
			[queryParams.vyrovnanieStatus]: filter(
				[
					{
						label: t('translation:Common.filter.Všetky'),
						value: null
					},
					...vyrovnanieStatusOptions
				],
				(item) => selectedFilters[queryParams.vyrovnanieStatus] != item.value
			)
		}

		const columns = [
			{
				title: t('translation:BlokovanieUpominania.filter.Číslo faktúry'),
				name: 'variabilnySymbol',
				width: '10%'
			},
			{
				title: t('translation:BlokovanieUpominania.filter.Typ'),
				name: 'typ',
				width: '15%'
			},
			{
				title: t('translation:BlokovanieUpominania.filter.Číslo dokladu'),
				name: 'cislo',
				width: '10%'
			},
			{
				title: t('translation:BlokovanieUpominania.filter.Dátum dokladu'),
				name: 'dokladDatum',
				width: '10%'
			},
			{
				title: t('translation:BlokovanieUpominania.filter.Splatnosť'),
				name: 'splatnostDatum',
				width: '10%'
			},
			{
				title: t('translation:BlokovanieUpominania.filter.Suma'),
				name: 'suma',
				width: '10%'
			},
			{
				title: t('translation:BlokovanieUpominania.filter.Otvorená suma'),
				name: 'sumaOtvorena',
				width: '10%'
			},
			{
				title: t('translation:BlokovanieUpominania.filter.Blokovanie (od - do, dôvod)'),
				notActive: true,
				width: '25%',
				textAlign: 'left'
			},
			{
				notActive: true
			}
		]

		const filterItems = []
		forEach(selectedFiltersValues, (values, filter) => {
			let color = 'blue'
			switch (filter) {
				case queryParams.vyrovnanieStatus:
					color = 'purple'
					break
			}

			forEach(values, (value, index) => {
				// prevent to push Empty state
				if (get(value, 'value')) {
					filterItems.push(
						<div
							key={`${filter}-${index}`}
							className='filter-item'
							data-color={color}
							onClick={() => this.clearFilter(get(value, 'value'), filter)}
							style={{ cursor: 'pointer' }}
						>
							<span className='filter-text'>{get(value, 'label')}</span>
							<span className='remove' />
						</div>
					)
				}
			})
		})

		const zuCislo = selectedFilters[queryParams.zmluvnyUcetCislo]

		const nextBackUrl = [window.location.pathname, window.location.search]
		if (backUrl) {
			if (!isEmpty(window.location.search)) {
				nextBackUrl.push(`&pohladavkaID=${pohladavkaID}&backUrl=${backUrl}`)
			} else {
				nextBackUrl.push(`?pohladavkaID=${pohladavkaID}&backUrl=${backUrl}`)
			}
		}

		const query = qs.stringify(
			{
				...selectedFilters,
				backUrl: btoa(join(nextBackUrl, ''))
			},
			{ skipNulls: true, encode: false }
		)

		const otvorenePohladavky = filter(get(blokovanieUpominania, 'data', []), (pohladavka) => {
			return get(pohladavka, 'vyrovnanieStatus.id') == POHLADAVKA_VYROVNANIE_STATUS.OTVORENA && get(pohladavka, 'suma') > 0
		})

		const pohladavkaIDItems = split(pohladavkaID, '_')

		return (
			<>
				<div className='content-header' style={{ boxShadow: 'none' }}>
					<div className='row' style={{ borderBottom: '1px solid #DFDFDF', paddingBottom: '10px' }}>
						<div className='col-6'>
							{backUrlLink && (
								<Link
									to={{
										pathname: head(split(backUrlLink, '?')),
										state: { fromDetail: true, pohladavkaID: head(pohladavkaIDItems), pohladavkaTyp: get(pohladavkaIDItems, '[1]') }
									}}
									className='button pull-left'
									data-type='back-button'
									data-color='blue'
									style={{ marginTop: '20px' }}
								>
									{t('translation:Common.Späť')}
								</Link>
							)}
							<h2 style={{ display: 'inline-block', marginBottom: '0' }}>
								{t('translation:BlokovanieUpominania.Blokovanie upomínania - Zmluvný účet {zuCislo}', { zuCislo })}
							</h2>
							<br />
							<small>
								{t('translation:Common.Aktualizované k ')} {formatDate(new Date(), '-', 'DD.MM.YYYY')}{' '}
								{dayjs().subtract(get(auth, 'config.stavUctuUpdateOffset'), 'minute').format('H:mm')}
							</small>
						</div>
						<div className='col-2' />
						<div className='col-2' />
						<div className='col-2'>
							<Permissions
								allowed={[PERMISSIONS.UKON_BLOKOVANIE_UPOMINANIA]}
								render={(hasPerm, actions) => {
									const canPerformAction = !isEmpty(otvorenePohladavky) && selectedFilters[queryParams.zmluvnyUcetCislo]
									const actionBtn = (
										<Link
											to={`${setRouteParams(BLOKOVANIE_UPOMINANIA_VYTVORIT, get(interakcia, 'opCislo'))}?${query}`}
											className={cx('button', 'pull-right', { disabled: !hasPerm || !canPerformAction })}
											style={{ marginTop: '15px' }}
											type='button'
											data-color='blue'
											onClick={(e) => {
												if (!hasPerm || !canPerformAction) {
													e.preventDefault()
													if (!hasPerm) {
														actions.openForbiddenModal()
													}
												}
											}}
										>
											{t('translation:BlokovanieUpominania.Blokovať upomínanie')}
										</Link>
									)

									let tooltipText
									if (!hasPerm) {
										tooltipText = <span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>
									} else if (!selectedFilters[queryParams.zmluvnyUcetCislo]) {
										tooltipText = <span>{t('translation:BlokovanieUpominania.Vyberte konkrétny zmluvný účet cez filter')}</span>
									} else if (isEmpty(otvorenePohladavky)) {
										tooltipText = <span>{t('translation:BlokovanieUpominania.Filtru nevyhovuje žiadna otvorená pohľadávka')}</span>
									}
									return tooltipText ? (
										<Tooltip html={tooltipText} position='left' trigger='mouseenter' theme='light'>
											{actionBtn}
										</Tooltip>
									) : (
										actionBtn
									)
								}}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col-3'>
							<div className='input-wrapper no-icon'>
								<label>{t('translation:Common.filter.Hľadanie')}</label>
								<input
									type='text'
									className='input-field'
									data-type='search'
									onChange={(e) => {
										this.filterChanged(
											{
												value: trim(get(e, 'target.value')) || null
											},
											queryParams.variabilnySymbol
										)
									}}
									placeholder={t('translation:BlokovanieUpominania.filter.Napíšte číslo faktúry')}
									value={selectedFilters[queryParams.variabilnySymbol] || ''}
								/>
							</div>
						</div>
						<div className='col-3'>
							<Select
								classNamePrefix='react-select'
								label={t('translation:Common.Zmluvný účet')}
								options={filtersOptions[queryParams.zmluvnyUcetCislo]}
								onChange={(zuCislo) => this.filterChanged(zuCislo, queryParams.zmluvnyUcetCislo)}
								closeMenuOnSelect={false}
								placeholder={''}
								value={find(filtersOptions[queryParams.zmluvnyUcetCislo], (option) => option.value == zuCislo)}
							/>
						</div>
						<div className='col-3'>
							<Select
								classNamePrefix='react-select'
								label={t('translation:StavUctu.Stav položky')}
								options={filtersOptions[queryParams.vyrovnanieStatus]}
								onChange={(vyrovnanieStatus) => this.filterChanged(vyrovnanieStatus, queryParams.vyrovnanieStatus)}
								closeMenuOnSelect={false}
								placeholder={t('translation:Common.filter.Všetky')}
								value={find(vyrovnanieStatusOptions, (option) => option.value == selectedFilters[queryParams.vyrovnanieStatus])}
							/>
						</div>
						<div className='col-3' />
						<div className='col-12'>
							<div className='filter-items clearfix' style={{ paddingRight: '150px' }}>
								{filterItems}
								{!isEmpty(filterItems) && (
									<div
										className='filter-item'
										style={{ margin: 0, position: 'absolute', top: '5px', right: '15px', cursor: 'pointer' }}
										data-color='red'
										onClick={() => this.clearFilters()}
									>
										<span className='filter-text'>{t('translation:Common.filter.Zrušiť filtre')}</span>
										<span className='remove' />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<Table
					columns={columns}
					triedenie={selectedFilters[queryParams.triedenie]}
					sortingColumn={this.sortingColumn}
					style={{ paddingRight: '20px' }}
				/>
				{content}
			</>
		)
	}

	render = () => {
		const { blokovanieUpominania, ciselnikySelectBox, selectedFilters, t } = this.props

		const { data, isLoading, isFailure, isLastPage, page } = blokovanieUpominania

		if ((isLoading && page == 1) || get(ciselnikySelectBox, 'zmluvneUcty.isLoading')) {
			return this.commonContentContainer(
				<div className='box' style={{ height: '500px' }}>
					<ElementLoading />
				</div>
			)
		}

		if (isFailure || get(ciselnikySelectBox, 'zmluvneUcty.isFailure')) {
			return this.failureContentElement()
		}

		if (isEmpty(data)) {
			if (!selectedFilters[queryParams.zmluvnyUcetCislo]) {
				return this.emptyContentElement(t('translation:BlokovanieUpominania.Vyberte konkrétny zmluvný účet cez filter'))
			}
			return this.emptyContentElement()
		}

		const loadMoreBtn = isLoading && page > 1 ? t('translation:Common.Načítavam') : t('translation:Common.Načítať ďalšie položky')

		const rows = map(data, (pohladavka, index) => <BlokovanieUpominaniaTableRow key={`blokovanie-upominania-${index}`} t={t} pohladavka={pohladavka} />)

		return this.commonContentContainer(
			<div id='scroll-pohladavky-container' className='content-wrapper'>
				<div className='row'>
					<div className='col-12'>
						<div className='box'>
							<div className='box-content table-wrapper' style={{ padding: 0 }}>
								<table id='pohladavky' className='content-table padded bordered' cellSpacing='0' style={{ overflow: 'scroll', height: '100%' }}>
									<tbody style={{ position: 'relative' }}>{rows}</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				{!isLastPage && (
					<div className='text-center'>
						<div className='button' data-color='blue' data-type='outline' style={{ margin: '20px 0' }} onClick={this.loadMore}>
							{loadMoreBtn}
						</div>
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	blokovanieUpominania: get(state, 'blokovanieUpominania.blokovanieUpominania'),
	ciselniky: get(state, 'ciselniky.data'),
	ciselnikySelectBox: get(state, 'ciselnikySelectBox'),
	interakcia: get(state, 'interakcie.detail.data'),
	selectedFilters: get(get(state, 'selectedFilters'), FILTER_SELECTORS.BLOKOVANIE_UPOMINANIA_PAGE, {})
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
	pohladavkyActions: bindActionCreators(PohladavkyActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch),
	ciselnikySelectBoxActions: bindActionCreators(CiselnikySelectBoxActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_POHLADAVKY])
)(BlokovanieUpominaniaZoznamPage)
