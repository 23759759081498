import React from 'react'
import { upperFirst, get, filter, take } from 'lodash'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'

// utils
import { formatDate } from '../utils/date'
import Permissions, { getRequiredPermissionsForUkonRead } from '../utils/permissionsHoc'
import { setRouteParams, UKON_DETAIL } from '../utils/routes'
import { getUkonStateColor } from '../utils/zmluvneVztahy'
import { history } from '../utils/history'
import { UKON_LIST_ROW_TYPE } from '../utils/enums'

class UkonList extends React.PureComponent {
	createContent = (ukon, index) => {
		const { uniqueKeyPrefix, interakcia, rowStyleClass, rowType, openNewTab, zvCislo } = this.props

		const zacatyOd = get(ukon, 'zacatyOd')
		const vytvorenyOd = get(ukon, 'vytvorenyOd')
		const spustenyOd = get(ukon, 'spustenyOd')
		const timeRange = filter([formatDate(zacatyOd, null, 'HH:mm'), formatDate(spustenyOd, null, 'HH:mm')], (item) => !!item).join(' - ')

		const permissions = getRequiredPermissionsForUkonRead(ukon)

		const leftCellContent = (
			<>
				{formatDate(vytvorenyOd, '-', 'DD.MM.YYYY')}
				<span style={{ marginLeft: '20px' }}>{timeRange}</span>
			</>
		)

		const rightCellContent = (
			<>
				<Permissions
					allowed={permissions}
					render={(hasPerm, actions) => {
						return (
							<a
								onClick={(e) => {
									e.preventDefault()
									if (hasPerm) {
										const opCislo = get(interakcia, 'opCislo')
										// CP-1810 - open in new tab if `Ukon` is assigned to another OP
										if (openNewTab || opCislo != get(ukon, 'opCislo')) {
											const win = window.open(
												`${setRouteParams(UKON_DETAIL, get(ukon, 'opCislo'), get(ukon, 'id'))}?clear=true&keepRoute=true`,
												'_blank'
											)
											win.focus()
										} else {
											const path = `${window.location.pathname}${window.location.search}?itemId=${zvCislo}`
											const backUrl = btoa(path)
											history.push(`${setRouteParams(UKON_DETAIL, opCislo, get(ukon, 'id'))}?backUrl=${backUrl}`)
										}
									} else {
										actions.openForbiddenModal()
									}
								}}
								style={{ textDecoration: 'underline', cursor: 'pointer', paddingLeft: rowType == UKON_LIST_ROW_TYPE.ONE_CELL ? '40px' : '0px' }}
								className='content'
							>
								{upperFirst(get(ukon, 'typ.nazov'))}
							</a>
						)
					}}
				/>
				<Tooltip html={get(ukon, 'ukonStav.nazov')} style={{ marginLeft: '20px' }} position='right' trigger='mouseenter' theme='light'>
					<span className='label circle ukon-state' data-color={getUkonStateColor(ukon)} />
				</Tooltip>
				{get(ukon, 'ukonStav.nazov')}
			</>
		)

		if (rowType == UKON_LIST_ROW_TYPE.ONE_CELL) {
			return (
				<tr key={`${uniqueKeyPrefix}-${index}`} className={rowStyleClass}>
					<td>
						{leftCellContent}
						{rightCellContent}
					</td>
				</tr>
			)
		}
		return (
			<tr key={`${uniqueKeyPrefix}-${index}`} className={rowStyleClass}>
				<td>{leftCellContent}</td>
				<td>{rightCellContent}</td>
			</tr>
		)
	}

	render() {
		const { ukony, maxCount } = this.props

		const ukonyTemp = maxCount ? take(ukony, maxCount) : ukony

		const content = ukonyTemp.map((ukon, index) => this.createContent(ukon, index))

		return content
	}
}

UkonList.propTypes = {
	uniqueKeyPrefix: PropTypes.string.isRequired,
	ukony: PropTypes.array.isRequired,
	interakcia: PropTypes.shape().isRequired,
	zvCislo: PropTypes.string.isRequired,
	rowStyleClass: PropTypes.string,
	rowType: PropTypes.string,
	openNewTab: PropTypes.bool,
	maxCount: PropTypes.number
}

export default UkonList
