import { ENVIRONMENT } from './enums'
import productionLogo from '../resources/img/logos/logo-zse-production.svg'
import testLogo from '../resources/img/logos/logo-zse-test.svg'

/**
 * @return Resource
 *
 * Get logo resource by current environment
 */
export function getLogo(env) {
	return env === ENVIRONMENT.PRODUCTION ? productionLogo : testLogo
}

/**
 * @return Boolean
 *
 * Get information if actual environment is production
 */
export function isProduction(env) {
	return env === ENVIRONMENT.PRODUCTION
}
