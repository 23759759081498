import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import Backend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import { initReactI18next } from 'react-i18next'
import { setDefaultLocale, registerLocale } from 'react-datepicker'
import sk from 'date-fns/locale/sk'

const NAMESPACES = ['translation', 'atoms', 'components', 'containers', 'loginPage']
const DEFAULT_LOCALE = 'sk'

// Set sk local for all date pickers
registerLocale('sk', sk)
setDefaultLocale(DEFAULT_LOCALE)

// eslint-disable-next-line
i18n.use(Backend).use(initReactI18next).init({
		debug: process.env.NODE_ENV === 'development',
		interpolation: {
			escapeValue: false
		},
		ns: NAMESPACES,
		lng: 'sk',
		load: 'languageOnly',
		fallbackLng: DEFAULT_LOCALE,
		defaultNS: 'translation',
		defaultLocale: DEFAULT_LOCALE,
		nsSeparator: ':',
		keySeparator: '.',
		react: {
			useSuspense: false,
			bindI18n: 'languageChanged loaded',
			bindStore: 'added removed',
			nsMode: 'default'
		},
		backend: {
			backends: [
				LocalStorageBackend, // primary
				XHR // fallback
			],
			backendOptions: [
				{
					prefix: 'i18next_res_',
					expirationTime: process.env.NODE_ENV === 'development' ? 0 : 0 // 7*24*60*60*1000 // 1 week
				},
				{
					loadPath: '/locales/{{lng}}/{{ns}}.json',
					queryStringParams: { v: process.env.REACT_APP_VERSION }
				}
			]
		}
	})

export default i18n
