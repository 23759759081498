import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes, getFormValues, touch } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { map, get, upperFirst, isEmpty, find, sumBy } from 'lodash'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Tooltip } from 'react-tippy'
import { NumericFormat } from 'react-number-format'

import validate from './validateForm'

// atoms
import { SelectField, DropZoneField, DatePickerField, TextareaField, TextInputField } from '../../../atoms'

// utils
import { FORMS, POHLADAVKA_TYP, BUSINESS_CHANNELS } from '../../../utils/enums'
import { getTypPohladavkyTooltip } from '../../../utils/pohladavky'

class BlokovanieUpominaniaInfo extends React.Component {
	static propTypes = {
		...propTypes,
		formValues: PropTypes.shape()
	}

	state = {}

	_mounted = false

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		const { formValues, dispatch } = this.props

		if (get(formValues, 'pripad') != get(prevProps, 'formValues.pripad')) {
			dispatch(touch(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE, 'pocetKD'))
		}
	}

	render() {
		const { formTitle, handleSubmit, invalid, auth, onDeleteFile, onBackClick, onCancelClick, formValues, ciselniky, t } = this.props

		const ukonVstupOptions = map(get(ciselniky, 'ukonVstup', []), (ukonVstup) => ({
			label: upperFirst(get(ukonVstup, 'nazov')),
			value: get(ukonVstup, 'id')
		}))

		const pripadOptions = map(get(ciselniky, 'blokovaniePripady', []), (pripad) => ({
			label: upperFirst(get(pripad, 'nazov')),
			value: get(pripad, 'id')
		}))

		const pripad = find(get(ciselniky, 'blokovaniePripady', []), (pripad) => get(pripad, 'id') == get(formValues, 'pripad'))

		let minTimeDatumPrijatiaZiadosti = null
		let maxTimeDatumPrijatiaZiadosti = null
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<button
						className={cx('button', 'pull-right', { disabled: invalid })}
						disabled={invalid}
						type='submit'
						data-color='blue'
						style={{ marginLeft: '20px' }}
					>
						{t('translation:Common.Pokračovať')}
					</button>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstup do úkonu')}</strong>
										</td>
										<td>
											<Field
												name='ukonVstup'
												component={SelectField}
												options={ukonVstupOptions}
												isSearchable={false}
												isDisabled={isEmpty(ukonVstupOptions)}
												classNamePrefix='react-select'
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
										</td>
										<td>
											<Field
												name='podpisMiesto'
												component={TextInputField}
												placeholder={t('translation:Common.Zadajte miesto podpisu úkonu (ak nejde o prípad odloženého podpisu)')}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
										</td>
										<td>
											<div className='select-wrapper'>
												<Field
													name='datumPrijatiaZiadosti'
													component={DatePickerField}
													showYearDropdown
													scrollableYearDropdown
													minDate={minTimeDatumPrijatiaZiadosti?.toDate()}
													maxDate={maxTimeDatumPrijatiaZiadosti?.toDate()}
													placeholderText={t('translation:Common.Zvoľte dátum')}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:Common.Zmluvný účet')}</strong>
											</td>
											<td>{get(formValues, 'zuCislo')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:BlokovanieUpominania.Pohľadávky')}</strong>
											</td>
											<td style={{ padding: 0 }}>
												<table className='search-results-table no-pointer' cellSpacing='0' style={{ border: 'none' }}>
													<thead>
														<tr>
															<th style={{ width: '20%' }}>{t('translation:Preplatky.filter.Číslo faktúry')}</th>
															<th style={{ width: '50%' }}>{t('translation:Preplatky.filter.Typ')}</th>
															<th style={{ whiteSpace: 'nowrap', textAlign: 'right', width: '30%' }}>
																{t('translation:Preplatky.filter.Otvorená suma')}
															</th>
														</tr>
													</thead>
													<tbody data-empty={t('translation:BlokovanieUpominania.Neboli zvolené žiadne pohľadávky')}>
														{map(get(formValues, 'pohladavky', []), (pohladavka, index) => {
															let typPohladavky
															switch (get(pohladavka, 'typ.id')) {
																case POHLADAVKA_TYP.PREPLATOK_Z_FAKTURACIE.value:
																case POHLADAVKA_TYP.AKONTO.value:
																case POHLADAVKA_TYP.ZALOHA_ZNOVUPRIPOJENIE.value:
																case POHLADAVKA_TYP.UDRZBA_UCTU.value:
																case POHLADAVKA_TYP.STANDARD_KVALITY.value:
																	typPohladavky = (
																		<Tooltip
																			html={getTypPohladavkyTooltip(pohladavka, t)}
																			position='top'
																			trigger='mouseenter'
																			theme='light'
																		>
																			{get(pohladavka, 'typ.nazov')}
																		</Tooltip>
																	)
																	break
																default:
																	typPohladavky = get(pohladavka, 'typ.nazov')
															}

															return (
																<tr key={`pohladavka_${index}`}>
																	<td style={{ whiteSpace: 'nowrap' }}>{get(pohladavka, 'variabilnySymbol')}</td>
																	<td style={{ wordBreak: 'normal' }}>{typPohladavky}</td>
																	<td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
																		<NumericFormat
																			thousandSeparator={' '}
																			decimalSeparator={','}
																			decimalScale={2}
																			fixedDecimalScale={true}
																			displayType='text'
																			suffix=' €'
																			value={get(pohladavka, 'sumaOtvorena')}
																		/>
																	</td>
																</tr>
															)
														})}
														{!isEmpty(get(formValues, 'pohladavky', [])) && (
															<tr key={`pohladavka_spolu`}>
																<td>
																	<strong>{t('translation:Common.SPOLU')}</strong>
																</td>
																<td />
																<td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
																	<strong>
																		<NumericFormat
																			thousandSeparator={' '}
																			decimalSeparator={','}
																			decimalScale={2}
																			fixedDecimalScale={true}
																			displayType='text'
																			suffix=' €'
																			value={sumBy(get(formValues, 'pohladavky', []), 'sumaOtvorena')}
																		/>
																	</strong>
																</td>
															</tr>
														)}
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:BlokovanieUpominania.Prípad (scenár)')}</strong>
											</td>
											<td>
												<Field
													name='pripad'
													component={SelectField}
													options={pripadOptions}
													isSearchable={false}
													isDisabled={isEmpty(pripadOptions)}
													classNamePrefix='react-select'
												/>
												{pripad && get(pripad, 'popis') && (
													<div style={{ paddingTop: '10px' }}>
														<label style={{ fontSize: '12px' }}>
															{t('translation:Common.Pozn', { poznamka: get(pripad, 'popis') })}
														</label>
													</div>
												)}
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:BlokovanieUpominania.Počet kalendárnych dní blokovania')}</strong>
											</td>
											<td>
												<Field
													name='pocetKD'
													component={TextInputField}
													disabled={!get(formValues, 'pripad')}
													type='number'
													min='1'
													placeholder={t('translation:BlokovanieUpominania.Zadajte počet kalendárnych dní')}
												/>
												{get(formValues, 'pocetKD') && (
													<div style={{ paddingTop: '10px' }}>
														<label style={{ fontSize: '12px' }}>
															{t('translation:BlokovanieUpominania.Dátum blokovania od do', {
																datumOd: dayjs().format('DD.MM.YYYY'),
																datumDo: dayjs()
																	.add(+get(formValues, 'pocetKD', 0), 'day')
																	.format('DD.MM.YYYY')
															})}
														</label>
													</div>
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Poznámka')}</strong>
										</td>
										<td>
											<Field name='poznamka' component={TextareaField} rows='3' placeholder={t('translation:Common.Zadajte poznámku')} />
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstupné dokumenty k úkonu')}</strong>
										</td>
										<td>
											<Field
												name='dokumenty'
												onDelete={onDeleteFile}
												component={DropZoneField}
												placeholder={t('translation:Common.Klikni alebo presuň súbory pre nahratie')}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(BlokovanieUpominaniaInfo)

const mapStateToProps = (state) => ({
	formValues: getFormValues(FORMS.BLOKOVANIE_UPOMINANIA_VYTVORENIE)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
