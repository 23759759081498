import {
	OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_START,
	OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_DONE,
	OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_FAIL
} from '../../types/obchodnyPartner'
import { SUHLASY_KATEGORIA } from '../../utils/enums'

import { getReq } from '../../utils/request'

export function digitalizaciaLoadStart() {
	return {
		type: OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_START
	}
}

export function digitalizaciaLoadDone(digitalizacia) {
	return {
		type: OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_DONE,
		payload: {
			digitalizacia
		}
	}
}

export function digitalizaciaLoadFail() {
	return {
		type: OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_FAIL
	}
}

export function loadDigitalizacia() {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = store.interakcie.detail

		dispatch(digitalizaciaLoadStart())
		try {
			const query = {
				kategoriaId: SUHLASY_KATEGORIA.DIGITALIZACIA
			}

			const res = await getReq(`/api/ces/suhlas/${interakcia.data.opCislo}/overview`, query)
			const digitalizacia = res.response

			dispatch(digitalizaciaLoadDone(digitalizacia))
			return digitalizacia
		} catch (e) {
			dispatch(digitalizaciaLoadFail())
		}
	}
}
