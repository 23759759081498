import React from 'react'
import { compose, bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { get, set, head, filter, map, find, includes, isEmpty, maxBy } from 'lodash'
import { initialize, reset, destroy, change, touch } from 'redux-form'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

// components
import VseobecnyAktivnyUkonInfo from '../../components/VseobecnyAktivnyUkon/VseobecnyAktivnyUkonInfo/VseobecnyAktivnyUkonInfo'
import VseobecnyAktivnyUkonConfirm from '../../components/VseobecnyAktivnyUkon/VseobecnyAktivnyUkonConfirm'
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementLoading from '../../components/ElementLoading'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import initProcesnyKonfigurator from '../../components/ProcesnyKonfigurator'

// actions
import * as TrackingActions from '../../actions/TrackingActions'
import * as UkonyActions from '../../actions/UkonyActions'
import * as CiselnikySelectBoxActions from '../../actions/CiselnikySelectBoxActions'
import PodpisovanieDokumentovActions from '../../actions/PodpisovanieDokumentov'
import * as DataActions from '../../actions/DataActions'
import * as FiltersActions from '../../actions/SelectedFiltersActions'
import * as StatusActions from '../../actions/StatusActions'

// utils
import { postReq, deleteReq, putReq } from '../../utils/request'
import { BUSINESS_CHANNELS, SCENARE, NOTIFICATION_TYPES, FORMS, UKONY, UKONY_CISELNIK, FILTER_SELECTORS, DOKUMENT_TYP } from '../../utils/enums'
import { setRouteParams, PREHLAD } from '../../utils/routes'
import { history } from '../../utils/history'
import { createFormInitValues } from '../../utils/form'
import { getUkonVstupByKanal } from '../../utils/scenar'
import { withPermissions, checkPermissions, PERMISSIONS } from '../../utils/permissionsHoc'

const scenarioOptions = [
	{
		kanal: BUSINESS_CHANNELS.ZSE_CENTRUM,
		scenarios: [{ typ: SCENARE.PODPISANIE_NA_MIESTE }, { typ: SCENARE.VYTLACIT_A_NAHRAT }, { typ: SCENARE.NAHRAT }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	},
	{
		kanal: BUSINESS_CHANNELS.ZAKAZNICKA_LINKA,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	},
	{
		kanal: BUSINESS_CHANNELS.BACK_OFFICE,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	}
]

class VseobecnyAktivnyUkonPage extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		interakcia: PropTypes.shape({
			id: PropTypes.any.isRequired
		}).isRequired,
		auth: PropTypes.shape().isRequired,
		trackingActions: PropTypes.shape().isRequired,
		ukonyActions: PropTypes.shape({
			clearUkonId: PropTypes.func.isRequired
		}).isRequired,
		ciselnikySelectBoxActions: PropTypes.shape().isRequired,
		podpisovanieDokumentovActions: PropTypes.shape({
			resetTemplatePdf: PropTypes.func.isRequired
		}).isRequired,
		dataActions: PropTypes.shape().isRequired,
		filtersActions: PropTypes.shape().isRequired,
		statusActions: PropTypes.shape().isRequired,
		tracking: PropTypes.shape().isRequired,
		ukonNovy: PropTypes.shape().isRequired,
		ciselniky: PropTypes.shape().isRequired,
		ciselnikySelectBox: PropTypes.shape().isRequired,
		signedPdf: PropTypes.shape(),
		notification: PropTypes.shape(),
		templatePdf: PropTypes.shape(),
		procesnyKonfigurator: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}).isRequired,
		statuses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		t: PropTypes.func.isRequired,
		selectedFiltersUkony: PropTypes.shape()
	}

	_mounted = false

	constructor(props) {
		super(props)

		const ProcesnyKonfigurator = initProcesnyKonfigurator(FORMS.VSEOBECNY_UKON_AKTIVNY, 'vseobecnyUkonAktivny')

		// filter possible scenars for signing pdf files
		const scenarioOption = filter(scenarioOptions, (scenarioOption) => {
			return scenarioOption.kanal == get(props, 'auth.businessChannel.actual.id')
		})

		this.state = {
			step: 1,
			scenarioOption: head(scenarioOption),
			isLoading: true,
			ProcesnyKonfigurator,
			koKriteria: [],
			isKoKriteriumChecked: false,
			validacneKriteria: [],
			schvalovacieKriteria: [],
			context: null
		}
	}

	componentDidMount() {
		this._mounted = true

		const { dispatch, auth, trackingActions, dataActions, ciselnikySelectBoxActions, tracking, ciselniky } = this.props

		ciselnikySelectBoxActions.loadCiselnikMiestaSpotreby()
		dataActions.registerLeavePageModal()

		if (!tracking.startTime || (tracking.startTime && tracking.type !== UKONY.VSEOBECNY_UKON_AKTIVNY)) {
			trackingActions.tryToStartTracking(UKONY.VSEOBECNY_UKON_AKTIVNY)
		}

		const initValues = createFormInitValues({
			ukonVstup: getUkonVstupByKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'ukonVstup')),
			datumPrijatiaZiadosti: new Date(),
			podpisMiesto: get(auth, 'user.podpisMiesto', ''),
			splnomocnenec: null,
			msCislo: null,
			kategoria1: null,
			kategoria2: null,
			kategoria3: null,
			kategoria4: null
		})

		dispatch(initialize(FORMS.VSEOBECNY_UKON_AKTIVNY, initValues, true))
		dispatch(reset(FORMS.VSEOBECNY_UKON_AKTIVNY))

		if (!get(initValues, 'ukonVstup')) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.VSEOBECNY_UKON_AKTIVNY, 'ukonVstup'))
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(get(initValues, 'dokumenty'))) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.VSEOBECNY_UKON_AKTIVNY, 'dokumenty'))
		}

		dispatch(touch(FORMS.VSEOBECNY_UKON_AKTIVNY, 'kategoria1'))
		dispatch(touch(FORMS.VSEOBECNY_UKON_AKTIVNY, 'kategoria2'))
		dispatch(touch(FORMS.VSEOBECNY_UKON_AKTIVNY, 'kategoria3'))
		dispatch(touch(FORMS.VSEOBECNY_UKON_AKTIVNY, 'kategoria4'))
		dispatch(touch(FORMS.VSEOBECNY_UKON_AKTIVNY, 'ziadostText'))

		this.setState({
			isLoading: false
		})
	}

	componentWillUnmount() {
		const { dispatch, trackingActions, ukonyActions, dataActions } = this.props

		dispatch(destroy(FORMS.VSEOBECNY_UKON_AKTIVNY))

		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()

		// clear ukonId from storage
		ukonyActions.clearUkonId()

		this._mounted = false
	}

	formatDataForUkon = (values) => {
		const { interakcia, auth, tracking, ciselniky } = this.props

		const { schvalovacieKriteria } = this.state

		const typ = find(get(ciselniky, 'ukonTyp', []), (ukonTyp) => get(ukonTyp, 'id') == UKONY_CISELNIK.VSEOBECNY_UKON_AKTIVNY)
		const vstup = find(get(ciselniky, 'ukonVstup', []), (vstup) => get(vstup, 'id') == get(values, 'ukonVstup'))

		let schvalovaciRezim = null
		if (!isEmpty(schvalovacieKriteria)) {
			const maxUrovenSchvalovania = get(
				maxBy(
					filter(schvalovacieKriteria, (schvalovacieKriterium) => !schvalovacieKriterium.vysledok),
					'urovenSchvalovania'
				),
				'urovenSchvalovania',
				0
			)
			const schvalovacieKriteriaNormalize = map(schvalovacieKriteria, (schvalovacieKriterium) => ({
				id: schvalovacieKriterium.nazov,
				vysledok: schvalovacieKriterium.vysledok,
				dovod: schvalovacieKriterium.popis
			}))
			schvalovaciRezim = {
				kod: maxUrovenSchvalovania,
				pravidla: schvalovacieKriteriaNormalize
			}
		}

		const kategoria1 = find(get(ciselniky, 'sapKontaktKategoria1', []), (kategoria) => get(kategoria, 'id') == get(values, 'kategoria1')) || null
		const kategoria2 = find(get(ciselniky, 'sapKontaktKategoria2', []), (kategoria) => get(kategoria, 'id') == get(values, 'kategoria2')) || null
		const kategoria3 = find(get(ciselniky, 'sapKontaktKategoria3', []), (kategoria) => get(kategoria, 'id') == get(values, 'kategoria3')) || null
		const kategoria4 = find(get(ciselniky, 'sapKontaktKategoria4', []), (kategoria) => get(kategoria, 'id') == get(values, 'kategoria4')) || null

		return {
			typ,
			opCislo: get(interakcia, 'opCislo'),
			interakciaId: get(interakcia, 'id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
			zacatyOd: dayjs(tracking.startTime).toISOString(),
			vstup,
			podpisMiesto: get(values, 'podpisMiesto'),
			poznamka: get(values, 'poznamka'),
			ziadanyOd: dayjs(get(values, 'datumPrijatiaZiadosti')).toISOString(),
			splnomocnenec: get(values, 'splnomocnenec'),
			data: {
				schvalovaciRezim,
				msCislo: get(values, 'msCislo', null),
				kategoria1,
				kategoria2,
				kategoria3,
				kategoria4,
				ziadostText: get(values, 'ziadostText', null)
			}
		}
	}

	setLoading = (value) => {
		if (this._mounted) {
			this.setState({
				isLoading: value
			})
		}
	}

	onDeleteFile = async (fileID) => {
		const { ukonNovy } = this.props

		try {
			await deleteReq(`/api/v0/ukony/${ukonNovy.id}/dokumenty/${fileID}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	stepOneClickHandler = async (values) => {
		const { dispatch, interakcia, ukonyActions, tracking, ukonNovy } = this.props

		this.setLoading(true)

		const body = this.formatDataForUkon(values)

		try {
			let ukonId
			// if ukon not exist yet create a new one else update it
			if (!ukonNovy.id) {
				const res = await postReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ukony`, null, body)

				// set podpis Miesto from response CP-588
				const podpisMiesto = get(res, 'response.content.podpisMiesto')
				dispatch(change(FORMS.VSEOBECNY_UKON_AKTIVNY, 'podpisMiesto', podpisMiesto))

				ukonId = get(res, 'response.content.id')
				ukonyActions.setUkonId(ukonId)
			} else {
				ukonId = ukonNovy.id
				const updateBody = {
					...body,
					trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
					id: ukonId
				}
				await putReq(`/api/v2/ukony/${ukonId}`, null, updateBody)
			}

			// dokumenty which are not upload does not have ID
			const notUploadedDokumenty = filter(get(values, 'dokumenty', []), (dokument) => !get(dokument, 'id'))
			const uploadedDokumenty = filter(get(values, 'dokumenty', []), (dokument) => get(dokument, 'id'))

			const dokumenty = map(notUploadedDokumenty, async (file) => {
				const dokumentResponse = await postReq(`/api/v0/ukony/${ukonId}/prilozit-dokument`, null, {
					contentType: file.type,
					nazov: file.name,
					data: file.dataAsBase64,
					typ: {
						id: DOKUMENT_TYP.VSTUPNY
					}
				})
				return {
					type: get(dokumentResponse, 'response.contentType'),
					id: get(dokumentResponse, 'response.id'),
					dataAsBase64: get(file, 'dataAsBase64'),
					typ: get(dokumentResponse, 'response.typ'),
					name: get(dokumentResponse, 'response.nazov')
				}
			})

			const dokumentyPromises = await Promise.all(dokumenty)

			// merge already uploaded dokumenty and fresh uploaded
			dispatch(change(FORMS.VSEOBECNY_UKON_AKTIVNY, 'dokumenty', [...uploadedDokumenty, ...dokumentyPromises]))

			if (this._mounted) {
				this.setState({
					step: 2,
					isLoading: false
				})
			}
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	stepTwoClickHandler = async (values) => {
		const { tracking, dataActions, ukonNovy, signedPdf, notification, templatePdf, t } = this.props

		this.setLoading(true)

		let notifikacie = {}
		if (get(notification, 'typ') == NOTIFICATION_TYPES.ADDRESS) {
			set(notifikacie, 'adresyPosta', [get(notification, 'address')])
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.EMAIL) {
			set(notifikacie, 'adresyUri', [
				{
					typ: NOTIFICATION_TYPES.EMAIL,
					hodnota: get(notification, 'email')
				}
			])
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.PRINTER) {
			set(notifikacie, 'adresyUri', [
				{
					typ: NOTIFICATION_TYPES.PRINTER,
					poradie: 0
				}
			])
		}

		notifikacie = isEmpty(notifikacie) ? undefined : notifikacie
		const documentForUpload = get(signedPdf, 'data.dataAsBase64') ? get(signedPdf, 'data') : get(templatePdf, 'data')

		try {
			if (documentForUpload) {
				await postReq(`/api/v0/ukony/${ukonNovy.id}/prilozit-dokument`, null, {
					contentType: get(documentForUpload, 'type'),
					typ: {
						id: DOKUMENT_TYP.VYSTUPNY
					},
					nazov: get(documentForUpload, 'name'),
					data: get(documentForUpload, 'dataAsBase64')
				})
			}

			const body = this.formatDataForUkon(values)
			const spustitBody = {
				...body,
				trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
				notifikacie,
				id: ukonNovy.id
			}
			await postReq(`/api/v2/ukony/${ukonNovy.id}/spustit`, null, spustitBody)

			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Úkon bol úspešne odoslaný'),
					success: true,
					isLoading: false
				})
			}
		} catch (e) {
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
					success: false,
					isLoading: false
				})
			}
		}
	}

	confirmModal = () => {
		const { interakcia, ukonyActions, selectedFiltersUkony } = this.props
		const { success } = this.state

		if (this._mounted) {
			if (success) {
				this.setState(
					{
						success: false
					},
					() => {
						// clear ukonId from storage
						ukonyActions.clearUkonId()
						// refetch new data for historia ukonov
						ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)
						history.replace(setRouteParams(PREHLAD, get(interakcia, 'opCislo')))
					}
				)
			} else {
				this.setState({
					result: null
				})
			}
		}
	}

	koKriteriaHandler = (isKoKriteriumChecked, koKriteria) =>
		this.setState({
			isKoKriteriumChecked,
			koKriteria
		})

	validacneKriteriaHandler = (validacneKriteria) =>
		this.setState({
			validacneKriteria
		})

	schvalovacieKriteriaHandler = (schvalovacieKriteria) =>
		this.setState({
			schvalovacieKriteria
		})

	setStep = (step) => this.setState({ step })

	/* koKriteriumContentElement = (koKriteria) => (<div className="failure-content-container">
		<img src={failureStateIcon} />
		{map(koKriteria, (koKriterium, index) => <React.Fragment key={index}>
			<p>{koKriterium.nazov}:</p>
			<p className="message fail">{koKriterium.popis}</p>
		</React.Fragment>)}
	</div>) */

	render() {
		const { auth, signedPdf, notification, procesnyKonfigurator, interakcia, t } = this.props
		const {
			step,
			scenarioOption,
			isLoading,
			koKriteria,
			validacneKriteria,
			schvalovacieKriteria,
			context,
			success,
			result,
			dissallowedScenarios,
			ProcesnyKonfigurator
		} = this.state

		if (isLoading || get(procesnyKonfigurator, 'isLoading')) {
			return <ElementLoading />
		}

		if (get(procesnyKonfigurator, 'isFailure') || isEmpty(procesnyKonfigurator, 'data.ukony')) {
			return <ElementEmptyContent text={t('translation:ProcesnyKonfigurator.Ľutujeme ale nepodarilo sa načítať Procesný konfigurátor')} />
		}

		// check if every Ko kriterium pass condition, if not navigate user back to step 1
		let nextStep = step
		const invalidKoKriteria = filter(koKriteria, {
			vysledok: false
		})
		if (!isEmpty(invalidKoKriteria)) {
			nextStep = 1
		}

		let component
		switch (nextStep) {
			case 1:
				component = (
					<VseobecnyAktivnyUkonInfo
						{...this.props}
						formTitle={t('translation:VseobecnyAktivnyUkon.Krok 1 z 2 Všeobecný aktívny úkon')}
						onSubmit={this.stepOneClickHandler}
						onDeleteFile={this.onDeleteFile}
						onBackClick={() => history.push(setRouteParams(PREHLAD, get(interakcia, 'opCislo')))}
						onCancelClick={() => history.push(setRouteParams(PREHLAD, get(interakcia, 'opCislo')))}
						schvalovacieKriteria={schvalovacieKriteria}
						validacneKriteria={validacneKriteria}
					/>
				)
				break
			case 2: {
				// check if scenar is define
				const customizedScenarioOption = {
					...scenarioOption,
					scenarios: filter(get(scenarioOption, 'scenarios', []), (scenar) => {
						return (
							!includes(dissallowedScenarios, get(scenar, 'typ')) &&
							isEmpty(get(scenar, 'allowedRoles', []) || checkPermissions(get(auth, 'user.roles', []), get(scenar, 'allowedRoles', [])))
						)
					})
				}

				// if notification is required and not selected yet
				const isDisabledNotification = get(customizedScenarioOption, 'notificationRequired') && !get(notification, 'typ')

				// for scenarios VYTLACIT_A_NAHRAT and PODPISANIE_NA_MIESTE is required signedPDF
				let isDisabledSignedPdf = true
				if (
					(find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.VYTLACIT_A_NAHRAT }) ||
						find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.PODPISANIE_NA_MIESTE })) &&
					get(signedPdf, 'data.dataAsBase64')
				) {
					isDisabledSignedPdf = false
				} else if (
					find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.ODOSLAT_BEZ_PODPISU }) ||
					find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.ODOSLAT_NA_PODPIS })
				) {
					isDisabledSignedPdf = false
				}

				const isDisabledScenarNotSelected = get(customizedScenarioOption, 'scenarios', []).length > get(customizedScenarioOption, 'maxAllowedScenars')
				component = (
					<VseobecnyAktivnyUkonConfirm
						{...this.props}
						formTitle={t('translation:VseobecnyAktivnyUkon.Krok 2 z 2 Všeobecný aktívny úkon')}
						scenarioOption={customizedScenarioOption}
						onSubmit={this.stepTwoClickHandler}
						onBackClick={() => {
							this.setState({ step: 1 })
						}}
						onCancelClick={() => history.push(setRouteParams(PREHLAD, get(interakcia, 'opCislo')))}
						notification={notification}
						isDisabledNotification={isDisabledNotification}
						isDisabledSignedPdf={isDisabledSignedPdf}
						isDisabledScenarNotSelected={isDisabledScenarNotSelected}
						schvalovacieKriteria={schvalovacieKriteria}
						disallowScenarios={this.disallowScenarios}
					/>
				)
				break
			}
			default:
		}

		return (
			<>
				{result && (
					<DefaultModal
						modalTitle={success ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
						modalContent={result}
						leftButton={{
							onClick: this.confirmModal,
							text: t('translation:Common.Zavrieť'),
							color: success ? 'green' : 'red'
						}}
						visible
					/>
				)}
				{component}
				<ProcesnyKonfigurator
					koKriteriaHandler={this.koKriteriaHandler}
					validacneKriteriaHandler={this.validacneKriteriaHandler}
					schvalovacieKriteriaHandler={this.schvalovacieKriteriaHandler}
					formatDataForUkon={this.formatDataForUkon}
					context={context}
				/>
			</>
		)
	}

	disallowScenarios = (scenarios) =>
		this.setState({
			disallowedScenarios: scenarios
		})
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth'),
	tracking: get(state, 'tracking'),
	ukonNovy: get(state, 'ukony.ukonNovy'),
	ciselniky: get(state, 'ciselniky.data'),
	ciselnikySelectBox: get(state, 'ciselnikySelectBox'),
	signedPdf: get(state, 'podpisovanieDokumentov.signedPdf'),
	notification: get(state, 'podpisovanieDokumentov.notification'),
	templatePdf: get(state, 'podpisovanieDokumentov.templatePdf'),
	procesnyKonfigurator: get(state, 'procesnyKonfigurator.procesnyKonfigurator'),
	statuses: get(state, 'statuses.statuses'),
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	ciselnikySelectBoxActions: bindActionCreators(CiselnikySelectBoxActions, dispatch),
	podpisovanieDokumentovActions: bindActionCreators(PodpisovanieDokumentovActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch),
	statusActions: bindActionCreators(StatusActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.UKON_VSEOBECNY_AKTIVNY])
)(VseobecnyAktivnyUkonPage)
