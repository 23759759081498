import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { reduxForm, propTypes, formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import { get, find, upperFirst } from 'lodash'
import { Tooltip } from 'react-tippy'
import { withTranslation } from 'react-i18next'

import validate from './validateForm'

// components
import FormInfo from '../../FormInfo'
import WrapperPodpisovanieDokumentov from '../../PodpisovanieDokumentov/WrapperPodpisovanieDokumentov'

// utils
import { formatFormValue } from '../../../utils/form'
import { FORMS } from '../../../utils/enums'
import { formatDate } from '../../../utils/date'
import { setRouteParams, ROZPISY_ZALOH_ZOZNAM } from '../../../utils/routes'

class RozpisZalohEditConfirm extends React.Component {
	static propTypes = {
		...propTypes,
		submitHandler: PropTypes.func.isRequired,
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.number.isRequired,
				opCislo: PropTypes.string.isRequired
			}).isRequired,
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		t: PropTypes.func
	}

	render() {
		const {
			handleSubmit,
			formValues,
			interakcia,
			originalValues,
			ciselniky,
			t,
			scenarioOption,
			isDisabledNotification,
			isDisabledSignedPdf,
			isDisabledScenarNotSelected,
			podpisovanieDokumentov,
			computedMatch,
			disallowScenarios
		} = this.props

		// formating strings
		const originalValuesNeznizovatString = originalValues.zalohyNeznizovatSumu
			? t('components:RozpisZalohEditConfirm.Áno')
			: t('components:RozpisZalohEditConfirm.Nie')
		const formValueString = formValues.zalohyNeznizovatSumu ? t('components:RozpisZalohEditConfirm.Áno') : t('components:RozpisZalohEditConfirm.Nie')

		const originalValuesSuma = originalValues.zalohySuma ? `${originalValues.zalohySuma} €` : `${formValues.zalohySumaPlaceholder} €`
		const formValueSuma = formValues.zalohySuma ? `${formValues.zalohySuma} €` : `${formValues.zalohySumaPlaceholder} €`

		const formValuesCyklus = find(ciselniky.zalohaCyklus, (el) => el.id == formValues.zalohyCyklus)
		const formValuesDenSplatnostiZaloh = find(ciselniky.zalohaDenSplatnosti, (el) => el.id == formValues.zalohyDenSplatnosti)

		const originalValuesDatumPozadovanejZmeny = formatDate(get(originalValues, 'datumZmeny'), '-', 'DD.MM.YYYY')
		const formValuesDatumPozadovanejZmeny = formatDate(get(formValues, 'datumZmeny'), '-', 'DD.MM.YYYY')

		const ukonVstup = find(get(ciselniky, 'ukonVstup', []), (item) => item.id == formValues.ukonVstup)

		const disabledBtn = (
			<button className='button' type='submit' disabled data-color='blue' style={{ marginLeft: '20px' }}>
				{t('components:RozpisZalohEditConfirm.Dokončiť')}
			</button>
		)

		let submitBtn = null
		if (get(podpisovanieDokumentov, 'templatePdf.isLoading')) {
			submitBtn = (
				<Tooltip
					html={<span>{t('components:RozpisZalohEditConfirm.Prebieha načítavanie dokumentu pre podpisovanie')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (isDisabledScenarNotSelected) {
			submitBtn = (
				<Tooltip
					html={<span>{t('components:RozpisZalohEditConfirm.Pre pokračovanie zvoľte scenár')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (isDisabledSignedPdf) {
			submitBtn = (
				<Tooltip
					html={<span>{t('components:RozpisZalohEditConfirm.Dokument musí byť podpísaný')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledSignedPdf && isDisabledNotification) {
			submitBtn = (
				<Tooltip
					html={<span>{t('components:RozpisZalohEditConfirm.Na dokončenie úkonu je potrebné nastaviť notifikáciu')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledNotification && !isDisabledSignedPdf) {
			submitBtn = (
				<button className='button pull-right' type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
					{t('components:RozpisZalohEditConfirm.Dokončiť')}
				</button>
			)
		}

		return (
			<form onSubmit={handleSubmit((e) => this.props.submitHandler(e))}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{submitBtn}</div>

					<button onClick={() => this.props.setStep(1)} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('components:RozpisZalohEditConfirm.Späť')}
					</button>

					<div className='header-title pull-left'>
						{t('components:RozpisZalohEditConfirm.Krok 2 z 2 Nadviazanie a podpísanie upraveného rozpisu záloh')} (
						{get(computedMatch, 'params.cisloRozpisZaloh')})
					</div>

					<Link
						to={setRouteParams(ROZPISY_ZALOH_ZOZNAM, get(interakcia, 'data.opCislo'))}
						className='button pull-right'
						data-type='outline'
						data-color='red'
					>
						{t('components:RozpisZalohEditConfirm.Zrušiť')}
					</Link>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<FormInfo schvalovacieKriteria={this.props.schvalovacieKriteria} />
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('components:RozpisZalohEditConfirm.Pôvodné hodnoty')}</th>
										<th>{t('components:RozpisZalohEditConfirm.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('components:RozpisZalohEditConfirm.Vstup do úkonu')}</strong>
										</td>
										<td>{get(originalValues, 'ukonVstup.nazov')}</td>
										<td>{formatFormValue(get(formValues, 'ukonVstup'), null, upperFirst(get(ukonVstup, 'nazov')))}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:EditContactConfirm.Splnomocnenec')}</strong>
										</td>
										<td />
										<td>{formatFormValue(get(formValues, 'splnomocnenec'), null)}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:EditContactConfirm.Miesto podpisu úkonu')}</strong>
										</td>
										<td>{get(originalValues, 'podpisMiesto')}</td>
										<td>{formatFormValue(get(formValues, 'podpisMiesto'), get(originalValues, 'podpisMiesto'))}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:RozpisZalohEditConfirm.Dátum prijatia žiadosti')}</strong>
										</td>
										<td />
										<td>
											<strong>{formatDate(get(formValues, 'datumPrijatiaZiadosti'), null, 'DD.MM.YYYY')}</strong>
										</td>
									</tr>
								</tbody>
							</table>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('components:RozpisZalohEditConfirm.Cyklus záloh')}</strong>
											</td>
											<td> {get(originalValues, 'zalohyCyklus.label', '-')} </td>
											<td>
												{get(originalValues, 'zalohyCyklus.value') == get(formValuesCyklus, 'id') &&
													get(formValuesCyklus, 'nazov', '-')}
												{get(originalValues, 'zalohyCyklus.value') != get(formValuesCyklus, 'id') && (
													<strong>{get(formValuesCyklus, 'nazov', '-')}</strong>
												)}
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('components:RozpisZalohEditConfirm.Výška záloh')}</strong>
											</td>
											<td> {originalValuesSuma} </td>
											<td>
												{originalValues.zalohySuma == (formValues.zalohySuma || formValues.zalohySumaPlaceholder) && formValueSuma}
												{originalValues.zalohySuma != (formValues.zalohySuma || formValues.zalohySumaPlaceholder) && (
													<strong>{formValueSuma}</strong>
												)}
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('components:RozpisZalohEditConfirm.Neznižovať výšku záloh')}</strong>
											</td>
											<td> {originalValuesNeznizovatString} </td>
											<td>
												{originalValues.zalohyNeznizovatSumu == formValues.zalohyNeznizovatSumu && formValueString}
												{originalValues.zalohyNeznizovatSumu != formValues.zalohyNeznizovatSumu && <strong>{formValueString}</strong>}
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('components:RozpisZalohEditConfirm.Deň splatnosti záloh')}</strong>
											</td>
											<td> {get(originalValues, 'zalohyDenSplatnosti.label', '-')} </td>
											<td>
												{get(originalValues, 'zalohyDenSplatnosti.value') == get(formValuesDenSplatnostiZaloh, 'id') &&
													get(formValuesDenSplatnostiZaloh, 'nazov', '-')}
												{get(originalValues, 'zalohyDenSplatnosti.value') != get(formValuesDenSplatnostiZaloh, 'id') && (
													<strong>{get(formValuesDenSplatnostiZaloh, 'nazov', '-')}</strong>
												)}
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('components:RozpisZalohEditConfirm.Dátum požadovanej zmeny')}</strong>
											</td>
											<td> {originalValuesDatumPozadovanejZmeny} </td>
											<td>
												{originalValuesDatumPozadovanejZmeny == formValuesDatumPozadovanejZmeny && formValuesDatumPozadovanejZmeny}
												{originalValuesDatumPozadovanejZmeny != formValuesDatumPozadovanejZmeny && (
													<strong>{formValuesDatumPozadovanejZmeny}</strong>
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('components:RozpisZalohEditConfirm.Poznámka')}</strong>
										</td>
										<td />
										<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
											<div className='input-wrapper'>
												<textarea
													disabled='disabled'
													rows='5'
													className='form-control static'
													style={{ backgroundColor: 'white' }}
													value={get(formValues, 'poznamka')}
												/>
											</div>
										</td>
									</tr>

									{/* <tr>
										<td>
											<strong>{t('components:RozpisZalohEditConfirm.Dokumenty')}</strong>
										</td>
										<td>
											-
										</td>
										<td>
											- <a className="file" href="/" target="_blank" data-type="general">
												priloha-planu-splatok-12-2-2018.pdf
											</a>
										</td>
									</tr> */}
								</tbody>
							</table>

							{/* <div className="clearfix">
								<div id="connect-to-act" className="button" data-type="outline" data-color="blue">Nadviazať na minulý úkon</div>
							</div>

							<div className="info-banner" data-color="green">
								{'Nadväzujúci úkon k "Zmena údajov Obchodného partnera a Zmluvného účtu" zo dňa 6.2.2018'}
							</div>

							<div className="inner-box">
								<h4>{locale['components.RozpisZalohEditConfirm.label.vystupneDokumenty']}</h4>
								<a className="file" href="/" target="_blank" data-type="general">
									Žiadosť o zmenu rozpisu záloh (24343940349034).pdf
								</a>
							</div> */}

							<WrapperPodpisovanieDokumentov scenarioOption={scenarioOption} disallowScenarios={disallowScenarios} />
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.ROZPISY_ZALOH,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate
})(RozpisZalohEditConfirm)

const selector = formValueSelector(FORMS.ROZPISY_ZALOH)

const mapStateToProps = (state) => {
	return {
		interakcia: state.interakcie.detail,
		ciselniky: state.ciselniky.data,
		podpisovanieDokumentov: state.podpisovanieDokumentov,
		formValues: {
			zalohySuma: selector(state, 'zalohySuma'),
			zalohySumaPlaceholder: selector(state, 'zalohySumaPlaceholder'),
			zalohyCyklus: selector(state, 'zalohyCyklus'),
			zalohyNeznizovatSumu: selector(state, 'zalohyNeznizovatSumu'),
			zalohyDenSplatnosti: selector(state, 'zalohyDenSplatnosti'),
			poznamka: selector(state, 'poznamka'),
			datumZmeny: selector(state, 'datumZmeny'),
			datumPrijatiaZiadosti: selector(state, 'datumPrijatiaZiadosti'),
			ukonVstup: selector(state, 'ukonVstup'),
			podpisMiesto: selector(state, 'podpisMiesto'),
			splnomocnenec: selector(state, 'splnomocnenec')
		}
	}
}

export default withTranslation('components')(connect(mapStateToProps, null)(form))
