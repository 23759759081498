import React from 'react'
import { UnmountClosed } from 'react-collapse'
import cx from 'classnames'
import { Tooltip } from 'react-tippy'
import PropTypes from 'prop-types'

// components
import { isEmpty } from 'lodash'
import SuhlasCollapsibleItem from './SuhlasCollapsibleItem'

class SuhlasKategoriaCollapsibleItem extends React.Component {
	static propTypes = {
		suhlasKey: PropTypes.string.isRequired,
		kategorizovaneSuhlasy: PropTypes.array.isRequired,
		categoryHelp: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		this.state = {
			isSuhlasKategoriaOpen: false
		}
	}

	onOpenSuhlasyList = () => {
		this.setState({
			isSuhlasKategoriaOpen: !this.state.isSuhlasKategoriaOpen
		})
	}

	render() {
		const { categoryHelp } = this.props

		return (
			<div className='box collapsible'>
				<div className='consent-category box-header clearfix' style={{ fontWeight: 'normal', padding: '0' }}>
					<table className='content-table' style={{ padding: '0' }}>
						<tbody>
							<tr>
								<td style={{ padding: '15px' }} onClick={this.onOpenSuhlasyList}>
									<strong>{this.props.suhlasKey}</strong>
									{!isEmpty(categoryHelp) && (
										<Tooltip key='info' html={categoryHelp?.content} position='top' theme='light'>
											<div className='info-icon' />
										</Tooltip>
									)}
								</td>
								<td style={{ width: '55px', padding: '15px' }} onClick={this.onOpenSuhlasyList}>
									<div
										className={cx('button', 'noselect', 'arrow', { opened: this.state.isSuhlasKategoriaOpen })}
										data-type='icon-button'
										data-icon='arrow'
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className={'box-content'} style={{ height: 'auto', overflow: 'inherit' }}>
					<div className='consent-category-content'>
						<UnmountClosed isOpened={this.state.isSuhlasKategoriaOpen} hasNestedCollapse>
							{this.props.kategorizovaneSuhlasy.map((suhlas) => {
								return <SuhlasCollapsibleItem key={suhlas.id} suhlas={suhlas} />
							})}
						</UnmountClosed>
					</div>
				</div>
			</div>
		)
	}
}

export default SuhlasKategoriaCollapsibleItem
