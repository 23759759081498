import React from 'react'
import { withTranslation } from 'react-i18next'
import { isEmpty, get, map, size, includes, head } from 'lodash'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'
import cx from 'classnames'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, FieldArray, propTypes } from 'redux-form'

// atoms
import ReasonSelector from '../../../../atoms/generics/ReasonSelector'
import TextInputField from '../../../../atoms/TextInputField'

// utils
import { createSuhlasDTO, formatOriginalSuhlas, formatSuhlas, isValidSuhlas } from '../../../../utils/suhlas'
import { DEFINICIA_TYP, SUHLAS_HODNOTA } from '../../../../utils/enums'
import { EDIT_MODE, COLUMNS_COUNT } from '../../../../containers/GenericUkon/attributesConfig'

// resources
import addCrossIcon from '../../../../resources/img/icons/add-cross.svg'

class EfakturaEmailsField extends React.Component {
	static propTypes = {
		...propTypes,
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		t: PropTypes.shape(),
		originalValues: PropTypes.shape(),
		formValues: PropTypes.shape(),
		onChangeSuhlas: PropTypes.func,
		wrapIntoContainer: PropTypes.bool,
		onDeactivate: PropTypes.func,
		obchodnyPartner: PropTypes.shape().isRequired,
		nesuhlasyZu: PropTypes.shape(),
		nesuhlasyDefinicie: PropTypes.shape(),
		cesCiselniky: PropTypes.shape(),
		validate: PropTypes.func
	}

	constructor(props) {
		super(props)
	}

	componentDidUpdate(prevProps) {
		const { formValues, onChangeSuhlas } = this.props

		const originalEmails = get(prevProps, 'formValues.eFakturaEmaily', [])
		const newEmails = get(formValues, 'eFakturaEmaily', [])

		if (isEmpty(originalEmails) && !isEmpty(newEmails)) {
			onChangeSuhlas(createSuhlasDTO(SUHLAS_HODNOTA.SUHLAS, null))
		}
	}

	renderEFaktury = ({ fields }) => {
		const {
			/* obchodnyPartner, */
			onDeactivate,
			t
		} = this.props

		return (
			<>
				{fields.map(this.renderSubFields)}
				{size(fields) < 4 && (
					<button
						className='button small noselect'
						type='button'
						data-type='outline'
						data-color='blue'
						onClick={() => {
							// NOTE: CP-2145
							/* if (size(fields) == 0 && get(obchodnyPartner, 'kontaktnyEmail')) {
							fields.push({
								email: get(obchodnyPartner, 'kontaktnyEmail')
							})
						}
						else { */
							// CP-2489 - Add pdf true
							fields.push({ pdf: true })
							/* } */
						}}
					>
						{t('translation:ZmluvneUcty.Pridať email')}
					</button>
				)}
				{size(fields) > 0 && (
					<button className='button small noselect pull-right' type='button' data-type='outline' data-color='red' onClick={onDeactivate}>
						{t('translation:ZmluvneUcty.Deaktivovať e-faktúru')}
					</button>
				)}
			</>
		)
	}

	renderSubFields = (parent, index, fields) => {
		const { isDeactiveRoute, validate, t } = this.props

		return (
			<div key={index} style={{ position: 'relative', marginBottom: '10px' }}>
				{!isDeactiveRoute && size(fields) > 1 && <img className='minus-field-icon' onClick={() => fields.remove(index)} src={addCrossIcon} />}
				<div style={{ width: !isDeactiveRoute && size(fields) > 1 ? 'calc(100% - 30px)' : '100%' }}>
					<Field
						name={`${parent}.email`}
						component={TextInputField}
						placeholder={t('translation:ZmluvneUcty.Zadajte kontaktný email')}
						validate={validate}
					/>
				</div>
			</div>
		)
	}

	render() {
		const {
			field,
			editMode,
			columnsCount,
			originalValues,
			formValues,
			onChangeSuhlas,
			wrapIntoContainer = true,
			t,
			nesuhlasyZu,
			nesuhlasyDefinicie,
			cesCiselniky
		} = this.props

		let valueBefore
		let valueAfter

		const originalEmails = get(originalValues, 'eFakturaEmaily', [])
		const newEmails = get(formValues, 'eFakturaEmaily', [])
		const originalSuhlas = get(originalValues, 'suhlasEfaktura')
		const newSuhlas = get(formValues, 'suhlasEfaktura')

		const originalNonPreferredValue = isEmpty(originalEmails)
		const newNonPreferredValue = isEmpty(newEmails)

		const nesuhlasyEfakturaEmialsField = nesuhlasyDefinicie?.[DEFINICIA_TYP.E_FAKTURA]?.nesulasy

		const nesuhlasy =
			nesuhlasyEfakturaEmialsField ||
			head(nesuhlasyZu.data?.response?.definicie?.filter((definicia) => definicia.id === DEFINICIA_TYP.E_FAKTURA))?.nesulasy

		const reasonOptions = map(nesuhlasy, (nesuhlas) => {
			return {
				value: nesuhlas.id,
				label: nesuhlas.dovod
			}
		})

		if (editMode == EDIT_MODE.EDIT) {
			valueBefore = (
				<td style={{ position: 'relative' }}>
					{!isEmpty(originalEmails)
						? map(originalEmails, (item, index) => <p key={index}>{get(item, 'email')}</p>)
						: t('translation:Common.neaktívna')}
					{originalNonPreferredValue && (
						<Tooltip html={<span>{t('translation:Common.Nedoporučené nastavenie')}</span>} position='bottom' trigger='mouseenter' theme='light'>
							<div className='info-icon' />
						</Tooltip>
					)}
					<p>{formatOriginalSuhlas(originalNonPreferredValue, originalSuhlas, nesuhlasy)}</p>
				</td>
			)

			let reasonDovod = null
			if (newSuhlas && newSuhlas.hodnota == SUHLAS_HODNOTA.NAMIETKA && isValidSuhlas(newSuhlas)) {
				if (newSuhlas.nesuhlasDovodIny) {
					reasonDovod = newSuhlas.nesuhlasDovodIny
				} else if (newSuhlas.nesuhlasDovod && newSuhlas.nesuhlasDovod.id) {
					reasonDovod = newSuhlas.nesuhlasDovod.id
				}
			}

			valueAfter = (
				<td>
					<FieldArray name={field} component={this.renderEFaktury} />
					{newNonPreferredValue && (
						<div style={{ marginTop: '5px' }}>
							<ReasonSelector
								selectOptions={reasonOptions}
								onChange={onChangeSuhlas}
								value={reasonDovod}
								reasonIsNotActual={newSuhlas && !isValidSuhlas(newSuhlas)}
							/>
						</div>
					)}
				</td>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			const eFakturaEmailyOriginal = map(originalEmails, (item, index) => <p key={index}>{get(item, 'email')}</p>)
			const eFakturaEmailyOriginalValues = map(originalEmails, (item) => get(item, 'email'))
			const eFakturaEmaily = map(newEmails, (item, index) => {
				const email = get(item, 'email')
				if (!includes(eFakturaEmailyOriginalValues, email)) {
					return (
						<p key={index}>
							<strong>{email}</strong>
						</p>
					)
				}
				return <p key={index}>{email}</p>
			})

			let eFakturaEmailySection = eFakturaEmaily
			if (isEmpty(eFakturaEmaily)) {
				if (!isEmpty(eFakturaEmailyOriginalValues)) {
					eFakturaEmailySection = (
						<>
							<strong>{t('translation:Common.neaktívna')}</strong>
							<p>{newNonPreferredValue && formatSuhlas(newNonPreferredValue, newSuhlas, originalSuhlas, cesCiselniky?.nesuhlasDovod)}</p>
						</>
					)
				} else {
					eFakturaEmailySection = (
						<>
							{t('translation:Common.neaktívna')}
							<p>{newNonPreferredValue && formatSuhlas(newNonPreferredValue, newSuhlas, originalSuhlas, cesCiselniky?.nesuhlasDovod)}</p>
						</>
					)
				}
			}

			valueBefore = (
				<td>
					{!isEmpty(eFakturaEmailyOriginal) ? eFakturaEmailyOriginal : t('translation:Common.neaktívna')}
					<p>{formatOriginalSuhlas(originalNonPreferredValue, originalSuhlas)}</p>
				</td>
			)

			valueAfter = (
				<td style={{ position: 'relative' }}>
					{eFakturaEmailySection}
					{newNonPreferredValue && (
						<Tooltip html={<span>{t('translation:Common.Nedoporučené nastavenie')}</span>} position='bottom' trigger='mouseenter' theme='light'>
							<div className='info-icon' />
						</Tooltip>
					)}
				</td>
			)
		}

		const content = (
			<tr>
				<td>
					<strong>{t('translation:ZmluvneUcty.E-mailové adresy pre e-faktúry')}</strong>
				</td>
				{columnsCount == COLUMNS_COUNT.THREE && <>{valueBefore}</>}
				<>{valueAfter}</>
			</tr>
		)

		return wrapIntoContainer ? (
			<div className={cx('inner-box', { warning: isEmpty(newEmails) })}>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>{content}</tbody>
				</table>
			</div>
		) : (
			content
		)
	}
}

const mapStateToProps = (state) => ({
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data'),
	nesuhlasyZu: get(state, 'ukonEditZU.nesuhlasyZu'),
	cesCiselniky: get(state, 'cesCiselniky.data')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(EfakturaEmailsField)
