export const ODBERNE_MIESTA_LOAD_START = 'ODBERNE_MIESTA_LOAD_START'
export const ODBERNE_MIESTA_LOAD_PART_DONE = 'ODBERNE_MIESTA_LOAD_PART_DONE'
export const ODBERNE_MIESTA_LOAD_DONE = 'ODBERNE_MIESTA_LOAD_DONE'
export const ODBERNE_MIESTA_LOAD_FAIL = 'ODBERNE_MIESTA_LOAD_FAIL'

export const ODBERNE_MIESTO_LOAD_START = 'ODBERNE_MIESTO_LOAD_START'
export const ODBERNE_MIESTO_LOAD_DONE = 'ODBERNE_MIESTO_LOAD_DONE'
export const ODBERNE_MIESTO_LOAD_FAIL = 'ODBERNE_MIESTO_LOAD_FAIL'
export const ODBERNE_MIESTO_DETAIL_CLEAR = 'ODBERNE_MIESTO_DETAIL_CLEAR'
export const ODBERNE_MIESTO_LIST_CLEAR = 'ODBERNE_MIESTO_LIST_CLEAR'

export const ODBERNE_MIESTA_SEARCH_START = 'ODBERNE_MIESTA_SEARCH_START'
export const ODBERNE_MIESTA_SEARCH_DONE = 'ODBERNE_MIESTA_SEARCH_DONE'
export const ODBERNE_MIESTA_SEARCH_FAIL = 'ODBERNE_MIESTA_SEARCH_FAIL'
export const ODBERNE_MIESTA_SEARCH_CLEAR = 'ODBERNE_MIESTA_SEARCH_CLEAR'

export const ODBERNE_MIESTO_LOAD_DETAIL_START = 'ODBERNE_MIESTO_LOAD_DETAIL_START'
export const ODBERNE_MIESTO_LOAD_DETAIL_DONE = 'ODBERNE_MIESTO_LOAD_DETAIL_DONE'
export const ODBERNE_MIESTO_LOAD_DETAIL_FAIL = 'ODBERNE_MIESTO_LOAD_DETAIL_FAIL'
