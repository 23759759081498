import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get, isEmpty, includes, map, upperFirst, find, uniqBy, head } from 'lodash'
import { withTranslation } from 'react-i18next'
import { compose, bindActionCreators } from 'redux'

// actions
import * as TrackingActions from '../../actions/TrackingActions'

// components
import Modal from './Modal'
import ElementLoading from '../ElementLoading'

// atoms
import Select from '../../atoms/BasicSelect'
import Input from '../../atoms/Input'
import AddressCustomField from '../../atoms/AddressField/AddressCustomField'
import TextareaField from '../../atoms/TextareaField'

// resources
import crossIcon from '../../resources/img/icons/cross-black.svg'

// utils
import { NOTIFICATION_TYPES } from '../../utils/enums'
import { getUkonVstupByKanal } from '../../utils/scenar'
import { formatAddress } from '../../utils/address'
import { getReq } from '../../utils/request'
import { getAccessToken } from '../../utils/auth'
import config from '../../utils/config'
import { containsDiacritics, isEmail } from '../../utils/email'

const CUSTOM = {
	EMAIL: 'EMAIL',
	ADDRESS: -1
}

class ModalSendItemDuplicate extends React.Component {
	static propTypes = {
		modalTitle: PropTypes.string,
		onCloseButton: PropTypes.func.isRequired,
		onSubmit: PropTypes.func.isRequired,
		obchodnyPartner: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		documentUrl: PropTypes.string,
		fileName: PropTypes.string,
		notificationTypes: PropTypes.array,
		ciselniky: PropTypes.shape(),
		auth: PropTypes.shape(),
		trackingActions: PropTypes.func.isRequired,
		selectedUpomienka: PropTypes.shape(),
		zuCislo: PropTypes.string,
		dokumentId: PropTypes.string,
		dokumentName: PropTypes.string
	}

	_mounted = false

	constructor(props) {
		super(props)

		const { auth, ciselniky } = props

		const ukonVstupOptions = map(get(ciselniky, 'data.ukonVstup', []), (ukonVstup) => ({
			label: upperFirst(get(ukonVstup, 'nazov')),
			value: get(ukonVstup, 'id')
		}))
		const ukonVstupId = getUkonVstupByKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'data.ukonVstup'))
		const ukonVstupValue = find(ukonVstupOptions, (ukonVstup) => get(ukonVstup, 'value') == ukonVstupId)

		this.state = {
			zmluvnyUcet: {
				data: null,
				isLoading: false,
				isFailure: false
			},
			odoslanieTyp: null,
			selectedAddress: null,
			selectedEmail: null,
			doRuk: null,
			customEmail: {
				value: '',
				error: null
			},
			customAddress: null,
			ukonVstup: {
				value: ukonVstupValue,
				error: !ukonVstupValue
			},
			poznamka: {
				value: '',
				error: null
			},
			emails: [],
			addresses: [],
			isProcessing: false
		}
	}

	componentWillUnmount() {
		this._mounted = false

		const { trackingActions } = this.props
		trackingActions.clearTracking()
	}

	loadZmluvnyUcet = async () => {
		try {
			const { interakcia, zuCislo } = this.props

			if (this._mounted) {
				this.setState({
					zmluvnyUcet: {
						data: null,
						isFailure: false,
						isLoading: true
					}
				})
			}

			const zmluvnyUcetDetail = await getReq(`/api/v0/obchodni-partneri/${get(interakcia, 'opCislo')}/zmluvne-ucty/${zuCislo}`)

			const state = {
				zmluvnyUcet: {
					isFailure: false,
					isLoading: false,
					data: get(zmluvnyUcetDetail, 'response.obsah')
				}
			}

			const { emails } = this.state
			const eFakturaEmaily = get(zmluvnyUcetDetail, 'response.obsah.eFakturaEmaily', [])
			if (!isEmpty(eFakturaEmaily)) {
				state.emails = uniqBy(
					[
						...map(eFakturaEmaily, (email) => {
							return {
								value: get(email, 'email'),
								label: get(email, 'email')
							}
						}),
						...emails
					],
					'value'
				)
				state.selectedEmail = {
					value: get(head(eFakturaEmaily), 'email'),
					label: get(head(eFakturaEmaily), 'email')
				}
			}
			if (this._mounted) {
				this.setState(state)
			}
		} catch (e) {
			if (this._mounted) {
				this.setState({
					zmluvnyUcet: {
						isFailure: true,
						isLoading: false,
						data: null
					}
				})
			}
		}
	}

	componentDidMount() {
		this._mounted = true

		const { ukonTyp, zuCislo, notificationTypes, obchodnyPartner, trackingActions, t } = this.props

		trackingActions.tryToStartTracking(ukonTyp)

		const emails = get(obchodnyPartner, 'kontaktnyEmail')
			? [
					{
						value: get(obchodnyPartner, 'kontaktnyEmail'),
						label: get(obchodnyPartner, 'kontaktnyEmail')
					},
					{
						value: CUSTOM.EMAIL,
						label: t('translation:ModalSendItemDuplicate.Zvoliť iný email')
					}
			  ]
			: [
					{
						value: CUSTOM.EMAIL,
						label: t('translation:ModalSendItemDuplicate.Zvoliť iný email')
					}
			  ]

		const addresses = get(obchodnyPartner, 'adresy', [])

		let odoslanieTyp = null
		if (includes(notificationTypes, NOTIFICATION_TYPES.EMAIL)) {
			odoslanieTyp = NOTIFICATION_TYPES.EMAIL
		} else if (includes(notificationTypes, NOTIFICATION_TYPES.ADDRESS)) {
			odoslanieTyp = NOTIFICATION_TYPES.ADDRESS
		} else if (includes(notificationTypes, NOTIFICATION_TYPES.PRINTER)) {
			odoslanieTyp = NOTIFICATION_TYPES.PRINTER
		}

		if (this._mounted) {
			if (zuCislo) {
				this.loadZmluvnyUcet()
			}
			this.setState({
				odoslanieTyp,
				addresses,
				emails,
				selectedEmail: head(emails)
			})
		}
	}

	handleSubmit = () => {
		const { t } = this.props
		const { selectedAddress, odoslanieTyp, ukonVstup, selectedEmail, customEmail, customAddress, doRuk, poznamka, isProcessing } = this.state

		if (isProcessing) {
			return
		}
		this.setState({
			isProcessing: true
		})

		let email = null
		let address = null
		if (get(selectedEmail, 'value') === CUSTOM.EMAIL) {
			if (!get(customEmail, 'value')) {
				return this.setState({
					customEmail: {
						error: t('translation:ModalSendItemDuplicate.Emailová adresa je povinná')
					},
					isProcessing: false
				})
			}
			if (!isEmail(get(customEmail, 'value'))) {
				return this.setState({
					customEmail: {
						error: t('translation:ModalSendItemDuplicate.Neplatná emailová adresa')
					},
					isProcessing: false
				})
			}
			if (containsDiacritics(get(customEmail, 'value'))) {
				return this.setState({
					customEmail: {
						error: t('translation:ModalSendItemDuplicate.Emailová adresa nesmie obsahovať diakritiku')
					},
					isProcessing: false
				})
			}

			email = get(customEmail, 'value')
		} else {
			email = selectedEmail ? get(selectedEmail, 'value') : null
		}

		if (get(selectedAddress, 'value') === CUSTOM.ADDRESS) {
			if (!get(customAddress, 'value') || !isEmpty(get(customAddress, 'errors'))) {
				return
			}
			address = {
				...get(customAddress, 'value'),
				doRukMeno: doRuk
			}
		} else {
			address = selectedAddress
				? {
						...get(selectedAddress, 'addressDTO'),
						doRukMeno: doRuk
				  }
				: null
		}

		if (get(ukonVstup, 'error')) {
			return
		}

		this.props.onSubmit({
			typ: odoslanieTyp,
			ukonVstup,
			email,
			address,
			poznamka
		})
	}

	handleAddressChange = (addressOption) => {
		this.setState({
			selectedAddress: addressOption,
			selectedEmail: null
		})
	}

	handleEmailChange = (newValue) => {
		this.setState({
			selectedEmail: newValue,
			doRuk: null,
			selectedAddress: null
		})
	}

	handleCustomEmail = (e) => {
		this.setState({
			customEmail: {
				value: get(e, 'currentTarget.value')
			},
			doRuk: null,
			selectedAddress: null
		})
	}

	handleDoRukChange = (e) => {
		this.setState({
			doRuk: get(e, 'currentTarget.value'),
			selectedEmail: null
		})
	}

	changeType = (type) => {
		if (type == NOTIFICATION_TYPES.ADDRESS) {
			const { addresses } = this.state
			const { obchodnyPartner } = this.props

			// check if OP has adresa korespondencna
			const adresaKorespondencna = find(addresses, {
				id: get(obchodnyPartner, 'adresaKorespondencna.id')
			})

			let selectedAddress = null

			if (adresaKorespondencna) {
				// set adresa korespondencna as default selected address
				selectedAddress = {
					addressDTO: adresaKorespondencna,
					value: adresaKorespondencna.id,
					label: formatAddress(adresaKorespondencna)
				}
			} else {
				// if OP does not have adresa korespondenca check if has adresa zakaznika
				const adresaZakaznika = find(addresses, {
					id: get(obchodnyPartner, 'adresaZakaznika.id')
				})

				if (adresaZakaznika) {
					selectedAddress = {
						addressDTO: adresaZakaznika,
						value: adresaZakaznika.id,
						label: formatAddress(adresaZakaznika)
					}
				} else {
					// if OP does not have adresa korespondenca and adresa zakaznika check if has any adress and pick the first one otherwise set null
					selectedAddress = !isEmpty(addresses)
						? {
								addressDTO: addresses[0],
								value: addresses[0].id,
								label: formatAddress(addresses[0])
						  }
						: null
				}
			}

			this.setState({
				odoslanieTyp: type,
				selectedEmail: null,
				customEmail: {
					value: '',
					error: null
				},
				selectedAddress
			})
		}

		if (type == NOTIFICATION_TYPES.EMAIL) {
			const { t } = this.props

			this.setState({
				odoslanieTyp: type,
				selectedAddress: null,
				doRuk: null,
				customAddress: null,
				selectedEmail: this.state.emails.length
					? this.state.emails[0]
					: {
							value: CUSTOM.EMAIL,
							label: t('translation:ModalSendItemDuplicate.Zvoliť iný email')
					  }
			})
		}

		if (type == NOTIFICATION_TYPES.PRINTER) {
			this.setState({
				odoslanieTyp: type,
				selectedAddress: null,
				doRuk: null,
				customAddress: null,
				customEmail: {
					value: '',
					error: null
				},
				selectedEmail: null
			})
		}
	}

	handleCreateEmail = (inputValue) => {
		const { emails } = this.state

		const newOption = {
			value: inputValue,
			label: inputValue
		}
		this.setState({
			emails: [...emails, newOption],
			selectedEmail: newOption
		})
	}

	handleNewAddress = (address) => {
		this.setState({
			customAddress: {
				value: get(address, 'editAddress'),
				errors: get(address, 'errors')
			}
		})
	}

	onChangeUkonVstup = (value) => {
		this.setState({
			ukonVstup: {
				value,
				error: !value
			}
		})
	}

	onChangePoznamka = (e) => {
		this.setState({
			poznamka: {
				value: get(e, 'target.value'),
				error: false
			}
		})
	}

	render() {
		const { modalTitle, notificationTypes, ciselniky, obchodnyPartner, zuCislo, dokumentId, dokumentName, t, documentUrl } = this.props
		const { selectedAddress, selectedEmail, emails, odoslanieTyp, customEmail, customAddress, ukonVstup, zmluvnyUcet, poznamka } = this.state

		const accessToken = getAccessToken()

		const ukonVstupOptions = map(get(ciselniky, 'data.ukonVstup', []), (ukonVstup) => ({
			label: upperFirst(get(ukonVstup, 'nazov')),
			value: get(ukonVstup, 'id')
		}))

		const column = notificationTypes.length > 0 ? `col-${12 / notificationTypes.length}` : `col-12`

		let content = null

		if (zuCislo && !get(zmluvnyUcet, 'isFailure') && !get(zmluvnyUcet, 'data')) {
			content = (
				<div style={{ minHeight: '100px' }}>
					<ElementLoading />
				</div>
			)
		} else if (includes(notificationTypes, NOTIFICATION_TYPES.ADDRESS) && odoslanieTyp == NOTIFICATION_TYPES.ADDRESS) {
			let warningDiffAddress
			if (get(zmluvnyUcet, 'data') && get(zmluvnyUcet, 'data.adresaFakturacie.id') !== get(selectedAddress, 'value')) {
				warningDiffAddress = (
					<div className='text-warn' style={{ paddingBottom: '20px' }}>
						{t(
							'translation:ModalSendItemDuplicate.Zadávate inú adresu ako je definovaná na úrovni zmluvného účtu Preverte so zákazníkom aktuálnosť korešpodenčných adries na úrovni zmluvného účtu a obchodného partnera'
						)}
					</div>
				)
			}

			const addresses = get(obchodnyPartner, 'adresy', [])
			content = (
				<>
					<AddressCustomField
						addresses={addresses}
						label={t('translation:Common.Adresa')}
						value={selectedAddress}
						isClearable={false}
						newAddress={get(selectedAddress, 'value') === CUSTOM.ADDRESS}
						onChange={(addressOption) =>
							this.setState({
								selectedAddress: addressOption
							})
						}
						handleNewAddress={this.handleNewAddress}
						error={!selectedAddress && t('translation:Common.Toto pole je povinné')}
					/>
					{warningDiffAddress}
					<Input
						style={{ height: '38px' }}
						showLabel
						onChange={this.handleDoRukChange}
						label={t('translation:Common.Do rúk')}
						placeholder={t('translation:ModalSendItemDuplicate.Zadajte meno doručovateľa')}
					/>
				</>
			)
		} else if (includes(notificationTypes, NOTIFICATION_TYPES.EMAIL) && odoslanieTyp == NOTIFICATION_TYPES.EMAIL) {
			content = (
				<>
					{emails.length > 0 && (
						<Select
							label={t('translation:Common.Email')}
							onChange={this.handleEmailChange}
							options={emails}
							value={selectedEmail}
							isClearable={false}
							onCreateOption={this.handleCreateEmail}
							placeholder={t('translation:ModalSendItemDuplicate.Prosím vyberte emailovú adresu')}
							noOptionsMessage={() =>
								`${t('translation:ModalSendItemDuplicate.Filtru nevyhovuje žiadna emailová adresa')}. ${t(
									'translation:ModalSendItemDuplicate.Prosím napíšte validnú emailovú adresu pre vytvorenie'
								)}`
							}
						/>
					)}
					{get(selectedEmail, 'value') === CUSTOM.EMAIL && (
						<Input
							onChange={this.handleCustomEmail}
							label={t('translation:ModalSendItemDuplicate.Zadajte emailovú adresu')}
							placeholder='Email'
							error={get(customEmail, 'error')}
							showLabel
							required
						/>
					)}
				</>
			)
		} else if (includes(notificationTypes, NOTIFICATION_TYPES.PRINTER) && odoslanieTyp == NOTIFICATION_TYPES.PRINTER) {
			const href = documentUrl || `${get(config, 'baseUrl')}/api/v0/dokumenty/${dokumentId}?accessToken=${accessToken}`
			content = (
				<div style={{ cursor: 'pointer', paddingBottom: '12px' }}>
					<a className='file' href={href} target='_blank' rel='noopener noreferrer' data-type='general' style={{ cursor: 'pointer' }}>
						{dokumentName}
					</a>
				</div>
			)
		}
		return (
			<Modal shown size='s'>
				{modalTitle && (
					<div className='modal-header'>
						<h3>{modalTitle}</h3>
						<div className='close' onClick={() => this.props.onCloseButton()}>
							<img src={crossIcon} width='25' />
						</div>
					</div>
				)}
				<div className='modal-content'>
					<div className='row' style={{ paddingBottom: '20px' }}>
						{includes(notificationTypes, NOTIFICATION_TYPES.ADDRESS) && (
							<div className={column}>
								<button
									className='button'
									type='button'
									onClick={() => this.changeType(NOTIFICATION_TYPES.ADDRESS)}
									data-color='blue'
									data-type={odoslanieTyp != NOTIFICATION_TYPES.ADDRESS ? 'outline' : ''}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								>
									{t('translation:Common.Poštou')}
								</button>
							</div>
						)}
						{includes(notificationTypes, NOTIFICATION_TYPES.EMAIL) && (
							<div className={column}>
								<button
									className='button'
									type='button'
									onClick={() => this.changeType(NOTIFICATION_TYPES.EMAIL)}
									data-color='blue'
									data-type={odoslanieTyp != NOTIFICATION_TYPES.EMAIL ? 'outline' : ''}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								>
									{t('translation:Common.Emailom')}
								</button>
							</div>
						)}
						{includes(notificationTypes, NOTIFICATION_TYPES.PRINTER) && (
							<div className={column}>
								<button
									className='button'
									type='button'
									onClick={() => this.changeType(NOTIFICATION_TYPES.PRINTER)}
									data-color='blue'
									data-type={odoslanieTyp != NOTIFICATION_TYPES.PRINTER ? 'outline' : ''}
									style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
								>
									{t('translation:Common.Tlač')}
								</button>
							</div>
						)}
					</div>
					<div className='row'>
						<div className='col-12'>{content}</div>
						<div className='col-12' style={{ paddingBottom: 0 }}>
							<Select
								label={t('translation:Common.Vstup do úkonu')}
								value={ukonVstup.value}
								onChange={this.onChangeUkonVstup}
								options={ukonVstupOptions}
								classNamePrefix='react-select'
								isDisabled={!get(ciselniky, 'data.ukonVstup')}
								isLoading={get(ciselniky, 'isLoading')}
								error={get(ukonVstup, 'error') && t('translation:Common.Toto pole je povinné')}
								placeholder={t('translation:Common.Zvoľte vstup do úkonu')}
							/>
						</div>
						<div className='col-12' style={{ paddingBottom: 0 }}>
							<TextareaField
								name='poznamka'
								showLabel
								label={t('translation:Common.Poznámka')}
								placeholder={t('translation:Common.Zadajte poznámku')}
								input={{
									value: get(poznamka, 'value'),
									onChange: this.onChangePoznamka
								}}
								meta={{
									touched: null,
									error: null
								}}
							/>
						</div>
					</div>
				</div>
				<div className='modal-footer clearfix'>
					<button
						type='button'
						className='button'
						onClick={this.handleSubmit}
						data-color='blue'
						disabled={
							(odoslanieTyp == NOTIFICATION_TYPES.EMAIL && !selectedEmail) ||
							(odoslanieTyp == NOTIFICATION_TYPES.ADDRESS && !get(customAddress, 'value') && !selectedAddress) ||
							(get(selectedAddress, 'value') == CUSTOM.ADDRESS && !isEmpty(get(customAddress, 'errors'))) ||
							get(ukonVstup, 'error')
						}
						style={{ width: '100%', marginLeft: 0 }}
					>
						{t('translation:Common.Odoslať')}
					</button>
				</div>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data'),
	ciselniky: get(state, 'ciselniky'),
	auth: get(state, 'auth')
})

const mapDispatchToProps = (dispatch) => ({
	trackingActions: bindActionCreators(TrackingActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(ModalSendItemDuplicate)
