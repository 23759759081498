import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, propTypes, getFormValues } from 'redux-form'
import PropTypes from 'prop-types'
import { get, find, upperFirst, map, isEmpty, sumBy } from 'lodash'
import { Tooltip } from 'react-tippy'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { NumericFormat } from 'react-number-format'

// components
import FormInfo from '../FormInfo'
import WrapperPodpisovanieDokumentov from '../PodpisovanieDokumentov/WrapperPodpisovanieDokumentov'

// utils
import { FORMS, POHLADAVKA_TYP } from '../../utils/enums'
import { getTypPohladavkyTooltip } from '../../utils/pohladavky'
import { formatDate } from '../../utils/date'

class VrateniePreplatkovConfirm extends React.Component {
	static propTypes = {
		...propTypes,
		formValues: PropTypes.shape()
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	render() {
		const {
			ciselniky,
			formTitle,
			handleSubmit,
			scenarioOption,
			isDisabledNotification,
			isDisabledSignedPdf,
			isDisabledScenarNotSelected,
			templatePdf,
			onBackClick,
			onCancelClick,
			schvalovacieKriteria,
			disallowScenarios,
			formValues,
			t
		} = this.props

		const ukonVstup = find(get(ciselniky, 'ukonVstup', []), (item) => {
			return get(item, 'id') == get(formValues, 'ukonVstup')
		})

		const disabledBtn = (
			<button className='button' type='submit' disabled data-color='blue' style={{ marginLeft: '20px' }}>
				{t('translation:Common.Dokončiť')}
			</button>
		)

		let submitBtn = null
		if (get(templatePdf, 'isLoading')) {
			submitBtn = (
				<Tooltip
					html={<span>{t('translation:Common.Prebieha načítavanie dokumentu pre podpisovanie')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (isDisabledScenarNotSelected) {
			submitBtn = (
				<Tooltip html={<span>{t('translation:Common.Pre pokračovanie zvoľte scenár')}</span>} position='left' trigger='mouseenter' theme='light'>
					{disabledBtn}
				</Tooltip>
			)
		} else if (isDisabledSignedPdf) {
			submitBtn = (
				<Tooltip html={<span>{t('translation:Common.Dokument musí byť podpísaný')}</span>} position='left' trigger='mouseenter' theme='light'>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledSignedPdf && isDisabledNotification) {
			submitBtn = (
				<Tooltip
					html={<span>{t('translation:Common.Na dokončenie úkonu je potrebné nastaviť notifikáciu')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledNotification && !isDisabledSignedPdf) {
			submitBtn = (
				<button className='button pull-right' type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
					{t('translation:Common.Dokončiť')}
				</button>
			)
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{submitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<FormInfo schvalovacieKriteria={schvalovacieKriteria} />
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstup do úkonu')}</strong>
										</td>
										<td>{upperFirst(get(ukonVstup, 'nazov', ''))}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
										</td>
										<td>{get(formValues, 'podpisMiesto')}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
										</td>
										<td>{formatDate(get(formValues, 'datumPrijatiaZiadosti'), null, 'DD.MM.YYYY')}</td>
									</tr>
								</tbody>
							</table>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:Common.Zmluvný účet')}</strong>
											</td>
											<td>{get(formValues, 'zuCislo')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Preplatky.Preplatky')}</strong>
											</td>
											<td style={{ padding: 0 }}>
												<table className='search-results-table no-pointer' cellSpacing='0' style={{ border: 'none' }}>
													<thead>
														<tr>
															<th style={{ width: '20%' }}>{t('translation:Preplatky.filter.Číslo faktúry')}</th>
															<th style={{ width: '40%' }}>{t('translation:Preplatky.filter.Typ')}</th>
															<th style={{ width: '20%' }}>{t('translation:Preplatky.filter.Číslo dokladu')}</th>
															<th style={{ whiteSpace: 'nowrap', textAlign: 'right', width: '20%' }}>
																{t('translation:Preplatky.filter.Otvorená suma')}
															</th>
														</tr>
													</thead>
													<tbody data-empty={t('translation:Preplatky.Neboli zvolené žiadne preplatky')}>
														{map(get(formValues, 'preplatky', []), (pohladavka, index) => {
															let typPohladavky
															switch (get(pohladavka, 'typ.id')) {
																case POHLADAVKA_TYP.PREPLATOK_Z_FAKTURACIE.value:
																case POHLADAVKA_TYP.AKONTO.value:
																case POHLADAVKA_TYP.ZALOHA_ZNOVUPRIPOJENIE.value:
																case POHLADAVKA_TYP.UDRZBA_UCTU.value:
																case POHLADAVKA_TYP.STANDARD_KVALITY.value:
																	typPohladavky = (
																		<Tooltip
																			html={getTypPohladavkyTooltip(pohladavka, t)}
																			position='top'
																			trigger='mouseenter'
																			theme='light'
																		>
																			{get(pohladavka, 'typ.nazov')}
																		</Tooltip>
																	)
																	break
																default:
																	typPohladavky = get(pohladavka, 'typ.nazov')
															}

															const sumaOtvorena = (
																<NumericFormat
																	thousandSeparator={' '}
																	decimalSeparator={','}
																	decimalScale={2}
																	fixedDecimalScale={true}
																	displayType='text'
																	suffix=' €'
																	value={get(pohladavka, 'sumaOtvorena')}
																/>
															)
															const sumaOtvorenaString = sumaOtvorena

															return (
																<tr key={`pohladavka_${index}`}>
																	<td style={{ whiteSpace: 'nowrap' }}>{get(pohladavka, 'variabilnySymbol', '-')}</td>
																	<td style={{ wordBreak: 'normal' }}>{typPohladavky}</td>
																	<td style={{ whiteSpace: 'nowrap' }}>{get(pohladavka, 'cislo')}</td>
																	<td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{sumaOtvorenaString}</td>
																</tr>
															)
														})}
														{!isEmpty(get(formValues, 'preplatky', [])) && (
															<tr key={`pohladavka_spolu`}>
																<td>
																	<strong>{t('translation:Common.SPOLU')}</strong>
																</td>
																<td />
																<td />
																<td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
																	<strong>
																		<NumericFormat
																			thousandSeparator={' '}
																			decimalSeparator={','}
																			decimalScale={2}
																			fixedDecimalScale={true}
																			displayType='text'
																			suffix=' €'
																			value={sumBy(get(formValues, 'preplatky', []), 'sumaOtvorena')}
																		/>
																	</strong>
																</td>
															</tr>
														)}
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Preplatky.Odklad splatnosti (informatívny)')}</strong>
											</td>
											<td>{formatDate(get(formValues, 'odkladSplatnosti'), '', 'DD.MM.YYYY')}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Poznámka')}</strong>
										</td>
										<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
											<div className='input-wrapper'>
												<textarea
													disabled='disabled'
													rows='5'
													className='form-control static'
													style={{ backgroundColor: 'white' }}
													value={get(formValues, 'poznamka')}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>

							<WrapperPodpisovanieDokumentov
								scenarioOption={scenarioOption}
								disallowScenarios={disallowScenarios}
								schvalovacieKriteria={schvalovacieKriteria}
							/>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.VRATENIE_PREPLATKOV_VYTVORENIE,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true
})(VrateniePreplatkovConfirm)

const mapStateToProps = (state) => ({
	formValues: getFormValues(FORMS.VRATENIE_PREPLATKOV_VYTVORENIE)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
