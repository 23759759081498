import {
	USER_LOGIN_SUCCESS,
	USER_LOGOUT,
	USER_BUSINESS_CHANNEL_CHANGED,
	USER_SET_DEFAULT_BUSINESS_CHANNEL,
	USER_UPDATE_CONFIG,
	USER_UPDATE_HEARTBEAT,
	USER_MIESTO_PODPISU_CHANGED
} from '../types/auth'

export const initState = {
	user: null,
	businessChannel: {
		actual: null,
		assign: []
	},
	config: {
		frontendCacheTime: 60,
		stavUctuUpdateOffset: 30
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case USER_LOGIN_SUCCESS:
			return {
				...state,
				user: action.payload.user,
				businessChannel: {
					...state.businessChannel,
					actual: action.payload.defaultBusinessChannel,
					assign: action.payload.assignBusinessChannels
				}
			}
		case USER_UPDATE_HEARTBEAT:
			return {
				...state,
				user: action.payload.user
			}
		case USER_BUSINESS_CHANNEL_CHANGED:
			return {
				...state,
				businessChannel: {
					...state.businessChannel,
					actual: action.payload.actualBusinessChannel
				}
			}
		case USER_MIESTO_PODPISU_CHANGED:
			return {
				...state,
				user: {
					...state.user,
					podpisMiesto: action.payload.podpisMiesto
				}
			}
		case USER_SET_DEFAULT_BUSINESS_CHANNEL:
			return {
				...state,
				businessChannel: {
					...state.businessChannel,
					actual: action.payload.defaultBusinessChannel,
					assign: action.payload.assignBusinessChannels
				}
			}
		case USER_UPDATE_CONFIG:
			return {
				...state,
				config: {
					...state.config,
					frontendCacheTime: action.payload.frontendCacheTime,
					stavUctuUpdateOffset: action.payload.stavUctuUpdateOffset
				}
			}
		case USER_LOGOUT:
			return {
				...initState
			}
		default:
			return state
	}
}
