import React from 'react'
import { connect } from 'react-redux'
import { map, isEmpty, get, orderBy, values, isEqual, split, keys, pick, indexOf, uniq, head } from 'lodash'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tippy'
import cx from 'classnames'
import reactQueryParams from 'react-query-params'

// atoms
import Select from '../../atoms/BasicSelect'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as FiltersActions from '../../actions/SelectedFiltersActions'

// components
import ElementLoading from '../../components/ElementLoading'
import SplnomocnenieRow from '../../components/TableRows/SplnomocneniaTableRow'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'

// utils
import { setRouteParams, VZTAHY_VYTVORIT } from '../../utils/routes'
import Permissions, { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import extractProperties from '../../utils/extractProperties'
import { FILTER_SELECTORS, OBCHODNY_PARTNER_TYP, VZTAHY_FILTER_TYP } from '../../utils/enums'

const ACTIVE_FILTER = 'aktivne'

const queryParams = {
	typ: 'typ',
	platnostOd: 'platnostOd',
	platnostDo: 'platnostDo',
	stav: 'stav'
}

const initFilters = {
	[queryParams.typ]: null,
	[queryParams.platnostOd]: null,
	[queryParams.platnostDo]: null,
	[queryParams.stav]: null
}

export const OP_VZTAHY_FILTER_FIRST = [
	{ id: 'ZZPSME', nazov: 'Vzťah zodpov.prac. SME' },
	{ id: 'ZZPSMT', nazov: 'Vzťah zodpov.prac.TOP SME' },
	{ id: 'ZMCAM', nazov: 'Zodp. pracovník AM' },
	{ id: 'ZMCKAM', nazov: 'Zodp. pracovník KAM' },
	{ id: 'ZOPR01', nazov: 'Vzťah oprávnenej osoby' },
	{ id: 'ZSTA01', nazov: 'Vzťah štatutárneho zástup' },
	{ id: 'BUR001', nazov: 'Vzťah kontaktnej osoby' },
	{ id: 'ZVIPUP', nazov: 'Vzťah VIP upomienkovanie' },
	{ id: 'ZSPRAV', nazov: 'Vzťah SVB/Správca' }
]

export const OP_VZTAHY_FILTER_LAST = [
	{ id: 'ZELWEB', nazov: 'Elektroweb - kont. osoba' },
	{ id: 'BUR011', nazov: 'Zodpovedný pracovník' }
]

class SplnomocneniaPage extends reactQueryParams {
	static propTypes = {
		interakcia: PropTypes.shape(),
		obchodnyPartnerActions: PropTypes.shape().isRequired,
		splnomocnenia: PropTypes.shape(),
		ciselniky: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		selectedFilters: PropTypes.shape()
	}

	defaultQueryParams = {
		[queryParams.typ]: null,
		[queryParams.platnostOd]: null,
		[queryParams.platnostDo]: null,
		[queryParams.stav]: null
	}

	componentDidMount() {
		const { obchodnyPartnerActions, filtersActions, selectedFilters, obchodnyPartnerDetail } = this.props

		const filteredParams = pick(
			this.queryParams,
			keys(this.queryParams).filter((key) => {
				return indexOf(values(queryParams), key) !== -1 && !isEmpty(this.queryParams[key])
			})
		)

		const opTyp = get(obchodnyPartnerDetail, 'data.typ')

		const filters = {
			...selectedFilters,
			[queryParams.typ]: opTyp === OBCHODNY_PARTNER_TYP.MOP ? VZTAHY_FILTER_TYP.MOP_SELECTED : VZTAHY_FILTER_TYP.MOO_SELECTED,
			...filteredParams
		}

		obchodnyPartnerActions.loadVztahy(1, filters)

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.OP_VZTAHY_PAGE, filters)
	}

	componentDidUpdate = () => {
		// prevent to inject not allowed query param
		const query = extractProperties(this.queryParams, values(queryParams))
		if (!isEqual(this.props.selectedFilters, query)) {
			this.setQueryParams({
				...this.props.selectedFilters
			})
		}
	}

	multiFilterAdd = (filters, fieldName) => {
		const { selectedFilters } = this.props
		if (!get(filters, '[0].value')) {
			return this.clearFilters(fieldName)
		}

		const filterArray = map(filters, (filter) => get(filter, 'value'))
		const newFiletrs = head(filterArray).split(',')
		const oldFilters = split(selectedFilters[fieldName], ',')

		// Ak si vyberiem multivalue (napr. Vybrané), tak nemergujem ale nahradzujem
		let mergedFilters = []
		if (newFiletrs.length > 1) {
			mergedFilters = newFiletrs
		} else {
			mergedFilters = uniq([...oldFilters, ...newFiletrs])
		}

		const value = mergedFilters.filter((e) => e !== null && e !== '' && e !== '[]').join(',')

		this.filterChanged({ value }, fieldName)
	}

	filterChanged = (filter, fieldName) => {
		const { filtersActions, selectedFilters, obchodnyPartnerActions } = this.props

		// stringify value or set a default value null
		const newFilter = {
			...selectedFilters,
			[fieldName]: get(filter, 'value') ? `${filter.value}` : null
		}
		if (isEqual(newFilter[fieldName], selectedFilters[fieldName])) {
			return
		}

		obchodnyPartnerActions.loadVztahy(1, newFilter)

		// update filter state
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.OP_VZTAHY_PAGE, newFilter)
	}

	clearFilters = (fieldName) => {
		const { filtersActions, obchodnyPartnerActions, selectedFilters } = this.props

		let filter
		if (fieldName) {
			filter = {
				...selectedFilters,
				[fieldName]: initFilters[fieldName]
			}
		} else {
			filter = {
				...initFilters
			}
		}

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.OP_VZTAHY_PAGE, filter)

		obchodnyPartnerActions.loadVztahy(1, filter)
	}

	clearFilter = (filterName, filterValue) => {
		const { selectedFilters, filtersActions, obchodnyPartnerActions } = this.props

		const filter = selectedFilters[filterName].split(',').filter((item) => item != filterValue)
		const val = !isEmpty(filter) ? filter.join(',') : null

		const newFilter = {
			...selectedFilters,
			[filterName]: val
		}

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.OP_VZTAHY_PAGE, newFilter)

		obchodnyPartnerActions.loadVztahy(1, newFilter)
	}

	render() {
		const { interakcia, splnomocnenia, t, obchodnyPartnerDetail } = this.props

		if (get(splnomocnenia, 'isLoading')) {
			return this.loadingContentElement()
		}

		if (get(splnomocnenia, 'isFailure')) {
			return this.failureContentElement()
		}

		if (isEmpty(get(splnomocnenia, 'data', []))) {
			return this.emptyContentElement()
		}

		return this.commonContentContainer(
			<div className='content-wrapper'>
				<div className='row'>
					<div className='col-12'>
						<div className='box'>
							<div className='box-content'>
								<table className='content-table bordered hoverable' cellSpacing='0'>
									<thead>
										<tr>
											<th>{t('containers:SplnomocneniaPage.Typ vzťahu')}</th>
											<th style={{ width: '150px' }}>{t('containers:SplnomocneniaPage.Číslo OP')}</th>
											<th>{t('containers:SplnomocneniaPage.Meno OP')}</th>
											<th style={{ width: '230px' }}> {t('containers:SplnomocneniaPage.Platnosť od - do')}</th>
											<th>{t('containers:SplnomocneniaPage.Dokumenty')}</th>
											<th style={{ width: '120px' }} />
										</tr>
									</thead>
									<tbody>
										{map(
											orderBy(get(splnomocnenia, 'data', []), ['splnomocnenec.cislo', 'platnostOd', 'platnostDo'], ['asc', 'asc', 'asc']),
											(splnomocnenie, index) => (
												<SplnomocnenieRow
													key={`${get(splnomocnenie, 'op2.cislo')}${get(splnomocnenie, 'platnostOd')}-${index}`}
													interakcia={interakcia}
													splnomocnenie={splnomocnenie}
													t={t}
													obchodnyPartner={obchodnyPartnerDetail}
												/>
											)
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	getTypFilter = () => {
		const { ciselniky, t, selectedFilters, obchodnyPartnerDetail } = this.props

		const opTyp = get(obchodnyPartnerDetail, 'data.typ')

		const isActiveTypFilter = !isEmpty(selectedFilters[queryParams.typ])

		let typFilterPlaceholder = t('containers:SplnomocneniaPage.Všetky')
		if (isActiveTypFilter) {
			typFilterPlaceholder = `${t('translation:Common.filter.Aktívne filtre')} (${selectedFilters[queryParams.typ].split(',').length})`
		}

		const selectedOptions = {
			value: opTyp === OBCHODNY_PARTNER_TYP.MOP ? VZTAHY_FILTER_TYP.MOP_SELECTED : VZTAHY_FILTER_TYP.MOO_SELECTED,
			label: t('containers:SplnomocneniaPage.Vybrané')
		}
		const allOptions = {
			value: null,
			label: t('containers:SplnomocneniaPage.Všetky')
		}

		const typFilterOptions = [
			selectedOptions,
			allOptions,
			...OP_VZTAHY_FILTER_FIRST.map((item) => {
				return { value: item.id, label: `${item.id} - ${item.nazov}` }
			}),
			...get(ciselniky, 'opVztah', [])
				.filter((item) => {
					const isInFirstArray = OP_VZTAHY_FILTER_FIRST.find((filter) => filter.id === item.id)
					const isInLastArray = OP_VZTAHY_FILTER_LAST.find((filter) => filter.id === item.id)
					return !isInFirstArray && !isInLastArray
				})
				.map((item) => {
					return { value: item.id, label: `${item.id} - ${item.nazov}` }
				}),
			...OP_VZTAHY_FILTER_LAST.map((item) => {
				return { value: item.id, label: `${item.id} - ${item.nazov}` }
			})
		].filter((item) => {
			// filter out selected items
			if (selectedFilters[queryParams.typ] && selectedFilters[queryParams.typ].split(',').includes(item?.value)) {
				return false
			}

			return true
		})

		return { typFilterOptions, typFilterPlaceholder, isActiveTypFilter }
	}

	getStavFilter = () => {
		const { t, selectedFilters } = this.props

		const isActiveStavFilter = selectedFilters[queryParams.stav] === ACTIVE_FILTER
		const stavFilterPlaceholder = isActiveStavFilter ? t('containers:SplnomocneniaPage.Len aktívne') : t('containers:SplnomocneniaPage.Všetky')

		const stavFilterOptions = [
			{
				value: ACTIVE_FILTER,
				label: t('containers:SplnomocneniaPage.Len aktívne')
			},
			{
				value: null,
				label: t('containers:SplnomocneniaPage.Všetky')
			}
		]

		return { stavFilterOptions, stavFilterPlaceholder, isActiveStavFilter }
	}

	filterElement = () => {
		const { interakcia, t, selectedFilters } = this.props

		const { typFilterOptions, isActiveTypFilter, typFilterPlaceholder } = this.getTypFilter()
		const { stavFilterOptions, isActiveStavFilter, stavFilterPlaceholder } = this.getStavFilter()

		let typFilterItems = []
		if (get(selectedFilters, queryParams.typ)) {
			typFilterItems = [...get(selectedFilters, queryParams.typ).split(',')]
		}

		return (
			<div className='content-header'>
				<div className='row'>
					<div className='col-3'>
						<Select
							className={cx({ 'active-filters': isActiveTypFilter, green: isActiveTypFilter })}
							label={t('containers:SplnomocneniaPage.Typ vzťahu')}
							options={typFilterOptions}
							onChange={(typ) => this.multiFilterAdd(typ, queryParams.typ)}
							placeholder={typFilterPlaceholder}
							isMulti
							closeMenuOnSelect={false}
						/>
					</div>
					<div className='col-3'>
						<Select
							className={cx({ 'active-filters': isActiveStavFilter, purple: isActiveStavFilter })}
							label={t('containers:SplnomocneniaPage.Stav vzťahu')}
							classNamePrefix='react-select'
							options={stavFilterOptions}
							onChange={(stav) => this.filterChanged(stav, queryParams.stav)}
							placeholder={stavFilterPlaceholder}
						/>
					</div>
					<div className='col-6'>
						<div className='input-wrapper'>
							<Permissions
								allowed={[PERMISSIONS.UKON_SPLNOMOCNENIE]}
								render={(hasPerm, actions) => {
									if (hasPerm) {
										return (
											<Link
												to={setRouteParams(VZTAHY_VYTVORIT, get(interakcia, 'data.opCislo'))}
												className='button pull-right'
												style={{ marginTop: 25 }}
												type='button'
												data-color='blue'
											>
												{t('containers:SplnomocneniaPage.Pridať nové splnomocnenie')}
											</Link>
										)
									}

									return (
										<Link
											to={setRouteParams(VZTAHY_VYTVORIT, get(interakcia, 'data.opCislo'))}
											className={cx('button', 'pull-right', { disabled: true })}
											style={{ marginTop: 25 }}
											type='button'
											data-color='blue'
											onClick={(e) => {
												e.preventDefault()
												actions.openForbiddenModal()
											}}
										>
											<Tooltip
												html={<span>{t('containers:SplnomocneniaPage.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>}
												position='left'
												trigger='mouseenter'
												theme='light'
											>
												{t('containers:SplnomocneniaPage.Pridať nové splnomocnenie')}
											</Tooltip>
										</Link>
									)
								}}
							/>
						</div>
					</div>
					<div className='col-12'>
						<div className='filter-items clearfix' style={{ paddingRight: '150px' }}>
							{typFilterItems.map((item, index) => (
								<div
									key={`${item}-${index}`}
									className='filter-item'
									data-color={'green'}
									onClick={() => this.clearFilter(queryParams.typ, item)}
									style={{ cursor: 'pointer' }}
								>
									<span className='filter-text'>{item}</span>
									<span className='remove' />
								</div>
							))}
							{get(selectedFilters, queryParams.stav) && (
								<div
									className='filter-item'
									data-color={'purple'}
									onClick={() => this.clearFilter(queryParams.stav, ACTIVE_FILTER)}
									style={{ cursor: 'pointer' }}
								>
									<span className='filter-text'>{t('containers:SplnomocneniaPage.Len aktívne')}</span>
									<span className='remove' />
								</div>
							)}
							{(typFilterItems?.length > 0 || get(selectedFilters, queryParams.stav)) && (
								<div
									className='filter-item'
									style={{ margin: 0, position: 'absolute', top: '5px', right: '15px', cursor: 'pointer' }}
									data-color='red'
									onClick={() => this.clearFilters()}
								>
									<span className='filter-text'>{t('translation:Common.filter.Zrušiť filtre')}</span>
									<span className='remove' />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}

	commonContentContainer = (content) => {
		return (
			<>
				{this.filterElement()}
				{content}
			</>
		)
	}

	loadingContentElement = () => {
		return this.commonContentContainer(<ElementLoading />)
	}

	failureContentElement = () => {
		const { t } = this.props

		return this.commonContentContainer(<ElementFailure text={t('containers:SplnomocneniaPage.Nepodarilo sa načítať zoznam splnomocnení')} />)
	}

	emptyContentElement = () => {
		const { t } = this.props

		return this.commonContentContainer(
			<ElementEmptyContent text={t('containers:SplnomocneniaPage.Pre obchodného partnera neevidujeme žiadne splnomocnenia')} />
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	splnomocnenia: state.obchodnyPartner.splnomocnenia,
	ciselniky: state.ciselniky.data,
	selectedFilters: get(state.selectedFilters, FILTER_SELECTORS.OP_VZTAHY_PAGE, {}),
	obchodnyPartnerDetail: state.obchodnyPartner.detail
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_SPLNOMOCNENIE])
)(SplnomocneniaPage)
