import { get } from 'lodash'
import { UPDATE_ZMLUVNE_VZTAHY_CONFIG, CLEAR_ZMLUVNE_VZTAHY_CONFIG } from '../types/zmluvneVztahyConfigTypes'

export const updateMiestaSpotrebyConfig = (msCislo, isOpen) => {
	return (dispatch, getState) => {
		const state = getState()
		const miestoSpotrebyConfig = get(state, `zmluvneVztahyConfig.miestaSpotreby.${msCislo}`) || {}

		dispatch({
			type: UPDATE_ZMLUVNE_VZTAHY_CONFIG,
			payload: {
				[msCislo]: {
					...miestoSpotrebyConfig,
					isOpenMiestoSpotreby: isOpen
				}
			}
		})
	}
}

export const updateNeaktivneZmluvneVztahyConfig = (msCislo, isOpen) => {
	return (dispatch, getState) => {
		const state = getState()
		const miestoSpotrebyConfig = get(state, `zmluvneVztahyConfig.miestaSpotreby.${msCislo}`) || {}

		dispatch({
			type: UPDATE_ZMLUVNE_VZTAHY_CONFIG,
			payload: {
				[msCislo]: {
					...miestoSpotrebyConfig,
					isOpenNeaktivneZmluvneVztahy: isOpen
				}
			}
		})
	}
}

export const updateNeaktivneZmluvneUctyConfig = (msCislo, isOpen) => {
	return (dispatch, getState) => {
		const state = getState()
		const miestoSpotrebyConfig = get(state, `zmluvneVztahyConfig.miestaSpotreby.${msCislo}`) || {}

		dispatch({
			type: UPDATE_ZMLUVNE_VZTAHY_CONFIG,
			payload: {
				[msCislo]: {
					...miestoSpotrebyConfig,
					isOpenNeaktivneZmluvneUcty: isOpen
				}
			}
		})
	}
}

export const clearZmluvneVztahyConfig = () => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_ZMLUVNE_VZTAHY_CONFIG
		})
	}
}
