import { get } from 'lodash'
import {
	OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_START,
	OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_DONE,
	OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_FAIL
} from '../../types/obchodnyPartner'
import { getReq } from '../../utils/request'

export const loadZmluvnyUcetDetail = (zuCislo) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')
		const opCislo = get(store, 'obchodnyPartner.detail.data.cislo')

		dispatch({
			type: OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_START
		})

		try {
			const zmluvnyUcetData = await getReq(`/api/v0/obchodni-partneri/${get(interakcia, 'opCislo')}/zmluvne-ucty/${zuCislo}`)

			const { odlisnyPlatca, odlisnyPlatcaDoslePlatby, odlisnyPlatcaUpomienky, odlisnyPrijemca } = get(zmluvnyUcetData, 'response.obsah')

			let opOdlisnyPlatca
			let opOdlisnyPlatcaDoslePlatby
			let opOdlisnyPlatcaUpomienky
			let opOdlisnyPrijemca

			if (odlisnyPlatca) {
				if (odlisnyPlatca != opCislo) {
					const odlisnyPlatcaData = await getReq(`/api/v0/obchodni-partneri/${odlisnyPlatca}`)
					opOdlisnyPlatca = get(odlisnyPlatcaData, 'response.obsah')
				} else {
					opOdlisnyPlatca = get(store, 'obchodnyPartner.detail.data')
				}
			}

			if (odlisnyPlatcaDoslePlatby) {
				if (odlisnyPlatcaDoslePlatby != opCislo) {
					const odlisnyPlatcaDoslePlatbyData = await getReq(`/api/v0/obchodni-partneri/${odlisnyPlatcaDoslePlatby}`)
					opOdlisnyPlatcaDoslePlatby = get(odlisnyPlatcaDoslePlatbyData, 'response.obsah')
				} else {
					opOdlisnyPlatcaDoslePlatby = get(store, 'obchodnyPartner.detail.data')
				}
			}

			if (odlisnyPlatcaUpomienky) {
				if (odlisnyPlatcaUpomienky != opCislo) {
					const odlisnyPlatcaUpomienkyData = await getReq(`/api/v0/obchodni-partneri/${odlisnyPlatcaUpomienky}`)
					opOdlisnyPlatcaUpomienky = get(odlisnyPlatcaUpomienkyData, 'response.obsah')
				} else {
					opOdlisnyPlatcaUpomienky = get(store, 'obchodnyPartner.detail.data')
				}
			}

			if (odlisnyPrijemca) {
				if (odlisnyPrijemca != opCislo) {
					const odlisnyPrijemcaData = await getReq(`/api/v0/obchodni-partneri/${odlisnyPrijemca}`)
					opOdlisnyPrijemca = get(odlisnyPrijemcaData, 'response.obsah')
				} else {
					opOdlisnyPrijemca = get(store, 'obchodnyPartner.detail.data')
				}
			}

			const payload = {
				zmluvnyUcet: get(zmluvnyUcetData, 'response.obsah'),
				odlisnyOp: {
					opOdlisnyPlatca,
					opOdlisnyPlatcaDoslePlatby,
					opOdlisnyPlatcaUpomienky,
					opOdlisnyPrijemca
				}
			}

			dispatch({
				type: OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_DONE,
				payload
			})

			return payload
		} catch (e) {
			dispatch({
				type: OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_FAIL
			})
			return e
		}
	}
}
