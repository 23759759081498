import { get } from 'lodash'
import dayjs from 'dayjs'

import { getReq } from '../utils/request'
import {
	CISELNIKY_LOAD_START,
	CISELNIKY_LOAD_DONE,
	CISELNIKY_LOAD_FAIL,
	CES_CISELNIKY_LOAD_START,
	CES_CISELNIKY_LOAD_DONE,
	CES_CISELNIKY_LOAD_FAIL
} from '../types/ciselniky'
import { minutesBetween } from '../utils/date'

export function ciselnikyLoadStart() {
	return {
		type: CISELNIKY_LOAD_START
	}
}

export function ciselnikyLoadDone(ciselniky) {
	return {
		type: CISELNIKY_LOAD_DONE,
		payload: {
			ciselniky
		}
	}
}

export function ciselnikyLoadFail() {
	return {
		type: CISELNIKY_LOAD_FAIL
	}
}

export function ciselnikyCESLoadStart() {
	return {
		type: CES_CISELNIKY_LOAD_START
	}
}

export function ciselnikyCESLoadDone(cesCiselniky) {
	return {
		type: CES_CISELNIKY_LOAD_DONE,
		payload: {
			cesCiselniky
		}
	}
}

export function ciselnikyCESLoadFail() {
	return {
		type: CES_CISELNIKY_LOAD_FAIL
	}
}

export const loadCiselniky = () => {
	return async (dispatch, getState) => {
		const { ciselniky, auth } = getState()

		const cacheTime = get(auth, 'config.frontendCacheTime')

		const lastLoadTimestamp = dayjs(ciselniky.lastLoad)
		const diffDays = dayjs().diff(lastLoadTimestamp, 'day')

		// check if is need load fresh data or not
		if (
			ciselniky.data &&
			cacheTime &&
			minutesBetween(ciselniky.lastLoad, new Date()) < cacheTime &&
			diffDays === 0 // load fresh at least once per day
		) {
			return
		}
		try {
			dispatch(ciselnikyLoadStart())
			const res = await getReq('/api/v0/ciselniky')
			dispatch(ciselnikyLoadDone(res.response))
		} catch (e) {
			dispatch(ciselnikyLoadFail())
		}
	}
}

export const loadCESCiselniky = () => {
	return async (dispatch, getState) => {
		const { cesCiselniky, auth } = getState()

		const cacheTime = get(auth, 'config.frontendCacheTime')

		const lastLoadTimestamp = dayjs(cesCiselniky.lastLoad)
		const diffDays = dayjs().diff(lastLoadTimestamp, 'day')

		// check if is need load fresh data or not
		if (
			cesCiselniky.data &&
			cacheTime &&
			minutesBetween(cesCiselniky.lastLoad, new Date()) < cacheTime &&
			diffDays === 0 // load fresh at least once per day
		) {
			return
		}
		try {
			dispatch(ciselnikyCESLoadStart())
			const res = await getReq('/api/ces/ciselniky')
			dispatch(ciselnikyCESLoadDone(res.response))
		} catch (e) {
			dispatch(ciselnikyCESLoadFail())
		}
	}
}
