const production = {
	baseUrl: '',
	loginUrl: '/api/v0/autorizacia/prihlasenie',
	wacomUrl: 'https://127.0.0.1:50080/1.0'
}

const development = {
	baseUrl: '',
	loginUrl: '/api/v0/autorizacia/prihlasenie',
	cacheTime: 0,
	wacomUrl: 'https://127.0.0.1:50080/1.0',
	inactiveInteractionAlertTime: 9999999 // NEVER
}

let config = {
	searchUserLimit: 10,
	zmluvneUctyPageSize: 10,
	cacheTime: 5, // 5 minutes
	fakturyPageSize: 20,
	inactiveInteractionAlertTime: 30 // 30 minutes
}

switch (process.env.NODE_ENV) {
	case 'production':
		config = {
			...config,
			...production
		}
		break
	case 'development':
	case 'test':
		config = {
			...config,
			...development
		}
		break
}

export default config
