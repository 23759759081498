import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes, getFormValues, change } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { every, map, get, upperFirst, isEmpty, some, filter } from 'lodash'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Tooltip } from 'react-tippy'

import validate from './validateForm'

// components
import FormInfo from '../../FormInfo'

// atoms
import { SelectField, DropZoneField, DatePickerField, TextareaField, TextInputField } from '../../../atoms'

// utils
import { FORMS, BUSINESS_CHANNELS } from '../../../utils/enums'
import { formatAddress, miestoSpotrebyAdresaDoplnokFormat } from '../../../utils/address'
import { isValidacneKriteriumError } from '../../../utils/form'

class VseobecnyAktivnyUkonInfo extends React.Component {
	static propTypes = {
		...propTypes,
		formValues: PropTypes.shape()
	}

	state = {
		ukonVstupOptions: []
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		const { dispatch, formValues } = this.props

		if (get(formValues, 'kategoria1') != get(prevProps, 'formValues.kategoria1')) {
			dispatch(change(FORMS.VSEOBECNY_UKON_AKTIVNY, 'kategoria2', null, true))
		}

		if (get(formValues, 'kategoria2') != get(prevProps, 'formValues.kategoria2')) {
			dispatch(change(FORMS.VSEOBECNY_UKON_AKTIVNY, 'kategoria3', null, true))
		}

		if (get(formValues, 'kategoria3') != get(prevProps, 'formValues.kategoria3')) {
			dispatch(change(FORMS.VSEOBECNY_UKON_AKTIVNY, 'kategoria4', null, true))
		}
	}

	render() {
		const {
			formTitle,
			handleSubmit,
			invalid,
			auth,
			validacneKriteria,
			schvalovacieKriteria,
			onDeleteFile,
			onBackClick,
			onCancelClick,
			ciselniky,
			ciselnikySelectBox,
			formValues,
			t
		} = this.props

		const ukonVstupOptions = map(get(ciselniky, 'ukonVstup', []), (ukonVstup) => ({
			label: upperFirst(get(ukonVstup, 'nazov')),
			value: get(ukonVstup, 'id')
		}))

		const miestoSpotrebyOptions = map(get(ciselnikySelectBox, 'miestaSpotreby.data', []), (miestoSpotreby) => ({
			label: `${formatAddress(get(miestoSpotreby, 'adresa'))}, ${miestoSpotrebyAdresaDoplnokFormat(miestoSpotreby)}`,
			value: get(miestoSpotreby, 'cislo')
		}))

		// build options for select box kategoria 1
		const sapKontaktKategoria1Options = map(get(ciselniky, 'sapKontaktKategoria1', []), (sapKontaktKategoria1) => ({
			label: upperFirst(get(sapKontaktKategoria1, 'nazov', '')),
			value: get(sapKontaktKategoria1, 'id')
		}))

		// filter options for select box kategoria 2 by selected kategoria 1 option
		const sapKontaktKategoria2Filter = filter(
			get(ciselniky, 'sapKontaktKategoria2', []),
			(sapKontaktKategoria2) => get(sapKontaktKategoria2, 'sapKontaktKategoria1') == get(formValues, 'kategoria1')
		)

		// build options for select box kategoria 2
		const sapKontaktKategoria2Options = map(sapKontaktKategoria2Filter, (sapKontaktKategoria2) => ({
			label: upperFirst(get(sapKontaktKategoria2, 'nazov', '')),
			value: get(sapKontaktKategoria2, 'id'),
			sapKontaktKategoria1: get(sapKontaktKategoria2, 'sapKontaktKategoria1')
		}))

		// filter options for select box kategoria 3 by selected kategoria 2 option
		const sapKontaktKategoria3Filter = filter(
			get(ciselniky, 'sapKontaktKategoria3', []),
			(sapKontaktKategoria3) => get(sapKontaktKategoria3, 'sapKontaktKategoria2') == get(formValues, 'kategoria2')
		)

		// build options for select box kategoria 3
		const sapKontaktKategoria3Options = map(sapKontaktKategoria3Filter, (sapKontaktKategoria3) => ({
			label: upperFirst(get(sapKontaktKategoria3, 'nazov', '')),
			value: get(sapKontaktKategoria3, 'id'),
			sapKontaktKategoria2: get(sapKontaktKategoria3, 'sapKontaktKategoria2')
		}))

		// filter options for select box kategoria 4 by selected kategoria 3 option
		const sapKontaktKategoria4Filter = filter(
			get(ciselniky, 'sapKontaktKategoria4', []),
			(sapKontaktKategoria4) => get(sapKontaktKategoria4, 'sapKontaktKategoria3') == get(formValues, 'kategoria3')
		)

		// build options for select box kategoria 4
		const sapKontaktKategoria4Options = map(sapKontaktKategoria4Filter, (sapKontaktKategoria4) => ({
			label: upperFirst(get(sapKontaktKategoria4, 'nazov', '')),
			value: get(sapKontaktKategoria4, 'id'),
			sapKontaktKategoria3: get(sapKontaktKategoria4, 'sapKontaktKategoria3')
		}))

		let minTimeDatumPrijatiaZiadosti = null
		let maxTimeDatumPrijatiaZiadosti = null
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}

		// result from procesnyKonfigurator if form is valid
		const passValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumError), {
			vysledok: true
		})
		const errorSchvalovacieKriterium = some(schvalovacieKriteria, {
			error: true
		})

		const isValid = passValidacneKriterium && !errorSchvalovacieKriterium
		const disableSubmit = !isValid || invalid

		const submitBtn = (
			<button
				className={cx('button', 'pull-right', { disabled: disableSubmit })}
				disabled={disableSubmit}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('translation:Common.Pokračovať')}
			</button>
		)

		let wrappedSubmitBtn
		if (!isValid) {
			let btnTooltipText
			if (!passValidacneKriterium) {
				const valKrit = filter(validacneKriteria, (validacneKriterium) => !validacneKriterium.vysledok)
				btnTooltipText = map(valKrit, (validacneKriterium) => (
					<div>
						{validacneKriterium.nazov}: {validacneKriterium.popis}
					</div>
				))
			} else if (errorSchvalovacieKriterium) {
				const schvalKrit = filter(schvalovacieKriteria, (schvalovacieKriterium) => !schvalovacieKriterium.vysledok || schvalovacieKriterium.error)
				btnTooltipText = map(schvalKrit, (schvalovacieKriterium) => (
					<div>
						{schvalovacieKriterium.nazov}: {schvalovacieKriterium.popis}
					</div>
				))
			}
			wrappedSubmitBtn = (
				<Tooltip html={btnTooltipText} position='bottom' trigger='mouseenter' theme='light'>
					{submitBtn}
				</Tooltip>
			)
		} else {
			wrappedSubmitBtn = submitBtn
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{wrappedSubmitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<FormInfo schvalovacieKriteria={schvalovacieKriteria} />
						<FormInfo validacneKriteria={validacneKriteria} />
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstup do úkonu')}</strong>
										</td>
										<td>
											<Field
												name='ukonVstup'
												component={SelectField}
												options={ukonVstupOptions}
												isSearchable={false}
												isDisabled={isEmpty(ukonVstupOptions)}
												classNamePrefix='react-select'
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Splnomocnenec')}</strong>
										</td>
										<td>
											<Field
												name='splnomocnenec'
												component={TextInputField}
												placeholder={t('translation:Common.Zadajte titul, meno a priezvisko splnomocnenca')}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
										</td>
										<td>
											<Field
												name='podpisMiesto'
												component={TextInputField}
												placeholder={t('translation:Common.Zadajte miesto podpisu úkonu (ak nejde o prípad odloženého podpisu)')}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
										</td>
										<td>
											<div className='select-wrapper'>
												<Field
													name='datumPrijatiaZiadosti'
													component={DatePickerField}
													showYearDropdown
													scrollableYearDropdown
													minDate={minTimeDatumPrijatiaZiadosti?.toDate()}
													maxDate={maxTimeDatumPrijatiaZiadosti?.toDate()}
													placeholderText={t('translation:Common.Zvoľte dátum')}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:Common.Miesto spotreby')}</strong>
											</td>
											<td>
												<Field
													name='msCislo'
													component={SelectField}
													options={miestoSpotrebyOptions}
													isSearchable={false}
													isDisabled={isEmpty(miestoSpotrebyOptions)}
													classNamePrefix='react-select'
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:VseobecnyAktivnyUkon.Kategória 1')}</strong>
											</td>
											<td>
												<Field
													name='kategoria1'
													component={SelectField}
													options={sapKontaktKategoria1Options}
													isSearchable={false}
													isDisabled={isEmpty(sapKontaktKategoria1Options)}
													classNamePrefix='react-select'
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:VseobecnyAktivnyUkon.Kategória 2')}</strong>
											</td>
											<td>
												<Field
													name='kategoria2'
													component={SelectField}
													options={sapKontaktKategoria2Options}
													isSearchable={false}
													isDisabled={isEmpty(sapKontaktKategoria2Options)}
													classNamePrefix='react-select'
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:VseobecnyAktivnyUkon.Kategória 3')}</strong>
											</td>
											<td>
												<Field
													name='kategoria3'
													component={SelectField}
													options={sapKontaktKategoria3Options}
													isSearchable={false}
													isDisabled={isEmpty(sapKontaktKategoria3Options)}
													classNamePrefix='react-select'
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:VseobecnyAktivnyUkon.Kategória 4')}</strong>
											</td>
											<td>
												<Field
													name='kategoria4'
													component={SelectField}
													options={sapKontaktKategoria4Options}
													isSearchable={false}
													isDisabled={isEmpty(sapKontaktKategoria4Options)}
													classNamePrefix='react-select'
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:VseobecnyAktivnyUkon.Text žiadosti')}</strong>
											</td>
											<td>
												<Field
													name='ziadostText'
													component={TextareaField}
													rows='3'
													placeholder={t('translation:VseobecnyAktivnyUkon.Tu napíšte text žiadosti')}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Poznámka')}</strong>
										</td>
										<td>
											<Field name='poznamka' component={TextareaField} rows='3' placeholder={t('translation:Common.Zadajte poznámku')} />
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstupné dokumenty k úkonu')}</strong>
										</td>
										<td>
											<Field
												name='dokumenty'
												onDelete={onDeleteFile}
												component={DropZoneField}
												placeholder={t('translation:Common.Klikni alebo presuň súbory pre nahratie')}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.VSEOBECNY_UKON_AKTIVNY,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(VseobecnyAktivnyUkonInfo)

const mapStateToProps = (state) => ({
	formValues: getFormValues(FORMS.VSEOBECNY_UKON_AKTIVNY)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
