export const ZMLUVNE_VZTAHY_LOAD_START = 'ZMLUVNE_VZTAHY_LOAD_START'
export const ZMLUVNE_VZTAHY_LOAD_PART_DONE = 'ZMLUVNE_VZTAHY_LOAD_PART_DONE'
export const ZMLUVNE_VZTAHY_LOAD_DONE = 'ZMLUVNE_VZTAHY_LOAD_DONE'
export const ZMLUVNE_VZTAHY_LOAD_FAIL = 'ZMLUVNE_VZTAHY_LOAD_FAIL'
export const ZMLUVNE_VZTAHY_CLEAR = 'ZMLUVNE_VZTAHY_CLEAR'

export const ZMLUVNY_VZTAH_LOAD_START = 'ZMLUVNY_VZTAH_LOAD_START'
export const ZMLUVNY_VZTAH_LOAD_DONE = 'ZMLUVNY_VZTAH_LOAD_DONE'
export const ZMLUVNY_VZTAH_LOAD_FAIL = 'ZMLUVNY_VZTAH_LOAD_FAIL'
export const ZMLUVNY_VZTAH_DETAIL_CLEAR = 'ZMLUVNY_VZTAH_DETAIL_CLEAR'
export const ZMLUVNY_VZTAH_LIST_CLEAR = 'ZMLUVNY_VZTAH_LIST_CLEAR'

export const KOMODITA_ROZPIS_ZALOH_LOAD_START = 'KOMODITA_ROZPIS_ZALOH_LOAD_START'
export const KOMODITA_ROZPIS_ZALOH_LOAD_DONE = 'KOMODITA_ROZPIS_ZALOH_LOAD_DONE'
export const KOMODITA_ROZPIS_ZALOH_LOAD_FAIL = 'KOMODITA_ROZPIS_ZALOH_LOAD_FAIL'

export const ZMLUVNY_VZTAH_DOCUMENTS_LOAD_START = 'ZMLUVNY_VZTAH_DOCUMENTS_LOAD_START'
export const ZMLUVNY_VZTAH_DOCUMENTS_LOAD_DONE = 'ZMLUVNY_VZTAH_DOCUMENTS_LOAD_DONE'
export const ZMLUVNY_VZTAH_DOCUMENTS_LOAD_FAIL = 'ZMLUVNY_VZTAH_DOCUMENTS_LOAD_FAIL'
export const ZMLUVNY_VZTAH_DOCUMENTS_LIST_CLEAR = 'ZMLUVNY_VZTAH_DOCUMENTS_LIST_CLEAR'

export const ZMLUVNY_VZTAH_LOAD_DETAIL_START = 'ZMLUVNY_VZTAH_LOAD_DETAIL_START'
export const ZMLUVNY_VZTAH_LOAD_DETAIL_DONE = 'ZMLUVNY_VZTAH_LOAD_DETAIL_DONE'
export const ZMLUVNY_VZTAH_LOAD_DETAIL_FAIL = 'ZMLUVNY_VZTAH_LOAD_DETAIL_FAIL'
