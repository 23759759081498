import React from 'react'
import PropTypes from 'prop-types'
import { get, isEqual, map, set, throttle } from 'lodash'
import cx from 'classnames'
import NumericInput from 'react-numeric-input'
import Select from 'react-select'

// utils
import { RADIO_GROUP_FIELD_TYPE } from '../utils/enums'

class RadioGroupField extends React.Component {
	static propTypes = {
		input: PropTypes.shape({
			value: PropTypes.any,
			onChange: PropTypes.func.isRequired
		}).isRequired,
		meta: PropTypes.shape({
			touched: PropTypes.bool,
			error: PropTypes.string
		}).isRequired,
		options: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.any,
				label: PropTypes.string
			})
		),
		editableOption: PropTypes.bool,
		editableOptionName: PropTypes.string,
		editableOptionType: PropTypes.oneOf([RADIO_GROUP_FIELD_TYPE.NUMBER, RADIO_GROUP_FIELD_TYPE.SELECT, RADIO_GROUP_FIELD_TYPE.DECIMAL]),
		editableOptionOptions: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.any,
				label: PropTypes.string
			})
		),
		formatValue: PropTypes.func,
		customParse: PropTypes.func,
		compare: PropTypes.func,
		placeholder: PropTypes.string
	}

	state = {
		open: false,
		editableOptionValueText: '',
		isEditableOptionActive: false
	}

	constructor(props) {
		super(props)
		this.selectRef = React.createRef()
	}

	componentDidMount() {
		const { input, editableOptionName } = this.props

		this.setState({
			editableOptionValueText: editableOptionName ? get(input, `value.${editableOptionName}`) || '' : get(input, 'value') || ''
		})
	}

	componentDidUpdate(prevProps) {
		const { input, editableOptionName } = this.props

		if (!isEqual(get(input, 'value'), get(prevProps, 'input.value'))) {
			this.setState({
				editableOptionValueText: editableOptionName ? get(input, `value.${editableOptionName}`) || '' : get(input, 'value') || ''
			})
		}
	}

	handleMenuOpen = () => {
		this.setState({
			open: true
		})
	}

	handleBlur = () => {
		const focusedElement = document.activeElement
		if (focusedElement && !focusedElement.className.includes('react-select')) {
			this.setState({
				open: false
			})
		} else {
			throttle(this.selectRef.current.focus(), 150)
		}
	}

	handleChange = (option) => {
		const { input } = this.props

		const value = get(option, 'value')

		// Ak kliknem na option INÉ, tak nechcem, aby mi value preskocilo do preddefinovaného option-u, ale chcem vzdy zobrat hodnotu z inputu
		const isEditableOptionActive = get(option, 'activateEditableOption', false)
		this.setState({
			isEditableOptionActive
		})

		this.setState({
			open: false
		})

		input.onChange(value)
	}

	formatEditableOption = () => {
		const {
			input,
			meta: { touched, error },
			editableOptionName,
			editableOptionType,
			editableOptionOptions,
			formatValue,
			customParse,
			placeholder
		} = this.props

		const { open, editableOptionValueText } = this.state

		if (editableOptionType == RADIO_GROUP_FIELD_TYPE.NUMBER) {
			return (
				<div className={cx('input-wrapper', { 'has-error': touched && error })}>
					<NumericInput
						min={1}
						max={9999}
						precision={0}
						value={get(input, 'value')}
						onChange={(val) => {
							this.handleChange({
								value: val
							})
						}}
						format={formatValue || undefined}
						parse={customParse}
						placeholder={placeholder}
					/>
					{/* <div className="text-danger">
					{touched ? error : ''}
				</div> */}
				</div>
			)
		}
		if (editableOptionType == RADIO_GROUP_FIELD_TYPE.DECIMAL) {
			return (
				<div className={cx('input-wrapper', { 'has-error': touched && error })}>
					<NumericInput
						min={1}
						max={9999}
						value={get(input, 'value')}
						onChange={(val) => {
							this.handleChange({
								value: val,
								activateEditableOption: true
							})
						}}
						format={formatValue || undefined}
						parse={customParse}
						placeholder={placeholder}
					/>
					{/* <div className="text-danger">
					{touched ? error : ''}
				</div> */}
				</div>
			)
		}
		if (editableOptionType == RADIO_GROUP_FIELD_TYPE.SELECT) {
			return (
				<div className={cx('select-wrapper', { 'has-error': touched && error })}>
					<Select
						options={editableOptionOptions}
						value={get(input, 'value.name')}
						onChange={(val) => {
							this.handleChange({
								value: val.value
							})
						}}
						noOptionsMessage={() => 'Nič sa nenašlo.'}
						placeholder={placeholder || 'Zvoľte možnosť'}
						// NOTE: next lines are for IE hot fix https://github.com/JedWatson/react-select/issues/1020
						onMenuOpen={this.handleMenuOpen}
						menuIsOpen={open}
						onBlur={this.handleBlur}
						ref={this.selectRef}
						classNamePrefix='react-select'
					/>
					{/* <div className="text-danger">
					{touched ? error : ''}
				</div> */}
				</div>
			)
		}
		if (editableOptionType == RADIO_GROUP_FIELD_TYPE.TEXT) {
			return (
				<div className={cx('input-wrapper', { 'has-error': touched && error })}>
					<input
						value={editableOptionValueText}
						onChange={(e) => {
							e.preventDefault()
							if (!editableOptionName) {
								return this.handleChange({
									value: get(e, 'target.value') || null
								})
							}
							const value = {}
							set(value, editableOptionName, get(e, 'target.value') || null)
							this.handleChange({ value })
						}}
						placeholder={placeholder}
						type='text'
						className='form-control input-field'
					/>
					{/* <div className="text-danger">
					{touched ? error : ''}
				</div> */}
				</div>
			)
		}
	}

	formatOption = (option, checked, index) => {
		const { input } = this.props

		return (
			<div key={`radio-group-${get(input, 'name')}-${index}`} className='radio' onClick={() => this.handleChange(option)}>
				<input checked={checked} type='radio' value={get(option, 'value')} name={get(input, 'name')} onChange={this.handleChange} />
				<label className='radio-label'>{get(option, 'label')}</label>
			</div>
		)
	}

	render() {
		const { compare, input, meta, options, editableOption } = this.props

		const value = get(input, 'value') !== undefined ? get(input, 'value') : null

		const { touched, error } = meta || {}

		let predefinedChecked = false
		return (
			<>
				<div className='radio-group-container'>
					{map(options, (option, index) => {
						if (Array.isArray(option)) {
							return (
								<div>
									{map(option, (option2, index2) => {
										const checked = compare(option2, value)
										if (checked) {
											predefinedChecked = true
										}
										return this.formatOption(option2, checked, index2)
									})}
								</div>
							)
						}

						const checked = compare(option, value)
						if (checked && !this.state.isEditableOptionActive) {
							predefinedChecked = true
						}
						return this.formatOption(option, checked, index)
					})}
					{editableOption && (
						<div
							key={`radio-group-${get(input, 'name')}-editable`}
							className='radio'
							onClick={() =>
								this.handleChange({
									value: null,
									activateEditableOption: true
								})
							}
						>
							<input
								checked={!predefinedChecked}
								type='radio'
								value={get(input, 'value')}
								name={get(input, 'name')}
								onChange={this.handleChange}
							/>
							<label className='radio-label'>Iné</label>
						</div>
					)}
				</div>
				{editableOption && !predefinedChecked && <div style={{ paddingTop: '10px' }}>{this.formatEditableOption()}</div>}
				<div className='text-danger'>{touched ? error : ''}</div>
			</>
		)
	}
}

export default RadioGroupField
