import { isEmpty, get } from 'lodash'
import dayjs from 'dayjs'

import { getReq } from '../../utils/request'
import { PROCESNY_KONFIGURATOR_LOAD_START, PROCESNY_KONFIGURATOR_LOAD_DONE, PROCESNY_KONFIGURATOR_LOAD_FAIL } from '../../types/procesnyKonfigurator'

import { minutesBetween } from '../../utils/date'

export function procesnyKonfiguratorLoadStart() {
	return {
		type: PROCESNY_KONFIGURATOR_LOAD_START
	}
}

export function procesnyKonfiguratorLoadDone(procesnyKonfigurator) {
	return {
		type: PROCESNY_KONFIGURATOR_LOAD_DONE,
		payload: {
			procesnyKonfigurator
		}
	}
}

export function procesnyKonfiguratorLoadFail() {
	return {
		type: PROCESNY_KONFIGURATOR_LOAD_FAIL
	}
}

export const loadProcesnyKonfigurator = () => {
	return async (dispatch, getState) => {
		const { procesnyKonfigurator, auth } = getState()

		const cacheTime = get(auth, 'config.frontendCacheTime')

		const lastLoadTimestamp = dayjs(get(procesnyKonfigurator, 'procesnyKonfigurator.lastLoad'), undefined, true)
		const diffDays = dayjs().diff(lastLoadTimestamp, 'day')

		// check if is need load fresh data or not
		if (
			!isEmpty(get(procesnyKonfigurator, 'procesnyKonfigurator.data')) &&
			cacheTime &&
			minutesBetween(get(procesnyKonfigurator, 'procesnyKonfigurator.lastLoad'), new Date()) < cacheTime &&
			diffDays === 0 // load fresh at least once per day
		) {
			return procesnyKonfigurator
		}
		try {
			dispatch(procesnyKonfiguratorLoadStart())
			const res = await getReq('/api/v0/procesny-konfigurator')
			dispatch(procesnyKonfiguratorLoadDone(res.response))
			return res.response
		} catch (e) {
			dispatch(procesnyKonfiguratorLoadFail())
		}
	}
}
