import { ZOSTATOK_ZMLUVNE_UCTY_LOAD_START, ZOSTATOK_ZMLUVNE_UCTY_LOAD_DONE, ZOSTATOK_ZMLUVNE_UCTY_LOAD_FAIL } from '../types/zostatokZmluvneUctyTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	zmluvneUcty: {}
}

export default (state = initState, action) => {
	switch (action.type) {
		case ZOSTATOK_ZMLUVNE_UCTY_LOAD_START: {
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					...action.payload.zmluvneUcty
				}
			}
		}
		case ZOSTATOK_ZMLUVNE_UCTY_LOAD_DONE:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					...action.payload.zmluvneUcty
				}
			}
		case ZOSTATOK_ZMLUVNE_UCTY_LOAD_FAIL:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					...action.payload.zmluvneUcty
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
