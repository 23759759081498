import React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'

// components
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'

// atoms
import Select from '../../atoms/DigitalizaciaBasicSelect'

// utils
import { checkPermissions } from '../../utils/permissionsHoc'
import { SUHLASY_WIDGET_KATEGORIA, SUHLAS_STAV } from '../../utils/enums'
import { setRouteParams } from '../../utils/routes'
import { history } from '../../utils/history'

// actions
import * as SpravaSuhlasovActions from '../../actions/ObchodniPartneri/SpravaSuhlasov'

class SpravaSuhlasovWidget extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}),
		t: PropTypes.func.isRequired,
		auth: PropTypes.shape(),
		suhlasy: PropTypes.shape()
	}

	_mounted = false

	constructor(props) {
		super(props)

		this.state = {}
	}

	componentDidMount() {
		const { spravaSuhlasovActions, auth } = this.props

		// TODO: Check if I shoud use id or name of businessChannel (CES ciselnik doesnt match BB ciselnik)
		const activeBusinessChannel = get(auth, 'businessChannel.actual.id')
		spravaSuhlasovActions.loadSpravaSuhlasov(activeBusinessChannel)

		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	commonContentContainer = (content) => {
		return <div className='box-content'>{content}</div>
	}

	transformSuhlasyToCategories = (suhlasy) => {
		const kategorizovaneSuhlasy = suhlasy.data.reduce((suhlasyObject, suhlas) => {
			const kategoria = suhlas.suhlasKategoria

			if (!suhlasyObject[kategoria]) {
				suhlasyObject[kategoria] = {}
				suhlasyObject[kategoria].items = []
			}

			suhlasyObject[kategoria].items.push(suhlas)
			return suhlasyObject
		}, {})

		return kategorizovaneSuhlasy
	}

	getOptions = (suhlas, t) => {
		const verzieSuhlasov = suhlas.suhlasVerzia

		if (Array.isArray(verzieSuhlasov) && verzieSuhlasov.length > 0) {
			return verzieSuhlasov.map((suhlasVerzia) => ({
				label: `${get(suhlas, 'nazov')} - ${t('translation:SpravaSuhlasov.Verzia')} ${suhlasVerzia.verzia}`,
				value: suhlasVerzia
			}))
		}

		return []
	}

	getStyleByState = (state) => {
		// colors are defined in global.less
		switch (state) {
			case SUHLAS_STAV.GREEN:
				return {
					backgroundColor: '#BAE2B8 !important',
					cursor: 'pointer'
				}
			case SUHLAS_STAV.GRAY:
				return {
					backgroundColor: '#F4F6F9 !important',
					cursor: 'pointer'
				}
			case SUHLAS_STAV.RED:
				return {
					backgroundColor: '#FFB6B6 !important',
					cursor: 'pointer'
				}
			default:
				return {
					backgroundColor: '#FFFFFF !important',
					cursor: 'pointer'
				}
		}
	}

	getStyles = (suhlas, selectOptions) => {
		return {
			option: (provided, { data }) => {
				return {
					...provided,
					...this.getStyleByState(get(data, 'value.stav')),
					color: '#191919'
				}
			},

			control: (provided) => ({
				...provided,
				...this.getStyleByState(get(suhlas, 'stav')),
				borderRadius: '5px',
				borderWidth: '0px',
				boxShadow: 'none !important'
			}),

			placeholder: (provided) => ({
				...provided,
				top: '50%',
				color: '#191919'
			}),

			indicatorSeparator: (provided) => ({
				...provided,
				backgroundColor: '#191919'
			}),

			dropdownIndicator: (provided) => ({
				...provided,
				color: '#191919'
			}),

			indicatorsContainer: (provided) => ({
				...provided,
				display: selectOptions.length > 0 ? 'flex' : 'none'
			})
		}
	}

	redirectToUri = () => {
		const { interakcia } = this.props

		const opCislo = get(interakcia, 'opCislo')
		const url = setRouteParams(`/op/${opCislo}/suhlasy`)
		history.push(url)
	}

	getActiveCategory = () => {
		const { suhlasy } = this.props
		const activeSuhlasy = get(suhlasy, 'data', []).find((category) => category.suhlasKategoria.id === SUHLASY_WIDGET_KATEGORIA)

		return get(activeSuhlasy, 'suhlasDefinicia', [])
	}

	render() {
		const { t, suhlasy, permission, auth } = this.props

		const hasPermission = checkPermissions(get(auth, 'user.roles', []), [permission])

		if (!hasPermission) {
			return this.commonContentContainer(<ElementFailure text='Na zobrazenie informácií nemáte potrebné oprávnenia.' />)
		}

		if (!suhlasy || suhlasy.isLoading) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (suhlasy.isFailure) {
			return this.commonContentContainer(<ElementFailure text={t('containers:SpravaSuhlasovPage.Nepodarilo sa načítať zoznam súhlasov')} />)
		}

		if (suhlasy.isPrepared === false) {
			return this.commonContentContainer(<ElementLoading />)
		}

		const suhlasyActiveCategory = this.getActiveCategory()

		return (
			<div className='box-content'>
				{suhlasyActiveCategory &&
					suhlasyActiveCategory.map((suhlas) => {
						const selectOptions = this.getOptions(suhlas, t)

						return (
							<Select
								className='consent-widget'
								key={get(suhlas, 'id')}
								name={get(suhlas, 'nazov')}
								placeholder={get(suhlas, 'nazov')}
								options={selectOptions}
								classNamePrefix='react-select'
								isSearchable={false}
								isDisabled={selectOptions.length === 0}
								customStyles={this.getStyles(suhlas, selectOptions)}
								onChange={this.redirectToUri}
							/>
						)
					})}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: state.auth,
	suhlasy: state.obchodnyPartner.suhlasy
})

const mapDispatchToProps = (dispatch) => ({
	spravaSuhlasovActions: bindActionCreators(SpravaSuhlasovActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(SpravaSuhlasovWidget)
