/* eslint-disable no-console */
import axios from 'axios'
import config from './config'
import { getAccessToken, isLoggedIn } from './auth'

// TODO: CARE-XXX Change this config
const host = config.baseUrl

export { host }

function buildHeaders() {
	const headers = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Access-Control-Allow-Credentials': true,
		'Cache-Control': 'no-cache, no-store',
		Pragma: 'no-cache'
	}

	if (isLoggedIn()) {
		headers.Authorization = `Bearer ${getAccessToken()}`
	}
	return headers
}

/**
 * @param string api endpoint
 * @param Object query object
 * @param function callback(err, res)
 * @param string accept header
 * @return Promise response
 * Performs get request to url and returns callback with result
 */
export function getReq(url, params, callback, accept, options) {
	const config = {
		headers: buildHeaders()
	}
	delete config['Content-Type']

	if (accept) {
		config.headers.Accept = accept
	}

	if (params) {
		config.params = params
	}

	return axios
		.get(host + url, { ...config, ...options })
		.then((res) => {
			if (!res) {
				return res
			}
			callback && callback(null, res.data)
			return res.data
		})
		.catch((err) => {
			callback && callback(err, null)
			throw err
		})
}

/**
 * @param string api endpoint
 * @param Object query object
 * @param Object body
 * @param function callback(err, res)
 * @return Promise response
 * Performs post request to url and returns callback with result
 */
export function postReq(url, params, data, callback) {
	const config = {
		headers: buildHeaders()
	}
	if (params) {
		config.params = params
	}

	return axios
		.post(host + url, data || {}, config)
		.then((res) => {
			if (!res) {
				return res
			}
			callback && callback(null, res.data)
			return res.data
		})
		.catch((err) => {
			callback && callback(err, null)
			throw err
		})
}

/**
 * @param string api endpoint
 * @param Object query object
 * @param Object body
 * @param function callback(err, res)
 *
 * Performs put request to url and returns callback with result
 */
export function putReq(url, params, data, callback) {
	const config = {
		headers: buildHeaders()
	}

	if (params) {
		config.params = params
	}

	return axios
		.put(host + url, data || {}, config)
		.then((res) => {
			if (!res) {
				return res
			}
			callback && callback(null, res.data)
			return res.data
		})
		.catch((err) => {
			callback && callback(err, null)
			throw err
		})
}

/**
 * @param string api endpoint
 * @param Object query object
 * @param Object body
 *
 * Performs delete request to url and returns with result
 */
export const deleteReq = (url, params) => {
	const config = {
		headers: buildHeaders()
	}

	if (params) {
		config.params = params
	}

	return axios
		.delete(host + url, config)
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			throw err
		})
}
