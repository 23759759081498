import React from 'react'
import { map, get, split, forEach, filter, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'

// utils
import { DIRECTION } from '../utils/enums'

class Table extends React.Component {
	state = {
		columns: []
	}

	componentDidMount() {
		this.normalizeColumns(this.props.triedenie)
	}

	componentDidUpdate(prevProps) {
		const { triedenie } = this.props
		if (prevProps.triedenie !== triedenie) {
			this.normalizeColumns(triedenie)
		}
	}

	normalizeColumns = (triedenie) => {
		const { columns } = this.props
		const triedenieCols = split(triedenie, ',')
		const normalizeCols = map(columns, (col) => {
			let column = col
			forEach(triedenieCols, (triedenieCol, index) => {
				const directionName = split(triedenieCol, ':')
				if (get(directionName, '[0]') === col.name) {
					column = {
						...col,
						order: index + 1,
						direction: get(directionName, '[1]')
					}
				}
			})
			return column
		})
		this.setState({ columns: normalizeCols })
	}

	toogleDirection = (direction) => {
		switch (direction) {
			case DIRECTION.DESC: {
				return DIRECTION.ASC
			}
			case DIRECTION.ASC: {
				return undefined
			}
			default:
				return DIRECTION.DESC
		}
	}

	onChangeDirection = (changedColumn) => {
		const { sortingColumn } = this.props
		const { columns } = this.state

		const newDirection = this.toogleDirection(changedColumn.direction)
		const correction = newDirection ? 1 : -1
		const newColumns = map(columns, (column) => {
			if (column.name == changedColumn.name) {
				return {
					...column,
					order: newDirection ? 1 : undefined,
					direction: newDirection
				}
			}

			return {
				...column,
				order: column.order ? column.order + correction : undefined
			}
		})
		this.setState(
			{
				columns: newColumns
			},
			() => {
				if (!sortingColumn) {
					return
				}
				const stringFormat = map(
					orderBy(
						filter(this.state.columns, (column) => {
							return column.direction && column.name && column.order
						}),
						['order'],
						['asc']
					),
					(column) => `${column.name}:${column.direction}`
				).join(',')
				const normalized = sortingColumn(stringFormat)
				if (normalized != stringFormat) {
					this.normalizeColumns(normalized)
				}
			}
		)
	}

	render() {
		const columnsItems = map(this.state.columns, (column, index) => {
			if (column.notActive) {
				return (
					<th
						className='disabled'
						key={index}
						style={{
							width: column.width,
							textAlign: column.textAlign || 'center',
							padding: column.padding
						}}
					>
						{column.title && <span className='title'>{column.title}</span>}
					</th>
				)
			}
			if (column.tooltip) {
				return (
					<th className={column.direction} key={index} style={{ width: column.width }} onClick={() => this.onChangeDirection(column)}>
						<Tooltip title={column.tooltip} position='top-start' trigger='mouseenter' theme='light'>
							<div>
								<span className='sort' />
								<span className='title'>{column.title}</span>
							</div>
						</Tooltip>
					</th>
				)
			}
			return (
				<th className={column.direction} key={index} style={{ width: column.width }} onClick={() => this.onChangeDirection(column)}>
					<span className='sort' />
					<span className='title'>{column.title}</span>
				</th>
			)
		})

		return (
			<table className='static-header sortable' cellSpacing='0' style={{ ...this.props.style }}>
				<thead>
					<tr>{columnsItems}</tr>
				</thead>
			</table>
		)
	}
}

Table.propTypes = {
	sortingColumn: PropTypes.func,
	columns: PropTypes.shape({
		name: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired
	}).isRequired,
	triedenie: PropTypes.string.isRequired,
	style: PropTypes.shape()
}

export default Table
