import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { get, map, isEmpty } from 'lodash'
import PropTypes from 'prop-types'

// utils
import { getReq } from '../../utils/request'
import { formatDate } from '../../utils/date'
import { checkPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { setRouteParams, FAKTURY_DETAIL } from '../../utils/routes'

// components
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'
import ElementEmptyContent from '../ElementEmptyContent'

class FakturyWidget extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape()
	}

	constructor(props) {
		super(props)
		this.state = {
			hasPermission: checkPermissions(get(props, 'authUser.roles', []), [PERMISSIONS.VIEW_FAKTURY]),
			faktury: {
				data: [],
				stranka: 1,
				velkostStranky: 3,
				isLoading: false,
				isFailure: false,
				isLastPage: false
			}
		}
	}

	_mounted = false

	loadFakturyByParts = (page = 1) => {
		const { interakcia } = this.props
		const { faktury } = this.state
		try {
			if (!this._mounted) {
				return
			}
			this.setState(
				{
					faktury: {
						...this.state.faktury,
						stranka: page,
						isLoading: true
					}
				},
				async () => {
					// TODO: Remove all deprecated endpoint
					const response = await getReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/faktury`, {
						stranka: page,
						velkostStranky: faktury.velkostStranky
					})
					const { velkostStranky, aktualnaStranka, zaznamov } = get(response, 'response.strankovanie')

					const isLastPage = !(velkostStranky * aktualnaStranka < zaznamov)
					const data = get(response, 'response.obsah', [])
					if (!this._mounted) {
						return
					}
					this.setState({
						faktury: {
							...this.state.faktury,
							data: page === 1 ? data : faktury.data.concat(data),
							isLoading: false,
							isLastPage
						}
					})
				}
			)
		} catch (e) {
			if (!this._mounted) {
				return
			}
			this.setState({
				faktury: {
					...this.state.faktury,
					isLoading: false,
					isFailure: true
				}
			})
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidMount() {
		this._mounted = true
		const { hasPermission } = this.state
		if (hasPermission) {
			this.loadFakturyByParts()
		}
	}

	onScrollHandler = (e) => {
		const { faktury } = this.state
		const { isLoading, isLastPage, stranka } = faktury
		const { clientHeight, scrollTop, scrollHeight } = e.target

		if (scrollHeight - clientHeight >= scrollTop - 5 && !isLastPage && !isLoading) {
			const fakturyNextPage = stranka + 1
			this.loadFakturyByParts(fakturyNextPage)
		}
	}

	commonContentContainer = (content) => {
		return (
			<div className='box-content' onScroll={this.onScrollHandler}>
				{content}
			</div>
		)
	}

	render() {
		const { interakcia } = this.props
		const { faktury, hasPermission } = this.state

		if (!hasPermission) {
			return this.commonContentContainer(<ElementFailure text='Na zobrazenie informácií nemáte potrebné oprávnenia.' />)
		}

		if (faktury.isLoading && faktury.stranka === 1) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (faktury.isFailure) {
			return this.commonContentContainer(<ElementFailure text='Faktúry sa nepodarilo načitať.' />)
		}

		if (isEmpty(faktury.data)) {
			return this.commonContentContainer(<ElementEmptyContent text='Pre obchodného partnera neevidujeme žiadne faktúry.' />)
		}

		const invoices = map(faktury.data, (faktura) => {
			const suma = get(faktura, 'sumaSpolu')
			const fakturaSuma = suma ? `${suma} €` : '-'
			return (
				<Link
					key={`faktura-${faktura.cislo}`}
					to={setRouteParams(FAKTURY_DETAIL, get(interakcia, 'data.opCislo'), get(faktura, 'cislo', ''))}
					className='inner-box'
				>
					<table>
						<tbody>
							<tr>
								<td>Faktúra</td>
								<td className='text-right'>
									<strong>{get(faktura, 'cislo', '-')}</strong>
								</td>
							</tr>
							<tr>
								<td>Dátum vystavenia</td>
								<td className='text-right'>
									<strong>{formatDate(get(faktura, 'vystavenieDatum'), null, 'DD.MM.YYYY')}</strong>
								</td>
							</tr>
							<tr>
								<td>K úhrade</td>
								<td className='text-right'>
									<strong>{fakturaSuma}</strong>
								</td>
							</tr>
							{/* <tr>
							<td>Stav úhrady</td>
							<td className="text-right">
								<div className="label" data-color="green">Uhradená</div>
							</td>
						</tr> */}
						</tbody>
					</table>
				</Link>
			)
		})
		if (faktury.isLoading) {
			invoices.push(
				<div key='faktura-loading' className='inner-box'>
					<table>
						<tbody>
							<tr>
								<td>
									<ElementLoading />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			)
		}
		return this.commonContentContainer(invoices)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	authUser: state.auth.user
})

export default connect(mapStateToProps, null)(FakturyWidget)
