import { get, set, map, filter, reduce, isEmpty, split, includes, orderBy, concat, some, size } from 'lodash'
import dayjs from 'dayjs'
import Qs from 'qs'
import {
	OBCHODNY_PARTNER_POHLADAVKY_LOAD_START,
	OBCHODNY_PARTNER_POHLADAVKY_LOAD_DONE,
	OBCHODNY_PARTNER_POHLADAVKY_LOAD_FAIL,
	OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_START,
	OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_DONE,
	OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_FAIL,
	OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_START,
	OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_DONE,
	OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_FAIL,
	OBCHODNY_PARTNER_POHLADAVKY_HIGHLIGHT
} from '../../types/obchodnyPartner'
import {
	PREPLATKY_ALL_LOAD_START,
	PREPLATKY_LOAD_START,
	PREPLATKY_ALL_LOAD_PART_DONE,
	PREPLATKY_LOAD_DONE,
	PREPLATKY_LOAD_FAIL,
	PREPLATKY_CLEAR,
	BLOKOVANIE_UPOMINANIA_ALL_LOAD_START,
	BLOKOVANIE_UPOMINANIA_LOAD_START,
	BLOKOVANIE_UPOMINANIA_ALL_LOAD_PART_DONE,
	BLOKOVANIE_UPOMINANIA_LOAD_DONE,
	BLOKOVANIE_UPOMINANIA_LOAD_FAIL,
	BLOKOVANIE_UPOMINANIA_CLEAR
} from '../../types/pohladavkyTypes'

import { getReq } from '../../utils/request'
import { POHLADAVKA_CUSTOM_TYPE, POHLADAVKA_VYROVNANIE_STATUS } from '../../utils/enums'

const POHLADAVKY_TYP_LEN_PLATBY = ['5', '7']

export const loadPohladavky = (page, pageSize = 15, queryParams, pohladavkaTypCiselnik) => {
	return async (dispatch, getStore) => {
		try {
			const store = getStore()
			const interakcia = store.interakcie.detail
			dispatch({
				type: OBCHODNY_PARTNER_POHLADAVKY_LOAD_START,
				payload: {
					page
				}
			})

			const query = {
				...queryParams,
				velkostStranky: pageSize,
				stranka: page
			}

			const paramsSerializer = (query) => Qs.stringify(query, { arrayFormat: 'repeat', skipNulls: true })

			if (query.typ) {
				let typy = query.typ.split(',')
				if (includes(typy, POHLADAVKA_CUSTOM_TYPE.LEN_PLATBY)) {
					typy = POHLADAVKY_TYP_LEN_PLATBY
				}
				if (includes(typy, POHLADAVKA_CUSTOM_TYPE.OKREM_PLATIEB)) {
					typy = map(
						filter(pohladavkaTypCiselnik, (typ) => !includes(POHLADAVKY_TYP_LEN_PLATBY, `${typ.id}`)),
						(typ) => `${typ.id}`
					)
				}
				query.typ = typy.join(',')
			}

			const pohladavkyData = await getReq(`/api/v2/op/${get(interakcia, 'data.opCislo')}/pohladavky`, query, null, null, { paramsSerializer })

			const pohladavky =
				page == 1
					? get(pohladavkyData, 'response.content', [])
					: concat(get(store, 'obchodnyPartner.pohladavky.data', []), get(pohladavkyData, 'response.content', []))

			dispatch({
				type: OBCHODNY_PARTNER_POHLADAVKY_LOAD_DONE,
				payload: {
					pohladavky,
					isLastPage: size(get(pohladavkyData, 'response.content', [])) < pageSize
				}
			})
		} catch (e) {
			dispatch({
				type: OBCHODNY_PARTNER_POHLADAVKY_LOAD_FAIL
			})
		}
	}
}

export const loadPohladavkyZostatok = (zuCislo) => {
	return async (dispatch, getStore) => {
		try {
			const store = getStore()
			const interakcia = store.interakcie.detail
			dispatch({
				type: OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_START
			})

			const res = zuCislo
				? await getReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/zmluvne-ucty/${zuCislo}/zostatok`, null, null, null)
				: await getReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/pohladavky/zostatok`, null, null, null)

			dispatch({
				type: OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_DONE,
				payload: { data: res.response.obsah }
			})
		} catch (e) {
			dispatch({
				type: OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_FAIL
			})
		}
	}
}

export const loadPohladavkaDetail = (idPohladavky, queryParams, pohladavkaTypCiselnik) => {
	return async (dispatch, getStore) => {
		try {
			const store = getStore()
			const interakcia = store.interakcie.detail

			const POHLADAVKY_TYP_OKREM_PLATIEB = map(
				filter(pohladavkaTypCiselnik, (typ) => !includes(POHLADAVKY_TYP_LEN_PLATBY, `${typ.id}`)),
				(typ) => `${typ.id}`
			)

			const storedPohladavka = store.obchodnyPartner.pohladavkaDetail
			if (idPohladavky == storedPohladavka.idPohladavky && !storedPohladavka.isLoading && !storedPohladavka.isFailure) {
				dispatch({
					type: OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_DONE,
					payload: {
						data: storedPohladavka.data,
						filtered: filterPohladavkaDetailData(storedPohladavka.data, queryParams, POHLADAVKY_TYP_OKREM_PLATIEB),
						idPohladavky
					}
				})
				return
			}

			dispatch({ type: OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_START })

			const alreadyLoaded = {}

			const loadPohladavka = async (idPohladavkyParam) => {
				set(alreadyLoaded, idPohladavkyParam, true)

				const pohladavkaDetailResponse = await getReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/pohladavky/${idPohladavkyParam}`)
				const pohladavkaDetail = get(pohladavkaDetailResponse, 'response.obsah', [])

				const suvisiaceDokladyPohladavka = reduce(
					pohladavkaDetail,
					(all, pohladavka) => {
						return all.concat(
							reduce(
								get(pohladavka, 'suvisiaceDoklady', []),
								(relevant, suvisiaciDoklad) => {
									if (!suvisiaciDoklad.cislo || get(alreadyLoaded, suvisiaciDoklad.cislo)) {
										return relevant
									}
									set(alreadyLoaded, suvisiaciDoklad.cislo, true)
									relevant.push(suvisiaciDoklad)
									return relevant
								},
								[]
							)
						)
					},
					[]
				)

				const helperDetailResponse = await getReq(
					`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/pohladavky/${idPohladavkyParam}/vyrovnavane-doklady`
				)
				const helperDetail = get(helperDetailResponse, 'response.obsah', [])

				const suvisiaceDokladyHelper = reduce(
					helperDetail,
					(all, suvisiaciDoklad) => {
						if (!suvisiaciDoklad.vyrovnavanaPohladavkaCislo || alreadyLoaded[suvisiaciDoklad.vyrovnavanaPohladavkaCislo]) {
							return all
						}
						set(alreadyLoaded, suvisiaciDoklad.vyrovnavanaPohladavkaCislo, true)
						all.push({
							id: suvisiaciDoklad.vyrovnavanaPohladavkaId,
							cislo: suvisiaciDoklad.vyrovnavanaPohladavkaCislo
						})
						return all
					},
					[]
				)

				const suvisiaceDoklady = concat(suvisiaceDokladyPohladavka, suvisiaceDokladyHelper)
				const suvisiacePohladavkyForLoading = map(suvisiaceDoklady, (suvisiaciDoklad) => loadPohladavka(suvisiaciDoklad.cislo))
				const suvisiacePohladavky = await Promise.all(suvisiacePohladavkyForLoading)

				return [].concat.apply(pohladavkaDetail, suvisiacePohladavky)
			}

			const pohladavkaDetailGroup = await Promise.all(map(split(idPohladavky, ','), (id) => loadPohladavka(id)))
			const pohladavkaDetail = [].concat.apply(...pohladavkaDetailGroup)

			const notEmptyPohladavkaDetail = filter(pohladavkaDetail, (pohladavka) => !isEmpty(pohladavka))

			dispatch({
				type: OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_DONE,
				payload: {
					data: notEmptyPohladavkaDetail,
					filtered: queryParams
						? filterPohladavkaDetailData(notEmptyPohladavkaDetail, queryParams, POHLADAVKY_TYP_OKREM_PLATIEB)
						: notEmptyPohladavkaDetail,
					idPohladavky
				}
			})
		} catch (e) {
			dispatch({ type: OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_FAIL })
		}
	}
}

export const highlightPohladavky = (cislo) => {
	return (dispatch, getStore) => {
		const store = getStore()
		const pohladavky = get(store, 'obchodnyPartner.pohladavky.data', [])
		const pohladavkaDetail = get(store, 'obchodnyPartner.pohladavkaDetail.filtered', [])
		if (!cislo) {
			dispatch({
				type: OBCHODNY_PARTNER_POHLADAVKY_HIGHLIGHT,
				payload: {
					pohladavky: map(pohladavky, (pohladavka) => {
						return {
							...pohladavka,
							highlighted: false,
							highlightedNumber: null
						}
					}),
					pohladavkaDetail: map(pohladavkaDetail, (pohladavka) => {
						return {
							...pohladavka,
							highlighted: false,
							highlightedNumber: null
						}
					})
				}
			})
		} else {
			dispatch({
				type: OBCHODNY_PARTNER_POHLADAVKY_HIGHLIGHT,
				payload: {
					pohladavky: map(pohladavky, (pohladavka) => {
						return {
							...pohladavka,
							highlighted:
								get(pohladavka, 'cislo') == cislo ||
								!!some(get(pohladavka, 'suvisiaceDoklady', []), (doklad) => doklad.cislo && doklad.cislo == cislo),
							highlightedNumber: cislo
						}
					}),
					pohladavkaDetail: map(pohladavkaDetail, (pohladavka) => {
						return {
							...pohladavka,
							highlighted:
								get(pohladavka, 'cislo') == cislo ||
								!!some(get(pohladavka, 'suvisiaceDoklady', []), (doklad) => doklad.cislo && doklad.cislo == cislo),
							highlightedNumber: cislo
						}
					})
				}
			})
		}
	}
}

function filterPohladavkaDetailData(data, queryParams, POHLADAVKY_TYP_OKREM_PLATIEB) {
	const temp = filter(data, (pohladavka) => {
		return (
			filterVariabilnySymbol(pohladavka, queryParams.variabilnySymbol) &&
			filterVyrovnanieStatus(pohladavka, queryParams.vyrovnanieStatus) &&
			filterZmluvnyUcetCislo(pohladavka, queryParams.zmluvnyUcetCislo) &&
			filterTyp(pohladavka, queryParams.typ, POHLADAVKY_TYP_OKREM_PLATIEB) &&
			filterDatumOd(pohladavka, queryParams.datumOd) &&
			filterDatumDo(pohladavka, queryParams.datumDo)
		)
	})

	const triedenieQueryParams = queryParams.triedenie ? queryParams.triedenie.split(',') : []

	const triedenieOrderParam = reduce(
		triedenieQueryParams,
		(target, param) => {
			const [attributes, orders] = target
			const [attribute, order] = param.split(':')
			if (!attribute) {
				return target
			}
			switch (attribute) {
				case 'typ':
					attributes.push((pohladavka) => get(pohladavka, 'typ.id'))
					break
				case 'dokladDatum':
				case 'splatnostDatum':
					attributes.push((pohladavka) => dayjs(get(pohladavka, attribute)))
					break
				default:
					attributes.push(attribute)
			}
			orders.push(order || 'asc')
			return target
		},
		[[], []]
	)

	return orderBy(temp, ...triedenieOrderParam)
}

function filterVariabilnySymbol(pohladavka, queryParam) {
	return queryParam ? get(pohladavka, 'variabilnySymbol') == queryParam : true
}

function filterVyrovnanieStatus(pohladavka, queryParam) {
	return queryParam ? get(pohladavka, 'vyrovnanieStatus.id') == queryParam : true
}

function filterZmluvnyUcetCislo(pohladavka, queryParam) {
	return queryParam ? includes(get(pohladavka, 'zuCisla', []), queryParam) : true
}

function filterTyp(pohladavka, queryParam, POHLADAVKY_TYP_OKREM_PLATIEB) {
	if (!queryParam) {
		return true
	}
	const typy = queryParam.split(',')
	if (includes(typy, POHLADAVKA_CUSTOM_TYPE.LEN_PLATBY)) {
		return includes(POHLADAVKY_TYP_LEN_PLATBY, `${get(pohladavka, 'typ.id')}`)
	}
	if (includes(typy, POHLADAVKA_CUSTOM_TYPE.OKREM_PLATIEB)) {
		return includes(POHLADAVKY_TYP_OKREM_PLATIEB, `${get(pohladavka, 'typ.id')}`)
	}
	return includes(split(queryParam, ','), `${get(pohladavka, 'typ.id')}`)
}

function filterDatumOd(pohladavka, queryParam) {
	return queryParam ? dayjs(pohladavka.dokladDatum) >= dayjs(queryParam) : true
}

function filterDatumDo(pohladavka, queryParam) {
	return queryParam ? dayjs(pohladavka.dokladDatum) <= dayjs(queryParam) : true
}

export const loadPreplatky = (page, pageSize = 15, queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		const query = {
			...queryParams,
			vyrovnanieStatus: POHLADAVKA_VYROVNANIE_STATUS.OTVORENA,
			lenPreplatky: true,
			velkostStranky: pageSize,
			stranka: page
		}

		dispatch({
			type: PREPLATKY_LOAD_START,
			payload: {
				page
			}
		})

		try {
			const preplatkyData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/pohladavky`, query)

			// if is page one assing current data if page is bigger merge with pevious data
			const preplatky =
				page == 1
					? get(preplatkyData, 'response.content', [])
					: concat(get(store, 'preplatky.preplatky.data', []), get(preplatkyData, 'response.content', []))

			dispatch({
				type: PREPLATKY_LOAD_DONE,
				payload: {
					preplatky,
					isLastPage: size(get(preplatkyData, 'response.content', [])) < pageSize
				}
			})
			return preplatky
		} catch (e) {
			dispatch({
				type: PREPLATKY_LOAD_FAIL
			})
		}
	}
}

async function loadPreplatkyByParts(opCislo, dispatch, pageSize = 1, queryParams = {}, getStore) {
	const loadPage = async (page) => {
		const store = getStore()
		const query = {
			...queryParams,
			vyrovnanieStatus: POHLADAVKA_VYROVNANIE_STATUS.OTVORENA,
			lenPreplatky: true,
			velkostStranky: pageSize,
			stranka: page
		}
		try {
			const preplatkyData = await getReq(`/api/v2/op/${opCislo}/pohladavky`, query)

			// if is page one assing current data if page is bigger merge with pevious data
			const preplatky =
				page == 1
					? get(preplatkyData, 'response.content', [])
					: concat(get(store, 'preplatky.preplatky.data', []), get(preplatkyData, 'response.content', []))

			dispatch({
				type: PREPLATKY_ALL_LOAD_PART_DONE,
				payload: {
					preplatky
				}
			})

			if (size(get(preplatkyData, 'response.content', [])) >= pageSize) {
				await loadPage(page + 1)
			} else {
				dispatch({
					type: PREPLATKY_LOAD_DONE,
					payload: {
						preplatky,
						isLastPage: true
					}
				})
				return preplatky
			}
		} catch (e) {
			dispatch({
				type: PREPLATKY_LOAD_FAIL
			})
		}
	}
	return await loadPage(1)
}

export const loadAllPreplatky = (queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: PREPLATKY_ALL_LOAD_START
		})

		return await loadPreplatkyByParts(get(interakcia, 'opCislo'), dispatch, 100, queryParams, getStore)
	}
}

export const preplatkyClear = () => {
	return (dispatch) => {
		dispatch({
			type: PREPLATKY_CLEAR
		})
	}
}

export const loadBlokovanieUpominania = (page, pageSize = 15, queryParams = {}, pohladavkaTypCiselnik) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		const query = {
			...queryParams,
			typ: map(
				filter(pohladavkaTypCiselnik, (typ) => !includes(POHLADAVKY_TYP_LEN_PLATBY, `${typ.id}`)),
				(typ) => `${typ.id}`
			).join(','),
			outputBlokovanie: true,
			lenNedoplatky: true,
			velkostStranky: pageSize,
			stranka: page
		}

		dispatch({
			type: BLOKOVANIE_UPOMINANIA_LOAD_START,
			payload: {
				page
			}
		})

		try {
			const blokovanieUpominaniaData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/pohladavky`, query)

			// if is page one assing current data if page is bigger merge with pevious data
			const blokovanieUpominania =
				page == 1
					? get(blokovanieUpominaniaData, 'response.content', [])
					: concat(get(store, 'blokovanieUpominania.blokovanieUpominania.data', []), get(blokovanieUpominaniaData, 'response.content', []))

			dispatch({
				type: BLOKOVANIE_UPOMINANIA_LOAD_DONE,
				payload: {
					blokovanieUpominania,
					isLastPage: size(get(blokovanieUpominaniaData, 'response.content', [])) < pageSize
				}
			})
			return blokovanieUpominania
		} catch (e) {
			dispatch({
				type: BLOKOVANIE_UPOMINANIA_LOAD_FAIL
			})
		}
	}
}

async function loadBlokovanieUpominaniaByParts(opCislo, dispatch, pageSize = 1, queryParams = {}, getStore, pohladavkaTypCiselnik) {
	const loadPage = async (page) => {
		const store = getStore()
		const query = {
			...queryParams,
			typ: map(
				filter(pohladavkaTypCiselnik, (typ) => !includes(POHLADAVKY_TYP_LEN_PLATBY, `${typ.id}`)),
				(typ) => `${typ.id}`
			).join(','),
			outputBlokovanie: true,
			lenNedoplatky: true,
			velkostStranky: pageSize,
			stranka: page
		}
		try {
			const blokovanieUpominaniaData = await getReq(`/api/v2/op/${opCislo}/pohladavky`, query)

			// if is page one assing current data if page is bigger merge with pevious data
			const blokovanieUpominania =
				page == 1
					? get(blokovanieUpominaniaData, 'response.content', [])
					: concat(get(store, 'blokovanieUpominania.blokovanieUpominania.data', []), get(blokovanieUpominaniaData, 'response.content', []))

			dispatch({
				type: BLOKOVANIE_UPOMINANIA_ALL_LOAD_PART_DONE,
				payload: {
					blokovanieUpominania
				}
			})

			if (size(get(blokovanieUpominaniaData, 'response.content', [])) >= pageSize) {
				await loadPage(page + 1)
			} else {
				dispatch({
					type: BLOKOVANIE_UPOMINANIA_LOAD_DONE,
					payload: {
						blokovanieUpominania,
						isLastPage: true
					}
				})
				return blokovanieUpominania
			}
		} catch (e) {
			dispatch({
				type: BLOKOVANIE_UPOMINANIA_LOAD_FAIL
			})
		}
	}
	return await loadPage(1)
}

export const loadAllBlokovanieUpominania = (queryParams = {}, pohladavkaTypCiselnik) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: BLOKOVANIE_UPOMINANIA_ALL_LOAD_START
		})

		return await loadBlokovanieUpominaniaByParts(get(interakcia, 'opCislo'), dispatch, 100, queryParams, getStore, pohladavkaTypCiselnik)
	}
}

export const blokovanieUpominaniaClear = () => {
	return (dispatch) => {
		dispatch({
			type: BLOKOVANIE_UPOMINANIA_CLEAR
		})
	}
}
