import React from 'react'
import PropTypes from 'prop-types'

function UkonDuplikatUpomienky(props) {
	const { dokumenty, formatDocuments } = props

	return (
		<tbody>
			<tr>
				<td colSpan={2}>{formatDocuments(dokumenty)}</td>
			</tr>
		</tbody>
	)
}

UkonDuplikatUpomienky.propTypes = {
	ukon: PropTypes.shape(),
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	dokumenty: PropTypes.array,
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonDuplikatUpomienky
