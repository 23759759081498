import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { get, find, isEmpty, set, head } from 'lodash'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import qs from 'qs'
import ReactQueryParams from 'react-query-params'

// components
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import DefaultModal from '../../components/Modals/DefaultModal'
import ModalSendItemDuplicate from '../../components/Modals/ModalSendItemDuplicate'
import { CHANNEL_TO_CHANGE_SELECTOR } from '../../components/BusinessChannelDropdownMenu'

// utils
import { getReq, postReq } from '../../utils/request'
import { getAccessToken } from '../../utils/auth'
import config from '../../utils/config'
import { setRouteParams, POHLADAVKY_ZOZNAM } from '../../utils/routes'
import { NOTIFICATION_TYPES, UKONY, UKONY_CISELNIK, FILTER_SELECTORS } from '../../utils/enums'
import { history } from '../../utils/history'
import Permissions, { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'

// actions
import * as TrackingActions from '../../actions/TrackingActions'
import * as HistoriaUkonovActions from '../../actions/UkonyActions'

class UctovnyDokladDetailPage extends ReactQueryParams {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		interakcia: PropTypes.shape({
			id: PropTypes.any.isRequired
		}).isRequired,
		auth: PropTypes.shape().isRequired,
		trackingActions: PropTypes.shape().isRequired,
		historiaUkonovActions: PropTypes.shape({
			clearUkonId: PropTypes.func.isRequired
		}).isRequired,
		tracking: PropTypes.shape().isRequired,
		ciselniky: PropTypes.shape().isRequired,
		channelToSwitch: PropTypes.shape(),
		forbiddenModal: PropTypes.bool,
		computedMatch: PropTypes.shape(),
		t: PropTypes.func.isRequired
	}

	defaultQueryParams = {
		backUrl: ''
	}

	_mounted = false

	constructor(props) {
		super(props)

		const { ciselniky, auth, interakcia } = props

		const { backUrl } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		} else {
			backUrlLink = setRouteParams(POHLADAVKY_ZOZNAM, get(interakcia, 'opCislo'))
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			backUrl: ''
		})

		this.state = {
			loading: false,
			document: null,
			documentHidden: false,
			redirect: false,
			backUrlLink
		}
	}

	loadDocument = async () => {
		try {
			const { interakcia, computedMatch } = this.props

			const pohladavkyResponse = await getReq(
				`/api/v0/obchodni-partneri/${get(interakcia, 'opCislo')}/pohladavky/${get(computedMatch, 'params.idPohladavky')}`
			)
			const pohladavka = find(
				get(pohladavkyResponse, 'response.obsah', []),
				(pohladavka) => get(pohladavka, 'id') == get(computedMatch, 'params.idPohladavky')
			)

			this.setState({ pohladavka })
			if (get(pohladavka, 'dokumentId')) {
				const dokumentId = get(pohladavka, 'dokumentId')
				const accessToken = getAccessToken()
				this.setState({
					loading: false,
					document: `${config.baseUrl}/api/v0/dokumenty/${dokumentId}?accessToken=${accessToken}`
				})
			} else {
				this.setState({
					loading: false,
					documentFail: true
				})
			}
		} catch (e) {
			this.setState({
				loading: false,
				documentFail: true
			})
		}
	}

	componentDidMount = () => {
		this._mounted = true

		if (this._mounted) {
			this.setState({
				loading: true
			})
			this.loadDocument()
		}
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	confirmModal = () => {
		const { interakcia } = this.props
		const { success, redirect } = this.state

		if (success) {
			this.setState(
				{
					result: null,
					success: false
				},
				() => {
					if (redirect) {
						history.push(setRouteParams(POHLADAVKY_ZOZNAM, get(interakcia, 'opCislo')))
					} else {
						this.setState({
							documentHidden: false
						})
					}
				}
			)
		} else {
			this.setState({
				result: null
			})
		}
	}

	onSubmitUkonUC18 = async (data) => {
		const { interakcia, auth, ciselniky, tracking, trackingActions, historiaUkonovActions, t, selectedFiltersUkony } = this.props
		const { pohladavka } = this.state

		try {
			let notifikacie = {}
			if (get(data, 'address')) {
				set(notifikacie, 'adresyPosta', [get(data, 'address')])
			}

			if (get(data, 'email')) {
				set(notifikacie, 'adresyUri', [
					{
						typ: NOTIFICATION_TYPES.EMAIL,
						hodnota: get(data, 'email')
					}
				])
			}

			if (get(data, 'typ') === NOTIFICATION_TYPES.PRINTER) {
				set(notifikacie, 'adresyUri', [
					{
						typ: NOTIFICATION_TYPES.PRINTER,
						poradie: 0
					}
				])
			}

			notifikacie = isEmpty(notifikacie) ? undefined : notifikacie

			const body = {
				typ: find(get(ciselniky, 'data.ukonTyp', []), (ukonTyp) => get(ukonTyp, 'id') == UKONY_CISELNIK.DUPLIKAT_UCTOVNEHO_DOKLADU),
				opCislo: get(interakcia, 'opCislo'),
				interakciaId: get(interakcia, 'id'),
				kanal: get(auth, 'businessChannel.actual'),
				trvanie: dayjs().diff(dayjs(get(tracking, 'startTime')), 'millisecond'),
				vstup: {
					id: get(data, 'ukonVstup.value.value'),
					nazov: get(data, 'ukonVstup.value.label')
				},
				zacatyOd: dayjs(get(tracking, 'startTime')).toISOString(),
				poznamka: get(data, 'poznamka.value'),
				data: {
					pohladavkaCislo: get(pohladavka, 'cislo'),
					zuCislo: head(get(pohladavka, 'zuCisla'))
				},
				notifikacie,
				dokumenty: []
			}

			await postReq(`/api/v2/ukony/vytvorit-a-spustit`, null, body)

			// clear tracking timer
			trackingActions.clearTracking()
			// refetch new data for historia ukonov
			historiaUkonovActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)

			this.setState({
				result: t('translation:UctovneDoklady.Duplikát účtovného dokladu bol úspešne odoslaný'),
				success: true,
				showModalUkonUC18: false,
				documentHidden: true
			})
		} catch (e) {
			this.setState({
				result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
				success: false,
				documentHidden: true
			})
		}
	}

	render = () => {
		const { tracking, channelToSwitch, forbiddenModal, t } = this.props
		const { result, success, documentFail, document, documentHidden, showModalUkonUC18, pohladavka, backUrlLink, loading } = this.state

		const style = {
			display: 'none',
			zIndex: 101,
			position: 'relative'
		}

		if (!loading && !result) {
			style.display = 'block'
		}

		if (channelToSwitch || forbiddenModal || showModalUkonUC18) {
			style.visibility = 'hidden'
		}

		let ukonTextBtn = t('translation:UctovneDoklady.Spustiť konzultáciu')
		let ukonColorBtn = 'blue'

		if (
			get(tracking, 'startTime') &&
			get(tracking, 'type') == UKONY.KONZULTACIA_UCTOVNEHO_DOKLADU &&
			get(tracking, 'data.data.pohladavkaCislo') &&
			get(tracking, 'data.data.pohladavkaCislo') == get(pohladavka, 'cislo')
		) {
			ukonTextBtn = t('translation:UctovneDoklady.Ukončiť konzultáciu')
			ukonColorBtn = 'red'
		}

		let modal
		if (showModalUkonUC18) {
			modal = (
				<ModalSendItemDuplicate
					zuCislo={head(get(pohladavka, 'zuCisla'))}
					ukonTyp={UKONY.DUPLIKAT_UCTOVNEHO_DOKLADU}
					dokumentId={get(pohladavka, 'dokumentId')}
					dokumentName={`${get(pohladavka, 'typ.nazov', t('translation:UctovneDoklady.Účtovný doklad'))}.pdf`}
					notificationTypes={[NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.PRINTER]}
					modalTitle={t('translation:UctovneDoklady.Zaslanie duplikátu účtovného dokladu')}
					onCloseButton={() => this.setState({ showModalUkonUC18: false })}
					onSubmit={this.onSubmitUkonUC18}
				/>
			)
		}

		if (result) {
			modal = (
				<DefaultModal
					modalTitle={success ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
					modalContent={result}
					leftButton={{
						onClick: this.confirmModal,
						text: t('translation:Common.Zavrieť'),
						color: success ? 'green' : 'red'
					}}
					visible
				/>
			)
		}

		return (
			<>
				{modal}
				<div className='content-header clearfix' style={{ zIndex: 0 }}>
					<Link to={backUrlLink} className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</Link>
					<div className='header-title pull-left'>
						{t('translation:UctovneDoklady.Náhľad účtovného dokladu', { cislo: get(pohladavka, 'cislo') })}
					</div>

					<div className='pull-right'>
						<Permissions
							allowed={[PERMISSIONS.UKON_DUPLIKAT_UCTOVNEHO_DOKLADU]}
							render={(hasPerm, actions) => {
								return (
									<Tooltip
										html={t('translation:UctovneDoklady.Zaslanie duplikátu účtovného dokladu')}
										position='bottom'
										trigger='mouseenter'
										theme='light'
									>
										<a
											className='button send-button'
											data-type='icon-button'
											data-icon='send'
											onClick={(e) => {
												if (!hasPerm) {
													e.preventDefault()
													actions.openForbiddenModal()
												} else {
													this.setState({ showModalUkonUC18: true })
												}
											}}
										/>
									</Tooltip>
								)
							}}
						/>
					</div>
				</div>

				<div className='content-wrapper embed-wrapper'>
					{!documentFail && !result && !channelToSwitch && !forbiddenModal && <ElementLoading />}
					{documentFail && !forbiddenModal && (
						<ElementFailure text={t('translation:UctovneDoklady.Nepodarilo sa načítať dokument pre účtovný doklad')} />
					)}
					{/* hide embed durring modal is showing because of IE issue */}
					{document && !documentHidden && <embed src={document} type='application/pdf' width='100%' height='100%' style={style} />}
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth'),
	tracking: get(state, 'tracking'),
	ciselniky: get(state, 'ciselniky'),
	channelToSwitch: get(state, 'data')[CHANNEL_TO_CHANGE_SELECTOR],
	forbiddenModal: get(state, 'data.forbiddenModal'),
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	historiaUkonovActions: bindActionCreators(HistoriaUkonovActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_POHLADAVKY])
)(UctovnyDokladDetailPage)
