import React from 'react'
import { propTypes } from 'redux-form'
import { get, map, isEmpty, sumBy } from 'lodash'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Tooltip } from 'react-tippy'
import { NumericFormat } from 'react-number-format'

// components
import ElementLoading from '../ElementLoading'
import ElementEmptyContent from '../ElementEmptyContent'

// utils
import { formatDate } from '../../utils/date'

class PlanSplatokAktualneNastavenie extends React.Component {
	static propTypes = {
		...propTypes
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	render() {
		const { aktualneNastavenie, t } = this.props

		let content
		if (get(aktualneNastavenie, 'isLoading')) {
			content = (
				<div style={{ minHeight: '100px', position: 'relative' }}>
					<ElementLoading />
				</div>
			)
		} else if (get(aktualneNastavenie, 'isFailure') || isEmpty(get(aktualneNastavenie, 'data', []))) {
			content = (
				<div style={{ minHeight: '250px', position: 'relative' }}>
					<ElementEmptyContent text={t('translation:PlanySplatok.Informatívny výpočet plánu splátok nie je k dispozícii')} />
				</div>
			)
		} else {
			content = (
				<table className='plan-splatok-table bordered' cellSpacing='0'>
					<thead>
						<tr>
							<th>{t('translation:PlanySplatok.Dátum splátky')}</th>
							<th style={{ textAlign: 'right' }}>{t('translation:PlanySplatok.Celkom k úhrade')}</th>
						</tr>
					</thead>
					<tbody>
						{map(get(aktualneNastavenie, 'data', []), (splatka) => (
							<tr key={`splatka-${get(splatka, 'splatnostDatum')}`}>
								<td>{formatDate(get(splatka, 'splatnostDatum'), '-', 'DD.MM.YYYY')}</td>
								<td style={{ textAlign: 'right' }}>
									<strong>
										<NumericFormat
											thousandSeparator={' '}
											decimalSeparator={','}
											decimalScale={2}
											fixedDecimalScale={true}
											displayType='text'
											suffix=' €'
											value={get(splatka, 'suma', 0)}
											defaultValue='-'
										/>
									</strong>
								</td>
							</tr>
						))}
						<tr>
							<td>{t('translation:Common.SPOLU')}</td>
							<td style={{ textAlign: 'right' }}>
								<strong>
									<NumericFormat
										thousandSeparator={' '}
										decimalSeparator={','}
										decimalScale={2}
										fixedDecimalScale={true}
										displayType='text'
										suffix=' €'
										value={sumBy(get(aktualneNastavenie, 'data', []), (splatka) => {
											return get(splatka, 'suma', 0)
										})}
										defaultValue='-'
									/>
								</strong>
							</td>
						</tr>
					</tbody>
				</table>
			)
		}

		return (
			<tr>
				<td style={{ verticalAlign: 'top' }}>
					<strong>{t('translation:PlanySplatok.Informatívny výpočet plánu splátok')}</strong>
					<Tooltip
						html={<span>{t('translation:PlanySplatok.Záväzný výpočet bude uvedený vo výstupnej dohode o platbách')}</span>}
						position='right'
						trigger='mouseenter'
						theme='light'
					>
						<div className='info-icon' />
					</Tooltip>
				</td>
				<td style={{ padding: 0 }}>{content}</td>
			</tr>
		)
	}
}

export default compose(withTranslation('components'))(PlanSplatokAktualneNastavenie)
