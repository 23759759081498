import { get } from 'lodash'
import {
	ZMLUVNE_UCTY_LOAD_START,
	ZMLUVNE_UCTY_LOAD_PART_DONE,
	ZMLUVNE_UCTY_LOAD_DONE,
	ZMLUVNE_UCTY_LOAD_FAIL,
	ZMLUVNY_UCET_LOAD_START,
	ZMLUVNY_UCET_LOAD_DONE,
	ZMLUVNY_UCET_LOAD_FAIL
} from '../types/zmluvneUctyTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	collection: {
		data: [],
		byCislo: null,
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case ZMLUVNE_UCTY_LOAD_START:
			return {
				...state,
				collection: {
					...state.collection,
					isLoading: true,
					isFailure: false
				}
			}
		case ZMLUVNE_UCTY_LOAD_PART_DONE: {
			return {
				...state,
				collection: {
					...state.collection,
					data: get(action, 'payload.zmluvneUcty', [])
				}
			}
		}
		case ZMLUVNE_UCTY_LOAD_DONE: {
			return {
				...state,
				collection: {
					data: get(action, 'payload.zmluvneUcty', []),
					byCislo: get(action, 'payload.byCislo', {}),
					isLoading: false,
					isFailure: false
				}
			}
		}
		case ZMLUVNE_UCTY_LOAD_FAIL:
			return {
				...state,
				collection: {
					...initState.collection,
					isLoading: false,
					isFailure: true
				}
			}
		case ZMLUVNY_UCET_LOAD_START:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case ZMLUVNY_UCET_LOAD_DONE:
			return {
				...state,
				detail: {
					...state.detail,
					data: get(action, 'payload.zmluvnyUcet', []),
					isLoading: false,
					isFailure: false
				}
			}
		case ZMLUVNY_UCET_LOAD_FAIL:
			return {
				...state,
				detail: {
					...initState.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
