import { get } from 'lodash'
import { ZOSTATOK_ZMLUVNE_UCTY_LOAD_START, ZOSTATOK_ZMLUVNE_UCTY_LOAD_DONE, ZOSTATOK_ZMLUVNE_UCTY_LOAD_FAIL } from '../types/zostatokZmluvneUctyTypes'

import { getReq } from '../utils/request'

const initStatePart = {
	data: {},
	isLoading: false,
	isFailure: false
}

export const loadZostatokZmluvnyUcet = (zuCislo, queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: ZOSTATOK_ZMLUVNE_UCTY_LOAD_START,
			payload: {
				zmluvneUcty: {
					[zuCislo]: {
						...initStatePart,
						isLoading: true
					}
				}
			}
		})

		const query = {
			...queryParams
		}

		try {
			const zostatokData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/zu/${zuCislo}/zostatok`, query)
			const zostatok = get(zostatokData, 'response.content')

			dispatch({
				type: ZOSTATOK_ZMLUVNE_UCTY_LOAD_DONE,
				payload: {
					zmluvneUcty: {
						[zuCislo]: {
							...initStatePart,
							data: zostatok,
							isLoading: false
						}
					}
				}
			})
			return zostatok
		} catch (e) {
			dispatch({
				type: ZOSTATOK_ZMLUVNE_UCTY_LOAD_FAIL,
				payload: {
					zmluvneUcty: {
						[zuCislo]: {
							...initStatePart,
							isFailure: true
						}
					}
				}
			})
		}
	}
}
