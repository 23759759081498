import React from 'react'
import { /* find,  */ get, isEmpty, map /* , startsWith */ } from 'lodash'
import { Tooltip } from 'react-tippy'
import dayjs from 'dayjs'

import { formatDate } from './date'

import { POHLADAVKA_TYP, POHLADAVKA_VYROVNANIE_STATUS, POHLADAVKA_SPLATNOST_STATUS } from './enums'

export const getTypPohladavkyTooltip = (pohladavka, t) => {
	switch (get(pohladavka, 'typ.id')) {
		case POHLADAVKA_TYP.PREPLATOK_Z_FAKTURACIE.value:
			return t(`components:StavUctuTableRow.Tooltip_Preplatok_z_fakturacie`)
		case POHLADAVKA_TYP.AKONTO.value:
			return t(`components:StavUctuTableRow.Tooltip_Akonto`)
		case POHLADAVKA_TYP.ZALOHA_ZNOVUPRIPOJENIE.value:
			return t(`components:StavUctuTableRow.Tooltip_Zaloha_znovupripojenie`)
		case POHLADAVKA_TYP.UDRZBA_UCTU.value:
			return t(`components:StavUctuTableRow.Tooltip_Udrzba_uctu`)
		case POHLADAVKA_TYP.STANDARD_KVALITY.value:
			return t(`components:StavUctuTableRow.Tooltip_Standard_kvality`)
		default:
	}
}

export const getInfoIcon = (pohladavka, t) => {
	const infoIcons = []
	if (get(pohladavka, 'planSplatokId')) {
		infoIcons.push(
			<Tooltip
				key='plan-splatok'
				html={
					<>
						<strong>{t('components:StavUctuTableRow.Faktúra zahrnutá do splátkového kalendára')}</strong>
						<br />
						<div className='tooltip-flex-holder'>
							<span>{t('components:StavUctuTableRow.Číslo plánu splátok')}</span>
							<span>{get(pohladavka, 'planSplatokId')}</span>
						</div>
					</>
				}
				position='left'
				trigger='mouseenter'
				theme='light'
			>
				<div className='table-icon' data-icon='s-black' />
			</Tooltip>
		)
	}
	if (!isEmpty(get(pohladavka, 'upomienky'))) {
		infoIcons.push(
			<Tooltip key='upomienky' html={t('components:StavUctuTableRow.Pre doklad existuje upomienka')} position='left' trigger='mouseenter' theme='light'>
				<div className='table-icon' data-icon='u-red' />
			</Tooltip>
		)
	}
	if (
		get(pohladavka, 'vyrovnanieStatus.id') == POHLADAVKA_VYROVNANIE_STATUS.OTVORENA &&
		get(pohladavka, 'sumaOtvorena') < 0 &&
		!isEmpty(get(pohladavka, 'odkladSplatnostiDo', []))
	) {
		infoIcons.push(
			<Tooltip
				key='odklad-splatnosti'
				html={
					<>
						<strong>{t('components:StavUctuTableRow.Odklad splatnosti')}</strong>
						<br />
						{map(get(pohladavka, 'odkladSplatnostiDo', []), (splatnost, index) => {
							return (
								<div key={`odklad-splatnosti-${index}`} className='tooltip-flex-holder'>
									{formatDate(splatnost, '-', 'DD.MM.YYYY')}
								</div>
							)
						})}
					</>
				}
				position='left'
				trigger='mouseenter'
				theme='light'
			>
				<div className='table-icon' data-icon='o-red' />
			</Tooltip>
		)
	}
	if (get(pohladavka, 'platobnyPrikaz')) {
		infoIcons.push(
			<Tooltip
				key='platobny-prikaz'
				html={<strong>{t('components:StavUctuTableRow.Platobný príkaz')}</strong>}
				position='left'
				trigger='mouseenter'
				theme='light'
			>
				<div className='table-icon' data-icon='car-red' />
			</Tooltip>
		)
	}
	// NOTE: CP-1939
	/* if (find(get(pohladavka, 'suvisiaceDoklady', []), (value) => startsWith(get(value, 'cislo'), '6'))) {
		infoIcons.push(<Tooltip
			key="odpisane-pohladavky"
			html={t('components:StavUctuTableRow.Faktúra neuhradená - odpis')}
			position="left"
			trigger="mouseenter"
			theme="light"
		>
			<div className="table-icon" data-icon="bin"></div>
		</Tooltip>)
	} */
	return infoIcons
}

export const getStateIcon = (state, t) => {
	switch (state) {
		case POHLADAVKA_SPLATNOST_STATUS.VYROVNANA:
			return (
				<Tooltip
					html={
						<>
							<strong>{t('components:StavUctuTableRow.Vyrovnaná položka')}</strong>
							{/* <br/>
				<div className="tooltip-flex-holder">
					<span>{t('components:StavUctuTableRow.Nie je evidovaná platba')}</span>
				</div> */}
						</>
					}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					<div className='label circle' data-color='green' />
				</Tooltip>
			)
		case POHLADAVKA_SPLATNOST_STATUS.SPLATNA_PRED:
			return (
				<Tooltip
					html={
						<>
							<strong>{t('components:StavUctuTableRow.Otvorená položka')}</strong>
							<br />
							<div className='tooltip-flex-holder'>
								<span>{t('components:StavUctuTableRow.Nie je evidovaná platba')}</span>
							</div>
						</>
					}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					<div className='label circle' data-color='blue' />
				</Tooltip>
			)
		case POHLADAVKA_SPLATNOST_STATUS.SPLATNA_PO:
			return (
				<Tooltip
					html={
						<>
							<strong>{t('components:StavUctuTableRow.Otvorená položka po splatnosti')}</strong>
							<br />
							<div className='tooltip-flex-holder'>
								<span>{t('components:StavUctuTableRow.Nie je evidovaná platba')}</span>
							</div>
						</>
					}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					<div className='label circle' data-color='red' />
				</Tooltip>
			)
		default:
			return null
	}
}

export const getItemState = (pohladavka) => {
	if (get(pohladavka, 'vyrovnanieStatus.id') == POHLADAVKA_VYROVNANIE_STATUS.VYROVNANA) {
		return POHLADAVKA_SPLATNOST_STATUS.VYROVNANA
	}
	if (!get(pohladavka, 'vyrovnanieStatus.id') || !get(pohladavka, 'splatnostDatum')) {
		return POHLADAVKA_SPLATNOST_STATUS.NESPLATNA
	}
	const splatnostDatum = dayjs(get(pohladavka, 'splatnostDatum')).startOf('day')
	if (splatnostDatum < dayjs().startOf('day')) {
		return POHLADAVKA_SPLATNOST_STATUS.SPLATNA_PO
	}
	return POHLADAVKA_SPLATNOST_STATUS.SPLATNA_PRED
}
