import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { connect } from 'react-redux'
import ReactTimeout from 'react-timeout'
import { isArray, map } from 'lodash'

import { bindActionCreators } from 'redux'
import * as StatusActions from '../actions/StatusActions'
import getColorClassByEnum from '../utils/getColorClassByEnum'

class StatusToastMessage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			shown: false,
			timer: null
		}
	}

	toggleShown = (timer) => {
		this.setState({
			shown: true,
			timer
		})
	}

	toggleHide = () => {
		this.setState({
			shown: false,
			timer: null
		})
	}

	onClickHandler = () => {
		// turn off timer
		if (this.state.timer) {
			this.props.clearTimeout(this.state.timer)
			this.setState({ timer: null })
		}
		this.props.StatusActions.statusPop(1)
	}

	toggle = () => {
		this.setState({ timer: null }, () => {
			this.props.StatusActions.statusPop(1)
		})
	}

	checkStatuses = () => {
		// if exists any statuses and timer is not turn on show toast message
		if (this.props.statuses.length > 0 && !this.state.timer) {
			// set 10 sec timer for cancel current toast message
			const timer = this.props.setTimeout(this.toggle, 10 * 1000)
			this.toggleShown(timer)
		}
		// if is not exists status any more and toast is shown hide itnow
		if (this.props.statuses.length === 0 && this.state.shown) {
			this.toggleHide()
		}
	}

	componentWillUnmount() {
		this.toggleHide()
	}

	componentDidUpdate() {
		this.checkStatuses()
	}

	componentDidMount() {
		this.checkStatuses()
	}

	render() {
		if (this.props.statuses.length < 1) {
			return null
		}
		const { typ, popis } = this.props.statuses[0]
		const backgroundColor = getColorClassByEnum(typ)

		let msgText = popis
		if (isArray(popis)) {
			msgText = map(popis, (text, index) => (
				<div key={index} style={{ textAlign: 'center' }}>
					{text}
				</div>
			))
		}

		return (
			<div className={cx('toast-message', { shown: this.state.shown })} data-color={backgroundColor}>
				<div className='label circle large' data-color={backgroundColor}>
					<span>{`${this.props.statuses.length}`}</span>
				</div>
				<div className='message-text'>{msgText}</div>
				<div>
					<div className='button' data-type='outline' data-color={backgroundColor} onClick={this.onClickHandler}>
						Zatvoriť
					</div>
					{/* <div className="button" data-color="blue">{this.props.messageConfirm}</div> */}
				</div>
			</div>
		)
	}
}

StatusToastMessage.propTypes = {
	statuses: PropTypes.array,
	StatusActions: PropTypes.shape({
		statusPop: PropTypes.func.isRequired
	}).isRequired,
	setTimeout: PropTypes.func.isRequired,
	clearTimeout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
	statuses: state.statuses.statuses
})

const mapDispatchToProps = (dispatch) => {
	return {
		StatusActions: bindActionCreators(StatusActions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReactTimeout(StatusToastMessage))
