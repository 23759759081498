import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { get } from 'lodash'

// config
import { COLUMNS_COUNT } from '../../../containers/GenericUkon/attributesConfig'

class ZmluvnyVztahCisloIsuField extends React.Component {
	static propTypes = {
		columnsCount: PropTypes.number,
		formValues: PropTypes.shape(),
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	render() {
		const { columnsCount = COLUMNS_COUNT.THREE, formValues, t } = this.props

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Číslo zmluvy')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td />}
							<td>{get(formValues, 'data.zmluvaNovaUkonId') || get(formValues, 'zmluvaNovaUkonId')}</td>
						</tr>
						<tr>
							<td>
								<strong>{t('translation:Common.Číslo zmluvy ISU')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td />}
							<td>{get(formValues, 'data.zvCisloIsu') || get(formValues, 'zvCisloIsu')}</td>
						</tr>
						<tr>
							<td>
								<strong>{t('translation:Common.Číslo zmluvného účtu')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td />}
							<td>{get(formValues, 'data.zuCislo') || get(formValues, 'zuCislo')}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(ZmluvnyVztahCisloIsuField)
