import React from 'react'
import { Link } from 'react-router-dom'
import { map, isEmpty, get } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Tooltip } from 'react-tippy'

// components
import PlanySplatokTableRow from '../../components/TableRows/PlanySplatokTableRow'
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import VyberTypuPlatcuPlanuSplatok from '../../components/Modals/VyberTypuPlatcuPlanuSplatok'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as PlanySplatokActions from '../../actions/ObchodniPartneri/PlanySplatokActions'
import * as OtvoreneFakturyActions from '../../actions/ObchodniPartneri/OtvoreneFakturyActions'

// utils
import { TYP_PLATCU_PLANU_SPLATOK, BUSINESS_CHANNELS } from '../../utils/enums'
import { setRouteParams, PLANY_SPLATOK_VYTVORIT } from '../../utils/routes'
import Permissions, { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { history } from '../../utils/history'

class PlanySplatokPage extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		auth: PropTypes.shape().isRequired,
		obchodnyPartnerActions: PropTypes.shape().isRequired,
		planySplatokActions: PropTypes.shape().isRequired,
		otvoreneFakturyActions: PropTypes.shape().isRequired,
		planySplatok: PropTypes.shape(),
		otvoreneFaktury: PropTypes.shape(),
		odberneMiesta: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		obchodnyPartner: PropTypes.shape().isRequired
	}

	_mounted = false

	constructor(props) {
		super(props)

		this.state = {
			vyberTypuPlatcuPlanuSplatok: {
				showModal: false
			}
		}
	}

	componentDidMount() {
		this._mounted = true

		const { obchodnyPartnerActions, planySplatokActions, otvoreneFakturyActions } = this.props

		obchodnyPartnerActions.loadAllOdberneMiesta(0)
		planySplatokActions.loadPlanySplatok()
		otvoreneFakturyActions.searchFaktury()
	}

	componentWillUnmount() {
		this._mounted = false
	}

	render() {
		const { interakcia, odberneMiesta, planySplatok, otvoreneFaktury, obchodnyPartner, t } = this.props

		if (get(odberneMiesta, 'isLoading') || !get(odberneMiesta, 'allDataPrepared') || get(planySplatok, 'isLoading') || get(otvoreneFaktury, 'isLoading')) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (get(odberneMiesta, 'isFailure') || get(planySplatok, 'isFailure') || get(otvoreneFaktury, 'isFailure')) {
			return this.commonContentContainer(<ElementFailure text={t('translation:PlanySplatok.Nepodarilo sa načítať plány splátok')} />)
		}

		if (isEmpty(get(planySplatok, 'data', []))) {
			return this.commonContentContainer(<ElementEmptyContent text={t('translation:PlanySplatok.Obchodný partner nemá žiadne plány splátok')} />)
		}

		const planySplatokRows = map(get(planySplatok, 'data', []), (planSplatok) => (
			<PlanySplatokTableRow
				key={get(planSplatok, 'id')}
				interakcia={interakcia}
				planSplatok={planSplatok}
				odberneMiesta={get(odberneMiesta, 'allData', [])}
				obchodnyPartner={obchodnyPartner}
				t={t}
			/>
		))

		return this.commonContentContainer(<div className='content-wrapper'>{planySplatokRows}</div>)
	}

	commonContentContainer = (content) => {
		const { interakcia, auth, otvoreneFaktury, t } = this.props
		const { vyberTypuPlatcuPlanuSplatok } = this.state

		const disableCreatePlanSplatokBtn = isEmpty(get(otvoreneFaktury, 'data', []))

		const createPlanSplatokBtn = (
			<Permissions
				allowed={[PERMISSIONS.UKON_PLAN_SPLATOK]}
				render={(hasPerm, actions) => {
					if (hasPerm) {
						const createBtn = (
							<Link
								to={`${setRouteParams(PLANY_SPLATOK_VYTVORIT, get(interakcia, 'opCislo'))}?platcaTyp=${TYP_PLATCU_PLANU_SPLATOK.SAM_ZA_SEBA}`}
								className={cx('button', 'pull-right', { disabled: disableCreatePlanSplatokBtn })}
								disabled={disableCreatePlanSplatokBtn}
								onClick={(e) => {
									e.preventDefault()
									if (!disableCreatePlanSplatokBtn) {
										if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
											history.push(
												`${setRouteParams(PLANY_SPLATOK_VYTVORIT, get(interakcia, 'opCislo'))}?platcaTyp=${
													TYP_PLATCU_PLANU_SPLATOK.SAM_ZA_SEBA
												}`
											)
										} else {
											this.setState({
												vyberTypuPlatcuPlanuSplatok: {
													showModal: true
												}
											})
										}
									}
								}}
								type='button'
								data-color='blue'
							>
								{t('translation:PlanySplatok.Vytvoriť plán splátok')}
							</Link>
						)

						return disableCreatePlanSplatokBtn ? (
							<Tooltip
								className='pull-right'
								html={<span>{t('translation:PlanySplatok.Obchodný partner nemá žiadnu nevyrovnanú faktúru')}</span>}
								position='top-start'
								trigger='mouseenter'
								theme='light'
							>
								{createBtn}
							</Tooltip>
						) : (
							createBtn
						)
					}

					return (
						<Link
							to={setRouteParams(PLANY_SPLATOK_VYTVORIT, get(interakcia, 'opCislo'))}
							className={cx('button', 'pull-right', { disabled: true })}
							type='button'
							data-color='blue'
							onClick={(e) => {
								e.preventDefault()
								actions.openForbiddenModal()
							}}
						>
							<Tooltip
								html={<span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>}
								position='left'
								trigger='mouseenter'
								theme='light'
							>
								{t('translation:PlanySplatok.Vytvoriť plán splátok')}
							</Tooltip>
						</Link>
					)
				}}
			/>
		)

		let modal
		if (get(vyberTypuPlatcuPlanuSplatok, 'showModal')) {
			modal = (
				<VyberTypuPlatcuPlanuSplatok
					modalTitle={t('translation:PlanySplatok.Zvoľte typ platcu pre vytvorenie plánu splátok')}
					onCloseButton={() =>
						this.setState({
							vyberTypuPlatcuPlanuSplatok: {
								showModal: false,
								zuCisla: [],
								fakturaCislo: null
							}
						})
					}
					onSubmit={(context) => {
						let query = `platcaTyp=${get(context, 'selectedPlatcaTyp')}`
						if (get(context, 'selectedPlatcaTyp') == TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC) {
							query += `&splnomocnenec=${get(context, 'selectedSplnomocnenec')}`
						}
						history.push(`${setRouteParams(PLANY_SPLATOK_VYTVORIT, get(interakcia, 'opCislo'))}?${query}`)
					}}
				/>
			)
		}

		return (
			<>
				<div className='content-header clearfix'>
					<div className='row'>
						<div className='col-9' />
						<div className='col-3'>
							<div className='input-wrapper'>{createPlanSplatokBtn}</div>
						</div>
					</div>
				</div>
				{content}
				{modal}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	interakcia: get(state, 'interakcie.detail.data'),
	odberneMiesta: get(state, 'obchodnyPartner.odberneMiesta'),
	planySplatok: get(state, 'planySplatok.planySplatok'),
	otvoreneFaktury: get(state, 'otvoreneFaktury.otvoreneFaktury'),
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data')
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
	planySplatokActions: bindActionCreators(PlanySplatokActions, dispatch),
	otvoreneFakturyActions: bindActionCreators(OtvoreneFakturyActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_PLAN_SPLATOK])
)(PlanySplatokPage)
