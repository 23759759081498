import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Field, reduxForm, propTypes, change, formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { every, map, get, upperFirst, some, filter } from 'lodash'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { Tooltip } from 'react-tippy'

import validate from './validateForm'

// atoms
import { SelectField, NumericInputField, DropZoneField, DatePickerField, TextareaField, DebounceFieldWrapper, TextInputField } from '../../../atoms'

// components
import FormInfo from '../../FormInfo'

// utils
import { getPrepocitanaZaloha } from '../../../utils/rozpisyZaloh'
import { FORMS } from '../../../utils/enums'
import { setRouteParams, ROZPISY_ZALOH_ZOZNAM } from '../../../utils/routes'
import { isValidacneKriteriumError } from '../../../utils/form'
import { calculateYearAdvancePayment } from '../../../utils/platby'

class RozpisZalohEditInfo extends React.Component {
	static propTypes = {
		...propTypes,
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.number.isRequired,
				opCislo: PropTypes.string.isRequired
			}).isRequired,
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		originalValues: PropTypes.shape({
			suma: PropTypes.number,
			neznizovat: PropTypes.bool,
			cyklus: PropTypes.string,
			splatnost: PropTypes.string,
			poznamka: PropTypes.string
		}),
		t: PropTypes.func
	}

	debounceNumericInput = DebounceFieldWrapper(NumericInputField, 100)

	formatValue = (num) => `${num.toString().split('.').join(',')} €`

	render() {
		const {
			handleSubmit,
			invalid,
			interakcia,
			originalValues,
			ciselniky,
			actualBusinessChannel,
			t,
			validacneKriteria,
			schvalovacieKriteria,
			onDeleteFile,
			computedMatch,
			formValues
		} = this.props

		const { vyuctovanie, aktualna, procesnyKonfigurator } = this.props.helperNotice

		const ukonVstup = map(get(ciselniky, 'ukonVstup', []), (ukonVstup) => ({
			label: upperFirst(ukonVstup.nazov),
			value: ukonVstup.id
		}))

		// allow only monthly, quarterly, six month and yearly options
		const zalohyCyklus = filter(get(ciselniky, 'zalohaCyklus', []), (zalohaCyklus) => get(zalohaCyklus, 'id') > 2 && get(zalohaCyklus, 'id') < 7)

		const cyklusEnum = map(zalohyCyklus, (zalohaCyklus) => ({
			value: get(zalohaCyklus, 'id'),
			label: get(zalohaCyklus, 'nazov')
		}))

		const denSplatnostiEnum = map(get(ciselniky, 'zalohaDenSplatnosti', []), (zalohaDenSplatnosti) => ({
			value: get(zalohaDenSplatnosti, 'id'),
			label: get(zalohaDenSplatnosti, 'nazov')
		}))

		const booleanEnum = [
			{ value: true, label: t('components:RozpisZalohEditInfo.Áno') },
			{ value: false, label: t('components:RozpisZalohEditInfo.Nie') }
		]

		// result from procesnyKonfigurator if form is valid
		const passValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumError), { vysledok: true })
		const errorSchvalovacieKriterium = some(schvalovacieKriteria, { error: true })

		const { zalohyCyklusAktualny } = formValues
		const zalohaVyuctovanie = getPrepocitanaZaloha(vyuctovanie, zalohyCyklusAktualny)
		const zalohyAktualna = getPrepocitanaZaloha(aktualna, zalohyCyklusAktualny)
		const minZaloha = zalohaVyuctovanie * (procesnyKonfigurator.standardneAtributy.minIntervalZmenyPP / 100)
		const maxZaloha = zalohaVyuctovanie * (procesnyKonfigurator.standardneAtributy.maxIntervalZmenyPP / 100)
		const simpleMessages = [
			<div key='helper'>
				<NumericFormat
					thousandSeparator={' '}
					decimalSeparator={','}
					decimalScale={2}
					displayType='text'
					prefix='Záloha vyúčtovanie: '
					suffix=' € , '
					value={zalohaVyuctovanie}
					defaultValue='-'
				/>
				<NumericFormat
					thousandSeparator={' '}
					decimalSeparator={','}
					decimalScale={2}
					displayType='text'
					prefix='Záloha aktuálna: '
					suffix=' € , '
					value={zalohyAktualna}
					defaultValue='-'
				/>
				<NumericFormat
					thousandSeparator={' '}
					decimalSeparator={','}
					decimalScale={2}
					displayType='text'
					prefix='Min záloha: '
					suffix=' €'
					value={minZaloha}
					defaultValue='-'
				/>
				{procesnyKonfigurator.standardneAtributy.maxIntervalZmenyPPEnabled != 'false' && (
					<NumericFormat
						thousandSeparator={' '}
						decimalSeparator={','}
						decimalScale={2}
						displayType='text'
						prefix=' , Max záloha: '
						suffix=' €'
						value={maxZaloha}
						defaultValue='-'
					/>
				)}
			</div>
		]

		const minTimeDatumZmeny = dayjs().startOf('day')
		const maxTimeDatumZmeny = null
		let minTimeDatumPrijatiaZiadosti = null
		let maxTimeDatumPrijatiaZiadosti = null

		if (actualBusinessChannel.id == 1) {
			// ZSEC
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (actualBusinessChannel.id == 2) {
			// ZL
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (actualBusinessChannel.id == 4) {
			// BO
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}

		const isValid = passValidacneKriterium && !errorSchvalovacieKriterium

		const btn = (
			<button
				className={cx('button', 'pull-right', { disabled: !isValid || invalid })}
				disabled={!isValid || invalid}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('components:RozpisZalohEditInfo.Pokračovať')}
			</button>
		)

		let btnComponent
		if (!isValid) {
			let btnTooltipText
			if (!passValidacneKriterium) {
				const valKrit = filter(validacneKriteria, (validacneKriterium) => !validacneKriterium.vysledok)
				btnTooltipText = map(valKrit, (validacneKriterium) => (
					<div>
						{validacneKriterium.nazov}: {validacneKriterium.popis}
					</div>
				))
			} else if (errorSchvalovacieKriterium) {
				const schvalKrit = filter(schvalovacieKriteria, (schvalovacieKriterium) => !schvalovacieKriterium.vysledok || schvalovacieKriterium.error)
				btnTooltipText = map(schvalKrit, (schvalovacieKriterium) => (
					<div>
						{schvalovacieKriterium.nazov}: {schvalovacieKriterium.popis}
					</div>
				))
			}
			btnComponent = (
				<Tooltip html={btnTooltipText} position='bottom' trigger='mouseenter' theme='light'>
					{btn}
				</Tooltip>
			)
		} else {
			btnComponent = btn
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					{btnComponent}

					<Link
						to={setRouteParams(ROZPISY_ZALOH_ZOZNAM, get(interakcia, 'data.opCislo'))}
						className='button pull-left'
						data-type='back-button'
						data-color='blue'
					>
						{t('components:RozpisZalohEditInfo.Späť')}
					</Link>

					<div className='header-title pull-left'>
						{t('components:RozpisZalohEditInfo.Krok 1 z 2 Editácia údajov rozpisu záloh')} ({get(computedMatch, 'params.cisloRozpisZaloh')})
					</div>

					<Link
						to={setRouteParams(ROZPISY_ZALOH_ZOZNAM, get(interakcia, 'data.opCislo'))}
						className='button pull-right'
						data-type='outline'
						data-color='red'
					>
						{t('components:RozpisZalohEditInfo.Zrušiť')}
					</Link>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<FormInfo schvalovacieKriteria={schvalovacieKriteria} />
						<FormInfo validacneKriteria={validacneKriteria} />
						<FormInfo simpleMessages={simpleMessages} />
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('components:RozpisZalohEditInfo.Aktuálne hodnoty')}</th>
										<th>{t('components:RozpisZalohEditInfo.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('components:RozpisZalohEditInfo.Vstup do úkonu')}</strong>
										</td>
										<td>{get(originalValues, 'ukonVstup.nazov')}</td>
										<td>
											<Field
												name='ukonVstup'
												component={SelectField}
												options={ukonVstup}
												isSearchable={false}
												isDisabled={ukonVstup.length == 0}
												classNamePrefix='react-select'
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:RozpisZalohEditInfo.Splnomocnenec')}</strong>
										</td>
										<td />
										<td>
											<Field
												name='splnomocnenec'
												component={TextInputField}
												placeholder={t('components:RozpisZalohEditInfo.Zadajte titul, meno a priezvisko splnomocnenca')}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:RozpisZalohEditInfo.Miesto podpisu úkonu')}</strong>
										</td>
										<td>{get(originalValues, 'podpisMiesto')}</td>
										<td>
											<Field
												name='podpisMiesto'
												component={TextInputField}
												placeholder={t(
													'components:RozpisZalohEditInfo.Zadajte miesto podpisu úkonu (ak nejde o prípad odloženého podpisu)'
												)}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:RozpisZalohEditInfo.Dátum prijatia žiadosti')}</strong>
										</td>
										<td />
										<td>
											<div className='select-wrapper'>
												<Field
													name='datumPrijatiaZiadosti'
													component={DatePickerField}
													showYearDropdown
													scrollableYearDropdown
													minDate={minTimeDatumPrijatiaZiadosti?.toDate()}
													maxDate={maxTimeDatumPrijatiaZiadosti?.toDate()}
													placeholderText={t('components:RozpisZalohEditInfo.Zvoľte dátum')}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('components:RozpisZalohEditInfo.Cyklus záloh')}</strong>
											</td>
											<td> {get(originalValues, 'zalohyCyklus.label')} </td>
											<td>
												<Field name='zalohyCyklus' component={SelectField} options={cyklusEnum} classNamePrefix='react-select' />
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('components:RozpisZalohEditInfo.Výška záloh')}</strong>
											</td>
											<td>
												<NumericFormat
													thousandSeparator={' '}
													decimalSeparator={','}
													decimalScale={2}
													displayType='text'
													suffix=' €'
													value={originalValues.zalohySuma}
													defaultValue='-'
												/>
											</td>
											<td>
												<Field
													name='zalohySuma'
													component={this.debounceNumericInput}
													/* required */
													strict
													placeholder={this.formatValue(get(formValues, 'zalohySumaPlaceholder'))}
													formatValue={(value) => (value > 0 ? this.formatValue(value) : '')}
													customParse={(stringValue) => {
														// remove any added custom format chars
														const val = stringValue.toString().split(',').join('.').replace(/\s€/, '')
														return parseFloat(val)
													}}
													/* min={0} */
													precision={0}
												/>
												{/* <div className="input-note text-right" data-text-color="green">
													10 € - 50 €
												</div> */}
												<div style={{ marginTop: '10px' }}>
													{t('translation:RozpisZalohEditInfo.Ročná suma záloh')}:{' '}
													{calculateYearAdvancePayment(
														get(formValues, 'zalohyCyklusAktualny'),
														!get(formValues, 'zalohySuma')
															? get(formValues, 'zalohySumaPlaceholder', 0)
															: get(formValues, 'zalohySuma')
													)}
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('components:RozpisZalohEditInfo.Neznižovať výšku záloh')}</strong>
											</td>
											<td>
												{' '}
												{originalValues.zalohyNeznizovatSumu
													? t('components:RozpisZalohEditInfo.Áno')
													: t('components:RozpisZalohEditInfo.Nie')}{' '}
											</td>
											<td>
												<Field
													name='zalohyNeznizovatSumu'
													component={SelectField}
													options={booleanEnum}
													classNamePrefix='react-select'
												/>
												{/* <div className="input-note text-right" data-text-color="orange">
													Vyžaduje schválenie
												</div> */}
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('components:RozpisZalohEditInfo.Deň splatnosti záloh')}</strong>
											</td>
											<td> {get(originalValues, 'zalohyDenSplatnosti.label')} </td>
											<td>
												<Field
													name='zalohyDenSplatnosti'
													component={SelectField}
													options={denSplatnostiEnum}
													classNamePrefix='react-select'
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('components:RozpisZalohEditInfo.Dátum požadovanej zmeny')}</strong>
											</td>
											<td />
											<td>
												<div className='select-wrapper'>
													<Field
														name='datumZmeny'
														component={DatePickerField}
														showYearDropdown
														scrollableYearDropdown
														minDate={minTimeDatumZmeny?.toDate()}
														maxDate={maxTimeDatumZmeny?.toDate()}
														placeholderText={t('components:RozpisZalohEditInfo.Zvoľte dátum')}
													/>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('components:RozpisZalohEditInfo.Poznámka')}</strong>
										</td>
										<td />
										<td>
											<Field
												name='poznamka'
												component={TextareaField}
												rows='3'
												placeholder={t('components:RozpisZalohEditInfo.Zadajte poznámku')}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:RozpisZalohEditInfo.Dokumenty')}</strong>
										</td>
										<td />
										<td>
											<Field
												name='dokumenty'
												onDelete={onDeleteFile}
												component={DropZoneField}
												placeholder={t('components:RozpisZalohEditInfo.Klikni alebo presuň súbory pre nahratie')}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					{/* TODO: zatial sa nevie ci to ma byt implementovane */}
					{/* <div className="box">
						<div className="box-content no-padding">
							<table className="payment-table" cellSpacing="0">
								<thead>
									<tr>
										<th>Rozpis záloh</th>
										<th data-paid>1. 2018</th>
										<th>4. 2018</th>
										<th>7. 2018</th>
										<th>10. 2018</th>
										<th>Spolu</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Predpokladaná suma</td>
										<td data-paid>
											<strong></strong>
										</td>
										<td>
											<strong></strong>
										</td>
										<td>
											<strong></strong>
										</td>
										<td>
											<strong></strong>
										</td>
										<td>
											<strong>420 €</strong>
										</td>
									</tr>
									<tr>
										<td>Aktuálne nastavenie</td>
										<td data-paid>
											<strong>105 €</strong>
										</td>
										<td>
											<strong>105 €</strong>
										</td>
										<td>
											<strong>105 €</strong>
										</td>
										<td>
											<strong>105 €</strong>
										</td>
										<td>
											<strong>420 €</strong>
										</td>
									</tr>
									<tr>
										<td>Predpokladané zúčtovanie</td>
										<td data-paid>
											<strong>105 €</strong>
										</td>
										<td>
											<strong>105 €</strong>
										</td>
										<td>
											<strong>105 €</strong>
										</td>
										<td>
											<strong>105 €</strong>
										</td>
										<td>
											<strong data-text-color="green">420 €</strong>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div> */}
				</div>
			</form>
		)
	}

	componentDidUpdate(prevProps) {
		const { zalohySuma } = this.props.formValues
		const { dispatch } = this.props

		if (zalohySuma != get(prevProps, 'formValues.zalohySuma')) {
			if (zalohySuma <= 0) {
				dispatch(change(FORMS.ROZPISY_ZALOH, 'zalohySuma', null))
			}
		}
	}
}

const form = reduxForm({
	form: FORMS.ROZPISY_ZALOH,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate,
	touchOnChange: true
})(RozpisZalohEditInfo)

const selector = formValueSelector(FORMS.ROZPISY_ZALOH)

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	ciselniky: state.ciselniky.data,
	actualBusinessChannel: state.auth.businessChannel.actual,
	formValues: {
		zalohySuma: selector(state, 'zalohySuma'),
		zalohySumaPlaceholder: selector(state, 'zalohySumaPlaceholder'),
		zalohyCyklusAktualny: selector(state, 'zalohyCyklus'),
		ukonVstup: selector(state, 'ukonVstup'),
		dokumenty: selector(state, 'dokumenty')
	}
})

export default withTranslation('components')(connect(mapStateToProps)(form))
