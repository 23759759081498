import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link } from 'react-router-dom'
import { get, map, find } from 'lodash'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import * as IBAN from 'iban'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'

// utils
import { setRouteParams, BANKOVE_UCTY_ZOZNAM, ZMLUVNE_UCTY_DETAIL } from '../../utils/routes'
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'

// components
import ElementLoading from '../../components/ElementLoading'

class BankovyUcetDetailPage extends React.Component {
	static propTypes = {
		obchodnyPartnerActions: PropTypes.shape(),
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				opCislo: PropTypes.string.isRequired
			})
		}).isRequired,
		t: PropTypes.func.isRequired,
		computedMatch: PropTypes.shape().isRequired,
		bankovyUcet: PropTypes.shape(),
		ciselniky: PropTypes.shape()
	}

	componentDidMount() {
		const { obchodnyPartnerActions, computedMatch } = this.props

		obchodnyPartnerActions.loadBankoveUctyDetail(get(computedMatch, 'params.id'))
	}

	render() {
		const { interakcia, t, bankovyUcet, computedMatch, ciselniky } = this.props
		const { params } = computedMatch

		if (bankovyUcet.isLoading) {
			return <ElementLoading />
		}

		const backUrl = btoa(`${window.location.pathname}${window.location.search}`)
		const zmluvneUcty = map(get(bankovyUcet, 'data.pouzitie', []), (item, index) => {
			const obchodnyPartner = get(params, 'opCislo') !== get(item, 'opCislo') ? <strong>{get(item, 'opCislo', '-')}</strong> : get(item, 'opCislo', '-')
			const zmluvnyUcet =
				get(item, 'opCislo') && get(item, 'zuCislo') ? (
					<Link to={`${setRouteParams(ZMLUVNE_UCTY_DETAIL, get(item, 'opCislo'), get(item, 'zuCislo'))}?backUrl=${backUrl}`}>
						{get(item, 'zuCislo')}
					</Link>
				) : (
					get(item, 'zuCislo', '-')
				)
			return (
				<tr key={index}>
					<td>{zmluvnyUcet}</td>
					<td>
						<strong>{obchodnyPartner}</strong>
					</td>
				</tr>
			)
		})

		// NOTE: CP-878 1) Do zoznamu (aj detailu) Bankových spojení pridať stĺpec / atribút "Zdroj dát" s hodnotami SAP vs. Flores (pri tom druhom bude treba fixne naviazať text na inú hodnotu = 3 namiesto 4).
		let zdrojDat
		if (get(bankovyUcet, 'data.zdrojDat.id') == 3) {
			const zdrojDatCiselnik4 = find(get(ciselniky, 'zdrojDat'), { id: 4 })
			zdrojDat = get(zdrojDatCiselnik4, 'nazov') || '-'
		} else {
			zdrojDat = get(bankovyUcet, 'data.zdrojDat.nazov') || '-'
		}

		return (
			<>
				<div className='content-header'>
					<div className='header-row clearfix'>
						<Link
							to={setRouteParams(BANKOVE_UCTY_ZOZNAM, get(interakcia, 'data.opCislo'))}
							className='button pull-left'
							data-type='back-button'
							data-color='blue'
						>
							{t('containers:BankovyUcetDetailPage.Späť')}
						</Link>
						<div className='header-title pull-left'>{t('containers:BankovyUcetDetailPage.Detail bankového spojenia')}</div>
					</div>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-content'>
							<div className='inner-box'>
								<div className='row'>
									<div className='col-7'>
										<table>
											<tbody>
												<tr>
													<td>{t('containers:BankovyUcetDetailPage.IBAN')}</td>
													<td>
														<strong>{IBAN.printFormat(get(bankovyUcet, 'data.IBAN') || '') || '-'}</strong>
													</td>
												</tr>
												<tr>
													<td>{t('containers:BankovyUcetDetailPage.Banka')}</td>
													<td>
														<strong>{get(bankovyUcet, 'data.banka.nazov', '-')}</strong>
													</td>
												</tr>
												<tr>
													<td>{t('containers:BankovyUcetDetailPage.Zdroj dát')}</td>
													<td>
														<strong>{zdrojDat}</strong>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-4'>
									<strong style={{ paddingLeft: '12px' }}>{t('containers:BankovyUcetDetailPage.Základné informácie')}</strong>
									<br />
									<br />
									<table className='content-table'>
										<tbody>
											<tr>
												<td>{t('containers:BankovyUcetDetailPage.SWIFT')}</td>
												<td>
													<strong>{get(bankovyUcet, 'data.banka.swiftKod', '-')}</strong>
												</td>
											</tr>
											<tr>
												<td>{t('containers:BankovyUcetDetailPage.Krajina')}</td>
												<td>
													<strong>{get(bankovyUcet, 'data.banka.stat', '-')}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className='col-8'>
									<strong style={{ paddingLeft: '10px' }}>{t('containers:BankovyUcetDetailPage.Použitie na zmluvných účtoch')}</strong>
									<br />
									<br />
									<table className='content-table bordered hoverable' cellSpacing='0'>
										<thead>
											<tr>
												<th>{t('containers:BankovyUcetDetailPage.Číslo ZÚ')}</th>
												<th>
													<strong>{t('containers:BankovyUcetDetailPage.Číslo OP')}</strong>
												</th>
											</tr>
										</thead>
										<tbody>{zmluvneUcty}</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	bankovyUcet: state.obchodnyPartner.bankoveUctyDetail,
	ciselniky: state.ciselniky.data
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_OP_ZMLUVY])
)(BankovyUcetDetailPage)
