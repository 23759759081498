import { get, map } from 'lodash'
import { WIDGETY_SETTING_LOAD_START, WIDGETY_SETTING_LOAD_FAIL, WIDGETY_SETTING_LOAD_DONE } from '../types/widgety'
import { getReq, putReq } from '../utils/request'

const widgetySettingLoadStart = () => {
	return {
		type: WIDGETY_SETTING_LOAD_START
	}
}

const widgetySettingLoadFail = () => {
	return {
		type: WIDGETY_SETTING_LOAD_FAIL
	}
}

const widgetySettingLoadDone = (widgetSettings) => {
	return {
		type: WIDGETY_SETTING_LOAD_DONE,
		payload: {
			widgetSettings
		}
	}
}

export const loadWidgetSettings = () => {
	return async (dispatch) => {
		try {
			dispatch(widgetySettingLoadStart())

			const widgetySettings = await getReq('/api/v0/widgety')

			const sorting = map(get(widgetySettings, 'widgety.sorting', []), (widget) => {
				const temp = {
					id: widget.id || widget.boxId
				}
				if (widget.title || widget.boxHeader) {
					temp.title = widget.title || widget.boxHeader
				}
				if (widget.settings) {
					temp.settings = widget.settings
				}
				return temp
			})

			dispatch(widgetySettingLoadDone({ sorting }))
		} catch (e) {
			dispatch(widgetySettingLoadFail())
		}
	}
}

export const updateWidgetSettings = (body) => {
	return async (dispatch) => {
		try {
			dispatch(widgetySettingLoadStart())

			const widgetySettings = await putReq('/api/v0/widgety', null, body)

			dispatch(widgetySettingLoadDone(get(widgetySettings, 'widgety')))
		} catch (e) {
			dispatch(widgetySettingLoadFail())
		}
	}
}

export const updateDefaultWidgetSettings = () => {
	return async (dispatch) => {
		try {
			dispatch(widgetySettingLoadStart())

			const widgetySettings = await putReq('/api/v0/widgety/defaultSetting')
			dispatch(widgetySettingLoadDone(get(widgetySettings, 'widgety')))
		} catch (e) {
			dispatch(widgetySettingLoadFail())
		}
	}
}
