import { get, concat, size, keyBy } from 'lodash'
import {
	MIESTA_SPOTREBY_LOAD_START,
	MIESTA_SPOTREBY_LOAD_PART_DONE,
	MIESTA_SPOTREBY_LOAD_DONE,
	MIESTA_SPOTREBY_LOAD_FAIL,
	MIESTA_SPOTREBY_CLEAR,
	MIESTA_SPOTREBY_SEARCH_START,
	MIESTA_SPOTREBY_SEARCH_DONE,
	MIESTA_SPOTREBY_SEARCH_FAIL,
	MIESTA_SPOTREBY_SEARCH_CLEAR
} from '../types/miestaSpotrebyTypes'

import { getReq } from '../utils/request'

async function loadMiestaSpotrebyByParts(opCislo, dispatch, pageSize = 100, queryParams = {}, getStore) {
	const loadPage = async (page) => {
		const store = getStore()
		const query = {
			...queryParams,
			pageSize,
			page
		}
		try {
			const miestaSpotrebyData = await getReq(`/api/v2/op/${opCislo}/miesta-spotreby`, query)

			// if is page one assing current data if page is bigger merge with pevious data
			const miestaSpotreby =
				page == 1
					? get(miestaSpotrebyData, 'response.content', [])
					: concat(get(store, 'miestaSpotreby.collection.data', []), get(miestaSpotrebyData, 'response.content', []))

			dispatch({
				type: MIESTA_SPOTREBY_LOAD_PART_DONE,
				payload: {
					miestaSpotreby
				}
			})

			if (size(get(miestaSpotrebyData, 'response.content', [])) >= pageSize) {
				await loadPage(page + 1)
			} else {
				dispatch({
					type: MIESTA_SPOTREBY_LOAD_DONE,
					payload: {
						miestaSpotreby,
						byCislo: keyBy(miestaSpotreby, 'cislo')
					}
				})
				return miestaSpotreby
			}
		} catch (e) {
			dispatch({
				type: MIESTA_SPOTREBY_LOAD_FAIL
			})
		}
	}
	return await loadPage(1)
}

export const loadAllMiestaSpotreby = (queryParams = {}, filterParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: MIESTA_SPOTREBY_LOAD_START
		})

		const query = {
			...queryParams,
			...filterParams
		}

		return await loadMiestaSpotrebyByParts(get(interakcia, 'opCislo'), dispatch, 100, query, getStore)
	}
}

export const clearMiestaSpotreby = () => {
	return async (dispatch) => {
		dispatch({
			type: MIESTA_SPOTREBY_CLEAR
		})
	}
}

export const searchMiestaSpotreby = (queryParams = {}, pageSize = 250, page = 1) => {
	return async (dispatch) => {
		dispatch({
			type: MIESTA_SPOTREBY_SEARCH_START
		})

		const query = {
			...queryParams,
			pageSize,
			page
		}
		try {
			const searchResultsData = await getReq('/api/v2/ms', query)
			const searchResults = get(searchResultsData, 'response.content', null)

			dispatch({
				type: MIESTA_SPOTREBY_SEARCH_DONE,
				payload: {
					searchResults
				}
			})

			return searchResults
		} catch (e) {
			dispatch({
				type: MIESTA_SPOTREBY_SEARCH_FAIL
			})
		}
	}
}

export const searchMiestaSpotrebyClear = () => {
	return (dispatch) => {
		dispatch({
			type: MIESTA_SPOTREBY_SEARCH_CLEAR
		})
	}
}
