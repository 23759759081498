import { FAKTURY_LOAD_DONE, FAKTURY_LOAD_FAIL, FAKTURY_LOAD_START } from '../types/faktury'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	data: [],
	page: 1,
	isLoading: false,
	isFailure: false,
	isReady: false,
	lastLoad: new Date(0),
	hasNextPage: true
}

export default (state = initState, action) => {
	switch (action.type) {
		case FAKTURY_LOAD_START:
			return {
				...state,
				page: action.payload.page,
				isLoading: true,
				isFailure: false
			}
		case FAKTURY_LOAD_FAIL:
			return {
				...initState,
				isFailure: true,
				isReady: true,
				lastLoad: new Date(0),
				hasNextPage: false
			}
		case FAKTURY_LOAD_DONE:
			return {
				...state,
				data: state.page === 1 ? action.payload.faktury : state.data.concat(action.payload.faktury),
				isLoading: false,
				isFailure: false,
				isReady: true,
				lastLoad: new Date(),
				hasNextPage: action.payload.hasNextPage
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
