import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

// utils
import { formatDate } from '../../utils/date'
import { SUHLAS_TYP_VYJADRENIA, BIZNIS_REFERENCIA_TYP } from '../../utils/enums'
import { openDataUriWindow } from '../../utils/files'

class UdelenySuhlas extends React.Component {
	static propTypes = {
		udelenySuhlas: PropTypes.shape({}).isRequired,
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {}
	}

	render() {
		const { t, udelenySuhlas } = this.props

		return (
			<div className='consent-last-statement'>
				<div className='consent-last-statement-column'>
					<ul>
						<li>
							<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Referencia')}</span>
							<span className='consent-last-statement-column-2'>{udelenySuhlas.biznisReferencia ?? '-'}</span>
						</li>
						<li>
							<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Typ referencie')}</span>
							<span className='consent-last-statement-column-2'>{udelenySuhlas.biznisReferenciaTyp ?? '-'}</span>
						</li>
						<li>
							<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Vytvoril')}</span>
							<span className='consent-last-statement-column-2'>{udelenySuhlas.pouzivatel ?? '-'}</span>
						</li>
						<li>
							<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Kanál')}</span>
							<span className='consent-last-statement-column-2'>{udelenySuhlas.kanal ?? '-'}</span>
						</li>
						<li>
							<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Definícia')}</span>
							<span className='consent-last-statement-column-2'>{`${t('translation:Suhlasy.Verzia')} ${udelenySuhlas.verzia ?? '-'} `}</span>
						</li>
					</ul>
				</div>
				<div className='consent-last-statement-column'>
					<ul>
						<li>
							<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Dátum')}</span>
							<span className='consent-last-statement-column-2'>
								{udelenySuhlas.datumVyjadrenie != undefined ? formatDate(udelenySuhlas.datumVyjadrenie, null, 'DD.MM.YYYY') : '-'}
							</span>
						</li>
						{udelenySuhlas.platnostDo != undefined && (
							<li>
								<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Platnosť do')}</span>
								<span className='consent-last-statement-column-2'>{formatDate(udelenySuhlas.platnostDo, null, 'DD.MM.YYYY')}</span>
							</li>
						)}
						<li>
							<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Dátum na verifikáciu')}</span>
							<span className='consent-last-statement-column-2'>
								{udelenySuhlas.verifikovatSuhlas != undefined ? formatDate(udelenySuhlas.verifikovatSuhlas, null, 'DD.MM.YYYY') : '-'}
							</span>
						</li>
						<li>
							<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Vyjadrenie')}</span>
							<span className='consent-last-statement-column-2'>
								<span className={udelenySuhlas.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.SUHLAS ? 'consent-true' : 'consent-false'}>
									{udelenySuhlas.typVyjadrenieSuhlas?.toUpperCase() ?? '-'}
								</span>
							</span>
						</li>
						{udelenySuhlas.nesuhlasDovod != undefined && (
							<li>
								<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Dôvod nesúhlasu')}</span>
								<span className='consent-last-statement-column-2'>{udelenySuhlas.nesuhlasDovod?.dovod ?? '-'}</span>
							</li>
						)}
						{udelenySuhlas.nesuhlasPoznamka != undefined && (
							<li>
								<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Poznámka')}</span>
								<span className='consent-last-statement-column-2'>{udelenySuhlas.nesuhlasPoznamka ?? '-'}</span>
							</li>
						)}
						{udelenySuhlas.biznisReferenciaTyp === BIZNIS_REFERENCIA_TYP.DOCUMENT_URL.nazov && (
							<li>
								<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Biznis referencia')}</span>
								<span className='consent-last-statement-column-2'>
									<a
										className='file'
										onClick={() => openDataUriWindow(udelenySuhlas.biznisReferencia)}
										data-type='general'
										style={{ cursor: 'pointer' }}
									>
										{t('translation:Suhlasy.Podpísaný dokument')}
									</a>
								</span>
							</li>
						)}
						{udelenySuhlas.biznisReferenciaTyp !== BIZNIS_REFERENCIA_TYP.DOCUMENT_URL.nazov && (
							<li>
								<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Biznis referencia')}</span>
								<span className='consent-last-statement-column-2'>{udelenySuhlas.biznisReferencia ?? '-'}</span>
							</li>
						)}
						{udelenySuhlas?.zmluvnyUcet && !udelenySuhlas?.produktKod && (
							<li>
								<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Zmluvný účet')}</span>
								<span className='consent-last-statement-column-2'>{udelenySuhlas.zmluvnyUcet ?? '-'}</span>
							</li>
						)}
						{udelenySuhlas?.produktKod && (
							<li>
								<span className='consent-last-statement-column-1'>{t('translation:Suhlasy.Produkt')}</span>
								<span className='consent-last-statement-column-2'>{udelenySuhlas.produktKod ?? '-'}</span>
							</li>
						)}
					</ul>
				</div>
			</div>
		)
	}
}

export default withTranslation('components')(UdelenySuhlas)
